import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Button } from 'reactstrap';

const ExportExcel = ({ listData, name, options }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const itemsList = [];
  listData.forEach((key0, j) => {
    const tutorial = {};
    options &&
      options.fields &&
      options.fields.forEach((key, i) => {
        tutorial[key] = key0[key];
      });
    itemsList.push(tutorial);
  });

  const exportToCSV = (items, fileName) => {
    const ws = XLSX.utils.json_to_sheet(items);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Button onClick={e => exportToCSV(itemsList, name)} color="success" type="button">
      <i className="mdi mdi-search-web" />
      Export to Excel
    </Button>
  );
};

export default ExportExcel;
