import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, Button, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './binder-rate.reducer';
import { IBinderRate } from 'app/shared/model/binder-rate.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IBinderRateDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class BinderRateDetail extends React.Component<IBinderRateDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { binderRateEntity } = this.props;
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  BinderRate [<b>{binderRateEntity.id}</b>]
                </CardTitle>
                <CardBody>
                  <dl className="jh-entity-details">
                    <dt>
                      <span id="rate">Rate</span>
                    </dt>
                    <dd>{binderRateEntity.rate}</dd>
                    <dt>
                      <span id="minimumValue">Minimum Value</span>
                    </dt>
                    <dd>{binderRateEntity.minimumValue}</dd>
                    <dt>
                      <span id="hasBinder">Has Binder</span>
                    </dt>
                    <dd>{binderRateEntity.hasBinder ? 'true' : 'false'}</dd>
                    <dt>
                      <span id="startActiveDate">Start Active Date</span>
                    </dt>
                    <dd>
                      <TextFormat value={binderRateEntity.startActiveDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                    </dd>
                    <dt>
                      <span id="endActiveDate">End Active Date</span>
                    </dt>
                    <dd>
                      <TextFormat value={binderRateEntity.endActiveDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                    </dd>
                    <dt>
                      <span id="status">Status</span>
                    </dt>
                    <dd>{binderRateEntity.status}</dd>
                    <dt>Underwriter</dt>
                    <dd>{binderRateEntity.underwriterId ? binderRateEntity.underwriterId : ''}</dd>
                    <dt>Risk Category</dt>
                    <dd>{binderRateEntity.riskCategoryId ? binderRateEntity.riskCategoryId : ''}</dd>
                    <dt>Risk Class</dt>
                    <dd>{binderRateEntity.riskClassId ? binderRateEntity.riskClassId : ''}</dd>
                    <dt>Cover Type</dt>
                    <dd>{binderRateEntity.coverTypeId ? binderRateEntity.coverTypeId : ''}</dd>
                  </dl>
                  <Button tag={Link} to="/entity/binder-rate" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
                  </Button>
                  &nbsp;
                  <Button tag={Link} to={`/entity/binder-rate/${binderRateEntity.id}/edit`} replace color="primary">
                    <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ binderRate }: IRootState) => ({
  binderRateEntity: binderRate.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BinderRateDetail);
