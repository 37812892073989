import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPolicyApplication, defaultValue } from 'app/shared/model/policy-application.model';

export const ACTION_TYPES = {
  FETCH_POLICYAPPLICATION_LIST: 'policyApplication/FETCH_POLICYAPPLICATION_LIST',
  FETCH_POLICYAPPLICATION: 'policyApplication/FETCH_POLICYAPPLICATION',
  CREATE_POLICYAPPLICATION: 'policyApplication/CREATE_POLICYAPPLICATION',
  UPDATE_POLICYAPPLICATION: 'policyApplication/UPDATE_POLICYAPPLICATION',
  DELETE_POLICYAPPLICATION: 'policyApplication/DELETE_POLICYAPPLICATION',
  RESET: 'policyApplication/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPolicyApplication>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type PolicyApplicationState = Readonly<typeof initialState>;

// Reducer

export default (state: PolicyApplicationState = initialState, action): PolicyApplicationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_POLICYAPPLICATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_POLICYAPPLICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_POLICYAPPLICATION):
    case REQUEST(ACTION_TYPES.UPDATE_POLICYAPPLICATION):
    case REQUEST(ACTION_TYPES.DELETE_POLICYAPPLICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_POLICYAPPLICATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_POLICYAPPLICATION):
    case FAILURE(ACTION_TYPES.CREATE_POLICYAPPLICATION):
    case FAILURE(ACTION_TYPES.UPDATE_POLICYAPPLICATION):
    case FAILURE(ACTION_TYPES.DELETE_POLICYAPPLICATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_POLICYAPPLICATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_POLICYAPPLICATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_POLICYAPPLICATION):
    case SUCCESS(ACTION_TYPES.UPDATE_POLICYAPPLICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_POLICYAPPLICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/policy-applications';

// Actions

export const getEntities: ICrudGetAllAction<IPolicyApplication> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_POLICYAPPLICATION_LIST,
    payload: axios.get<IPolicyApplication>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IPolicyApplication> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_POLICYAPPLICATION,
    payload: axios.get<IPolicyApplication>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPolicyApplication> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_POLICYAPPLICATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IPolicyApplication> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_POLICYAPPLICATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPolicyApplication> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_POLICYAPPLICATION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
