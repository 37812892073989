import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, Button, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './levies.reducer';
import { ILevies } from 'app/shared/model/levies.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ILeviesDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class LeviesDetail extends React.Component<ILeviesDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { leviesEntity } = this.props;
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Levies [<b>{leviesEntity.id}</b>]
                </CardTitle>
                <CardBody>
                  <dl className="jh-entity-details">
                    <dt>
                      <span id="chargeName">Charge Name</span>
                    </dt>
                    <dd>{leviesEntity.chargeName}</dd>
                    <dt>
                      <span id="abbreviation">Abbreviation</span>
                    </dt>
                    <dd>{leviesEntity.abbreviation}</dd>
                    <dt>
                      <span id="description">Description</span>
                    </dt>
                    <dd>{leviesEntity.description}</dd>
                    <dt>
                      <span id="country">Country</span>
                    </dt>
                    <dd>{leviesEntity.country}</dd>
                    <dt>
                      <span id="startDate">Start Date</span>
                    </dt>
                    <dd>
                      <TextFormat value={leviesEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                    </dd>
                    <dt>
                      <span id="endDateTime">End Date Time</span>
                    </dt>
                    <dd>
                      <TextFormat value={leviesEntity.endDateTime} type="date" format={APP_LOCAL_DATE_FORMAT} />
                    </dd>
                    <dt>
                      <span id="status">Status</span>
                    </dt>
                    <dd>{leviesEntity.status}</dd>
                    <dt>
                      <span id="rateType">Rate Type</span>
                    </dt>
                    <dd>{leviesEntity.rateType}</dd>
                    <dt>
                      <span id="rateValue">Rate Value</span>
                    </dt>
                    <dd>{leviesEntity.rateValue}</dd>
                  </dl>
                  <Button tag={Link} to="/entity/levies" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
                  </Button>
                  &nbsp;
                  <Button tag={Link} to={`/entity/levies/${leviesEntity.id}/edit`} replace color="primary">
                    <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ levies }: IRootState) => ({
  leviesEntity: levies.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeviesDetail);
