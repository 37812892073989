import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IRiskClassDefaultData, defaultValue } from 'app/shared/model/risk-class-default-data.model';

export const ACTION_TYPES = {
  FETCH_RISKCLASSDEFAULTDATA_LIST: 'riskClassDefaultData/FETCH_RISKCLASSDEFAULTDATA_LIST',
  FETCH_RISKCLASSDEFAULTDATA: 'riskClassDefaultData/FETCH_RISKCLASSDEFAULTDATA',
  CREATE_RISKCLASSDEFAULTDATA: 'riskClassDefaultData/CREATE_RISKCLASSDEFAULTDATA',
  UPDATE_RISKCLASSDEFAULTDATA: 'riskClassDefaultData/UPDATE_RISKCLASSDEFAULTDATA',
  DELETE_RISKCLASSDEFAULTDATA: 'riskClassDefaultData/DELETE_RISKCLASSDEFAULTDATA',
  RESET: 'riskClassDefaultData/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRiskClassDefaultData>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type RiskClassDefaultDataState = Readonly<typeof initialState>;

// Reducer

export default (state: RiskClassDefaultDataState = initialState, action): RiskClassDefaultDataState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RISKCLASSDEFAULTDATA):
    case REQUEST(ACTION_TYPES.UPDATE_RISKCLASSDEFAULTDATA):
    case REQUEST(ACTION_TYPES.DELETE_RISKCLASSDEFAULTDATA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA):
    case FAILURE(ACTION_TYPES.CREATE_RISKCLASSDEFAULTDATA):
    case FAILURE(ACTION_TYPES.UPDATE_RISKCLASSDEFAULTDATA):
    case FAILURE(ACTION_TYPES.DELETE_RISKCLASSDEFAULTDATA):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_RISKCLASSDEFAULTDATA):
    case SUCCESS(ACTION_TYPES.UPDATE_RISKCLASSDEFAULTDATA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RISKCLASSDEFAULTDATA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/risk-class-default-data';

// Actions

export const getEntities: ICrudGetAllAction<IRiskClassDefaultData> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA_LIST,
    payload: axios.get<IRiskClassDefaultData>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IRiskClassDefaultData> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RISKCLASSDEFAULTDATA,
    payload: axios.get<IRiskClassDefaultData>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IRiskClassDefaultData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RISKCLASSDEFAULTDATA,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IRiskClassDefaultData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RISKCLASSDEFAULTDATA,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IRiskClassDefaultData> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RISKCLASSDEFAULTDATA,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
