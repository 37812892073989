import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, Button, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './standard-rate.reducer';
import { IStandardRate } from 'app/shared/model/standard-rate.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IStandardRateDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class StandardRateDetail extends React.Component<IStandardRateDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { standardRateEntity } = this.props;
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  StandardRate [<b>{standardRateEntity.id}</b>]
                </CardTitle>
                <CardBody>
                  <dl className="jh-entity-details">
                    <dt>
                      <span id="rate">Rate</span>
                    </dt>
                    <dd>{standardRateEntity.rate}</dd>
                    <dt>
                      <span id="minimumValue">Minimum Value</span>
                    </dt>
                    <dd>{standardRateEntity.minimumValue}</dd>
                    <dt>
                      <span id="startActiveDate">Start Active Date</span>
                    </dt>
                    <dd>
                      <TextFormat value={standardRateEntity.startActiveDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                    </dd>
                    <dt>
                      <span id="endActiveDate">End Active Date</span>
                    </dt>
                    <dd>
                      <TextFormat value={standardRateEntity.endActiveDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                    </dd>
                    <dt>
                      <span id="status">Status</span>
                    </dt>
                    <dd>{standardRateEntity.status}</dd>
                    <dt>Product Type</dt>
                    <dd>{standardRateEntity.productTypeId ? standardRateEntity.productTypeId : ''}</dd>
                    <dt>Risk Category</dt>
                    <dd>{standardRateEntity.riskCategoryId ? standardRateEntity.riskCategoryId : ''}</dd>
                    <dt>Risk Class</dt>
                    <dd>{standardRateEntity.riskClassId ? standardRateEntity.riskClassId : ''}</dd>
                    <dt>Cover Type</dt>
                    <dd>{standardRateEntity.coverTypeId ? standardRateEntity.coverTypeId : ''}</dd>
                  </dl>
                  <Button tag={Link} to="/entity/standard-rate" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
                  </Button>
                  &nbsp;
                  <Button tag={Link} to={`/entity/standard-rate/${standardRateEntity.id}/edit`} replace color="primary">
                    <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ standardRate }: IRootState) => ({
  standardRateEntity: standardRate.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardRateDetail);
