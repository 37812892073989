import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const QuotesMenu = () => (
  <MenuItem to="/quotes" icon="mdi mdi-pencil-box-outline">
    Quotes
  </MenuItem>
);

export default QuotesMenu;
