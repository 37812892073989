import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
import { Typography, Box, Checkbox, withStyles, FormControlLabel } from '@material-ui/core';
import DataTable from 'app/shared/common/dataTable';
import { NavLink } from 'react-router-dom';
import { sortBy } from 'lodash';

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  underwriters: PropTypes.any,
  productTypeId: PropTypes.number.isRequired,
  handleRankChange: PropTypes.func
};

function TabPanel(props) {
  const { children, value, underwriters, productTypeId, index, loading, updating, handleRankChange, ...other } = props;
  const tableData = sortBy(underwriters, productTypeId === 3 ? 'healthRank' : 'rank').map(prop => ({
    ...prop,
    id: (
      <Button tag={NavLink} to={`test/${prop.id}`} color="link" size="sm">
        {prop.id}
      </Button>
    ),
    currentRank: (
      <Button tag={NavLink} to={`test/${prop.id}`} color="link" size="sm">
        {productTypeId === 3 ? prop.healthRank : prop.rank}
      </Button>
    ),
    actions: (
      <div className="text-center">
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={(productTypeId === 3 ? prop.healthRank : prop.rank) === 1}
              onChange={() => handleRankChange(prop.id, productTypeId)}
              name="checkedG"
            />
          }
          label=""
        />
      </div>
    )
  }));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
          <Typography>{underwriters ? underwriters.length : ''}</Typography>
          <DataTable
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: 'Name',
                accessor: 'name'
              },
              {
                Header: 'Rank',
                accessor: 'currentRank'
              },
              {
                Header: 'Top',
                accessor: 'actions',
                sortable: false,
                filterable: false
              }
            ]}
            manual
            loading={loading || updating}
            showPaginationBottom
            tableData={tableData}
            filterable
          />
        </Box>
      )}
    </div>
  );
}

const GreenCheckbox = withStyles({
  root: {
    color: 'green',
    '&$checked': {
      color: 'green'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export default TabPanel;
