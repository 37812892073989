import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudDeleteAction } from 'app/shared/reducers/redux-action.type';
import { IPaymentCallback, defaultValue } from 'app/shared/model/payment-callback.model';

export const ACTION_TYPES = {
  FETCH_PAYMENTCALLBACK_LIST: 'paymentCallback/FETCH_PAYMENTCALLBACK_LIST',
  FETCH_PAYMENTCALLBACK: 'paymentCallback/FETCH_PAYMENTCALLBACK',
  CREATE_PAYMENTCALLBACK: 'paymentCallback/CREATE_PAYMENTCALLBACK',
  UPDATE_PAYMENTCALLBACK: 'paymentCallback/UPDATE_PAYMENTCALLBACK',
  DELETE_PAYMENTCALLBACK: 'paymentCallback/DELETE_PAYMENTCALLBACK',
  TOGGLE_ALLOCATE_MODAL: 'paymentCallback/TOGGLE_ALLOCATE_MODAL',
  SET_BLOB: 'paymentCallback/SET_BLOB',
  RESET: 'paymentCallback/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPaymentCallback>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  allocateModal: false,
  updateSuccess: false,
  attach: {}
};

export type PaymentCallbackState = Readonly<typeof initialState>;

// Reducer

export default (state: PaymentCallbackState = initialState, action): PaymentCallbackState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAYMENTCALLBACK_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PAYMENTCALLBACK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PAYMENTCALLBACK):
    case REQUEST(ACTION_TYPES.UPDATE_PAYMENTCALLBACK):
    case REQUEST(ACTION_TYPES.DELETE_PAYMENTCALLBACK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PAYMENTCALLBACK_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PAYMENTCALLBACK):
    case FAILURE(ACTION_TYPES.CREATE_PAYMENTCALLBACK):
    case FAILURE(ACTION_TYPES.UPDATE_PAYMENTCALLBACK):
    case FAILURE(ACTION_TYPES.DELETE_PAYMENTCALLBACK):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENTCALLBACK_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENTCALLBACK):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAYMENTCALLBACK):
    case SUCCESS(ACTION_TYPES.UPDATE_PAYMENTCALLBACK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PAYMENTCALLBACK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.TOGGLE_ALLOCATE_MODAL:
      return {
        ...state,
        allocateModal: !state.allocateModal
      };
    case ACTION_TYPES.SET_BLOB:
      const { name, data, contentType } = action.payload;
      const { log } = console;
      log(name, data, contentType);
      return {
        ...state,
        attach: {
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/callbacks';

// Actions

export const getEntities: ICrudGetAllAction<IPaymentCallback> = (page = 0, size = 10, sort = ' ,') => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENTCALLBACK_LIST,
    payload: axios.get<IPaymentCallback>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IPaymentCallback> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENTCALLBACK,
    payload: axios.get<IPaymentCallback>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPaymentCallback> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PAYMENTCALLBACK,
    payload: axios.post(apiUrl + '/mobile-payment/confirmation', cleanEntity(entity))
  });
  dispatch(getEntities(0, 10));
  return result;
};

export const updateEntity: ICrudPutAction<IPaymentCallback> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENTCALLBACK,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPaymentCallback> = (id, companyId) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PAYMENTCALLBACK,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(0, 10, `,&companyId=${companyId}`));
  return result;
};
export const toggleAllocate = () => async dispatch =>
  dispatch({
    type: ACTION_TYPES.TOGGLE_ALLOCATE_MODAL
  });
export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});
export const reset = () => ({
  type: ACTION_TYPES.RESET
});
