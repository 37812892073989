import React from 'react';
import BlankTemplate from 'app/shared/templates/blank-template';
import RegisterInitSuccess from 'app/modules/account/register/register-init-success';

const RegisterTemplate = props => (
  <BlankTemplate>
    <RegisterInitSuccess {...props} />
  </BlankTemplate>
);

export default RegisterTemplate;
