import React from 'react';
import classname from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

class ClientTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = selectedTab => {
    this.props.handleTab(selectedTab);
  };

  render() {
    const { navTabItems, activeTab } = this.props;
    return (
      <div className="client-tab">
        <Nav tabs>
          {navTabItems.map(item => (
            <NavItem key={JSON.stringify(item)}>
              <NavLink
                className={classname({
                  active: activeTab === item.key
                })}
                onClick={() => this.handleClick(item.key)}
              >
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    );
  }
}

export default ClientTab;
