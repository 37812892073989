import { Moment } from 'moment';

export const enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const enum CoverTypeCategory {
  PER_PERSON = 'PER_PERSON',
  PER_FAMILY = 'PER_FAMILY'
}

export const enum RateType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED'
}

export interface IBinderRate {
  id?: number;
  rate?: number;
  minimumValue?: number;
  minSumInsured?: number;
  maxSumInsured?: number;
  productName?: string;
  hasBinder?: boolean;
  isMultiple?: boolean;
  startActiveDate?: Moment;
  endActiveDate?: Moment;
  status?: Status;
  underwriterId?: number;
  riskCategoryId?: number;
  riskClassId?: number;
  coverTypeId?: number;
  underwriterName?: string;
  riskCategoryName?: string;
  riskClassName?: string;
  coverTypeName?: string;
  tonnageRangeId?: number;
  coverAreaId?: number;
  childMinimumValue?: number;
  brokerRateType?: RateType;
  brokerRateValue?: number;
  minAge?: string;
  maxAge?: string;
  maxTonnage?: string;
  minTonnage?: string;
  ageRange?: string;
  spouseValue?: number;
  hasSpouseValue?: boolean;
  productTypeId?: number;
  productTypeName?: string;
  underwriterProductId?: number;
  coverTypeCategory?: CoverTypeCategory;
  minimumValue1?: number;
  minimumValue2?: number;
  minimumValue3?: number;
  minimumValue4?: number;
  minimumValue5?: number;
  minimumValue6?: number;
  minimumValueOther?: number;
}

export const defaultValue: Readonly<IBinderRate> = {
  hasBinder: false
};
