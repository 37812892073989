import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import DataTable from 'app/shared/common/dataTable';
import { getSortState, IPaginationBaseState } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './company.reducer';
import { ICompany } from 'app/shared/model/company.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface ICompanyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ICompanyState = IPaginationBaseState;

export class Company extends React.Component<ICompanyProps, ICompanyState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.getEntities();
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    this.props.getEntities(state.page, state.pageSize, `${sort},${order}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { loading, companyList, match, totalItems } = this.props;
    const tableData = companyList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Companies
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  Create new Company
                </NavLink>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Usertype',
                  accessor: 'usertype',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="COMPANY">COMPANY</option>
                      <option value="INDIVIDUAL">INDIVIDUAL</option>
                    </select>
                  )
                },
                {
                  Header: 'First Name',
                  accessor: 'firstName'
                },
                {
                  Header: 'Middle Name',
                  accessor: 'middleName'
                },
                {
                  Header: 'Last Name',
                  accessor: 'lastName'
                },
                {
                  Header: 'Email',
                  accessor: 'email'
                },
                {
                  Header: 'Display Name',
                  accessor: 'displayName'
                },
                {
                  Header: 'Logo',
                  accessor: 'logo'
                },
                {
                  Header: 'Telephone',
                  accessor: 'telephone'
                },
                {
                  Header: 'Contact Persion',
                  accessor: 'contactPersion'
                },
                {
                  Header: 'Mobile',
                  accessor: 'mobile'
                },
                {
                  Header: 'Street Address',
                  accessor: 'streetAddress'
                },
                {
                  Header: 'County',
                  accessor: 'county'
                },
                {
                  Header: 'Country',
                  accessor: 'country'
                },
                {
                  Header: 'Pin Number',
                  accessor: 'pinNumber'
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ company }: IRootState) => ({
  loading: company.loading,
  companyList: company.entities,
  totalItems: company.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Company);
