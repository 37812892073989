import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudDeleteAction } from 'app/shared/reducers/redux-action.type';
import { IUnderwriter, defaultValue } from 'app/shared/model/underwriter.model';

export const ACTION_TYPES = {
  FETCH_UNDERWRITER_LIST: 'underwriter/FETCH_UNDERWRITER_LIST',
  FETCH_UNDERWRITER: 'underwriter/FETCH_UNDERWRITER',
  CREATE_UNDERWRITER: 'underwriter/CREATE_UNDERWRITER',
  UPDATE_UNDERWRITER: 'underwriter/UPDATE_UNDERWRITER',
  DELETE_UNDERWRITER: 'underwriter/DELETE_UNDERWRITER',
  UPDATE_UNDERWRITER_RANK: 'underwriter/UPDATE_UNDERWRITER_RANK',
  RESET: 'underwriter/RESET',
  SET_BLOB: 'underwriter/SET_BLOB',
  SET_PIN_IMAGE: 'underwriter/SET_PIN_IMAGE',
  SET_BINDER_PDF: 'underwriter/SET_BINDER_PDF',
  DELETE_BINDER_PDF: 'underwriter/DELETE_BINDER_PDF'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUnderwriter>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type UnderwriterState = Readonly<typeof initialState>;

// Reducer

export default (state: UnderwriterState = initialState, action): UnderwriterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_UNDERWRITER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_UNDERWRITER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_UNDERWRITER):
    case REQUEST(ACTION_TYPES.UPDATE_UNDERWRITER):
    case REQUEST(ACTION_TYPES.DELETE_UNDERWRITER):
    case REQUEST(ACTION_TYPES.UPDATE_UNDERWRITER_RANK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_UNDERWRITER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_UNDERWRITER):
    case FAILURE(ACTION_TYPES.CREATE_UNDERWRITER):
    case FAILURE(ACTION_TYPES.UPDATE_UNDERWRITER):
    case FAILURE(ACTION_TYPES.DELETE_UNDERWRITER):
    case FAILURE(ACTION_TYPES.UPDATE_UNDERWRITER_RANK):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_UNDERWRITER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_UNDERWRITER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_UNDERWRITER):
    case SUCCESS(ACTION_TYPES.UPDATE_UNDERWRITER):
    case SUCCESS(ACTION_TYPES.UPDATE_UNDERWRITER_RANK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_UNDERWRITER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB:
      // const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          logoFile: {
            ...state.entity.logoFile,
            content: action.payload.data,
            contentContentType: action.payload.contentType
          }
        }
      };
    case ACTION_TYPES.SET_PIN_IMAGE:
      return {
        ...state,
        entity: {
          ...state.entity,
          pinImage: {
            ...state.entity.pinImage,
            content: action.payload.data,
            contentContentType: action.payload.contentType
          }
        }
      };
    case ACTION_TYPES.SET_BINDER_PDF:
      return {
        ...state,
        entity: {
          ...state.entity,
          binderPdfs: [...state.entity.binderPdfs, { content: action.payload.data, contentContentType: action.payload.contentType }]
        }
      };
    case ACTION_TYPES.DELETE_BINDER_PDF:
      const binderPdfs = state.entity.binderPdfs;
      const index = action.payload.index;
      return {
        ...state,
        entity: {
          ...state.entity,
          binderPdfs: [...binderPdfs.slice(0, index), ...binderPdfs.slice(index + 1)]
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/underwriters';

// Actions

export const getEntities: ICrudGetAllAction<IUnderwriter> = (page = 0, size = 10, sort = ' ,') => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_UNDERWRITER_LIST,
    payload: axios.get<IUnderwriter>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IUnderwriter> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_UNDERWRITER,
    payload: axios.get<IUnderwriter>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IUnderwriter> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_UNDERWRITER,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(0, 10, `,&companyId=${entity.companyId}`));
  return result;
};

export const updateEntity: ICrudPutAction<IUnderwriter> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_UNDERWRITER,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(0, 10, `,&companyId=${entity.companyId}`));
  return result;
};

export const updateRank: ICrudPutAction<any> = ({ underwriterId = 0, productTypeId = 0 }) => async dispatch => {
  const requestUrl = `${apiUrl}/update-rank/${underwriterId}?productTypeId=${productTypeId}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_UNDERWRITER_RANK,
    payload: axios.put(requestUrl)
  });
  dispatch(getEntities(0, 10, `,&companyId=1`));
  return result;
};

export const deleteEntity: ICrudDeleteAction<IUnderwriter> = (id, companyId) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_UNDERWRITER,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(0, 10, `,&companyId=${companyId}`));
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const setPinImage = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_PIN_IMAGE,
  payload: {
    name,
    data,
    contentType
  }
});

export const setBinderPdf = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BINDER_PDF,
  payload: {
    name,
    data,
    contentType
  }
});

export const deleteBinderPdf = index => ({
  type: ACTION_TYPES.DELETE_BINDER_PDF,
  payload: {
    index
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
