export const enum EntityName {
  QUOTE = 'QUOTE'
}

export interface ISmsContent {
  id?: number;
  entityName?: EntityName;
  title?: string;
  content?: string;
}

export const defaultValue: Readonly<ISmsContent> = {};
