import React from 'react';
import { connect } from 'react-redux';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Alert, Button, Col, Row, Label } from 'reactstrap';
import { getUrlParameter } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import img2 from '../../../../assets/images/big/auth-bg.jpg';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover'
};

export interface IPasswordResetFinishProps extends StateProps, DispatchProps, RouteComponentProps<{ key: string }> {}

export interface IPasswordResetFinishState {
  password: string;
  key: string;
}

export class PasswordResetFinishPage extends React.Component<IPasswordResetFinishProps, IPasswordResetFinishState> {
  state: IPasswordResetFinishState = {
    password: '',
    key: getUrlParameter('key', this.props.location.search)
  };

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.resetPasswordSuccess !== this.props.resetPasswordSuccess && nextProps.resetPasswordSuccess) {
      this.props.history.push('/reset/finish-success');
    }
  }

  handleValidSubmit = (event, values) => {
    this.props.handlePasswordResetFinish(this.state.key, values.newPassword);
  };

  updatePassword = event => {
    this.setState({ password: event.target.value });
  };

  getResetForm() {
    return (
      <AvForm onValidSubmit={this.handleValidSubmit}>
        <AvGroup>
          <Label for="newPassword" className="font-medium">
            New password
          </Label>
          <AvField
            name="newPassword"
            placeholder={'New password'}
            type="password"
            size="lg"
            validate={{
              required: { value: true, errorMessage: 'Your password is required.' },
              minLength: { value: 4, errorMessage: 'Your password is required to be at least 4 characters.' },
              maxLength: { value: 50, errorMessage: 'Your password cannot be longer than 50 characters.' }
            }}
            onChange={this.updatePassword}
          />
        </AvGroup>
        <AvGroup>
          <Label for="confirmPassword" className="font-medium">
            New password confirmation
          </Label>
          <AvField
            name="confirmPassword"
            placeholder="Confirm the new password"
            type="password"
            size="lg"
            validate={{
              required: { value: true, errorMessage: 'Your confirmation password is required.' },
              minLength: { value: 4, errorMessage: 'Your confirmation password is required to be at least 4 characters.' },
              maxLength: { value: 50, errorMessage: 'Your confirmation password cannot be longer than 50 characters.' },
              match: { value: 'newPassword', errorMessage: 'The password and its confirmation do not match!' }
            }}
          />
        </AvGroup>
        <Row className="mb-3">
          <Col xs="12">
            <Button color="primary" size="lg" type="submit" block>
              Validate new password
            </Button>
          </Col>
        </Row>
        <Row className="mb-3 text-right">
          <Col xs="12">
            <Link to="/login" className="text-info ml-1">
              <b>Sign In</b>
            </Link>
          </Col>
        </Row>
      </AvForm>
    );
  }

  render() {
    const { key } = this.state;

    return (
      <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
        <div className="auth-box">
          <div id="loginform">
            <div className="logo">
              <h5 className="font-medium mb-3">Reset password</h5>
            </div>
            <Row>
              <Col xs="12">
                {key ? (
                  this.getResetForm()
                ) : (
                  <Alert color="danger">
                    <strong>password reset key not valid</strong> Please use the registration form to sign up.
                  </Alert>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (rootState: IRootState) => ({
  resetPasswordSuccess: rootState.passwordReset.resetPasswordSuccess
});

const mapDispatchToProps = { handlePasswordResetFinish, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetFinishPage);
