import { IAttachmentFile } from 'app/shared/model/attachment-file.model';
import { Moment } from 'moment';

export const enum AttachmentType {
  LOGO = 'LOGO',
  BINDER_PDF = 'BINDER_PDF',
  PIN_IMAGE = 'PIN_IMAGE',
  OFFER_IMAGE = 'OFFER_IMAGE',
  ID_IMAGE = 'ID_IMAGE',
  OTHER_IMAGE = 'OTHER_IMAGE',
  LOG_BOOK_IMAGE = 'LOG_BOOK_IMAGE',
  DRIVING_LICENCE_IMAGE = 'DRIVING_LICENCE_IMAGE',
  VALUATION_REPORT_IMAGE = 'VALUATION_REPORT_IMAGE'
}

export const enum EntityName {
  UNDERWRITER = 'UNDERWRITER',
  BINDER = 'BINDER',
  CLIENT = 'CLIENT',
  COMPANY = 'COMPANY',
  KYC = 'KYC'
}

export interface IAttachment {
  id?: number;
  name?: string;
  type?: AttachmentType;
  entityName?: EntityName;
  entityId?: number;
  path?: string;
  format?: string;
  description?: string;
  dateOfBirth?: Moment;
  issueDate?: Moment;
  countryOfIssue?: string;
  attachmentFileId?: number;
  attachmentFile?: IAttachmentFile;
}

export const defaultValue: Readonly<IAttachment> = {};
