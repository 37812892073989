import { IProductType } from 'app/shared/model/product-type.model';

export interface IShortCodes {
  id?: number;
  shortcode?: string;
  companyId?: number;
  companyName?: string;
  description?: string;
  productTypes?: IProductType[];
}

export const defaultValue: Readonly<IShortCodes> = {};
