import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Row, Col, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';

export interface ILogoutProps extends StateProps, DispatchProps {
  idToken: string;
  logoutUrl: string;
}

export class PasswordResetInitSuccess extends React.Component<ILogoutProps> {
  componentDidMount() {
    // this.props.logout();
  }

  render() {
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <h4>Password Reset Request successful!</h4>
              <h6>Check your emails for details on how to reset your password.</h6>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  // logoutUrl: storeState.authentication.logoutUrl,
  // idToken: storeState.authentication.idToken
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetInitSuccess);
