import React from 'react';
import BlankTemplate from 'app/shared/templates/blank-template';
import Login from 'app/modules/login/login';

const LoginTemplate = props => (
  <BlankTemplate>
    <Login {...props} />
  </BlankTemplate>
);

export default LoginTemplate;
