import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './benefit-details.reducer';

export interface IBenefitDetailsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const BenefitDetailsDetail = (props: IBenefitDetailsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { benefitDetailsEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          BenefitDetails [<b>{benefitDetailsEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{benefitDetailsEntity.name}</dd>
          <dt>
            <span id="productName">Product Name</span>
          </dt>
          <dd>{benefitDetailsEntity.productName}</dd>
          <dt>
            <span id="value">Value</span>
          </dt>
          <dd>{benefitDetailsEntity.value}</dd>
          <dt>Underwriter</dt>
          <dd>{benefitDetailsEntity.underwriterId ? benefitDetailsEntity.underwriterId : ''}</dd>
        </dl>
        <Button tag={Link} to="/entity/benefit-details" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/entity/benefit-details/${benefitDetailsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ benefitDetails }: IRootState) => ({
  benefitDetailsEntity: benefitDetails.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitDetailsDetail);
