import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Row, Col, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import { Link } from 'react-router-dom';

export interface ILogoutProps extends StateProps, DispatchProps {}

export class PasswordResetFinishSuccess extends React.Component<ILogoutProps> {
  render() {
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <h4>Password reset successful!</h4>
                </Col>
                <Col md={3}>
                  <Button outline color="info" tag={Link} to="/login" size="lg" type="submit" block>
                    LOGIN
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetFinishSuccess);
