import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvGroup, AvInput } from 'availity-reactstrap-validation';

const InputItem = ({ label, id, placeholder, type, value, onChange, name }) => (
  <AvGroup>
    <Row>
      <Label sm="4" id={id + 'Label'} for={id}>
        {label}
      </Label>
      <Col sm="8">
        <AvInput id={id} placeholder={placeholder} type={type} value={value} onChange={onChange} name={name} />
        <AvFeedback>This field is required.</AvFeedback>
      </Col>
    </Row>
  </AvGroup>
);

export default InputItem;
