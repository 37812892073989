import React from 'react';
import { connect } from 'react-redux';
import StepZilla from 'react-stepzilla';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import ClientKYCStep from 'app/modules/aki/aki-wizard/client-kyc';
import ClientPolicyStep from 'app/entities/quote/offline-wizard/client-policy';
import ClientCertificateStep from 'app/modules/aki/aki-wizard/client-certificate';
import SelectQuoteStep from 'app/modules/aki/aki-wizard/select-quote';
import SelectClientStep from 'app/modules/aki/aki-wizard/select-client';

export interface IAkiProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export interface IWizardState {
  selectedRiskCategory: number;
  startAtStep: number;
}

class Aki extends React.Component<IAkiProps, IWizardState> {
  constructor(props) {
    super(props);
    const { location } = props;
    let startAtStep = 0;
    if (location.search.indexOf('wizard=2') !== -1) {
      startAtStep = Number(location.search.split('=').pop());
    }
    this.state = {
      startAtStep,
      selectedRiskCategory: 0
    };
  }

  componentDidMount() {}

  updateSelectedRiskCategory = selectedRiskCategory => {
    this.setState({ selectedRiskCategory });
  };

  render() {
    const { startAtStep } = this.state;
    const { match, location } = this.props;
    const steps = [
      {
        name: 'Select Quote',
        component: <SelectQuoteStep id={this.props.match.params.id} />
      },
      {
        name: 'Select Client',
        component: <SelectClientStep />
      },
      {
        name: 'Client KYC',
        component: <ClientKYCStep id={this.props.match.params.id} />
      },
      // {
      //     name: 'Client Policy',
      //     component: <ClientPolicyStep match={match} />
      // },
      {
        name: 'Certificate',
        component: <ClientCertificateStep />
      }
    ];
    return (
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <i className="mdi mdi-border-right mr-2" />
          Sell Policy
        </CardTitle>
        <CardBody>
          <StepZilla showSteps startAtStep={startAtStep} showNavigation={false} stepsNavigation={false} steps={steps} />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ quote }: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Aki);
