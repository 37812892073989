import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PolicyApplication from './policy-application';
import PolicyApplicationDetail from './policy-application-detail';
import PolicyApplicationUpdate from './policy-application-update';
import PolicyApplicationDeleteDialog from './policy-application-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PolicyApplicationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PolicyApplicationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PolicyApplicationDetail} />
      <ErrorBoundaryRoute path={match.url} component={PolicyApplication} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={PolicyApplicationDeleteDialog} />
  </>
);

export default Routes;
