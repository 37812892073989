import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TonnageRange from './tonnage-range';
import TonnageRangeDetail from './tonnage-range-detail';
import TonnageRangeUpdate from './tonnage-range-update';
import TonnageRangeDeleteDialog from './tonnage-range-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TonnageRangeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TonnageRangeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TonnageRangeDetail} />
      <ErrorBoundaryRoute path={match.url} component={TonnageRange} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={TonnageRangeDeleteDialog} />
  </>
);

export default Routes;
