import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RiskClassFormula from './risk-class-formula';
import RiskClassFormulaDetail from './risk-class-formula-detail';
import RiskClassFormulaUpdate from './risk-class-formula-update';
import RiskClassFormulaDeleteDialog from './risk-class-formula-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RiskClassFormulaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RiskClassFormulaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RiskClassFormulaDetail} />
      <ErrorBoundaryRoute path={match.url} component={RiskClassFormula} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={RiskClassFormulaDeleteDialog} />
  </>
);

export default Routes;
