const config = {
  VERSION: process.env.VERSION
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  USER: 'ROLE_USER',
  PARTNER: 'ROLE_PARTNER',
  AGENT: 'ROLE_AGENT',
  DEBITOR: 'ROLE_DEBITOR',
  FINANCE: 'ROLE_FINANCE',
  ACCOUNTANT: 'ROLE_ACCOUNTANT'
};

export const CarBodyTypes = ['HATCHBACK', 'SEDAN', 'SUV', 'COUPE', 'CONVERTIBLE', 'STATION_WAGON', 'VAN'];

export const PAYMENT_METHODS = ['CARD', 'MPESA', 'CASH', 'CHEQUE', 'BANK_TRANSFER'];

export const PAYMENT_SEARCH_PARAMS = [
  { title: 'TRANSACTION_CODE', value: 'TransID' },
  {
    title: 'MSISDN',
    value: 'MSISDN'
  },
  { title: 'TRANSACTION_TIME', value: 'TransTime' },
  { title: 'TRANSACTION_AMOUNT', value: 'TransAmount' },
  { title: 'FIRST_NAME', value: 'FirstName' },
  { title: 'LAST_NAME', value: 'LastName' }
];

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error'
};

export const EMAIL_SHORT_CODES = [
  {
    text: 'Policy NO',
    value: 'policyNumber'
  },
  {
    text: 'First Name',
    value: 'firstName'
  },
  {
    text: 'Last Name',
    value: 'lastName'
  },
  {
    text: 'Mobile',
    value: 'mobile'
  },
  {
    text: 'Email',
    value: 'email'
  },
  {
    text: 'Expiry Date',
    value: 'endDate'
  },
  {
    text: 'Premium Payable',
    value: 'premiumPayable'
  },
  {
    text: 'Underwriter Name',
    value: 'underwriterName'
  }
];

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_STRING_DATE_FORMAT = 'Do MMM YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
