import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import StepZilla from 'react-stepzilla';
import DocumentType from './document-type';
import DocumentIDForm from '../general-form/document-Id';
import DocumentLicenceForm from './document-licence';
import DocumentLogbookForm from './document-logbook';
import DocumentValuationReportForm from './document-valuation-report';
import DocumentOtherForm from '../general-form/document-other';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { setBlob } from 'app/entities/kyc/kyc.reducer';

export interface IRiskClassFormProps extends StateProps, DispatchProps {
  onSubmit;
  updateStateEntity;
}

class RiskClassForm extends React.Component<IRiskClassFormProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {}
    };
  }

  updateStateEntity = values => {
    this.setState(prevState => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        ...values
      }
    }));
  };

  render() {
    const { formValues } = this.state;
    const { updateStateEntity, onSubmit } = this.props;

    const documentForms = {
      ID: <DocumentIDForm onSubmit={onSubmit} />,
      DRIVING_LICENCE: <DocumentLicenceForm onSubmit={onSubmit} />,
      LOG_BOOK: <DocumentLogbookForm onSubmit={onSubmit} />,
      VALUATION_REPORT: <DocumentValuationReportForm onSubmit={onSubmit} />,
      OTHER: <DocumentOtherForm onSubmit={onSubmit} />
    };

    const steps = [
      {
        name: 'Document Type',
        component: <DocumentType formValues={formValues} updateStateEntity={updateStateEntity} updateState={this.updateStateEntity} />
      },
      {
        name: 'Document Details',
        component: (formValues.generalKYCType && documentForms[formValues.generalKYCType]) || <div />
      }
    ];
    return (
      <div className="general-form-container">
        <Row className="justify-content-center">
          <Col sm="12">
            <StepZilla showSteps startAtStep={0} showNavigation={false} stepsNavigation={false} steps={steps} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clientCategories: storeState.clientCategory.entities,
  account: storeState.authentication.account,
  kYCEntity: storeState.kYC.entity
});

const mapDispatchToProps = {
  getClientCategories,
  setBlob
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskClassForm);
