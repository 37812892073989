export const enum ClassificationCategory {
  PRIVATE = 'PRIVATE',
  COMMERCIAL = 'COMMERCIAL',
  PUBLIC_SERVICE_VEHICLE = 'PUBLIC_SERVICE_VEHICLE',
  AFFORDABLE_HEALTH = 'AFFORDABLE_HEALTH',
  STANDARD_HEALTH = 'STANDARD_HEALTH',
  PARENTAL_HEALTH = 'PARENTAL_HEALTH',
  INTERNATIONAL_HEALTH = 'INTERNATIONAL_HEALTH'
}

export interface IRiskClass {
  id?: number;
  name?: string;
  description?: string;
  brokerCommission?: number;
  classificationCategory?: ClassificationCategory;
  riskCategoryId?: number;
  riskCategoryName?: string;
  companyId?: number;
  productTypeId?: number;
  productTypeName?: string;
}

export const defaultValue: Readonly<IRiskClass> = {};
