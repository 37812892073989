import { Moment } from 'moment';

export const enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface IStandardRateDefaultData {
  id?: number;
  rate?: number;
  minimumValue?: number;
  startActiveDate?: Moment;
  endActiveDate?: Moment;
  status?: Status;
  productTypeId?: number;
  riskCategoryId?: number;
  riskClassId?: number;
  coverTypeId?: number;
}

export const defaultValue: Readonly<IStandardRateDefaultData> = {};
