import { IUnderwriter } from 'app/shared/model/underwriter.model';
import { IServiceProviderType } from 'app/shared/model/service-provider-type.model';

export const enum UserType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL'
}

export const enum BranchType {
  HEAD_OFFICE = 'HEAD_OFFICE',
  BRANCH = 'BRANCH'
}

export interface IServiceProvider {
  id?: number;
  name?: string;
  type?: string;
  contactPerson?: string;
  branch?: string;
  rating?: number;
  county?: string;
  division?: string;
  longitude?: number;
  latitude?: number;
  email?: string;
  phone?: string;
  userType?: UserType;
  branchType?: BranchType;
  address?: string;
  streetAddress?: string;
  pinNumber?: string;
  underwriters?: IUnderwriter[];
  serviceProviderTypes?: IServiceProviderType[];
}

export const defaultValue: Readonly<IServiceProvider> = {};
