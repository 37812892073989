import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Label, FormGroup, CustomInput, Col, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity as getQuote, updateEntity } from './quote.reducer';
// tslint:disable-next-line:no-unused-variable
import {
  APP_DATE_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_STRING_DATE_FORMAT,
  AUTHORITIES
} from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import DataTable from 'app/shared/common/dataTable';
import PolicySummary from 'app/entities/quote/quote-update/policy-summary';
import ClientDetails from 'app/entities/quote/quote-update/client-details';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import sortBy from 'lodash/sortBy';
import { getAdditionalBenefits } from 'app/entities/benefit/benefit.reducer';

export interface IQuoteDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class QuoteDetail extends React.Component<IQuoteDetailProps, any> {
  private componentRef = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.state = {
      startAtStep: 0,
      calculatePremium: 0,
      selectedAdditionalBenefits: [],
      actionDate: new Date()
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.props.getQuote(this.props.match.params.id);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { isAdmin, account } = this.props;
    this.setState({ itemsPerPage: state.pageSize });
  }

  getCurrentLogo = quote => {
    const { logoFile } = quote;
    if (logoFile && logoFile.content) {
      return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
    }
    return null;
  };

  handleDateChange = date => {
    this.setState({ actionDate: date });
  };

  saveEntity = async (event, errors, values) => {
    if (errors.length === 0) {
      const { quote, account } = this.props;
      const quoteEntity = {
        ...quote,
        ...values
      };
      const callEntity = {
        audioFile: '',
        callStatusId: values.callStatusId,
        companyId: account.companyId,
        contactNo: values.contactNo,
        notes: values.notes,
        userId: account.id,
        quoteId: quote.id,
        actionTime: moment(this.state.actionDate).format()
      };

      this.props.updateEntity(quoteEntity);
      // @ts-ignore
    }
  };

  render() {
    const { calculatePremium, actionDate } = this.state;
    const { quote, companyId } = this.props;

    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Quote [<b>{quote.id}</b>]
                </CardTitle>
                <CardBody className="p-5 mb-0">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="5">
                          <div className="d-flex align-items-center">
                            <div className="media align-items-center">
                              {this.getCurrentLogo(quote) && (
                                <img
                                  src={this.getCurrentLogo(quote)}
                                  alt="Generic placeholder image"
                                  className="img-fluid mr-2 pr-2 media-object"
                                />
                              )}
                            </div>
                            <div className="d-flex align-items-center flex-fill">
                              <div>
                                <h4 className="font-24 font-light mb-0">Monthly Premium</h4>
                                <h2 className="text-primary font-bold mb-0">{`Ksh. ${String(Math.round(calculatePremium)).replace(
                                  /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                                  '$1,'
                                )}`}</h2>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="d-flex align-items-center content-benefit">
                            <div className="d-flex align-items-center flex-fill justify-content-between">
                              <div className="flex-fill pr-5">
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Risk Class:</h4>
                                  <h4 className="font-bold mb-0">{quote.riskClassName}</h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Cover Type:</h4>
                                  <h4 className="font-bold mb-0">{quote.coverTypeName}</h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Sum Insured:</h4>
                                  <h4 className="font-bold mb-0">
                                    {`Ksh. ${String(quote.sumInsured).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-fill justify-content-between">
                              <div className="flex-fill pr-2">
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Cover Start Date:</h4>
                                  <h4 className="font-bold mb-0">
                                    <TextFormat type="date" value={`${quote.createdAt || null}`} format={APP_LOCAL_STRING_DATE_FORMAT} />
                                  </h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Cover EndDate:</h4>
                                  <h4 className="font-bold mb-0">
                                    {quote.expiryDate && (
                                      <TextFormat type="date" value={`${quote.expiryDate}`} format={APP_LOCAL_STRING_DATE_FORMAT || ''} />
                                    )}
                                  </h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Quote Date:</h4>
                                  <h4 className="font-bold mb-0">
                                    <TextFormat type="date" value={`${quote.createdAt}`} format={APP_LOCAL_STRING_DATE_FORMAT} />
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <ClientDetails />
                    <PolicySummary />
                    <div className="add-benefit-container" ref={this.componentRef}>
                      <>
                        <Card>
                          <CardBody className="p-5">
                            <AvForm model={quote} onSubmit={this.saveEntity}>
                              {companyId === 1 && (
                                <Row className="mt-5 mb-5">
                                  <Col md="12 text-center">
                                    <h4>Call Information</h4>
                                  </Col>
                                </Row>
                              )}

                              {companyId === 1 && (
                                <>
                                  <Row className="mt-5 mb-3">
                                    <Col>
                                      <AvGroup>
                                        <Row>
                                          <Label sm="3" for="contact-no">
                                            Contact No
                                          </Label>
                                          <Col sm="8">
                                            <AvInput
                                              id="contact-no"
                                              type="text"
                                              value={quote.mobile || ''}
                                              placeholder="Contact Number"
                                              className="form-control"
                                              name="contactNo"
                                            />
                                          </Col>
                                        </Row>
                                      </AvGroup>
                                      <AvGroup>
                                        <Row>
                                          <Label sm="3" id="actionDateLabel" for="client-actionDate">
                                            Action Date
                                          </Label>
                                          <Col sm="8">
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                              <KeyboardDateTimePicker
                                                label="Action Date"
                                                value={actionDate}
                                                onChange={this.handleDateChange}
                                                disablePast
                                              />
                                            </MuiPickersUtilsProvider>
                                          </Col>
                                        </Row>
                                      </AvGroup>
                                    </Col>
                                  </Row>
                                  <Row className="pl-3 pr-5 mt-3 mb-3">
                                    <Col md="12 text-right">
                                      {companyId === 1 && (
                                        <Button type="submit" className="btn btn-success">
                                          <i className="fa fa-check" /> Save
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <Row>
                                <Col md="12">
                                  <div>
                                    <h3 className="mb-2">Conversation History</h3>
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="pl-3 pr-5 mt-4 mb-5">
            <Col md="12 text-right">
              <Button tag={Link} to={companyId !== 1 ? '/quotes' : '/entity/quote'} replace color="info">
                <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp; &nbsp;
              {companyId === 1 && (
                <Button tag={Link} to={`/entity/quote/${quote.id}/edit`} replace color="primary">
                  <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  quote: storeState.quote.entity,
  account: storeState.authentication.account,
  companyId: storeState.authentication.account.companyId,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER])
});

const mapDispatchToProps = {
  getQuote,
  getAdditionalBenefits,
  updateEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteDetail);
