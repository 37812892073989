import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { getSortState, ICrudGetAllAction, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './kyc.reducer';
import { IKYC } from 'app/shared/model/kyc.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IKYCProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export type IKYCState = IPaginationBaseState;

export class KYC extends React.Component<IKYCProps, IKYCState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    // this.props.getEntities();
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`companyId=${this.props.account.companyId}`);
    const { account } = this.props;
    this.props.getEntities(state.page, state.pageSize, `${sort},${order}&companyId=${account.companyId}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { kYCList, match } = this.props;
    return (
      <div>
        {/* <h2 id="kyc-heading">
          KYCS
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new KYC
          </Link>
        </h2> */}
        <div className="table-responsive">
          {kYCList && kYCList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Kyc Type</th>
                  {/* <th>General KYC Type</th>
                  <th>Custom KYC Type</th>
                  <th>Motor KYC Type</th>
                  <th>Expiry Date</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Year</th>
                  <th>Chasis Number</th>
                  <th>Engine Number</th>
                  <th>Color</th>
                  <th>Registration</th>
                  <th>Value Of Car</th>
                  <th>Date Of Valuation</th>
                  <th>Valuation Center</th>
                  <th>Car Body Type</th>
                  <th>Client</th> */}
                  <th>Risk Class</th>
                  {/* <th>Attachment</th> */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {kYCList.map((kYC, i) => (
                  <tr key={`entity-${i}`}>
                    {/* <td>
                      <Button tag={Link} to={`${match.url}/${kYC.id}`} color="link" size="sm">
                        {kYC.id}
                      </Button>
                    </td> */}
                    <td>{kYC.kycType}</td>
                    {/* <td>{kYC.generalKYCType}</td>
                    <td>{kYC.customKYCType}</td>
                    <td>{kYC.motorKYCType}</td>
                    <td>
                      <TextFormat type="date" value={kYC.expiryDate} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                    <td>{kYC.make}</td>
                    <td>{kYC.model}</td>
                    <td>{kYC.year}</td>
                    <td>{kYC.chasisNumber}</td>
                    <td>{kYC.engineNumber}</td>
                    <td>{kYC.color}</td>
                    <td>{kYC.registration}</td>
                    <td>{kYC.valueOfCar}</td>
                    <td>
                      <TextFormat type="date" value={kYC.dateOfValuation} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                    <td>{kYC.valuationCenter}</td>
                    <td>{kYC.carBodyType}</td>
                    <td>{kYC.clientId ? <Link to={`client/${kYC.clientId}`}>{kYC.clientId}</Link> : ''}</td> */}
                    <td>{kYC.riskClassId ? <Link to={`risk-class/${kYC.riskClassId}`}>{kYC.riskClassId}</Link> : ''}</td>
                    {/* <td>{kYC.attachmentId ? <Link to={`attachment/${kYC.attachmentId}`}>{kYC.attachmentId}</Link> : ''}</td> */}
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/entity/kyc/${kYC.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                        </Button>
                        <Button tag={Link} to={`/entity/kyc/${kYC.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                        </Button>
                        <Button tag={Link} to={`/entity/kyc/${kYC.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">No KYCS found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ kYC, authentication }: IRootState) => ({
  kYCList: kYC.entities,
  totalItems: kYC.totalItems,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYC);
