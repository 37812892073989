import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CarOption from './car-option';
import CarOptionDetail from './car-option-detail';
import CarOptionUpdate from './car-option-update';
import CarOptionDeleteDialog from './car-option-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CarOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CarOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CarOptionDetail} />
      <ErrorBoundaryRoute path={match.url} component={CarOption} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CarOptionDeleteDialog} />
  </>
);

export default Routes;
