export const enum Status {
  ACTIVE = 'ACTIVE',
  PROSPECTIVE = 'PROSPECTIVE',
  FORMER = 'FORMER'
}

export interface IClient {
  id?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  clientName?: string;
  ledgerName?: string;
  clientPrintName?: string;
  idNumber?: string;
  contactPersion?: string;
  telephone?: string;
  mobile?: string;
  email?: string;
  address?: string;
  streetAddress?: string;
  county?: string;
  country?: string;
  pinNumber?: string;
  notes?: string;
  status?: Status;
  categoryId?: number;
  categoryName?: string;
  titleId?: number;
  idTypeId?: number;
  createdAt?: Date;
  companyId?: number;
  clientPolicies?: any[];
  partner?: any;
}

export const defaultValue: Readonly<IClient> = {};
