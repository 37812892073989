import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { sortBy, isEmpty } from 'lodash';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getCoverAreas } from 'app/entities/cover-area/cover-area.reducer';
import { getEntities as getBenefitTypes } from 'app/entities/benefit-type/benefit-type.reducer';
import { getEntities as getUnderwriterProducts } from 'app/entities/underwriter-product/underwriter-product.reducer';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getBenefitCategories } from 'app/entities/benefit-category/benefit-category.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntities as getAgeRanges } from 'app/entities/age-range/age-range.reducer';
import { getEntity, updateEntity, createEntity, reset } from './benefit.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { getEntities as getProductTypes, setProductType } from 'app/entities/product-type/product-type.reducer';

// tslint:disable-next-line:no-unused-variable

export interface IBenefitUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IBenefitUpdateState {
  productTypeId: string;
  isNew: boolean;
  benefitTypeId: string;
  underwriterId: string;
  riskClassId: string;
  coverTypeId: string;
  benefitCategory: string;
  multiple: boolean;
}

export class BenefitUpdate extends React.Component<IBenefitUpdateProps, IBenefitUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      multiple: false,
      productTypeId: '0',
      benefitTypeId: '0',
      underwriterId: '0',
      riskClassId: '0',
      coverTypeId: '0',
      benefitCategory: 'INPATIENT',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    const { log } = console;
    const { companyId } = this.props.account;
    if (this.state.isNew) {
      this.props.reset();
    } else {
      Promise.resolve(this.props.getEntity(this.props.match.params.id))
        .then(res => {
          // @ts-ignore
          const { data } = res.value;
          const { productTypeName, underwriterId, multiple, productTypeId } = data;
          this.setState({
            underwriterId: String(underwriterId),
            multiple,
            productTypeId: String(productTypeId)
          });
          this.props.getRiskClasses(0, 2000, `,&companyId=${companyId}&riskCategoryName=${productTypeName}`);
          this.props.getUnderwriterProducts(0, 2000, `,&companyId=${companyId}&underwriterId=${underwriterId}`);
        })
        .catch(err => log(err));
    }
    this.props.getProductTypes(0, 10, `name,asc&companyId=${companyId}`);
    this.props.getCoverTypes(0, 2000, `,&companyId=${companyId}`);
    this.props.getBenefitTypes(0, 2000, `,&companyId=${companyId}`);
    this.props.getUnderwriters(0, 100, `,&companyId=${companyId}`);
    this.props.getBenefitCategories(0, 20, `,&companyId=${companyId}`);
    this.props.getCoverAreas(0, 2000, `,&companyId=${companyId}`);
    this.props.getAgeRanges(0, 2000, `minValue&sort=maxValue&companyId=${companyId}`);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { benefitEntity, account } = this.props;
      const { multiple } = this.state;
      const entity = {
        ...benefitEntity,
        ...values,
        companyId: account.companyId,
        multiple
      };
      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleChangeProductType = id => {
    const { productTypes, account } = this.props;
    const productType = productTypes.find(item => item.id === Number(id));
    this.setState({ productTypeId: String(productType.id) });
    productType.name === 'General' && this.props.getRiskClasses(0, 2000, `,&companyId=${account.companyId}&riskCategoryName=Motor`);
    productType.name === 'Health' && this.props.getRiskClasses(0, 2000, `,&companyId=${account.companyId}&riskCategoryName=Health`);
    productType.name === 'Medical' && this.props.getRiskClasses(0, 2000, `,&companyId=${account.companyId}&riskCategoryName=Medical`);
    const { log } = console;
    log(this.state.productTypeId);
  };

  handleChangeRiskClass = id => {
    this.setState({ riskClassId: '' + id });
  };

  handleMultipleChange = multiple => {
    this.setState({ multiple });
  };

  handleChangeUnderwriter = id => {
    const { account } = this.props;
    const { riskClassId, productTypeId, benefitCategory } = this.state;
    if (productTypeId && productTypeId !== '0') {
      this.props.getUnderwriterProducts(
        0,
        2000,
        `,&companyId=${account.companyId}&productTypeId=${productTypeId}
      &queryParams=riskClassId=${riskClassId},underwriterId=${id},levelCategory=${benefitCategory}`
      );
    }
  };

  handleClose = () => {
    this.props.history.replace('/entity/benefit');
  };

  render() {
    const {
      benefitEntity,
      benefitTypes,
      underwriters,
      riskClasses,
      coverTypes,
      productTypes,
      loading,
      updating,
      underwriterProducts,
      coverAreas,
      benefitCategories,
      ageRanges
    } = this.props;
    const { isNew, riskClassId, productTypeId, multiple } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a Benefit
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : benefitEntity} onSubmit={this.saveEntity}>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="productTypeLabel" for="benefit-productType">
                                Product Type
                              </Label>
                              <Col sm="9">
                                <AvInput
                                  id="benefit-productTypeId"
                                  type="select"
                                  className="form-control"
                                  onChange={event => this.handleChangeProductType(event.target.value)}
                                  name="productTypeId"
                                  value={benefitEntity.productTypeId || ''}
                                >
                                  <option value="" key="0">
                                    Select Product Type
                                  </option>
                                  {productTypes
                                    ? productTypes.map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {otherEntity.name}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      {productTypeId !== '0' && productTypeId !== '3' && (
                        <>
                          <Row>
                            <Col md="6">
                              <AvGroup>
                                <Row>
                                  <Label sm="3" for="benefit-benefitType">
                                    Benefit Type
                                  </Label>
                                  <Col sm="9">
                                    <AvInput
                                      id="benefit-benefitType"
                                      type="select"
                                      value={benefitEntity.benefitTypeId || ''}
                                      className="form-control"
                                      name="benefitTypeId"
                                    >
                                      <option value="" key="0" />
                                      {benefitTypes
                                        ? sortBy(benefitTypes, ['name']).map(otherEntity => (
                                            <option value={otherEntity.id} key={otherEntity.id}>
                                              {otherEntity.name || ''}
                                            </option>
                                          ))
                                        : null}
                                    </AvInput>
                                  </Col>
                                </Row>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <AvGroup>
                                <Row>
                                  <Label sm="3" id="benefitRateLabel" for="benefit-benefitRate">
                                    Benefit Rate
                                  </Label>
                                  <Col sm="9">
                                    <AvInput
                                      id="benefit-benefitRate"
                                      type="select"
                                      className="form-control"
                                      name="benefitRate"
                                      value={(!isNew && benefitEntity.benefitRate) || 'FIXED'}
                                    >
                                      <option value="FIXED">FIXED</option>
                                      <option value="PERCENTAGE">PERCENTAGE</option>
                                    </AvInput>
                                  </Col>
                                </Row>
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Row>
                                  <Label sm="3" for="cover-type-coverType">
                                    Cover Type
                                  </Label>
                                  <Col sm="9">
                                    <AvInput
                                      id="cover-type-coverType"
                                      type="select"
                                      value={benefitEntity.coverTypeId || ''}
                                      className="form-control"
                                      name="coverTypeId"
                                    >
                                      <option key="0" value="">
                                        Select Cover Type
                                      </option>
                                      {coverTypes
                                        ? sortBy(coverTypes, ['name']).map(otherEntity => (
                                            <option value={otherEntity.id} key={otherEntity.id}>
                                              {otherEntity.name}
                                            </option>
                                          ))
                                        : null}
                                    </AvInput>
                                  </Col>
                                </Row>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <AvGroup>
                                <Row>
                                  <Label sm="3" id="benefitChargeTypeLabel" for="benefit-benefitChargeType">
                                    Benefit Charge Type
                                  </Label>
                                  <Col sm="9">
                                    <AvInput
                                      id="benefit-benefitChargeType"
                                      type="select"
                                      className="form-control"
                                      name="benefitChargeType"
                                      value={(!isNew && benefitEntity.benefitChargeType) || 'FIXED'}
                                    >
                                      <option value="FIXED">FIXED</option>
                                      <option value="PERCENTAGE">PERCENTAGE</option>
                                    </AvInput>
                                  </Col>
                                </Row>
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Row>
                                  <Label sm="3" id="minimumValueLabel" for="benefit-minimumValue">
                                    Minimum Value
                                  </Label>
                                  <Col sm="9">
                                    <AvField id="benefit-minimumValue" type="string" className="form-control" name="minimumValue" />
                                  </Col>
                                </Row>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <AvGroup>
                                <Row>
                                  <Label sm="3" id="benefitChargeValueLabel" for="benefit-benefitChargeValue">
                                    Benefit Charge Value
                                  </Label>
                                  <Col sm="9">
                                    <AvField
                                      id="benefit-benefitChargeValue"
                                      type="number"
                                      className="form-control"
                                      name="benefitChargeValue"
                                    />
                                  </Col>
                                </Row>
                              </AvGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" for="cover-type-riskClass">
                                Risk Class
                              </Label>
                              <Col sm="9">
                                <AvInput
                                  id="cover-type-riskClass"
                                  type="select"
                                  className="form-control"
                                  onChange={event => this.handleChangeRiskClass(event.target.value)}
                                  name="riskClassId"
                                  value={(!isNew && benefitEntity.riskClassId) || ''}
                                  required
                                >
                                  <option key="0" value="">
                                    Select Risk Class
                                  </option>
                                  {riskClasses
                                    ? sortBy(riskClasses, ['name']).map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {otherEntity.name}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                                <AvFeedback>This field is required.</AvFeedback>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="nameLabel" for="benefit-name">
                                Name
                              </Label>
                              <Col sm="9">
                                <AvField
                                  id="benefit-name"
                                  type="text"
                                  name="name"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" for="benefit-underwriter">
                                Underwriter
                              </Label>
                              <Col sm="9">
                                <AvInput
                                  id="benefit-underwriter"
                                  type="select"
                                  className="form-control"
                                  onChange={event => this.handleChangeUnderwriter(event.target.value)}
                                  name="underwriterId"
                                  value={benefitEntity.underwriterId || ''}
                                >
                                  <option value="" key="0" />
                                  {underwriters
                                    ? underwriters.map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {otherEntity.name}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="benefitValueLabel" for="benefit-benefitValue">
                                Benefit Value
                              </Label>
                              <Col sm="9">
                                <AvField id="benefit-benefitValue" type="number" className="form-control" name="benefitValue" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="startDateLabel" for="client-policy-startDate">
                                Start Date
                              </Label>
                              <Col sm="9">
                                <AvField id="client-policy-startDate" type="date" className="form-control" name="startDate" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="endDateLabel" for="client-policy-endDate">
                                End Date
                              </Label>
                              <Col sm="9">
                                <AvField id="client-policy-endDate" type="date" className="form-control" name="endDate" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Col sm="12">
                            <AvField id="benefit-description" type="textarea" rows={3} placeholder="Description" name="description" />
                          </Col>
                        </Col>
                      </Row>
                      <FormGroup className="mt-3">
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/benefit" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  ageRanges: storeState.ageRange.entities,
  coverAreas: storeState.coverArea.entities,
  benefitTypes: storeState.benefitType.entities,
  productTypes: storeState.productType.entities,
  underwriters: storeState.underwriter.entities,
  riskClasses: storeState.riskClass.entities,
  coverTypes: storeState.coverType.entities,
  benefitCategories: storeState.benefitCategory.entities,
  benefitEntity: storeState.benefit.entity,
  underwriterProducts: storeState.underwriterProduct.entities,
  productTypeEntity: storeState.productType.entity,
  loading: storeState.benefit.loading,
  updating: storeState.benefit.updating,
  updateSuccess: storeState.benefit.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getAgeRanges,
  getBenefitTypes,
  getBenefitCategories,
  getCoverAreas,
  getUnderwriterProducts,
  getUnderwriters,
  getProductTypes,
  getRiskClasses,
  getCoverTypes,
  getEntity,
  setProductType,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitUpdate);
