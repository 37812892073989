import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UnderwriterProduct from './underwriter-product';
import UnderwriterProductDetail from './underwriter-product-detail';
import UnderwriterProductUpdate from './underwriter-product-update';
import UnderwriterProductDeleteDialog from './underwriter-product-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UnderwriterProductUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UnderwriterProductUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UnderwriterProductDetail} />
      <ErrorBoundaryRoute path={match.url} component={UnderwriterProduct} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UnderwriterProductDeleteDialog} />
  </>
);

export default Routes;
