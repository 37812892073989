import React from 'react';
import { getSession } from 'app/shared/reducers/authentication';
import { getReports } from 'app/modules/reports/reducers/reports.reducer';
import { getEntities as getEarnings } from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import { getEntities as getTransactions } from 'app/entities/transactions/transactions.reducer';
import { Card, Row, Col, Label, TabContent, TabPane, CardSubtitle, CardTitle, FormGroup, CardBody, Input, Table, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { size } from 'lodash';
import { Line } from 'react-chartjs-2';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { Link, RouteComponentProps } from 'react-router-dom';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import AgentTab from './agent-tab';
import DataTable from 'app/shared/common/dataTable';
import 'c3/c3.css';
import { IRootState } from 'app/shared/reducers';

export interface IAgentHomeProp extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IAgentHomeState extends IPaginationBaseState {
  activeTab: number;
}

const lineData = {
  labels: [1, 2, 3, 4, 5, 6, 7, 8],
  datasets: [
    {
      label: 'Income',
      borderWidth: 1,
      backgroundColor: 'rgba(94,114,228,.1)',
      borderColor: 'rgb(94,114,228)',
      pointBorderColor: 'rgb(94,114,228)',
      pointBackgroundColor: 'rgb(94,114,228)',
      data: [0, 15, 6, 11, 25, 9, 18, 24]
    },
    {
      label: 'Outcome',
      borderWidth: 1,
      backgroundColor: 'rgba(79,195,247,.1)',
      borderColor: 'rgb(79,195,247)',
      pointBorderColor: 'rgb(79,195,247)',
      pointBackgroundColor: 'rgb(79,195,247)',
      data: [0, 8, 11, 22, 8, 10, 5, 21]
    }
  ]
};

export class AgentHome extends React.Component<IAgentHomeProp, IAgentHomeState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE, 'createdAt', 'desc'),
      activeTab: 1
    };
    this.fetchData = this.fetchData.bind(this);
    this.fetchTransactionsData = this.fetchTransactionsData.bind(this);
  }

  componentDidMount() {
    this.props.getSession();
    const { account, isAuthenticated, reports } = this.props;
    if (isAuthenticated) {
      this.props.getReports({ companyId: account.companyId });
    }
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { companyId, id } = this.props.account;
    filtered.push(`companyId=${companyId}`);
    filtered.push(`agentId=${id}`);
    this.props.getEarnings(state.page, state.pageSize, `${sort || 'createdAt'},${order || 'desc'}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  fetchTransactionsData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { companyId, id } = this.props.account;
    filtered.push(`companyId=${companyId}`);
    filtered.push(`agentId=${id}`);
    this.props.getTransactions(state.page, state.pageSize, `${sort || 'createdAt'},${order || 'desc'}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  handleChangePeriod = value => {
    // console.log(value);
  };

  handleTab = selectedTab => {
    this.setState({ activeTab: selectedTab });
    const { companyId, id } = this.props.account;
    const { activePage, itemsPerPage } = this.state;
    if (selectedTab === 1) {
      this.props.getEarnings(activePage, itemsPerPage, `createdAt,desc&companyId=${companyId}&agentId=${id}`);
    } else if (selectedTab === 2) {
      this.props.getTransactions(activePage, itemsPerPage, `createdAt,desc&companyId=${companyId}&agentId=${id}`);
    }
  };

  renderCreateButton() {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 11:
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Policy
          </Link>
        );
      case 21:
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Claim
          </Link>
        );
      case 31:
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Invoice
          </Link>
        );
      case 5:
        return (
          <Link to={`/entity/kyc/new`} color="success" className="btn btn-create-new">
            Create New KYC Document
          </Link>
        );
      default:
        return <div className="btn btn-create-new visible-hidden">default button</div>;
    }
  }

  render() {
    const { account, reports, transactions, earnings } = this.props;
    const { activeTab } = this.state;

    const earningsData = earnings.map(prop => ({
      ...prop,
      date: <TextFormat type="date" value={`${prop.payDate}`} format={APP_LOCAL_DATE_FORMAT} />,
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={Link} to={`/entity/client-policy/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
        </div>
      )
    }));

    const statementsData = transactions.map(prop => ({
      ...prop,
      date: <TextFormat type="date" value={`${prop.transTime}`} format={APP_LOCAL_DATE_FORMAT} />,
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={Link} to={`/entity/client-policy/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
        </div>
      )
    }));

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="d-md-flex">
              <div>
                <h4 className="mb-1 card-title">Insurance Sale Summaries</h4>
                <h6 className="mb-0 mt-0 font-16 font-light card-subtitle">Overview of Current Month</h6>
              </div>
              <div className="ml-auto d-flex no-block align-items-center">
                <div className="dl">
                  <Input type="select" className="custom-select" onChange={event => this.handleChangePeriod(event.target.value)}>
                    <option value="MONTH">Monthly</option>
                    <option value="DAY">Daily</option>
                    <option value="WEEK">Weekly</option>
                    <option value="YEAR">Yearly</option>
                  </Input>
                </div>
              </div>
            </div>
            <Row>
              <Col lg="4" className="mt-4">
                <h1 className="mb-0 mt-4">Ksh. {reports.currentEarnings || '0.00'}</h1>
                <h6 className="font-light text-muted">Current Month Commission</h6>
                <h3 className="mt-4 mb-0">{reports.currentClaims || '0.00'}</h3>
                <h6 className="font-light text-muted">Current Month Premium Sold</h6>
                <a className="btn btn-info my-3 p-3 px-4" href="#">
                  Last Month Summary
                </a>
              </Col>
              <Col lg="8">
                <div className="campaign ct-charts">
                  <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 250 }}>
                    <Line
                      data={lineData}
                      options={{
                        maintainAspectRatio: false,
                        legend: { display: false, labels: { fontFamily: 'Nunito Sans' } },
                        scales: {
                          yAxes: [
                            {
                              stacked: true,
                              gridLines: { display: false },
                              ticks: { fontFamily: 'Nunito Sans' }
                            }
                          ],
                          xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: 'Nunito Sans' } }]
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border-top pt-4 pb-5">
            <Row className="mb-0">
              {/*{*/}
              {/*  !isPartner && (*/}
              {/*    <Col lg="3" md="6">*/}
              {/*      <div className="d-flex align-items-center">*/}
              {/*        <div className="mr-3">*/}
              {/*    <span className="display-5 d-flex text-muted">*/}
              {/*      <i className="mdi mdi-cellphone-iphone"/>*/}
              {/*    </span>*/}
              {/*        </div>*/}
              {/*        <div className="d-flex flex-column justify-content-center">*/}
              {/*          <span>Mobile Money</span>*/}
              {/*          <h4 className="font-bold mb-0">Ksh. 982,112</h4>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </Col>*/}
              {/*  )*/}
              {/*}*/}
              {/*{*/}
              {/*  !isPartner && (*/}
              {/*    <Col lg="3" md="6">*/}
              {/*      <div className="d-flex align-items-center">*/}
              {/*        <div className="mr-3">*/}
              {/*    <span className="display-5 d-flex text-muted">*/}
              {/*      <i className="mdi mdi-credit-card-multiple"/>*/}
              {/*    </span>*/}
              {/*        </div>*/}
              {/*        <div className="d-flex flex-column justify-content-center">*/}
              {/*          <span>Card Payments</span>*/}
              {/*          <h4 className="font-bold mb-0">Ksh. 432,670</h4>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </Col>*/}
              {/*  )*/}
              {/*}*/}
              <Col lg="3" md="6">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-credit-card-multiple" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>AGENT CODE</span>
                    <h4 className="font-bold mb-0">{account.agentCode}</h4>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-account-multiple" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>NAME</span>
                    <h4 className="font-bold mb-0">{`${account.firstName} ${account.lastName}`}</h4>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-account-plus" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>MOBILE</span>
                    <h4 className="font-bold mb-0">{account.mobile || ''}</h4>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-gold" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>AGENT RANK</span>
                    <h4 className="font-bold mb-0">Bronze</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="9">
                <AgentTab handleTab={this.handleTab} />
              </Col>
              <Col sm="3" className="text-right">
                {this.renderCreateButton()}
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <TabContent activeTab={String(activeTab)}>
              <TabPane tabId="1">
                <DataTable
                  columns={[
                    {
                      Header: 'DATE',
                      accessor: 'policyName'
                    },
                    {
                      Header: 'PURCHASING PHONE',
                      accessor: 'invoiceNo'
                    },
                    {
                      Header: 'PRODUCT',
                      accessor: 'startDate'
                    },
                    {
                      Header: 'AMOUNT',
                      accessor: 'endDate'
                    },
                    {
                      Header: 'COMMISSION',
                      accessor: 'paymentStatus'
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  noDataText="No Earnings to display."
                  tableData={earningsData}
                  filterable
                />
              </TabPane>
              <TabPane tabId="2">
                <DataTable
                  columns={[
                    {
                      Header: 'DATE',
                      accessor: 'brokerRefNo'
                    },
                    {
                      Header: 'TRANS ID',
                      accessor: 'brokerClaimNo'
                    },
                    {
                      Header: 'AMOUNT',
                      accessor: 'policyNo'
                    },
                    {
                      Header: 'MODE',
                      accessor: 'insurerClaimNo'
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  noDataText="No Transactions found."
                  tableData={statementsData}
                  filterable
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authentication, reports, transactions, clientPolicyPayment }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,
  reports: reports.entity,
  transactions: transactions.entities,
  earnings: clientPolicyPayment.entities
});

const mapDispatchToProps = { getSession, getReports, getEarnings, getTransactions };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentHome);
