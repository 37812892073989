import React, { useEffect } from 'react';

import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, ListGroup, ListGroupItem } from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

const EmailCampaign = ({ reports }) => {
  useEffect(() => {}, []);
  return (
    <Card className="card-hover">
      <CardBody>
        <div className="d-md-flex align-items-center">
          <div>
            <CardTitle>Product Uptake Ratio</CardTitle>
            <CardSubtitle>Product Uptake Ratio</CardSubtitle>
          </div>
          <div className="ml-auto align-items-center">
            <div className="dl">
              <Input type="select" className="custom-select">
                <option value="0">Monthly</option>
                <option value="1">Daily</option>
                <option value="2">Weekly</option>
                <option value="3">Yearly</option>
              </Input>
            </div>
          </div>
        </div>
        <Row className="mt-4">
          <Col lg="6">
            <div>
              <Chart
                style={{ height: '290px', width: '100%' }}
                config={{
                  data: {
                    columns: [(reports.productUptakeRatio && reports.productUptakeRatio.map(u => [u.productName, u.value])) || []],

                    type: 'donut',
                    tooltip: {
                      show: true
                    }
                  },
                  donut: {
                    label: {
                      show: false
                    },
                    title: 'Ratio',
                    width: 35
                  },
                  legend: {
                    hide: true
                  },
                  color: {
                    pattern: ['#40c4ff', '#2961ff', '#ff821c', '#7e74fb']
                  }
                }}
              />
            </div>
          </Col>
          <Col lg="6">
            <h1 className="display-6 mb-0 font-medium">
              {reports.productUptakeRatio && reports.productUptakeRatio.length
                ? Math.max(...reports.productUptakeRatio.map(o => o.value))
                : 0}{' '}
              %
            </h1>
            <span>Highest Product Uptake</span>
            <ListGroup>
              {reports.productUptakeRatio &&
                reports.productUptakeRatio.map(u => (
                  <ListGroupItem className="border-0 mt-3 p-0" key={u.productName}>
                    <i className="fas fa-circle mr-1 text-cyan font-12" /> {u.productName || ''}{' '}
                    <span className="float-right">{u.value} %</span>
                  </ListGroupItem>
                ))}
            </ListGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default EmailCampaign;
