import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './customer-authentication.reducer';
import { ICustomerAuthentication } from 'app/shared/model/customer-authentication.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICustomerAuthenticationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CustomerAuthenticationDetail = (props: ICustomerAuthenticationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { customerAuthenticationEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          CustomerAuthentication [<b>{customerAuthenticationEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="mobile">Mobile</span>
          </dt>
          <dd>{customerAuthenticationEntity.mobile}</dd>
          <dt>
            <span id="email">Email</span>
          </dt>
          <dd>{customerAuthenticationEntity.email}</dd>
          <dt>
            <span id="idNumber">Id Number</span>
          </dt>
          <dd>{customerAuthenticationEntity.idNumber}</dd>
          <dt>
            <span id="firstName">First Name</span>
          </dt>
          <dd>{customerAuthenticationEntity.firstName}</dd>
          <dt>
            <span id="middleName">Middle Name</span>
          </dt>
          <dd>{customerAuthenticationEntity.middleName}</dd>
          <dt>
            <span id="lastName">Last Name</span>
          </dt>
          <dd>{customerAuthenticationEntity.lastName}</dd>
          <dt>
            <span id="isVerified">Is Verified</span>
          </dt>
          <dd>{customerAuthenticationEntity.isVerified ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/customer-authentication" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/customer-authentication/${customerAuthenticationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ customerAuthentication }: IRootState) => ({
  customerAuthenticationEntity: customerAuthentication.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAuthenticationDetail);
