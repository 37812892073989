export interface ICarOptionValue {
  id?: number;
  isBase?: boolean;
  carOptionId?: number;
  carTypeId?: number;
  carEquipmentId?: number;
}

export const defaultValue: Readonly<ICarOptionValue> = {
  isBase: false
};
