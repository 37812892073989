import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import profilephoto from '../../../assets/images/users/1.jpg';

const AccountMenuItemsAuthenticated = ({ account }) => {
  const ImageURL = account.imageUrl ? account.imageUrl : profilephoto;
  return (
    <DropdownMenu right className="user-dd">
      <span className="with-arrow">
        <span className="bg-primary" />
      </span>
      <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
        <div className="">
          <img src={ImageURL} alt="user" className="rounded-circle" width="60" />
        </div>
        <div className="ml-2">
          <h4 className="mb-0">{account.firstName}</h4>
          <p className=" mb-0">{account.email}</p>
        </div>
      </div>
      <MenuItem icon="wrench" to="/account/settings">
        Settings
      </MenuItem>
      <DropdownItem divider />
      <MenuItem icon="lock" to="/account/password">
        Password
      </MenuItem>
      <DropdownItem divider />
      <MenuItem icon="sign-out-alt" to="/logout">
        Sign out
      </MenuItem>
    </DropdownMenu>
  );
};

const accountMenuItems = (
  <DropdownMenu right className="user-dd">
    <span className="with-arrow">
      <span className="bg-primary" />
    </span>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login">
      Sign in
    </MenuItem>
    <DropdownItem divider />
    <MenuItem icon="sign-in-alt" to="/register">
      Register
    </MenuItem>
  </DropdownMenu>
);

export const AccountMenu = ({ isAuthenticated = false, account }) => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret className="pro-pic">
      <img src={profilephoto} alt="user" className="rounded-circle" width="31" />
    </DropdownToggle>
    {isAuthenticated ? <AccountMenuItemsAuthenticated account={account} /> : accountMenuItems}
  </UncontrolledDropdown>
);

export default AccountMenu;
