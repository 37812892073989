import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AttachmentFile from './attachment-file';
import AttachmentFileDetail from './attachment-file-detail';
import AttachmentFileUpdate from './attachment-file-update';
import AttachmentFileDeleteDialog from './attachment-file-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AttachmentFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AttachmentFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AttachmentFileDetail} />
      <ErrorBoundaryRoute path={match.url} component={AttachmentFile} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AttachmentFileDeleteDialog} />
  </>
);

export default Routes;
