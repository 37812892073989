import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RiskClassDefaultData from './risk-class-default-data';
import RiskClassDefaultDataDetail from './risk-class-default-data-detail';
import RiskClassDefaultDataUpdate from './risk-class-default-data-update';
import RiskClassDefaultDataDeleteDialog from './risk-class-default-data-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RiskClassDefaultDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RiskClassDefaultDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RiskClassDefaultDataDetail} />
      <ErrorBoundaryRoute path={match.url} component={RiskClassDefaultData} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={RiskClassDefaultDataDeleteDialog} />
  </>
);

export default Routes;
