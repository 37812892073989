import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  CREATE_ACCOUNT: 'register/CREATE_ACCOUNT',
  REGISTER_INIT: 'register/REGISTER_INIT',
  REGISTER_FINISH: 'register/REGISTER_FINISH',
  RESET: 'register/RESET'
};

const initialState = {
  loading: false,
  registrationInitSuccess: false,
  registrationInitFailure: false,
  registrationSuccess: false,
  registrationFailure: false,
  errorMessage: null
};

export type RegisterState = Readonly<typeof initialState>;

// Reducer
export default (state: RegisterState = initialState, action): RegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ACCOUNT):
    case REQUEST(ACTION_TYPES.REGISTER_INIT):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationFailure: true,
        errorMessage: action.payload.response.data.errorKey
      };
    case FAILURE(ACTION_TYPES.REGISTER_INIT):
      return {
        ...initialState,
        registrationInitFailure: true,
        errorMessage: action.payload.response.data.errorKey
      };
    case FAILURE(ACTION_TYPES.REGISTER_FINISH):
      return {
        ...initialState,
        registrationFailure: true,
        errorMessage: action.payload.response.data.errorKey
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationSuccess: true
      };
    case SUCCESS(ACTION_TYPES.REGISTER_INIT):
      return {
        ...initialState,
        registrationInitSuccess: true
      };
    case SUCCESS(ACTION_TYPES.REGISTER_FINISH):
      return {
        ...initialState,
        registrationSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions
export const handleRegister = (login, email, password, contactNo, companyName, defaultData, langKey = 'en') => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ACCOUNT,
    payload: axios.post('api/register', {
      login,
      email,
      password,
      contactNo,
      companyName,
      langKey,
      defaultData
    }),
    meta: {
      successMessage: '<strong>Registration saved!</strong> Please check your email for confirmation.'
    }
  });
  return result;
};

export const handleRegisterInit = (login, email, contactNo) => ({
  type: ACTION_TYPES.REGISTER_INIT,
  // If the content-type isn't set that way, axios will try to encode the body and thus modify the data sent to the server.
  payload: axios.post(`api/register/init`, {
    login,
    email,
    contactNo
  }),
  meta: {
    successMessage: 'Check your emails for details on how to verify your email.',
    errorMessage: "<strong>Email address isn't registered!</strong> Please check and try again"
  }
});

export const handleRegisterFinish = data => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.REGISTER_FINISH,
    payload: axios.post('api/register/finish', data),
    meta: {
      successMessage: '<strong>You have successfully completed verification!</strong> Please login to continue.'
    }
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
