import React from 'react';

import { connect } from 'react-redux';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Label, Button } from 'reactstrap';
import { PDFViewer } from '@react-pdf/renderer';
import { IRootState } from 'app/shared/reducers';
import { createEntity as createDebitNote, updateEntity } from 'app/entities/debit-note/debit-note.reducer';
import { DebitType } from 'app/shared/model/debit-note.model';
import DebitNote from 'app/entities/direct-payment/debit-wizard/debit-note';
import { updateEntity as updatePayment } from 'app/entities/direct-payment/direct-payment.reducer';
import { createEntity as createInvoice } from 'app/entities/invoice/invoice.reducer';
import { createEntity as createClientPolicyPayment } from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import moment from 'moment';
import { Platform, TransactionStatus } from 'app/shared/model/client-policy-payment.model';
import { NavLink } from 'react-router-dom';
import utils from 'app/config/utils';
import { DebitStatus, RemitStatus } from 'app/shared/model/payment-callback.model';
import PDFPreview from 'app/scenes/pdf-preview';

export interface IDebitNoteStepProps extends StateProps, DispatchProps {
  jumpToStep;
}

export interface IDebitNoteState {
  errorMessage: string;
  showPreview: boolean;
  remarks: string;
}

class DebitNoteStep extends React.Component<IDebitNoteStepProps, IDebitNoteState> {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      showPreview: false,
      remarks: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {}

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      // this.props.jumpToStep(1);
    }
  }

  async handleSubmit(event, errors, values) {
    const { kYCEntity, clientPolicyEntity, clientEntity, directPayment } = this.props;
    if (errors.length === 0) {
      const debitNoteEntity = {
        remarks: values.narration,
        debitType: DebitType.DEBIT,
        clientPolicyId: clientPolicyEntity.id,
        productTypeId: clientPolicyEntity.productTypeId,
        clientId: clientEntity.id,
        kycId: kYCEntity.id
      };
      await this.props.createDebitNote(debitNoteEntity);
      this.setState({ showPreview: true });
      const invoiceEntity = {
        description: this.state.remarks,
        companyId: clientPolicyEntity.companyId,
        date: moment(),
        // @ts-ignore
        invoiceAmount: directPayment.transAmount,
        clientId: this.props.clientEntity.id,
        clientPoliciesIds: [this.props.clientPolicyEntity.id]
      };
      await this.props.createInvoice(invoiceEntity);
      const { invoiceNo, id } = this.props.invoiceEntity;
      const paymentEntity = {
        ...directPayment,
        debitNoteId: this.props.debitNoteEntity.id,
        debitStatus: DebitStatus.DEBITED,
        remitStatus: RemitStatus.REMITTED,
        invoiceId: id,
        invoiceNo
      };
      const policyPayment = {
        // @ts-ignore
        payDate: utils.getDateFromString(directPayment.transTime),
        // @ts-ignore
        amount: directPayment.transAmount,
        // invoiceReceipt: this.props.invoiceEntity.id,
        // @ts-ignore
        paymentConfirmationNumber: directPayment.transID,
        // deviceId: string;
        // ipAddress: string;
        transactionStatus: TransactionStatus.SUCCESS,
        paymentMethod: 'MPESA',
        platform: Platform.MOBILE_APP,
        isIPF: false,
        clientPolicyId: this.props.clientPolicyEntity.id,
        debitNoteId: this.props.debitNoteEntity.id,
        companyId: paymentEntity.companyId,
        productTypeId: paymentEntity.productTypeId,
        debitStatus: DebitStatus.DEBITED,
        remitStatus: RemitStatus.NOT_REMITTED
      };
      this.props.updatePayment(paymentEntity);
      this.props.createClientPolicyPayment(policyPayment);
    }
  }

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(2);
  }

  render() {
    const { showPreview } = this.state;
    return (
      <div className="step step1 mt-5 ">
        <AvForm onSubmit={this.handleSubmit}>
          <Row className="mb-2 justify-content-md-center">
            <Col md="9" className="mt-5">
              <AvGroup>
                <Row>
                  <Label sm="3" for="client-kycTypes">
                    Narration
                  </Label>
                  <Col sm="9">
                    <AvField id="debit-note-description" type="textarea" rows={5} placeholder="Narration" name="narration" />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <Row className="ml-5 mr-5 justify-content-md-center">
            <Col md="10" className="mt-5">
              <Row>
                <Col sm="6">
                  <Button type="button" color="info" onClick={this.handleBack}>
                    <span className="d-none d-md-inline">BACK</span>
                  </Button>
                </Col>
                <Col sm="6" className="text-right">
                  <Button type="submit" className="btn btn-success">
                    FINISH
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {showPreview && (
            <Row className="justify-content-md-center">
              <Col md={12}>
                <div className="form-group mb-5 mt-5">
                  <PDFPreview height={900} width="90%">
                    <DebitNote
                      account={this.props.account}
                      kYC={this.props.kYCEntity}
                      policy={this.props.policyEntity}
                      client={this.props.clientEntity}
                      clientPolicy={this.props.clientPolicyEntity}
                      debitNoteEntity={this.props.debitNoteEntity}
                    />
                  </PDFPreview>
                </div>
              </Col>
            </Row>
          )}
        </AvForm>
        <Row className="ml-5 mr-5 justify-content-md-center">
          <Col md="10" className="mt-5">
            <Row>
              <Col sm="6" className="text-right">
                <NavLink to={`/entity/direct-payments`} color="success" activeClassName="active" className="btn btn-create-new">
                  CLOSE
                </NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  debitNoteEntity: storeState.debitNote.entity,
  loading: storeState.debitNote.loading,
  updateSuccess: storeState.quote.updateSuccess,
  clientPolicyEntity: storeState.clientPolicy.entity,
  policyEntity: storeState.policy.entity,
  directPayment: storeState.paymentCallbacks.entity,
  kYCEntity: storeState.kYC.entity,
  invoiceEntity: storeState.invoice.entity,
  clientEntity: storeState.client.entity
});

const mapDispatchToProps = {
  updateEntity,
  createDebitNote,
  updatePayment,
  createInvoice,
  createClientPolicyPayment
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebitNoteStep);
