import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { Button, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'react-flags-select/css/react-flags-select.css';
import { IRootState } from 'app/shared/reducers';
import { createEntity } from '../quote.reducer';

export interface IQuoteProps extends StateProps, DispatchProps {
  jumpToStep;
  selectedRiskCategory;
  getClientByEmail;
}

export interface IQuoteState {
  mobile: string;
  errorMessage: string;
  fetchSuccess: boolean;
  riskClass: any;
  vehicleType: any;
  vehicleTypes: any;
}

class QuoteDetailStep extends React.Component<IQuoteProps, IQuoteState> {
  constructor(props) {
    super(props);
    this.state = {
      mobile: this.props.client.mobile,
      fetchSuccess: this.props.fetchSuccess,
      errorMessage: '',
      vehicleType: null,
      riskClass: null,
      vehicleTypes: []
    };
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {}

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(2);
    }
  }

  handleMobile = mobile => {
    this.setState({ mobile: mobile ? mobile.substring(1) : undefined });
  };
  handleSubmit = async (event, errors, values) => {
    values['companyId'] = this.props.account.companyId;
    const { client } = this.props;
    if (errors.length === 0) {
      const entity = {
        ...values,
        email: this.props.client.email,
        mobile: this.state.mobile,
        firstName: client.firstName,
        lastName: client.lastName,
        // coverTypeName: this.props.coverTypes.find(item => Number(item.id) === Number(values.coverTypeId)).name,
        // riskClassName: this.props.riskClasses.find(item => Number(item.id) === Number(values.riskClassId)).name,
        clientId: client.id,
        clientName: `${client.firstName} ${client.lastName}`,
        quoteSource: 'IBS Admin Portal'
      };
      this.props.createEntity(entity);
    }
  };

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(0);
  }

  render() {
    const { updating } = this.props;
    const { errorMessage, riskClass, mobile } = this.state;
    return (
      <div className="step step2 mb-5">
        <div />
        <Row className="justify-content-md-center">
          <Col lg={9}>
            <AvForm onSubmit={this.handleSubmit}>
              <Row>
                <Col>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="effective-date">
                        Effective Date
                      </Label>
                      <Col sm="8">
                        <AvField id="effective-date" type="date" className="form-control" name="date" required />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="mobileLabel" for="client-mobile">
                        Mobile
                      </Label>
                      <Col sm="8">
                        <PhoneInput
                          placeholder="Enter mobile number"
                          value={mobile}
                          id="client-mobile"
                          name="mobile"
                          onChange={this.handleMobile}
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="carRegistration">
                        Car Registration
                      </Label>
                      <Col sm="8">
                        <AvField id="carRegistration" type="text" className="form-control" name="registration" required />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>

              <Row>
                {riskClass && (riskClass.id === 56 || riskClass.id === 58 || riskClass.id === 57 || riskClass.id) === 54 && (
                  <Col>
                    <AvGroup>
                      <Row>
                        <Label sm="2" for="licencedToCarry">
                          Licenced To Carry
                        </Label>
                        <Col sm="4">
                          <AvField id="licencedToCarry" type="number" className="form-control" name="licencedToCarry" required />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                )}
              </Row>
              {errorMessage && <div className="text-danger">{errorMessage}</div>}
              <FormGroup className="mt-5">
                <Row>
                  <Col sm="6">
                    <Button type="button" color="info" onClick={this.handleBack}>
                      <span className="d-none d-md-inline">Back</span>
                    </Button>
                  </Col>
                  <Col sm="6" className="text-right">
                    <Button type="submit" className="btn btn-success" disabled={updating}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </AvForm>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  errorMessage: storeState.client.errorMessage,
  updating: storeState.quote.updating,
  client: storeState.client.entity,
  fetchSuccess: storeState.client.fetchSuccess,
  updateSuccess: storeState.quote.updateSuccess
});

const mapDispatchToProps = {
  createEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteDetailStep);
