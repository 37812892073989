import moment from 'moment';
import axios from 'axios';
import { AttachmentType } from 'app/shared/model/attachment.model';

export const moneyConverter = value => String(Math.round(value)).replace(/(\d)(?=(\d{3})+\b)/g, '$1,');

export const enum ImageType {
  logo = 'LOGO',
  pin = 'PIN',
  offer = 'OFFER'
}

export const getType = name => {
  const typeMapping = {
    ID: AttachmentType.ID_IMAGE,
    PIN: AttachmentType.PIN_IMAGE,
    OFFER: AttachmentType.OFFER_IMAGE,
    logbook: AttachmentType.LOG_BOOK_IMAGE,
    drivingLicence: AttachmentType.DRIVING_LICENCE_IMAGE,
    valuationReport: AttachmentType.VALUATION_REPORT_IMAGE
  };
  return typeMapping[name] || null;
};

export const getAttachment = (name, attachments) => attachments && attachments.find(a => a['type'] === getType(name));

export const getContent = (name, attachment) => {
  if (attachment && attachment.attachmentFile && attachment.attachmentFile.content) {
    return attachment.attachmentFile.content;
  }
  return '';
};

export const getContentType = (name, attachment) => {
  if (attachment && attachment.attachmentFile && attachment.attachmentFile.contentContentType) {
    return attachment.attachmentFile.contentContentType;
  }
  return '';
};

export default {
  getDateFromString(date) {
    const year = parseInt(date.substring(0, 4), 10);
    const month = parseInt(date.substring(4, 6), 10) - 1;
    const day = parseInt(date.substring(6, 8), 10);
    const hour = parseInt(date.substring(8, 10), 10);
    const minute = parseInt(date.substring(10, 12), 10);
    const second = parseInt(date.substring(12, 14), 10);
    return moment(new Date(year, month, day, hour, minute, second));
  },
  getImageUrl(name, attachments) {
    const attachment = getAttachment(name, attachments);
    let url = '';
    if (attachment) {
      url = `data:${getContentType(name, attachment)};base64,${getContent(name, attachment)}`;
    }
    return url;
  },
  getCurrentImage(entity, type) {
    const { logoFile, pinImage, image } = entity;
    if (logoFile && logoFile.content && type === ImageType.logo) {
      return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
    }
    if (pinImage && pinImage.content && type === ImageType.pin) {
      return `data:${pinImage.contentContentType};base64, ${pinImage.content}`;
    }
    if (image && image.content && type === ImageType.offer) {
      return `data:${image.contentContentType};base64, ${image.content}`;
    }
    return null;
  },
  validateName(name) {
    const re = /^[^0-9 *&^$#@!(){}\[\]\\//]+[^0-9*&^$#@!(){}\[\]\\//]+$/;
    return re.test(name);
  },
  async exportEntity(apiUrl, page, size, params, actionType, entityName, dispatch) {
    const requestUrl = `${apiUrl}/export?page=${page}&size=${size}&${params}`;
    try {
      dispatch({ type: `${actionType}_REQUEST` });
      const response = await axios.get(requestUrl, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/csv' });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an <a> element for downloading the file
      const a = document.createElement('a');
      a.href = url;
      a.download = `${entityName}.csv`;

      // Trigger a click event on the <a> element to initiate the download
      document.body.appendChild(a);
      a.click();
      // Clean up: Revoke the Blob URL
      window.URL.revokeObjectURL(url);
      // Dispatch a success action if needed
      dispatch({ type: `${actionType}_SUCCESS` });
    } catch (error) {
      // Handle any errors that occur during the request
      console.error(`Error exporting ${entityName} data:`, error);
      // Dispatch an error action if needed
      dispatch({ type: `${actionType}_ERROR`, payload: error });
    }
  }
};
