import React, { useState } from 'react';
import { Button, Modal, ModalHeader, Row, Col, Label, ModalBody, ModalFooter } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { size } from 'lodash';
import { EMAIL_SHORT_CODES } from 'app/config/constants';
import MultiSelect from 'app/shared/common/multi-select';
import { IRootState } from 'app/shared/reducers';
import { getEntities, createEntity as sendReminders } from 'app/entities/reminder/reminder.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

const messageOptions = [
  {
    value: 'sms',
    label: 'SMS'
  },
  {
    value: 'email',
    label: 'EMAIL'
  }
];

export interface IRemindersProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  toggleModal;
  contactList;
  open;
}

export const Reminders = (props: IRemindersProps) => {
  const [message, setMessage] = useState('');
  const [count, setCount] = useState(0);
  const [selectedMessageTypes, setSelectedMessageTypes] = useState([]);

  const toggle = () => props.toggleModal();

  const onPressShortCode = code => setMessage(`${message}{{${code.value}}}`);

  const onChange = e => {
    const text = e.target.value;
    setMessage(text);
    setCount(text.length);
  };

  const onFinish = () => {
    let substitutedString = message;
    const data = props.contactList.map(e => {
      const placeholders = EMAIL_SHORT_CODES.map(s => s.value);
      for (const placeholder in placeholders) {
        if (placeholders[placeholder] in e) {
          substitutedString = substitutedString.replace(`{{${placeholders[placeholder]}}}`, e[placeholders[placeholder]]);
        }
      }
      return {
        message: substitutedString,
        mobile: e.mobile,
        email: e.email,
        senderId: 'Flag42',
        messageType: 'SMS'
      };
    });
    props.sendReminders(data);
    toggle();
  };

  const handleChangeMessageType = types => {
    setSelectedMessageTypes(types);
  };

  return (
    <div>
      <Modal
        size="lg"
        style={{ maxWidth: '900px', width: '90%' }}
        isOpen={props.open}
        toggle={toggle}
        fullscreen={'fullscreen'}
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Policy Renewal Reminder</ModalHeader>
        <ModalBody>
          <AvForm model={{}} onSubmit={onFinish}>
            <h5>
              Total Recipients - <span>{size(props.contactList)}</span>
            </h5>
            <Row className="mt-5 mb-4 justify-content-between">
              {EMAIL_SHORT_CODES.map(e => (
                <Col className="pr-2" key={e.value}>
                  <Button outline color="info" onClick={() => onPressShortCode(e)}>
                    {e.text}
                  </Button>
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Label for="message">Message</Label>
                  <AvInput
                    value={message}
                    maxLength={320}
                    onChange={onChange}
                    type={'textarea'}
                    rows={5}
                    name="message"
                    id="message"
                    required
                  />
                  <p>{count + '/320'}</p>
                  <AvFeedback>This is an error!</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="client-kycTypes">
                      Message Types
                    </Label>
                    <Col sm="10">
                      <MultiSelect
                        id="client-kycTypes"
                        name="kycTypes"
                        optionSelected={selectedMessageTypes}
                        isMulti
                        handleChange={handleChangeMessageType}
                        options={messageOptions}
                      />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <Button type={'submit'} color="success" onClick={onFinish}>
            SEND REMINDERS
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ reminder }: IRootState) => ({
  reminderList: reminder.entities,
  loading: reminder.loading,
  totalItems: reminder.totalItems
});

const mapDispatchToProps = {
  getEntities,
  sendReminders
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reminders);
