import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IClientCategory } from 'app/shared/model/client-category.model';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { INameTitle } from 'app/shared/model/name-title.model';
import { getEntities as getNameTitles } from 'app/entities/name-title/name-title.reducer';
import { IIdType } from 'app/shared/model/id-type.model';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './client.reducer';
import { getEntity as getPaymentEntity } from 'app/entities/direct-payment/direct-payment.reducer';
import { IClient } from 'app/shared/model/client.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import sortBy from 'lodash/sortBy';

export interface IClientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; paymentId: string }> {}

export interface IClientUpdateState {
  isNew: boolean;
  categoryId: string;
  titleId: string;
  idTypeId: string;
  telephone: string;
  mobile: string;
  country: string;
  paymentId: string;
}

export class ClientUpdate extends React.Component<IClientUpdateProps, IClientUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: '',
      titleId: '0',
      paymentId: '0',
      idTypeId: '0',
      telephone: '',
      mobile: '',
      country: '',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const paymentId = searchParams.get('paymentId');
      this.props.reset();
      if (paymentId) {
        this.setState({ paymentId });
        Promise.resolve(this.props.getPaymentEntity(paymentId)).then(res => {
          const { directPayment, account } = this.props;
          // @ts-ignore
          this.setState({ mobile: directPayment.msisdn });
        });
      }
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
    if (this.props.clientEntity.id) {
      this.setState({ categoryId: String(this.props.clientEntity.categoryId) });
    }
    this.props.getClientCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getNameTitles(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getIdTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { clientEntity } = this.props;
      const entity = {
        ...clientEntity,
        ...values,
        companyId: this.props.account.companyId,
        mobile: this.state.mobile,
        telephone: this.state.telephone,
        country: this.state.country
      };
      if (this.props.account.partner) {
        entity.partner = this.props.account.partner;
      }
      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  onCountry = country => {
    this.setState({ country });
  };

  handleCategoryChange = categoryId => {
    this.setState({ categoryId: categoryId.target.value });
  };

  handleClose = () => {
    this.props.history.push('/entity/client');
  };

  handleBack = () => {
    history.back();
  };

  handlePhone = telephone => {
    this.setState({ telephone });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  render() {
    const { clientEntity, clientCategories, nameTitles, directPayment, idTypes, loading, updating } = this.props;
    const { isNew, categoryId, paymentId } = this.state;
    return (
      <div>
        <Card>
          <CardBody className="pr-0 pl-0">
            <AvForm model={isNew && paymentId ? directPayment : isNew ? {} : clientEntity} onSubmit={this.saveEntity}>
              <Row className="pl-3 pr-3">
                <Col md="12">
                  <h4 className="font-medium mb-4">New Client Details</h4>
                </Col>
              </Row>
              <Row className="pl-3 pr-3">
                <Col md="6">
                  <AvGroup>
                    <Row>
                      <Label sm="3" for="client-category">
                        Client Category
                      </Label>
                      <Col sm="6">
                        <AvInput
                          id="client-category"
                          type="select"
                          className="form-control"
                          name="categoryId"
                          onChange={category => this.handleCategoryChange(category)}
                          required
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          {clientCategories
                            ? sortBy(clientCategories, ['name']).map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              {categoryId === '1' && (
                <div>
                  <hr className="mt-0 mb-4" />
                  <Row className="pl-3 pr-3 pt-3">
                    <Col md="12">
                      <h4 className="font-medium mb-4">Personal Info</h4>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="firstNameLabel" for="client-firstName">
                            First Name
                          </Label>
                          <Col sm="8">
                            <AvField
                              id="client-firstName"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                              placeholder="Enter first name"
                              type="text"
                              name="firstName"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="idNumberLabel" for="client-idNumber">
                            Id Number
                          </Label>
                          <Col sm="8">
                            <AvField id="client-idNumber" placeholder="Enter ID number" type="text" name="idNumber" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="middleNameLabel" for="client-middleName">
                            Middle Name
                          </Label>
                          <Col sm="8">
                            <AvField id="client-middleName" placeholder="Enter middle name" type="text" name="middleName" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="emailLabel" for="client-email">
                            Email
                          </Label>
                          <Col sm="8">
                            <AvField id="client-email" placeholder="Enter email address" type="text" name="email" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="lastNameLabel" for="client-lastName">
                            Last Name
                          </Label>
                          <Col sm="8">
                            <AvField
                              id="client-lastName"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                              placeholder="Enter last name"
                              type="text"
                              name="lastName"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Row>
                          <Label sm="3" id="mobileLabel" for="client-mobile">
                            Mobile
                          </Label>
                          <Col sm="8">
                            <PhoneInput
                              placeholder="Enter mobile number"
                              value={(!isNew && clientEntity.mobile) || (paymentId !== '0' && directPayment.msisdn) || ''}
                              id="client-mobile"
                              name="mobile"
                              onChange={this.handleMobile}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" for="client-idType">
                            Id Type
                          </Label>
                          <Col sm="8">
                            <AvInput id="client-idType" type="select" className="form-control" name="idTypeId">
                              <option value="" selected disabled>
                                Select
                              </option>
                              {idTypes
                                ? sortBy(idTypes, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="statusLabel" for="client-status">
                            Status
                          </Label>
                          <Col sm="8">
                            <AvInput
                              id="client-status"
                              type="select"
                              className="form-control"
                              name="status"
                              value={(!isNew && clientEntity.status) || 'ACTIVE'}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="INACTIVE">INACTIVE</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" for="pin-number">
                            Pin Number
                          </Label>
                          <Col sm="8">
                            <AvField
                              id="pin-number"
                              // validate={ {
                              //   required: { value: true, errorMessage: 'This field is required.' }
                              // } }
                              className="form-control"
                              placeholder="Enter Pin"
                              type="text"
                              name="pinNumber"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                </div>
              )}
              {categoryId === '2' && (
                <div>
                  <hr className="mt-5 mb-4" />
                  <Row className="pl-3 pr-3 pt-3">
                    <Col md="12">
                      <h4 className="font-medium mb-4">Company Info</h4>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="clientNameLabel" for="client-clientName">
                            Company Name
                          </Label>
                          <Col sm="8">
                            <AvField id="client-clientName" placeholder="Enter company name" type="text" name="clientName" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="addressLabel" for="client-address">
                            Address
                          </Label>
                          <Col sm="8">
                            <AvField id="client-address" placeholder="Enter address" type="text" name="address" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="ledgerNameLabel" for="client-ledgerName">
                            Ledger Name
                          </Label>
                          <Col sm="8">
                            <AvField id="client-ledgerName" placeholder="Enter ledger name" type="text" name="ledgerName" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="streetAddressLabel" for="client-streetAddress">
                            Street Address
                          </Label>
                          <Col sm="8">
                            <AvField id="client-streetAddress" placeholder="Enter street address" type="text" name="streetAddress" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="contactPersionLabel" for="client-contactPersion">
                            Contact Person
                          </Label>
                          <Col sm="8">
                            <AvField id="client-contactPersion" placeholder="Enter contact person" type="text" name="contactPersion" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="countyLabel" for="client-county">
                            County
                          </Label>
                          <Col sm="8">
                            <AvField id="client-county" placeholder="Enter county" type="text" name="county" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3 mb-2">
                    <Col md="6">
                      <FormGroup>
                        <Row>
                          <Label sm="3" id="telephoneLabel" for="client-telephone">
                            Telephone
                          </Label>
                          <Col sm="8">
                            <FormGroup>
                              <PhoneInput
                                placeholder="Enter phone number"
                                value={(!isNew && clientEntity.telephone) || ''}
                                id="client-telephone"
                                name="telephone"
                                onChange={this.handlePhone}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Row>
                          <Label sm="3" id="countryLabel" for="client-country">
                            Country
                          </Label>
                          <Col sm="8">
                            <FormGroup>
                              <ReactFlagsSelect
                                id="client-country"
                                defaultCountry={(!isNew && clientEntity.country) || ''}
                                onSelect={this.onCountry}
                                name="country"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3">
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="notesLabel" for="client-notes">
                            Notes
                          </Label>
                          <Col sm="8">
                            <AvField id="client-notes" placeholder="Enter Notes" type="textarea" name="notes" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="pinNumberLabel" for="client-pinNumber">
                            Postal Code
                          </Label>
                          <Col sm="8">
                            <AvField
                              id="client-pinNumber"
                              placeholder="Enter Postal Code"
                              type="number"
                              className="form-control"
                              name="pinNumber"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                </div>
              )}
              <Row className="pl-3 pr-5 mt-4 mb-5">
                <Col md="12 text-right">
                  <Button onClick={this.handleBack} color="info">
                    <FontAwesomeIcon icon="arrow-left" />
                    &nbsp;
                    <span className="d-none d-md-inline">Back</span>
                  </Button>
                  &nbsp; &nbsp;
                  <Button type="submit" className="btn btn-success" disabled={updating}>
                    <i className="fa fa-check" /> Save
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clientCategories: storeState.clientCategory.entities,
  nameTitles: storeState.nameTitle.entities,
  directPayment: storeState.paymentCallbacks.entity,
  idTypes: storeState.idType.entities,
  clientEntity: storeState.client.entity,
  loading: storeState.client.loading,
  updating: storeState.client.updating,
  updateSuccess: storeState.client.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getClientCategories,
  getNameTitles,
  getIdTypes,
  getEntity,
  getPaymentEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientUpdate);
