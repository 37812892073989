import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICarOption, defaultValue } from 'app/shared/model/car-option.model';

export const ACTION_TYPES = {
  FETCH_CAROPTION_LIST: 'carOption/FETCH_CAROPTION_LIST',
  FETCH_CAROPTION: 'carOption/FETCH_CAROPTION',
  CREATE_CAROPTION: 'carOption/CREATE_CAROPTION',
  UPDATE_CAROPTION: 'carOption/UPDATE_CAROPTION',
  DELETE_CAROPTION: 'carOption/DELETE_CAROPTION',
  RESET: 'carOption/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICarOption>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type CarOptionState = Readonly<typeof initialState>;

// Reducer

export default (state: CarOptionState = initialState, action): CarOptionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CAROPTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CAROPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CAROPTION):
    case REQUEST(ACTION_TYPES.UPDATE_CAROPTION):
    case REQUEST(ACTION_TYPES.DELETE_CAROPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CAROPTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CAROPTION):
    case FAILURE(ACTION_TYPES.CREATE_CAROPTION):
    case FAILURE(ACTION_TYPES.UPDATE_CAROPTION):
    case FAILURE(ACTION_TYPES.DELETE_CAROPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CAROPTION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CAROPTION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CAROPTION):
    case SUCCESS(ACTION_TYPES.UPDATE_CAROPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CAROPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/car-options';

// Actions

export const getEntities: ICrudGetAllAction<ICarOption> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_CAROPTION_LIST,
  payload: axios.get<ICarOption>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<ICarOption> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CAROPTION,
    payload: axios.get<ICarOption>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ICarOption> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CAROPTION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICarOption> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CAROPTION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICarOption> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CAROPTION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
