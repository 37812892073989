import React, { useEffect } from 'react';
import { Progress, Card, CardBody, Row, Col } from 'reactstrap';

import img1 from '../../assets/images/background/active-bg.png';

const ActiveVisitors = ({ reports }) => {
  useEffect(() => {}, [reports]);
  const otherClaims = () => {
    const other =
      reports.claimsRatio &&
      reports.claimsRatio.filter(c => !['General', 'Health'].includes(c.productName)).reduce((a, b) => +a + +b.value, 0);
    return [(other && other[0]) || 0, (other && other[0] > 0) || false];
  };

  const medicalClaims = () => {
    const medical = reports.claimsRatio && reports.claimsRatio.find(c => c.productName === 'Health');
    return [(medical && medical.value) || 0, !!medical];
  };

  const generalClaims = () => {
    const general = reports.claimsRatio && reports.claimsRatio.find(c => c.productName === 'General');
    return [(general && general.value) || 0, !!general];
  };

  const [generalClaimsValue, hasGeneralClaims] = generalClaims();
  const [otherClaimsValue, hasOtherClaims] = otherClaims();
  const [medicalClaimsValue, hasMedicalClaims] = medicalClaims();
  return (
    <Card className="card-hover">
      <CardBody style={{ background: `url(${img1}) no-repeat top center` }}>
        <div className="pt-3 text-center">
          <i className="mdi mdi-file-chart display-4 text-orange d-block" />
          <span className="display-4 d-block font-medium">{reports.currentClaims || 0}</span>
          <span>Total Claims this Month</span>
          <Progress multi className="mt-3">
            {hasOtherClaims && <Progress bar color="info" value={otherClaimsValue} />}
            {hasMedicalClaims && <Progress bar color="success" value={medicalClaimsValue} />}
            {hasGeneralClaims && <Progress bar color="warning" value={generalClaimsValue} />}
          </Progress>
          <Row className="mt-4 mb-4">
            {hasGeneralClaims && (
              <Col xs="4" md="12" lg="4" className="border-right text-center">
                <h4 className="mb-0 font-medium">{generalClaimsValue} %</h4>General
              </Col>
            )}
            {hasMedicalClaims && (
              <Col xs="4" md="12" lg="4" className="border-right text-md-left">
                <h4 className="mb-0 font-medium">{medicalClaimsValue} %</h4>Medical
              </Col>
            )}
            {hasOtherClaims && (
              <Col xs="4" md="12" lg="4" className="text-right text-md-left">
                <h4 className="mb-0 font-medium">{otherClaimsValue} %</h4>Other
              </Col>
            )}
          </Row>
          <a href="/" className="waves-effect waves-light mt-4 mb-2 btn btn-lg btn-info accent-4">
            View More Details
          </a>
        </div>
      </CardBody>
    </Card>
  );
};

export default ActiveVisitors;
