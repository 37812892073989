import React from 'react';
import { connect } from 'react-redux';
import StepZilla from 'react-stepzilla';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import ClientStep from './step1';
import PolicyStep from './step2';
import ClientKYCStep from './step3';
import DebitNoteStep from './step4';

export interface IQuoteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IWizardState {
  selectedRiskCategory: number;
  startAtStep: number;
  open: boolean;
  modalError: any;
}

class QuoteWizard extends React.Component<IQuoteProps, IWizardState> {
  constructor(props) {
    super(props);
    const { location } = props;
    let startAtStep = 0;
    const { log } = console;
    // if (location.search.indexOf('wizard=2') !== -1) {
    //   startAtStep = Number(location.search.split('=').pop());
    // }
    const endURL = `?wizard=${startAtStep}`;
    if (props.location.search !== endURL) {
      log(startAtStep);
      startAtStep = Number(location.search.split('=').pop());
      props.history.push(`${props.location.pathname}${endURL}`);
    }
    log(startAtStep);
    this.state = {
      startAtStep,
      selectedRiskCategory: 0,
      open: false,
      modalError: {}
    };
  }

  updateSelectedRiskCategory = selectedRiskCategory => {
    this.setState({ selectedRiskCategory });
  };

  openModal = isOpen => {
    this.setState({ open: isOpen });
  };

  setModalError = error => {
    this.setState({ modalError: error });
  };

  render() {
    const { startAtStep, selectedRiskCategory, open, modalError } = this.state;
    const { match, location } = this.props;
    const steps = [
      {
        name: 'Select Client',
        component: (
          <ClientStep
            open={open}
            modalError={modalError}
            setModalError={this.setModalError}
            openModal={this.openModal}
            match={match}
            location={location}
            updateSelectedRiskCategory={this.updateSelectedRiskCategory}
          />
        )
      },
      {
        name: 'Select Policy',
        component: (
          <PolicyStep
            open={open}
            modalError={modalError}
            openModal={this.openModal}
            setModalError={this.setModalError}
            match={match}
            location={location}
            updateSelectedRiskCategory={this.updateSelectedRiskCategory}
            selectedRiskCategory={selectedRiskCategory}
          />
        )
      },
      {
        name: 'Client KYC',
        component: <ClientKYCStep match={match} location={location} />
      },
      {
        name: 'Debit Summary',
        component: <DebitNoteStep />
      }
    ];
    return (
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <i className="mdi mdi-border-right mr-2" />
          Client Policy
        </CardTitle>
        <CardBody>
          <StepZilla showSteps startAtStep={startAtStep} showNavigation={false} stepsNavigation={false} steps={steps} />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ quote }: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteWizard);
