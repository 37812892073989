import React, { Component } from 'react';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import 'react-table/react-table.css';
import { Button, CustomInput } from 'reactstrap';

import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT, AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getAllEntities } from 'app/entities/quote/quote.reducer';
import { connect } from 'react-redux';
import DataTable from 'app/shared/common/dataTable';

export interface ISearchFilterProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ISearchFilterState = IPaginationBaseState;

class QuotesFilter extends Component<any, ISearchFilterState> {
  private readonly reactTable: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(window.location, ITEMS_PER_PAGE),
      sort: 'createdAt,desc'
    };
    this.fetchData = this.fetchData.bind(this);
    this.reactTable = React.createRef();
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`companyId=${this.props.account.companyId}`);
    this.props.getAllEntities(state.page, state.pageSize, `${sort || 'createdAt'},${order || 'desc'}&${filtered.join('&')}`);
    this.setState({ ...state, itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  private selectQuote(e, quote) {
    if (!e.target.checked) {
      this.props.onPickItem(null);
    } else {
      this.props.onPickItem(quote.id);
    }
  }

  render() {
    const { loading, quoteList, match, account } = this.props;
    const columns = [
      {
        Header: (
          <div className="header-container">
            <span>Name</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'id'
      },
      {
        Header: (
          <div className="header-container">
            <span>Email</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'firstName'
      },
      {
        Header: (
          <div className="header-container">
            <span>Mobile</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'email'
      },
      {
        Header: (
          <div className="header-container">
            <span>Registration</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'registration'
      },
      {
        Header: (
          <div className="header-container">
            <span>Mobile</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'mobile'
      },
      {
        Header: (
          <div className="header-container">
            <span>Quote Date</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'createdAt',
        resizable: true
      },
      {
        Header: () => (
          <div className="header-container">
            <span>Underwriter</span>
          </div>
        ),
        accessor: 'underwriterName',
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        filterable: false
      }
    ];
    const tableData = quoteList.map(prop => ({
      ...prop,
      id: `${prop.firstName} ${prop.lastName}`,
      createdAt: prop.createdAt === null ? '' : <TextFormat type="date" value={`${prop.createdAt}`} format={APP_LOCAL_DATETIME_FORMAT} />,
      date: prop.date === null ? '' : <TextFormat type="date" value={`${prop.date}`} format={APP_LOCAL_DATE_FORMAT} />,
      select: (
        <div className="text-center">
          <div className="checkbox">
            <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} />
          </div>
        </div>
      ),
      actions: (
        <div className="text-center">
          <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} onChange={e => this.selectQuote(e, prop)} />
        </div>
      )
    }));
    return (
      <DataTable
        ref={this.reactTable}
        manual
        className="-striped -highlight"
        defaultPageSize={10}
        columns={columns}
        pages={this.getMaxPage()}
        loading={loading}
        onFetchData={this.fetchData}
        tableData={tableData}
        filterable
      />
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.quote.loading,
  summaryLoading: storeState.quote.summaryLoading,
  quoteList: storeState.quote.entities,
  totalItems: storeState.quote.totalItems,
  account: storeState.authentication.account,
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER])
});

const mapDispatchToProps = {
  getAllEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotesFilter);
