import { makeStyles } from '@material-ui/core';
import { textWhite, textPrimary } from '../../../assets/colors';

export default makeStyles(theme => ({
  container: {},
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3.8),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  title: {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: 20,
    fontWeight: 500,
    color: textPrimary
  },
  headerContainer: {
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(1.2),
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(12,12,12,10%)',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  premiumContainer: {
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  totalContainer: {
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1.2),
    borderBottom: '0px solid rgba(12,12,12,10%)',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  couponContainer: {
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1.2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  amountContainer: {
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.6),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center'
  },
  headerCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1
  },
  headerColHalf: {
    flex: 0.4
  },
  flex: {
    flex: 1
  },
  primary: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 700,
    fontSize: 11,
    color: textPrimary
  },
  secondary: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    fontSize: 9,
    color: textPrimary,
    lineHeight: 2
  },
  totalText: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    fontSize: 11,
    color: textPrimary,
    lineHeight: 2
  },
  totalSecondary: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 700,
    fontSize: 13,
    color: textPrimary,
    lineHeight: 2
  },
  amountText: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 700,
    fontSize: 15,
    color: textPrimary,
    lineHeight: 2
  },
  policyContainer: {
    borderTop: '1px solid rgba(12,12,12,10%)',
    borderBottom: '1px solid rgba(12,12,12,10%)',
    padding: '15px 0'
  },
  policyTextContainer: {
    display: 'flex',
    alignItems: 'enter'
  },
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    position: 'relative',
    marginBottom: theme.spacing(3)
  },
  button: {
    backgroundColor: '#F54336',
    fontSize: 15,
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 700,
    color: textWhite,
    textTransform: 'capitalize',
    boxShadow: 'none',
    borderRadius: 20,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover,&:active,&:focus': {
      backgroundColor: '#F54336',
      boxShadow: 'none'
    }
  },
  paragraphContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  paragraph: {
    fontSize: 7,
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    color: textPrimary,
    paddingLeft: 10
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 15,
    fontSize: 18
  },
  borderRight: {
    borderRight: '1px solid rgba(20,159,203, 16%)',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none'
    }
  },
  buttonProgress: {
    color: '#F54336',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));
