import { Status } from 'app/shared/model/policy.model';
import { PaymentStatus } from 'app/shared/model/client-policy.model';

export interface IPolicyRenewalClass {
  clientPolicyId?: number;
  policyDate?: Date;
  startDate?: Date;
  endDate?: Date;
  underwriterName?: string;
  riskClassName?: string;
  policyNumber?: number;
  policyName?: string;
  productTypeName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  mobile?: string;
  email?: string;
  paymentStatus?: PaymentStatus;
  policyStatus?: Status;
  from?: Date;
  to?: Date;
}

export const defaultValue: Readonly<IPolicyRenewalClass> = {};
