import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import company, {
  CompanyState
} from 'app/entities/company/company.reducer';
// prettier-ignore
import benefitType, {
  BenefitTypeState
} from 'app/entities/benefit-type/benefit-type.reducer';
// prettier-ignore
import benefit, {
  BenefitState
} from 'app/entities/benefit/benefit.reducer';
// prettier-ignore
import underwriter, {
  UnderwriterState
} from 'app/entities/underwriter/underwriter.reducer';
// prettier-ignore
import clientCategory, {
  ClientCategoryState
} from 'app/entities/client-category/client-category.reducer';
// prettier-ignore
import nameTitle, {
  NameTitleState
} from 'app/entities/name-title/name-title.reducer';
// prettier-ignore
import idType, {
  IdTypeState
} from 'app/entities/id-type/id-type.reducer';
// prettier-ignore
import client, {
  ClientState
} from 'app/entities/client/client.reducer';
// prettier-ignore
import productType, {
  ProductTypeState
} from 'app/entities/product-type/product-type.reducer';
// prettier-ignore
import riskCategory, {
  RiskCategoryState
} from 'app/entities/risk-category/risk-category.reducer';
// prettier-ignore
import riskClass, {
  RiskClassState
} from 'app/entities/risk-class/risk-class.reducer';
// prettier-ignore
import coverType, {
  CoverTypeState
} from 'app/entities/cover-type/cover-type.reducer';
// prettier-ignore
import policy, {
  PolicyState
} from 'app/entities/policy/policy.reducer';
// prettier-ignore
import policyBenefit, {
  PolicyBenefitState
} from 'app/entities/policy-benefit/policy-benefit.reducer';
// prettier-ignore
import clientPolicy, {
  ClientPolicyState
} from 'app/entities/client-policy/client-policy.reducer';
// prettier-ignore
import clientPolicyPayment, {
  ClientPolicyPaymentState
} from 'app/entities/client-policy-payment/client-policy-payment.reducer';
// prettier-ignore
import attachmentFile, {
  AttachmentFileState
} from 'app/entities/attachment-file/attachment-file.reducer';
// prettier-ignore
import attachment, {
  AttachmentState
} from 'app/entities/attachment/attachment.reducer';
import riskClassFormula, { RiskClassFormulaState } from 'app/entities/risk-class-formula/risk-class-formula.reducer';
// prettier-ignore
import formulaParam, {
  FormulaParamState
} from 'app/entities/formula-param/formula-param.reducer';
// prettier-ignore
import binderRate, {
  BinderRateState
} from 'app/entities/binder-rate/binder-rate.reducer';
// prettier-ignore
import standardRate, {
  StandardRateState
} from 'app/entities/standard-rate/standard-rate.reducer';
// prettier-ignore
import levies, {
  LeviesState
} from 'app/entities/levies/levies.reducer';
// prettier-ignore
import productTypeDefaultData, {
  ProductTypeDefaultDataState
} from 'app/entities/product-type-default-data/product-type-default-data.reducer';
// prettier-ignore
import riskCategoryDefaultData, {
  RiskCategoryDefaultDataState
} from 'app/entities/risk-category-default-data/risk-category-default-data.reducer';
// prettier-ignore
import riskClassDefaultData, {
  RiskClassDefaultDataState
} from 'app/entities/risk-class-default-data/risk-class-default-data.reducer';
// prettier-ignore
import standardRateDefaultData, {
  StandardRateDefaultDataState
} from 'app/entities/standard-rate-default-data/standard-rate-default-data.reducer';
// prettier-ignore
import leviesDefaultData, {
  LeviesDefaultDataState
} from 'app/entities/levies-default-data/levies-default-data.reducer';
// prettier-ignore
import quote, {
  QuoteState
} from 'app/entities/quote/quote.reducer';
// prettier-ignore
import kYC, {
  KYCState
} from 'app/entities/kyc/kyc.reducer';
// prettier-ignore
import serviceProviderType, {
  ServiceProviderTypeState
} from 'app/entities/service-provider-type/service-provider-type.reducer';
// prettier-ignore
import serviceProvider, {
  ServiceProviderState
} from 'app/entities/service-provider/service-provider.reducer';
// prettier-ignore
import communicationInfo, {
  CommunicationInfoState
} from 'app/entities/communication-info/communication-info.reducer';
// prettier-ignore
import smsContent, {
  SmsContentState
} from 'app/entities/sms-content/sms-content.reducer';
// prettier-ignore
import vehicleType, {
  VehicleTypeState
} from 'app/entities/vehicle-type/vehicle-type.reducer';
// prettier-ignore
import tonnageRange, {
  TonnageRangeState
} from 'app/entities/tonnage-range/tonnage-range.reducer';
// prettier-ignore
import carEquipment, {
  CarEquipmentState
} from 'app/entities/car-equipment/car-equipment.reducer';
// prettier-ignore
import carGeneration, {
  CarGenerationState
} from 'app/entities/car-generation/car-generation.reducer';
// prettier-ignore
import carMake, {
  CarMakeState
} from 'app/entities/car-make/car-make.reducer';
// prettier-ignore
import carModel, {
  CarModelState
} from 'app/entities/car-model/car-model.reducer';
// prettier-ignore
import carOption, {
  CarOptionState
} from 'app/entities/car-option/car-option.reducer';
// prettier-ignore
import carOptionValue, {
  CarOptionValueState
} from 'app/entities/car-option-value/car-option-value.reducer';
// prettier-ignore
import carSerie, {
  CarSerieState
} from 'app/entities/car-serie/car-serie.reducer';
// prettier-ignore
import carSpecification, {
  CarSpecificationState
} from 'app/entities/car-specification/car-specification.reducer';
// prettier-ignore
import carSpecificationValue, {
  CarSpecificationValueState
} from 'app/entities/car-specification-value/car-specification-value.reducer';
// prettier-ignore
import carTrim, {
  CarTrimState
} from 'app/entities/car-trim/car-trim.reducer';
// prettier-ignore
import carType, {
  CarTypeState
} from 'app/entities/car-type/car-type.reducer';
// prettier-ignore
import vehicleAdditionalInfo, { VehicleAdditionalInfoState } from './vehicle-adittional-info.reducer';
import notification, { NotificationState } from 'app/shared/notification/notification.reducer';
// prettier-ignore
import akiCertificateState, { AkiCertificateState } from 'app/modules/aki/aki.reducer';
import reports, { ReportsState } from 'app/modules/reports/reducers/reports.reducer';
// prettier-ignore
import customerAuthentication, {
  CustomerAuthenticationState
} from 'app/entities/customer-authentication/customer-authentication.reducer';
// prettier-ignore
import customerAuthenticationToken, {
  CustomerAuthenticationTokenState
} from 'app/entities/customer-authentication-token/customer-authentication-token.reducer';
// prettier-ignore
import benefitPlan, {
  BenefitPlanState
} from 'app/entities/benefit-plan/benefit-plan.reducer';
// prettier-ignore
import ageRange, {
  AgeRangeState
} from 'app/entities/age-range/age-range.reducer';
// prettier-ignore
import claim, {
  ClaimState
} from 'app/entities/claim/claim.reducer';
import paymentCallbacks, { PaymentCallbackState } from 'app/entities/direct-payment/direct-payment.reducer';
// prettier-ignore
import coverArea, {
  CoverAreaState
} from 'app/entities/cover-area/cover-area.reducer';
// prettier-ignore
import benefitDetails, {
  BenefitDetailsState
} from 'app/entities/benefit-details/benefit-details.reducer';
// prettier-ignore
import policyApplication, {
  PolicyApplicationState
} from 'app/entities/policy-application/policy-application.reducer';
// prettier-ignore
import benefitSpanType, {
  BenefitSpanTypeState
} from 'app/entities/benefit-span-type/benefit-span-type.reducer';
// prettier-ignore
import shortCodes, {
  ShortCodesState
} from 'app/entities/short-codes/short-codes.reducer';
// prettier-ignore
import underwriterProduct, {
  UnderwriterProductState
} from 'app/entities/underwriter-product/underwriter-product.reducer';
// prettier-ignore
import benefitCategory, {
  BenefitCategoryState
} from 'app/entities/benefit-category/benefit-category.reducer';
import debitNote, { DebitNoteState } from 'app/entities/debit-note/debit-note.reducer';
// prettier-ignore
import riskClassKycType, {
  RiskClassKycTypeState
} from 'app/entities/risk-class-kyc-type/risk-class-kyc-type.reducer';
// prettier-ignore
import invoice, {
  InvoiceState
} from 'app/entities/invoice/invoice.reducer';
// prettier-ignore
import transactions, {
  TransactionsState
} from 'app/entities/transactions/transactions.reducer';
import billWise, { BillWiseReportsState } from 'app/modules/reports/reducers/billwise-report.reducer';
import policyReport, { PolicyReportState } from 'app/modules/policy-renewal/policy-renewal.reducer';

// prettier-ignore
import kYCType, {
  KYCTypeState
} from 'app/entities/kyc-type/kyc-type.reducer';
// prettier-ignore
import reminder, {
  ReminderState
} from 'app/entities/reminder/reminder.reducer';
// prettier-ignore
import remittance, {
  RemittanceState
} from 'app/entities/remittance/remittance.reducer';
// prettier-ignore
import offer, {
  OfferState
} from 'app/entities/offer/offer.reducer';
import country, { CountryState } from 'app/entities/country/country.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly company: CompanyState;
  readonly benefitType: BenefitTypeState;
  readonly benefit: BenefitState;
  readonly underwriter: UnderwriterState;
  readonly clientCategory: ClientCategoryState;
  readonly nameTitle: NameTitleState;
  readonly idType: IdTypeState;
  readonly client: ClientState;
  readonly productType: ProductTypeState;
  readonly riskCategory: RiskCategoryState;
  readonly riskClass: RiskClassState;
  readonly coverType: CoverTypeState;
  readonly policy: PolicyState;
  readonly policyBenefit: PolicyBenefitState;
  readonly clientPolicy: ClientPolicyState;
  readonly clientPolicyPayment: ClientPolicyPaymentState;
  readonly attachmentFile: AttachmentFileState;
  readonly attachment: AttachmentState;
  readonly riskClassFormula: RiskClassFormulaState;
  readonly formulaParam: FormulaParamState;
  readonly binderRate: BinderRateState;
  readonly standardRate: StandardRateState;
  readonly levies: LeviesState;
  readonly productTypeDefaultData: ProductTypeDefaultDataState;
  readonly riskCategoryDefaultData: RiskCategoryDefaultDataState;
  readonly riskClassDefaultData: RiskClassDefaultDataState;
  readonly standardRateDefaultData: StandardRateDefaultDataState;
  readonly leviesDefaultData: LeviesDefaultDataState;
  readonly quote: QuoteState;
  readonly kYC: KYCState;
  readonly serviceProviderType: ServiceProviderTypeState;
  readonly serviceProvider: ServiceProviderState;
  readonly communicationInfo: CommunicationInfoState;
  readonly smsContent: SmsContentState;
  readonly vehicleType: VehicleTypeState;
  readonly tonnageRange: TonnageRangeState;
  readonly carEquipment: CarEquipmentState;
  readonly carGeneration: CarGenerationState;
  readonly carMake: CarMakeState;
  readonly carModel: CarModelState;
  readonly carOption: CarOptionState;
  readonly carOptionValue: CarOptionValueState;
  readonly carSerie: CarSerieState;
  readonly carSpecification: CarSpecificationState;
  readonly carSpecificationValue: CarSpecificationValueState;
  readonly carTrim: CarTrimState;
  readonly carType: CarTypeState;
  readonly customerAuthentication: CustomerAuthenticationState;
  readonly customerAuthenticationToken: CustomerAuthenticationTokenState;
  readonly benefitPlan: BenefitPlanState;
  readonly ageRange: AgeRangeState;
  readonly country: CountryState;
  readonly claim: ClaimState;
  readonly coverArea: CoverAreaState;
  readonly benefitDetails: BenefitDetailsState;
  readonly policyApplication: PolicyApplicationState;
  readonly benefitSpanType: BenefitSpanTypeState;
  readonly shortCodes: ShortCodesState;
  readonly underwriterProduct: UnderwriterProductState;
  readonly benefitCategory: BenefitCategoryState;
  readonly invoice: InvoiceState;
  readonly transactions: TransactionsState;
  readonly kYCType: KYCTypeState;
  readonly reminder: ReminderState;
  readonly remittance: RemittanceState;
  readonly offer: OfferState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
  readonly akiCertificateState: AkiCertificateState;
  readonly paymentCallbacks: PaymentCallbackState;
  readonly riskClassKycType: RiskClassKycTypeState;
  readonly debitNote: DebitNoteState;
  readonly reports: ReportsState;
  readonly billWise: BillWiseReportsState;
  readonly policyReport: PolicyReportState;
  vehicleAdditionalInfo: VehicleAdditionalInfoState;
  notification: NotificationState;
}

const rootReducer = combineReducers<IRootState>({
  vehicleAdditionalInfo,
  notification,
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  company,
  benefitType,
  benefit,
  underwriter,
  clientCategory,
  nameTitle,
  idType,
  client,
  productType,
  riskCategory,
  riskClass,
  coverType,
  policy,
  policyBenefit,
  clientPolicy,
  clientPolicyPayment,
  attachmentFile,
  attachment,
  riskClassFormula,
  formulaParam,
  binderRate,
  standardRate,
  levies,
  productTypeDefaultData,
  riskCategoryDefaultData,
  riskClassDefaultData,
  standardRateDefaultData,
  leviesDefaultData,
  quote,
  kYC,
  serviceProviderType,
  paymentCallbacks,
  serviceProvider,
  communicationInfo,
  smsContent,
  vehicleType,
  tonnageRange,
  carEquipment,
  carGeneration,
  carMake,
  carModel,
  carOption,
  carOptionValue,
  carSerie,
  carSpecification,
  carSpecificationValue,
  carTrim,
  carType,
  customerAuthentication,
  customerAuthenticationToken,
  benefitPlan,
  ageRange,
  claim,
  coverArea,
  benefitDetails,
  policyApplication,
  benefitSpanType,
  shortCodes,
  underwriterProduct,
  debitNote,
  benefitCategory,
  invoice,
  transactions,
  kYCType,
  country,
  reminder,
  remittance,
  offer,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  akiCertificateState,
  riskClassKycType,
  reports,
  billWise,
  policyReport,
  loadingBar
});

export default rootReducer;
