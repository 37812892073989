import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PaymentCallback from './direct-payment';
import PaymentCallbackDetail from './direct-payment-detail';
import PaymentCallbackUpdate from './direct-payment-update';
import DebitWizard from './debit-wizard';
import PaymentCallbackDeleteDialog from './direct-payment-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PaymentCallbackUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/debit`} component={DebitWizard} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PaymentCallbackUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PaymentCallbackDetail} />
      <ErrorBoundaryRoute path={match.url} component={PaymentCallback} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={PaymentCallbackDeleteDialog} />
  </>
);

export default Routes;
