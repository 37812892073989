import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import BenefitSpanType from './benefit-span-type';
import BenefitSpanTypeDetail from './benefit-span-type-detail';
import BenefitSpanTypeUpdate from './benefit-span-type-update';
import BenefitSpanTypeDeleteDialog from './benefit-span-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={BenefitSpanTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={BenefitSpanTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={BenefitSpanTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={BenefitSpanType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={BenefitSpanTypeDeleteDialog} />
  </>
);

export default Routes;
