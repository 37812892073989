import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { saveAs } from 'file-saver';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudDeleteAction } from 'app/shared/reducers/redux-action.type';
import { IDebitNote, defaultValue } from 'app/shared/model/debit-note.model';
import utils from 'app/config/utils';

export const ACTION_TYPES = {
  FETCH_DEBITNOTE_LIST: 'debitNote/FETCH_DEBITNOTE_LIST',
  FETCH_DEBITNOTE: 'debitNote/FETCH_DEBITNOTE',
  CREATE_DEBITNOTE: 'debitNote/CREATE_DEBITNOTE',
  UPDATE_DEBITNOTE: 'debitNote/UPDATE_DEBITNOTE',
  DELETE_DEBITNOTE: 'debitNote/DELETE_DEBITNOTE',
  EXPORT_DEBITS: 'debitNote/EXPORT_DEBITS',
  RESET: 'debitNote/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDebitNote>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type DebitNoteState = Readonly<typeof initialState>;

// Reducer

export default (state: DebitNoteState = initialState, action): DebitNoteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DEBITNOTE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DEBITNOTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_DEBITNOTE):
    case REQUEST(ACTION_TYPES.UPDATE_DEBITNOTE):
    case REQUEST(ACTION_TYPES.DELETE_DEBITNOTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_DEBITNOTE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DEBITNOTE):
    case FAILURE(ACTION_TYPES.CREATE_DEBITNOTE):
    case FAILURE(ACTION_TYPES.UPDATE_DEBITNOTE):
    case FAILURE(ACTION_TYPES.DELETE_DEBITNOTE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEBITNOTE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEBITNOTE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_DEBITNOTE):
    case SUCCESS(ACTION_TYPES.UPDATE_DEBITNOTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_DEBITNOTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/debit-notes';

// Actions

export const getEntities: ICrudGetAllAction<IDebitNote> = (page = 0, size = 10, sort = ' ,') => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_DEBITNOTE_LIST,
    payload: axios.get<IDebitNote>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IDebitNote> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DEBITNOTE,
    payload: axios.get<IDebitNote>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IDebitNote> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DEBITNOTE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities(0, 10, `,&companyId=${entity.companyId}`));
  return result;
};

export const updateEntity: ICrudPutAction<IDebitNote> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DEBITNOTE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(0, 10, `,&companyId=${entity.companyId}`));
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDebitNote> = (id, companyId) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DEBITNOTE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(0, 10, `,&companyId=${companyId}`));
  return result;
};

export const exportDebits = (page = 0, size = 10, params, entityName) => async dispatch => {
  await utils.exportEntity(apiUrl, page, size, params, ACTION_TYPES.EXPORT_DEBITS, entityName, dispatch);
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
