import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, Label, CardBody } from 'reactstrap';
import moment from 'moment';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import DataTable from 'app/shared/common/dataTable';
import { TextFormat, getSortState, IPaginationBaseState } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getAllPolicies } from 'app/modules/policy-renewal/policy-renewal.reducer';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import MyTable from 'app/modules/policy-renewal/MyTable';
import Reminders from 'app/modules/policy-renewal/reminders';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IClientPolicyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IClientPolicyState extends IPaginationBaseState {
  from: string;
  to: string;
  selectAll: boolean;
  selection: any[];
  sendReminder: boolean;
}

export class ClientPolicy extends React.Component<IClientPolicyProps, IClientPolicyState> {
  private readonly reactTable: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      from: new Date().toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0],
      selectAll: false,
      selection: [],
      sendReminder: false
    };
    this.reactTable = React.createRef();
  }

  componentDidMount() {
    const { account } = this.props;
    const currentState = this.reactTable.current && this.reactTable.current.state;
    const productTypeIds = account.productTypes.map(a => `productTypeIds=${a.id}`);
    this.props.getAllPolicies(
      (currentState && currentState.page) || 0,
      (currentState && currentState.pageSize) || 10,
      `${'id'},${'desc'}&companyId=${account.companyId}&from=${this.state.from}&to=${
        this.state.to
      }&policyStatus=ACTIVE&${productTypeIds.join('&')}`
    );
  }

  toggleModal = () => {
    this.setState({ sendReminder: !this.state.sendReminder });
  };

  // fetchData(state) {
  //   const sort = state.sorted.map(d => d.id).join();
  //   const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
  //   const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
  //   const { account } = this.props;
  //   const { log } = console;
  //   log(account);
  //   const productTypeIds = account.productTypes.map(a => `productTypeId=${a.id}`);
  //   filtered.push(`companyId=${account.companyId}`);
  //   this.props.getAllPolicies(
  //     state.page,
  //     state.pageSize,
  //     `${sort || 'endDate'},${order || 'desc'}&fromDate=${this.state.from}&toDate=${this.state.to}&${productTypeIds.join(
  //       '&'
  //     )}&${filtered.join('&')}`
  //   );
  //   this.setState({ itemsPerPage: state.pageSize });
  // }

  handleChange = (selectAll, selection) => {
    this.setState({ selectAll, selection });
  };

  handleFilter = (event, errors, values) => {
    const { startDate, endDate } = values;
    // const { log } = console;
    // log(startDate, endDate);
    const { account } = this.props;
    const productTypeIds = account.productTypes.map(a => `productTypeIds=${a.id}`);
    const currentState = this.reactTable.current && this.reactTable.current.state;
    this.props.getAllPolicies(
      (currentState && currentState.page) || 0,
      (currentState && currentState.pageSize) || 10,
      `endDate,desc&from=${startDate}&to=${endDate}&${productTypeIds.join('&')}&policyStatus=ACTIVE&companyId=${account.companyId}`
    );
    this.setState({ from: startDate, to: startDate });
  };

  render() {
    const { loading, policiesList, match, location, history, isSuperAdmin } = this.props;
    const { selection, selectAll, sendReminder, from, to } = this.state;
    const tableData = policiesList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.clientPolicyId}`} color="link" size="sm">
          {prop.clientPolicyId}
        </Button>
      ),
      clientName: `${prop.firstName || ''} ${prop.middleName || ''} ${prop.lastName || ''}`,
      policyDate: (
        <TextFormat type="date" value={moment(prop.policyDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />
      ),
      startDate: <TextFormat type="date" value={moment(prop.startDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      endDate: <TextFormat type="date" value={moment(prop.endDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.clientPolicyId}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button
            className="ml-1"
            color="inverse"
            size="sm"
            round="true"
            icon="true"
            tag={NavLink}
            to={`${match.url}/${prop.clientPolicyId}/edit`}
          >
            <i className="fa fa-edit" />
          </Button>
          <Button
            className="ml-1"
            color="inverse"
            size="sm"
            round="true"
            icon="true"
            tag={NavLink}
            to={`${match.url}/${prop.clientPolicyId}/certificate`}
          >
            <i className="fa fa-certificate" />
          </Button>
          {isSuperAdmin && (
            <Button
              className="ml-1"
              color="danger"
              size="sm"
              round="true"
              icon="true"
              tag={NavLink}
              to={`${match.url}/${prop.clientPolicyId}/delete`}
            >
              <i className="fa fa-times" />
            </Button>
          )}
        </div>
      )
    }));
    const styles = {
      fontSize: '10px',
      textAlign: 'center'
    };
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Client Policies
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  Create new Client Policy
                </NavLink>
              </Col>
            </Row>
            {selection.length > 0 && (
              <Row>
                <Col sm="6">
                  <Button type="button" className="btn btn-success" onClick={() => this.setState({ sendReminder: !sendReminder })}>
                    <i className="mdi mdi-search-web" /> Send Renewal Reminders
                  </Button>
                </Col>
              </Row>
            )}

            <AvForm model={{}} onSubmit={this.handleFilter}>
              <Row className="mt-2 ml-2 align-items-center">
                <Col md={6}>
                  <Row className="mt-2 ml-4 align-items-center">
                    <h6>POLICY EXPIRY PERIOD</h6>
                  </Row>
                  <Row className="mt-2 ml-2 align-items-center">
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="startDateLabel" for="client-policy-startDate">
                            FROM
                          </Label>
                          <Col sm="9">
                            <AvField id="client-policy-startDate" value={from} type="date" className="form-control" name="startDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="3" id="endDateLabel" for="client-policy-endDate">
                            TO
                          </Label>
                          <Col sm="9">
                            <AvField id="client-policy-endDate" value={to} type="date" className="form-control" name="endDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Button type="submit" className="btn btn-success">
                    <i className="mdi mdi-search-web" /> Filter
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </CardTitle>
          <CardBody>
            <MyTable
              selection={selection}
              selectAll={selectAll}
              styles={styles}
              onSelect={this.handleChange}
              columns={[
                {
                  Header: 'ID',
                  width: 60,
                  accessor: 'clientPolicyId'
                },
                {
                  Header: 'Policy Date',
                  width: 70,
                  accessor: 'policyDate'
                },
                {
                  Header: 'Start Date',
                  width: 70,
                  accessor: 'startDate'
                },
                {
                  Header: 'End Date',
                  width: 70,
                  accessor: 'endDate'
                },
                {
                  Header: 'Client Name',
                  accessor: 'clientName'
                },
                {
                  Header: 'Premium Payable',
                  accessor: 'premiumPayable'
                },
                {
                  Header: 'Mobile',
                  width: 85,
                  accessor: 'mobile'
                },
                {
                  Header: 'Payment Status',
                  width: 70,
                  accessor: 'paymentStatus',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="UNPAID">UNPAID</option>
                      <option value="PARTIAL">PARTIAL</option>
                      <option value="PAID">PAID</option>
                    </select>
                  )
                },
                {
                  Header: 'Policy Status',
                  width: 70,
                  accessor: 'policyStatus',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Policy',
                  accessor: 'policyName',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Insurer',
                  accessor: 'underwriterName',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'riskClassName',
                  accessor: 'riskClassName',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              data={tableData}
              keyField={'clientPolicyId'}
            />
          </CardBody>
        </Card>
        <Reminders
          history={history}
          match={match}
          location={location}
          contactList={this.props.policiesList.filter(p => this.state.selection.map(s => Number(s.substr(7))).includes(p.clientPolicyId))}
          open={sendReminder}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ policyReport, authentication }: IRootState) => ({
  loading: policyReport.loading,
  policiesList: policyReport.entities,
  account: authentication.account,
  isSuperAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPER_ADMIN])
});

const mapDispatchToProps = {
  getAllPolicies
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPolicy);
