import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICarSpecificationValue, defaultValue } from 'app/shared/model/car-specification-value.model';

export const ACTION_TYPES = {
  FETCH_CARSPECIFICATIONVALUE_LIST: 'carSpecificationValue/FETCH_CARSPECIFICATIONVALUE_LIST',
  FETCH_CARSPECIFICATIONVALUE: 'carSpecificationValue/FETCH_CARSPECIFICATIONVALUE',
  CREATE_CARSPECIFICATIONVALUE: 'carSpecificationValue/CREATE_CARSPECIFICATIONVALUE',
  UPDATE_CARSPECIFICATIONVALUE: 'carSpecificationValue/UPDATE_CARSPECIFICATIONVALUE',
  DELETE_CARSPECIFICATIONVALUE: 'carSpecificationValue/DELETE_CARSPECIFICATIONVALUE',
  RESET: 'carSpecificationValue/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICarSpecificationValue>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type CarSpecificationValueState = Readonly<typeof initialState>;

// Reducer

export default (state: CarSpecificationValueState = initialState, action): CarSpecificationValueState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CARSPECIFICATIONVALUE):
    case REQUEST(ACTION_TYPES.UPDATE_CARSPECIFICATIONVALUE):
    case REQUEST(ACTION_TYPES.DELETE_CARSPECIFICATIONVALUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE):
    case FAILURE(ACTION_TYPES.CREATE_CARSPECIFICATIONVALUE):
    case FAILURE(ACTION_TYPES.UPDATE_CARSPECIFICATIONVALUE):
    case FAILURE(ACTION_TYPES.DELETE_CARSPECIFICATIONVALUE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CARSPECIFICATIONVALUE):
    case SUCCESS(ACTION_TYPES.UPDATE_CARSPECIFICATIONVALUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CARSPECIFICATIONVALUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/car-specification-values';

// Actions

export const getEntities: ICrudGetAllAction<ICarSpecificationValue> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE_LIST,
  payload: axios.get<ICarSpecificationValue>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<ICarSpecificationValue> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CARSPECIFICATIONVALUE,
    payload: axios.get<ICarSpecificationValue>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ICarSpecificationValue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CARSPECIFICATIONVALUE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICarSpecificationValue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CARSPECIFICATIONVALUE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICarSpecificationValue> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CARSPECIFICATIONVALUE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
