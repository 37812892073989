import { IRiskCategory } from 'app/shared/model/risk-category.model';

export interface IKYCType {
  id?: number;
  name?: string;
  description?: string;
  riskCategories?: IRiskCategory[];
}

export const defaultValue: Readonly<IKYCType> = {};
