import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface IModalProps {
  open: boolean;
  setOpen: any;
  modalError: any;
  args?: any;
}

const ModalView: React.FC<IModalProps> = ({ open, setOpen, modalError, args }) => {
  const toggle = () => setOpen(!open);

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>{(modalError && modalError.title) || ''}</ModalHeader>
        <ModalBody>{(modalError && modalError.description) || ''}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalView;
