import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { setBlob, setInputData, createEntity } from '../quote.reducer';
import { getEntityByEmail } from 'app/entities/client/client.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntities as getVehicleTypes } from 'app/entities/vehicle-type/vehicle-type.reducer';
import { getEntities as getTonnageRanges } from 'app/entities/tonnage-range/tonnage-range.reducer';
import { IRiskCategory } from 'app/shared/model/risk-category.model';

export interface IQuoteProps extends StateProps, DispatchProps {
  jumpToStep;
  updateSelectedRiskCategory;
  getClientByEmail;
}

export interface IQuoteState {
  riskClassId: number;
  email: string;
  errorMessage: string;
  riskClass: any;
  vehicleType: any;
  vehicleTypes: any;
  riskCategoryId: string;
  riskCategory: IRiskCategory;
  noOfChildren: any;
  sumInsured: string;
  children: any[];
}

class QuoteRiskStep extends React.Component<IQuoteProps, IQuoteState> {
  constructor(props) {
    super(props);
    this.state = {
      riskClassId: null,
      email: '',
      errorMessage: '',
      vehicleType: null,
      riskClass: null,
      riskCategory: {},
      vehicleTypes: [],
      riskCategoryId: '0',
      noOfChildren: [],
      sumInsured: '',
      children: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { companyId } = this.props.account;
    this.props.getRiskCategories(0, 200, `,&companyId=${companyId}`);
    this.props.getCoverTypes(0, 20, `,&companyId=${companyId}`);
    this.props.getProductTypes(0, 20, `,&companyId=${companyId}`);

    this.setState({
      noOfChildren: Array.from(new Array(11), (val, index) => ({
        name: String(11 - index - 1),
        id: 11 - index - 1
      })).reverse()
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(1);
    }
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(
      { target: { files: [event[event.length - 1]] } },
      (contentType, data) => this.props.setBlob(name, data, contentType),
      isAnImage
    );
  };

  handleSubmit(event, errors, values) {
    if (errors.length === 0) {
      this.props.updateSelectedRiskCategory(values.riskCategoryId);
      const entity = {
        ...values,
        children: this.props.inputData.childDOBs,
        sendEmail: false,
        sumInsured: values.sumInsured && parseFloat(values.sumInsured.replace(/,/g, '')),
        productTypeId: this.state.riskCategory.productTypeId
      };
      this.props.createEntity(entity);
    }
  }

  formatValue = value => {
    if (!value) return '0';
    if (Number.isNaN(value)) return '0';
    const re = `\\B(?=(\\d{${3}})+(?!\\d))`;
    this.setState({
      sumInsured: String(Number(value.replace(/\D/g, '')).toFixed(0)).replace(new RegExp(re, 'g'), ',')
    });
  };

  handleDateChange = e => {
    const { inputData } = this.props;
    this.props.setInputData({ ...inputData, childDOBs: [...inputData.childDOBs, e.target.value] });
  };

  handleChildrenChange = e => {
    const childNo = this.state.noOfChildren.find(item => item.id === Number(e.target.value));
    let count = 0;
    const children = [];
    this.setState({ children: [] });
    while (count < childNo.id) {
      const newcount = count + 1;
      children.push({ id: 'child' + newcount });
      count++;
    }
    this.props.setInputData({ noOfChildren: childNo.id, children, childDOBs: [] });
  };

  handleRiskCategoryChange = e => {
    this.setState({ riskCategory: this.props.riskCategories.find(value => Number(value.id) === Number(e.target.value)) });
    this.props.getRiskClasses(0, 200, `,&riskCategoryId=${e.target.value}&companyId=${this.props.account.companyId}`);
  };

  handleVehicleChange = e => {
    const vehicleType = this.props.vehicleTypes.find(value => Number(value.id) === Number(e.target.value));
    this.setState({ vehicleType });
  };

  handleRiskChange = e => {
    const riskClass = this.props.riskClasses.find(value => Number(value.id) === Number(e.target.value));
    this.setState({ riskClass });
    riskClass &&
      riskClass.riskCategoryName === 'Motor' &&
      this.props.getVehicleTypes(
        0,
        20,
        `,&classificationCategory=${riskClass.classificationCategory}&companyId=${this.props.account.companyId}`
      );
  };

  render() {
    const { riskCategories, riskClasses, tonnageRanges, coverTypes, inputData, vehicleTypes } = this.props;
    const { riskCategory, vehicleType, riskClass } = this.state;
    return (
      <div className="step step1 mt-5 ">
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <AvForm onSubmit={this.handleSubmit}>
              <div className="form-group mb-5 mt-5">
                <Row>
                  <Col>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="risk-class-riskCategory">
                          Risk Category
                        </Label>
                        <Col sm="8">
                          <AvInput
                            id="risk-class-riskCategory"
                            type="select"
                            className="form-control"
                            name="riskCategoryId"
                            onChange={this.handleRiskCategoryChange}
                            required
                          >
                            <option value="" key="">
                              {' '}
                              Select{' '}
                            </option>
                            {riskCategories
                              ? sortBy(riskCategories, ['name']).map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <div className="text-danger">{this.state.errorMessage}</div>
                </Row>
                {!isEmpty(riskCategory) && (
                  <>
                    <Row>
                      <Col>
                        <AvGroup>
                          <Row>
                            <Label sm="4" for="risk-class-riskCategory">
                              Risk Class
                            </Label>
                            <Col sm="8">
                              <AvInput
                                id="quote-riskClass"
                                type="select"
                                className="form-control"
                                name="riskClassId"
                                onChange={this.handleRiskChange}
                                required
                              >
                                <option disabled value="" key="0" />
                                {riskClasses
                                  ? riskClasses.map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.name}
                                      </option>
                                    ))
                                  : null}
                              </AvInput>
                              <AvFeedback>This field is required.</AvFeedback>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    </Row>
                    {riskCategory && riskCategory.name === 'Health' && (
                      <>
                        <Row>
                          <Col>
                            <AvGroup>
                              <Row>
                                <Label sm="4" for="date-of-birth">
                                  Date of Birth
                                </Label>
                                <Col sm="8">
                                  <AvField id="date-of-birth" type="date" className="form-control" name="dateOfBirth" required />
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <AvGroup>
                              <Row>
                                <Label sm="4" for="spouse-date-of-birth">
                                  Spouse Date of Birth
                                </Label>
                                <Col sm="8">
                                  <AvField id="spouse-date-of-birth" type="date" className="form-control" name="spouseDOB" />
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <AvGroup>
                              <Row>
                                <Label sm="4" for="no-of-children">
                                  Number of Children
                                </Label>
                                <Col sm="8">
                                  <AvInput
                                    id="no-of-children"
                                    type="select"
                                    className="form-control"
                                    name="noOfChildren"
                                    onChange={this.handleChildrenChange}
                                    required
                                  >
                                    <option>Select a type</option>
                                    {this.state.noOfChildren
                                      ? this.state.noOfChildren.map(otherEntity => (
                                          <option value={otherEntity.id} key={otherEntity.id}>
                                            {otherEntity.name}
                                          </option>
                                        ))
                                      : null}
                                  </AvInput>
                                  <AvFeedback>This field is required.</AvFeedback>{' '}
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                        {riskClass &&
                          riskClass.name === 'International' &&
                          inputData &&
                          inputData.children.length &&
                          inputData.children.map(val => (
                            <Row key={val.id}>
                              <Col>
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" for={`child-${inputData.children.indexOf(val) + 1}-date-of-birth`}>
                                      Child {inputData.children.indexOf(val) + 1} Date of Birth
                                    </Label>
                                    <Col sm="8">
                                      <AvField
                                        id={`child-${inputData.children.indexOf(val) + 1}-date-of-birth`}
                                        type="date"
                                        className="form-control"
                                        onChange={this.handleDateChange}
                                        name="spouseDOB"
                                        required
                                      />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                            </Row>
                          ))}
                      </>
                    )}
                    {riskCategory && riskCategory.name === 'Motor' && (
                      <>
                        <Row>
                          <Col>
                            <AvGroup>
                              <Row>
                                <Label sm="4" for="binder-rate-coverType">
                                  Cover Type
                                </Label>
                                <Col sm="8">
                                  <AvInput id="binder-rate-coverType" type="select" className="form-control" name="coverTypeId" required>
                                    <option disabled value="" key="0" />
                                    {coverTypes
                                      ? coverTypes.map(otherEntity => (
                                          <option value={otherEntity.id} key={otherEntity.id}>
                                            {otherEntity.name}
                                          </option>
                                        ))
                                      : null}
                                  </AvInput>
                                  <AvFeedback>This field is required.</AvFeedback>
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <AvGroup>
                              <Row>
                                <Label sm="4" for="sumInsured">
                                  Sum Insured
                                </Label>
                                <Col sm="8">
                                  <AvField
                                    id="sumInsured"
                                    type="text"
                                    className="form-control"
                                    name="sumInsured"
                                    value={this.state.sumInsured}
                                    required
                                    onChange={value => this.formatValue(value.target.value)}
                                  />
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <AvGroup>
                              <Row>
                                <Label sm="4" for="vehicle-type">
                                  Vehicle Type
                                </Label>
                                <Col sm="8">
                                  <AvInput
                                    id="vehicle-type"
                                    type="select"
                                    className="form-control"
                                    name="vehicleTypeId"
                                    onChange={this.handleVehicleChange}
                                    required
                                  >
                                    <option>Select a type</option>
                                    {vehicleTypes
                                      ? vehicleTypes.map(otherEntity => (
                                          <option value={otherEntity.id} key={otherEntity.id}>
                                            {otherEntity.name}
                                          </option>
                                        ))
                                      : null}
                                  </AvInput>
                                  <AvFeedback>This field is required.</AvFeedback>
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                          {vehicleType && vehicleType.hasRange && (
                            <Col>
                              <AvGroup>
                                <Row>
                                  <Label sm="4" for="binder-rate-tonnageRange">
                                    Tonnage Range
                                  </Label>
                                  <Col sm="8">
                                    <AvInput id="binder-rate-tonnageRange" type="select" className="form-control" name="tonnageRangeId">
                                      <option value="" key="0" />
                                      {tonnageRanges
                                        ? tonnageRanges.map(otherEntity => (
                                            <option value={otherEntity.id} key={otherEntity.id}>
                                              {`${otherEntity.minValue} - ${otherEntity.maxValue} ${otherEntity.unit}`}
                                            </option>
                                          ))
                                        : null}
                                    </AvInput>
                                  </Col>
                                </Row>
                              </AvGroup>
                            </Col>
                          )}
                        </Row>
                      </>
                    )}
                  </>
                )}
              </div>
              <Col sm="12" className="mt-5">
                <FormGroup>
                  <Row>
                    <Col sm="12" />
                    <Col sm="12" className="text-right">
                      <Button type="submit" className="btn btn-success">
                        Next
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </AvForm>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskCategories: storeState.riskCategory.entities,
  riskClasses: storeState.riskClass.entities,
  coverTypes: storeState.coverType.entities,
  productTypes: storeState.productType.entities,
  vehicleTypes: storeState.vehicleType.entities,
  account: storeState.authentication.account,
  tonnageRanges: storeState.tonnageRange.entities,
  inputData: storeState.quote.inputData,
  updateSuccess: storeState.quote.updateSuccess
});

const mapDispatchToProps = {
  getRiskCategories,
  getRiskClasses,
  getCoverTypes,
  getVehicleTypes,
  setBlob,
  getEntityByEmail,
  getTonnageRanges,
  setInputData,
  getProductTypes,
  createEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRiskStep);
