import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISmsContent, defaultValue } from 'app/shared/model/sms-content.model';

export const ACTION_TYPES = {
  FETCH_SMSCONTENT_LIST: 'smsContent/FETCH_SMSCONTENT_LIST',
  FETCH_SMSCONTENT: 'smsContent/FETCH_SMSCONTENT',
  CREATE_SMSCONTENT: 'smsContent/CREATE_SMSCONTENT',
  UPDATE_SMSCONTENT: 'smsContent/UPDATE_SMSCONTENT',
  DELETE_SMSCONTENT: 'smsContent/DELETE_SMSCONTENT',
  RESET: 'smsContent/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISmsContent>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type SmsContentState = Readonly<typeof initialState>;

// Reducer

export default (state: SmsContentState = initialState, action): SmsContentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SMSCONTENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SMSCONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SMSCONTENT):
    case REQUEST(ACTION_TYPES.UPDATE_SMSCONTENT):
    case REQUEST(ACTION_TYPES.DELETE_SMSCONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SMSCONTENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SMSCONTENT):
    case FAILURE(ACTION_TYPES.CREATE_SMSCONTENT):
    case FAILURE(ACTION_TYPES.UPDATE_SMSCONTENT):
    case FAILURE(ACTION_TYPES.DELETE_SMSCONTENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SMSCONTENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_SMSCONTENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SMSCONTENT):
    case SUCCESS(ACTION_TYPES.UPDATE_SMSCONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SMSCONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/sms-contents';

// Actions

export const getEntities: ICrudGetAllAction<ISmsContent> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SMSCONTENT_LIST,
  payload: axios.get<ISmsContent>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<ISmsContent> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SMSCONTENT,
    payload: axios.get<ISmsContent>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ISmsContent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SMSCONTENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISmsContent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SMSCONTENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISmsContent> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SMSCONTENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
