import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody, Badge, Table } from 'reactstrap';
import { TextFormat, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'app/shared/common/dataTable';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getUsers, updateUser } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IUserManagementProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export class UserManagement extends React.Component<IUserManagementProps, IPaginationBaseState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const { isSuperAdmin } = this.props;
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`${isSuperAdmin ? '' : 'companyId=' + this.props.account.companyId}`);
    this.props.getUsers(state.page, state.pageSize, `${sort},${order}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  toggleActive = user => () => {
    this.props.updateUser({
      ...user,
      activated: !user.activated
    });
  };

  render() {
    const { loading, users, account, match, isPartner } = this.props;

    const tableData = users.map((prop, key) => {
      const disabled = account.login === prop.login;
      return {
        ...prop,
        id: (
          <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
            {prop.id}
          </Button>
        ),
        authorities: prop.authorities
          ? prop.authorities.map((authority, j) => (
              <div key={`user-auth-${key}-${j}`}>
                <Badge color="info">{authority}</Badge>
              </div>
            ))
          : null,
        activated: prop.activated ? (
          <Button color="success" disabled={disabled} onClick={this.toggleActive(prop)}>
            Activated
          </Button>
        ) : (
          <Button color="danger" disabled={disabled} onClick={this.toggleActive(prop)}>
            Deactivated
          </Button>
        ),
        createdDate: <TextFormat value={prop.createdDate} type="date" format={APP_DATE_FORMAT} blankOnInvalid />,
        lastModifiedDate: <TextFormat value={prop.lastModifiedDate} type="date" format={APP_DATE_FORMAT} blankOnInvalid />,
        actions: (
          <div className="text-center">
            <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.login}`}>
              <i className="fa fa-eye" />
            </Button>
            <Button
              className="ml-1"
              color="inverse"
              size="sm"
              round="true"
              icon="true"
              tag={NavLink}
              to={`${match.url}/${prop.login}/edit`}
            >
              <i className="fa fa-edit" />
            </Button>
            <Button
              disabled={disabled}
              className="ml-1"
              color="danger"
              size="sm"
              round="true"
              icon="true"
              tag={NavLink}
              to={`${match.url}/${prop.id}/delete`}
            >
              <i className="fa fa-times" />
            </Button>
          </div>
        )
      };
    });
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Users
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  Create a new {isPartner ? 'Agent' : 'User'}
                </NavLink>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Login',
                  accessor: 'login'
                },
                {
                  Header: 'Email',
                  accessor: 'email'
                },
                {
                  Header: 'Profiles',
                  accessor: 'authorities',
                  show: !isPartner
                },
                {
                  Header: 'Created Date',
                  accessor: 'createdDate'
                },
                {
                  Header: 'Agent Code',
                  accessor: 'agentCode'
                },
                {
                  Header: 'Last Modified Date',
                  accessor: 'lastModifiedDate'
                },
                {
                  Header: 'Status',
                  accessor: 'activated',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.userManagement.loading,
  users: storeState.userManagement.users,
  totalItems: storeState.userManagement.totalItems,
  account: storeState.authentication.account,
  isSuperAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SUPER_ADMIN]),
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER])
});

const mapDispatchToProps = { getUsers, updateUser };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagement);
