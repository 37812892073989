import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CarMake from './car-make';
import CarMakeDetail from './car-make-detail';
import CarMakeUpdate from './car-make-update';
import CarMakeDeleteDialog from './car-make-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CarMakeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CarMakeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CarMakeDetail} />
      <ErrorBoundaryRoute path={match.url} component={CarMake} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CarMakeDeleteDialog} />
  </>
);

export default Routes;
