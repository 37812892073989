import React from 'react';
import { NavLink as Link } from 'react-router-dom';

export interface ILinkItem {
  icon: string;
  to: string;
}

export default class HeaderItem extends React.Component<ILinkItem> {
  render() {
    const { to, icon, children } = this.props;
    return (
      <li className="sidebar-item">
        <Link to={to} className="sidebar-link text-white">
          <i className={icon} />
          <span className="hide-menu">{children}</span>
        </Link>
      </li>
    );
  }
}
