import { Moment } from 'moment';
import { Status } from 'app/shared/model/enumerations/status.model';
import { IAttachmentFile } from 'app/shared/model/attachment-file.model';

export interface IOffer {
  id?: number;
  name?: string;
  description?: string;
  status?: Status;
  amount?: number;
  startDate?: Moment;
  endDate?: Moment;
  topOffer?: boolean;
  image?: IAttachmentFile;
  purchaseCount?: number;
  viewsCount?: number;
  productTypeId?: number;
  productTypeName?: string;
  offerItems?: any[];
}

export const defaultValue: Readonly<IOffer> = {
  topOffer: false
};
