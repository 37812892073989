export const ACTION_TYPES = {
  ADD_ADDITIONAL_VEHICLE_INFO: 'addAdditionalVehicleInfo/ADD_ADDITIONAL_VEHICLE_INFO',
  ADD_ADDITIONAL_VEHICLE_INFO_RESET: 'addAdditionalVehicleInfo/ADD_ADDITIONAL_VEHICLE_INFO_RESET'
};

const initialState = {
  bodyType: '',
  chassisNumber: '',
  engineNumber: '',
  make: null,
  model: null,
  color: ''
};

export type VehicleAdditionalInfoState = typeof initialState;

export default (state: VehicleAdditionalInfoState = initialState, action): VehicleAdditionalInfoState => {
  switch (action.type) {
    case ACTION_TYPES.ADD_ADDITIONAL_VEHICLE_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const addVehicleAdditionalInfo = vehicleInfo => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.ADD_ADDITIONAL_VEHICLE_INFO,
    payload: vehicleInfo
  });
  return result;
};
