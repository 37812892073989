import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import utils from 'app/config/utils';

const LightBox = ({ attachments, name, onCloseRequest }) => (
  <Lightbox mainSrc={utils.getImageUrl(name, attachments)} onCloseRequest={onCloseRequest} />
);
LightBox.propTypes = {
  name: PropTypes.string,
  attachments: PropTypes.array,
  onCloseRequest: PropTypes.func
};

export default LightBox;
