import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './service-provider.reducer';
import { IServiceProvider } from 'app/shared/model/service-provider.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IServiceProviderProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class ServiceProvider extends React.Component<IServiceProviderProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { serviceProviderList, match } = this.props;
    return (
      <div>
        <h2 id="service-provider-heading">
          Service Providers
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new Service Provider
          </Link>
        </h2>
        <div className="table-responsive">
          {serviceProviderList && serviceProviderList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Contact Person</th>
                  <th>Branch</th>
                  <th>Rating</th>
                  <th>County</th>
                  <th>Division</th>
                  <th>Longitude</th>
                  <th>Latitude</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>User Type</th>
                  <th>Branch Type</th>
                  <th>Address</th>
                  <th>Street Address</th>
                  <th>Pin Number</th>
                  <th>Underwriter</th>
                  <th>Service Provider Type</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {serviceProviderList.map((serviceProvider, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${serviceProvider.id}`} color="link" size="sm">
                        {serviceProvider.id}
                      </Button>
                    </td>
                    <td>{serviceProvider.name}</td>
                    <td>{serviceProvider.type}</td>
                    <td>{serviceProvider.contactPerson}</td>
                    <td>{serviceProvider.branch}</td>
                    <td>{serviceProvider.rating}</td>
                    <td>{serviceProvider.county}</td>
                    <td>{serviceProvider.division}</td>
                    <td>{serviceProvider.longitude}</td>
                    <td>{serviceProvider.latitude}</td>
                    <td>{serviceProvider.email}</td>
                    <td>{serviceProvider.phone}</td>
                    <td>{serviceProvider.userType}</td>
                    <td>{serviceProvider.branchType}</td>
                    <td>{serviceProvider.address}</td>
                    <td>{serviceProvider.streetAddress}</td>
                    <td>{serviceProvider.pinNumber}</td>
                    <td>
                      {serviceProvider.underwriters
                        ? serviceProvider.underwriters.map((val, j) => (
                            <span key={j}>
                              <Link to={`underwriter/${val.id}`}>{val.name}</Link>
                              {j === serviceProvider.underwriters.length - 1 ? '' : ', '}
                            </span>
                          ))
                        : null}
                    </td>
                    <td>
                      {serviceProvider.serviceProviderTypes
                        ? serviceProvider.serviceProviderTypes.map((val, j) => (
                            <span key={j}>
                              <Link to={`service-provider-type/${val.id}`}>{val.name}</Link>
                              {j === serviceProvider.serviceProviderTypes.length - 1 ? '' : ', '}
                            </span>
                          ))
                        : null}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${serviceProvider.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${serviceProvider.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${serviceProvider.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">No Service Providers found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ serviceProvider }: IRootState) => ({
  serviceProviderList: serviceProvider.entities
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProvider);
