import React from 'react';

import { Button, Label, Alert, Row, Col, CustomInput } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import img2 from '../../assets/images/big/auth-bg.jpg';
import logolighticon from '../../assets/images/logo-light.svg';
export interface ILoginModalProps {
  loginError: boolean;
  handleLogin: Function;
}

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover'
};

class LoginForm extends React.Component<ILoginModalProps> {
  handleSubmit = (event, errors, { username, password, rememberMe }) => {
    const { handleLogin } = this.props;
    handleLogin(username, password, rememberMe);
  };

  render() {
    const { loginError } = this.props;

    return (
      <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center">
              <Col md="6" lg="4" className="bg-dark text-white">
                <div className="p-4">
                  <div className="d-flex app-title-container">
                    <div className="d-flex flex-column justify-content-center pr-3">
                      <img src={logolighticon} alt="homepage" className="light-logo" />
                    </div>
                    {/* <div className="d-flex flex-column">
                      <h2 className="mt-2 mb-0 text-cyan font-bold">IBS</h2>
                      <p className="op-5 mb-0 font-light text-uppercase font-14">Insurance Brokerage System</p>
                    </div> */}
                  </div>
                  <p className="op-5 mt-2">IBS - Insurance Brokerage System is provides you with a one-stop automated insurance tool.</p>
                  <p className="op-5 mt-2">We take care of all the hard work so you can focus on taking care of your customers.</p>
                  <p className="op-5 mt-2">Our SaaS is best suited to brokers and agents</p>
                  <p className="op-5 mt-5">Sign Up Today!</p>
                </div>
              </Col>
              <Col md="6" lg="4" className="bg-white">
                <div className="p-4">
                  <h3 className="font-medium mb-3">Sign In</h3>
                  {loginError && (
                    <Col md="12">
                      <Alert color="danger">
                        <strong>Failed to sign in!</strong> Please check your credentials and try again.
                      </Alert>
                    </Col>
                  )}
                  <AvForm className="mt-3" id="loginform" onSubmit={this.handleSubmit}>
                    <AvGroup>
                      <Label for="username" className="font-medium">
                        Username
                      </Label>
                      <AvInput name="username" id="username" placeholder="Username" required autoFocus size="lg" />
                      <AvFeedback>Username cannot be empty!</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                      <Label for="password" className="mt-3 font-medium">
                        Password
                      </Label>
                      <AvInput type="password" name="password" id="password" placeholder="Password" required size="lg" />
                      <AvFeedback>Password cannot be empty!</AvFeedback>
                    </AvGroup>
                    <div className="d-flex no-block align-items-center justify-content-between mb-4 mt-4">
                      <AvInput tag={CustomInput} type="checkbox" name="rememberMe" label="Remember Me" />
                      <Link to="/reset/request">Forgot Password</Link>
                    </div>
                    <Row className="mb-3">
                      <Col xs="12">
                        <Button color="primary" size="lg" type="submit" block>
                          Log In
                        </Button>
                      </Col>
                    </Row>
                    <div className="text-center">
                      Don&apos;t have an account?{' '}
                      <Link to="/register" className="text-info ml-1">
                        <b>Sign Up</b>
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;
