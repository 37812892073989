import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IRiskClass } from 'app/shared/model/risk-class.model';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './cover-type.reducer';
import { ICoverType } from 'app/shared/model/cover-type.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import sortBy from 'lodash/sortBy';

export interface ICoverTypeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICoverTypeUpdateState {
  isNew: boolean;
  riskClassId: string;
}

export class CoverTypeUpdate extends React.Component<ICoverTypeUpdateProps, ICoverTypeUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      riskClassId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getRiskClasses(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getProductTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { coverTypeEntity } = this.props;
      const entity = {
        ...coverTypeEntity,
        ...values,
        companyId: this.props.account.companyId
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/cover-type');
  };

  render() {
    const { coverTypeEntity, riskClasses, productTypes, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a CoverType
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : coverTypeEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="cover-type-name">
                            Name
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="cover-type-name"
                              type="text"
                              name="name"
                              placeholder="Enter name"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      {/* <AvGroup>
                        <Row>
                          <Label sm="2" id="brokerCommissionLabel" for="cover-type-brokerCommission">
                            Broker Commission
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="cover-type-brokerCommission"
                              type="string"
                              className="form-control"
                              name="brokerCommission"
                              placeholder="Enter broker commission"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' },
                                number: { value: true, errorMessage: 'This field should be a number.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup> */}
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="descriptionLabel" for="cover-type-description">
                            Description
                          </Label>
                          <Col sm="10">
                            <AvField id="cover-type-description" placeholder="Enter description" type="text" name="description" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="startDateLabel" for="client-policy-startDate">
                            Start Date
                          </Label>
                          <Col sm="10">
                            <AvField id="client-policy-startDate" type="date" className="form-control" name="startDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="endDateLabel" for="client-policy-endDate">
                            End Date
                          </Label>
                          <Col>
                            <AvField id="client-policy-endDate" type="date" className="form-control" name="endDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="cover-type-riskClass">
                            Risk Class
                          </Label>
                          <Col sm="10">
                            <AvInput id="cover-type-riskClass" type="select" className="form-control" name="riskClassId" required>
                              {riskClasses
                                ? sortBy(riskClasses, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="cover-type-riskClass">
                            Product Type
                          </Label>
                          <Col sm="10">
                            <AvInput id="cover-type-productType" type="select" className="form-control" name="productTypeId" required>
                              {productTypes
                                ? sortBy(productTypes, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/cover-type" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskClasses: storeState.riskClass.entities,
  productTypes: storeState.productType.entities,
  coverTypeEntity: storeState.coverType.entity,
  loading: storeState.coverType.loading,
  updating: storeState.coverType.updating,
  updateSuccess: storeState.coverType.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getRiskClasses,
  getProductTypes,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverTypeUpdate);
