export const enum CalculationParam {
  SUM_INSURED = 'SUM_INSURED',
  RATE = 'RATE',
  NET_PREMIUM = 'NET_PREMIUM',
  TL = 'TL',
  PCF = 'PCF',
  SD = 'SD',
  GROSS_PREMIUM = 'GROSS_PREMIUM',
  COMMISION_RATE = 'COMMISION_RATE',
  USER_INPUT = 'USER_INPUT',
  COMMISSION = 'COMMISSION',
  FIXED = 'FIXED'
}

export interface IFormulaParam {
  id?: number;
  name?: string;
  calculationParam?: CalculationParam;
  value?: number;
  riskClassFormulaId?: number;
}

export const defaultValue: Readonly<IFormulaParam> = {};
