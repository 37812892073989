import React from 'react';

import { connect } from 'react-redux';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Button, Col, Row, Label } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import img2 from '../../../../assets/images/big/auth-bg.jpg';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover'
};

export interface IPasswordResetInitProps extends StateProps, DispatchProps, RouteComponentProps<{ key: any }> {}

export class PasswordResetInit extends React.Component<IPasswordResetInitProps> {
  componentWillUnmount() {
    this.props.reset();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.resetPasswordSuccess !== this.props.resetPasswordSuccess && nextProps.resetPasswordSuccess) {
      this.props.history.push('/reset/init-success');
    }
  }

  handleValidSubmit = (event, values) => {
    this.props.handlePasswordResetInit(values.email);
    event.preventDefault();
  };

  render() {
    return (
      <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
        <div className="auth-box">
          <div id="loginform">
            <div className="logo">
              <h5 className="font-medium mb-3">Reset your password</h5>
            </div>
            <Row>
              <Col xs="12">
                <AvForm onValidSubmit={this.handleValidSubmit}>
                  <AvGroup>
                    <Label for="username" className="font-medium">
                      Email
                    </Label>
                    <AvField
                      name="email"
                      placeholder={'Your email'}
                      type="email"
                      size="lg"
                      validate={{
                        required: { value: true, errorMessage: 'Your email is required.' },
                        minLength: { value: 5, errorMessage: 'Your email is required to be at least 5 characters.' },
                        maxLength: { value: 254, errorMessage: 'Your email cannot be longer than 50 characters.' }
                      }}
                    />
                  </AvGroup>
                  <Row className="mb-3 mt-3">
                    <Col xs="12">
                      <Button color="primary" size="lg" type="submit" block>
                        Reset password
                      </Button>
                    </Col>
                  </Row>
                  <div className="text-center">
                    Already have an account?{' '}
                    <Link to="/login" className="text-info ml-1">
                      <b>Sign In</b>
                    </Link>
                  </div>
                </AvForm>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (rootState: IRootState) => ({
  resetPasswordSuccess: rootState.passwordReset.resetPasswordSuccess
});

const mapDispatchToProps = { handlePasswordResetInit, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetInit);
