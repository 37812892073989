import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './company.reducer';
import { ICompany } from 'app/shared/model/company.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ICompanyUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICompanyUpdateState {
  isNew: boolean;
  telephone: string;
  mobile: string;
  country: string;
}

export class CompanyUpdate extends React.Component<ICompanyUpdateProps, ICompanyUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      telephone: '',
      mobile: '',
      country: ''
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    this.props.getProductTypes(0, 20, `,&companyId=${this.props.account.companyId}`);
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  onCountry = country => {
    this.setState({ country });
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { companyEntity, productTypes } = this.props;
      const entity = {
        ...companyEntity,
        ...values,
        companyId: this.props.account.companyId,
        mobile: this.state.mobile,
        telephone: this.state.telephone,
        productTypes: productTypes.filter(p => values.productTypes.map(e => Number(e)).includes(p.id)),
        country: this.state.country
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    // this.props.history.push('/entity/company');
  };
  handlePhone = telephone => {
    this.setState({ telephone });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  render() {
    const { companyEntity, loading, productTypes, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a Company
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : companyEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="usertypeLabel" for="company-usertype">
                            Usertype
                          </Label>
                          <Col md="10">
                            <AvInput
                              id="company-usertype"
                              type="select"
                              className="form-control"
                              name="usertype"
                              value={(!isNew && companyEntity.usertype) || 'COMPANY'}
                            >
                              <option value="COMPANY">COMPANY</option>
                              <option value="INDIVIDUAL">INDIVIDUAL</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="firstNameLabel" for="company-firstName">
                            First Name
                          </Label>
                          <Col sm="10">
                            <AvField id="company-firstName" placeholder="Enter first name" type="text" name="firstName" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="middleNameLabel" for="company-middleName">
                            Middle Name
                          </Label>
                          <Col sm="10">
                            <AvField id="company-middleName" placeholder="Enter middle name" type="text" name="middleName" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="lastNameLabel" for="company-lastName">
                            Last Name
                          </Label>
                          <Col sm="10">
                            <AvField id="company-lastName" placeholder="Enter last name" type="text" name="lastName" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="company-name">
                            Name
                          </Label>
                          <Col sm="10">
                            <AvField placeholder="Enter name" id="company-name" type="text" name="name" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="emailLabel" for="company-email">
                            Email
                          </Label>
                          <Col sm="10">
                            <AvField id="company-email" placeholder="Enter email address" type="text" name="email" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="displayNameLabel" for="company-displayName">
                            Display Name
                          </Label>
                          <Col sm="10">
                            <AvField id="company-displayName" placeholder="Enter display name" type="text" name="displayName" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="logoLabel" for="company-logo">
                            Logo
                          </Label>
                          <Col sm="10">
                            <AvField id="company-logo" placeholder="Enter logo" type="text" name="logo" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="telephoneLabel" for="company-telephone">
                            Telephone
                          </Label>
                          <Col sm="10">
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={(!isNew && companyEntity.telephone) || ''}
                              id="company-telephone"
                              name="telephone"
                              onChange={this.handlePhone}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="contactPersionLabel" for="company-contactPersion">
                            Contact Person
                          </Label>
                          <Col sm="10">
                            <AvField id="company-contactPersion" placeholder="Enter contact person" type="text" name="contactPersion" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="mobileLabel" for="company-mobile">
                            Mobile
                          </Label>
                          <Col sm="10">
                            <PhoneInput
                              placeholder="Enter mobile number"
                              value={(!isNew && companyEntity.mobile) || ''}
                              id="company-mobile"
                              name="mobile"
                              onChange={this.handleMobile}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="addressLabel" for="company-address">
                            Address
                          </Label>
                          <Col sm="10">
                            <AvField id="company-address" placeholder="Enter address" type="text" name="address" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="streetAddressLabel" for="company-streetAddress">
                            Street Address
                          </Label>
                          <Col sm="10">
                            <AvField id="company-streetAddress" placeholder="Enter street address" type="text" name="streetAddress" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="countyLabel" for="company-county">
                            County
                          </Label>
                          <Col sm="10">
                            <AvField id="company-county" placeholder="Enter county" type="text" name="county" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="countryLabel" for="company-country">
                            Country
                          </Label>
                          <Col sm="10">
                            <ReactFlagsSelect
                              id="company-country"
                              defaultCountry={(!isNew && companyEntity.country) || ''}
                              onSelect={this.onCountry}
                              name="country"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="pinNumberLabel" for="company-pinNumber">
                            Postal Code
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="company-pinNumber"
                              placeholder="Enter Postal Code"
                              type="number"
                              className="form-control"
                              name="pinNumber"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="productTypes">
                            Products
                          </Label>
                          <Col sm="10">
                            <AvInput
                              type="select"
                              className="form-control"
                              name="productTypes"
                              value={companyEntity.productTypes && companyEntity.productTypes.map(s => s.id)}
                              multiple
                            >
                              {productTypes &&
                                productTypes.map(p => (
                                  <option value={p.id} key={p.id}>
                                    {p.name}
                                  </option>
                                ))}
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/company" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  companyEntity: storeState.company.entity,
  loading: storeState.company.loading,
  updating: storeState.company.updating,
  updateSuccess: storeState.company.updateSuccess,
  productTypes: storeState.productType.entities,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getProductTypes,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUpdate);
