import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const UnderwritersMenu = () => (
  <MenuItem to="/entity/underwriter" icon="mdi mdi-pencil-box-outline">
    Underwriters
  </MenuItem>
);

export default UnderwritersMenu;
