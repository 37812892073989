import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, Label, FormGroup, CardBody, Input, Button } from 'reactstrap';

// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from 'app/shared/common/dataTable';
import { IRootState } from 'app/shared/reducers';
import { getEntities, exportBinderRates } from './binder-rate.reducer';
import { IBinderRate } from 'app/shared/model/binder-rate.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import sortBy from 'lodash/sortBy';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';

export interface IQuoteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IBinderRateProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IBinderRateState extends IPaginationBaseState {
  riskClassId: string;
  productTypeId: string;
}

export class BinderRate extends React.Component<IBinderRateProps, IBinderRateState> {
  private readonly reactTable: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      riskClassId: '',
      productTypeId: ''
    };
    this.fetchData = this.fetchData.bind(this);
    this.reactTable = React.createRef();
  }

  componentDidMount() {
    this.loadInitial();
  }

  async loadInitial() {}

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { productTypes, companyId } = this.props.account;
    let productTypeId;
    if (productTypes && productTypes.length === 1) {
      productTypeId = String(productTypes[0].id);
      this.setState({ productTypeId });
    } else {
      productTypeId = this.state.productTypeId;
    }
    const { riskClassId } = this.state;
    filtered.push(`companyId=${companyId}`);
    Promise.all([
      this.props.getEntities(
        state.page,
        state.pageSize,
        `${sort},${order}&productTypeId=${productTypeId}&riskClassId=${riskClassId}&${filtered.join('&')}`
      ),
      this.props.getRiskClasses(0, 200, `,&productTypeId=${productTypeId}&companyId=${companyId}`),
      this.props.getProductTypes(0, 20, `,&${productTypes.map(a => `id=${a.id}`).join('&')}&companyId=${companyId}`)
    ]);
    this.setState({ itemsPerPage: state.pageSize });
  }

  handleExport = () => {
    const { riskClassId, productTypeId } = this.state;
    const queryString = `riskClassId=${riskClassId}&productTypeId=${productTypeId}&companyId=${this.props.account.companyId}`;
    const { page, pageSize } = this.reactTable.current.state;
    this.props.exportBinderRates(page, pageSize, queryString, 'binderRates');
  };

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  handleProductChange = async productTypeId => {
    await this.setState({ productTypeId });
    if (this.reactTable.current) {
      this.fetchData(this.reactTable.current.state);
    }
  };

  handleRiskChange = async riskClassId => {
    await this.setState({ riskClassId });
    if (this.reactTable.current) {
      this.fetchData(this.reactTable.current.state);
    }
  };

  render() {
    const { loading, binderRateList, match, productTypes, riskClasses, exportLoading, totalItems } = this.props;
    const { productTypeId, riskClassId } = this.state;
    const isHealth = productTypeId && productTypeId === '3';
    const isMotor = productTypeId && productTypeId === '1';
    const tableData = binderRateList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      rate: prop.rate ? prop.rate : '0.0%',
      ageRange: `${prop.minAge || ''} - ${prop.maxAge || ''}`,
      tonnage: `${prop.minTonnage || ''} - ${prop.maxTonnage || ''}`,
      underwriterId: prop.underwriterId ? <NavLink to={`underwriter/${prop.underwriterId}`}>{prop.underwriterId}</NavLink> : '',
      riskClassId: prop.riskClassId ? <NavLink to={`risk-class/${prop.riskClassId}`}>{prop.riskClassId}</NavLink> : '',
      coverTypeId: prop.coverTypeId ? <NavLink to={`cover-type/${prop.coverTypeId}`}>{prop.coverTypeId}</NavLink> : '',
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));

    const styles = {
      fontSize: '10px'
    };
    return (
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <Row>
            <Col sm="6">
              <i className="mdi mdi-border-right mr-2" />
              Binder Rates
            </Col>
            <Col sm="6" className="d-flex justify-content-end align-items-center">
              <Row>
                <Col>
                  <Button disabled={exportLoading} loading={exportLoading} onClick={this.handleExport} color="success" type="button">
                    <i className="mdi mdi-search-web" />
                    Export to Excel
                  </Button>
                </Col>
                <Col>
                  <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                    Create new Binder Rate
                  </NavLink>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mb-2 mt-3">
            <Col md="4">
              <FormGroup className="mb-0">
                <Row>
                  <Label sm="3" className="pr-0" for="quote-productType">
                    Product
                  </Label>
                  <Col sm="7">
                    <Input
                      id="quote-productType"
                      type="select"
                      name="productTypeId"
                      required
                      value={productTypeId}
                      onChange={event => this.handleProductChange(event.target.value)}
                    >
                      <option value="" key="">
                        {' '}
                        Select{' '}
                      </option>
                      {productTypes
                        ? sortBy(productTypes, ['name']).map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </option>
                          ))
                        : null}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup className="mb-0">
                <Row>
                  <Label sm="3" className="pr-0" for="risk-class-riskCategory">
                    Risk Class
                  </Label>
                  <Col sm="7">
                    <Input
                      id="quote-risk-class"
                      type="select"
                      name="riskClassId"
                      value={riskClassId}
                      required
                      onChange={event => this.handleRiskChange(event.target.value)}
                    >
                      <option value="" key="">
                        {' '}
                        Select{' '}
                      </option>
                      {riskClasses
                        ? sortBy(riskClasses, ['name']).map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </option>
                          ))
                        : null}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <DataTable
            columns={[
              {
                Header: 'Underwriter',
                accessor: 'underwriterName'
              },
              {
                Header: 'Product',
                show: isMotor,
                accessor: 'productName'
              },
              {
                Header: 'Product Name',
                show: isHealth,
                accessor: 'underwriterProductName'
              },
              {
                Header: 'Risk Class',
                accessor: 'riskClassName'
              },
              {
                Header: 'Rate',
                accessor: 'rate'
              },
              {
                Header: 'Value',
                accessor: 'minimumValue'
              },
              {
                Header: 'MinSumInsured',
                show: isMotor,
                accessor: 'minSumInsured'
              },
              {
                Header: 'MaxSumInsured',
                show: isMotor,
                accessor: 'maxSumInsured'
              },
              {
                Header: 'Tonnage',
                show: isMotor,
                accessor: 'tonnage'
              },
              {
                Header: 'Spouse Discount',
                show: isHealth,
                accessor: 'discountRate'
              },
              {
                Header: 'Child Value',
                show: isHealth,
                accessor: 'childMinimumValue'
              },
              {
                Header: 'Age Range',
                show: isHealth,
                accessor: 'ageRange',
                sortable: false,
                filterable: false
              },
              {
                Header: 'Cover Type',
                accessor: 'coverTypeName'
              },
              {
                Header: 'Actions',
                accessor: 'actions',
                sortable: false,
                filterable: false
              }
            ]}
            manual
            defaultPageSize={10}
            tableRef={this.reactTable}
            pages={this.getMaxPage()}
            loading={loading}
            onFetchData={this.fetchData}
            showPaginationBottom
            tableData={tableData}
            styles={styles}
            filterable
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ binderRate, productType, riskClass, authentication }: IRootState) => ({
  loading: binderRate.loading,
  account: authentication.account,
  riskClasses: riskClass.entities,
  exportLoading: binderRate.exportLoading,
  productTypes: productType.entities,
  binderRateList: binderRate.entities,
  totalItems: binderRate.totalItems
});

const mapDispatchToProps = {
  getEntities,
  getRiskClasses,
  getProductTypes,
  exportBinderRates
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BinderRate);
