import React from 'react';
import { Collapse } from 'reactstrap';
import MenuItem from 'app/shared/layout/menus/link-item';

export const ClientsMenu = ({ isAdmin, account }) => (
  <MenuItem to="/clients" icon="mdi mdi-account-network">
    Clients
  </MenuItem>
);

export default ClientsMenu;
