import React from 'react';
import { Card, CardBody } from 'reactstrap';

const containerStyle = {
  height: '100vh',
  width: '85%',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const cardStyle = {
  width: '85%',
  height: '60vh'
};

const RegisterInitSuccess: React.FC = () => (
  <div style={containerStyle}>
    <Card style={cardStyle}>
      <CardBody className="text-center">
        <h3>Congratulations!</h3>
        <h4>Registration successful!</h4>
        <h6>Please Check your inbox to verify your email.</h6>
      </CardBody>
    </Card>
  </div>
);

export default RegisterInitSuccess;
