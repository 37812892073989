import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CarSpecificationValue from './car-specification-value';
import CarSpecificationValueDetail from './car-specification-value-detail';
import CarSpecificationValueUpdate from './car-specification-value-update';
import CarSpecificationValueDeleteDialog from './car-specification-value-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CarSpecificationValueUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CarSpecificationValueUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CarSpecificationValueDetail} />
      <ErrorBoundaryRoute path={match.url} component={CarSpecificationValue} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CarSpecificationValueDeleteDialog} />
  </>
);

export default Routes;
