import { defaultValue, IBillWiseReportClass } from '../billwise-model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudGetAllAction } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_BILLWISE_REPORTS: 'reports/FETCH_BILLWISE_REPORTS'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entity: defaultValue,
  entities: [] as ReadonlyArray<IBillWiseReportClass>,
  updating: false,
  response: null,
  updateSuccess: false
};
export type BillWiseReportsState = Readonly<typeof initialState>;

export default (state: BillWiseReportsState = initialState, action): BillWiseReportsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BILLWISE_REPORTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };

    case SUCCESS(ACTION_TYPES.FETCH_BILLWISE_REPORTS):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        entity: action.payload.data,
        updateSuccess: true,
        response: action.payload.data
      };

    default:
      return state;
  }
};
const apiUrl = 'api/billwise-reports';

export const getEntities: ICrudGetAllAction<IBillWiseReportClass> = (page = 0, size = 10, sort = ' ,') => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_BILLWISE_REPORTS,
    payload: axios.get<IBillWiseReportClass>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};
