import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getEntity as getRemit } from 'app/entities/remittance/remittance.reducer';
import { getEntity as getUnderwriter } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getClientPolicyPayments } from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import RemitPreview from 'app/modules/reports/remittance/remittance-preview';
import PDFPreview from 'app/scenes/pdf-preview';

export interface IRemitDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IRemitDetailsState extends IPaginationBaseState {
  transCode: string;
  underwriterId: string;
}

export class Remittance extends React.Component<IRemitDetailsProps, IRemitDetailsState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      transCode: '',
      underwriterId: ''
    };
  }

  componentDidMount() {
    const { log } = console;
    const { match, account } = this.props;
    Promise.resolve(this.props.getRemit(match.params.id))
      .then(() => {
        const { remitEntity } = this.props;
        log(remitEntity);
        this.props.getUnderwriter(remitEntity.underwriterId);
        this.props.getClientPolicyPayments(0, 2000, `,&companyId=${account.companyId}&remittanceId=${remitEntity.id}`);
      })
      .catch(err => log(err));
  }

  render() {
    const { clientPolicyPayments, account, underwriterEntity, remitEntity } = this.props;
    const name = `${underwriterEntity.name}-Remit-${remitEntity.id}`;
    // @ts-ignore
    return (
      // @ts-ignore
      <PDFPreview title={name} className={''} height="900px" width="100%" fileName={`${name}.pdf`}>
        <RemitPreview
          account={account}
          underwriter={underwriterEntity}
          remittance={remitEntity}
          clientPolicyPayments={clientPolicyPayments}
        />
      </PDFPreview>
    );
  }
}

const mapStateToProps = (iRootState: IRootState) => ({
  account: iRootState.authentication.account,
  underwriterEntity: iRootState.underwriter.entity,
  remitEntity: iRootState.remittance.entity,
  clientPolicyPayments: iRootState.clientPolicyPayment.entities
});

const mapDispatchToProps = {
  getRemit,
  getUnderwriter,
  getClientPolicyPayments
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Remittance);
