import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './levies.reducer';
import { ILevies } from 'app/shared/model/levies.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ILeviesUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ILeviesUpdateState {
  isNew: boolean;
}

export class LeviesUpdate extends React.Component<ILeviesUpdateProps, ILeviesUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { leviesEntity } = this.props;
      const entity = {
        ...leviesEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/levies');
  };

  render() {
    const { leviesEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a Levies
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : leviesEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="chargeNameLabel" for="levies-chargeName">
                            Charge Name
                          </Label>
                          <Col sm="10">
                            <AvField id="levies-chargeName" type="text" name="chargeName" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="abbreviationLabel" for="levies-abbreviation">
                            Abbreviation
                          </Label>
                          <Col sm="10">
                            <AvField id="levies-abbreviation" type="text" name="abbreviation" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="descriptionLabel" for="levies-description">
                            Description
                          </Label>
                          <Col sm="10">
                            <AvField id="levies-description" type="text" name="description" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="countryLabel" for="levies-country">
                            Country
                          </Label>
                          <Col sm="10">
                            <AvField id="levies-country" type="text" name="country" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label id="startDateLabel" for="levies-startDate">
                            Start Date
                          </Label>
                          <Col sm="10">
                            <AvField id="levies-startDate" type="date" className="form-control" name="startDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label id="endDateTimeLabel" for="levies-endDateTime">
                            End Date Time
                          </Label>
                          <Col sm="10">
                            <AvField id="levies-endDateTime" type="date" className="form-control" name="endDateTime" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="statusLabel" for="levies-status">
                            Status
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="levies-status"
                              type="select"
                              className="form-control"
                              name="status"
                              value={(!isNew && leviesEntity.status) || 'ACTIVE'}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="INACTIVE">INACTIVE</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="rateTypeLabel" for="levies-rateType">
                            Rate Type
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="levies-rateType"
                              type="select"
                              className="form-control"
                              name="rateType"
                              value={(!isNew && leviesEntity.rateType) || 'FIXED'}
                            >
                              <option value="FIXED">FIXED</option>
                              <option value="PERCENTAGE">PERCENTAGE</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="rateValueLabel" for="levies-rateValue">
                            Rate Value
                          </Label>
                          <Col sm="10">
                            <AvField id="levies-rateValue" type="string" className="form-control" name="rateValue" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/levies" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  leviesEntity: storeState.levies.entity,
  loading: storeState.levies.loading,
  updating: storeState.levies.updating,
  updateSuccess: storeState.levies.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeviesUpdate);
