import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getShortCodes } from 'app/entities/short-codes/short-codes.reducer';
import { getEntity, updateEntity, createEntity, reset } from './direct-payment.reducer';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import sortBy from 'lodash/sortBy';

export interface IDirectPaymentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IDirectPaymentUpdateState {
  isNew: boolean;
  riskClassId: string;
  categoryId: number;
}

export class DirectPaymentUpdate extends React.Component<IDirectPaymentUpdateProps, IDirectPaymentUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      riskClassId: '0',
      categoryId: null,
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getRiskCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getProductTypes(0, 200, `,&companyId=${this.props.account.companyId}`);
    this.props.getShortCodes(0, 20, `,&companyId=${this.props.account.companyId}`);
  }

  handleCategoryChange = categoryId => {
    this.props.getRiskClasses(0, 200, `,&riskCategoryId=${categoryId}&companyId=${this.props.account.companyId}`);
    this.setState({ categoryId });
  };

  saveEntity = async (event, errors, values) => {
    if (errors.length === 0) {
      const { directPaymentEntity } = this.props;
      const entity = {
        ...directPaymentEntity,
        ...values,
        companyId: this.props.account.companyId
      };

      if (this.state.isNew) {
        const dataResult = await this.props.createEntity(entity);
        const { log } = console;
        log(dataResult);
        this.props.history.push(`/entity/direct-payments/${directPaymentEntity.id}/debit`);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/direct-payments');
  };

  render() {
    const { directPaymentEntity, productTypes, riskCategories, riskClasses, shortCodes, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a DirectPayment
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : directPaymentEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="direct-payment-name">
                            Transaction ID
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="direct-payment-trans-id"
                              type="text"
                              name="transId"
                              placeholder="Enter Trans ID"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="direct-payment-name">
                            Mobile Number
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="direct-payment-trans-id"
                              type="text"
                              name="msisdn"
                              placeholder="Enter mobile number"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="direct-payment-amount">
                            Trans Amount
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="direct-payment-amount"
                              type="text"
                              name="transAmount"
                              placeholder="Enter amount"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="paymentTypeLabel" for="direct-payment-paymentType">
                            Payment Type
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="direct-payment-paymentType"
                              type="select"
                              name="paymentType"
                              value={(!isNew && directPaymentEntity.paymentType) || 'FROM_CLIENT'}
                            >
                              <option value="FROM_CLIENT">FROM CLIENT</option>
                              <option value="FROM_UNDERWRITER">FROM UNDERWRITER</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      {/* <AvGroup>
                        <Row>
                          <Label sm="2" id="brokerCommissionLabel" for="direct-payment-brokerCommission">
                            Broker Commission
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="direct-payment-brokerCommission"
                              type="string"
                              className="form-control"
                              name="brokerCommission"
                              placeholder="Enter broker commission"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' },
                                number: { value: true, errorMessage: 'This field should be a number.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup> */}
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="descriptionLabel" for="direct-payment-description">
                            Description
                          </Label>
                          <Col sm="10">
                            <AvField id="direct-payment-description" placeholder="Enter description" type="text" name="description" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="payDateLabel" for="client-policy-payDate">
                            Pay Date
                          </Label>
                          <Col sm="10">
                            <AvField id="client-policy-createdAt" type="datetime-local" className="form-control" name="createdAt" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="direct-payment-productType">
                            Product Type
                          </Label>
                          <Col sm="10">
                            <AvInput id="direct-payment-productType" type="select" className="form-control" name="productTypeId" required>
                              <option value={''} key={''}>
                                Select Product Type
                              </option>
                              {productTypes
                                ? sortBy(productTypes, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="direct-payment-riskCategory">
                            Risk Category
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="direct-payment-riskCategory"
                              type="select"
                              onChange={e => this.handleCategoryChange(e.target.value)}
                              className="form-control"
                              name="riskCategoryId"
                              required
                            >
                              <option value={''} key={''}>
                                Select Risk Category
                              </option>
                              {riskCategories
                                ? sortBy(riskCategories, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="direct-payment-riskClass">
                            Risk Class
                          </Label>
                          <Col sm="10">
                            <AvInput id="direct-payment-riskClass" type="select" className="form-control" name="riskClassId" required>
                              <option value={''} key={''}>
                                Select Risk Class
                              </option>
                              {riskClasses
                                ? sortBy(riskClasses, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="direct-payment-shortCode">
                            ShortCode
                          </Label>
                          <Col sm="10">
                            <AvInput id="direct-payment-shortCode" type="select" className="form-control" name="BusinessShortCode" required>
                              <option value={''} key={''}>
                                Select Shortcode
                              </option>
                              {shortCodes
                                ? sortBy(shortCodes, ['shortcode']).map(otherEntity => (
                                    <option value={otherEntity.shortcode} key={otherEntity.shortcode}>
                                      {otherEntity.shortcode}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="direct-payment-agentCode">
                            Agent Code
                          </Label>
                          <Col sm="10">
                            <AvField id="direct-payment-agentCode" type="text" name="agentCode" placeholder="Enter agent Code" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/direct-payments" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  shortCodes: storeState.shortCodes.entities,
  riskClasses: storeState.riskClass.entities,
  riskCategories: storeState.riskCategory.entities,
  productTypes: storeState.productType.entities,
  directPaymentEntity: storeState.paymentCallbacks.entity,
  loading: storeState.paymentCallbacks.loading,
  updating: storeState.paymentCallbacks.updating,
  updateSuccess: storeState.paymentCallbacks.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getRiskClasses,
  getRiskCategories,
  getProductTypes,
  getShortCodes,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectPaymentUpdate);
