import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, ModalFooter, Button } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { deleteEntity, getEntity, setBlob } from 'app/entities/kyc/kyc.reducer';
import sortBy from 'lodash/sortBy';
import Lightbox from 'react-image-lightbox';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { setFileData } from 'react-jhipster';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';

export interface IUnderwriterDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAddKYCDialogState {
  isNew: boolean;
  clientId: string;
  riskClassId: string;
  attachmentId: string;
  categoryId: string;
  documentType: string;
  isFullScreen: boolean;
  isOpen: boolean;
}

export class AddKYCDialog extends React.Component<IUnderwriterDeleteDialogProps, IAddKYCDialogState> {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '0',
      riskClassId: '0',
      attachmentId: '0',
      isNew: true,
      categoryId: null,
      isFullScreen: false,
      isOpen: true,
      documentType: null
    };
  }

  componentDidMount() {
    this.props.getIdTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getClientCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  handleClick = () => {
    this.setState({ isFullScreen: true, isOpen: false });
  };

  handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      // const { clientEntity } = this.props;
      //
      // const entity = {
      //   ...clientEntity,
      //   ...values,
      //   companyId: this.props.account.companyId,
      //   mobile: this.state.mobile,
      //   telephone: this.state.telephone,
      //   country: this.state.country,
      //   partner: this.props.partners.find(value => value.id === Number(values.partner))
      // };
      // if (this.props.account.partner) {
      //   entity.partner = this.props.account.partner;
      // }
      // if (this.state.isNew) {
      //   this.props.createEntity(entity);
      // } else {
      //   this.props.updateEntity(entity);
      // }
    }
  };

  handleChangeType = event => {
    this.setState({ categoryId: event.target.value });
  };

  handleChangeDocumentType = event => {
    this.setState({
      documentType: event.target.value
    });
  };

  handleClose = event => {
    event.stopPropagation();
    this.props.history.goBack();
  };

  render() {
    const { kYCEntity, idTypes, clientCategories, clients, riskClasses, attachments, loading, updating } = this.props;
    const { isFullScreen, isOpen } = this.state;
    const { content, contentContentType } = kYCEntity;
    return (
      <Modal size="lg" style={{ maxWidth: '800px', width: '90%' }} isOpen={isOpen} toggle={this.handleClose} backdrop={'static'}>
        <ModalHeader toggle={this.handleClose}>Add KYC File</ModalHeader>
        <ModalBody id="ibsApp.client.kyc.add">
          <Fragment>
            <Row className="justify-content-md-center">
              <Col md={12}>
                <h4 className="mt-5 font-medium title text-center mb-5">Select What Category of KYC Documents You Would Like to Upload</h4>
              </Col>
            </Row>
            <AvForm onSubmit={this.handleSubmit}>
              <Row className="justify-content-md-center">
                <Col md={8}>
                  <AvGroup>
                    <Row>
                      <Label sm="3" id="kycTypeLabel" for="kyc-kycType">
                        KYC Category
                      </Label>
                      <Col>
                        <AvInput
                          id="kyc-kycType"
                          type="select"
                          className="form-control"
                          name="kycType"
                          onChange={this.handleChangeType}
                          required
                        >
                          <option value="">Select</option>
                          <option value="GENERAL">GENERAL</option>
                          <option value="RISK_CLASS">RISK_CLASS</option>
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              {this.state.categoryId === 'GENERAL' && (
                <Row className="justify-content-md-center">
                  <Col lg={8}>
                    <AvGroup className="mb-2 mt-2">
                      <Row>
                        <Label sm="3" className="text-right" id="kycTypeLabel" for="kyc-kycType">
                          Select Document Type
                        </Label>
                        <Col>
                          <AvInput
                            id="kyc-generalKYCType"
                            type="select"
                            className="form-control"
                            name="generalKYCType"
                            onChange={this.handleChangeDocumentType}
                          >
                            <option value="">Select</option>
                            <option value="ID">ID</option>
                            <option value="PIN">PIN</option>
                            <option value="OTHER">OTHER</option>
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
              )}
              {this.state.categoryId === 'GENERAL' && this.state.documentType === 'ID' && (
                <Row className="mb-2 mt-2 justify-content-md-center">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="client-idType">
                          Id Type
                        </Label>
                        <Col sm="8">
                          <AvInput id="client-idType" type="select" className="form-control" name="idTypeId">
                            {idTypes
                              ? sortBy(idTypes, ['name']).map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="4" id="idNumber" for="id-number">
                          ID Number
                        </Label>
                        <Col sm="8">
                          <AvInput id="id-number" placeholder="Enter ID Number" type="text" name="idNumber" />
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="DOB-date">
                          D.O.B
                        </Label>
                        <Col sm="8">
                          <AvInput id="DOB-date" type="date" className="form-control" name="dob" />
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="issue-date">
                          Issue Date
                        </Label>
                        <Col sm="8">
                          <AvInput id="issue-date" type="date" className="form-control" name="issueDate" required />
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="country-Issue">
                          Country of Issue
                        </Label>
                        <Col sm="8">
                          <AvInput id="country-Issue" type="select" className="form-control" name="countryOfIssue">
                            <option>Select a type</option>
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>

                    <AvGroup>
                      <Row>
                        <Label sm="4" for="country-Issue">
                          Upload Document
                        </Label>
                        <Col sm="8">
                          <div className="custom-file">
                            <input
                              id="upload-document"
                              type="file"
                              className="form-control"
                              onChange={this.onBlobChange(false, 'content')}
                            />
                            <AvInput type="hidden" name="content" value={content} />
                            <Label className="custom-file-label custom-file-upload" for="upload-document">
                              <FontAwesomeIcon icon="cloud-upload-alt" />
                              Click to Upload...
                            </Label>
                          </div>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
              )}
              {this.state.categoryId === 'GENERAL' && this.state.documentType === 'PIN' && (
                <Row className="mb-2 mt-2 justify-content-md-center">
                  <Col md="8">
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="pin-type">
                          Pin Type
                        </Label>
                        <Col sm="8">
                          <AvInput id="pin-type" type="select" className="form-control" name="pinType" required>
                            {clientCategories
                              ? sortBy(clientCategories, ['name']).map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="4" id="pinNumber" for="pin-number">
                          Pin Number
                        </Label>
                        <Col sm="8">
                          <AvInput id="pin-number" placeholder="Enter Pin Number" type="text" name="pinNumber" />
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
              )}
              {this.state.categoryId === 'GENERAL' && this.state.documentType === 'OTHER' && (
                <Row className="mb-2 mt-2 justify-content-md-center">
                  <Col md="8">
                    <AvGroup>
                      <Row>
                        <Label sm="4" id="description" for="description">
                          Description
                        </Label>
                        <Col sm="8">
                          <AvInput id="description" placeholder="Enter Description" type="text" name="description" />
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
              )}
              <Row className="mb-2 mt-2 justify-content-md-center">
                <Col md="8">
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="country-Issue">
                        Upload Document
                      </Label>
                      <Col sm="8">
                        <div className="custom-file">
                          <input id="upload-document" type="file" className="form-control" onChange={this.onBlobChange(false, 'content')} />
                          <AvInput type="hidden" name="content" value={content} />
                          <Label className="custom-file-label custom-file-upload" for="upload-document">
                            <FontAwesomeIcon icon="cloud-upload-alt" />
                            Click to Upload...
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              <AvGroup>
                <Row className="mb-5 mt-4 justify-content-md-center">
                  <Col md="12">
                    {content && (
                      <div className="image-preview-container">
                        <div className="image-container">
                          <img src={`data:${contentContentType};base64,${content}`} />
                        </div>
                        <div className="image-button-container">
                          <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                            <FontAwesomeIcon icon="eye" />
                          </Button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Button type="button" color="danger" size="sm" onClick={this.clearBlob('content')}>
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </div>
                        {isFullScreen && (
                          <Lightbox
                            mainSrc={`data:${contentContentType};base64,${content}`}
                            onCloseRequest={() => this.setState({ isFullScreen: false, isOpen: true })}
                          />
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </AvGroup>
            </AvForm>
          </Fragment>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button type="submit" id="jhi-confirm-delete-underwriter" color="btn btn-success">
            <FontAwesomeIcon icon="check" />
            &nbsp; Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clients: storeState.client.entities,
  clientCategories: storeState.clientCategory.entities,
  riskClasses: storeState.riskClass.entities,
  attachments: storeState.attachment.entities,
  idTypes: storeState.idType.entities,
  account: storeState.authentication.account,
  kYCEntity: storeState.kYC.entity,
  loading: storeState.kYC.loading,
  updating: storeState.kYC.updating,
  updateSuccess: storeState.kYC.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  deleteEntity,
  getIdTypes,
  setBlob,
  getClientCategories
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddKYCDialog);
