import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ServiceProviderType from './service-provider-type';
import ServiceProviderTypeDetail from './service-provider-type-detail';
import ServiceProviderTypeUpdate from './service-provider-type-update';
import ServiceProviderTypeDeleteDialog from './service-provider-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ServiceProviderTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ServiceProviderTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ServiceProviderTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ServiceProviderType} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ServiceProviderTypeDeleteDialog} />
  </>
);

export default Routes;
