import './footer.scss';

import React from 'react';

import { Col, Row } from 'reactstrap';

const Footer = props => (
  <footer className="footer text-center">
    DOTS Limited. © {new Date().getFullYear()}
    {'. '}All Rights Reserved. <a href="https://dots.ke/">Dots</a>
  </footer>
);

export default Footer;
