import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './claim.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import SearchFilter from 'app/entities/claim/filters';
import QuotesFilter from 'app/entities/claim/filters/quote-filter';
import ClientFilter from 'app/entities/claim/filters/client-filter';
import UnderwriterFilter from 'app/entities/claim/filters/underwrter-filter';
import KYCFilter from 'app/entities/claim/filters/kyc-filter';
import ClientPolicyFilter from 'app/entities/claim/filters/client-policy-filter';
import RiskClassFilter from 'app/entities/claim/filters/risk-class-filter';

export interface IClaimUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClaimUpdate = (props: IClaimUpdateProps) => {
  const [quoteId, setQuoteId] = useState('0');
  const [clientId, setClientId] = useState('0');
  const [riskClassId, setRiskClassId] = useState('0');
  const [underwriterId, setUnderwriterId] = useState('0');
  const [coverTypeId, setCoverTypeId] = useState('0');
  const [clientPolicyId, setClientPolicyId] = useState('0');
  const [kYCId, setKYcId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [popupOpen, setPopupOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');

  const { claimEntity, coverTypes, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/claim' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.getCoverTypes(0, 20, `&companyId=${props.account.companyId}`);
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const toggle = () => {
    setPopupOpen(!popupOpen);
  };

  const saveEntity = (event, errors, values) => {
    values.dateReported = convertDateTimeToServer(values.dateReported);

    if (errors.length === 0) {
      const entity = {
        ...claimEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const onOpen = (entity, modalTitle) => {
    setTitle(modalTitle);
    setType(entity);
    toggle();
  };

  const onSelect = id => {
    switch (type) {
      case 'client':
        id ? setClientId(id) : setClientId(null);
        break;
      case 'riskClass':
        id ? setRiskClassId(id) : setRiskClassId(null);
        break;
      case 'underwriter':
        id ? setUnderwriterId(id) : setUnderwriterId(null);
        break;
      case 'kyc':
        id ? setKYcId(id) : setKYcId(null);
        break;
      case 'clientPolicy':
        id ? setClientPolicyId(id) : setClientPolicyId(null);
        break;
      default:
        id ? setQuoteId(id) : setQuoteId(null);
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="ibsApp.claim.home.createOrEditLabel">Create or edit a Claim</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : claimEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="claim-id">ID</Label>
                  <AvInput id="claim-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <SearchFilter toggle={toggle} open={popupOpen} title={title}>
                {type === 'quote' && <QuotesFilter onPickItem={onSelect} />}
                {type === 'client' && <ClientFilter onPickItem={onSelect} />}
                {type === 'underwriter' && <UnderwriterFilter onPickItem={onSelect} />}
                {type === 'clientPolicy' && <ClientPolicyFilter onPickItem={onSelect} />}
                {type === 'kyc' && <KYCFilter onPickItem={onSelect} />}
                {type === 'riskClass' && <RiskClassFilter onPickItem={onSelect} />}
              </SearchFilter>
              <AvGroup>
                <Label id="brokerRefNoLabel" for="claim-brokerRefNo">
                  Broker Ref No
                </Label>
                <AvField id="claim-brokerRefNo" type="text" name="brokerRefNo" />
              </AvGroup>
              <AvGroup>
                <Label id="fileRefNoLabel" for="claim-fileRefNo">
                  File Ref No
                </Label>
                <AvField id="claim-fileRefNo" type="text" name="fileRefNo" />
              </AvGroup>
              <AvGroup>
                <Label id="brokerClaimNoLabel" for="claim-brokerClaimNo">
                  Broker Claim No
                </Label>
                <AvField id="claim-brokerClaimNo" type="text" name="brokerClaimNo" />
              </AvGroup>
              <AvGroup>
                <Label id="policyNoLabel" for="claim-policyNo">
                  Policy No
                </Label>
                <AvField id="claim-policyNo" type="text" name="policyNo" />
              </AvGroup>
              <AvGroup>
                <Label id="insurerClaimNoLabel" for="claim-insurerClaimNo">
                  Insurer Claim No
                </Label>
                <AvField id="claim-insurerClaimNo" type="text" name="insurerClaimNo" />
              </AvGroup>
              <AvGroup>
                <Label id="coverPeriodLabel" for="claim-coverPeriod">
                  Cover Period
                </Label>
                <AvField id="claim-coverPeriod" type="text" name="coverPeriod" />
              </AvGroup>
              <AvGroup>
                <Label id="dateOfLossLabel" for="claim-dateOfLoss">
                  Date Of Loss
                </Label>
                <AvField id="claim-dateOfLoss" type="date" className="form-control" name="dateOfLoss" />
              </AvGroup>
              <AvGroup>
                <Label id="claimGroupLabel" for="claim-claimGroup">
                  Claim Group
                </Label>
                <AvInput
                  id="claim-claimGroup"
                  type="select"
                  className="form-control"
                  name="claimGroup"
                  value={(!isNew && claimEntity.claimGroup) || 'WRITEOFF'}
                >
                  <option value="WRITEOFF">WRITEOFF</option>
                  <option value="PATIAL">PATIAL</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="dateReportedLabel" for="claim-dateReported">
                  Date Reported
                </Label>
                <AvInput
                  id="claim-dateReported"
                  type="datetime-local"
                  className="form-control"
                  name="dateReported"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.claimEntity.dateReported)}
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="policeStationReportedToLabel" for="claim-policeStationReportedTo">
                  Police Station Reported To
                </Label>
                <AvField id="claim-policeStationReportedTo" type="text" name="policeStationReportedTo" />
              </AvGroup>
              <AvGroup>
                <Label id="garageLabel" for="claim-garage">
                  Garage
                </Label>
                <AvField id="claim-garage" type="text" name="garage" />
              </AvGroup>
              <AvGroup>
                <Label id="claimAmountLabel" for="claim-claimAmount">
                  Claim Amount
                </Label>
                <AvField id="claim-claimAmount" type="string" className="form-control" name="claimAmount" />
              </AvGroup>
              <AvGroup>
                <Label id="excessAmountLabel" for="claim-excessAmount">
                  Excess Amount
                </Label>
                <AvField id="claim-excessAmount" type="string" className="form-control" name="excessAmount" />
              </AvGroup>
              <AvGroup>
                <Label id="settlementAmountLabel" for="claim-settlementAmount">
                  Settlement Amount
                </Label>
                <AvField id="claim-settlementAmount" type="string" className="form-control" name="settlementAmount" />
              </AvGroup>
              <AvGroup>
                <Label id="adjusterAppointedLabel" for="claim-adjusterAppointed">
                  Adjuster Appointed
                </Label>
                <AvField id="claim-adjusterAppointed" type="text" name="adjusterAppointed" />
              </AvGroup>
              <AvGroup>
                <Label for="claim-quote">Quote</Label>
                <AvInput
                  id="claim-quote"
                  type="text"
                  className="form-control"
                  name="quoteId"
                  value={quoteId}
                  onClick={() => onOpen('quote', 'Select Quote')}
                />
              </AvGroup>
              <AvGroup>
                <Label for="claim-client">Client</Label>
                <AvInput
                  id="claim-client"
                  type="text"
                  className="form-control"
                  name="clientId"
                  value={clientId}
                  onClick={() => onOpen('client', 'Select Client')}
                />
              </AvGroup>
              <AvGroup>
                <Label for="claim-riskClass">Risk Class</Label>
                <AvInput
                  id="claim-riskClass"
                  type="text"
                  className="form-control"
                  name="riskClassId"
                  value={riskClassId}
                  onClick={() => onOpen('riskClass', 'Select Risk Class')}
                />
              </AvGroup>
              <AvGroup>
                <Label for="claim-underwriter">Underwriter</Label>
                <AvInput
                  id="claim-underwriter"
                  type="text"
                  className="form-control"
                  name="underwriterId"
                  value={underwriterId}
                  onClick={() => onOpen('underwriter', 'Select Underwriter')}
                />
              </AvGroup>
              <AvGroup>
                <Label for="claim-coverType">Cover Type</Label>
                <AvInput id="claim-coverType" type="select" className="form-control" name="coverTypeId">
                  <option value="" key="0" />
                  {coverTypes
                    ? coverTypes.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="claim-clientPolicy">Client Policy</Label>
                <AvInput
                  id="claim-clientPolicy"
                  type="text"
                  className="form-control"
                  name="clientPolicyId"
                  value={clientPolicyId}
                  onClick={() => onOpen('clientPolicy', 'Select Client Policy')}
                />
              </AvGroup>
              <AvGroup>
                <Label for="claim-kYC">KYC</Label>
                <AvInput
                  id="claim-kYC"
                  type="text"
                  className="form-control"
                  name="kYCId"
                  value={kYCId}
                  onClick={() => onOpen('kyc', 'Select KYC')}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/claims" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  coverTypes: storeState.coverType.entities,
  claimEntity: storeState.claim.entity,
  loading: storeState.claim.loading,
  updating: storeState.claim.updating,
  updateSuccess: storeState.claim.updateSuccess
});

const mapDispatchToProps = {
  getCoverTypes,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimUpdate);
