import { defaultValue, IReportClass } from '../reports-model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import utils from 'app/config/utils';

export const ACTION_TYPES = {
  FETCH_REPORTS: 'reports/FETCH_REPORTS'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entity: defaultValue,
  updating: false,
  response: null,
  updateSuccess: false
};
export type ReportsState = Readonly<typeof initialState>;

export default (state: ReportsState = initialState, action): ReportsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REPORTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };

    case SUCCESS(ACTION_TYPES.FETCH_REPORTS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
        updateSuccess: true,
        response: action.payload.data
      };

    default:
      return state;
  }
};
const apiUrl = 'api/report-summary';

export const getReports = entity => {
  const requestUrl = `${apiUrl}`;
  return {
    type: ACTION_TYPES.FETCH_REPORTS,
    payload: axios.post<IReportClass>(requestUrl, entity)
  };
};
