import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
import { getSortState, ICrudGetAllAction, IPaginationBaseState, JhiItemCount, JhiPagination, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './invoice.reducer';
import { IInvoice } from 'app/shared/model/invoice.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import DataTable from 'app/shared/common/dataTable';

export interface IInvoiceProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IInvoiceState = IPaginationBaseState;

export class Invoice extends React.Component<IInvoiceProps, IInvoiceState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      sort: 'id',
      order: 'desc'
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const { log } = console;
    log(state);
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { account } = this.props;
    filtered.push(`companyId=${account.companyId}`);
    const productTypeIds = account.productTypes.map(a => `productTypeId=${a.id}`);
    this.props.getEntities(
      state.page,
      state.pageSize,
      `${sort || 'id'},${order || 'desc'}&${productTypeIds.join('&')}&${filtered.join('&')}`
    );
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { invoices, totalItems, match, loading } = this.props;
    const tableData = invoices.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      createdAt:
        prop.createdAt === null ? 'null' : <TextFormat type="date" value={`${prop.createdAt}`} format={APP_LOCAL_DATETIME_FORMAT} />,
      clientId: (
        <Button tag={NavLink} to={`${match.url}/${prop.clientId}`} color="link" size="sm">
          {prop.clientId}
        </Button>
      ),
      // name: `${prop.firstName || ''} ${prop.lastName || ''}`,
      newInvoice: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-plus" />
            New Invoice
          </Button>
        </div>
      ),
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
            <span className="d-none d-md-inline">View</span>
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));

    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Invoicing
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  Create new Invoice
                </NavLink>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Created Date',
                  accessor: 'createdAt',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '12px' }}>{row.value}</div>
                },
                {
                  Header: 'Date',
                  accessor: 'date',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '12px' }}>{row.value}</div>
                },
                {
                  Header: 'Client Id',
                  accessor: 'clientId'
                },
                {
                  Header: 'Invoice No',
                  accessor: 'invoiceNo'
                },
                {
                  Header: 'Amount',
                  accessor: 'invoiceAmount'
                },
                {
                  Header: 'Tax',
                  accessor: 'invoiceTax'
                },
                {
                  Header: 'No of Policies',
                  accessor: 'clientPolicies'
                  // Cell: row => <div style={{ textAlign: 'center', fontSize: '12px' }}>{row && row.value.length}</div>
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ invoice, authentication, client }: IRootState) => ({
  totalItems: invoice.totalItems,
  invoices: invoice.entities,
  clients: invoice.entities,
  loading: invoice.loading,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoice);
