export interface IBillWiseReportClass {
  payDate?: Date;
  amount?: number;
  clientBalance?: number;
  underwriterName?: string;
  underwriterAmount?: number;
  underwriterBalance?: number;
  clientPolicyId?: number;
  policyName?: string;
  productTypeName?: string;
  clientName?: string;
  premiumPaid?: number;
  underwriterProductName?: string;
  partial?: boolean;
  from?: Date;
  to?: Date;
}

export const defaultValue: Readonly<IBillWiseReportClass> = {};
