import { Moment } from 'moment';
import { ICoverArea } from 'app/shared/model/cover-area.model';

export const enum BenefitRate {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE'
}

export const enum BenefitStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface IBenefit {
  id?: number;
  name?: string;
  benefitRate?: BenefitRate;
  benefitValue?: number;
  benefitChargeType?: BenefitRate;
  benefitChargeValue?: number;
  description?: string;
  currencySymbol?: string;
  minimumValue?: number;
  minAge?: number;
  maxAge?: number;
  inpatient?: number;
  outpatient?: number;
  maternity?: number;
  dental?: number;
  optical?: number;
  benefitTypeId?: number;
  underwriterId?: number;
  riskClassId?: number;
  coverTypeId?: number;
  benefitTypeName?: string;
  underwriterName?: string;
  riskClassName?: string;
  coverTypeName?: string;
  productName?: string;
  coverAreaId?: number;
  startDate?: Moment;
  endDate?: Moment;
  status?: BenefitStatus;
  companyId?: number;
  age?: number;
  discountRate?: number;
  benefitLimit?: number;
  childBenefitValue?: number;
  benefitCategoryId?: number;
  levelCategory?: string;
  productTypeId?: number;
  ageRangeId?: number;
  underwriterProductId?: number;
  underwriterProductName?: string;
  productTypeName?: string;
  multiple?: boolean;
}

export const defaultValue: Readonly<IBenefit> = {};
