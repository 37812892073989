import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import DataTable from 'app/shared/common/dataTable';
import { getSortState, IPaginationBaseState } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './policy.reducer';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IPolicyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IPolicyState = IPaginationBaseState;

export class Policy extends React.Component<IPolicyProps, IPolicyState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { account } = this.props;
    const productTypeIds = account.productTypes.map(a => `productTypeId=${a.id}`);
    filtered.push(`companyId=${account.companyId}`);
    this.props.getEntities(state.page, state.pageSize, `${sort},${order}&${productTypeIds.join('&')}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { loading, policyList, match, isSuperAdmin } = this.props;
    const tableData = policyList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      coverTypeId: prop.coverTypeId ? <NavLink to={`cover-type/${prop.coverTypeId}`}>{prop.coverTypeId}</NavLink> : '',
      underwriterId: prop.underwriterId ? <NavLink to={`underwriter/${prop.underwriterId}`}>{prop.underwriterId}</NavLink> : '',
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          {isSuperAdmin && (
            <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
              <i className="fa fa-times" />
            </Button>
          )}
        </div>
      )
    }));
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Policies
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  Create new Policy
                </NavLink>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Name',
                  accessor: 'name'
                },
                {
                  Header: 'Primium Payable',
                  accessor: 'primiumPayable'
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="INACTIVE">INACTIVE</option>
                    </select>
                  )
                },
                {
                  Header: 'Cover Type',
                  accessor: 'coverTypeName',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Underwriter',
                  accessor: 'underwriterName',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ policy, authentication }: IRootState) => ({
  loading: policy.loading,
  policyList: policy.entities,
  totalItems: policy.totalItems,
  account: authentication.account,
  isSuperAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPER_ADMIN])
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Policy);
