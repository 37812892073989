import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './attachment-file.reducer';
import { IAttachmentFile } from 'app/shared/model/attachment-file.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAttachmentFileDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AttachmentFileDetail extends React.Component<IAttachmentFileDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { attachmentFileEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            AttachmentFile [<b>{attachmentFileEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="content">Content</span>
            </dt>
            <dd>
              {attachmentFileEntity.content ? (
                <div>
                  <a onClick={openFile(attachmentFileEntity.contentContentType, attachmentFileEntity.content)}>Open&nbsp;</a>
                  <span>
                    {attachmentFileEntity.contentContentType}, {byteSize(attachmentFileEntity.content)}
                  </span>
                </div>
              ) : null}
            </dd>
            <dt>Attachment</dt>
            <dd>{attachmentFileEntity.attachmentId ? attachmentFileEntity.attachmentId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/attachment-file" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/attachment-file/${attachmentFileEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ attachmentFile }: IRootState) => ({
  attachmentFileEntity: attachmentFile.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachmentFileDetail);
