import { Moment } from 'moment';
import { DebitStatus, RemitStatus } from 'app/shared/model/payment-callback.model';

export const enum TransactionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}

export const enum PaymentMethod {
  CARD = 'CARD',
  MPESA = 'MPESA',
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  BANK_TRANSFER = 'BANK_TRANSFER'
}

export const enum Platform {
  WEB = 'WEB',
  MOBILE_APP = 'MOBILE_APP'
}

export interface IRemitPayment {
  transCode?: string;
  clientPolicyPaymentsIds?: [];
  underwriterId?: number;
  paymentMethod?: PaymentMethod;
  transDate?: Moment;
  companyId?: number;
}

export interface IClientPolicyPayment {
  id?: number;
  payDate?: Moment;
  startDate?: Moment;
  endDate?: Moment;
  amount?: number;
  invoiceReceipt?: string;
  paymentConfirmationNumber?: string;
  deviceId?: string;
  ipAddress?: string;
  transactionStatus?: TransactionStatus;
  paymentMethod?: string;
  quoteId?: number;
  platform?: Platform;
  isIPF?: boolean;
  clientPolicyId?: number;
  clientPolicyName?: string;
  policyNo?: string;
  clientName?: string;
  companyId?: number;
  agentCode?: string;
  debitStatus?: DebitStatus;
  remitStatus?: RemitStatus;
  agentCommission?: number;
  underwriterAmount?: number;
  brokerCommission?: number;
  productTypeId?: number;
  debitNoteId?: number;
  remittanceId?: number;
}

export const defaultValue: Readonly<IClientPolicyPayment> = {
  isIPF: false
};
