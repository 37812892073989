import React, { Component } from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Card } from '@material-ui/core';

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Card />;
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  quote: storeState.quote.entity
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetails);
