import { RouteComponentProps } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Alert, Container, Button, Row, Col, Label } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { getUrlParameter } from 'react-jhipster';
import { handleRegisterFinish, reset } from 'app/modules/account/register/register.reducer';
import MultiSelect from 'app/shared/common/multi-select';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';

export interface IRegisterSetupProps extends StateProps, DispatchProps, RouteComponentProps<{ key: string }> {}

export interface IRegisterSetupState {
  password: string;
  key: string;
  category: string;
  selectedOption: any[];
}

export class RegisterSetup extends React.Component<IRegisterSetupProps, IRegisterSetupState> {
  state: IRegisterSetupState = {
    password: '',
    category: 'INDIVIDUAL',
    key: getUrlParameter('key', this.props.location.search),
    selectedOption: []
  };

  componentDidMount() {
    this.props.getProductTypes(0, 10, `name,asc&companyId=1`);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.registrationSuccess !== this.props.registrationSuccess && nextProps.registrationSuccess) {
      this.props.history.push('/register/success');
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  saveEntity = (event, errors, values) => {
    const { key, password, selectedOption } = this.state;
    const formData = {
      ...values,
      key,
      password,
      productTypeIds: selectedOption.map(s => s.value)
    };
    this.props.handleRegisterFinish(formData);
  };

  updatePassword = password => {
    this.setState({ password });
  };

  handleChangeCategory = value => {
    this.setState({ category: value });
  };
  handleChangeOption = selectedOption => {
    this.setState({
      selectedOption
    });
  };

  render() {
    const { key, category, selectedOption } = this.state;
    const { productTypes } = this.props;

    const options = productTypes.filter(p => p.name !== 'Medical').map(p => ({ value: p.id, label: p.name }));

    return (
      <Container>
        <Row className="justify-content-center mt-5">
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <h4 id="ibsApp.register.home.completeSetupLabel" className="font-medium mb-3">
              Complete Registration Setup
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            {key ? (
              <AvForm onSubmit={this.saveEntity}>
                <Row>
                  <Col>
                    <Label id="firstNameLabel" for="firstName">
                      FirstName
                    </Label>
                    <AvField
                      id="firstName"
                      name="firstName"
                      placeholder={'First Name'}
                      labelClass="font-medium"
                      size="sm"
                      validate={{
                        required: { value: true, errorMessage: 'First Name is required.' }
                      }}
                    />
                  </Col>
                  <Col>
                    <Label id="lastNameLabel" for="lastName">
                      LastName
                    </Label>
                    <AvField
                      id="lastName"
                      name="lastName"
                      placeholder={'Last Name'}
                      labelClass="font-medium"
                      size="sm"
                      validate={{
                        required: { value: true, errorMessage: 'Last Name is required.' }
                      }}
                    />
                  </Col>
                </Row>
                <AvGroup>
                  <Label id="categoryLabel" for="category-name">
                    Category
                  </Label>
                  <AvInput
                    id="client-category"
                    type="select"
                    size="sm"
                    className="form-control"
                    name="category"
                    value={category}
                    onChange={event => this.handleChangeCategory(event.target.value)}
                  >
                    <option value="INDIVIDUAL">INDIVIDUAL</option>
                    <option value="COMPANY">COMPANY</option>
                  </AvInput>
                </AvGroup>
                {category === 'COMPANY' && (
                  <AvGroup>
                    <Label for="partnerName" className="font-medium">
                      Company Name
                    </Label>
                    <AvField
                      id="company-name"
                      name="companyName"
                      placeholder={'Company Name'}
                      labelClass="font-medium"
                      size="sm"
                      validate={{
                        required: { value: true, errorMessage: 'Company Name is required.' }
                      }}
                    />
                  </AvGroup>
                )}
                <AvGroup>
                  <Label for="productTypes" className="font-medium">
                    Product Types
                  </Label>
                  <MultiSelect
                    id="partner-productTypes"
                    name="productTypes"
                    optionSelected={selectedOption}
                    isMulti
                    handleChange={this.handleChangeOption}
                    options={options}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="firstPassword" className="font-medium">
                    New password
                  </Label>
                  <AvField
                    name="firstPassword"
                    placeholder={'New password'}
                    type="password"
                    labelClass="font-medium"
                    size="sm"
                    onChange={e => this.updatePassword(e.target.value)}
                    validate={{
                      required: { value: true, errorMessage: 'Your password is required.' },
                      minLength: {
                        value: 4,
                        errorMessage: 'Your password is required to be at least 4 characters.'
                      },
                      maxLength: { value: 50, errorMessage: 'Your password cannot be longer than 50 characters.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="secondPassword" className="font-medium">
                    New password confirmation
                  </Label>
                  <AvField
                    name="secondPassword"
                    placeholder="Confirm the new password"
                    type="password"
                    labelClass="font-medium"
                    size="sm"
                    validate={{
                      required: { value: true, errorMessage: 'Your confirmation password is required.' },
                      minLength: {
                        value: 4,
                        errorMessage: 'Your confirmation password is required to be at least 4 characters.'
                      },
                      maxLength: {
                        value: 50,
                        errorMessage: 'Your confirmation password cannot be longer than 50 characters.'
                      },
                      match: {
                        value: 'firstPassword',
                        errorMessage: 'The password and its confirmation do not match!'
                      }
                    }}
                  />
                </AvGroup>
                <Row className="mb-3 mt-3">
                  <Col xs="12">
                    <Button color="primary" size="lg" type="submit" block>
                      SUBMIT
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            ) : (
              <Alert color="danger">
                <strong>Email Verify key not valid</strong> Please use the registration form to sign up.
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  productTypes: storeState.productType.entities,
  registrationSuccess: storeState.register.registrationSuccess
});

const mapDispatchToProps = {
  handleRegisterFinish,
  getProductTypes,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSetup);
