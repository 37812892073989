import React, { useState } from 'react';
import { Button, Modal, ModalHeader, Row, Col, Label, ModalBody, ModalFooter } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { size } from 'lodash';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import DataTable from 'app/shared/common/dataTable';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { PaymentMethod } from 'app/shared/model/enumerations/payment-method.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IRemitProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  toggleModal;
  dataList;
  open;
  underwriters;
  remitPayments;
}

export interface IRemitState extends IPaginationBaseState {
  transCode: string;
  underwriterId: string;
}

export class Remit extends React.Component<IRemitProps, IRemitState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      transCode: '',
      underwriterId: ''
    };
  }

  onFinish = async (event, errors, values) => {
    const { remitPayments, dataList, account, toggleModal } = this.props;
    if (errors.length === 0) {
      const data = dataList.map(e => e.id);
      const remitData = {
        ...values,
        transDate: moment(),
        clientPolicyPaymentsIds: data,
        paymentMethod: PaymentMethod.MPESA,
        companyId: Number(account.companyId)
      };
      await remitPayments(remitData);
      toggleModal();
    }
  };

  render() {
    const { underwriters, dataList, toggleModal, open, match } = this.props;
    // log(dataList);
    const tableData = dataList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      clientName: `${prop.firstName || ''} ${prop.middleName || ''} ${prop.lastName || ''}`,
      date: prop.payDate,
      clientId: prop.clientId ? <NavLink to={`client/${prop.clientId}`}>{prop.clientId}</NavLink> : '',
      actions: (
        <div className="text-center">
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));

    return (
      <div>
        <Modal
          size="lg"
          style={{ maxWidth: '900px', width: '90%' }}
          isOpen={open}
          toggle={toggleModal}
          fullscreen={'fullscreen'}
          backdrop="static"
        >
          <ModalHeader toggle={toggleModal}>Payment Remittance</ModalHeader>
          <ModalBody>
            <AvForm model={{}} onSubmit={this.onFinish}>
              <h5>
                Total Transactions - <span>{size(dataList)}</span>
              </h5>
              <Row className="mt-5 mb-4 justify-content-between">
                <Col sm="5" className="pr-2">
                  <AvGroup>
                    <Label id="transCode" for="trans-code">
                      Transaction Code
                    </Label>
                    <AvField
                      id="transCode"
                      type="text"
                      name="transCode"
                      validate={{
                        required: { value: true, errorMessage: 'This field is required.' }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm="7">
                  <Label id="remit-underwriter" for="remit-underwriter">
                    Underwriter
                  </Label>
                  <AvInput id="remit-underwriter" type="select" className="form-control" name="underwriterId">
                    <option value="0" key="0">
                      Select Underwriter
                    </option>
                    {underwriters
                      ? underwriters.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </Col>
              </Row>
              <DataTable
                columns={[
                  {
                    Header: 'Client Name',
                    accessor: 'clientName',
                    Cell: row => <div style={{ textAlign: 'center', fontSize: '12px' }}>{row.value}</div>
                  },

                  {
                    Header: 'Transaction Code',
                    accessor: 'paymentConfirmationNumber'
                  },
                  {
                    Header: 'Pay Date',
                    accessor: 'date'
                  },
                  {
                    Header: 'Debit Note Number',
                    accessor: 'debitNoteId'
                  },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    filterable: false
                  }
                ]}
                manual
                defaultPageSize={10}
                // pages={this.getMaxPage()}
                // loading={loading}
                // onFetchData={this.fetchData}
                showPaginationBottom
                tableData={tableData}
                filterable
              />
              <Row className="justify-content-center text-center mb-5 mt-5">
                <Col>
                  <Button color="secondary" onClick={toggleModal}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button type="submit" color="success">
                    SUBMIT
                  </Button>{' '}
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (iRootState: IRootState) => ({
  // loading: reminder.loading
  account: iRootState.authentication.account
});

const mapDispatchToProps = {
  // getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Remit);
