export const enum CertificateRequestType {
  ISSUE_TYPE_A_CERTIFICATE = 'IssuanceTypeACertificate',
  ISSUE_TYPE_B_CERTIFICATE = 'IssuanceTypeBCertificate',
  ISSUE_TYPE_C_CERTIFICATE = 'IssuanceTypeCCertificate',
  ISSUE_TYPE_D_CERTIFICATE = 'IssuanceTypeDCertificate',
  PREVIEW_TYPE_A_CERTIFICATE = 'PreviewTypeACertificate',
  PREVIEW_TYPE_B_CERTIFICATE = 'PreviewTypeBCertificate',
  PREVIEW_TYPE_C_CERTIFICATE = 'PreviewTypeCCertificate',
  PREVIEW_TYPE_D_CERTIFICATE = 'PreviewTypeDCertificate'
}

export const enum TypeOfCover {
  COMPREHENSIVE = 100,
  THIRD_PARTY = 200,
  THIRD_PARTY_THEFT_AMD_FIRE = 300
}

export const enum TypeACertificate {
  CLASS_A_PSV_UNMARKED = 1,
  TYPE_A_TAXI = 8
}

export const enum TypeBCertificate {
  MOTOR_COMMERCIAL_OWN_GOODS = 1,
  MOTOR_COMMERCIAL_GENERAL_CARTAGE = 2,
  MOTOR_INSTITUTIONAL_VEHICLE = 3,
  MOTOR_SPECIAL_VEHICLES = 4,
  TANKERS_LIQUID_CARRYING = 5,
  MOTOR_TRADE_ROAD_RISK = 6
}

export const enum TypeDCertificate {
  TYPE_D_MOTORCYCLE = 4,
  TYPE_D_PSV_MOTOR_CYCLE = 9,
  TYPE_D_MOTOR_CYCLE_COMMERCIAL = 10
}

export interface IAkiClass {
  memberCompanyId?: number;
  typeOfCertificate?: number;
  typeOfCover?: TypeOfCover;
  intermediaryIRANumber?: string;
  policyHolder?: string;
  policyNumber?: string;
  commencingDate?: string;
  expiringDate?: string;
  registrationNumber?: string;
  chassisNumber?: string;
  phoneNumber?: string;
  bodyType?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  engineNumber?: string;
  email?: string;
  sumInsured?: number;
  insuredPIN?: string;
  yearOfManufacture?: number;
  hudumaNumber?: string;
  lincensedToCarry?: number; // (When Type D PSV Motor Cycle)
  tonnage?: number;
}

export const defaultValue: Readonly<IAkiClass> = {};
