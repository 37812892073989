import { IClientPolicy } from 'app/shared/model/client-policy.model';
import { Moment } from 'moment';
import { IClient } from 'app/shared/model/client.model';

export interface IInvoice {
  id?: number;
  companyId?: number;
  createdAt?: Moment;
  description?: string;
  invoiceNo?: string;
  date?: Moment;
  invoiceTax?: number;
  invoiceAmount?: number;
  trackingNumber?: string;
  clientId?: number;
  clientPoliciesIds?: Number[];
}

export const defaultValue: Readonly<IInvoice> = {};

export interface IInvoiceCart {
  client?: IClient;
  clientPolicies?: IClientPolicy[];
  cartTotal?: number;
}

export const cartState: IInvoiceCart = {};
