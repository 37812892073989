import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Label, FormGroup, Input, Button } from 'reactstrap';
import { getSortState, IPaginationBaseState, setFileData, TextFormat } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { getEntityByEmail } from 'app/entities/client/client.reducer';
import {
  getEntities as getClientPolicies,
  getEntity as getClientPolicy,
  createEntity as createClientPolicy,
  updateEntity as updateClientPolicy
} from 'app/entities/client-policy/client-policy.reducer';
import { updateEntity as updatePayment, getEntity as getDirectPayment } from 'app/entities/direct-payment/direct-payment.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntity as getPolicy, createEntity as createPolicy, updateEntity as updatePolicy } from 'app/entities/policy/policy.reducer';
import { IRiskCategory } from 'app/shared/model/risk-category.model';
import DataTable from 'app/shared/common/dataTable';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { isEqual, isEmpty, size } from 'lodash';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import ModalView from './modal-view';

export interface IPolicyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; id: string }> {
  jumpToStep;
  selectedRiskCategory;
  updateSelectedRiskCategory;
  getPolicyByEmail;
  open;
  openModal;
  setModalError;
  modalError;
}

export interface IPolicyState extends IPaginationBaseState {
  riskClassId: string;
  email: string;
  errorMessage: string;
  riskClass: any;
  vehicleType: any;
  vehicleTypes: any;
  riskCategoryId: string;
  riskCategory: IRiskCategory;
  noOfChildren: any;
  openPayable: string;
  children: any[];
  isNew: boolean;
  selectedPolicy: boolean;
}

class PolicyStep extends React.Component<IPolicyProps, IPolicyState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      riskClassId: '0',
      email: '',
      errorMessage: '',
      vehicleType: null,
      riskClass: null,
      riskCategory: {},
      vehicleTypes: [],
      riskCategoryId: '0',
      noOfChildren: [],
      openPayable: '0',
      children: [],
      selectedPolicy: false,
      isNew: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { companyId } = this.props.account;
    const { clientEntity, directPayment } = this.props;
    const { log } = console;
    // log(clientEntity);
    if (clientEntity && clientEntity.id) {
      this.fetchClientPolicies(clientEntity.id).then(r => {
        const clientPoliciesSize = size(this.props.clientPolicies);
        if (clientPoliciesSize > 0) {
          this.setState({ isNew: false });
          clientPoliciesSize === 1 && this.fetchPolicy(this.props.clientPolicies[0].policyId);
        }
        // log(this.props.clientPolicies);
      });
    }
    const { id } = directPayment;
    if (isEmpty(id)) {
      this.fetchDirectPayment(this.props.match.params.id);
    }
    const start_actions = [];
    start_actions.push.apply(start_actions, [
      this.props.getRiskCategories(0, 200, `,&companyId=${companyId}`),
      this.props.getRiskClasses(0, 200, `,&companyId=${companyId}`),
      this.props.getUnderwriters(0, 200, `,&companyId=${companyId}`),
      this.props.getProductTypes(0, 20, `,&companyId=${companyId}`)
    ]);
    Promise.all(start_actions);
  }

  async fetchDirectPayment(id) {
    await this.props.getDirectPayment(id);
  }

  fetchPolicy = async id => {
    await this.props.getPolicy(id);
    this.props.getCoverTypes(0, 20, `,&companyId=${this.props.account.companyId}&riskClassId=${this.props.policyEntity.riskClassId}`);
  };

  fetchClientPolicies = async id => {
    const { productTypes } = this.props.account;
    const productTypeIds = productTypes.map(a => `productTypes_Id=${a.id}`);
    await this.props.getClientPolicies(0, 5, `id,desc&clientId=${id}&companyId=${1}&${productTypeIds.join('&')}`);
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(1);
    }
  }

  async handleSubmit(event, errors, values) {
    if (errors.length === 0) {
      const { directPayment, policyEntity, clientEntity, clientPolicyEntity } = this.props;
      this.props.updateSelectedRiskCategory(values.riskCategoryId);
      const { companyId } = directPayment;
      const productTypeId = this.props.riskClasses.find(value => Number(value.id) === Number(values.riskClassId)).productTypeId;
      const policy = {
        ...policyEntity,
        ...values,
        companyId,
        productTypeId
      };
      const clientPolicy = {
        ...clientPolicyEntity,
        ...values,
        clientId: clientEntity.id,
        policyId: policyEntity.id,
        companyId,
        productTypeId
      };
      // log(this.state.selectedPolicy);
      if (!this.state.selectedPolicy) {
        await this.props.createPolicy(policy);
        clientPolicy.policyId = this.props.policyEntity.id;
        await this.props.createClientPolicy(clientPolicy);
      } else {
        !isEqual(JSON.stringify(policyEntity), JSON.stringify(policy)) && (await this.props.updatePolicy(policy));
        clientPolicy.policyId = this.props.policyEntity.id;
        !isEqual(JSON.stringify(clientPolicyEntity), JSON.stringify(clientPolicy)) && (await this.props.updateClientPolicy(clientPolicy));
      }
    }
  }

  handleDateChange = e => {
    const { inputData } = this.props;
    // this.props.setInputData({ ...inputData, childDOBs: [...inputData.childDOBs, e.target.value] });
  };

  handleChildrenChange = e => {
    const childNo = this.state.noOfChildren.find(item => item.id === Number(e.target.value));
    let count = 0;
    const children = [];
    this.setState({ children: [] });
    while (count < childNo.id) {
      const newcount = count + 1;
      children.push({ id: 'child' + newcount });
      count++;
    }
  };

  handleRiskCategoryChange = e => {
    this.props.updateSelectedRiskCategory(e.target.value);
    const { companyId } = this.props.account;
    const riskCategory = this.props.riskCategories.find(value => Number(value.id) === Number(e.target.value));
    this.setState({ riskCategory });
    this.props.getRiskClasses(0, 200, `,&riskCategoryId=${e.target.value}&companyId=${companyId}`);
  };

  onPickPolicy = async (e, policyData) => {
    await this.setState({ selectedPolicy: true });
    await this.props.getClientPolicy(policyData.id);
    await this.props.getPolicy(policyData.policyId);
    this.props.getCoverTypes(0, 20, `,&companyId=${this.props.account.companyId}&riskClassId=${this.props.policyEntity.riskClassId}`);
  };

  handleRiskClassChange = e => {
    const riskClassId = e.target.value;
    const riskClass = this.props.riskClasses.find(value => Number(value.id) === Number(riskClassId));
    this.setState({ riskClass });
    this.props.getCoverTypes(0, 20, `,&companyId=${this.props.account.companyId}&riskClassId=${riskClassId}`);
  };

  handleBack = () => {
    const { jumpToStep } = this.props;
    jumpToStep(0);
  };

  onNext = async () => {
    const { directPayment, policyEntity, clientPolicyEntity } = this.props;
    const { log } = console;
    // log('NEXT', this.state.selectedPolicy);
    if (this.state.selectedPolicy) {
      const paymentEntity = {
        ...directPayment,
        underwriterId: policyEntity.underwriterId,
        riskClassId: policyEntity.riskClassId,
        riskCategoryId: policyEntity.riskCategoryId,
        clientPolicyId: clientPolicyEntity.id,
        productTypeId: clientPolicyEntity.productTypeId
      };
      paymentEntity.clientPolicyId = this.props.clientPolicyEntity.id;
      !isEqual(JSON.stringify(directPayment), JSON.stringify(paymentEntity)) && (await this.props.updatePayment(paymentEntity));
      const updatedUrl = this.props.location.search.replace(/(\?|&)wizard=0/, '$1wizard=1');
      this.props.history.push(`${updatedUrl}`);
      this.props.jumpToStep(2);
    } else {
      this.props.setModalError({
        title: 'No Policy Selected',
        description: 'Please select policy or create new policy to continue'
      });
      this.props.openModal(true);
    }
  };

  render() {
    const {
      riskCategories,
      riskClasses,
      clientPolicies,
      coverTypes,
      updating,
      inputData,
      policyEntity,
      clientPolicyEntity,
      underwriters,
      open,
      modalError
    } = this.props;
    const { riskCategory, riskClass, openPayable, isNew } = this.state;
    const { log } = console;
    // log(riskCategory, riskCategoryId);
    const tablePoliciesData = clientPolicies.map(prop => ({
      ...prop,
      premiumPayable: `Ksh. ${String(prop.premiumPayable).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`,
      policyDate: (
        <TextFormat type="date" value={moment(prop.policyDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />
      ),
      startDate: <TextFormat type="date" value={moment(prop.startDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      endDate: <TextFormat type="date" value={moment(prop.endDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      policyId: prop.policyId ? <NavLink to={`policy/${prop.policyId}`}>{prop.policyId}</NavLink> : '',
      actions: (
        <div className="action-container">
          <Input type="checkbox" onClick={e => this.onPickPolicy(e, prop)} />
        </div>
      )
    }));

    return (
      <div className="step step2 mt-5 ">
        <AvForm onSubmit={this.handleSubmit}>
          <div className="form-group mb-5 mt-5">
            <Row>
              <Col md={4}>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="risk-class-riskCategory">
                      Risk Category
                    </Label>
                    <Col sm="8">
                      <AvInput
                        id="risk-class-riskCategory"
                        type="select"
                        className="form-control"
                        name="riskCategoryId"
                        value={policyEntity.riskCategoryId || ''}
                        onChange={this.handleRiskCategoryChange}
                        required
                      >
                        <option value="" key="">
                          {' '}
                          Select{' '}
                        </option>
                        {riskCategories
                          ? sortBy(riskCategories, ['name']).map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
                <div className="text-danger">{this.state.errorMessage}</div>
              </Col>
              <Col md={4}>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="risk-class-riskCategory">
                      Risk Class
                    </Label>
                    <Col sm="8">
                      <AvInput
                        id="quote-riskClass"
                        type="select"
                        className="form-control"
                        name="riskClassId"
                        value={policyEntity.riskClassId || ''}
                        onChange={this.handleRiskClassChange}
                      >
                        <option value="" key="">
                          {' '}
                          Select{' '}
                        </option>
                        <option disabled value="" key="0" />
                        {riskClasses
                          ? riskClasses.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col md={4}>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="policy-underwriter">
                      Underwriter
                    </Label>
                    <Col sm="8">
                      <AvInput
                        id="policy-underwriter"
                        type="select"
                        className="form-control"
                        value={policyEntity.underwriterId || ''}
                        name="underwriterId"
                        required
                      >
                        <option value="" key="">
                          {' '}
                          Select Underwriter{' '}
                        </option>
                        {underwriters
                          ? sortBy(underwriters, ['name']).map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <>
              {riskCategory && riskCategory.name === 'Health' && (
                <>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="date-of-birth">
                            Date of Birth
                          </Label>
                          <Col sm="8">
                            <AvField id="date-of-birth" type="date" className="form-control" name="dateOfBirth" required />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="spouse-date-of-birth">
                            Spouse Date of Birth
                          </Label>
                          <Col sm="8">
                            <AvField id="spouse-date-of-birth" type="date" className="form-control" name="spouseDOB" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="no-of-children">
                            Number of Children
                          </Label>
                          <Col sm="8">
                            <AvInput
                              id="no-of-children"
                              type="select"
                              className="form-control"
                              name="noOfChildren"
                              onChange={this.handleChildrenChange}
                              required
                            >
                              <option>Select a type</option>
                              {this.state.noOfChildren
                                ? this.state.noOfChildren.map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>{' '}
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  {riskClass &&
                    riskClass.name === 'International' &&
                    inputData &&
                    inputData.children.length &&
                    inputData.children.map(val => (
                      <Row key={val.id}>
                        <Col>
                          <AvGroup>
                            <Row>
                              <Label sm="4" for={`child-${inputData.children.indexOf(val) + 1}-date-of-birth`}>
                                Child {inputData.children.indexOf(val) + 1} Date of Birth
                              </Label>
                              <Col sm="8">
                                <AvField
                                  id={`child-${inputData.children.indexOf(val) + 1}-date-of-birth`}
                                  type="date"
                                  className="form-control"
                                  onChange={this.handleDateChange}
                                  name="spouseDOB"
                                  required
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                    ))}
                </>
              )}
              <Row>
                <Col md={4}>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="premiumPayableLabel" for="policy-premiumPayable">
                        Premium Payable
                      </Label>
                      <Col sm="8">
                        <AvField
                          id="policy-premiumPayable"
                          type="string"
                          className="form-control"
                          name="premiumPayable"
                          value={policyEntity.premiumPayable || ''}
                          placeholder="Enter premium payable"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' },
                            number: { value: true, errorMessage: 'This field should be a number.' }
                          }}
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="openPayableLabel" for="client-policy-openPayable">
                        Open Payable
                      </Label>
                      <Col sm="8">
                        <AvField
                          id="client-policy-openPayable"
                          type="string"
                          className="form-control"
                          name="openPayable"
                          value={openPayable}
                          placeholder="Enter open payable"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' },
                            number: { value: true, errorMessage: 'This field should be a number.' }
                          }}
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                {!isNew && (
                  <Col md={4}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" id="nameLabel" for="policy-name">
                          Name
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="policy-name"
                            type="text"
                            name="name"
                            disabled
                            value={policyEntity.name || ''}
                            placeholder="Enter name"
                            validate={{
                              required: { value: true, errorMessage: 'This field is required.' }
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                )}
                <Col md={4}>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="policyNoLabel" for="policy-number">
                        Policy Number
                      </Label>
                      <Col sm="8">
                        <AvField
                          id="policy-number"
                          type="text"
                          name="policyNo"
                          value={policyEntity.policyNo || ''}
                          placeholder="Enter Policy Number"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' }
                          }}
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="policyDateLabel" for="client-policy-policyDate">
                        Policy Date
                      </Label>
                      <Col sm="8">
                        <AvField
                          id="client-policy-policyDate"
                          type="date"
                          className="form-control"
                          value={clientPolicyEntity.policyDate || ''}
                          name="policyDate"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' }
                          }}
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="startDateLabel" for="client-policy-startDate">
                        Start Date
                      </Label>
                      <Col sm="8">
                        <AvField
                          id="client-policy-startDate"
                          value={clientPolicyEntity.startDate || ''}
                          type="date"
                          className="form-control"
                          name="startDate"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="endDateLabel" for="client-policy-endDate">
                        End Date
                      </Label>
                      <Col sm={8}>
                        <AvField
                          id="client-policy-endDate"
                          type="date"
                          value={(clientPolicyEntity && clientPolicyEntity.endDate) || ''}
                          className="form-control"
                          name="endDate"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="binder-rate-coverType">
                        Cover Type
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="binder-rate-coverType"
                          type="select"
                          className="form-control"
                          value={policyEntity.coverTypeId || ''}
                          name="coverTypeId"
                          required
                        >
                          <option value="" key="">
                            {' '}
                            Select{' '}
                          </option>
                          {coverTypes
                            ? coverTypes.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="pl-3 pr-5 mb-5">
                <Col md="12 text-right">
                  <Button type="submit" className="btn btn-success" disabled={updating}>
                    <i className="fa fa-plus" /> Add New Policy
                  </Button>
                </Col>
              </Row>
            </>
          </div>
          <DataTable
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: 'Policy Name',
                accessor: 'name'
              },
              {
                Header: 'Policy Date',
                accessor: 'policyDate'
              },
              {
                Header: 'Start Date',
                accessor: 'startDate'
              },
              {
                Header: 'End Date',
                accessor: 'endDate'
              },
              {
                Header: 'Policy Id',
                accessor: 'policyId',
                sortable: false,
                filterable: false
              },
              {
                Header: 'ACTIONS',
                accessor: 'actions',
                sortable: false,
                filterable: false,
                style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
              }
            ]}
            manual
            defaultPageSize={5}
            pages={1}
            noDataText={'No client policy found'}
            onFetchData={() => {}}
            showPaginationBottom
            tableData={tablePoliciesData}
            filterable
          />
          <Col sm="12" className="mt-5">
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Button type="button" color="info" onClick={this.handleBack}>
                    <span className="d-none d-md-inline">Back</span>
                  </Button>
                </Col>
                <Col sm="12" className="text-right">
                  <Button className="btn btn-success" onClick={this.onNext} disabled={updating}>
                    <i className="fa fa-check" /> NEXT
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </AvForm>
        <ModalView open={open} setOpen={this.props.openModal} modalError={modalError} />
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskCategories: storeState.riskCategory.entities,
  riskClasses: storeState.riskClass.entities,
  coverTypes: storeState.coverType.entities,
  underwriters: storeState.underwriter.entities,
  productTypes: storeState.productType.entities,
  account: storeState.authentication.account,
  updating: storeState.client.updating || storeState.paymentCallbacks.updating,
  inputData: storeState.quote.inputData,
  clientPolicies: storeState.clientPolicy.entities,
  directPayment: storeState.paymentCallbacks.entity,
  clientEntity: storeState.client.entity,
  clientPolicyEntity: storeState.clientPolicy.entity,
  policyEntity: storeState.policy.entity,
  updateSuccess: storeState.quote.updateSuccess
});

const mapDispatchToProps = {
  getRiskCategories,
  getRiskClasses,
  getCoverTypes,
  getDirectPayment,
  getEntityByEmail,
  getClientPolicies,
  getUnderwriters,
  updatePolicy,
  updateClientPolicy,
  updatePayment,
  getPolicy,
  getClientPolicy,
  createClientPolicy,
  createPolicy,
  getProductTypes
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PolicyStep)
);
