export const enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface IPolicy {
  id?: number;
  name?: string;
  policyNo?: string;
  premiumPayable?: number;
  status?: Status;
  coverTypeId?: number;
  underwriterId?: number;
  riskCategoryId?: number;
  riskCategoryName?: string;
  riskClassId?: number;
  riskClassName?: string;
  companyId?: number;
}

export const defaultValue: Readonly<IPolicy> = {};
