import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './claim.reducer';
import { IClaim } from 'app/shared/model/claim.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClaimDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClaimDetail = (props: IClaimDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { claimEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Claim [<b>{claimEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="brokerRefNo">Broker Ref No</span>
          </dt>
          <dd>{claimEntity.brokerRefNo}</dd>
          <dt>
            <span id="fileRefNo">File Ref No</span>
          </dt>
          <dd>{claimEntity.fileRefNo}</dd>
          <dt>
            <span id="brokerClaimNo">Broker Claim No</span>
          </dt>
          <dd>{claimEntity.brokerClaimNo}</dd>
          <dt>
            <span id="policyNo">Policy No</span>
          </dt>
          <dd>{claimEntity.policyNo}</dd>
          <dt>
            <span id="insurerClaimNo">Insurer Claim No</span>
          </dt>
          <dd>{claimEntity.insurerClaimNo}</dd>
          <dt>
            <span id="coverPeriod">Cover Period</span>
          </dt>
          <dd>{claimEntity.coverPeriod}</dd>
          <dt>
            <span id="dateOfLoss">Date Of Loss</span>
          </dt>
          <dd>
            {claimEntity.dateOfLoss ? <TextFormat value={claimEntity.dateOfLoss} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="claimGroup">Claim Group</span>
          </dt>
          <dd>{claimEntity.claimGroup}</dd>
          <dt>
            <span id="dateReported">Date Reported</span>
          </dt>
          <dd>{claimEntity.dateReported ? <TextFormat value={claimEntity.dateReported} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="policeStationReportedTo">Police Station Reported To</span>
          </dt>
          <dd>{claimEntity.policeStationReportedTo}</dd>
          <dt>
            <span id="garage">Garage</span>
          </dt>
          <dd>{claimEntity.garage}</dd>
          <dt>
            <span id="claimAmount">Claim Amount</span>
          </dt>
          <dd>{claimEntity.claimAmount}</dd>
          <dt>
            <span id="excessAmount">Excess Amount</span>
          </dt>
          <dd>{claimEntity.excessAmount}</dd>
          <dt>
            <span id="settlementAmount">Settlement Amount</span>
          </dt>
          <dd>{claimEntity.settlementAmount}</dd>
          <dt>
            <span id="adjusterAppointed">Adjuster Appointed</span>
          </dt>
          <dd>{claimEntity.adjusterAppointed}</dd>
          <dt>Quote</dt>
          <dd>{claimEntity.quoteId ? claimEntity.quoteId : ''}</dd>
          <dt>Client</dt>
          <dd>{claimEntity.clientId ? claimEntity.clientId : ''}</dd>
          <dt>Risk Class</dt>
          <dd>{claimEntity.riskClassId ? claimEntity.riskClassId : ''}</dd>
          <dt>Underwriter</dt>
          <dd>{claimEntity.underwriterId ? claimEntity.underwriterId : ''}</dd>
          <dt>Cover Type</dt>
          <dd>{claimEntity.coverTypeId ? claimEntity.coverTypeId : ''}</dd>
          <dt>Client Policy</dt>
          <dd>{claimEntity.clientPolicyId ? claimEntity.clientPolicyId : ''}</dd>
          <dt>K YC</dt>
          <dd>{claimEntity.kYCId ? claimEntity.kYCId : ''}</dd>
        </dl>
        <Button tag={Link} to="/claim" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/claim/${claimEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ claim }: IRootState) => ({
  claimEntity: claim.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimDetail);
