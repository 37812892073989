import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './communication-info.reducer';
import { ICommunicationInfo } from 'app/shared/model/communication-info.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ICommunicationInfoUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICommunicationInfoUpdateState {
  isNew: boolean;
  userId: string;
}

export class CommunicationInfoUpdate extends React.Component<ICommunicationInfoUpdateProps, ICommunicationInfoUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUsers();
  }

  saveEntity = (event, errors, values) => {
    values.dateTime = convertDateTimeToServer(values.dateTime);

    if (errors.length === 0) {
      const { communicationInfoEntity } = this.props;
      const entity = {
        ...communicationInfoEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/communication-info');
  };

  render() {
    const { communicationInfoEntity, users, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="ibsApp.communicationInfo.home.createOrEditLabel">Create or edit a CommunicationInfo</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : communicationInfoEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="communication-info-id">ID</Label>
                    <AvInput id="communication-info-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="firstNameLabel" for="communication-info-firstName">
                    First Name
                  </Label>
                  <AvField id="communication-info-firstName" type="text" name="firstName" />
                </AvGroup>
                <AvGroup>
                  <Label id="lastNameLabel" for="communication-info-lastName">
                    Last Name
                  </Label>
                  <AvField id="communication-info-lastName" type="text" name="lastName" />
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="communication-info-email">
                    Email
                  </Label>
                  <AvField id="communication-info-email" type="text" name="email" />
                </AvGroup>
                <AvGroup>
                  <Label id="mobileLabel" for="communication-info-mobile">
                    Mobile
                  </Label>
                  <AvField id="communication-info-mobile" type="text" name="mobile" />
                </AvGroup>
                <AvGroup>
                  <Label id="dateTimeLabel" for="communication-info-dateTime">
                    Date Time
                  </Label>
                  <AvInput
                    id="communication-info-dateTime"
                    type="datetime-local"
                    className="form-control"
                    name="dateTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.communicationInfoEntity.dateTime)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="typeLabel" for="communication-info-type">
                    Type
                  </Label>
                  <AvInput
                    id="communication-info-type"
                    type="select"
                    className="form-control"
                    name="type"
                    value={(!isNew && communicationInfoEntity.type) || 'EMAIL'}
                  >
                    <option value="EMAIL">EMAIL</option>
                    <option value="SMS">SMS</option>
                    <option value="CALL">CALL</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="entityNameLabel" for="communication-info-entityName">
                    Entity Name
                  </Label>
                  <AvInput
                    id="communication-info-entityName"
                    type="select"
                    className="form-control"
                    name="entityName"
                    value={(!isNew && communicationInfoEntity.entityName) || 'QUOTE'}
                  >
                    <option value="QUOTE">QUOTE</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="entityIdLabel" for="communication-info-entityId">
                    Entity Id
                  </Label>
                  <AvField id="communication-info-entityId" type="string" className="form-control" name="entityId" />
                </AvGroup>
                <AvGroup>
                  <Label id="contentLabel" for="communication-info-content">
                    Content
                  </Label>
                  <AvField id="communication-info-content" type="text" name="content" />
                </AvGroup>
                <AvGroup>
                  <Label for="communication-info-user">User</Label>
                  <AvInput id="communication-info-user" type="select" className="form-control" name="userId">
                    <option value="" key="0" />
                    {users
                      ? users.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/communication-info" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  communicationInfoEntity: storeState.communicationInfo.entity,
  loading: storeState.communicationInfo.loading,
  updating: storeState.communicationInfo.updating,
  updateSuccess: storeState.communicationInfo.updateSuccess
});

const mapDispatchToProps = {
  getUsers,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationInfoUpdate);
