import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';

const DebitNote = ({ client, clientPolicy, kYC, account, policy, debitNoteEntity }) => {
  const companyAddress = `
  P O Box 28281-00200, Nairobi
  Tel: 2211152/2219636/2213253
  Mobile: 0720498001/0734534098
  Fax: 020 2218174
  1st Ngong Avenue, ACK Garden House, Ground Floor, Wing D, Nairobi, Kenya
  Email: info@kenbright.co.ke
`;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentContainer}>
          <View style={styles.content}>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Image src="/content/images/broker-agent.png" style={styles.logo} />
                <Text style={styles.companyAddress}>{companyAddress}</Text>
              </View>
              <View style={styles.headerCol}>
                <Text style={styles.title}>DEBIT NOTE - {debitNoteEntity.id || ''}</Text>
              </View>
            </View>
            <View style={styles.customerDetails}>
              <View style={styles.customerInfo}>
                <Text style={styles.customerValue}>{`${client.firstName} ${client.middleName} ${client.lastName}`}</Text>
                <Text style={styles.customerValue}>Account NO: {client.id}</Text>
                <Text style={styles.customerValue}>Underwriter: {policy.underwriterName}</Text>
              </View>
              <View style={styles.customerDateInfo}>
                <Text style={[styles.customerLabel, styles.float]}>DATE: {new Date().toLocaleDateString()}</Text>
              </View>
            </View>
            <View style={styles.policySection}>
              <View style={styles.policyRow}>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>POLICY NO</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>POLICY TYPE</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>EFFECTIVE DATE</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>EXPIRY DATE</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>PREMIUM</Text>
                </View>
              </View>
              <View style={styles.policyRow}>
                <View style={styles.policyCol}>
                  <Text style={[styles.textLabel, styles.value, styles.cap]}> {policy.policyNo}</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={[styles.textLabel, styles.value, styles.cap]}> {policy.riskClassName || ''}</Text>
                  <Text style={[styles.textLabel, styles.value, styles.cap]}> {kYC.registration || ''}</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={[styles.textLabel, styles.value, styles.cap]}> {clientPolicy.startDate || ''}</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={[styles.textLabel, styles.value, styles.cap]}>{clientPolicy.endDate || ''}</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={[styles.textLabel, styles.value, styles.cap]}>Ksh {clientPolicy.premiumPayable}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.narration, styles.marginTop]}>
              <Text style={styles.value}>Details:</Text>
              <Text style={[styles.value, styles.marginTop]}>{debitNoteEntity.remarks || ''}</Text>
              <View style={styles.sepparator} />
            </View>
            <View style={styles.paymentSummary}>
              <View style={styles.firstThird} />
              <View style={styles.content}>
                <View style={styles.lastThird}>
                  <View>
                    <Text style={styles.summaryItem}>Net Premium</Text>
                    <Text style={styles.summaryItem}>Training Levy</Text>
                    <Text style={styles.summaryItem}>PCF</Text>
                    <Text style={styles.summaryItem}>Stamp Duty</Text>
                    <Text style={styles.summaryItem}>Total Premium</Text>
                  </View>
                  <View>
                    <Text style={styles.summaryItem}>{clientPolicy.premiumPayable}</Text>
                    <Text style={styles.summaryItem}>{clientPolicy.premiumPayable * 0.002}</Text>
                    <Text style={styles.summaryItem}>{clientPolicy.premiumPayable * 0.0025}</Text>
                    <Text style={styles.summaryItem}>40.00</Text>
                    <Text style={styles.summaryItem}>
                      {Math.ceil(clientPolicy.premiumPayable + 40 + clientPolicy.premiumPayable * 0.0045)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.footerSection}>
              <View style={styles.sepparator} />
              <View style={styles.textRow}>
                <Text style={styles.textLabel}>Passed By:</Text>
                <Text style={styles.value}>{account.login}</Text>
              </View>
              <View style={styles.textRow}>
                <Text style={styles.textLabel}>Authorized By:</Text>
                <Text style={styles.value}>info@kenbright.co.ke</Text>
              </View>
              <View style={styles.textRow}>
                <Text style={[styles.textImportant, styles.cap, styles.bold]}>important</Text>
                <Text style={styles.textImportant}>
                  : Please note that the Insurance Act requires that premiums are paid prior to the inception of cover
                </Text>
              </View>
              <View style={styles.textRow}>
                <Text style={[styles.businessNo, styles.cap]}>Mpesa Business Number</Text>
                <Text style={styles.businessNo}>: 806 550</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 35,
    backgroundColor: '#F5F5F5'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between'
  },
  content: {
    flex: 1
  },
  firstThird: {
    flex: 3
  },
  lastThird: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  headerSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    paddingBottom: 20,
    marginBottom: 20
  },
  headerCol: {
    display: 'flex',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderLeft: '1px solid #ddd'
  },
  logo: {
    width: 150,
    height: 80
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
    textTransform: 'uppercase'
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    marginBottom: 10
  },
  textLabel: {
    fontWeight: 'heavy',
    fontSize: 10
  },
  businessNo: {
    fontWeight: 'bold',
    fontSize: 15
  },
  textImportant: {
    fontSize: 10
  },
  value: {
    fontSize: 8,
    marginLeft: 5
  },
  bold: {
    fontWeight: 'bold'
  },
  cap: {
    textTransform: 'uppercase'
  },
  policySection: {
    border: '1px solid #ddd',
    flexDirection: 'column',
    marginTop: 20
  },
  policyRow: {
    flexDirection: 'row'
  },
  marginTop: {
    marginTop: 15
  },
  narration: {
    height: '25%',
    fontSize: 10,
    justifyContent: 'space-between'
  },
  policyCol: {
    flex: 1,
    border: '1px solid #ddd',
    padding: 10
  },
  footerSection: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 'auto'
  },
  important: {
    fontWeight: 'bold',
    color: 'red',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  float: {
    right: 'auto'
  },
  customerDetails: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 20,
    marginLeft: -10
  },
  customerInfo: {
    width: '45%',
    flexDirection: 'column',
    marginBottom: 10,
    paddingLeft: 10
  },
  customerDateInfo: {
    width: '45%',
    flexDirection: 'column',
    marginBottom: 10,
    paddingLeft: 10,
    alignItems: 'flex-end'
  },
  customerLabel: {
    fontWeight: 'bold',
    fontSize: 12
  },
  customerValue: {
    fontSize: 14
  },
  sepparator: {
    marginBottom: 5,
    borderBottom: '1px solid #ddd',
    width: '100%'
  },
  companyAddress: {
    fontSize: 6,
    marginTop: 2
  },
  borderTop: {
    borderTop: '1px solid #ddd',
    paddingTop: 10
  },
  paymentSummary: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    fontSize: 10
  },
  summaryItem: {
    fontSize: 7,
    marginBottom: 4
  }
});

export default DebitNote;
