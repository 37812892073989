import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Table, Row, Col, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction } from 'react-jhipster';
import DataTable from 'app/shared/common/dataTable';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from './risk-class-formula.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { IRiskClassFormula } from 'app/shared/model/risk-class-formula.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IRiskClassFormulaState {
  riskClassId: string;
  coverTypeId: string;
}

export interface IRiskClassFormulaProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class RiskClassFormula extends React.Component<IRiskClassFormulaProps, IRiskClassFormulaState> {
  constructor(props) {
    super(props);
    this.state = {
      riskClassId: '0',
      coverTypeId: '0'
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData() {
    const { riskClassId, coverTypeId } = this.props.location.state;
    this.props.getEntities(riskClassId, coverTypeId);
    this.setState({ riskClassId, coverTypeId });
  }

  getMaxPage() {
    const { riskClassFormulaList } = this.props;
    const totalItems = riskClassFormulaList.length;
    const division = Math.floor(totalItems / ITEMS_PER_PAGE);
    const modulo = totalItems % ITEMS_PER_PAGE;
    return division + (modulo !== 0 ? 1 : 0);
  }
  render() {
    const { riskClassId, coverTypeId } = this.state;
    const { loading, riskClassFormulaList, match } = this.props;
    const tableData = riskClassFormulaList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      riskClassId: (prop.riskClassId && <NavLink to={`risk-class/${prop.riskClassId}`}>{prop.riskClassId}</NavLink>) || '',
      actions: (
        <div className="text-center">
          <Button className="ml-1" color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button
            className="ml-1"
            color="inverse"
            size="sm"
            round="true"
            icon="true"
            tag={NavLink}
            to={{
              pathname: `${match.url}/${prop.id}/edit`,
              state: {
                riskClassId,
                coverTypeId
              }
            }}
          >
            <i className="fa fa-edit" />
          </Button>
          <Button
            className="ml-1"
            color="danger"
            size="sm"
            round="true"
            icon="true"
            tag={NavLink}
            to={{
              pathname: `${match.url}/${prop.id}/delete`,
              state: {
                riskClassId,
                coverTypeId
              }
            }}
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));
    return (
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <Row>
            <Col sm="6">
              <i className="mdi mdi-border-right mr-2" />
              Risk Class Formulas
            </Col>
            <Col sm="6" className="text-right">
              <NavLink
                to={{
                  pathname: `${match.url}/new`,
                  state: {
                    riskClassId,
                    coverTypeId
                  }
                }}
                color="success"
                activeClassName="active"
                className="btn btn-create-new"
              >
                Create new Risk Class Formula
              </NavLink>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <DataTable
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: 'Name',
                accessor: 'name'
              },
              {
                Header: 'Calculation Param',
                accessor: 'calculationParam'
              },
              {
                Header: 'Calculation Type',
                accessor: 'calculationType'
              },
              {
                Header: 'Risk Class',
                accessor: 'riskClassId',
                sortable: false,
                filterable: false
              },
              {
                Header: 'Actions',
                accessor: 'actions',
                sortable: false,
                filterable: false
              }
            ]}
            onFetchData={this.fetchData}
            defaultPageSize={10}
            showPaginationBottom
            loading={loading}
            pages={this.getMaxPage()}
            tableData={tableData}
            filterable
            manual
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ riskClassFormula }: IRootState) => ({
  riskClassFormulaList: riskClassFormula.entities,
  loading: riskClassFormula.loading
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskClassFormula);
