import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from './benefit-span-type.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import DataTable from 'app/shared/common/dataTable';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface IBenefitSpanTypeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IBenefitSpanTypeState = IPaginationBaseState;

export class BenefitSpanType extends React.Component<IBenefitSpanTypeProps, IBenefitSpanTypeState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`companyId=${this.props.account.companyId}`);
    this.props.getEntities(state.page, state.pageSize, `${sort || 'id'},${order || 'desc'}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { loading, benefitSpanTypeList, match, totalItems, isAdmin } = this.props;
    // @ts-ignore
    const tableData = benefitSpanTypeList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      actions: (
        <div className="text-center">
          <Button className="ml-1" color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));

    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Benefit Span Types
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  <FontAwesomeIcon icon="plus" />
                  &nbsp; Create new Benefit Span Type
                </NavLink>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Inpatient',
                  accessor: 'inpatient'
                },
                {
                  Header: 'Outpatient',
                  accessor: 'outpatient'
                },
                {
                  Header: 'Maternity',
                  accessor: 'maternity'
                },
                {
                  Header: 'Dental',
                  accessor: 'dental'
                },
                {
                  Header: 'Optical',
                  accessor: 'optical'
                },
                {
                  Header: 'Underwriter',
                  accessor: 'underwriterName'
                },
                {
                  Header: 'RiskClass',
                  accessor: 'riskClassName'
                },
                {
                  Header: 'CoverType',
                  accessor: 'coverTypeName'
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ benefitSpanType, authentication }: IRootState) => ({
  benefitSpanTypeList: benefitSpanType.entities,
  loading: benefitSpanType.loading,
  totalItems: benefitSpanType.totalItems,
  account: authentication.account,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitSpanType);
