import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import Invoice from 'app/entities/invoice/index';

const EntityTemplate = props => (
  <MainTemplate>
    <Invoice {...props} />
  </MainTemplate>
);

export default EntityTemplate;
