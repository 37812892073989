import React, { Component } from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { TextFormat } from 'react-jhipster';
import { Card, Row, Label, Col, CardBody, Button } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { APP_LOCAL_STRING_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { RouteComponentProps } from 'react-router-dom';
import { getEntities as getCarMakes } from 'app/entities/car-make/car-make.reducer';
import { getEntityModels as getCarModels } from 'app/entities/car-model/car-model.reducer';
import { getEntities as getBenefits } from 'app/entities/benefit/benefit.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IPolicySummaryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

class PolicySummary extends React.Component<IPolicySummaryProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      calculatePremium: 0
    };
  }

  componentDidMount() {
    this.props.getCarMakes(0, 2000);
    const year = new Date().getFullYear();
    this.setState({
      currentYear: year,
      yearOfManufactures: Array.from(new Array(42), (val, index) => ({
        name: String(year - index),
        id: year - index
      })).reverse()
    });
  }

  handleDateChange = date => {
    this.setState({ actionDate: date });
  };

  getCurrentLogo = quote => {
    const { logoFile } = quote;
    if (logoFile && logoFile.content) {
      return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
    }
    return null;
  };

  saveEntity = async (event, errors, values) => {};

  async getCarModel(e) {
    if (!(event && event.target)) {
      return;
    }
    const make = await this.props.vehicleMakes.find(item => Number(item.id) === Number(e.target.value));
    this.setState({ carMakeId: make.id, carMake: make.name });
    this.props.getCarModels(make.id);
  }

  render() {
    const { quote, vehicleModels, vehicleMakes, riskClasses, coverTypes, underwriters, updating, benefits, isPartner } = this.props;
    const { isNew, yearOfManufactures } = this.state;
    return (
      <Card>
        <CardBody className="p-5">
          <AvForm model={isNew ? {} : quote} onSubmit={this.saveEntity}>
            <Row className="d-flex justify-content-center p-5">
              <div className="t-header flex-2">
                <h4>Policy Summary</h4>
              </div>
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="quote-coverType">
                      Cover Type
                    </Label>
                    <Col>
                      <AvInput id="quote-coverType" disabled={isPartner} type="select" className="form-control" name="coverTypeId">
                        <option value="" key="0" />
                        {coverTypes
                          ? coverTypes.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="quote-riskClass">
                      Risk Class
                    </Label>
                    <Col>
                      <AvInput id="quote-riskClass" disabled={isPartner} type="select" className="form-control" name="riskClassId">
                        <option value="" key="0" />
                        {riskClasses
                          ? riskClasses.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="4" id="sumInsuredLabel" for="quote-sumInsured">
                      Sum Insured
                    </Label>
                    <Col>
                      <AvField id="quote-sumInsured" disabled={isPartner} type="string" className="form-control" name="sumInsured" />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="car-make">
                      Car Make
                    </Label>
                    <Col>
                      <AvInput
                        id="car-make"
                        type="select"
                        disabled={isPartner}
                        className="form-control"
                        name="carMakeId"
                        onChange={e => this.getCarModel(e)}
                      >
                        <option disabled value="" key="0" />
                        {vehicleMakes
                          ? vehicleMakes.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="car-model">
                      Car Model
                    </Label>
                    <Col>
                      <AvInput id="car-model" disabled={isPartner} type="select" className="form-control" name="carModelId" required>
                        <option disabled value="" key="0" />
                        {vehicleModels
                          ? vehicleModels.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="yearOfManufacture">
                      Year Of Manufacture
                    </Label>
                    <Col>
                      <AvInput
                        id="yearOfManufacture"
                        type="select"
                        className="form-control"
                        disabled={isPartner}
                        name="yearOfManufacture"
                        required
                      >
                        <option disabled value="" key="0" />
                        {yearOfManufactures
                          ? yearOfManufactures.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="premiumPayableLabel" for="quote-premiumPayable">
                      Premium Payable
                    </Label>
                    <Col>
                      <AvField
                        id="quote-premiumPayable"
                        type="string"
                        disabled={isPartner}
                        className="form-control"
                        name="premiumPayable"
                      />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="rateLabel" for="quote-rate">
                      Rate
                    </Label>
                    <Col>
                      <AvField id="quote-rate" disabled={isPartner} type="string" className="form-control" name="rate" />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="dateLabel" for="quote-date">
                      Start Date
                    </Label>
                    <Col>
                      {isPartner && <AvField id="quote-date" type="text" disabled className="form-control" name="date" />}
                      {!isPartner && <AvField id="quote-date" type="date" className="form-control" name="date" />}
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="expiryDateLabel" for="quote-expiryDate">
                      Expiry Date
                    </Label>
                    <Col>
                      {isPartner && <AvField id="quote-expiryDate" type="text" disabled className="form-control" name="expiryDate" />}
                      {!isPartner && <AvField id="quote-expiryDate" type="date" className="form-control" name="expiryDate" />}
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="quote-underwriter">
                      Underwriter
                    </Label>
                    <Col>
                      <AvInput id="quote-underwriter" disabled={isPartner} type="select" className="form-control" name="underwriterId">
                        <option value="" key="0" />
                        {underwriters
                          ? underwriters.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              {!isPartner && (
                <Col>
                  <AvGroup>
                    <Row>
                      <Label sm="3" id="quoteSourceLabel" for="quote-quoteSource">
                        Quote Source
                      </Label>
                      <Col>
                        <AvField id="quote-quoteSource" type="text" name="source" />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              )}
            </Row>
            <Row>
              {!isPartner && (
                <Col md={6}>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Col sm="3" />
                          <Col>
                            <Label id="isEmailedLabel" check style={{ marginLeft: 20 }}>
                              <AvInput id="quote-isEmailed" type="checkbox" className="form-control" name="isEmailed" />
                              Is Emailed
                            </Label>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Col sm="3" />
                          <Col>
                            <Label id="isMessagedLabel" check style={{ marginLeft: 20 }}>
                              <AvInput id="quote-isMessaged" type="checkbox" className="form-control" name="isMessaged" />
                              Is Messaged
                            </Label>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm={3} for="quote-additionalBenefits">
                      Additional Benefits
                    </Label>
                    <Col>
                      <AvInput
                        id="quote-additionalBenefits"
                        type="select"
                        multiple
                        disabled={isPartner}
                        className="form-control"
                        name="additionalBenefits"
                        value={quote.additionalBenefits && quote.additionalBenefits.map(e => e.id)}
                      >
                        <option value="" key="0" />
                        {benefits
                          ? benefits.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row className={'pl-3 pr-5 mt-3 mb-5'}>
              <Col md="12 text-right">
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> UPDATE
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  underwriters: storeState.underwriter.entities,
  quote: storeState.quote.entity,
  updating: storeState.quote.updating,
  vehicleMakes: storeState.carMake.entities,
  vehicleModels: storeState.carModel.entities,
  riskClasses: storeState.riskClass.entities,
  coverTypes: storeState.coverType.entities,
  benefits: storeState.benefit.entities,
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER])
});

const mapDispatchToProps = {
  getCarMakes,
  getCarModels,
  getBenefits
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicySummary);
