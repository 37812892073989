import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AkiWizard from './aki-wizard';
// import UnderwriterDetail from './underwriter-detail';
// import UnderwriterUpdate from './underwriter-update';
import AddKYCDialog from './add-kyc-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      {/*<ErrorBoundaryRoute exact path={`${match.url}/new`} component={UnderwriterUpdate} />*/}
      {/*<ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UnderwriterUpdate} />*/}
      {/*<ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UnderwriterDetail} />*/}
      <ErrorBoundaryRoute path={match.url} component={AkiWizard} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/add-kyc`} component={AddKYCDialog} />
  </>
);

export default Routes;
