import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './name-title.reducer';
import { INameTitle } from 'app/shared/model/name-title.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface INameTitleUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface INameTitleUpdateState {
  isNew: boolean;
}

export class NameTitleUpdate extends React.Component<INameTitleUpdateProps, INameTitleUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { nameTitleEntity } = this.props;
      const entity = {
        ...nameTitleEntity,
        ...values,
        companyId: this.props.account.companyId
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/name-title');
  };

  render() {
    const { nameTitleEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a NameTitle
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : nameTitleEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="name-title-name">
                            Name
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="name-title-name"
                              type="text"
                              name="name"
                              placeholder="Enter name"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/name-title" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  nameTitleEntity: storeState.nameTitle.entity,
  loading: storeState.nameTitle.loading,
  updating: storeState.nameTitle.updating,
  updateSuccess: storeState.nameTitle.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NameTitleUpdate);
