import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { Button, Row, Container, Col, Label, Input, FormGroup } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-flags-select/css/react-flags-select.css';
import { IRootState } from 'app/shared/reducers';
import { CarBodyTypes } from 'app/config/constants';
import { createEntity } from '../../kyc/kyc.reducer';
import { createEntity as createAttachment } from '../../attachment/attachment.reducer';
import { updateEntity as updateQuote } from 'app/entities/quote/quote.reducer';
import { createEntity as createAttachmentFile } from '../../attachment-file/attachment-file.reducer';
import { getEntities as getCarMakes } from 'app/entities/car-make/car-make.reducer';
import { getEntityModels as getCarModels } from 'app/entities/car-model/car-model.reducer';
import { addVehicleAdditionalInfo } from 'app/shared/reducers/vehicle-adittional-info.reducer';
import { AttachmentType, EntityName } from 'app/shared/model/attachment.model';

export interface IQuoteProps extends StateProps, DispatchProps {
  jumpToStep;
}

class QuoteKYCStep extends React.Component<IQuoteProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      preview_id: null,
      preview_licence: null,
      preview_logbook: null,
      preview_valuation_report: null,
      carBodyTypes: CarBodyTypes
    };
    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getCarMakes(0, 2000);
    const year = new Date().getFullYear();
    this.setState({
      currentYear: year,
      yearOfManufactures: Array.from(new Array(42), (val, index) => ({
        name: String(year - index),
        id: year - index
      })).reverse()
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(4);
    }
  }

  async getCarModel(e) {
    if (!(event && event.target)) {
      return;
    }
    const make = await this.props.vehicleMakes.find(item => Number(item.id) === Number(e.target.value));
    this.setState({ carMakeId: make.id, carMake: make.name });
    this.props.getCarModels(make.id);
  }

  async handleChange(e) {
    const attachmentFilesUrl = 'api/attachment-files';
    const attachmentsUrl = 'api/attachments';
    const currentFile = e.target.files[0];
    const currentFileURI = URL.createObjectURL(currentFile);
    this.setState({
      [e.target.name]: e.target.files[0],
      [`preview_${e.target.name}`]: currentFile.name
    });

    const buffer = await currentFile.arrayBuffer();
    const byteArray = new Int8Array(buffer);

    const attachmentFile = {
      content: Array.from(byteArray),
      contentContentType: currentFile.type
    };
    const aFile = await axios.post(attachmentFilesUrl, attachmentFile);
    const attachment = {
      name: currentFile.name,
      type: AttachmentType.PIN_IMAGE,
      entityName: EntityName.CLIENT,
      entityId: 1,
      path: currentFileURI,
      format: currentFile.type,
      attachmentFileId: aFile.data.id
    };
    const at = await axios.post(attachmentsUrl, attachment);
    this.setState({ attachments: [...this.state.attachments, at.data] });
  }

  handleSubmit = async (event, errors, values) => {
    const cart = this.props.quote.cart[0];
    const client = this.props.client;
    const quoteRef = this.props.quote.entity;
    values['companyId'] = this.props.account.companyId;
    if (errors.length === 0) {
      const kyc = {
        ...values,
        make: this.state.carMake,
        model: this.props.vehicleModels.find(v => Number(v.id) === Number(values.model)).name,
        mobile: this.props.quote.entity.mobile,
        registration: this.props.quote.entity.registration,
        valueOfCar: this.props.quote.entity.sumInsured,
        riskClassId: this.props.quote.entity.riskClassId,
        clientId: this.props.client.id,
        attachments: this.state.attachments,
        generalKYCType: 'OTHER',
        motorKYCType: 'OTHER',
        customKYCType: '',
        expiryDate: this.props.quote.entity.expiryDate
      };
      const vehicleInfo = {
        bodyType: values.carBodyType,
        chassisNumber: values.chassisNumber,
        engineNumber: values.engineNumber
      };
      this.props.addVehicleAdditionalInfo(vehicleInfo);
      const quote = {
        ...values,
        ...cart,
        clientName: `${client.firstName} ${client.lastName}`,
        quoteSource: 'IBS Portal',
        riskClassName: quoteRef.riskClassName,
        coverTypeName: quoteRef.coverTypeName,
        carMakeId: values.make,
        clientId: this.props.client.id,
        carModelId: values.model,
        yearOfManufacture: values.year
      };
      await this.props.createEntity(kyc);
      await this.props.updateQuote(quote);
    }
  };

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(2);
  }

  render() {
    const { vehicleMakes, vehicleModels } = this.props;
    const { carBodyTypes, yearOfManufactures } = this.state;
    return (
      <div className="step step2 mb-5">
        <Row className="justify-content-md-center">
          <Col>
            <AvForm onSubmit={this.handleSubmit}>
              <Col className={'mb-5'}>
                <Row>
                  <Col sm={6} md={6} lg={3}>
                    <AvGroup>
                      <Col>
                        <Label for="kyc_id">National ID</Label>
                        <Col>
                          <AvInput
                            onChange={this.handleChange}
                            id="kyc_id"
                            type="file"
                            className="form-control"
                            name="nationalId"
                            required
                          />
                        </Col>
                        {this.state.preview_nationalId && <span>{this.state.preview_nationalId}</span>}
                      </Col>
                    </AvGroup>
                  </Col>
                  <Col sm={6} md={6} lg={3}>
                    <AvGroup>
                      <Col>
                        <Label for="kyc_driving_licence">Driving Licence</Label>
                        <Col>
                          <AvInput
                            onChange={e => this.handleChange(e)}
                            id="kyc_driving_licence"
                            type="file"
                            className="form-control"
                            required
                            name="driving_licence"
                          />
                        </Col>
                        {this.state.preview_driving_licence && <span className="h-1 pt-2">{this.state.preview_driving_licence}</span>}
                      </Col>
                    </AvGroup>
                  </Col>
                  <Col sm={6} md={6} lg={3}>
                    <AvGroup>
                      <Col>
                        <Label for="kyc_logbook">Log Book</Label>
                        <Col>
                          <AvInput
                            onChange={e => this.handleChange(e)}
                            id="kyc_logbook"
                            type="file"
                            className="form-control"
                            required
                            name="logbook"
                          />
                        </Col>
                        {this.state.preview_logbook && <span>{this.state.preview_logbook}</span>}
                      </Col>
                    </AvGroup>
                  </Col>
                  <Col sm={6} md={6} lg={3}>
                    <AvGroup>
                      <Col>
                        <Label for="kyc_valuation_report">Vehicle Valuation Report</Label>
                        <Col>
                          <AvInput
                            onChange={e => this.handleChange(e)}
                            id="kyc_valuation_report"
                            type="file"
                            className="form-control"
                            required
                            name="valuation_report"
                          />
                        </Col>
                        {this.state.preview_valuation_report && <span>{this.state.preview_valuation_report}</span>}
                      </Col>
                    </AvGroup>
                  </Col>
                </Row>
              </Col>
              <Row className={'justify-content-md-center'}>
                <Col lg={9}>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="chassisNumberLabel">
                            Chassis Number
                          </Label>
                          <Col sm="8">
                            <AvField id="chassisNumberLabel" type="text" className="form-control" name="chassisNumber" required />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="engineNumberLabel">
                            Engine Number
                          </Label>
                          <Col sm="8">
                            <AvField id="engineNumberLabel" type="text" className="form-control" name="engineNumber" required />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="car-make">
                            Car Make
                          </Label>
                          <Col sm="8">
                            <AvInput id="car-make" type="select" className="form-control" name="make" onChange={e => this.getCarModel(e)}>
                              <option disabled value="" key="0" />
                              {vehicleMakes
                                ? vehicleMakes.map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        {this.state.carMakeId && (
                          <Row>
                            <Label sm="4" for="car-model">
                              Car Model
                            </Label>
                            <Col sm="8">
                              <AvInput id="car-model" type="select" className="form-control" name="model" required>
                                <option disabled value="" key="0" />
                                {vehicleModels
                                  ? vehicleModels.map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.name}
                                      </option>
                                    ))
                                  : null}
                              </AvInput>
                              <AvFeedback>This field is required.</AvFeedback>
                            </Col>
                          </Row>
                        )}
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="yearOfManufacture">
                            Year Of Manufacture
                          </Label>
                          <Col sm="8">
                            <AvInput id="yearOfManufacture" type="select" className="form-control" name="year" required>
                              <option disabled value="" key="0" />
                              {yearOfManufactures
                                ? yearOfManufactures.map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="valuationCentre">
                            Valuation Centre
                          </Label>
                          <Col sm="8">
                            <AvField id="valuationCentre" type="text" className="form-control" name="valuationCenter" required />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="color">
                            Color
                          </Label>
                          <Col sm="8">
                            <AvField id="color" type="text" className="form-control" name="color" required />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="effective-date">
                            Valuation Date
                          </Label>
                          <Col sm="8">
                            <AvField id="valuation-date" type="date" className="form-control" name="dateOfValuation" required />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="kyc_type">
                            KYC Type
                          </Label>
                          <Col sm="8">
                            <AvInput id="kyc_type" type="select" className="form-control" name="kycType">
                              <option disabled value="" key="0" />
                              <option value="GENERAL" key="1">
                                GENERAL
                              </option>
                              <option value="RISK_CLASS" key="2">
                                RISK CLASS
                              </option>
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="car-body-type">
                            Car Body Type
                          </Label>
                          <Col sm="8">
                            <AvInput id="car-body-type" type="select" className="form-control" name="carBodyType" required>
                              <option disabled value="" key="0" />
                              {carBodyTypes
                                ? carBodyTypes.map(otherEntity => (
                                    <option value={otherEntity} key={otherEntity}>
                                      {otherEntity}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <FormGroup className="mt-5">
                    <Row>
                      <Col sm="6">
                        <Button type="button" color="info" onClick={this.handleBack}>
                          <span className="d-none d-md-inline">Back</span>
                        </Button>
                      </Col>
                      <Col sm="6" className="text-right">
                        <Button type="submit" className="btn btn-success">
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  vehicleMakes: storeState.carMake.entities,
  vehicleModels: storeState.carModel.entities,
  attachment: storeState.attachment.entity,
  quote: storeState.quote,
  client: storeState.client.entity,
  attachments: storeState.attachment.attachments,
  account: storeState.authentication.account,
  updating: storeState.kYC.updating,
  attachmentFile: storeState.attachmentFile.entity,
  updateSuccess: storeState.kYC.updateSuccess,
  attachmentUpdateSuccess: storeState.attachment.updateSuccess
});

const mapDispatchToProps = {
  getCarMakes,
  getCarModels,
  updateQuote,
  createEntity,
  createAttachment,
  createAttachmentFile,
  addVehicleAdditionalInfo
  // getTonnageRanges
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteKYCStep);
