import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const DashboardMenu = () => (
  <MenuItem to="/" icon="mdi mdi-view-dashboard">
    Dashboard
  </MenuItem>
);

export default DashboardMenu;
