import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';

import 'react-table/react-table.css';

const ReactSelectTable = selectTableHOC(Table);

// Define the state interface
interface IMyTableState {
  selectAll: boolean;
  selection: string[];
}

class SelectTable extends Component<any, any> {
  static defaultProps = {
    keyField: 'id'
  };

  static propTypes = {
    keyField: PropTypes.string
  };
  checkboxTableRef: React.RefObject<any> | null;

  constructor(props) {
    super(props);
    this.state = {};
    this.checkboxTableRef = React.createRef();
  }

  componentDidMount() {}

  /**
   * Toggle a single checkbox for select table
   */
  toggleSelection = (key, shift, row) => {
    // start off with the existing state
    let selection = [...this.props.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the selection
    this.props.onSelect(false, selection);
    // this.setState({ selection });
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { keyField } = this.props;

    const selectAll = !this.props.selectAll;
    const selection = [];

    if (selectAll && this.checkboxTableRef && this.checkboxTableRef.current) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTableRef.current.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        selection.push(`select-${item._original[keyField]}`);
      });
    }
    this.props.onSelect(selectAll, selection);
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = key => {
    const { log } = console;
    return this.props.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => {
    const { selection } = this.props;
    return {
      onClick: (e, handleOriginal) => {
        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {
        background: rowInfo && selection.includes(`select-${rowInfo.original.id}`) && 'lightgreen'
      }
    };
  };

  render() {
    const { columns, data, keyField, styles } = this.props;
    const { log } = console;
    // log(data);
    return (
      <ReactSelectTable
        keyField={keyField}
        columns={columns}
        data={data}
        defaultPageSize={data.length || 10}
        ref={this.props.tableRef}
        toggleSelection={this.toggleSelection}
        selectAll={this.state.selectAll}
        selectType="checkbox"
        toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        style={styles}
      />
    );
  }
}

export default SelectTable;
