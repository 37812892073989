import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const AgentDashboardMenu = () => (
  <MenuItem to="/agent" icon="mdi mdi-view-dashboard">
    Dashboard
  </MenuItem>
);

export default AgentDashboardMenu;
