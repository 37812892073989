import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './cover-area.reducer';
import { ICoverArea } from 'app/shared/model/cover-area.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICoverAreaDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CoverAreaDetail = (props: ICoverAreaDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { coverAreaEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          CoverArea [<b>{coverAreaEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{coverAreaEntity.name}</dd>
          <dt>
            <span id="description">Description</span>
          </dt>
          <dd>{coverAreaEntity.description}</dd>
        </dl>
        <Button tag={Link} to="/cover-area" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cover-area/${coverAreaEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ coverArea }: IRootState) => ({
  coverAreaEntity: coverArea.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverAreaDetail);
