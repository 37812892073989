import { Moment } from 'moment';
import { PaymentMethod } from 'app/shared/model/enumerations/payment-method.model';

export interface IRemittance {
  id?: number;
  transCode?: string;
  transDate?: string;
  paymentMethod?: PaymentMethod;
  underwriterId?: number;
}

export const defaultValue: Readonly<IRemittance> = {};
