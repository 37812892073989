import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ICarSerie } from 'app/shared/model/car-serie.model';
import { getEntities as getCarSeries } from 'app/entities/car-serie/car-serie.reducer';
import { ICarType } from 'app/shared/model/car-type.model';
import { getEntities as getCarTypes } from 'app/entities/car-type/car-type.reducer';
import { ICarModel } from 'app/shared/model/car-model.model';
import { getEntities as getCarModels } from 'app/entities/car-model/car-model.reducer';
import { getEntity, updateEntity, createEntity, reset } from './car-trim.reducer';
import { ICarTrim } from 'app/shared/model/car-trim.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ICarTrimUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICarTrimUpdateState {
  isNew: boolean;
  carSerieId: string;
  carTypeId: string;
  carModelId: string;
}

export class CarTrimUpdate extends React.Component<ICarTrimUpdateProps, ICarTrimUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      carSerieId: '0',
      carTypeId: '0',
      carModelId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getCarSeries();
    this.props.getCarTypes();
    this.props.getCarModels();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { carTrimEntity } = this.props;
      const entity = {
        ...carTrimEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/car-trim');
  };

  render() {
    const { carTrimEntity, carSeries, carTypes, carModels, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="ibsApp.carTrim.home.createOrEditLabel">Create or edit a CarTrim</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : carTrimEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="car-trim-id">ID</Label>
                    <AvInput id="car-trim-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="car-trim-name">
                    Name
                  </Label>
                  <AvField id="car-trim-name" type="text" name="name" />
                </AvGroup>
                <AvGroup>
                  <Label id="startProductionYearLabel" for="car-trim-startProductionYear">
                    Start Production Year
                  </Label>
                  <AvField id="car-trim-startProductionYear" type="string" className="form-control" name="startProductionYear" />
                </AvGroup>
                <AvGroup>
                  <Label id="endProductionYearLabel" for="car-trim-endProductionYear">
                    End Production Year
                  </Label>
                  <AvField id="car-trim-endProductionYear" type="string" className="form-control" name="endProductionYear" />
                </AvGroup>
                <AvGroup>
                  <Label for="car-trim-carSerie">Car Serie</Label>
                  <AvInput id="car-trim-carSerie" type="select" className="form-control" name="carSerieId">
                    <option value="" key="0" />
                    {carSeries
                      ? carSeries.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="car-trim-carType">Car Type</Label>
                  <AvInput id="car-trim-carType" type="select" className="form-control" name="carTypeId">
                    <option value="" key="0" />
                    {carTypes
                      ? carTypes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="car-trim-carModel">Car Model</Label>
                  <AvInput id="car-trim-carModel" type="select" className="form-control" name="carModelId">
                    <option value="" key="0" />
                    {carModels
                      ? carModels.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/car-trim" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  carSeries: storeState.carSerie.entities,
  carTypes: storeState.carType.entities,
  carModels: storeState.carModel.entities,
  carTrimEntity: storeState.carTrim.entity,
  loading: storeState.carTrim.loading,
  updating: storeState.carTrim.updating,
  updateSuccess: storeState.carTrim.updateSuccess
});

const mapDispatchToProps = {
  getCarSeries,
  getCarTypes,
  getCarModels,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarTrimUpdate);
