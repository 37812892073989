import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntities as getUnderwriterProducts } from 'app/entities/underwriter-product/underwriter-product.reducer';
import { getEntities as getAgeRanges } from 'app/entities/age-range/age-range.reducer';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntities as getCoverAreas } from 'app/entities/cover-area/cover-area.reducer';
import { getEntities as getTonnageRanges } from 'app/entities/tonnage-range/tonnage-range.reducer';
import { getEntity, updateEntity, createEntity, reset } from './binder-rate.reducer';
// tslint:disable-next-line:no-unused-variable
import { size } from 'lodash';

export interface IBinderRateUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IBinderRateUpdateState {
  isNew: boolean;
  underwriterId: string;
  riskCategoryId: string;
  riskClassId: string;
  coverTypeId: string;
  tonnageRangeId: string;
  ageRangeId: string;
  coverAreaId: string;
  underwriterProductId: string;
  productTypeId: string;
  multiple: boolean;
  spouseValue: boolean;
}

export class BinderRateUpdate extends React.Component<IBinderRateUpdateProps, IBinderRateUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      underwriterId: '0',
      riskCategoryId: '0',
      riskClassId: '0',
      coverTypeId: '0',
      tonnageRangeId: '0',
      ageRangeId: '0',
      coverAreaId: '0',
      multiple: false,
      spouseValue: false,
      underwriterProductId: '0',
      productTypeId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    const { productTypes, companyId } = this.props.account;
    if (this.state.isNew) {
      this.props.reset();
    } else {
      Promise.resolve(this.props.getEntity(this.props.match.params.id)).then(() => {
        // @ts-ignore
        const { underwriterId, isMultiple, productTypeId, riskCategoryId } = this.props.binderRateEntity;
        this.setState({
          productTypeId: String(productTypeId),
          multiple: isMultiple
        });
        Promise.all([
          this.props.getRiskClasses(0, 2000, `,&companyId=${companyId}&riskCategoryId=${riskCategoryId}`),
          this.props.getCoverTypes(0, 2000, `,&companyId=${companyId}&productTypeId=${productTypeId}`),
          this.props.getUnderwriterProducts(0, 2000, `,&companyId=${companyId}&underwriterId=${underwriterId}`)
        ]);
      });
    }
    if (size(productTypes) === 1) {
      const id = String(productTypes[0].id);
      this.setState({
        productTypeId: id
      });
      this.handleProductChange(id);
    }
    const productTypeIds = productTypes.map(a => `productTypeId=${a.id}`);
    const actions = [
      this.props.getProductTypes(0, 20, `,&${productTypes.map(a => `id=${a.id}`).join('&')}&companyId=${companyId}`),
      this.props.getRiskCategories(0, 2000, `,&companyId=${companyId}&${productTypeIds.join('&')}`),
      this.props.getUnderwriters(0, 2000, `,&companyId=${companyId}&${productTypes.map(a => `productTypes_Id=${a.id}`).join('&')}`),
      this.props.getTonnageRanges(),
      this.props.getCoverAreas(),
      this.props.getAgeRanges(0, 2000, `,&companyId=${companyId}`)
    ];
    Promise.all(actions);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { binderRateEntity } = this.props;
      const entity = {
        ...binderRateEntity,
        ...values
      };
      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleMultipleChange = multiple => {
    this.setState({ multiple });
  };
  handleSpouseChange = spouseValue => {
    this.setState({ spouseValue });
  };

  handleRiskClassChange = id => {
    this.setState({ riskClassId: '' + id });
  };

  handleProductChange = id => {
    this.setState({
      productTypeId: String(id)
    });
    const { companyId } = this.props.account;
    Promise.all([
      this.props.getUnderwriters(0, 2000, `,&companyId=${companyId}&productTypes_Id=${id}`),
      this.props.getCoverTypes(0, 2000, `,&companyId=${companyId}&productTypeId=${id}`),
      this.props.getRiskClasses(0, 2000, `,&companyId=${companyId}&productTypeId=${id}`)
    ]);
  };

  handleUnderwriterChange = id => {
    this.props.getUnderwriterProducts(
      0,
      2000,
      `,&companyId=${this.props.account.companyId}&underwriterId=${id}&productTypeId=${this.state.productTypeId}`
    );
  };

  handleClose = () => {
    this.props.history.push('/entity/binder-rate');
  };

  render() {
    const {
      binderRateEntity,
      underwriters,
      riskClasses,
      coverTypes,
      tonnageRanges,
      productTypes,
      coverAreas,
      underwriterProducts,
      loading,
      updating,
      ageRanges
    } = this.props;
    const { isNew, productTypeId, riskClassId, multiple, spouseValue } = this.state;

    return (
      <Card>
        <CardBody>
          <Col sm="12">
            <Card>
              <CardTitle className="bg-light border-bottom p-3 mb-0">
                <i className="mdi mdi-book mr-2" />
                Create or edit a BinderRate
              </CardTitle>
              <CardBody>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <AvForm model={isNew ? {} : binderRateEntity} onSubmit={this.saveEntity}>
                    <Row>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="ageRangeLabel" for="binder-rate-ageRange">
                              Product Type
                            </Label>
                            <Col sm="9">
                              <AvInput
                                id="binder-rate-productType"
                                type="select"
                                className="form-control"
                                onChange={event => this.handleProductChange(event.target.value)}
                                name="productTypeId"
                                required
                              >
                                <option value="" key="0" />
                                {productTypes
                                  ? productTypes.map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.name}
                                      </option>
                                    ))
                                  : null}
                              </AvInput>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" for="binder-rate-riskClass">
                              Risk Class
                            </Label>
                            <Col sm="9">
                              <AvInput
                                id="binder-rate-riskClass"
                                type="select"
                                className="form-control"
                                onChange={event => this.handleRiskClassChange(event.target.value)}
                                name="riskClassId"
                                required
                              >
                                <option value="" key="0" />
                                {riskClasses
                                  ? riskClasses.map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.name}
                                      </option>
                                    ))
                                  : null}
                              </AvInput>
                              <AvFeedback>This field is required.</AvFeedback>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" for="binder-rate-underwriter">
                              Underwriter
                            </Label>
                            <Col sm="9">
                              <AvInput
                                id="binder-rate-underwriter"
                                type="select"
                                className="form-control"
                                onChange={event => this.handleUnderwriterChange(event.target.value)}
                                name="underwriterId"
                                required
                              >
                                <option value="" key="0" />
                                {underwriters
                                  ? underwriters.map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.name}
                                      </option>
                                    ))
                                  : null}
                              </AvInput>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      {productTypeId === '3' && (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" for="binder-rate-underwriter">
                                Product
                              </Label>
                              <Col sm="9">
                                <AvInput
                                  id="binder-rate-underwriterProduct"
                                  type="select"
                                  className="form-control"
                                  name="underwriterProductId"
                                  required={productTypeId === '3'}
                                >
                                  <option value="" key="0" />
                                  {underwriterProducts
                                    ? underwriterProducts.map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {otherEntity.name}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      )}
                      {productTypeId !== '3' && (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="productNameLabel" for="binder-productName">
                                Product Name
                              </Label>
                              <Col sm="9">
                                <AvField id="binder-productName" type="string" className="form-control" name="productName" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      )}
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" for="binder-rate-coverType">
                              Cover Type
                            </Label>
                            <Col sm="9">
                              <AvInput id="binder-rate-coverType" type="select" className="form-control" required name="coverTypeId">
                                <option value="" key="0" />
                                {coverTypes
                                  ? coverTypes.map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.name}
                                      </option>
                                    ))
                                  : null}
                              </AvInput>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="rateLabel" for="binder-rate-rate">
                              Rate
                            </Label>
                            <Col sm="9">
                              <AvField id="binder-rate-rate" type="string" className="form-control" name="rate" />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="minimumValueLabel" for="binder-rate-minimumValue">
                              Minimum Value
                            </Label>
                            <Col sm="9">
                              <AvField id="binder-rate-minimumValue" type="string" className="form-control" required name="minimumValue" />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="statusLabel" for="binder-rate-status">
                              Status
                            </Label>
                            <Col sm="9">
                              <AvInput
                                id="binder-rate-status"
                                type="select"
                                className="form-control"
                                name="status"
                                value={(!isNew && binderRateEntity.status) || 'ACTIVE'}
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </AvInput>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      {productTypeId === '3' && (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="12" id="hasBinderLabel" check>
                                <AvInput
                                  id="binder-rate-hasBinder"
                                  type="checkbox"
                                  className="form-control form-custom-check-input"
                                  name="isMultiple"
                                  onChange={event => this.handleMultipleChange(event.target.checked)}
                                  value={multiple}
                                />
                                Has M+ Values?
                              </Label>
                            </Row>
                          </AvGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="brokerRateTypeLabel" for="binder-rate-brokerRateType">
                              Broker Rate Type
                            </Label>
                            <Col sm="9">
                              <AvField
                                id="binder-rate-brokerRateType"
                                type="select"
                                className="form-control"
                                value={!isNew && binderRateEntity.brokerRateType}
                                required
                                name="brokerRateType"
                              >
                                <option value="">Select</option>
                                <option value="PERCENTAGE">PERCENTAGE</option>
                                <option value="FIXED">FIXED</option>
                              </AvField>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="brokerRateValueLabel" for="binder-rate-brokerRateValue">
                              Broker Rate Value
                            </Label>
                            <Col sm="9">
                              <AvInput
                                id="binder-rate-brokerRateValue"
                                type="number"
                                className="form-control"
                                name="brokerRateValue"
                                required
                                value={!isNew && binderRateEntity.brokerRateValue}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      {productTypeId === '3' && (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              Spouse Value?
                              <Col>
                                <AvInput
                                  id="binder-rate-hasSpouseValue"
                                  type="checkbox"
                                  className="form-control form-custom-check-input"
                                  name="hasSpouseValue"
                                  onChange={event => this.handleSpouseChange(event.target.checked)}
                                  value={(!isNew && binderRateEntity.hasSpouseValue) || spouseValue}
                                />
                              </Col>
                              {((!isNew && binderRateEntity.hasSpouseValue) || spouseValue) && (
                                <Col>
                                  <AvField id="binder-rate-spouseValue" type="string" className="form-control" name="spouseValue" />
                                </Col>
                              )}
                            </Row>
                          </AvGroup>
                        </Col>
                      )}
                    </Row>
                    {productTypeId === '3' && (
                      <>
                        {multiple && (
                          <>
                            <Row>
                              <Col md={4}>
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" id="benefitValueLabel" for="benefit-benefitValue">
                                      M-1 Value
                                    </Label>
                                    <Col sm="8">
                                      <AvField id="benefit-benefitValue" type="number" className="form-control" name="minimumValue1" />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                              <Col md={4}>
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" id="binder-rateValueLabel" for="binder-rate-binder-rateValue">
                                      M-2 Value
                                    </Label>
                                    <Col sm="8">
                                      <AvField
                                        id="binder-rate-binder-rateValue"
                                        type="number"
                                        className="form-control"
                                        name="minimumValue2"
                                      />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                              <Col md="4">
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" id="binder-rateValueLabel" for="binder-rate-binder-rateValue">
                                      M-3 Value
                                    </Label>
                                    <Col sm="8">
                                      <AvField
                                        id="binder-rate-binder-rateValue"
                                        type="number"
                                        className="form-control"
                                        name="minimumValue3"
                                      />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" id="binder-rateValueLabel" for="binder-rate-binder-rateValue">
                                      M-4 Value
                                    </Label>
                                    <Col sm="8">
                                      <AvField
                                        id="binder-rate-binder-rateValue"
                                        type="number"
                                        className="form-control"
                                        name="minimumValue4"
                                      />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                              <Col md={4}>
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" id="binder-rateValueLabel" for="binder-rate-binder-rateValue">
                                      M-5 Value
                                    </Label>
                                    <Col sm="8">
                                      <AvField
                                        id="binder-rate-binder-rateValue"
                                        type="number"
                                        className="form-control"
                                        name="minimumValue5"
                                      />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                              <Col md={4}>
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" id="binder-rateValueLabel" for="binder-rate-binder-rateValue">
                                      M-6 Value
                                    </Label>
                                    <Col sm="8">
                                      <AvField id="binder-rate-minimumValue6" type="number" className="form-control" name="minimumValue6" />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                              <Col md={4}>
                                <AvGroup>
                                  <Row>
                                    <Label sm="4" id="binder-rateValueLabel" for="binder-rate-binder-rateValue">
                                      M Value Other
                                    </Label>
                                    <Col sm="8">
                                      <AvField
                                        id="binder-rate-binder-rateValue"
                                        type="number"
                                        className="form-control"
                                        name="minimumValueOther"
                                      />
                                    </Col>
                                  </Row>
                                </AvGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                    {productTypeId !== '3' && (
                      <Row>
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="minSumInsuredLabel" for="binder-rate-minSumInsured">
                                Minimum Sum Insured
                              </Label>
                              <Col sm="9">
                                <AvField id="binder-rate-minSumInsured" type="string" className="form-control" name="minSumInsured" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="maxSumInsuredLabel" for="binder-rate-maxSumInsured">
                                Maximum Sum Insured
                              </Label>
                              <Col sm="9">
                                <AvField id="binder-rate-maxSumInsured" type="string" className="form-control" name="maxSumInsured" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" for="binder-rate-tonnageRange">
                                Tonnage Range
                              </Label>
                              <Col sm="9">
                                <AvInput id="binder-rate-tonnageRange" type="select" className="form-control" name="tonnageRangeId">
                                  <option value="" key="0" />
                                  {tonnageRanges
                                    ? tonnageRanges.map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {`${otherEntity.minValue} - ${otherEntity.maxValue} ${otherEntity.unit}`}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="startActiveDateLabel" for="binder-rate-startActiveDate">
                              Start Date
                            </Label>
                            <Col sm="9">
                              <AvField id="binder-rate-startActiveDate" type="date" className="form-control" name="startActiveDate" />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="3" id="endActiveDateLabel" for="binder-rate-endActiveDate">
                              End Date
                            </Label>
                            <Col sm="9">
                              <AvField id="binder-rate-endActiveDate" type="date" className="form-control" name="endActiveDate" />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Row>
                            <Label sm="12" id="hasBinderLabel" check>
                              <AvInput
                                id="binder-rate-hasBinder"
                                type="checkbox"
                                className="form-control form-custom-check-input"
                                name="hasBinder"
                              />
                              Has Binder
                            </Label>
                          </Row>
                        </AvGroup>
                      </Col>
                    </Row>
                    {productTypeId === '3' && (
                      <Row>
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm={3} id="discountRateLabel" for="binder-rate-discountRate">
                                Spouse Discount Rate
                              </Label>

                              <Col sm={9}>
                                <AvField id="binder-rate-discountRate" type="string" className="form-control" name="discountRate" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="childMinimumValueLabel" for="binder-rate-childMinimumValue">
                                Child Minimum Value
                              </Label>
                              <Col sm="9">
                                <AvField
                                  id="binder-rate-childMinimumValue"
                                  type="string"
                                  className="form-control"
                                  name="childMinimumValue"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="ageRangeLabel" for="binder-rate-ageRange">
                                Age Range
                              </Label>
                              <Col sm="9">
                                <AvInput
                                  id="binder-rate-ageRange"
                                  type="select"
                                  className="form-control"
                                  required={productTypeId === '3'}
                                  name="ageRangeId"
                                >
                                  <option value="" key="0" />
                                  {ageRanges
                                    ? ageRanges.map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {`${otherEntity.minValue} - ${otherEntity.maxValue} ${otherEntity.unit}`}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                    )}
                    {productTypeId === '3' && (
                      <Row>
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="coverTypeCategoryLabel" for="binder-rate-coverTypeCategory">
                                Cover Type Category
                              </Label>
                              <Col sm="9">
                                <AvInput
                                  id="binder-rate-coverTypeCategory"
                                  type="select"
                                  className="form-control"
                                  name="coverTypeCategory"
                                  value={(!isNew && binderRateEntity.coverTypeCategory) || ''}
                                  required={productTypeId === '3'}
                                >
                                  <option value="" key="0" />
                                  <option value="PER_FAMILY">PER_FAMILY</option>
                                  <option value="PER_PERSON">PER_PERSON</option>
                                </AvInput>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>

                        {riskClassId === '75' && (
                          <Col md="4">
                            <AvGroup>
                              <Row>
                                <Label sm="3" for="productTypes">
                                  Cover Area
                                </Label>
                                <Col sm="9">
                                  <AvInput type="select" className="form-control" name="coverAreaId">
                                    <option value="" key="0">
                                      Select Cover Area
                                    </option>
                                    {coverAreas &&
                                      coverAreas.map(p => (
                                        <option value={p.id} key={p.id}>
                                          {p.name}
                                        </option>
                                      ))}
                                  </AvInput>
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        )}
                      </Row>
                    )}
                    <FormGroup className="mt-5">
                      <Row>
                        <Col sm="6" className="text-center">
                          <Button tag={Link} id="cancel-save" to="/entity/binder-rate" replace color="info">
                            <FontAwesomeIcon icon="arrow-left" />
                            &nbsp;
                            <span className="d-none d-md-inline">Back</span>
                          </Button>
                          &nbsp; &nbsp;
                          <Button type="submit" className="btn btn-success" disabled={updating}>
                            <i className="fa fa-check" /> Save
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </AvForm>
                )}
              </CardBody>
            </Card>
          </Col>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  productTypes: storeState.productType.entities,
  underwriters: storeState.underwriter.entities,
  underwriterProducts: storeState.underwriterProduct.entities,
  riskCategories: storeState.riskCategory.entities,
  riskClasses: storeState.riskClass.entities,
  coverTypes: storeState.coverType.entities,
  binderRateEntity: storeState.binderRate.entity,
  loading: storeState.binderRate.loading,
  updating: storeState.binderRate.updating,
  updateSuccess: storeState.binderRate.updateSuccess,
  tonnageRanges: storeState.tonnageRange.entities,
  ageRanges: storeState.ageRange.entities,
  coverAreas: storeState.coverArea.entities,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getAgeRanges,
  getUnderwriters,
  getProductTypes,
  getUnderwriterProducts,
  getRiskCategories,
  getRiskClasses,
  getCoverTypes,
  getEntity,
  updateEntity,
  createEntity,
  getTonnageRanges,
  getCoverAreas,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BinderRateUpdate);
