import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IBenefitSpanType, defaultValue } from 'app/shared/model/benefit-span-type.model';

export const ACTION_TYPES = {
  FETCH_BENEFITSPANTYPE_LIST: 'benefitSpanType/FETCH_BENEFITSPANTYPE_LIST',
  FETCH_BENEFITSPANTYPE: 'benefitSpanType/FETCH_BENEFITSPANTYPE',
  CREATE_BENEFITSPANTYPE: 'benefitSpanType/CREATE_BENEFITSPANTYPE',
  UPDATE_BENEFITSPANTYPE: 'benefitSpanType/UPDATE_BENEFITSPANTYPE',
  DELETE_BENEFITSPANTYPE: 'benefitSpanType/DELETE_BENEFITSPANTYPE',
  RESET: 'benefitSpanType/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBenefitSpanType>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type BenefitSpanTypeState = Readonly<typeof initialState>;

// Reducer

export default (state: BenefitSpanTypeState = initialState, action): BenefitSpanTypeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BENEFITSPANTYPE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BENEFITSPANTYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_BENEFITSPANTYPE):
    case REQUEST(ACTION_TYPES.UPDATE_BENEFITSPANTYPE):
    case REQUEST(ACTION_TYPES.DELETE_BENEFITSPANTYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BENEFITSPANTYPE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BENEFITSPANTYPE):
    case FAILURE(ACTION_TYPES.CREATE_BENEFITSPANTYPE):
    case FAILURE(ACTION_TYPES.UPDATE_BENEFITSPANTYPE):
    case FAILURE(ACTION_TYPES.DELETE_BENEFITSPANTYPE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_BENEFITSPANTYPE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_BENEFITSPANTYPE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_BENEFITSPANTYPE):
    case SUCCESS(ACTION_TYPES.UPDATE_BENEFITSPANTYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_BENEFITSPANTYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/benefit-span-types';

// Actions

export const getEntities: ICrudGetAllAction<IBenefitSpanType> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_BENEFITSPANTYPE_LIST,
    payload: axios.get<IBenefitSpanType>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IBenefitSpanType> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_BENEFITSPANTYPE,
    payload: axios.get<IBenefitSpanType>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IBenefitSpanType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BENEFITSPANTYPE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IBenefitSpanType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BENEFITSPANTYPE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IBenefitSpanType> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BENEFITSPANTYPE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
