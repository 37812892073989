import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IInvoice, defaultValue, cartState } from 'app/shared/model/invoice.model';
import { defaultValue as clientDefaultValue } from 'app/shared/model/client.model';

export const ACTION_TYPES = {
  FETCH_INVOICE_LIST: 'invoice/FETCH_INVOICE_LIST',
  FETCH_INVOICE: 'invoice/FETCH_INVOICE',
  CREATE_INVOICE: 'invoice/CREATE_INVOICE',
  UPDATE_INVOICE: 'invoice/UPDATE_INVOICE',
  DELETE_INVOICE: 'invoice/DELETE_INVOICE',
  OPEN_INVOICE_MODAL: 'invoice/OPEN_INVOICE_MODAL',
  CLOSE_INVOICE_MODAL: 'invoice/CLOSE_INVOICE_MODAL',
  UNSELECT_POLICY_ITEM: 'invoice/UNSELECT_POLICY_ITEM',
  SELECT_POLICY_ITEM: 'invoice/SELECT_POLICY_ITEM',
  UNSELECT_INVOICE_CLIENT: 'invoice/UNSELECT_INVOICE_CLIENT',
  SELECT_INVOICE_CLIENT: 'invoice/SELECT_INVOICE_CLIENT',
  RESET: 'invoice/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvoice>,
  entity: defaultValue,
  updating: false,
  modalOpen: false,
  totalItems: 0,
  updateSuccess: false,
  cart: { ...cartState, totalItems: 0, client: clientDefaultValue, clientPolicies: [] }
};

export type InvoiceState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceState = initialState, action): InvoiceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INVOICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_INVOICE):
    case REQUEST(ACTION_TYPES.UPDATE_INVOICE):
    case REQUEST(ACTION_TYPES.DELETE_INVOICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INVOICE):
    case FAILURE(ACTION_TYPES.CREATE_INVOICE):
    case FAILURE(ACTION_TYPES.UPDATE_INVOICE):
    case FAILURE(ACTION_TYPES.DELETE_INVOICE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_INVOICE):
    case SUCCESS(ACTION_TYPES.UPDATE_INVOICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_INVOICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SELECT_POLICY_ITEM:
      const item = action.payload;
      const policy = state.cart.clientPolicies.find(x => x.id === item.id);
      if (policy) {
        return {
          ...state,
          loading: false,
          totalItems: state.entities.length,
          updateSuccess: true,
          updating: false,
          cart: {
            ...state.cart,
            cartTotal: state.cart.clientPolicies.length,
            clientPolicies: state.cart.clientPolicies.map(x => (x.id === policy.id ? policy : x))
          }
        };
      }
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        cart: {
          ...state.cart,
          clientPolicies: [...state.cart.clientPolicies, item],
          cartTotal: state.cart.clientPolicies.length + 1
        }
      };
    case ACTION_TYPES.UNSELECT_POLICY_ITEM:
      return {
        ...state,
        cart: {
          ...state.cart,
          cartTotal: state.cart.clientPolicies.length - 1,
          clientPolicies: state.cart.clientPolicies.filter(x => x.id !== action.payload)
        }
      };
    case ACTION_TYPES.SELECT_INVOICE_CLIENT:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        cart: {
          ...state.cart,
          client: action.payload
        }
      };
    case ACTION_TYPES.UNSELECT_INVOICE_CLIENT:
      return {
        ...state,
        cart: {
          ...state.cart,
          client: {}
        }
      };
    case ACTION_TYPES.CLOSE_INVOICE_MODAL:
      return {
        ...state,
        modalOpen: action.payload
      };
    case ACTION_TYPES.OPEN_INVOICE_MODAL:
      return {
        ...state,
        modalOpen: action.payload
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoices';

// Actions

export const getEntities: ICrudGetAllAction<IInvoice> = (page = 0, size = 10, sort = ' ,') => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICE_LIST,
    payload: axios.get<IInvoice>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IInvoice> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICE,
    payload: axios.get<IInvoice>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IInvoice> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IInvoice> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INVOICE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IInvoice> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INVOICE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const openModal = value => async dispatch =>
  dispatch({
    type: ACTION_TYPES.OPEN_INVOICE_MODAL,
    payload: value
  });

export const selectClientItem = client => async dispatch =>
  dispatch({
    type: ACTION_TYPES.SELECT_INVOICE_CLIENT,
    payload: client
  });

export const unSelectClientItem = id => async dispatch =>
  dispatch({
    type: ACTION_TYPES.UNSELECT_INVOICE_CLIENT,
    payload: id
  });

export const selectPolicyItem = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SELECT_POLICY_ITEM,
    payload: entity
  });
  return result;
};

export const unSelectPolicyItem = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UNSELECT_POLICY_ITEM,
    payload: id
  });
  return result;
};

export const closeModal = value => async dispatch =>
  dispatch({
    type: ACTION_TYPES.CLOSE_INVOICE_MODAL,
    payload: value
  });

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
