import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { getEntity as getQuote } from 'app/entities/quote/quote.reducer';
import { getEntity } from 'app/entities/client-policy/client-policy.reducer';
import { createEntity } from 'app/entities/client/client.reducer';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { getEntities as getClientPolicyPayments } from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import { getEntity as getRiskClass } from 'app/entities/risk-class/risk-class.reducer';

export interface IQuoteProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  jumpToStep;
}

export interface IClientPolicyState {
  country: string;
  mobile: string;
  categoryId: number;
  underwriterId: string;
  clientId: string;
  riskClassId: bigint;
  coverTypeId: bigint;
  newClient: boolean;
}

class ClientPolicyStep extends React.Component<IQuoteProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      country: null,
      mobile: null,
      categoryId: null,
      underwriterId: '0',
      clientId: '0',
      riskClassId: null,
      coverTypeId: null,
      newClient: false
    };
    this.handleBack = this.handleBack.bind(this);
  }

  handleSubmit = (event, errors, values) => {
    const { vehicleInfo, quote, client, clientPolicy } = this.props;
    // if (errors.length === 0) {
    //   const entity = {
    //     ...values,
    //     companyId: this.props.account.companyId,
    //     mobile: this.state.mobile || this.props.quote.mobile,
    //     telephone: this.state.telephone,
    //     country: this.state.country,
    //     partner: this.props.partners.find(value => value.id === Number(values.partner))
    //   };
    //   if (this.props.account.partner) {
    //     entity.partner = this.props.account.partner;
    //   }
    //   this.props.createEntity(entity);
    // }
    this.props.getRiskClass(quote.riskClassId);
    this.props.jumpToStep(4);
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(4);
    }
  }

  componentDidMount() {
    this.props.getIdTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(2);
  }

  render() {
    const { vehicleMake, vehicleModel, clientPolicyPayments, client, kyc, clientPolicy, quote, idTypes, updating } = this.props;

    const totalClientPolicyPayment =
      clientPolicyPayments && clientPolicyPayments.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
    return (
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          Client Policy Summary
        </CardTitle>
        <CardBody className="pr-0 pl-0">
          <Row className="pl-5 pr-5">
            <Col xs={6} md={4}>
              <h3>Personal Details</h3>
              <hr />
              <Row className="p-3">
                <Col sm={3}>Full Name</Col>
                <Col className="font-18">{`${client.firstName || ''}  ${client.middleName || ''} ${client.lastName || ''}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Email</Col>
                <Col className="font-18">{`${client.email}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Mobile Number</Col>
                <Col className="font-18">{`${client.mobile}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Id Type</Col>
                <Col className="font-18">{`${idTypes.find(id => id.id === client.idTypeId).name}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>ID Number</Col>
                <Col className="font-18">{`${client.idNumber}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Pin Number</Col>
                <Col className="font-18">{`${client.pinNumber}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Client Category</Col>
                <Col className="font-18">{`${client.categoryName}`}</Col>
              </Row>
            </Col>
            <Col xs={6} md={4}>
              <h3>Policy Details</h3>
              <hr />
              <Row className="p-3">
                <Col sm={3}>Risk Class</Col>
                <Col className="font-18">{`${quote.riskClassName}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Cover Type</Col>
                <Col className="font-18">{`${quote.coverTypeName}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Premium Payable</Col>
                <Col className="font-18">{`${quote.premiumPayable}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Cover Start Date</Col>
                <Col className="font-18">{`${quote.date}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Rate</Col>
                <Col className="font-18">{`${quote.rate}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Vehicle Registration</Col>
                <Col className="font-18">{`${quote.registration}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Underwriter</Col>
                <Col className="font-18">{`${quote.underwriterName}`}</Col>
              </Row>
            </Col>
            <Col xs={6} md={4}>
              <h3>Vehicle Details</h3>
              <hr />
              <Row className="p-3">
                <Col sm={3}>Car Make</Col>
                <Col className="font-18">{`${vehicleMake.name}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Model</Col>
                <Col className="font-18">{`${vehicleModel.name}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Year Of Manufacture</Col>
                <Col className="font-18">{`${kyc.year}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Chassis Number</Col>
                <Col className="font-18">{`${kyc.chasisNumber}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Engine Number</Col>
                <Col className="font-18">{`${kyc.engineNumber}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Body Type</Col>
                <Col className="font-18">{`${kyc.carBodyType}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Color</Col>
                <Col className="font-18">{`${kyc.color}`}</Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className="justify-content-md-center">
            <Col md="4">
              <h3> Payment Details</h3>
              <hr />
              <Row className="p-3">
                <Col sm={3}>Total Amount Paid</Col>
                <Col className="font-18">{`${totalClientPolicyPayment}`}</Col>
              </Row>
              <Row className="p-3">
                <Col sm={3}>Payment Status</Col>
                <Col className="font-18">{`${clientPolicy.paymentStatus}`}</Col>
              </Row>
            </Col>
          </Row>
          <Row className="pl-3 pr-5 mt-4 mb-5">
            <Col md="12 text-right">
              <Button id="cancel-save" onClick={this.handleBack} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp; &nbsp;
              <Button type="submit" className="btn btn-success" onClick={this.handleSubmit} disabled={updating}>
                <i className="fa fa-check" /> NEXT
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  vehicleMake: storeState.vehicleAdditionalInfo.make,
  vehicleModel: storeState.vehicleAdditionalInfo.model,
  kyc: storeState.kYC.entity,
  clientPolicy: storeState.clientPolicy.entity,
  clientPolicyPayments: storeState.clientPolicyPayment.entities,
  riskCategories: storeState.riskCategory.entities,
  account: storeState.authentication.account,
  quote: storeState.quote.entity,
  client: storeState.client.entity,
  updateSuccess: storeState.riskClass.updateSuccess,
  clientCategories: storeState.clientCategory.entities,
  idTypes: storeState.idType.entities,
  updating: storeState.riskClass.updating,
  vehicleInfo: storeState.vehicleAdditionalInfo
});

const mapDispatchToProps = {
  getQuote,
  getClientCategories,
  getIdTypes,
  createEntity,
  getEntity,
  getClientPolicyPayments,
  getRiskClass
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPolicyStep);
