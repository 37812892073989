import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IRiskClassFormula } from 'app/shared/model/risk-class-formula.model';
import { getEntities as getRiskClassFormulas } from 'app/entities/risk-class-formula/risk-class-formula.reducer';
import { getEntity, updateEntity, createEntity, reset } from './formula-param.reducer';
import { IFormulaParam } from 'app/shared/model/formula-param.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IFormulaParamUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IFormulaParamUpdateState {
  isNew: boolean;
  riskClassFormulaId: string;
}

export class FormulaParamUpdate extends React.Component<IFormulaParamUpdateProps, IFormulaParamUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      riskClassFormulaId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getRiskClassFormulas();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { formulaParamEntity } = this.props;
      const entity = {
        ...formulaParamEntity,
        ...values,
        companyId: this.props.account.companyId
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/formula-param');
  };

  render() {
    const { formulaParamEntity, riskClassFormulas, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <Card>
        <CardBody>
          <Col md="12">
            <Card>
              <CardTitle className="bg-light border-bottom p-3 mb-0">
                <i className="mdi mdi-book mr-2" />
                Create or edit a FormulaParam
              </CardTitle>
              <CardBody>
                {loading && <p>Loading...</p>}
                {!loading && (
                  <AvForm model={isNew ? {} : formulaParamEntity} onSubmit={this.saveEntity}>
                    <AvGroup>
                      <Row>
                        <Label sm="2" id="nameLabel" for="formula-param-name">
                          Name
                        </Label>
                        <Col sm="10">
                          <AvInput id="formula-param-name" className="form-control" type="text" name="name" />
                        </Col>
                      </Row>
                    </AvGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="2" id="calculationParamLabel" for="formula-param-calculationParam">
                          Calculation Param
                        </Label>
                        <Col sm="10">
                          <AvInput
                            id="formula-param-calculationParam"
                            type="select"
                            className="form-control"
                            name="calculationParam"
                            value={(!isNew && formulaParamEntity.calculationParam) || 'SUM_INSURED'}
                          >
                            <option value="SUM_INSURED">SUM_INSURED</option>
                            <option value="RATE">RATE</option>
                            <option value="NET_PREMIUM">NET_PREMIUM</option>
                            <option value="TL">TL</option>
                            <option value="PCF">PCF</option>
                            <option value="SD">SD</option>
                            <option value="GROSS_PREMIUM">GROSS_PREMIUM</option>
                            <option value="COMMISION_RATE">COMMISION_RATE</option>
                            <option value="USER_INPUT">USER_INPUT</option>
                            <option value="COMMISSION">COMMISSION</option>
                            <option value="FIXED">FIXED</option>
                          </AvInput>
                        </Col>
                      </Row>
                    </AvGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="2" id="valueLabel" for="formula-param-value">
                          Value
                        </Label>
                        <Col sm="10">
                          <AvInput id="formula-param-value" type="string" className="form-control" name="value" />
                        </Col>
                      </Row>
                    </AvGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="2" for="formula-param-riskClassFormula">
                          Risk Class Formula
                        </Label>
                        <Col sm="10">
                          <AvInput id="formula-param-riskClassFormula" type="select" className="form-control" name="riskClassFormulaId">
                            <option value="" key="0" />
                            {riskClassFormulas
                              ? riskClassFormulas.map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.id}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                        </Col>
                      </Row>
                    </AvGroup>
                    <FormGroup>
                      <Row>
                        <Col sm="12" className="text-center">
                          <Button tag={Link} id="cancel-save" to="/entity/formula-param" replace color="info">
                            <FontAwesomeIcon icon="arrow-left" />
                            &nbsp;
                            <span className="d-none d-md-inline">Back</span>
                          </Button>
                          &nbsp; &nbsp;
                          <Button type="submit" className="btn btn-success" disabled={updating}>
                            <i className="fa fa-check" /> Save
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </AvForm>
                )}
              </CardBody>
            </Card>
          </Col>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskClassFormulas: storeState.riskClassFormula.entities,
  formulaParamEntity: storeState.formulaParam.entity,
  loading: storeState.formulaParam.loading,
  updating: storeState.formulaParam.updating,
  updateSuccess: storeState.formulaParam.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getRiskClassFormulas,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulaParamUpdate);
