import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ICoverType } from 'app/shared/model/cover-type.model';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { IUnderwriter } from 'app/shared/model/underwriter.model';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './policy.reducer';
import sortBy from 'lodash/sortBy';
import { getEntity as getPaymentEntity } from 'app/entities/direct-payment/direct-payment.reducer';
import { createEntity as createClientPolicy } from 'app/entities/client-policy/client-policy.reducer';
import { getClientByMobile } from 'app/entities/client/client.reducer';

export interface IPolicyUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IPolicyUpdateState {
  isNew: boolean;
  coverTypeId: string;
  underwriterId: string;
  paymentId: string;
  clientId: string;
}

export class PolicyUpdate extends React.Component<IPolicyUpdateProps, IPolicyUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      coverTypeId: '0',
      underwriterId: '0',
      paymentId: '0',
      clientId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const paymentId = searchParams.get('paymentId');
      this.props.reset();
      if (paymentId) {
        this.setState({ paymentId });
        Promise.resolve(this.props.getPaymentEntity(paymentId)).then(res => {
          const { directPayment, account } = this.props;
          // @ts-ignore
          Promise.resolve(this.props.getClientByMobile(this.props.directPayment.msisdn)).then(clientRes => {
            const { id } = this.props.client;
            if (id) {
              this.setState({ clientId: String(id) });
            }
          });
        });
      }
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
    this.props.getCoverTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getProductTypes(0, 20, `,&companyId=${this.props.account.companyId}`);
    this.props.getUnderwriters(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { policyEntity } = this.props;
      const entity = {
        ...policyEntity,
        ...values,
        companyId: this.props.account.companyId
      };
      const { companyId, id } = this.props.client;
      const clientPolicy = {
        policyDate: values.policyDate,
        startDate: values.startDate,
        endDate: values.endDate,
        premiumPayable: values.premiumPayable,
        openPayable: values.openPayable,
        paymentStatus: values.paymentStatus,
        clientId: id,
        policyId: 1,
        companyId
      };
      const policy = {
        premiumPayable: values.premiumPayable,
        status: values.status,
        coverTypeId: values.coverTypeId,
        underwriterId: values.underwriterId,
        productTypeId: values.productTypeId,
        companyId
      };
      if (this.state.isNew) {
        Promise.resolve(this.props.createEntity(policy)).then(res => {
          clientPolicy['policyId'] = this.props.policyEntity.id;
          this.props.createClientPolicy(clientPolicy);
        });
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    history.back();
  };

  render() {
    const { policyEntity, coverTypes, underwriters, productTypes, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a Policy
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : policyEntity} onSubmit={this.saveEntity}>
                      {!isNew && (
                        <AvGroup>
                          <Row>
                            <Label sm="2" id="nameLabel" for="policy-name">
                              Name
                            </Label>
                            <Col sm="10">
                              <AvField
                                id="policy-name"
                                type="text"
                                name="name"
                                placeholder="Enter name"
                                validate={{
                                  required: { value: true, errorMessage: 'This field is required.' }
                                }}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      )}
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="premiumPayableLabel" for="policy-premiumPayable">
                            Premium Payable
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="policy-premiumPayable"
                              type="string"
                              className="form-control"
                              name="premiumPayable"
                              placeholder="Enter premium payable"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' },
                                number: { value: true, errorMessage: 'This field should be a number.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="statusLabel" for="policy-status">
                            Status
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="policy-status"
                              type="select"
                              className="form-control"
                              name="status"
                              value={(!isNew && policyEntity.status) || 'ACTIVE'}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="INACTIVE">INACTIVE</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="policy-coverType">
                            Cover Type
                          </Label>
                          <Col sm="10">
                            <AvInput id="policy-coverType" type="select" className="form-control" name="coverTypeId" required>
                              {coverTypes
                                ? sortBy(coverTypes, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="policy-underwriter">
                            Underwriter
                          </Label>
                          <Col sm="10">
                            <AvInput id="policy-underwriter" type="select" className="form-control" name="underwriterId" required>
                              {underwriters
                                ? sortBy(underwriters, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="policy-productType">
                            Product Type
                          </Label>
                          <Col sm="10">
                            <AvInput id="policy-productType" type="select" className="form-control" name="productTypeId" required>
                              {productTypes
                                ? sortBy(productTypes, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="policyDateLabel" for="client-policy-policyDate">
                            Policy Date
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="client-policy-policyDate"
                              type="date"
                              className="form-control"
                              name="policyDate"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="startDateLabel" for="client-policy-startDate">
                            Start Date
                          </Label>
                          <Col sm="10">
                            <AvField id="client-policy-startDate" type="date" className="form-control" name="startDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="endDateLabel" for="client-policy-endDate">
                            End Date
                          </Label>
                          <Col>
                            <AvField id="client-policy-endDate" type="date" className="form-control" name="endDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="openPayableLabel" for="client-policy-openPayable">
                            Open Payable
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="client-policy-openPayable"
                              type="string"
                              className="form-control"
                              name="openPayable"
                              placeholder="Enter open payable"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' },
                                number: { value: true, errorMessage: 'This field should be a number.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="paymentStatusLabel" for="client-policy-paymentStatus">
                            Payment Status
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="client-policy-paymentStatus"
                              type="select"
                              className="form-control"
                              name="paymentStatus"
                              value={'UNPAID'}
                            >
                              <option value="UNPAID">UNPAID</option>
                              <option value="PARTIAL">PARTIAL</option>
                              <option value="PAID">PAID</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/policy" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  coverTypes: storeState.coverType.entities,
  underwriters: storeState.underwriter.entities,
  productTypes: storeState.productType.entities,
  policyEntity: storeState.policy.entity,
  loading: storeState.policy.loading,
  client: storeState.client.entity,
  updating: storeState.policy.updating,
  updateSuccess: storeState.policy.updateSuccess,
  directPayment: storeState.paymentCallbacks.entity,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getCoverTypes,
  getUnderwriters,
  getProductTypes,
  getEntity,
  updateEntity,
  createEntity,
  getPaymentEntity,
  getClientByMobile,
  createClientPolicy,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyUpdate);
