import { ApplicationStatus } from 'app/shared/model/enumerations/application-status.model';

export interface IPolicyApplication {
  id?: number;
  clientEmail?: string;
  clientMobile?: string;
  underwriterEmail?: string;
  status?: ApplicationStatus;
  notes?: string;
  clientId?: number;
  underwriterId?: number;
  productTypeId?: number;
  companyId?: number;
  clientPolicyId?: number;
}

export const defaultValue: Readonly<IPolicyApplication> = {};
