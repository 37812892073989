import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function SearchFilter({ toggle, children, open, title }) {
  return (
    <Modal size="lg" style={{ width: '100%' }} isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div>{children}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          SELECT
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default SearchFilter;
