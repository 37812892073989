export interface ICarGeneration {
  id?: number;
  name?: string;
  yearBegin?: number;
  yearEnd?: number;
  carModelId?: number;
  carTypeId?: number;
}

export const defaultValue: Readonly<ICarGeneration> = {};
