import React from 'react';
import PropTypes from 'prop-types';
// tslint:disable-next-line:no-unused-variable
import ReactTable from 'react-table';
import 'react-table/react-table.css';

export interface IDataTableProps {
  loading?: boolean;
  manual?: boolean;
  pages?: number;
  filterable?: boolean;
  onFetchData?: any;
  defaultPageSize?: number;
  showPaginationBottom?: boolean;
  tableData?: any[];
  columns?: any[];
  tableRef?: any;
  styles?: any;
  getTrProps?: any;
  onExpandedChange?: any;
  fireFetchData?: any;
  noDataText?: any;
  SubComponent?: any;
}

class DataTable extends React.Component<IDataTableProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      loading,
      manual,
      pages,
      filterable,
      onFetchData,
      defaultPageSize,
      showPaginationBottom,
      tableData,
      getTrProps,
      styles,
      noDataText,
      SubComponent,
      columns,
      tableRef
    } = this.props;
    const defaultStyles = {
      fontSize: '9px',
      textAlign: 'center'
    };
    return (
      <ReactTable
        ref={tableRef}
        columns={columns}
        manual={manual}
        defaultPageSize={defaultPageSize}
        pages={pages}
        loading={loading}
        onFetchData={onFetchData}
        showPaginationBottom={showPaginationBottom}
        className="-striped -highlight"
        data={tableData}
        style={{ ...styles, ...defaultStyles }}
        noDataText={noDataText}
        getTrProps={getTrProps}
        filterable={filterable}
        SubComponent={SubComponent}
      />
    );
  }
}

export default DataTable;
