import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import Agent from 'app/modules/administration/user-management/index';

const EntityTemplate = props => (
  <MainTemplate>
    <Agent {...props} />
  </MainTemplate>
);

export default EntityTemplate;
