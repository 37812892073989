import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Row, Col, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import { Link } from 'react-router-dom';

export interface ILogoutProps extends StateProps, DispatchProps {
  idToken: string;
  logoutUrl: string;
}

export class RegisterSuccess extends React.Component<ILogoutProps> {
  componentDidMount() {
    // this.props.logout();
  }

  render() {
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <h4>Registration successful!</h4>
                </Col>
                <Col md={3}>
                  <Button color="primary" tag={Link} to="/login" size="lg" type="submit" block>
                    Log In
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  // logoutUrl: storeState.authentication.logoutUrl,
  // idToken: storeState.authentication.idToken
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSuccess);
