import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Button, Row, Col, Label, FormGroup } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import PolicySummary from 'app/entities/quote/policy-summary/PolicySummary';
import UserDetailsSummary from 'app/entities/quote/user-details-summary/UserDetailsSummary';
import { PAYMENT_METHODS as paymentMethods } from 'app/config/constants';
import { createEntity as createClientPolicyPayment } from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import { previewAkiCertificate } from 'app/modules/aki/aki.reducer';
import { getEntities as getUnderWriters } from 'app/entities/underwriter/underwriter.reducer';
import { Platform, TransactionStatus } from 'app/shared/model/client-policy-payment.model';
import { CertificateRequestType, TypeACertificate, TypeBCertificate, TypeOfCover } from 'app/modules/aki/aki-model';
import { AkiDetails } from 'app/entities/quote/aki-details/AkiDetails';

export interface IQuoteProps extends StateProps, DispatchProps {
  jumpToStep;
  quotes;
  cart;
  quote;
  clientPolicyPayment;
  vehicleMakes;
  vehicleModels;
  vehicleTypes;
  coverTypes;
  riskClasses;
  client;
  vehicleInfo;
  account;
  match;
  sendAllQuotes;
  selectQuoteItem;
  unSelectQuoteItem;
  createClientPolicyPayment;
  createClientPolicyPaymentSuccess;
}

class PaymentStep extends React.Component<IQuoteProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      akiUpdateSuccess: false,
      display: false,
      akiErrors: null,
      errorMessage: '',
      fetchSuccess: false,
      mobile: '',
      sumInsured: '',
      balance: this.props.quote.premiumPayable
    };
  }

  componentDidMount() {
    this.setState({ balance: this.props.quote.premiumPayable });
    this.props.getUnderWriters(0, 200, `&companyId=${this.props.account.companyId}`);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.akiUpdateSuccess !== this.props.akiUpdateSuccess && nextProps.akiUpdateSuccess) {
      this.setState({ akiUpdateSuccess: true, display: true });
    }
  }

  getCertificateRequestType(riskClass, action) {
    if (action === 'issue') {
      if (riskClass.classificationCategory === 'COMMERCIAL_PRIMARY') {
        return CertificateRequestType.ISSUE_TYPE_B_CERTIFICATE;
      } else if (riskClass.classificationCategory === 'PUBLIC_SERVICE_VEHICLE') {
        return CertificateRequestType.ISSUE_TYPE_A_CERTIFICATE;
      } else {
        return CertificateRequestType.ISSUE_TYPE_C_CERTIFICATE;
      }
    } else {
      if (riskClass.classificationCategory === 'COMMERCIAL_PRIMARY') {
        return CertificateRequestType.PREVIEW_TYPE_B_CERTIFICATE;
      } else if (riskClass.classificationCategory === 'PUBLIC_SERVICE_VEHICLE') {
        return CertificateRequestType.PREVIEW_TYPE_A_CERTIFICATE;
      } else {
        return CertificateRequestType.PREVIEW_TYPE_C_CERTIFICATE;
      }
    }
  }

  getTypeOfCertificate(riskClass) {
    if (riskClass.classificationCategory === 'COMMERCIAL_PRIMARY') {
      if (riskClass.name === 'Own Goods' || riskClass.name === 'Own Goods Enhanced' || riskClass.name === 'Own Goods - Pickups') {
        return TypeBCertificate.MOTOR_COMMERCIAL_OWN_GOODS;
      } else if (
        riskClass.name === 'Institutional Vehicles' ||
        riskClass.name === 'Driving Schools' ||
        riskClass.name === 'Driving Schools Vehicles - Saloon' ||
        riskClass.name === 'Driving Schools Vehicles - Others' ||
        riskClass.name === 'Driving Schools Vehicles - Heavy Vehicles'
      ) {
        return TypeBCertificate.MOTOR_INSTITUTIONAL_VEHICLE;
      } else if (riskClass.name === 'General Cartage - Tankers') {
        return TypeBCertificate.TANKERS_LIQUID_CARRYING;
      }
      if (
        riskClass.name === 'Agrictultural & Forestry Vehicles' ||
        riskClass.name === 'Ambulance, Hearse, Firefighters' ||
        riskClass.name === 'Contingency Liability' ||
        riskClass.name === 'Construction Vehicles'
      ) {
        return TypeBCertificate.MOTOR_SPECIAL_VEHICLES;
      } else {
        return TypeBCertificate.MOTOR_COMMERCIAL_GENERAL_CARTAGE;
      }
    } else if (riskClass.riskCategory === 'PUBLIC_SERVICE_VEHICLE') {
      if (riskClass.name === 'Chauffeur Driven' || riskClass.name === 'Chauffeur Driven - Taxis') {
        return TypeACertificate.TYPE_A_TAXI;
      } else {
        return TypeACertificate.CLASS_A_PSV_UNMARKED;
      }
    }
  }

  parsePhone(phone) {
    if (phone.length > 9) {
      return phone.substr(phone.length - 9);
    }
  }

  handleClose = () => {
    this.setState({ display: false });
  };

  handleBack = () => {
    const { jumpToStep } = this.props;
    jumpToStep(3);
  };

  getCoverType(coverTypeId) {
    return coverTypeId === 1
      ? TypeOfCover.COMPREHENSIVE
      : coverTypeId === 2
      ? TypeOfCover.THIRD_PARTY
      : TypeOfCover.THIRD_PARTY_THEFT_AMD_FIRE;
  }

  handleSubmit = async (event, errors, values) => {
    const { quote, vehicleMakes, vehicleModels } = this.props;
    const riskClass = this.props.riskClasses.find(value => Number(value.id) === quote.riskClassId);
    const vehicleType = this.props.vehicleTypes.find(value => Number(value.id) === quote.vehicleTypeId);
    const carMake = vehicleMakes.find(v => Number(v.id) === Number(quote.carMakeId));
    const carModel = vehicleModels.find(v => Number(v.id) === Number(quote.carModelId));

    values['companyId'] = this.props.account.companyId;
    if (errors.length === 0) {
      const clientPayment = {
        ...values,
        payDate: new Date(),
        invoiceReceipt: 10000,
        paymentConfirmationNumber: null,
        deviceId: null,
        ipAddress: quote.userIp,
        transactionStatus: TransactionStatus.SUCCESS,
        platform: Platform.WEB,
        isIPF: null,
        quoteId: quote.id,
        clientPolicyId: null,
        clientPolicyName: null,
        clientName: `${quote.firstName} ${quote.lastName}`
      };
      const result = Promise.resolve(this.props.createClientPolicyPayment(clientPayment));
      result.then(() => {
        this.previewAKICert(quote, riskClass, vehicleType, carMake, carModel);
      });
      result.catch(() => {});
    }
  };

  previewAKICert(quote, riskClass, vehicleType, carMake, carModel) {
    // Preview Certificate
    const { vehicleInfo, underwriters } = this.props;
    const memberCompany = underwriters.find(value => Number(value.id) === Number(quote.underwriterId));
    if (memberCompany && memberCompany.akiCompanyId > 0) {
      const akiPreviewEntity = {
        memberCompanyId: memberCompany.akiCompanyId,
        typeOfCertificate: this.getTypeOfCertificate(riskClass),
        certificateRequestType: this.getCertificateRequestType(riskClass, 'preview'),
        typeOfCover: this.getCoverType(quote.coverTypeId),
        intermediaryIRANumber: null,
        policyHolder: `${quote.firstName} ${quote.lastName}`,
        policyNumber: this.props.clientPolicyPayment.clientPolicyId,
        commencingDate: quote.date,
        expiringDate: quote.expiryDate,
        registrationNumber: quote.registration,
        chassisNumber: vehicleInfo.chassisNumber,
        phoneNumber: this.parsePhone(quote.mobile),
        bodyType: vehicleInfo.bodyType,
        vehicleMake: carMake.name,
        vehicleModel: carModel.name,
        engineNumber: vehicleInfo.engineNumber,
        email: quote.email,
        sumInsured: quote.sumInsured,
        insuredPIN: this.props.client.pinNumber,
        yearOfManufacture: quote.yearOfManufacture,
        hudumaNumber: null,
        lincensedToCarry: null, // (When Type D PSV Motorcycle)
        tonnage: 4
      };
      this.props.previewAkiCertificate(akiPreviewEntity, akiPreviewEntity.certificateRequestType);
    } else {
      this.setState({ akiErrors: `Company ${memberCompany.name} not recognized On AKI` });
    }
  }

  handleAmount = e => {
    this.setState({ balance: this.props.quote.premiumPayable - e.target.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { akiErrors, display, akiUpdateSuccess } = this.state;
    const { quote, client, vehicleTypes, vehicleMakes, vehicleModels, akiResponse, loading } = this.props;
    const carMake = vehicleMakes.find(v => Number(v.id) === Number(quote.carMakeId));
    const carModel = vehicleModels.find(v => Number(v.id) === Number(quote.carModelId));
    const vehicleType = vehicleTypes.find(v => Number(v.id) === Number(quote.vehicleTypeId));

    return (
      <div className="step step5 mt-5">
        {(akiUpdateSuccess || akiErrors) && display ? (
          <AkiDetails akiErrors={akiErrors} handleClose={this.handleClose} akiResponse={akiResponse ? akiResponse : null} />
        ) : (
          <Row className="justify-content-md-center">
            <Col lg={9}>
              <Row className="mb-5">
                <Col className="text-center">
                  <UserDetailsSummary client={client} quote={quote} carMake={carMake} carModel={carModel} />
                </Col>
                <Col className="text-center">
                  <PolicySummary quote={quote} vehicleType={vehicleType} />
                </Col>
              </Row>
              <AvForm className="mb-5" onSubmit={this.handleSubmit}>
                <Row>
                  <Col className="text-center">
                    <AvGroup>
                      <Row>
                        <Label sm="2" for="vehicle-type">
                          Payment Method
                        </Label>
                        <Col sm="4">
                          <AvInput id="payment-method" type="select" name="paymentMethod" onChange={this.handleChange} required>
                            <option>Select a Payment Method</option>
                            {paymentMethods
                              ? paymentMethods.map(otherEntity => (
                                  <option value={otherEntity} key={otherEntity}>
                                    {otherEntity}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="mt-40">
                  <Col>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="amountPaid">
                          Amount Paid
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="amountPaid"
                            type="number"
                            className="form-control"
                            name="amount"
                            onChange={this.handleAmount}
                            required
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="balance">
                          Balance
                        </Label>
                        <Col sm="8">
                          <AvField id="balance" value={this.state.balance} type="text" className="form-control" name="balance" disabled />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <FormGroup className="mt-4">
                  <Row>
                    <Col sm="6">
                      <Button type="button" color="info" onClick={this.handleBack}>
                        <span className="d-none d-md-inline">Back</span>
                      </Button>
                    </Col>
                    <Col sm="6" className="text-right">
                      <Button type="submit" className="btn btn-success" disabled={loading}>
                        SUBMIT
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </AvForm>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clientPolicyPayment: storeState.clientPolicyPayment.entity,
  riskClasses: storeState.riskClass.entities,
  vehicleMakes: storeState.carMake.entities,
  vehicleModels: storeState.carModel.entities,
  coverTypes: storeState.coverType.entities,
  vehicleTypes: storeState.vehicleType.entities,
  account: storeState.authentication.account,
  errorMessage: storeState.client.errorMessage,
  loading: storeState.akiCertificateState.loading || storeState.clientPolicyPayment.loading,
  quote: storeState.quote.entity,
  quotes: storeState.quote.entities,
  akiResponse: storeState.akiCertificateState.response,
  underwriters: storeState.underwriter.entities,
  vehicleInfo: storeState.vehicleAdditionalInfo,
  client: storeState.client.entity,
  tonnageRanges: storeState.tonnageRange.entities,
  fetchSuccess: storeState.client.fetchSuccess,
  akiUpdateSuccess: storeState.akiCertificateState.updateSuccess,
  createClientPolicyPaymentSuccess: storeState.clientPolicyPayment.updateSuccess
});

const mapDispatchToProps = {
  createClientPolicyPayment,
  previewAkiCertificate,
  getUnderWriters
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentStep);
