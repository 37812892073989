import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, CustomInput, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getAdditionalBenefits, getEntities as getBenefits } from 'app/entities/benefit/benefit.reducer';
import { getEntity, updateEntity, createEntity, reset } from '../quote.reducer';
import { mapIdList } from 'app/shared/util/entity-utils';
import { getEntities as getCarMakes } from 'app/entities/car-make/car-make.reducer';
import { getEntity as getQuote } from 'app/entities/quote/quote.reducer';
import { getEntityByEmail as getClient, getEntities as getClients, getEntityByEmail } from 'app/entities/client/client.reducer';
import { getEntityModels as getCarModels } from 'app/entities/car-model/car-model.reducer';
import {
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_STRING_DATE_FORMAT,
  AUTHORITIES,
  CarBodyTypes,
  PAYMENT_METHODS as paymentMethods
} from 'app/config/constants';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import 'react-phone-number-input/style.css';
import { TextFormat } from 'react-jhipster';
import PaymentDetails from 'app/entities/quote/quote-update/payment-details';
import ClientDetails from 'app/entities/quote/quote-update/client-details';
import PolicySummary from 'app/entities/quote/quote-update/policy-summary';

export interface IQuoteUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IQuoteUpdateState {
  isNew: boolean;
  idsadditionalBenefits: any[];
  underwriterId: string;
  clientId: string;
  riskClassId: bigint;
  coverTypeId: bigint;
  newClient: boolean;
  loadingClient: boolean;
  calculatePremium: number;
}

export class QuoteUpdate extends React.Component<IQuoteUpdateProps, any> {
  private componentRef = React.createRef<HTMLDivElement>();
  private readonly reactTable: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      idsadditionalBenefits: [],
      underwriterId: '0',
      clientId: '0',
      riskClassId: null,
      coverTypeId: null,
      isNew: !this.props.match.params || !this.props.match.params.id,
      carBodyTypes: CarBodyTypes,
      newClient: false,
      loadingClient: true,
      calculatePremium: 0
    };
  }

  // componentWillUpdate(nextProps, nextState) {
  //   if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
  //     this.handleClose();
  //   }
  // }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getQuote(this.props.match.params.id);
      const quoteResult = Promise.resolve(this.props.getQuote(this.props.match.params.id));
      quoteResult.then(res => {
        this.props.quote.carMakeId && this.props.getCarModels(this.props.quote.carMakeId);
        const result = Promise.resolve(this.props.quote.email && this.props.getClient(this.props.quote.email));
        result.then(resp => {
          this.setState({
            loadingClient: false
          });
          this.setState({ errorMessage: '' });
        });
        result.catch(err => {
          this.setState({
            loadingClient: false,
            newClient: true
          });
        });
      });
    }
    this.props.getClientCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getUnderwriters(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getRiskClasses(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getCoverTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getBenefits(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  onCountry = country => {
    this.setState({ country });
  };
  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handleCategoryChange = categoryId => {
    this.setState({ categoryId: categoryId.target.value });
  };

  saveClient = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...values,
        companyId: this.props.account.companyId,
        mobile: this.state.mobile,
        telephone: this.state.telephone,
        country: this.state.country
      };
      if (this.props.account.partner) {
        entity.partner = this.props.account.partner;
      }
      // this.props.createEntity(entity);
    }
  };

  // getMaxPage() {
  //   const { totalCalls } = this.props;
  //   const { itemsPerPage } = this.state;
  //   const division = Math.floor(totalCalls / itemsPerPage);
  //   const modulo = totalCalls % itemsPerPage;
  //   return division + (modulo !== 0 ? 1 : 0);
  // }

  getCurrentLogo = quote => {
    const { logoFile } = quote;
    if (logoFile && logoFile.content) {
      return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
    }
    return null;
  };

  handleDateChange = date => {
    this.setState({ actionDate: date });
  };

  saveQuote = (event, errors, values) => {
    if (errors.length === 0) {
      const { quote } = this.props;
      const entity = {
        ...quote,
        ...values,
        additionalBenefits: values.additionalBenefits && mapIdList(values.additionalBenefits)
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  async getCarModel(e) {
    if (!(event && event.target)) {
      return;
    }
    const make = await this.props.vehicleMakes.find(item => Number(item.id) === Number(e.target.value));
    this.setState({ carMakeId: make.id, carMake: make.name });
    this.props.getCarModels(make.id);
  }

  handleSubmit = async (event, errors, values) => {
    // const cart = this.props.quote.cart[0];
    // const client = this.props.client;
    // const quoteRef = this.props.quote.entity;
    // values['companyId'] = this.props.account.companyId;
    // if (errors.length === 0) {
    //   const kyc = {
    //     ...values,
    //     make: this.state.carMake,
    //     model: this.props.vehicleModels.find(v => Number(v.id) === Number(values.model)).name,
    //     mobile: this.props.quote.entity.mobile,
    //     registration: this.props.quote.entity.registration,
    //     valueOfCar: this.props.quote.entity.sumInsured,
    //     riskClassId: this.props.quote.entity.riskClassId,
    //     clientId: this.props.client.id,
    //     attachments: this.state.attachments,
    //     generalKYCType: 'OTHER',
    //     motorKYCType: 'OTHER',
    //     customKYCType: '',
    //     expiryDate: this.props.quote.entity.expiryDate
    //   };
    //   const vehicleInfo = {
    //     bodyType: values.carBodyType,
    //     chassisNumber: values.chassisNumber,
    //     engineNumber: values.engineNumber
    //   };
    //   this.props.addVehicleAdditionalInfo(vehicleInfo);
    //   const quote = {
    //     ...values,
    //     ...cart,
    //     clientName: `${client.firstName} ${client.lastName}`,
    //     quoteSource: 'IBS Portal',
    //     riskClassName: quoteRef.riskClassName,
    //     coverTypeName: quoteRef.coverTypeName,
    //     carMakeId: values.make,
    //     clientId: this.props.client.id,
    //     carModelId: values.model,
    //     yearOfManufacture: values.year
    //   };
    //   await this.props.createEntity(kyc);
    //   await this.props.updateQuote(quote);
    // }
  };

  handleClose = () => {
    this.props.history.push('/entity/quote');
  };

  render() {
    const { calculatePremium, isNew } = this.state;
    const { quote } = this.props;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a Quote
                </CardTitle>
                <CardBody>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="5">
                          <div className="d-flex align-items-center">
                            <div className="media align-items-center">
                              {this.getCurrentLogo(quote) && (
                                <img
                                  src={this.getCurrentLogo(quote)}
                                  alt="Generic placeholder image"
                                  className="img-fluid mr-2 media-object"
                                />
                              )}
                            </div>
                            <div className="d-flex align-items-center flex-fill">
                              <div>
                                <h4 className="font-24 font-light mb-0">Monthly Premium</h4>
                                <h1 className="text-primary font-bold mb-0">{`Ksh. ${String(Math.round(calculatePremium)).replace(
                                  /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                                  '$1,'
                                )}`}</h1>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="d-flex align-items-center content-benefit">
                            <div className="d-flex align-items-center flex-fill justify-content-between">
                              <div className="flex-fill pr-5">
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Risk Class:</h4>
                                  <h4 className="font-bold mb-0">{quote.riskClassName}</h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Cover Type:</h4>
                                  <h4 className="font-bold mb-0">{quote.coverTypeName}</h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Sum Insured:</h4>
                                  <h4 className="font-bold mb-0">
                                    {`Ksh. ${String(quote.sumInsured).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-fill justify-content-between">
                              <div className="flex-fill pr-2">
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Cover Start Date:</h4>
                                  <h4 className="font-bold mb-0">
                                    <TextFormat type="date" value={`${quote.createdAt}`} format={APP_LOCAL_STRING_DATE_FORMAT} />
                                  </h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Cover EndDate:</h4>
                                  <h4 className="font-bold mb-0">
                                    <TextFormat type="date" value={`${quote.expiryDate}`} format={APP_LOCAL_STRING_DATE_FORMAT} />
                                  </h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4 className="font-light mb-0">Quote Date:</h4>
                                  <h4 className="font-bold mb-0">
                                    <TextFormat type="date" value={`${quote.createdAt}`} format={APP_LOCAL_STRING_DATE_FORMAT} />
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <PolicySummary />
                  <ClientDetails isNew={isNew} />
                  {/*<KYCDetails/>*/}
                  <PaymentDetails />

                  <FormGroup>
                    <Row>
                      <Col sm="12" className="text-center">
                        <Button tag={Link} id="cancel-save" to="/entity/quote" replace color="info">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <span className="d-none d-md-inline">Back</span>
                        </Button>
                        &nbsp; &nbsp;
                        {/*<Button type="submit" className="btn btn-success" disabled={ updating }>*/}
                        {/*  <i className="fa fa-check"/> Save*/}
                        {/*</Button>*/}
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  underwriters: storeState.underwriter.entities,
  riskClasses: storeState.riskClass.entities,
  coverTypes: storeState.coverType.entities,
  vehicleMakes: storeState.carMake.entities,
  vehicleModels: storeState.carModel.entities,
  quote: storeState.quote.entity,
  loading: storeState.quote.loading,
  updating: storeState.quote.updating,
  updateSuccess: storeState.quote.updateSuccess,
  account: storeState.authentication.account,
  clientCategories: storeState.clientCategory.entities,
  idTypes: storeState.idType.entities
});

const mapDispatchToProps = {
  getClientCategories,
  getUnderwriters,
  getClient,
  getRiskClasses,
  getCoverTypes,
  getCarMakes,
  getCarModels,
  getBenefits,
  getQuote,
  getIdTypes,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteUpdate);
