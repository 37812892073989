import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudDeleteAction } from 'app/shared/reducers/redux-action.type';
import { IRiskClassFormula, defaultValue } from 'app/shared/model/risk-class-formula.model';

export const ACTION_TYPES = {
  FETCH_RISKCLASSFORMULA_LIST: 'riskClassFormula/FETCH_RISKCLASSFORMULA_LIST',
  FETCH_RISKCLASSFORMULA: 'riskClassFormula/FETCH_RISKCLASSFORMULA',
  CREATE_RISKCLASSFORMULA: 'riskClassFormula/CREATE_RISKCLASSFORMULA',
  UPDATE_RISKCLASSFORMULA: 'riskClassFormula/UPDATE_RISKCLASSFORMULA',
  DELETE_RISKCLASSFORMULA: 'riskClassFormula/DELETE_RISKCLASSFORMULA',
  RESET: 'riskClassFormula/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRiskClassFormula>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type RiskClassFormulaState = Readonly<typeof initialState>;

// Reducer

export default (state: RiskClassFormulaState = initialState, action): RiskClassFormulaState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RISKCLASSFORMULA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_RISKCLASSFORMULA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RISKCLASSFORMULA):
    case REQUEST(ACTION_TYPES.UPDATE_RISKCLASSFORMULA):
    case REQUEST(ACTION_TYPES.DELETE_RISKCLASSFORMULA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_RISKCLASSFORMULA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RISKCLASSFORMULA):
    case FAILURE(ACTION_TYPES.CREATE_RISKCLASSFORMULA):
    case FAILURE(ACTION_TYPES.UPDATE_RISKCLASSFORMULA):
    case FAILURE(ACTION_TYPES.DELETE_RISKCLASSFORMULA):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_RISKCLASSFORMULA_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_RISKCLASSFORMULA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_RISKCLASSFORMULA):
    case SUCCESS(ACTION_TYPES.UPDATE_RISKCLASSFORMULA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RISKCLASSFORMULA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/risk-class-formulas';

// Actions

export const getEntities: ICrudGetAllAction<IRiskClassFormula> = (riskClassId, coverTypeId) => ({
  type: ACTION_TYPES.FETCH_RISKCLASSFORMULA_LIST,
  payload: axios.get<IRiskClassFormula>(
    `${apiUrl}?riskClassId=${riskClassId || ''}&coverTypeId=${coverTypeId || ''}&cacheBuster=${new Date().getTime()}`
  )
});

export const getEntity: ICrudGetAction<IRiskClassFormula> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RISKCLASSFORMULA,
    payload: axios.get<IRiskClassFormula>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IRiskClassFormula> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RISKCLASSFORMULA,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(entity.riskClassId, entity.coverTypeId));
  return result;
};

export const updateEntity: ICrudPutAction<IRiskClassFormula> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RISKCLASSFORMULA,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(entity.riskClassId, entity.coverTypeId));
  return result;
};

export const deleteEntity = (id, riskClassId, coverTypeId) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RISKCLASSFORMULA,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(Number(riskClassId), Number(coverTypeId)));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
