import { Moment } from 'moment';
import { IBenefit } from 'app/shared/model/benefit.model';
import { IPartner } from 'app/shared/model/partner.model';

export const enum ClientType {
  ACTIVE = 'ACTIVE',
  PROSPECTIVE = 'PROSPECTIVE',
  INACTIVE = 'INACTIVE'
}

export const enum QuoteStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SOLD = 'SOLD'
}

export interface IQuote {
  companyId?: any;
  id?: number;
  clientType?: ClientType;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  createdAt?: Moment;
  date?: Moment;
  expiryDate?: Moment;
  isUsedBinderRate?: boolean;
  sumInsured?: number;
  productName?: string;
  registration?: string;
  rate?: number;
  basicPremium?: number;
  totalAdditionalBenefits?: number;
  levies?: number;
  premiumPayable?: number;
  quoteSource?: string;
  isEmailed?: boolean;
  isMessaged?: boolean;
  yearOfManufacture?: number;
  hasVehicleValued?: boolean;
  underwriterId?: number;
  callStatusId?: number;
  clientId?: number;
  riskClassId?: number;
  coverTypeId?: number;
  vehicleTypeId?: number;
  additionalBenefits?: IBenefit[];
  underwriterName?: string;
  clientName?: string;
  riskClassName?: string;
  coverTypeName?: string;
  basicBenefits?: IBenefit[];
  tonnageRangeId?: number;
  carMakeId?: number;
  carModelId?: number;
  currentStep?: number;
  quotes?: IQuote[];
  capacity?: number;
  userIp?: string;
  partner?: IPartner;
  status?: QuoteStatus;
  dateOfBirth?: Moment;
  spouseDOB?: Moment;
  sendEmail?: boolean;
  noOfChildren?: number;
  productTypeId?: number;
  children?: any[];
  budgetId?: number;
  callPermission?: boolean;
}

export const defaultValue: Readonly<IQuote> = {
  isUsedBinderRate: false,
  isEmailed: false,
  isMessaged: false,
  hasVehicleValued: false
};

export interface ISummary {
  quotes?: number;
  clients?: number;
  users?: number;
  quotesPayments?: number;
  mobilePayments?: number;
  cardPayments?: number;
}
export const summaryValue: Readonly<ISummary> = {
  quotes: 0,
  clients: 0,
  users: 0,
  quotesPayments: 0
};
