export const ACTION_TYPES = {
  ADD_NOTIFICATION: 'notification/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'notification/REMOVE_NOTIFICATION'
};

const initialState = {
  totalNotifications: 0
};

export type NotificationState = typeof initialState;

export default (state: NotificationState = initialState, action): NotificationState => {
  switch (action.type) {
    case ACTION_TYPES.ADD_NOTIFICATION:
      return { ...state, totalNotifications: state.totalNotifications + 1 };
    default:
      return state;
  }
};

export const addNotification = () => async dispatch =>
  dispatch({
    type: ACTION_TYPES.ADD_NOTIFICATION
  });
