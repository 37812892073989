export interface IRiskClassKycType {
  id?: number;
  name?: string;
  description?: string;
  riskCategoryId?: number;
  riskCategoryName?: string;
  riskClassId?: number;
  riskClassName?: number;
}

export const defaultValue: Readonly<IRiskClassKycType> = {};
