import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './standard-rate-default-data.reducer';
import { IStandardRateDefaultData } from 'app/shared/model/standard-rate-default-data.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IStandardRateDefaultDataDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class StandardRateDefaultDataDetail extends React.Component<IStandardRateDefaultDataDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { standardRateDefaultDataEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            StandardRateDefaultData [<b>{standardRateDefaultDataEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="rate">Rate</span>
            </dt>
            <dd>{standardRateDefaultDataEntity.rate}</dd>
            <dt>
              <span id="minimumValue">Minimum Value</span>
            </dt>
            <dd>{standardRateDefaultDataEntity.minimumValue}</dd>
            <dt>
              <span id="startActiveDate">Start Active Date</span>
            </dt>
            <dd>
              <TextFormat value={standardRateDefaultDataEntity.startActiveDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="endActiveDate">End Active Date</span>
            </dt>
            <dd>
              <TextFormat value={standardRateDefaultDataEntity.endActiveDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="status">Status</span>
            </dt>
            <dd>{standardRateDefaultDataEntity.status}</dd>
            <dt>Product Type</dt>
            <dd>{standardRateDefaultDataEntity.productTypeId ? standardRateDefaultDataEntity.productTypeId : ''}</dd>
            <dt>Risk Category</dt>
            <dd>{standardRateDefaultDataEntity.riskCategoryId ? standardRateDefaultDataEntity.riskCategoryId : ''}</dd>
            <dt>Risk Class</dt>
            <dd>{standardRateDefaultDataEntity.riskClassId ? standardRateDefaultDataEntity.riskClassId : ''}</dd>
            <dt>Cover Type</dt>
            <dd>{standardRateDefaultDataEntity.coverTypeId ? standardRateDefaultDataEntity.coverTypeId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/standard-rate-default-data" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/standard-rate-default-data/${standardRateDefaultDataEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ standardRateDefaultData }: IRootState) => ({
  standardRateDefaultDataEntity: standardRateDefaultData.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardRateDefaultDataDetail);
