export interface IBenefitSpanType {
  id?: number;
  inpatient?: string;
  outpatient?: string;
  maternity?: string;
  dental?: string;
  optical?: string;
  underwriterId?: number;
  underwriterName?: string;
  riskClassId?: number;
  riskClassName?: string;
  coverTypeId?: number;
  coverTypeName?: string;
}

export const defaultValue: Readonly<IBenefitSpanType> = {};
