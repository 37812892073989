import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Card, CardTitle, Row, Col, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-flags-select/css/react-flags-select.css';
import { IRootState } from 'app/shared/reducers';
import { CarBodyTypes } from 'app/config/constants';
import { createEntity, updateEntity, getEntityByClientId } from '../../kyc/kyc.reducer';
import { createEntity as createAttachment } from '../../attachment/attachment.reducer';
import { updateEntity as updateQuote } from 'app/entities/quote/quote.reducer';
import { createEntity as createAttachmentFile } from '../../attachment-file/attachment-file.reducer';
import { getEntities as getCarMakes } from 'app/entities/car-make/car-make.reducer';
import { getEntityModels as getCarModels } from 'app/entities/car-model/car-model.reducer';
import { addVehicleAdditionalInfo } from 'app/shared/reducers/vehicle-adittional-info.reducer';
import { AttachmentType, EntityName } from 'app/shared/model/attachment.model';
import _ from 'lodash';

export interface IQuoteProps extends StateProps, DispatchProps {
  jumpToStep;
}

export interface IClientKYCState {
  totalAmountPaid: number;
  carBodyTypes: any[];
  attachments: any[];
  preview_id: number;
  preview_licence: string;
  preview_logbook: string;
  preview_valuation_report: string;
  currentYear: number;
  year: number;
  yearOfManufactures: any[];
  carMake: number;
  model: number;
}

class ClientKYCStep extends React.Component<IQuoteProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      preview_id: null,
      preview_licence: null,
      preview_logbook: null,
      preview_valuation_report: null,
      carBodyTypes: CarBodyTypes,
      currentYear: null,
      year: new Date().getFullYear(),
      yearOfManufactures: [],
      yearOfManufacture: null,
      carMake: null,
      model: null
    };
    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const carMakesResult = Promise.resolve(this.props.getCarMakes(0, 2000));
    const year = this.state.year;
    this.setState({
      yearOfManufactures: Array.from(new Array(42), (val, index) => ({
        name: String(year - index),
        id: year - index
      })).reverse()
    });
    carMakesResult.then(r => {
      const makeId = this.props.quote.carMakeId;
      if (makeId) {
        this.getCarModel(makeId);
        const make = this.props.vehicleMakes.find(item => Number(item.id) === Number(makeId));
        this.setState({ make });
      }
    });
    if (this.props.quote.yearOfManufacture) {
      this.setState({ yearOfManufacture: this.props.quote.yearOfManufacture });
    }
    const kycResult = Promise.resolve(this.props.getEntityByClientId(this.props.client.id));
    kycResult.then(res => {
      const { kyc } = this.props;
      if (kyc.attachments.length) {
        const driving_licence = kyc.attachments.find(a => a.name === 'driving_licence');
        const logbook = kyc.attachments.find(a => a.name === 'logbook');
        const nationalId = kyc.attachments.find(a => a.name === 'nationalId');
        const valuation_report = kyc.attachments.find(a => a.name === 'valuation_report');
        this.setState({
          driving_licence,
          logbook,
          nationalId,
          valuation_report
        });
      }
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(3);
    }
  }

  getCarModel(e) {
    const modelsResult = Promise.resolve(this.props.getCarModels(e));
    modelsResult.then(r => {
      if (this.props.quote.carModelId) {
        const model = this.props.vehicleModels.find(item => Number(item.id) === Number(this.props.quote.carModelId));
        this.setState({ model });
      }
    });
  }

  async handleChange(e) {
    e.persist();
    const attachmentFilesUrl = 'api/attachment-files';
    const attachmentsUrl = 'api/attachments';
    const currentFile = e.target.files[0];
    const currentFileURI = URL.createObjectURL(currentFile);
    this.setState({
      [e.target.name]: e.target.files[0],
      [e.target.name]: currentFileURI
    });

    const buffer = await currentFile.arrayBuffer();
    const byteArray = new Int8Array(buffer);
    const attachmentFile = {
      content: Array.from(byteArray),
      contentContentType: currentFile.type
    };
    const attachment = {
      name: e.target.name,
      type:
        (e.target.name === 'nationalId' && AttachmentType.ID_IMAGE) ||
        (e.target.name === 'logbook' && AttachmentType.LOG_BOOK_IMAGE) ||
        (e.target.name === 'driving_licence' && AttachmentType.DRIVING_LICENCE_IMAGE) ||
        (e.target.name === 'valuation_report' && AttachmentType.VALUATION_REPORT_IMAGE),
      entityName: EntityName.CLIENT,
      entityId: 1,
      path: currentFileURI,
      format: currentFile.type,
      attachmentFile
    };
    const at = await axios.post(attachmentsUrl, attachment);
    this.setState({ [e.target.name]: at.data });
    // if (Object.keys(this.props.kyc).length) {
    //   const { kyc } = this.props;
    //   if (kyc.attachments.some(a => a.name === e.target.name)) {
    //     kyc.attachments[kyc.attachments.findIndex(att => att.name === e.target.name)] = at.data;
    //   } else {
    //     kyc.attachments.push(at.data);
    //   }
    //   this.setState({ attachments: kyc.attachments });
    // } else {
    //   this.setState({ attachments: [...this.state.attachments, at.data] });
    // }
  }

  handleSubmit = async (event, errors, values) => {
    const { client, quote, kyc, vehicleModels, vehicleMakes, account } = this.props;
    values['companyId'] = account.companyId;
    const { make, model, yearOfManufacture, nationalId, logbook, driving_licence, valuation_report } = this.state;
    const kycData = {
      ...kyc,
      ...values,
      make: values.make || make.id,
      model: values.model || model.id,
      mobile: this.props.quote.mobile,
      registration: quote.registration,
      valueOfCar: quote.sumInsured,
      riskClassId: quote.riskClassId,
      clientId: client.id,
      attachments: [nationalId && nationalId, logbook && logbook, driving_licence && driving_licence, valuation_report && valuation_report],
      generalKYCType: 'OTHER',
      motorKYCType: 'OTHER',
      customKYCType: '',
      expiryDate: quote.expiryDate
    };
    const vehicleInfo = {
      make: vehicleMakes.find(value => value.id === Number(values.make)) || make,
      model: vehicleModels.find(value => value.id === Number(values.model)) || model,
      bodyType: values.carBodyType,
      chassisNumber: values.chasisNumber,
      engineNumber: values.engineNumber
    };
    this.props.addVehicleAdditionalInfo(vehicleInfo);
    const quoteData = {
      ...this.props.quote,
      ...values,
      clientName: `${client.firstName} ${client.lastName}`,
      riskClassName: quote.riskClassName,
      coverTypeName: quote.coverTypeName,
      carMakeId: values.make || make.id,
      clientId: this.props.client.id,
      carModelId: values.model || model.id,
      yearOfManufacture: values.year || yearOfManufacture
    };
    const quoteValues = {
      year: quote.yearOfManufacture,
      make: quote.carMakeId,
      model: quote.carModelId
    };
    const kycValues = {
      carBodyType: kyc.carBodyType,
      chasisNumber: kyc.chasisNumber,
      color: kyc.color,
      companyId: account.companyId,
      dateOfValuation: kyc.dateOfValuation,
      driving_licence:
        kyc.attachments && kyc.attachments.find(a => a.name === 'driving_licence')
          ? kyc.attachments.find(a => a.name === 'driving_licence').name
          : '',
      engineNumber: kyc.engineNumber,
      kycType: kyc.kycType,
      logbook:
        kyc.attachments && kyc.attachments.find(a => a.name === 'logbook') ? kyc.attachments.find(a => a.name === 'logbook').name : '',
      make: Number(kyc.make),
      model: Number(kyc.model),
      nationalId:
        kyc.attachments && kyc.attachments.find(a => a.name === 'nationalId')
          ? kyc.attachments.find(a => a.name === 'nationalId').name
          : '',
      valuationCenter: kyc.valuationCenter,
      valuation_report:
        kyc.attachments && kyc.attachments.find(a => a.name === 'valuation_report')
          ? kyc.attachments.find(a => a.name === 'valuation_report').name
          : '',
      year: kyc.year
    };
    if (!_.isEqual(quoteValues, { year: values.year, make: values.make, model: values.model })) {
      await this.props.updateQuote(quoteData);
    }
    if (Object.keys(this.props.kyc).length) {
      if (_.isEqual(values, kycValues)) {
        this.props.jumpToStep(3);
      } else {
        await this.props.updateEntity(kycData);
      }
    } else {
      await this.props.createEntity(kycData);
    }
  };

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(1);
  }

  render() {
    const { vehicleMakes, quote, client, kyc, vehicleModels, updating } = this.props;
    const {
      carBodyTypes,
      make,
      model,
      yearOfManufacture,
      yearOfManufactures,
      nationalId,
      logbook,
      driving_licence,
      valuation_report
    } = this.state;
    const idFile = nationalId || null;
    const logbookFile = logbook || null;
    const drivingLicenceFile = driving_licence || null;
    const valuationReportFile = valuation_report || null;
    return (
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          KYC Info
        </CardTitle>
        <CardBody className="pr-5 pl-5">
          <AvForm onSubmit={this.handleSubmit}>
            <Row className="p-8">
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="kyc_id">
                      National ID
                    </Label>
                    <Col sm="8">
                      <AvInput onChange={this.handleChange} id="kyc_id" type="file" className="form-control" name="nationalId" />
                    </Col>
                    {idFile && (
                      <img
                        style={{ height: '250px', objectFit: 'cover', width: '100%', paddingLeft: '20px', paddingRight: '20px' }}
                        src={`data:${idFile.attachmentFile && idFile.attachmentFile.contentContentType};base64,${idFile.attachmentFile &&
                          idFile.attachmentFile.content}`}
                        alt=""
                      />
                    )}
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="kyc_logbook">
                      Log Book
                    </Label>
                    <Col sm="8">
                      <AvInput onChange={e => this.handleChange(e)} id="kyc_logbook" type="file" className="form-control" name="logbook" />
                    </Col>
                    {logbookFile && (
                      <img
                        style={{ height: '250px', objectFit: 'cover', width: '100%', paddingLeft: '20px', paddingRight: '20px' }}
                        src={`data:${logbookFile &&
                          logbookFile.attachmentFile &&
                          logbookFile.attachmentFile.contentContentType};base64,${logbookFile &&
                          logbookFile.attachmentFile &&
                          logbookFile.attachmentFile.content}`}
                        alt=""
                      />
                    )}
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="chasisNumberLabel">
                      Chassis Number
                    </Label>
                    <Col sm="8">
                      <AvField
                        id="chasisNumberLabel"
                        type="text"
                        className="form-control"
                        name="chasisNumber"
                        value={kyc.chasisNumber || ''}
                        required
                      />
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="car-make">
                      Car Make
                    </Label>
                    <Col sm="8">
                      <AvInput
                        id="car-make"
                        type="select"
                        className="form-control"
                        name="make"
                        value={(make && make.id) || null}
                        onChange={e => event && event.target && this.getCarModel(e.target.value)}
                      >
                        <option disabled value="" key="0" />
                        {vehicleMakes
                          ? vehicleMakes.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="yearOfManufacture">
                      Year Of Manufacture
                    </Label>
                    <Col sm="8">
                      <AvInput
                        id="yearOfManufacture"
                        type="select"
                        className="form-control"
                        name="year"
                        value={(yearOfManufacture && yearOfManufacture) || null}
                        required
                      >
                        <option disabled value={''} key="0" />
                        {yearOfManufactures
                          ? yearOfManufactures.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="color">
                      Color
                    </Label>
                    <Col sm="8">
                      <AvField id="color" type="text" className="form-control" value={kyc && kyc.color} name="color" required />
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="kyc_type">
                      KYC Type
                    </Label>
                    <Col sm="8">
                      <AvInput id="kyc_type" type="select" className="form-control" value={kyc && kyc.kycType} name="kycType">
                        <option disabled value="" key="0" />
                        <option value="GENERAL" key="1">
                          GENERAL
                        </option>
                        <option value="RISK_CLASS" key="2">
                          RISK CLASS
                        </option>
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="kyc_driving_licence">
                      Driving Licence
                    </Label>
                    <Col sm="8">
                      <AvInput
                        onChange={e => this.handleChange(e)}
                        id="kyc_driving_licence"
                        type="file"
                        className="form-control"
                        name="driving_licence"
                      />
                    </Col>
                    {drivingLicenceFile && (
                      <img
                        style={{ height: '250px', objectFit: 'cover', width: '100%', paddingLeft: '20px', paddingRight: '20px' }}
                        src={`data:${drivingLicenceFile.attachmentFile && drivingLicenceFile.attachmentFile.contentContentType};base64,
                         ${drivingLicenceFile.attachmentFile && drivingLicenceFile.attachmentFile.content}`}
                        alt=""
                      />
                    )}
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="kyc_valuation_report">
                      Vehicle Valuation Report
                    </Label>
                    <Col sm="8">
                      <AvInput
                        onChange={e => this.handleChange(e)}
                        id="kyc_valuation_report"
                        type="file"
                        className="form-control"
                        name="valuation_report"
                      />
                    </Col>
                    {valuationReportFile && (
                      <img
                        style={{ height: '250px', objectFit: 'cover', width: '100%', paddingLeft: '20px', paddingRight: '20px' }}
                        src={`data:${valuationReportFile.attachmentFile && valuationReportFile.attachmentFile.contentContentType};base64,
                         ${valuationReportFile.attachmentFile && valuationReportFile.attachmentFile.content}`}
                        alt=""
                      />
                    )}
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="engineNumberLabel">
                      Engine Number
                    </Label>
                    <Col sm="8">
                      <AvField
                        id="engineNumberLabel"
                        type="text"
                        className="form-control"
                        name="engineNumber"
                        value={kyc && kyc.engineNumber}
                        required
                      />
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="car-model">
                      Car Model
                    </Label>
                    <Col sm="8">
                      <AvInput
                        id="car-model"
                        type="select"
                        className="form-control"
                        name="model"
                        value={(model && model.id) || null}
                        required
                      >
                        <option disabled value={''} key="0" />
                        {vehicleModels
                          ? vehicleModels.map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="valuationCentre">
                      Valuation Centre
                    </Label>
                    <Col sm="8">
                      <AvField
                        id="valuationCentre"
                        type="text"
                        className="form-control"
                        name="valuationCenter"
                        value={kyc && kyc.valuationCenter}
                      />
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="effective-date">
                      Valuation Date
                    </Label>
                    <Col sm="8">
                      <AvField
                        id="valuation-date"
                        type="date"
                        className="form-control"
                        name="dateOfValuation"
                        value={kyc && kyc.dateOfValuation}
                      />
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="car-body-type">
                      Car Body Type
                    </Label>
                    <Col sm="8">
                      <AvInput
                        id="car-body-type"
                        type="select"
                        className="form-control"
                        name="carBodyType"
                        value={kyc && kyc.carBodyType}
                        required
                      >
                        <option disabled value="" key="0" />
                        {carBodyTypes
                          ? carBodyTypes.map(otherEntity => (
                              <option value={otherEntity} key={otherEntity}>
                                {otherEntity}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row className="pl-3 pr-5 mt-3 mb-5">
              <Col md="12 text-right">
                <Button type="button" color="info" onClick={this.handleBack}>
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp; &nbsp;
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> NEXT
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  vehicleMakes: storeState.carMake.entities,
  vehicleModels: storeState.carModel.entities,
  attachment: storeState.attachment.entity,
  quote: storeState.quote.entity,
  kyc: storeState.kYC.entity,
  client: storeState.client.entity,
  attachments: storeState.attachment.attachments,
  account: storeState.authentication.account,
  updating: storeState.kYC.updating,
  attachmentFile: storeState.attachmentFile.entity,
  updateSuccess: storeState.kYC.updateSuccess,
  attachmentUpdateSuccess: storeState.attachment.updateSuccess
});

const mapDispatchToProps = {
  getCarMakes,
  getCarModels,
  updateQuote,
  createEntity,
  createAttachment,
  createAttachmentFile,
  addVehicleAdditionalInfo,
  getEntityByClientId,
  updateEntity
  // getTonnageRanges
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientKYCStep);
