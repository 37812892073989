import { Moment } from 'moment';

export const enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface IStandardRate {
  id?: number;
  rate?: number;
  minimumValue?: number;
  startActiveDate?: Moment;
  endActiveDate?: Moment;
  status?: Status;
  productTypeId?: number;
  riskCategoryId?: number;
  riskClassId?: number;
  coverTypeId?: number;
  productTypeName?: string;
  riskCategoryName?: string;
  riskClassName?: string;
  coverTypeName?: string;
}

export const defaultValue: Readonly<IStandardRate> = {};
