import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, Row, Col, Label, FormGroup, CardBody, Input, Table, Button } from 'reactstrap';
import { ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './claim.reducer';
import { IClaim } from 'app/shared/model/claim.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { Line } from 'react-chartjs-2';

export interface IClaimProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

const lineData = {
  labels: [1, 2, 3, 4, 5, 6, 7, 8],
  datasets: [
    {
      label: 'Income',
      borderWidth: 1,
      backgroundColor: 'rgba(94,114,228,.1)',
      borderColor: 'rgb(94,114,228)',
      pointBorderColor: 'rgb(94,114,228)',
      pointBackgroundColor: 'rgb(94,114,228)',
      data: [0, 15, 6, 11, 25, 9, 18, 24]
    },
    {
      label: 'Outcome',
      borderWidth: 1,
      backgroundColor: 'rgba(79,195,247,.1)',
      borderColor: 'rgb(79,195,247)',
      pointBorderColor: 'rgb(79,195,247)',
      pointBackgroundColor: 'rgb(79,195,247)',
      data: [0, 8, 11, 22, 8, 10, 5, 21]
    }
  ]
};

export const Claim = (props: IClaimProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    const productTypeIds = props.account.productTypes.map(a => `productTypeId=${a.id}`);
    props.getEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}&${productTypeIds.join('&')}`
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sortValue = params.get('sort');
    if (page && sortValue) {
      const sortSplit = sortValue.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [props.location.search]);

  const handleChangePeriod = value => {
    // console.log(value);
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const { claimList, match, loading, totalItems, reports } = props;
  return (
    <div>
      <h2 id="claim-heading">
        Claims
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Claim
        </Link>
      </h2>

      <Card>
        <CardBody className="border-bottom pb-5">
          <div className="d-md-flex">
            <div>
              <h4 className="mb-1 card-title">Claim Summaries</h4>
              <h6 className="mb-0 mt-0 font-16 font-light card-subtitle">Overview of Current Month</h6>
            </div>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Input type="select" className="custom-select" onChange={event => handleChangePeriod(event.target.value)}>
                  <option value="MONTH">Monthly</option>
                  <option value="DAY">Daily</option>
                  <option value="WEEK">Weekly</option>
                  <option value="YEAR">Yearly</option>
                </Input>
              </div>
            </div>
          </div>
          <Row>
            <Col lg="4" className="mt-4">
              <h1 className="mb-0 mt-4">Ksh. {0}</h1>
              <h6 className="font-light text-muted">Current Month Claims Value</h6>
              <h3 className="mt-4 mb-0">{reports.currentClaims || 0}</h3>
              <h6 className="font-light text-muted">Current Month Claims</h6>
              <a className="btn btn-info my-3 p-3 px-4" href="#">
                Last Month Summary
              </a>
            </Col>
            <Col lg="8">
              <div className="campaign ct-charts">
                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 250 }}>
                  <Line
                    data={lineData}
                    options={{
                      maintainAspectRatio: false,
                      legend: { display: false, labels: { fontFamily: 'Nunito Sans' } },
                      scales: {
                        yAxes: [
                          {
                            stacked: true,
                            gridLines: { display: false },
                            ticks: { fontFamily: 'Nunito Sans' }
                          }
                        ],
                        xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: 'Nunito Sans' } }]
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="table-responsive">
        {claimList && claimList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('brokerRefNo')}>
                  Broker Ref No <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fileRefNo')}>
                  File Ref No <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('brokerClaimNo')}>
                  Broker Claim No <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('policyNo')}>
                  Policy No <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('insurerClaimNo')}>
                  Insurer Claim No <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('coverPeriod')}>
                  Cover Period <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dateOfLoss')}>
                  Date Of Loss <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('claimGroup')}>
                  Claim Group <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dateReported')}>
                  Date Reported <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('policeStationReportedTo')}>
                  Police Station Reported To <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('garage')}>
                  Garage <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('claimAmount')}>
                  Claim Amount <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('excessAmount')}>
                  Excess Amount <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('settlementAmount')}>
                  Settlement Amount <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('adjusterAppointed')}>
                  Adjuster Appointed <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Quote <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Client <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Risk Class <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Underwriter <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Cover Type <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Client Policy <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  K YC <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {claimList.map((claim, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${claim.id}`} color="link" size="sm">
                      {claim.id}
                    </Button>
                  </td>
                  <td>{claim.brokerRefNo}</td>
                  <td>{claim.fileRefNo}</td>
                  <td>{claim.brokerClaimNo}</td>
                  <td>{claim.policyNo}</td>
                  <td>{claim.insurerClaimNo}</td>
                  <td>{claim.coverPeriod}</td>
                  <td>{claim.dateOfLoss ? <TextFormat type="date" value={claim.dateOfLoss} format={APP_LOCAL_DATE_FORMAT} /> : null}</td>
                  <td>{claim.claimGroup}</td>
                  <td>{claim.dateReported ? <TextFormat type="date" value={claim.dateReported} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{claim.policeStationReportedTo}</td>
                  <td>{claim.garage}</td>
                  <td>{claim.claimAmount}</td>
                  <td>{claim.excessAmount}</td>
                  <td>{claim.settlementAmount}</td>
                  <td>{claim.adjusterAppointed}</td>
                  <td>{claim.quoteId ? <Link to={`quote/${claim.quoteId}`}>{claim.quoteId}</Link> : ''}</td>
                  <td>{claim.clientId ? <Link to={`client/${claim.clientId}`}>{claim.clientId}</Link> : ''}</td>
                  <td>{claim.riskClassId ? <Link to={`risk-class/${claim.riskClassId}`}>{claim.riskClassId}</Link> : ''}</td>
                  <td>{claim.underwriterId ? <Link to={`underwriter/${claim.underwriterId}`}>{claim.underwriterId}</Link> : ''}</td>
                  <td>{claim.coverTypeId ? <Link to={`cover-type/${claim.coverTypeId}`}>{claim.coverTypeId}</Link> : ''}</td>
                  <td>{claim.clientPolicyId ? <Link to={`client-policy/${claim.clientPolicyId}`}>{claim.clientPolicyId}</Link> : ''}</td>
                  <td>{claim.kYCId ? <Link to={`kyc/${claim.kYCId}`}>{claim.kYCId}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${claim.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${claim.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${
                          paginationState.order
                        }`}
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${claim.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${
                          paginationState.order
                        }`}
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Claims found</div>
        )}
      </div>
      {props.totalItems ? (
        <div className={claimList && claimList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ claim, authentication, reports }: IRootState) => ({
  claimList: claim.entities,
  loading: claim.loading,
  totalItems: claim.totalItems,
  account: authentication.account,
  reports: reports.entity
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Claim);
