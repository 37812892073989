import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { ICrudGetAction, ICrudDeleteAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IClient } from 'app/shared/model/client.model';
import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity } from './client.reducer';

export interface IClientDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClientDeleteDialog extends React.Component<IClientDeleteDialogProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  confirmDelete = event => {
    this.props.deleteEntity(this.props.clientEntity.id, this.props.clientEntity.companyId);
    this.handleClose(event);
  };

  handleClose = event => {
    event.stopPropagation();
    this.props.history.goBack();
  };

  render() {
    const { clientEntity, loading } = this.props;
    return (
      <Modal isOpen toggle={this.handleClose}>
        {loading ? (
          <ModalHeader>Loading...</ModalHeader>
        ) : (
          <div>
            <ModalHeader toggle={this.handleClose}>Confirm delete operation</ModalHeader>
            <ModalBody id="ibsApp.client.delete.question">Are you sure you want to delete this Client?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleClose}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Cancel
              </Button>
              <Button id="jhi-confirm-delete-client" color="danger" onClick={this.confirmDelete}>
                <FontAwesomeIcon icon="trash" />
                &nbsp; Delete
              </Button>
            </ModalFooter>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ client }: IRootState) => ({
  clientEntity: client.entity,
  loading: client.loading
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDeleteDialog);
