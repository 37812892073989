import { Moment } from 'moment';

export const enum DebitType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export interface IDebitNote {
  id?: number;
  remarks?: string;
  kycId?: number;
  debitType?: DebitType;
  clientId?: number;
  startDate?: Moment;
  endDate?: Moment;
  clientPolicyId?: number;
  productTypeId?: number;
  companyId?: number;
}

export const defaultValue: Readonly<IDebitNote> = {};
