import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IServiceProvider, defaultValue } from 'app/shared/model/service-provider.model';

export const ACTION_TYPES = {
  FETCH_SERVICEPROVIDER_LIST: 'serviceProvider/FETCH_SERVICEPROVIDER_LIST',
  FETCH_SERVICEPROVIDER: 'serviceProvider/FETCH_SERVICEPROVIDER',
  CREATE_SERVICEPROVIDER: 'serviceProvider/CREATE_SERVICEPROVIDER',
  UPDATE_SERVICEPROVIDER: 'serviceProvider/UPDATE_SERVICEPROVIDER',
  DELETE_SERVICEPROVIDER: 'serviceProvider/DELETE_SERVICEPROVIDER',
  RESET: 'serviceProvider/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IServiceProvider>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type ServiceProviderState = Readonly<typeof initialState>;

// Reducer

export default (state: ServiceProviderState = initialState, action): ServiceProviderState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SERVICEPROVIDER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SERVICEPROVIDER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SERVICEPROVIDER):
    case REQUEST(ACTION_TYPES.UPDATE_SERVICEPROVIDER):
    case REQUEST(ACTION_TYPES.DELETE_SERVICEPROVIDER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SERVICEPROVIDER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SERVICEPROVIDER):
    case FAILURE(ACTION_TYPES.CREATE_SERVICEPROVIDER):
    case FAILURE(ACTION_TYPES.UPDATE_SERVICEPROVIDER):
    case FAILURE(ACTION_TYPES.DELETE_SERVICEPROVIDER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICEPROVIDER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICEPROVIDER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SERVICEPROVIDER):
    case SUCCESS(ACTION_TYPES.UPDATE_SERVICEPROVIDER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SERVICEPROVIDER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/service-providers';

// Actions

export const getEntities: ICrudGetAllAction<IServiceProvider> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SERVICEPROVIDER_LIST,
  payload: axios.get<IServiceProvider>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<IServiceProvider> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SERVICEPROVIDER,
    payload: axios.get<IServiceProvider>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IServiceProvider> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SERVICEPROVIDER,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IServiceProvider> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SERVICEPROVIDER,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IServiceProvider> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SERVICEPROVIDER,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
