import React from 'react';
import { Switch } from 'react-router-dom';

// tslint:disable-next-line:no-unused-variable
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Company from './company';
import BenefitType from './benefit-type';
import Benefit from './benefit';
import Underwriter from './underwriter';
import ClientCategory from './client-category';
import NameTitle from './name-title';
import IdType from './id-type';
import Client from './client';
import ProductType from './product-type';
import RiskCategory from './risk-category';
import RiskClass from './risk-class';
import CoverType from './cover-type';
import Policy from './policy';
import PolicyBenefit from './policy-benefit';
import ClientPolicy from './client-policy';
import ClientPolicyPayment from './client-policy-payment';
import AttachmentFile from './attachment-file';
import Attachment from './attachment';
import RiskClassFormula from './risk-class-formula';
import FormulaParam from './formula-param';
import BinderRate from './binder-rate';
import StandardRate from './standard-rate';
import Levies from './levies';
import ProductTypeDefaultData from './product-type-default-data';
import RiskCategoryDefaultData from './risk-category-default-data';
import RiskClassDefaultData from './risk-class-default-data';
import StandardRateDefaultData from './standard-rate-default-data';
import LeviesDefaultData from './levies-default-data';
import Quote from './quote';
import KYC from './kyc';
import ServiceProviderType from './service-provider-type';
import ServiceProvider from './service-provider';
import CommunicationInfo from './communication-info';
import SmsContent from './sms-content';
import VehicleType from './vehicle-type';
import TonnageRange from './tonnage-range';
import CarEquipment from './car-equipment';
import CarGeneration from './car-generation';
import CarMake from './car-make';
import CarModel from './car-model';
import CarOption from './car-option';
import CarOptionValue from './car-option-value';
import CarSerie from './car-serie';
import CarSpecification from './car-specification';
import CarSpecificationValue from './car-specification-value';
import CarTrim from './car-trim';
import CarType from './car-type';
import CustomerAuthentication from './customer-authentication';
import CustomerAuthenticationToken from './customer-authentication-token';
import BenefitPlan from './benefit-plan';
import AgeRange from './age-range';
import RiskClassKycType from './risk-class-kyc-type';
import Claim from './claim';
import CoverArea from './cover-area';
import BenefitDetails from './benefit-details';
import PolicyApplication from './policy-application';
import BenefitSpanType from './benefit-span-type';
import ShortCodes from './short-codes';
import UnderwriterProduct from './underwriter-product';
import BenefitCategory from './benefit-category';
import Invoice from './invoice';
import Transactions from './transactions';
import PaymentCallback from './direct-payment';
import KYCType from './kyc-type';
import Reminder from './reminder';
import Remittance from './remittance';
import Offer from './offer';
import Country from './country';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */ }
      <ErrorBoundaryRoute path={ `${ match.url }/company` } component={ Company }/>
      <ErrorBoundaryRoute path={`${match.url}/benefit-type`} component={BenefitType} />
      <ErrorBoundaryRoute path={`${match.url}/benefit`} component={Benefit} />
      <ErrorBoundaryRoute path={`${match.url}/underwriter`} component={Underwriter} />
      <ErrorBoundaryRoute path={`${match.url}/client-category`} component={ClientCategory} />
      <ErrorBoundaryRoute path={`${match.url}/name-title`} component={NameTitle} />
      <ErrorBoundaryRoute path={`${match.url}/id-type`} component={IdType} />
      <ErrorBoundaryRoute path={`${match.url}/client`} component={Client} />
      <ErrorBoundaryRoute path={`${match.url}/product-type`} component={ProductType} />
      <ErrorBoundaryRoute path={`${match.url}/risk-category`} component={RiskCategory} />
      <ErrorBoundaryRoute path={`${match.url}/risk-class`} component={RiskClass} />
      <ErrorBoundaryRoute path={`${match.url}/cover-type`} component={CoverType} />
      <ErrorBoundaryRoute path={`${match.url}/policy`} component={Policy} />
      <ErrorBoundaryRoute path={`${match.url}/policy-benefit`} component={PolicyBenefit} />
      <ErrorBoundaryRoute path={`${match.url}/client-policy`} component={ClientPolicy} />
      <ErrorBoundaryRoute path={`${match.url}/client-policy-payment`} component={ClientPolicyPayment} />
      <ErrorBoundaryRoute path={`${match.url}/attachment-file`} component={AttachmentFile} />
      <ErrorBoundaryRoute path={`${match.url}/attachment`} component={Attachment} />
      <ErrorBoundaryRoute path={`${match.url}/risk-class-formula`} component={RiskClassFormula} />
      <ErrorBoundaryRoute path={`${match.url}/formula-param`} component={FormulaParam} />
      <ErrorBoundaryRoute path={`${match.url}/binder-rate`} component={BinderRate} />
      <ErrorBoundaryRoute path={`${match.url}/standard-rate`} component={StandardRate} />
      <ErrorBoundaryRoute path={`${match.url}/levies`} component={Levies} />
      <ErrorBoundaryRoute path={`${match.url}/product-type-default-data`} component={ProductTypeDefaultData} />
      <ErrorBoundaryRoute path={`${match.url}/risk-category-default-data`} component={RiskCategoryDefaultData} />
      <ErrorBoundaryRoute path={`${match.url}/risk-class-default-data`} component={RiskClassDefaultData} />
      <ErrorBoundaryRoute path={`${match.url}/standard-rate-default-data`} component={StandardRateDefaultData} />
      <ErrorBoundaryRoute path={`${match.url}/levies-default-data`} component={LeviesDefaultData} />
      <ErrorBoundaryRoute path={`${match.url}/quote`} component={Quote} />
      <ErrorBoundaryRoute path={`${match.url}/kyc`} component={KYC} />
      <ErrorBoundaryRoute path={`${match.url}/service-provider-type`} component={ServiceProviderType} />
      <ErrorBoundaryRoute path={`${match.url}/service-provider`} component={ServiceProvider} />
      <ErrorBoundaryRoute path={`${match.url}/communication-info`} component={CommunicationInfo} />
      <ErrorBoundaryRoute path={`${match.url}/sms-content`} component={SmsContent} />
      <ErrorBoundaryRoute path={`${match.url}/vehicle-type`} component={VehicleType} />
      <ErrorBoundaryRoute path={`${match.url}/tonnage-range`} component={TonnageRange} />
      <ErrorBoundaryRoute path={`${match.url}/car-equipment`} component={CarEquipment} />
      <ErrorBoundaryRoute path={`${match.url}/car-generation`} component={CarGeneration} />
      <ErrorBoundaryRoute path={`${match.url}/car-make`} component={CarMake} />
      <ErrorBoundaryRoute path={`${match.url}/car-model`} component={CarModel} />
      <ErrorBoundaryRoute path={`${match.url}/car-option`} component={CarOption} />
      <ErrorBoundaryRoute path={`${match.url}/car-option-value`} component={CarOptionValue} />
      <ErrorBoundaryRoute path={`${match.url}/car-serie`} component={CarSerie} />
      <ErrorBoundaryRoute path={`${match.url}/car-specification`} component={CarSpecification} />
      <ErrorBoundaryRoute path={`${match.url}/car-specification-value`} component={CarSpecificationValue} />
      <ErrorBoundaryRoute path={`${match.url}/car-trim`} component={CarTrim} />
      <ErrorBoundaryRoute path={`${match.url}/car-type`} component={CarType} />
      <ErrorBoundaryRoute path={`${match.url}/customer-authentication`} component={CustomerAuthentication} />
      <ErrorBoundaryRoute path={`${match.url}/customer-authentication-token`} component={CustomerAuthenticationToken} />
      <ErrorBoundaryRoute path={`${match.url}/benefit-plan`} component={BenefitPlan} />
      <ErrorBoundaryRoute path={`${match.url}/product-type`} component={ProductType} />
      <ErrorBoundaryRoute path={`${match.url}/age-range`} component={AgeRange} />
      <ErrorBoundaryRoute path={`${match.url}/risk-class-kyc-type`} component={RiskClassKycType} />
      <ErrorBoundaryRoute path={`${match.url}/claim`} component={Claim} />
      <ErrorBoundaryRoute path={`${match.url}/cover-area`} component={CoverArea} />
      <ErrorBoundaryRoute path={`${match.url}/benefit-details`} component={BenefitDetails} />
      <ErrorBoundaryRoute path={`${match.url}/policy-application`} component={PolicyApplication} />
      <ErrorBoundaryRoute path={`${match.url}/benefit-span-type`} component={BenefitSpanType} />
      <ErrorBoundaryRoute path={`${match.url}/short-codes`} component={ShortCodes} />
      <ErrorBoundaryRoute path={`${match.url}/underwriter-product`} component={UnderwriterProduct} />
      <ErrorBoundaryRoute path={`${match.url}/benefit-category`} component={BenefitCategory} />
      <ErrorBoundaryRoute path={`${match.url}/invoice`} component={Invoice} />
      <ErrorBoundaryRoute path={`${match.url}/countries`} component={Country} />
      <ErrorBoundaryRoute path={`${match.url}/transactions`} component={Transactions} />
      <ErrorBoundaryRoute path={`${match.url}/direct-payments`} component={PaymentCallback} />
      <ErrorBoundaryRoute path={`${match.url}/kyc-type`} component={KYCType} />
      <ErrorBoundaryRoute path={`${match.url}/reminder`} component={Reminder} />
      <ErrorBoundaryRoute path={`${match.url}/remittance`} component={Remittance} />
      <ErrorBoundaryRoute path={`${match.url}/offer`} component={Offer} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
