import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const AgentsMenu = () => (
  <MenuItem to="/agents" icon="mdi mdi-account">
    Agents
  </MenuItem>
);

export default AgentsMenu;
