import { Age } from 'app/shared/model/enumerations/age.model';

export interface IAgeRange {
  id?: number;
  minValue?: number;
  maxValue?: number;
  unit?: Age;
}

export const defaultValue: Readonly<IAgeRange> = {};
