import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { IRootState } from 'app/shared/reducers';
import { setBlob, setAttachmentData } from '../kyc.reducer';
import { AttachmentType } from 'app/shared/model/attachment.model';

export interface IDocumentPinProps extends StateProps, DispatchProps {
  jumpToStep;
  onSubmit;
}

class DocumentPinForm extends React.Component<IDocumentPinProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false
    };
  }

  componentDidMount() {
    this.props.getClientCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  handleClick = () => {
    this.setState({ isFullScreen: true });
  };

  handlePrevious = () => {
    this.props.jumpToStep(0);
  };

  onSubmit = async (event, errors, values) => {
    if (errors.length === 0) {
      await this.props.setAttachmentData({ ...values, name: values.pinNumber, type: AttachmentType.PIN_IMAGE });
      this.props.onSubmit();
    }
  };

  render() {
    const { isFullScreen } = this.state;
    const { kYCEntity, clientCategories } = this.props;
    const { content, contentContentType } = kYCEntity;
    return (
      <AvForm className="mb-2 mt-5 w-100" onSubmit={this.onSubmit}>
        <Row className="mb-5 mt-4 justify-content-md-center">
          <Col md="5">
            <AvGroup>
              <Row>
                <Label sm="4" for="pin-type">
                  Pin Type
                </Label>
                <Col sm="8">
                  <AvInput id="pin-type" type="select" className="form-control" name="pinType" required>
                    {clientCategories
                      ? sortBy(clientCategories, ['name']).map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>This field is required.</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Label sm="4" id="pinNumber" for="pin-number">
                  Pin Number
                </Label>
                <Col sm="8">
                  <AvInput id="pin-number" placeholder="Enter Pin Number" type="text" name="pinNumber" />
                  <AvFeedback>This field is required.</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Label sm="4" for="country-Issue">
                  Upload Document
                </Label>
                <Col sm="8">
                  <div className="custom-file">
                    <input
                      id="upload-document"
                      type="file"
                      className="form-control"
                      onChange={this.onBlobChange(false, AttachmentType.ID_IMAGE)}
                    />
                    <AvInput type="hidden" name="content" value={content} />
                    <Label className="custom-file-label custom-file-upload" for="upload-document">
                      <FontAwesomeIcon icon="cloud-upload-alt" />
                      Click to Upload...
                    </Label>
                  </div>
                </Col>
              </Row>
            </AvGroup>
          </Col>
          <Col md="5">
            {content && (
              <div className="image-preview-container">
                <div className="image-contianer">
                  <img src={`data:${contentContentType};base64,${content}`} />
                </div>
                <div className="image-button-container">
                  <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                    <FontAwesomeIcon icon="eye" />
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button type="button" color="danger" size="sm" onClick={this.clearBlob('content')}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </div>
                {isFullScreen && (
                  <Lightbox
                    mainSrc={`data:${contentContentType};base64,${content}`}
                    onCloseRequest={() => this.setState({ isFullScreen: false })}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm="10">
            <FormGroup className="pt-5">
              <Row>
                <Col sm="6" className="text-left">
                  <Button type="button" onClick={this.handlePrevious} className="font-bold btn-rounded" color="info">
                    &nbsp; &nbsp;Back&nbsp; &nbsp;
                  </Button>
                </Col>
                <Col sm="6" className="text-right">
                  <Button type="submit" className="btn btn-success btn-rounded font-bold">
                    &nbsp; &nbsp; SAVE &nbsp; &nbsp;
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clientCategories: storeState.clientCategory.entities,
  account: storeState.authentication.account,
  kYCEntity: storeState.kYC.entity
});

const mapDispatchToProps = {
  getClientCategories,
  setBlob,
  setAttachmentData
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentPinForm);
