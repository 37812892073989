import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import RegisterSuccess from 'app/modules/account/register/register-success';

const HomeTemplate = props => (
  <MainTemplate>
    <RegisterSuccess {...props} />
  </MainTemplate>
);

export default HomeTemplate;
