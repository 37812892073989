import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import Quote from './quote';
import QuoteWizard from './wizard';
import QuoteDetail from './quote-detail';
import QuoteUpdate from './quote-update/quote-update';
import QuoteDeleteDialog from './quote-delete-dialog';
import AddBenefit from './benefit';
import OfflineQuoteWizard from 'app/entities/quote/offline-wizard';
import RequestPayment from 'app/entities/quote/request-payment';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={QuoteUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/create-quote`} component={QuoteWizard} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={QuoteUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/sell-quote`} component={OfflineQuoteWizard} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/quote-payment`} component={RequestPayment} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={QuoteDetail} />
      <ErrorBoundaryRoute exact path={`/entity/quote/add-benefit/:id`} component={AddBenefit} />
      <ErrorBoundaryRoute path={match.url} component={Quote} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={QuoteDeleteDialog} />
  </>
);

export default Routes;
