import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import Utils, { ImageType, moneyConverter } from 'app/config/utils';
import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { TextFormat } from 'react-jhipster';

const RemitPreview = ({ remittance, underwriter, clientPolicyPayments, account }) => {
  const companyAddress = `
  P O Box 28281-00200, Nairobi
  Tel: 2211152/2219636/2213253
  Mobile: 0720498001/0734534098
  Fax: 020 2218174
  1st Ngong Avenue, ACK Garden House, Ground Floor, Wing D, Nairobi, Kenya
  Email: info@kenbright.co.ke
`;

  const underwriterLogo = Utils.getCurrentImage(underwriter, ImageType.logo);

  const totalAmount = clientPolicyPayments.reduce((n, { amount }) => n + amount, 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentContainer}>
          <View style={styles.content}>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Image src="/content/images/broker-agent.png" style={styles.logo} />
                <Text style={styles.companyAddress}>{companyAddress}</Text>
              </View>
              <View style={styles.headerCol}>{underwriterLogo && <Image src={underwriterLogo} style={styles.logo} />}</View>
            </View>
            <View style={styles.customerDetails}>
              <View style={styles.customerInfo}>
                <Text style={styles.customerLabel}> Trans Code:</Text>{' '}
                <Text style={styles.customerValue}> {`${remittance.transCode}`}</Text>
                <Text style={styles.customerLabel}> Underwriter:</Text> <Text style={styles.customerValue}> {underwriter.name || ''}</Text>
              </View>
              <View style={styles.customerInfo}>
                <Text style={styles.customerLabel}> Total Amount:</Text>{' '}
                <Text style={styles.customerValue}> KES: {` ${moneyConverter(totalAmount)} `}</Text>
                <Text style={styles.customerLabel}> Date Remitted:</Text>
                <Text style={styles.customerValue}>
                  <TextFormat type="date" value={`${remittance.createdAt}`} format={APP_LOCAL_DATETIME_FORMAT} />
                </Text>
              </View>
            </View>
            <View style={styles.policySection}>
              <View style={styles.policyRow}>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>Policy No</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>Client Name</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>Effective Date</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>EXPIRY DATE</Text>
                </View>
                <View style={styles.policyCol}>
                  <Text style={styles.textLabel}>Amount</Text>
                </View>
              </View>
              {clientPolicyPayments &&
                clientPolicyPayments.map(e => (
                  <View style={styles.policyRow}>
                    <View style={styles.policyCol}>
                      <Text style={[styles.tableValue]}> {e.policyNo}</Text>
                    </View>
                    <View style={styles.policyCol}>
                      <Text style={[styles.tableValue]}> {`${e.firstName} ${e.middleName} ${e.lastName}`}</Text>
                    </View>
                    <View style={styles.policyCol}>
                      <Text style={[styles.tableValue]}> {e.startDate || ''}</Text>
                    </View>
                    <View style={styles.policyCol}>
                      <Text style={[styles.tableValue]}>{e.endDate || ''}</Text>
                    </View>
                    <View style={styles.policyCol}>
                      <Text style={[styles.tableValue]}>Ksh {moneyConverter(e.amount)}</Text>
                    </View>
                  </View>
                ))}
            </View>
            <View style={styles.paymentSummary}>
              <View style={styles.firstThird} />
              <View style={styles.content}>
                <View style={styles.lastThird}>
                  <View>
                    <Text style={styles.summaryItem}>Total Amount</Text>
                    <Text style={styles.summaryItem}>Broker Commission</Text>
                    <Text style={styles.summaryItem}>Amount</Text>
                  </View>
                  <View>
                    <Text style={styles.summaryItem}>KES: {moneyConverter(totalAmount)}</Text>
                    <Text style={styles.summaryItem}>
                      KES: {moneyConverter(clientPolicyPayments.reduce((n, { brokerCommission }) => n + brokerCommission, 0))}
                    </Text>
                    <Text style={styles.summaryItem}>
                      KES: {moneyConverter(clientPolicyPayments.reduce((n, { underwriterAmount }) => n + underwriterAmount, 0))}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.footerSection}>
              <View style={styles.sepparator} />
              <View style={styles.textRow}>
                <Text style={styles.textLabel}>Processed By:</Text>
                <Text style={styles.tableValue}>info@kenbright.co.ke</Text>
              </View>
              <View style={styles.textRow}>
                <Text style={[styles.textImportant, styles.bold]}>important</Text>
                <Text style={styles.tableValue}>
                  : Please note that the Insurance Act requires that premiums are paid prior to the inception of cover
                </Text>
              </View>
              <View style={styles.textRow}>
                <Text style={[styles.businessNo]}>Mpesa Business Number</Text>
                <Text style={styles.businessNo}>: 806 550</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 35,
    backgroundColor: '#F5F5F5'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between'
  },
  content: {
    flex: 1
  },
  firstThird: {
    flex: 3
  },
  lastThird: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  headerSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    paddingBottom: 20,
    marginBottom: 20
  },
  headerCol: {
    display: 'flex',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderLeft: '1px solid #ddd'
  },
  logo: {
    width: 150,
    height: 80
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
    textTransform: 'uppercase'
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    marginBottom: 10
  },
  textLabel: {
    fontWeight: 'heavy',
    fontSize: 10,
    textTransform: 'uppercase'
  },
  businessNo: {
    fontWeight: 'bold',
    fontSize: 15
  },
  textImportant: {
    fontSize: 10,
    textTransform: 'uppercase'
  },
  tableValue: {
    fontSize: 7,
    textAlign: 'center',
    marginLeft: 5
  },
  bold: {
    fontWeight: 'bold'
  },
  policySection: {
    border: '1px solid #ddd',
    flexDirection: 'column',
    marginTop: 20
  },
  policyRow: {
    flexDirection: 'row'
  },
  marginTop: {
    marginTop: 15
  },
  narration: {
    height: '25%',
    fontSize: 10,
    justifyContent: 'space-between'
  },
  policyCol: {
    flex: 1,
    border: '1px solid #ddd',
    padding: 10
  },
  footerSection: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 'auto'
  },
  important: {
    fontWeight: 'bold',
    color: 'red',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  float: {
    right: 'auto'
  },
  customerDetails: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 20,
    marginLeft: -10
  },
  customerInfo: {
    width: '45%',
    flexDirection: 'column',
    marginBottom: 10,
    paddingLeft: 10
  },
  customerDateInfo: {
    width: '45%',
    flexDirection: 'column',
    marginBottom: 10,
    paddingLeft: 10,
    alignItems: 'flex-end'
  },
  customerLabel: {
    paddingTop: 10,
    fontWeight: 'bold',
    fontSize: 10
  },
  customerValue: {
    fontSize: 12
  },
  sepparator: {
    marginBottom: 5,
    borderBottom: '1px solid #ddd',
    width: '100%'
  },
  companyAddress: {
    fontSize: 6,
    marginTop: 2
  },
  borderTop: {
    borderTop: '1px solid #ddd',
    paddingTop: 10
  },
  paymentSummary: {
    marginTop: 25,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    fontSize: 10
  },
  summaryItem: {
    fontSize: 7,
    marginBottom: 4
  }
});

export default RemitPreview;
