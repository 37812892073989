import { defaultValue, IAkiClass } from './aki-model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';

export const ACTION_TYPES = {
  PREVIEW_TYPE_A_CERTIFICATE: 'akiCertificate/PREVIEW_TYPE_B_CERTIFICATE',
  PREVIEW_TYPE_B_CERTIFICATE: 'akiCertificate/PREVIEW_TYPE_B_CERTIFICATE',
  PREVIEW_TYPE_C_CERTIFICATE: 'akiCertificate/PREVIEW_TYPE_B_CERTIFICATE',
  PREVIEW_TYPE_D_CERTIFICATE: 'akiCertificate/PREVIEW_TYPE_B_CERTIFICATE',
  UNSELECT_AKI_QUOTE_ITEM: 'akiCertificate/UNSELECT_AKI_QUOTE_ITEM',
  SELECT_AKI_QUOTE_ITEM: 'akiCertificate/SELECT_AKI_QUOTE_ITEM',
  UNSELECT_AKI_CLIENT_ITEM: 'akiCertificate/UNSELECT_AKI_CLIENT_ITEM',
  SELECT_AKI_CLIENT_ITEM: 'akiCertificate/SELECT_AKI_CLIENT_ITEM',
  SET_AKI_CLIENTS_ITEM: 'akiCertificate/SET_AKI_CLIENTS_ITEM'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entity: defaultValue,
  quote: null,
  client: {},
  clients: [],
  updating: false,
  response: null,
  updateSuccess: false
};
export type AkiCertificateState = Readonly<typeof initialState>;

export default (state: AkiCertificateState = initialState, action): AkiCertificateState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.PREVIEW_TYPE_A_CERTIFICATE):
    case REQUEST(ACTION_TYPES.PREVIEW_TYPE_B_CERTIFICATE):
    case REQUEST(ACTION_TYPES.PREVIEW_TYPE_C_CERTIFICATE):
    case REQUEST(ACTION_TYPES.PREVIEW_TYPE_D_CERTIFICATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };

    case FAILURE(ACTION_TYPES.PREVIEW_TYPE_A_CERTIFICATE):
    case FAILURE(ACTION_TYPES.PREVIEW_TYPE_B_CERTIFICATE):
    case FAILURE(ACTION_TYPES.PREVIEW_TYPE_C_CERTIFICATE):
    case FAILURE(ACTION_TYPES.PREVIEW_TYPE_D_CERTIFICATE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };

    case SUCCESS(ACTION_TYPES.PREVIEW_TYPE_A_CERTIFICATE):
    case SUCCESS(ACTION_TYPES.PREVIEW_TYPE_B_CERTIFICATE):
    case SUCCESS(ACTION_TYPES.PREVIEW_TYPE_C_CERTIFICATE):
    case SUCCESS(ACTION_TYPES.PREVIEW_TYPE_D_CERTIFICATE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
        updateSuccess: true,
        response: action.payload.data
      };
    case ACTION_TYPES.SELECT_AKI_QUOTE_ITEM:
      const item = action.payload;
      return {
        ...state,
        quote: item,
        loading: false,
        updateSuccess: true,
        updating: false
      };

    case ACTION_TYPES.UNSELECT_AKI_QUOTE_ITEM:
      return {
        ...state
      };

    case ACTION_TYPES.SELECT_AKI_CLIENT_ITEM:
      const client = action.payload;
      return {
        ...state,
        client,
        loading: false,
        updateSuccess: true,
        updating: false
      };

    case ACTION_TYPES.UNSELECT_AKI_CLIENT_ITEM:
      return {
        ...state
      };
    case ACTION_TYPES.SET_AKI_CLIENTS_ITEM:
      return {
        ...state,
        clients: action.payload
      };
    default:
      return state;
  }
};
const apiUrl = 'api/aki/v1/IntermediaryIntegration';

export const previewAkiCertificate = (entity, certificateRequestType) => {
  const requestUrl = `${apiUrl}/${certificateRequestType}`;
  return {
    type: ACTION_TYPES.PREVIEW_TYPE_C_CERTIFICATE,
    payload: axios.post<IAkiClass>(requestUrl, entity)
  };
};

export const getMemberCompany = name => {
  const companies = [
    { id: 11, name: 'African Merchant Assurance' },
    { id: 12, name: 'AIG Kenya Insurance Company Ltd' },
    { id: 13, name: 'Allianz Insurance' },
    { id: 15, name: 'Britam General Insurance' },
    { id: 16, name: 'CIC General Insurance' },
    { id: 18, name: 'Directline Assurance' },
    { id: 20, name: 'First Assurance' },
    { id: 23, name: 'ICEA Lion General Insurance' },
    { id: 24, name: 'Intra-Africa Assurance' },
    { id: 25, name: 'Invesco Assurance Company' },
    { id: 26, name: 'Jubilee Insurance' },
    { id: 27, name: 'Kenindia Assurance' },
    { id: 31, name: 'Mayfair Insurance' },
    { id: 34, name: 'Pacis Insurance' },
    { id: 36, name: 'Pioneer Insurance' },
    { id: 37, name: 'Resolution Insurance' },
    { id: 38, name: 'Saham Insurance' },
    { id: 39, name: 'Sanlam Insurance' },
    { id: 40, name: 'Takaful Insurance of Africa' },
    { id: 41, name: 'Tausi Assurance' }
  ];
  return companies.find(company => company.name === name);
};

export const selectAkiQuoteItem = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SELECT_AKI_QUOTE_ITEM,
    payload: entity
  });
  return result;
};

export const unSelectAkiQuoteItem = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UNSELECT_AKI_QUOTE_ITEM,
    payload: id
  });
  return result;
};

export const selectAkiClientItem = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SELECT_AKI_CLIENT_ITEM,
    payload: entity
  });
  return result;
};

export const unSelectAkiClientItem = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UNSELECT_AKI_CLIENT_ITEM,
    payload: id
  });
  return result;
};

export const setClients = clients => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_AKI_CLIENTS_ITEM,
    payload: clients
  });
  return result;
};
