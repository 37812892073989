import { defaultValue, IPolicyRenewalClass } from './policy-renewal-model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudGetAllAction } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_POLICY_REPORT: 'reports/FETCH_POLICY_REPORT'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entity: defaultValue,
  entities: [] as ReadonlyArray<IPolicyRenewalClass>,
  updating: false,
  response: null,
  updateSuccess: false
};
export type PolicyReportState = Readonly<typeof initialState>;

export default (state: PolicyReportState = initialState, action): PolicyReportState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_POLICY_REPORT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };

    case SUCCESS(ACTION_TYPES.FETCH_POLICY_REPORT):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        updateSuccess: true,
        response: action.payload.data
      };

    default:
      return state;
  }
};
const apiUrl = 'api/policy-report';

export const getAllPolicies: ICrudGetAllAction<IPolicyRenewalClass> = (page = 0, size = 10, sort = ' ,') => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_POLICY_REPORT,
    payload: axios.get<IPolicyRenewalClass>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};
