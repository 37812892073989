import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { setBlob, setData, updateStateEntity, setAttachmentData } from '../kyc.reducer';
import { IRootState } from 'app/shared/reducers';
import CountriesList from 'app/assets/nationalities';
import { AttachmentType } from 'app/shared/model/attachment.model';

export interface IDocumentIDProps extends StateProps, DispatchProps {
  jumpToStep;
  onSubmit;
  updateStateEntity;
}

class DocumentIDForm extends React.Component<IDocumentIDProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      countries: CountriesList
    };
  }

  componentDidMount() {
    this.props.getIdTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  onChange = event => {
    setData('ID_IMAGE', event.target.name, event.target.value);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  handleClick = () => {
    this.setState({ isFullScreen: true });
  };

  handlePrevious = () => {
    this.props.jumpToStep(0);
  };

  onSubmit = async (event, errors, values) => {
    if (errors.length === 0) {
      await this.props.setAttachmentData({ ...values, name: values.idNumber, type: AttachmentType.ID_IMAGE });
      this.props.onSubmit();
    }
  };

  render() {
    const { isFullScreen, country, countries } = this.state;
    const { kYCEntity, idTypes } = this.props;
    const { content, contentContentType } = kYCEntity;
    return (
      <AvForm className="mb-2 mt-5 w-100" onSubmit={this.onSubmit}>
        <Row className="mb-5 mt-4 justify-content-md-center">
          <Col md="5">
            <AvGroup>
              <Row>
                <Label sm="4" for="client-idType">
                  Id Type
                </Label>
                <Col sm="8">
                  <AvInput id="client-idType" type="select" className="form-control" name="idTypeId">
                    <option value="">Select</option>
                    {idTypes
                      ? sortBy(idTypes, ['name']).map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>This field is required.</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Label sm="4" id="idNumber" for="id-number">
                  ID Number
                </Label>
                <Col sm="8">
                  <AvInput id="id-number" placeholder="Enter ID Number" type="text" name="idNumber" />
                  <AvFeedback>This field is required.</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Label sm="4" for="DOB-date">
                  D.O.B
                </Label>
                <Col sm="8">
                  <AvInput id="DOB-date" type="date" className="form-control" name="dateOfBirth" />
                  <AvFeedback>This field is required.</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Label sm="4" for="issue-date">
                  Issue Date
                </Label>
                <Col sm="8">
                  <AvInput id="issue-date" type="date" className="form-control" name="issueDate" required />
                  <AvFeedback>This field is required.</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Label sm="4" for="country-Issue">
                  Country of Issue
                </Label>
                <Col sm="8">
                  <AvInput id="country-Issue" type="select" className="form-control" name="countryOfIssue">
                    <option>Select a Country</option>
                    <option value="" key="0" />
                    {countries
                      ? countries.map(otherEntity => (
                          <option value={otherEntity.en_short_name} key={otherEntity.num_code}>
                            {otherEntity.en_short_name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>This field is required.</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Label sm="4" for="country-Issue">
                  Upload Document
                </Label>
                <Col sm="8">
                  <div className="custom-file">
                    <input
                      id="upload-document"
                      type="file"
                      className="form-control"
                      onChange={this.onBlobChange(false, AttachmentType.ID_IMAGE)}
                    />
                    <AvInput type="hidden" name="content" value={content} />
                    <Label className="custom-file-label custom-file-upload" for="upload-document">
                      <FontAwesomeIcon icon="cloud-upload-alt" />
                      Click to Upload...
                    </Label>
                  </div>
                </Col>
              </Row>
            </AvGroup>
          </Col>
          <Col md="5">
            {content && (
              <div className="image-preview-container">
                <div className="image-contianer">
                  <img src={`data:${contentContentType};base64,${content}`} />
                </div>
                <div className="image-button-container">
                  <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                    <FontAwesomeIcon icon="eye" />
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button type="button" color="danger" size="sm" onClick={this.clearBlob('content')}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </div>
                {isFullScreen && (
                  <Lightbox
                    mainSrc={`data:${contentContentType};base64,${content}`}
                    onCloseRequest={() => this.setState({ isFullScreen: false })}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm="10">
            <FormGroup>
              <Row>
                <Col sm="6" className="text-left">
                  <Button type="button" onClick={this.handlePrevious} className="font-bold btn-rounded" color="info">
                    &nbsp; &nbsp;Back&nbsp; &nbsp;
                  </Button>
                </Col>
                <Col sm="6" className="text-right">
                  <Button type="submit" className="btn btn-success btn-rounded font-bold">
                    &nbsp; &nbsp; SAVE &nbsp; &nbsp;
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  idTypes: storeState.idType.entities,
  account: storeState.authentication.account,
  kYCEntity: storeState.kYC.entity
});

const mapDispatchToProps = {
  getIdTypes,
  setBlob,
  setData,
  updateStateEntity,
  setAttachmentData
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentIDForm);
