import { Moment } from 'moment';

export interface ICoverType {
  id?: number;
  name?: string;
  brokerCommission?: number;
  description?: string;
  riskClassId?: number;
  startDate?: Moment;
  endDate?: Moment;
  companyId?: number;
  productTypeName?: string;
  productTypeId?: number;
}

export const defaultValue: Readonly<ICoverType> = {};
