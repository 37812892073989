import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './kyc.reducer';
import { IKYC } from 'app/shared/model/kyc.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IKYCDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class KYCDetail extends React.Component<IKYCDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  handleBack() {
    this.props.history.goBack();
  }

  render() {
    const { kYCEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            KYC [<b>{kYCEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="kycType">Kyc Type</span>
            </dt>
            <dd>{kYCEntity.kycType}</dd>
            <dt>
              <span id="generalKYCType">General KYC Type</span>
            </dt>
            <dd>{kYCEntity.generalKYCType}</dd>
            <dt>
              <span id="customKYCType">Custom KYC Type</span>
            </dt>
            <dd>{kYCEntity.customKYCType}</dd>
            <dt>
              <span id="motorKYCType">Motor KYC Type</span>
            </dt>
            <dd>{kYCEntity.motorKYCType}</dd>
            <dt>
              <span id="expiryDate">Expiry Date</span>
            </dt>
            <dd>
              <TextFormat value={kYCEntity.expiryDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="make">Make</span>
            </dt>
            <dd>{kYCEntity.make}</dd>
            <dt>
              <span id="model">Model</span>
            </dt>
            <dd>{kYCEntity.model}</dd>
            <dt>
              <span id="year">Year</span>
            </dt>
            <dd>{kYCEntity.year}</dd>
            <dt>
              <span id="chasisNumber">Chasis Number</span>
            </dt>
            <dd>{kYCEntity.chasisNumber}</dd>
            <dt>
              <span id="engineNumber">Engine Number</span>
            </dt>
            <dd>{kYCEntity.engineNumber}</dd>
            <dt>
              <span id="color">Color</span>
            </dt>
            <dd>{kYCEntity.color}</dd>
            <dt>
              <span id="registration">Registration</span>
            </dt>
            <dd>{kYCEntity.registration}</dd>
            <dt>
              <span id="valueOfCar">Value Of Car</span>
            </dt>
            <dd>{kYCEntity.valueOfCar}</dd>
            <dt>
              <span id="dateOfValuation">Date Of Valuation</span>
            </dt>
            <dd>
              <TextFormat value={kYCEntity.dateOfValuation} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="valuationCenter">Valuation Center</span>
            </dt>
            <dd>{kYCEntity.valuationCenter}</dd>
            <dt>
              <span id="carBodyType">Car Body Type</span>
            </dt>
            <dd>{kYCEntity.carBodyType}</dd>
            <dt>Client</dt>
            <dd>{kYCEntity.clientId ? kYCEntity.clientId : ''}</dd>
            <dt>Risk Class</dt>
            <dd>{kYCEntity.riskClassId ? kYCEntity.riskClassId : ''}</dd>
            <dt>Attachment</dt>
            <dd>{kYCEntity.attachmentId ? kYCEntity.attachmentId : ''}</dd>
          </dl>
          <Button color="info" onCLick={this.handleBack}>
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/kyc/${kYCEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ kYC }: IRootState) => ({
  kYCEntity: kYC.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCDetail);
