import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, Button, Row, Col, CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'app/shared/common/dataTable';
import ReactToPrint from 'react-to-print';
import profilephoto from '../../../assets/images/users/1.jpg';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { sendAllQuotes, selectQuoteItem, unSelectQuoteItem } from 'app/entities/quote/quote.reducer';

export interface IQuoteProps {
  jumpToStep;
  quotes;
  cart;
  match;
  sendAllQuotes;
  selectQuoteItem;
  unSelectQuoteItem;
}

class QuoteResultStep extends React.Component<IQuoteProps, any> {
  private componentRef = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBack = () => {
    const { jumpToStep } = this.props;
    jumpToStep(1);
  };

  handleNext = () => {
    const { jumpToStep } = this.props;
    if (this.props.cart.length > 0) {
      jumpToStep(3);
    }
  };

  private addToCart(e, quote) {
    if (!e.target.checked) {
      this.props.unSelectQuoteItem(quote.id);
    } else {
      this.props.selectQuoteItem(quote);
    }
  }

  getCurrentLogo = quote => {
    const { logoFile } = quote;
    if (logoFile && logoFile.content) {
      return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
    }
    return null;
  };

  sendAllQuotes = () => {
    this.props.quotes.length > 0 && this.props.sendAllQuotes(this.props.quotes[0]);
  };

  render() {
    const { match, quotes } = this.props;
    const quoteRef = quotes.find(q => !q.underwriterId) || {};
    const tableData = quotes
      .filter(q => q.underwriterId)
      .map(prop => ({
        ...prop,
        logo: (
          <div className="media">
            {this.getCurrentLogo(prop) && (
              <img width="55" src={this.getCurrentLogo(prop)} alt="Generic placeholder image" className="img-fluid mr-2 media-object" />
            )}
          </div>
        ),
        rate: prop.rate ? `${prop.rate}%` : '0.0%',
        premiumPayable: `Ksh. ${String(prop.premiumPayable).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`,
        benefits: (
          <div className="link-container">
            <Link to={`/entity/quote/add-benefit/${prop.id}?wizard=2`} className="link-button">
              <FontAwesomeIcon icon="plus" />
            </Link>
            <Link to={`/entity/quote/add-benefit/${prop.id}?wizard=2`} className="link-button">
              <FontAwesomeIcon icon="eye" />
            </Link>
          </div>
        ),
        select: (
          <div className="text-center">
            <div className="checkbox">
              <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} />
            </div>
          </div>
        ),
        actions: (
          <div className="action-container">
            <div className="checkbox">
              <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} onChange={e => this.addToCart(e, prop)} />
            </div>
            <div onClick={e => this.addToCart(e, prop)} className="link-button">
              <i className=" fa fa-cart-plus " />
            </div>
          </div>
        )
      }));
    return (
      <div className="step step3">
        <Row className="justify-content-md-center">
          <Col lg={12}>
            <Row className="justify-content-center mb-5">
              <Col sm={6} className="pl-5 pr-5">
                <p className="text-center font-18">
                  Find Your Quotes Below for <b>{`${quoteRef.coverTypeName} Cover`}</b> for a Sum Insured of Ksh.{' '}
                  <b>{String(quoteRef.sumInsured).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}</b>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="actions-container">
                  <Button to="/" className="icon-link-btn">
                    <i className="mdi mdi-file-pdf" />
                  </Button>
                  <Button className="icon-link-btn" onClick={() => this.sendAllQuotes()}>
                    <i className="mdi mdi-email-outline" />
                  </Button>
                  <ReactToPrint
                    trigger={() => (
                      <Button className="icon-link-btn">
                        <i className="mdi mdi-printer" />
                      </Button>
                    )}
                    content={() => this.componentRef.current}
                  />
                  <Button to="/" className="icon-link-btn">
                    <i className="mdi mdi-swap-horizontal" />
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="table-container" ref={this.componentRef}>
              <DataTable
                columns={[
                  {
                    Header: 'UNDERWRITER',
                    accessor: 'logo',
                    width: 250,
                    resizable: false,
                    headerStyle: { textAlign: 'left' },
                    style: { display: 'flex', alignItems: 'center' },
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: (
                      <div className="header-container">
                        <span>PRODUCT</span>
                        <FontAwesomeIcon icon="sort" />
                      </div>
                    ),
                    accessor: 'underwriterName',
                    headerStyle: { textAlign: 'left' },
                    style: { display: 'flex', alignItems: 'center' }
                  },
                  {
                    Header: (
                      <div className="header-container">
                        <span>PREMIUM</span>
                        <FontAwesomeIcon icon="sort" />
                      </div>
                    ),
                    accessor: 'premiumPayable',
                    headerStyle: { textAlign: 'left' },
                    style: { display: 'flex', alignItems: 'center' }
                  },
                  {
                    Header: (
                      <div className="header-container">
                        <span>RATE</span>
                        <FontAwesomeIcon icon="sort" />
                      </div>
                    ),
                    accessor: 'rate',
                    width: 100,
                    style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                  },
                  {
                    Header: 'BENEFITS',
                    accessor: 'benefits',
                    width: 130,
                    style: { display: 'flex', alignItems: 'center' },
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'ACTIONS',
                    accessor: 'actions',
                    sortable: false,
                    filterable: false,
                    style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                  }
                ]}
                manual
                defaultPageSize={5}
                pages={1}
                showPaginationBottom={false}
                tableData={tableData}
              />
            </div>
          </Col>
          <Col lg={9}>
            <FormGroup className="mt-4">
              <Row>
                <Col sm="6">
                  <Button type="button" color="info" onClick={this.handleBack}>
                    <span className="d-none d-md-inline">Back</span>
                  </Button>
                </Col>
                <Col sm="6" className="text-right">
                  <Button className="btn btn-success" onClick={this.handleNext}>
                    NEXT
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  quotes: storeState.quote.entities,
  cart: storeState.quote.cart
});

const mapDispatchToProps = {
  sendAllQuotes,
  selectQuoteItem,
  unSelectQuoteItem
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteResultStep);
