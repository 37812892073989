import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { Button, Card, CardBody, CardTitle, CustomInput, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { getEntity as getQuote, updateEntity as updateQuote } from 'app/entities/quote/quote.reducer';
import { getEntities as getPayments } from 'app/entities/direct-payment/direct-payment.reducer';
import {
  createEntity as createClientPolicy,
  getEntities as getClientPolicies,
  getEntity as getClientPolicy
} from 'app/entities/client-policy/client-policy.reducer';
import {
  createEntity as createPolicyPayment,
  getEntities as getClientPolicyPayments
} from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import { createEntity as createPolicy } from 'app/entities/policy/policy.reducer';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { APP_LOCAL_DATETIME_FORMAT, PAYMENT_METHODS as paymentMethods, PAYMENT_SEARCH_PARAMS } from 'app/config/constants';
import { PaymentStatus } from 'app/shared/model/client-policy.model';
import { Status } from 'app/shared/model/policy.model';
import moment from 'moment';
import { Platform, TransactionStatus } from 'app/shared/model/client-policy-payment.model';
import DataTable from 'app/shared/common/dataTable';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IQuoteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  jumpToStep;
}

export interface IClientDetailsState extends IPaginationBaseState {
  totalAmountPaid: number;
  balance: number;
  idsadditionalBenefits: any[];
  underwriterId: string;
  clientId: string;
  riskClassId: bigint;
  coverTypeId: bigint;
  country: string;
  mobile: string;
  categoryId: number;
  clientPolicyId: number;
  searchParam: string;
}

class ClientPaymentStep extends React.Component<IQuoteProps, IClientDetailsState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      itemsPerPage: ITEMS_PER_PAGE,
      sort: 'recordedAt',
      idsadditionalBenefits: [],
      underwriterId: '0',
      clientId: '0',
      riskClassId: null,
      coverTypeId: null,
      totalAmountPaid: null,
      balance: this.props.quote.premiumPayable,
      country: '',
      mobile: '',
      categoryId: null,
      clientPolicyId: null,
      searchParam: ''
    };
    this.handleBack = this.handleBack.bind(this);
    this.fetchPaymentData = this.fetchPaymentData.bind(this);
  }

  onCountry = country => {
    this.setState({ country });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handlePolicyChange = event => {
    event.persist();
    if (!isNaN(Number(event.target.value))) {
      const clientPolicyId = Number(event.target.value);
      this.setState({ clientPolicyId });
      const policyResult = Promise.resolve(
        this.props.getClientPolicyPayments(0, 2000, `,&companyId=${this.props.account.companyId}&clientPolicyId=${clientPolicyId}`)
      );
      policyResult.then(res => {
        const { clientPolicyPayments } = this.props;
        const totalAmountPaid =
          clientPolicyPayments && clientPolicyPayments.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
        const clientPolicy = this.props.clientPolicies.find(p => p.id === clientPolicyId);
        this.setState({ totalAmountPaid, balance: clientPolicy.premiumPayable - totalAmountPaid });
      });
    } else {
      this.setState({ totalAmountPaid: 0, balance: 0 });
    }
  };

  handleCategoryChange = categoryId => {
    this.setState({ categoryId: categoryId.target.value });
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updatePaymentSuccess !== this.props.updatePaymentSuccess && nextProps.updatePaymentSuccess) {
      this.props.jumpToStep(2);
    }
  }

  componentDidMount() {
    this.props.getClientPolicies(0, 2000, `,&companyId=${this.props.account.companyId}&clientId=${this.props.client.id}`);
    this.props.getIdTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  fetchPaymentData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { account } = this.props;
    this.props.getPayments(
      state.page,
      state.pageSize,
      `${sort || 'recordedAt'},${order || 'desc'}&companyId=${this.props.account.companyId}&${filtered.join('&')}`
    );
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalPayments } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalPayments / itemsPerPage);
    const modulo = totalPayments % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  handleSubmit = async (event, errors, values) => {
    const { quote, clientPolicies, client } = this.props;
    const policyData = {
      name: `${quote.coverTypeName} ${quote.riskClassName} ${quote.registration}`,
      premiumPayable: quote.premiumPayable,
      status: Status.INACTIVE,
      coverTypeId: quote.coverTypeId,
      underwriterId: quote.underwriterId,
      companyId: this.props.account.companyId
    };
    if (clientPolicies.length) {
      if (values.clientPolicyId) {
        const clientPolicyPayment = {
          ...values,
          payDate: new Date(),
          deviceId: null,
          ipAddress: quote.userIp,
          transactionStatus: TransactionStatus.SUCCESS,
          platform: Platform.WEB,
          isIPF: false,
          quoteId: quote.id,
          clientPolicyId: clientPolicies.find(p => p.id === Number(values.clientPolicyId)).id,
          clientPolicyName: clientPolicies.find(p => p.id === Number(values.clientPolicyId)).policyName,
          clientName: `${client.firstName} ${client.middleName} ${client.lastName}`,
          companyId: this.props.account.companyId
        };
        this.props.getClientPolicy(values.clientPolicyId);
        const policyResult = Promise.resolve(
          this.props.getClientPolicyPayments(0, 2000, `,&companyId=${this.props.account.companyId}&clientPolicyId=${values.clientPolicyId}`)
        );
        policyResult.then(res => {
          const amountPaid =
            this.props.clientPolicyPayments &&
            this.props.clientPolicyPayments.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
          if (values.amount) {
            const totalAmountPaid = values.account + amountPaid;
            this.props.createPolicyPayment(clientPolicyPayment);
            if (totalAmountPaid >= this.props.quote.premiumPayable) {
              const quoteData = {
                ...this.props.quote,
                currentStep: 5
              };
              this.props.updateQuote(quoteData);
            }
          } else {
            this.props.jumpToStep(2);
          }
        });
      } else {
        if (errors.length === 0) {
          this.createPolicyPayment(policyData, values);
        }
      }
    } else {
      if (errors.length === 0) {
        this.createPolicyPayment(policyData, values);
      }
    }
  };

  createPolicyPayment = (policyData, values) => {
    const { quote, client } = this.props;
    const policyResult = Promise.resolve(this.props.createPolicy(policyData));
    policyResult.then(res => {
      const { policy } = this.props;
      const clientPolicyEntity = {
        policyDate: moment(),
        invoiceNo: null,
        startDate: moment(),
        endDate: moment(),
        premiumPayable: quote.premiumPayable,
        openPayable: 20,
        paymentStatus: values.balance < 1 ? PaymentStatus.PAID : PaymentStatus.PARTIAL,
        clientId: client.id,
        policyId: policy.id,
        policyName: `${quote.coverTypeName} ${quote.riskClassName} ${quote.registration}`,
        clientName: `${client.firstName} ${client.middleName} ${client.lastName}`,
        companyId: this.props.account.companyId
      };
      const clientPolicyResult = Promise.resolve(this.props.createClientPolicy(clientPolicyEntity));
      clientPolicyResult.then(resp => {
        const { clientPolicy } = this.props;
        const clientPolicyPayment = {
          ...values,
          payDate: new Date(),
          deviceId: null,
          ipAddress: quote.userIp,
          transactionStatus: TransactionStatus.SUCCESS,
          platform: Platform.WEB,
          isIPF: false,
          quoteId: quote.id,
          clientPolicyId: clientPolicy.id,
          clientPolicyName: clientPolicy.policyName,
          clientName: clientPolicy.clientName,
          companyId: this.props.account.companyId
        };
        this.props.createPolicyPayment(clientPolicyPayment);
        if (clientPolicyEntity.paymentStatus === PaymentStatus.PAID) {
          const quoteData = {
            ...this.props.quote,
            currentStep: 5
          };
          this.props.updateQuote(quoteData);
        }
        this.props.getClientPolicyPayments(0, 2000, `,&companyId=${this.props.account.companyId}&clientPolicyId=${clientPolicy.id}`);
      });
    });
  };

  handleOnselectPayment = (e, payment) => {
    if (!e.target.checked) {
      this.setState({ totalAmountPaid: this.state.totalAmountPaid - Number(payment.transAmount) });
    } else {
      this.setState({ totalAmountPaid: this.state.totalAmountPaid + Number(payment.transAmount) });
    }
  };

  handleAmountChange = e => {
    this.setState({ balance: Number(this.props.quote.premiumPayable) - Number(e.target.value) });
  };

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(0);
  }

  handleSearchParam = e => {
    // console.log(e.target.value);
    // const param = e.target.value;
    // indexOf(PAYMENT_SEARCH_PARAMS, {title: param}) === 0 && param
    //     .toLowerCase()
    //     .replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) {
    //       return c.toUpperCase();
    //     });
    // e.target.value.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
    //   .replace (/[-_]+(.)/g, (_, c) => '' + c.toUpperCase()) // First char after each -/_
    // const searchParam = e.target.value && e.target.value.toLowerCase().replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
    //   .replace(/[-_]+(.)/g, (_, c) => '' + c.toUpperCase());
    this.setState({
      searchParam: e.target.value
    });
  };

  handlePaymentSearch = e => {
    this.props.getPayments(0, 10, `,&companyId=&${this.state.searchParam}=${e.target.value}`);
  };

  render() {
    const { balance, totalAmountPaid } = this.state;
    const { clientPolicies, updating, paymentCallbacks, loadingPaymentCallbacks, quote } = this.props;
    const tableData = paymentCallbacks.map(prop => ({
      ...prop,
      clientName: <span>{`${prop.FirstName} ${prop.LastName}`}</span>,
      select: (
        <div className="text-center">
          <div className="checkbox">
            <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} />
          </div>
        </div>
      ),
      actions: (
        <div className="action-container">
          <div className="checkbox">
            <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} onChange={e => this.handleOnselectPayment(e, prop)} />
          </div>
        </div>
      )
    }));
    return (
      <Card className="mt-5">
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          Client Payment Details
        </CardTitle>
        <CardBody className="pr-0 pl-0">
          <AvForm onSubmit={this.handleSubmit}>
            <Row className="pl-3 pr-3">
              <Col md="12">
                <h4 className="font-medium mb-4">Payment Details</h4>
              </Col>
            </Row>
            <Row className="mt-3 mb-3 pl-5 pr-5">
              <Col md={4}>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="payment-search-field">
                      Search By
                    </Label>
                    <Col sm="6">
                      <AvInput
                        id="payment-search-field"
                        onChange={e => this.handleSearchParam(e)}
                        type="select"
                        name="paymentSearchParam"
                        required
                      >
                        {PAYMENT_SEARCH_PARAMS.map(p => (
                          <option value={p.value} key={p.title}>
                            {p.title}
                          </option>
                        ))}
                      </AvInput>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col sm="6">
                <AvGroup>
                  <AvField
                    id="paymentConfirmationNumber"
                    type="text"
                    className="form-control"
                    name="paymentConfirmationNumber"
                    onChange={this.handlePaymentSearch}
                    required
                  />
                </AvGroup>
              </Col>
            </Row>
            <Row className="pl-3 pr-3">
              <Col md="6">
                <AvGroup>
                  <Row>
                    <Label sm="3" for="payment-method">
                      Payment Method
                    </Label>
                    <Col sm="8">
                      <AvInput id="payment-method" type="select" name="paymentMethod" required>
                        <option>Select a Payment Method</option>
                        {paymentMethods
                          ? paymentMethods.map(otherEntity => (
                              <option value={otherEntity} key={otherEntity}>
                                {otherEntity}
                              </option>
                            ))
                          : null}
                      </AvInput>
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="amountPaid">
                      Amount Paid
                    </Label>
                    <Col sm="8">
                      <AvField id="amountPaid" type="number" className="form-control" name="amount" onChange={this.handleAmountChange} />
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="paymentConfirmationNumber">
                      Confirmation Number
                    </Label>
                    <Col sm="8">
                      <AvField
                        id="paymentConfirmationNumber"
                        type="text"
                        className="form-control"
                        name="paymentConfirmationNumber"
                        required
                      />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                {clientPolicies.length ? (
                  <AvGroup>
                    <Row>
                      <Label sm="3" for="client-policy-id">
                        Client Policy
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="client-policy-id"
                          type="select"
                          name="clientPolicyId"
                          onChange={policyId => this.handlePolicyChange(policyId)}
                          required
                        >
                          <option>New Policy</option>
                          {clientPolicies.length
                            ? clientPolicies.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.policyName}
                                </option>
                              ))
                            : null}
                        </AvInput>
                      </Col>
                    </Row>
                  </AvGroup>
                ) : (
                  <div />
                )}
                <AvGroup>
                  <Row>
                    <Label sm="3" for="totalAmountPaid">
                      Total Amount Paid
                    </Label>
                    <Col sm="8  ">
                      <AvField
                        id="totalAmountPaid"
                        value={totalAmountPaid || '0'}
                        type="text"
                        className="form-control"
                        name="totalAmountPaid"
                        disabled
                      />
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Row>
                    <Label sm="3" for="balance">
                      Amount Left
                    </Label>
                    <Col sm="8  ">
                      <AvField
                        id="balance"
                        value={quote.premiumPayable - balance || 0}
                        type="text"
                        className="form-control"
                        name="balance"
                        disabled
                      />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row className="pl-3 pr-5 mt-4 mb-5">
              <Col md={12}>
                <DataTable
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'clientName',
                      width: 250,
                      resizable: false,
                      headerStyle: { textAlign: 'left' },
                      style: { display: 'flex', alignItems: 'center' },
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: (
                        <div className="header-container">
                          <span>Payment Id</span>
                          <FontAwesomeIcon icon="sort" />
                        </div>
                      ),
                      accessor: 'paymentId',
                      headerStyle: { textAlign: 'left' },
                      style: { display: 'flex', alignItems: 'center' }
                    },
                    {
                      Header: (
                        <div className="header-container">
                          <span>Mobile</span>
                          <FontAwesomeIcon icon="sort" />
                        </div>
                      ),
                      accessor: 'msisdn',
                      headerStyle: { textAlign: 'left' },
                      style: { display: 'flex', alignItems: 'center' }
                    },
                    {
                      Header: (
                        <div className="header-container">
                          <span>Trans Amount</span>
                          <FontAwesomeIcon icon="sort" />
                        </div>
                      ),
                      accessor: 'transAmount',
                      headerStyle: { textAlign: 'left' },
                      style: { display: 'flex', alignItems: 'center' }
                    },
                    {
                      Header: 'Business ShortCode',
                      accessor: 'businessShortCode',
                      // width: 100,
                      style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                    },
                    {
                      Header: (
                        <div className="header-container">
                          <span>Trans ID</span>
                          <FontAwesomeIcon icon="sort" />
                        </div>
                      ),
                      accessor: 'transID',
                      headerStyle: { textAlign: 'left' },
                      style: { display: 'flex', alignItems: 'center' }
                    },
                    {
                      Header: (
                        <div className="header-container">
                          <span>Trans Time</span>
                          <FontAwesomeIcon icon="sort" />
                        </div>
                      ),
                      accessor: 'transTime',
                      headerStyle: { textAlign: 'left' },
                      style: { display: 'flex', alignItems: 'center' }
                    },
                    {
                      Header: (
                        <div className="header-container">
                          <span>Trans Type</span>
                          <FontAwesomeIcon icon="sort" />
                        </div>
                      ),
                      accessor: 'transactionType',
                      headerStyle: { textAlign: 'left' },
                      style: { display: 'flex', alignItems: 'center' }
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  manual
                  defaultPageSize={5}
                  pages={this.getMaxPage()}
                  onFetchData={this.fetchPaymentData}
                  loading={loadingPaymentCallbacks}
                  showPaginationBottom
                  tableData={tableData}
                />
              </Col>
            </Row>
            <Row className="pl-3 pr-5 mt-4 mb-5">
              <Col md="12 text-right">
                <Button tag={Link} id="cancel-save" onClick={this.handleBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp; &nbsp;
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> NEXT
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskCategories: storeState.riskCategory.entities,
  account: storeState.authentication.account,
  quote: storeState.quote.entity,
  client: storeState.client.entity,
  policy: storeState.policy.entity,
  totalPayments: storeState.paymentCallbacks.totalItems,
  loadingPaymentCallbacks: storeState.paymentCallbacks.loading,
  paymentCallbacks: storeState.paymentCallbacks.entities,
  clientPolicies: storeState.clientPolicy.entities,
  clientPolicyPayments: storeState.clientPolicyPayment.entities,
  clientPolicy: storeState.clientPolicy.entity,
  updatePaymentSuccess: storeState.clientPolicyPayment.updateSuccess,
  updateSuccess: storeState.client.updateSuccess,
  clientCategories: storeState.clientCategory.entities,
  idTypes: storeState.idType.entities,
  updating: storeState.client.updating
});

const mapDispatchToProps = {
  getQuote,
  getClientCategories,
  getIdTypes,
  getPayments,
  createPolicyPayment,
  getClientPolicies,
  createPolicy,
  createClientPolicy,
  getClientPolicyPayments,
  getClientPolicy,
  updateQuote
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPaymentStep);
