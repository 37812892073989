import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { Line } from 'react-chartjs-2';
// tslint:disable-next-line:no-unused-variable
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getEntities, getSubEntities } from './quote.reducer';
import { getEntity as getCompany } from 'app/entities/company/company.reducer';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
// tslint:disable-next-line:no-unused-variable
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ExportExcel from 'app/shared/common/exportExcel';
import { getReports } from 'app/modules/reports/reducers/reports.reducer';
import { types } from 'sass';
import Number = types.Number;

export interface IQuoteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IQuoteState extends IPaginationBaseState {
  status: string;
  userQuotes: any[];
  startDate: string;
  endDate: string;
  productTypeIds: number[];
  currentPeriod: string;
  expandedRows: object;
  riskCategoryId: string;
  riskClassId: string;
  productTypeId: string;
  reportPeriod: string;
  uptakePeriod: string;
}

const lineData = {
  labels: [1, 2, 3, 4, 5, 6, 7, 8],
  datasets: [
    {
      label: 'Income',
      borderWidth: 1,
      backgroundColor: 'rgba(94,114,228,.1)',
      borderColor: 'rgb(94,114,228)',
      pointBorderColor: 'rgb(94,114,228)',
      pointBackgroundColor: 'rgb(94,114,228)',
      data: [0, 15, 6, 11, 25, 9, 18, 24]
    },
    {
      label: 'Outcome',
      borderWidth: 1,
      backgroundColor: 'rgba(79,195,247,.1)',
      borderColor: 'rgb(79,195,247)',
      pointBorderColor: 'rgb(79,195,247)',
      pointBackgroundColor: 'rgb(79,195,247)',
      data: [0, 8, 11, 22, 8, 10, 5, 21]
    }
  ]
};

const getStartDate = () => {
  const startDate = new Date();
  startDate.setDate(1); // Set the date to the first day of the month
  return startDate.toISOString().split('T')[0];
};

export class Quote extends React.Component<IQuoteProps, IQuoteState> {
  private componentRef = React.createRef<HTMLDivElement>();
  private readonly reactTable: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      itemsPerPage: ITEMS_PER_PAGE,
      sort: 'createdAt',
      status: 'ACTIVE',
      expandedRows: {},
      userQuotes: [],
      startDate: getStartDate(),
      riskCategoryId: '',
      riskClassId: '',
      productTypeId: '',
      productTypeIds: [],
      endDate: new Date().toISOString().split('T')[0],
      currentPeriod: 'Month',
      reportPeriod: 'MONTH',
      uptakePeriod: 'MONTH'
    };
    this.fetchData = this.fetchData.bind(this);
    this.reactTable = React.createRef();
  }

  componentWillMount() {
    const { productTypes } = this.props.account;
    this.setState({ productTypeIds: productTypes.map(a => `productTypeIds=${a.id}`) });
  }

  componentDidMount() {
    this.loadInitial();
  }

  async loadInitial() {
    const { account } = this.props;
    const { productTypes, companyId } = account;
    const { uptakePeriod, reportPeriod } = this.state;
    this.props.getReports({ companyId: account.companyId, reportPeriod, uptakePeriod });
    this.props.getProductTypes(0, 20, `,&${productTypes.map(a => `id=${a.id}`).join('&')}&companyId=${companyId}`);
    await this.props.getRiskCategories(0, 200, `,&${productTypes.map(a => `productTypeId=${a.id}`).join('&')}&companyId=${companyId}`);
    this.props.getRiskClasses(0, 200, `,&${this.props.riskCategories.map(a => `riskCategoryId=${a.id}`).join('&')}&companyId=${companyId}`);
  }

  fetchData(state) {
    const { startDate, endDate, riskClassId, productTypeIds, status } = this.state;
    const { sorted, pageSize, page } = state;
    const sort = sorted.map(d => d.id).join();
    const order = sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { companyId } = this.props.account;
    this.props.getEntities(
      page || 1,
      pageSize || 10,
      `${sort},${order}&${productTypeIds.join('&')}&riskClassId=${riskClassId}&${
        companyId === 1 ? '' : 'companyId=' + companyId
      }&fromDate=${startDate}&toDate=${endDate}&status=${status || 'ACTIVE'}&${filtered.join('&')}`
    );
    this.setState({ ...state, itemsPerPage: pageSize });
  }

  handleFilter = (event, errors, values) => {
    const { log } = console;
    log(values);
    const currentState = this.reactTable.current.state;
    const { status, productTypeIds } = this.state;
    const { startDate, endDate, riskClassId } = values;
    this.fetchQuotes({
      state: currentState,
      startDate,
      endDate,
      status,
      productTypeIds,
      riskClassId
    });
    this.setState({ endDate, startDate });
  };

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  handleState = status => {
    const currentState = this.reactTable.current.state;
    const { startDate, endDate, productTypeId, riskCategoryId, riskClassId } = this.state;
    const productTypeIds = [];
    productTypeIds.push(productTypeId);
    this.fetchQuotes({ state: currentState, startDate, endDate, status, productTypeIds, riskCategoryId, riskClassId });
    this.setState({ itemsPerPage: currentState.pageSize, status });
  };

  handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    await this.setState(prevState => ({ ...prevState, [name]: value }));
    const currentState = this.reactTable.current.state;
    const { startDate, endDate, productTypeId, riskCategoryId, riskClassId, status } = this.state;
    const productTypeIds = [];
    const { log } = console;
    log(productTypeId);
    productTypeIds.push(productTypeId);
    this.fetchQuotes({ state: currentState, startDate, endDate, status, productTypeIds, riskCategoryId, riskClassId });
  };

  handleChangePeriod = value => {
    // console.log(value);
  };

  fetchQuotes = values => {
    const { account, isAdmin } = this.props;
    const { state, startDate, endDate, productTypeIds, riskClassId, status } = values;
    const pTypeIds = productTypeIds.map(p => 'productTypeIds=' + p);
    const { sorted, page, pageSize } = state;
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const sort = sorted.map(d => d.id).join();
    const order = sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    this.props.getEntities(
      page > 1 ? page : 1,
      pageSize,
      `${sort},${order}${isAdmin ? '' : '&companyId=' + account.companyId}&fromDate=${startDate}&toDate=${endDate}&${pTypeIds.join(
        '&'
      )}&riskClassId=${riskClassId || ''}&status=${status || 'ACTIVE'}&${filtered.join('&')}`
    );
  };

  handleExpand = async (e, rowInfo) => {
    const { createdAt, mobile } = rowInfo.original;
    const { expandedRows } = this.state;
    const { index } = rowInfo;
    if (!expandedRows[index]) {
      try {
        await this.props.getSubEntities(0, 10, `id,desc&createdAt=${createdAt['props']['value']}&mobile=${mobile}`);
        rowInfo.subRows = this.props.subQuotes;
        rowInfo.row.subRows = this.props.subQuotes;
        this.setState({ expandedRows: { [index]: true } });
      } catch (error) {
        console.error('Error fetching subrows:', error);
      }
    } else {
      this.setState({ expandedRows: { [index]: !expandedRows[index] } });
    }
  };

  getPercentageValue = (value: number) => {
    const totalValue = this.props.reports.yesToCall + this.props.reports.noToCall;
    return Math.round((value / totalValue) * 100);
  };

  render() {
    const { loading, loadingSubQuotes, quoteList, subQuotes, match, reports, companyId, productTypes, isPartner, riskClasses } = this.props;
    const { status, expandedRows, startDate, endDate } = this.state;
    const tableData = orderBy(quoteList, 'createdAt', 'desc').map(prop => ({
      ...prop,
      dateId: prop.createdAt,
      name: `${prop.firstName}  ${prop.lastName}`,
      callPermission: prop.callPermission ? 'Yes' : 'No',
      riskCategory: prop.currentStep >= 1 && <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />,
      carDetails: prop.currentStep >= 2 && <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />,
      underwriter: prop.currentStep >= 3 && <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />,
      policyInfo: prop.currentStep >= 4 && <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />,
      payment: prop.currentStep >= 5 && <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />,
      sold: prop.currentStep >= 6 && <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />,
      createdAt: prop.createdAt === null ? '' : <TextFormat type="date" value={`${prop.createdAt}`} format={APP_LOCAL_DATETIME_FORMAT} />,
      date: prop.date === null ? '' : <TextFormat type="date" value={`${prop.date}`} format={APP_LOCAL_DATE_FORMAT} />,
      actions: (
        <div className="text-center">
          {companyId === 1 && (
            <>
              {/*<Button*/}
              {/*  className="ml-1"*/}
              {/*  color="success"*/}
              {/*  size="sm"*/}
              {/*  round="true"*/}
              {/*  icon="true"*/}
              {/*  tag={NavLink}*/}
              {/*  to={`${match.url}/${prop.id}/quote-payment`}*/}
              {/*>*/}
              {/*  <i className="fa fa-edit" />*/}
              {/*  ADD NOTE*/}
              {/*</Button>*/}
              <Button
                className="ml-1"
                color="success"
                size="sm"
                round="true"
                icon="true"
                tag={NavLink}
                to={`${match.url}/${prop.id}/quote-payment`}
              >
                <i className="fa fa-edit" />
                SELL
              </Button>
              <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
                <i className="fa fa-edit" />
              </Button>
              {/*<Button*/}
              {/*  className="ml-1"*/}
              {/*  color="danger"*/}
              {/*  size="sm"*/}
              {/*  round="true"*/}
              {/*  icon="true"*/}
              {/*  tag={ NavLink }*/}
              {/*  to={ `${ match.url }/${ prop.id }/delete` }*/}
              {/*>*/}
              {/*  <i className="fa fa-times"/>*/}
              {/*</Button>*/}
            </>
          )}
          {companyId !== 1 && (
            <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
              <i className="fa fa-eye" />
              View
            </Button>
          )}
        </div>
      )
    }));
    const styles = {
      fontSize: '7px',
      textAlign: 'center'
    };
    return (
      <div className="quote-container" ref={this.componentRef}>
        <Card>
          <CardBody>
            <div className="d-md-flex">
              <div>
                <h4 className="mb-1 card-title">Quotes & Sales Summaries</h4>
                <h6 className="mb-0 mt-0 font-16 font-light card-subtitle">Overview of Current Month</h6>
              </div>
              <div className="ml-auto d-flex no-block align-items-center">
                <div className="dl">
                  <Input type="select" className="custom-select" onChange={event => this.handleChangePeriod(event.target.value)}>
                    <option value="MONTH">Monthly</option>
                    <option value="DAY">Daily</option>
                    <option value="WEEK">Weekly</option>
                    <option value="YEAR">Yearly</option>
                  </Input>
                </div>
              </div>
            </div>
            <Row>
              <Col lg="4" className="mt-4">
                <h1 className="mb-0 mt-4">Ksh. {reports.currentEarnings || 0}</h1>
                <h6 className="font-light text-muted">Current Month Earnings</h6>
                <h3 className="mt-4 mb-0">{reports.currentSales || 0}</h3>
                <h6 className="font-light text-muted">Current Month Sales</h6>
                <a className="btn btn-info my-3 p-3 px-4" href="#">
                  Last Month Summary
                </a>
              </Col>
              <Col lg="8">
                <div className="campaign ct-charts">
                  <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 250 }}>
                    <Line
                      data={lineData}
                      options={{
                        maintainAspectRatio: false,
                        legend: { display: false, labels: { fontFamily: 'Nunito Sans' } },
                        scales: {
                          yAxes: [
                            {
                              stacked: true,
                              gridLines: { display: false },
                              ticks: { fontFamily: 'Nunito Sans' }
                            }
                          ],
                          xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: 'Nunito Sans' } }]
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border-top pt-4 pb-5">
            <Row className="mb-0">
              {/*{*/}
              {/*  !isPartner && (*/}
              {/*    <Col lg="3" md="6">*/}
              {/*      <div className="d-flex align-items-center">*/}
              {/*        <div className="mr-3">*/}
              {/*    <span className="display-5 d-flex text-muted">*/}
              {/*      <i className="mdi mdi-cellphone-iphone"/>*/}
              {/*    </span>*/}
              {/*        </div>*/}
              {/*        <div className="d-flex flex-column justify-content-center">*/}
              {/*          <span>Mobile Money</span>*/}
              {/*          <h4 className="font-bold mb-0">Ksh. 982,112</h4>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </Col>*/}
              {/*  )*/}
              {/*}*/}
              {/*{*/}
              {/*  !isPartner && (*/}
              {/*    <Col lg="3" md="6">*/}
              {/*      <div className="d-flex align-items-center">*/}
              {/*        <div className="mr-3">*/}
              {/*    <span className="display-5 d-flex text-muted">*/}
              {/*      <i className="mdi mdi-credit-card-multiple"/>*/}
              {/*    </span>*/}
              {/*        </div>*/}
              {/*        <div className="d-flex flex-column justify-content-center">*/}
              {/*          <span>Card Payments</span>*/}
              {/*          <h4 className="font-bold mb-0">Ksh. 432,670</h4>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </Col>*/}
              {/*  )*/}
              {/*}*/}
              <Col lg="2" md="4">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-help" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>Quotes</span>
                    <h4 className="font-bold mb-0">{reports.quotes || 0}</h4>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="4">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-cellphone-link" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>Yes To Call</span>
                    <h4 className="font-bold mb-0">{this.getPercentageValue(reports.yesToCall)}%</h4>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="4">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-cellphone-link-off" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>No To Call</span>
                    <h4 className="font-bold mb-0">{this.getPercentageValue(reports.noToCall)}%</h4>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="4">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-credit-card-multiple" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>Invoiced Amount</span>
                    <h4 className="font-bold mb-0">
                      Ksh. {(reports && reports.currentWalletBalance && reports.currentWalletBalance.toFixed(2)) || 0}
                    </h4>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="4">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-account-multiple" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>Users</span>
                    <h4 className="font-bold mb-0">{reports.users || 0}</h4>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="4">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <span className="display-5 d-flex text-muted">
                      <i className="mdi mdi-account-plus" />
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>Clients</span>
                    <h4 className="font-bold mb-0">{reports.clients || 0}</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="pt-5 pb-5 pr-4 pl-4">
            <Row className="align-items-center mb-2">
              {/*<Col md="3">*/}
              {/*  <FormGroup className="mb-0">*/}
              {/*    <Row>*/}
              {/*      <Label sm="5" className="pr-0" for="risk-class-riskCategory">*/}
              {/*        Risk Category*/}
              {/*      </Label>*/}
              {/*      <Col sm="7">*/}
              {/*        <Input*/}
              {/*          id="risk-class-riskCategory"*/}
              {/*          type="select"*/}
              {/*          name="riskCategoryId"*/}
              {/*          required*/}
              {/*          onChange={ event => this.handleChange(event) }*/}
              {/*        >*/}
              {/*          <option value="" key="">*/}
              {/*            { ' ' }*/}
              {/*            Select{ ' ' }*/}
              {/*          </option>*/}
              {/*          { riskCategories*/}
              {/*            ? sortBy(riskCategories, [ 'name' ]).map(otherEntity => (*/}
              {/*              <option value={ otherEntity.id } key={ otherEntity.id }>*/}
              {/*                { otherEntity.name }*/}
              {/*              </option>*/}
              {/*            ))*/}
              {/*            : null }*/}
              {/*        </Input>*/}
              {/*      </Col>*/}
              {/*    </Row>*/}
              {/*  </FormGroup>*/}
              {/*</Col>*/}
              <Col md="4">
                <FormGroup className="mb-0">
                  <Row>
                    <Label sm="3" className="pr-0" for="quote-productType">
                      Product
                    </Label>
                    <Col sm="7">
                      <Input
                        id="quote-productType"
                        type="select"
                        name="productTypeId"
                        required
                        onChange={event => this.handleChange(event)}
                      >
                        <option value="" key="">
                          {' '}
                          Select{' '}
                        </option>
                        {productTypes
                          ? sortBy(productTypes, ['name']).map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup className="mb-0">
                  <Row>
                    <Label sm="3" className="pr-0" for="risk-class-riskCategory">
                      Risk Class
                    </Label>
                    <Col sm="7">
                      <Input id="quote-risk-class" type="select" name="riskClassId" required onChange={event => this.handleChange(event)}>
                        <option value="" key="">
                          {' '}
                          Select{' '}
                        </option>
                        {riskClasses
                          ? sortBy(riskClasses, ['name']).map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="4">
                <div className="d-flex justify-content-end">
                  <Button color={status === 'ACTIVE' ? 'success' : 'primary'} onClick={() => this.handleState('ACTIVE')}>
                    <i className="mdi mdi-check-circle-outline" />
                    ACTIVE
                  </Button>
                  &nbsp;
                  <Button color={status === 'INACTIVE' ? 'success' : 'primary'} onClick={() => this.handleState('INACTIVE')}>
                    <i className="mdi mdi-close" />
                    INACTIVE
                  </Button>
                  &nbsp;
                  <Button color={status === 'SOLD' ? 'success' : 'primary'} onClick={() => this.handleState('SOLD')}>
                    <i className="mdi mdi-basket" />
                    SOLD
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mb-2 mt-3">
              <Col md="6">
                <AvForm onSubmit={this.handleFilter}>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="start-date">
                            From
                          </Label>
                          <Col sm="8">
                            <AvField id="start-date" type="date" className="form-control" value={startDate} name="startDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="3" for="end-date">
                            To
                          </Label>
                          <Col sm="9">
                            <AvField id="end-date" type="date" className="form-control" value={endDate} name="endDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md={2}>
                      <Button color="primary" type="submit">
                        <i className="mdi mdi-search-web" />
                        Search
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
              <Col md="3">
                <div className="d-flex justify-content-center">
                  <Button className="icon-link-btn">
                    <i className="mdi mdi-file-pdf" />
                  </Button>
                  <Button className="icon-link-btn">
                    <i className="mdi mdi-email-outline" />
                  </Button>
                  <ReactToPrint
                    trigger={() => (
                      <Button className="icon-link-btn">
                        <i className="mdi mdi-printer" />
                      </Button>
                    )}
                    content={() => this.componentRef.current}
                  />
                </div>
              </Col>
              <Col md="3">
                <div className="d-flex justify-content-end">
                  <ExportExcel
                    listData={quoteList}
                    name={'Quotes'}
                    options={{
                      fields: [
                        'id',
                        'createdAt',
                        'firstName',
                        'lastName',
                        'email',
                        'mobile',
                        'sumInsured',
                        'registration',
                        'currentStep',
                        'riskClass',
                        'quoteSource',
                        'coverType',
                        'carModel',
                        'carMake',
                        'underwriter'
                      ]
                    }}
                  />
                </div>
              </Col>
            </Row>
            <ReactTable
              columns={[
                {
                  Header: (
                    <div className="header-container">
                      <i className="mdi mdi-plus" />
                    </div>
                  ),
                  width: 40,
                  show: companyId === 1,
                  expander: true,
                  accessor: 'expander',
                  Expander: ({ isExpanded, ...rest }) => (
                    <div onClick={e => this.handleExpand(e, rest)}>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
                  ),
                  style: {
                    cursor: 'pointer',
                    fontSize: 16,
                    padding: '12px',
                    textAlign: 'center',
                    userSelect: 'none'
                  },
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>Mobile</span>
                      <i className="mdi mdi-swap-vertical" />
                    </div>
                  ),
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  accessor: 'mobile',
                  resizable: true
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>Quote Date</span>
                      <i className="mdi mdi-swap-vertical" />
                    </div>
                  ),
                  accessor: 'createdAt',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  resizable: true
                },
                {
                  Header: () => (
                    <div className="header-container">
                      <span>Source</span>
                    </div>
                  ),
                  accessor: 'quoteSource',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  show: !isPartner
                },
                {
                  Header: () => (
                    <div className="header-container">
                      <span>Call Permission</span>
                    </div>
                  ),
                  accessor: 'callPermission',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>
                },
                {
                  Header: () => (
                    <div className="header-container">
                      <span>Drop Of Page</span>
                    </div>
                  ),
                  width: 78,
                  accessor: 'currentStep',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>Premium</span>
                    </div>
                  ),
                  width: 80,
                  accessor: 'premiumPayable',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>Insurer</span>
                    </div>
                  ),
                  width: 90,
                  accessor: 'underwriterName',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>Risk Class</span>
                    </div>
                  ),
                  width: 80,
                  accessor: 'riskClassName',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>CoverType</span>
                    </div>
                  ),
                  width: 80,
                  accessor: 'coverTypeName',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '11px' }}>{row.value}</div>,
                  show: !isPartner,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>Agent Code</span>
                    </div>
                  ),
                  width: 78,
                  accessor: 'agentCode',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: (
                    <div className="header-container">
                      <span>Sold</span>
                    </div>
                  ),
                  width: 78,
                  accessor: 'sold',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              ref={this.reactTable}
              manual
              className="-striped -highlight"
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              expanded={expandedRows}
              showPaginationBottom
              data={tableData}
              filterable
              style={styles}
              SubComponent={() => (
                <div style={{ padding: '0px' }}>
                  <ReactTable
                    data={subQuotes.map(prop => ({
                      ...prop,
                      id: `${prop.firstName} ${prop.lastName}`,
                      riskCategory: prop.currentStep >= 1 && (
                        <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />
                      ),
                      carDetails: prop.currentStep >= 2 && (
                        <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />
                      ),
                      underwriter: prop.currentStep >= 3 && (
                        <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />
                      ),
                      policyInfo: prop.currentStep >= 4 && (
                        <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />
                      ),
                      payment: prop.currentStep >= 5 && (
                        <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />
                      ),
                      sold: prop.currentStep >= 6 && (
                        <i style={{ color: '#008000', textShadow: '1px 1px 1px #ccc' }} className="fa fa-check" />
                      ),
                      createdAt:
                        prop.createdAt === null ? (
                          ''
                        ) : (
                          <TextFormat type="date" value={`${prop.createdAt}`} format={APP_LOCAL_DATETIME_FORMAT} />
                        ),
                      date: prop.date === null ? '' : <TextFormat type="date" value={`${prop.date}`} format={APP_LOCAL_DATE_FORMAT} />,
                      actions: (
                        <div className="text-center">
                          {companyId !== 1 && (
                            <Button
                              className="ml-1"
                              color="inverse"
                              size="sm"
                              round="true"
                              icon="true"
                              tag={NavLink}
                              to={`${match.url}/${prop.id}`}
                            >
                              <i className="fa fa-eye" />
                              View
                            </Button>
                          )}
                          {companyId === 1 && (
                            <>
                              <Button
                                className="ml-1"
                                color="success"
                                size="sm"
                                round="true"
                                icon="true"
                                tag={NavLink}
                                to={`${match.url}/${prop.id}/sell-quote`}
                              >
                                <i className="fa fa-edit" />
                                SELL
                              </Button>
                              <Button
                                className="ml-1"
                                color="inverse"
                                size="sm"
                                round="true"
                                icon="true"
                                tag={NavLink}
                                to={`${match.url}/${prop.id}`}
                              >
                                <i className="fa fa-edit" />
                              </Button>
                              {/*<Button*/}
                              {/*  className="ml-1"*/}
                              {/*  color="danger"*/}
                              {/*  size="sm"*/}
                              {/*  round="true"*/}
                              {/*  icon="true"*/}
                              {/*  tag={ NavLink }*/}
                              {/*  to={ `${ match.url }/${ prop.id }/delete` }*/}
                              {/*>*/}
                              {/*  <i className="fa fa-times"/>*/}
                              {/*</Button>*/}
                            </>
                          )}
                        </div>
                      )
                    }))}
                    columns={[
                      {
                        Header: (
                          <div className="header-container">
                            <span>Mobile</span>
                            <i className="mdi mdi-swap-vertical" />
                          </div>
                        ),
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        accessor: 'mobile',
                        resizable: true
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Quote Date</span>
                            <i className="mdi mdi-swap-vertical" />
                          </div>
                        ),
                        accessor: 'createdAt',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        resizable: true
                      },
                      {
                        Header: () => (
                          <div className="header-container">
                            <span>Source</span>
                          </div>
                        ),
                        accessor: 'quoteSource',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        show: !isPartner
                      },
                      {
                        Header: () => (
                          <div className="header-container">
                            <span>Drop Of Page</span>
                          </div>
                        ),
                        width: 78,
                        accessor: 'currentStep',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Premium</span>
                          </div>
                        ),
                        width: 78,
                        accessor: 'premiumPayable',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Insurer</span>
                          </div>
                        ),
                        width: 78,
                        accessor: 'underwriterName',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Risk Class</span>
                          </div>
                        ),
                        width: 78,
                        accessor: 'riskClassName',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>CoverType</span>
                          </div>
                        ),
                        width: 78,
                        accessor: 'coverTypeName',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        show: !isPartner,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Agent Code</span>
                          </div>
                        ),
                        width: 78,
                        accessor: 'agentCode',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Sold</span>
                          </div>
                        ),
                        width: 78,
                        accessor: 'sold',
                        Cell: row => <div style={{ textAlign: 'center', fontSize: '10px' }}>{row.value}</div>,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Actions',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    className="-striped -highlight"
                    defaultPageSize={10}
                    loading={loadingSubQuotes}
                    showPagination
                    filterable={false}
                    style={styles}
                  />
                </div>
              )}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.quote.loading,
  loadingSubQuotes: storeState.quote.loadingSubQuotes,
  summaryLoading: storeState.quote.summaryLoading,
  quotesSummary: storeState.quote.quotesSummary,
  quoteList: storeState.quote.entities,
  subQuotes: storeState.quote.subQuotes,
  riskCategories: storeState.riskCategory.entities,
  riskClasses: storeState.riskClass.entities,
  totalItems: storeState.quote.totalItems,
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER]),
  companyId: storeState.authentication.account.companyId,
  company: storeState.company.entity,
  productTypes: storeState.productType.entities,
  account: storeState.authentication.account,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]),
  reports: storeState.reports.entity
});

const mapDispatchToProps = {
  getEntities,
  getSubEntities,
  getRiskCategories,
  getRiskClasses,
  getProductTypes,
  getCompany,
  getReports
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quote);
