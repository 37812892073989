import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IProductType } from 'app/shared/model/product-type.model';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { IRiskCategory } from 'app/shared/model/risk-category.model';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { IRiskClass } from 'app/shared/model/risk-class.model';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { ICoverType } from 'app/shared/model/cover-type.model';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './standard-rate.reducer';
import { IStandardRate } from 'app/shared/model/standard-rate.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IStandardRateUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IStandardRateUpdateState {
  isNew: boolean;
  productTypeId: string;
  riskCategoryId: string;
  riskClassId: string;
  coverTypeId: string;
}

export class StandardRateUpdate extends React.Component<IStandardRateUpdateProps, IStandardRateUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      productTypeId: '0',
      riskCategoryId: '0',
      riskClassId: '0',
      coverTypeId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    // this.props.getProductTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
    // this.props.getRiskCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getRiskClasses(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getCoverTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { standardRateEntity } = this.props;
      const entity = {
        ...standardRateEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/standard-rate');
  };

  render() {
    const { standardRateEntity, productTypes, riskCategories, riskClasses, coverTypes, loading, updating } = this.props;
    const { isNew } = this.state;
    return (
      <Card>
        <CardBody>
          <Col md="12">
            <Card>
              <CardTitle className="bg-light border-bottom p-3 mb-0">
                <i className="mdi mdi-book mr-2" />
                Create or edit a StandardRate
              </CardTitle>
              <CardBody>
                {loading && <p>Loading...</p>}
                <AvForm model={isNew ? {} : standardRateEntity} onSubmit={this.saveEntity}>
                  <AvGroup>
                    <Row>
                      <Label sm="2" id="rateLabel" for="standard-rate-rate">
                        Rate
                      </Label>
                      <Col sm="10">
                        <AvField id="standard-rate-rate" type="string" className="form-control" name="rate" />
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="2" id="minimumValueLabel" for="standard-rate-minimumValue">
                        Minimum Value
                      </Label>
                      <Col sm="10">
                        <AvField id="standard-rate-minimumValue" type="string" className="form-control" name="minimumValue" />
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="2" id="startActiveDateLabel" for="standard-rate-startActiveDate">
                        Start Active Date
                      </Label>
                      <Col sm="10">
                        <AvField id="standard-rate-startActiveDate" type="date" className="form-control" name="startActiveDate" />
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="2" id="endActiveDateLabel" for="standard-rate-endActiveDate">
                        End Active Date
                      </Label>
                      <Col sm="10">
                        <AvField id="standard-rate-endActiveDate" type="date" className="form-control" name="endActiveDate" />
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="2" id="statusLabel" for="standard-rate-status">
                        Status
                      </Label>
                      <Col sm="10">
                        <AvInput
                          id="standard-rate-status"
                          type="select"
                          className="form-control"
                          name="status"
                          value={(!isNew && standardRateEntity.status) || 'ACTIVE'}
                        >
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="INACTIVE">INACTIVE</option>
                        </AvInput>
                      </Col>
                    </Row>
                  </AvGroup>
                  {/* <AvGroup>
                    <Row>
                      <Label sm="2" for="standard-rate-productType">
                        Product Type
                      </Label>
                      <Col sm="10">
                        <AvInput id="standard-rate-productType" type="select" className="form-control" name="productTypeId">
                          <option value="" key="0" />
                          {productTypes
                            ? productTypes.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="2" for="standard-rate-riskCategory">
                        Risk Category
                      </Label>
                      <Col sm="10">
                        <AvInput id="standard-rate-riskCategory" type="select" className="form-control" name="riskCategoryId">
                          <option value="" key="0" />
                          {riskCategories
                            ? riskCategories.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                      </Col>
                    </Row>
                  </AvGroup> */}
                  <AvGroup>
                    <Row>
                      <Label sm="2" for="standard-rate-riskClass">
                        Risk Class
                      </Label>
                      <Col sm="10">
                        <AvInput id="standard-rate-riskClass" type="select" className="form-control" name="riskClassId" required>
                          <option value="" key="0" />
                          {riskClasses
                            ? riskClasses.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="2" for="standard-rate-coverType">
                        Cover Type
                      </Label>
                      <Col sm="10">
                        <AvInput id="standard-rate-coverType" type="select" className="form-control" name="coverTypeId">
                          <option value="" key="0" />
                          {coverTypes
                            ? coverTypes.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                      </Col>
                    </Row>
                  </AvGroup>
                  <FormGroup>
                    <Row>
                      <Col sm="12" className="text-center">
                        <Button tag={Link} id="cancel-save" to="/entity/standard-rate" replace color="info">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <span className="d-none d-md-inline">Back</span>
                        </Button>
                        &nbsp; &nbsp;
                        <Button type="submit" className="btn btn-success" disabled={updating}>
                          <i className="fa fa-check" /> Save
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  productTypes: storeState.productType.entities,
  riskCategories: storeState.riskCategory.entities,
  riskClasses: storeState.riskClass.entities,
  coverTypes: storeState.coverType.entities,
  standardRateEntity: storeState.standardRate.entity,
  loading: storeState.standardRate.loading,
  updating: storeState.standardRate.updating,
  updateSuccess: storeState.standardRate.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getProductTypes,
  getRiskCategories,
  getRiskClasses,
  getCoverTypes,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardRateUpdate);
