export const enum PaymentType {
  FROM_CLIENT = 'FROM_CLIENT',
  FROM_UNDERWRITER = 'FROM_UNDERWRITER'
}

export const enum DebitStatus {
  DEBITED = 'DEBITED',
  NOT_DEBITED = 'NOT_DEBITED'
}

export const enum RemitStatus {
  REMITTED = 'REMITTED',
  NOT_REMITTED = 'NOT_REMITTED'
}

export interface IPaymentCallback {
  id?: number;
  paymentId?: string;
  TransactionType?: number;
  TransID?: string;
  TransTime?: number;
  TransAmount?: number;
  BusinessShortCode?: number;
  BillRefNumber?: string;
  InvoiceNumber?: string;
  ThirdPartyTransID?: string;
  OrgAccountBalance?: number;
  MSISDN?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  CreatedAt?: string;
  UpdatedAt?: string;
  productTypeId?: number;
  companyId?: number;
  riskClassId?: number;
  riskCategoryId?: number;
  clientId?: number;
  clientPolicyId?: number;
  policyId?: number;
  underwriterId?: number;
  agentCode?: string;
  partial?: boolean;
  paymentType?: PaymentType;
  debitNoteId?: number;
  kYCId?: number;
  invoiceId?: number;
  debitStatus?: DebitStatus;
  remitStatus?: RemitStatus;
}

export const defaultValue: Readonly<IPaymentCallback> = {};
