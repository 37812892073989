import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IRiskCategory } from 'app/shared/model/risk-category.model';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { getEntity, updateEntity, createEntity, reset } from './risk-class.reducer';
import { IRiskClass } from 'app/shared/model/risk-class.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IRiskClassUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IRiskClassUpdateState {
  isNew: boolean;
  riskCategoryId: string;
  entity: IRiskClass;
}

export class RiskClassUpdate extends React.Component<IRiskClassUpdateProps, IRiskClassUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      riskCategoryId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      entity: {}
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getRiskCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.riskClassEntity.id) {
      this.setState({ entity: newProps.riskClassEntity });
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { riskClassEntity } = this.props;
      const entity = {
        ...riskClassEntity,
        ...values,
        companyId: this.props.account.companyId
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/risk-class');
  };

  updateStateEntity = event => {
    if (!(event && event.target)) {
      return;
    }
    let val: any;

    if (event.target.type === 'checkbox') {
      val = event.target.checked;
    } else if (event.target.value === '') {
      val = null;
    } else {
      val = event.target.value;
    }
    const { riskClassEntity } = this.props;
    const entity = {
      ...riskClassEntity,
      [`${event.target.name}`]: val
    };
    if (event.target.name === 'riskCategoryId') {
      entity.riskCategoryId = parseInt(event.target.value, 10);
    }
    this.setState({ entity });
  };

  render() {
    const { riskClassEntity, riskCategories, loading, updating } = this.props;
    const { isNew } = this.state;
    const riskCategory = this.state.entity.riskCategoryId && riskCategories.find(f => f.id === this.state.entity.riskCategoryId);
    return (
      <div>
        <Card>
          <CardBody>
            <Col sm="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a RiskClass
                </CardTitle>
                <CardBody>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <AvForm model={isNew ? {} : riskClassEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="nameLabel" for="risk-class-name">
                            Name
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="risk-class-name"
                              type="text"
                              name="name"
                              placeholder="Enter name"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="descriptionLabel" for="risk-class-description">
                            Description
                          </Label>
                          <Col sm="10">
                            <AvField id="risk-class-description" placeholder="Enter description" type="text" name="description" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="brokerCommissionLabel" for="risk-class-brokerCommission">
                            Broker Commission
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="risk-class-brokerCommission"
                              type="string"
                              className="form-control"
                              name="brokerCommission"
                              placeholder="Enter broker commission"
                              validate={{
                                number: { value: true, errorMessage: 'This field should be a number.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="risk-class-riskCategory">
                            Risk Category
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="risk-class-riskCategory"
                              type="select"
                              className="form-control"
                              name="riskCategoryId"
                              required
                              onChange={this.updateStateEntity}
                            >
                              {riskCategories
                                ? sortBy(riskCategories, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      {riskCategory && riskCategory.name === 'Motor' && (
                        <AvGroup>
                          <Row>
                            <Label sm="2" id="classificationCategoryLabel" for="risk-class-classificationCategory">
                              Classification Category
                            </Label>
                            <Col sm="10">
                              <AvInput
                                id="risk-class-classificationCategory"
                                type="select"
                                className="form-control"
                                name="classificationCategory"
                                value={(!isNew && riskClassEntity.classificationCategory) || 'PRIVATE'}
                              >
                                <option value="COMMERCIAL_SECONDARY">COMMERCIAL_SECONDARY</option>
                                <option value="PRIVATE">PRIVATE</option>
                                <option value="COMMERCIAL_PRIMARY">COMMERCIAL_PRIMARY</option>
                                <option value="PUBLIC_SERVICE_VEHICLE">PUBLIC_SERVICE_VEHICLE</option>
                              </AvInput>
                            </Col>
                          </Row>
                        </AvGroup>
                      )}
                      {riskCategory && riskCategory.name === 'Health' && (
                        <AvGroup>
                          <Row>
                            <Label sm="2" id="classificationCategoryLabel" for="risk-class-classificationCategory">
                              Classification Category
                            </Label>
                            <Col sm="10">
                              <AvInput
                                id="risk-class-classificationCategory"
                                type="select"
                                className="form-control"
                                name="classificationCategory"
                                value={(!isNew && riskClassEntity.classificationCategory) || 'PRIVATE'}
                              >
                                <option value="AFFORDABLE_HEALTH">AFFORDABLE_HEALTH</option>
                                <option value="STANDARD_HEALTH">STANDARD_HEALTH</option>
                                <option value="PARENTAL_HEALTH">PARENTAL_HEALTH</option>
                                <option value="INTERNATIONAL_HEALTH">INTERNATIONAL_HEALTH</option>
                              </AvInput>
                            </Col>
                          </Row>
                        </AvGroup>
                      )}
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/risk-class" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskCategories: storeState.riskCategory.entities,
  riskClassEntity: storeState.riskClass.entity,
  loading: storeState.riskClass.loading,
  updating: storeState.riskClass.updating,
  updateSuccess: storeState.riskClass.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getRiskCategories,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskClassUpdate);
