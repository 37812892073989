import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './service-provider-type.reducer';
import { IServiceProviderType } from 'app/shared/model/service-provider-type.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IServiceProviderTypeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class ServiceProviderType extends React.Component<IServiceProviderTypeProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { serviceProviderTypeList, match } = this.props;
    return (
      <div>
        <h2 id="service-provider-type-heading">
          Service Provider Types
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new Service Provider Type
          </Link>
        </h2>
        <div className="table-responsive">
          {serviceProviderTypeList && serviceProviderTypeList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {serviceProviderTypeList.map((serviceProviderType, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${serviceProviderType.id}`} color="link" size="sm">
                        {serviceProviderType.id}
                      </Button>
                    </td>
                    <td>{serviceProviderType.name}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${serviceProviderType.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${serviceProviderType.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${serviceProviderType.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">No Service Provider Types found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ serviceProviderType }: IRootState) => ({
  serviceProviderTypeList: serviceProviderType.entities
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderType);
