import classname from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';
import moment from 'moment';
import { Typography, IconButton, Button, CircularProgress, Grid } from '@material-ui/core';
import useStyles from '../wizard/styles';
import { moneyConverter } from 'app/config/utils';

// import CouponCode from "./coupon-code";

const UserDetailsSummary = ({ loading, onClick, client, quote, carMake, carModel }) => {
  const classes = useStyles();
  const handleClick = () => {};

  const additionalBenefitsTotal = Math.round(
    // sumBy(additionalBenefits, "minimumValue")
    sumBy([], 'minimumValue')
  );

  const subTotal = Math.round(Math.round(30) + additionalBenefitsTotal);

  return (
    <div className={classes.container}>
      <Grid item className={classes.borderRight}>
        <div className={classes.titleContainer}>
          <Typography component="h1" variant="h6" className={classes.title}>
            General Details
          </Typography>
        </div>
        <div className={classes.headerContainer}>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.primary}>{`${client.firstName} ${client.lastName}`}</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.primary}>{client.mobile}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.premiumContainer}>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Email</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{client.email}</Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>ID Type</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{client.idTypeName}</Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>ID Number</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{client.idNumber}</Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Address</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{client.address}</Typography>
            </div>
          </div>

          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Cover Type</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{quote.coverTypeName}</Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Risk Class</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{quote.riskClassName}</Typography>
              <Typography className={classes.secondary}>
                {/*{(rate &&*/}
                {/*  `${Number(rate).toFixed(3).slice(-2).split("").join(".")}%`) ||*/}
                {/*"0.0%"}*/}
              </Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Start Date</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{quote.date}</Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>End Date</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{quote.expiryDate}</Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Car Registration</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{quote.registration}</Typography>
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Vehicle Make</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              {carMake && <Typography className={classes.secondary}>{carMake.name}</Typography>}
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Vehicle Model</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              {carModel && <Typography className={classes.secondary}>{carModel.name}</Typography>}
            </div>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.secondary}>Year Of Manufacture</Typography>
            </div>
            <div className={classname(classes.headerCol, classes.headerColHalf)}>
              <Typography className={classes.secondary}>{quote.yearOfManufacture}</Typography>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

UserDetailsSummary.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  client: PropTypes.object,
  quote: PropTypes.object,
  carMake: PropTypes.object,
  carModel: PropTypes.object
};

UserDetailsSummary.defaultProps = {
  loading: false,
  onClick: () => {}
};

export default memo(UserDetailsSummary);
