import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { defaultValue, KYCType } from 'app/shared/model/kyc.model';

import { IClient } from 'app/shared/model/client.model';
import { getEntities as getClients } from 'app/entities/client/client.reducer';
import { IRiskClass } from 'app/shared/model/risk-class.model';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { AttachmentType, EntityName, IAttachment } from 'app/shared/model/attachment.model';
import { getEntities as getAttachments, createEntity as createAttachment } from 'app/entities/attachment/attachment.reducer';
import { getEntity, updateEntity, updateStateEntity, createEntity, reset } from './kyc.reducer';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import GeneralForm from './general-form';
import sortBy from 'lodash/sortBy';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import RiskClassForm from './risk-class-form';

export interface IKYCUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  jumpToStep;
}

export interface IKYCUpdateState {
  isNew: boolean;
  riskClassId: string;
  attachmentId: string;
  kycType: string;
}

export class KYCUpdate extends React.Component<IKYCUpdateProps, IKYCUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      riskClassId: '0',
      attachmentId: '0',
      kycType: '',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    const { account, match, kYCEntity, clientEntity } = this.props;
    const { log } = console;
    log(kYCEntity, clientEntity);
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(match.params.id);
    }

    this.props.getRiskCategories(0, 200, `,&companyId=${account.companyId}`);

    this.props.getRiskClasses(0, 2000, `,&companyId=${account.companyId}`);
    this.props.getAttachments(0, 2000, `,&companyId=${account.companyId}`);
  }

  // updateStateEntity = event => {
  //   if (!(event && event.target)) {
  //     return;
  //   }
  //   let val: any;
  //
  //   if (event.target.type === 'checkbox') {
  //     val = event.target.checked;
  //   } else if (event.target.value === '') {
  //     val = null;
  //   } else {
  //     val = event.target.value;
  //   }
  //
  //   const entity = {
  //     ...this.props.kYCEntity,
  //     [`${ event.target.name }`]: val
  //   };
  //
  //   this.props.updateStateEntity(entity);
  // };\

  getKYCType = generalKYCType => {
    if (generalKYCType === 'PIN') {
      return AttachmentType.PIN_IMAGE;
    }
    if (generalKYCType === 'ID') {
      return AttachmentType.ID_IMAGE;
    }
  };

  saveEntity = () => {
    const { kycEntities, kYCEntity } = this.props;
    if (this.state.isNew) {
      const attaches = kYCEntity.attachments.map(a => {
        return { ...a, entityName: EntityName.KYC };
      });
      this.props.createEntity({ ...kYCEntity, clientId: this.props.clientEntity.id, attachments: attaches });
    } else {
      const attaches = kYCEntity.attachments.map(a => {
        return { ...a, entityName: EntityName.KYC, entityId: kYCEntity.id };
      });
      this.props.updateEntity({ ...kYCEntity, attachments: attaches });
    }

    // if (kycEntities.length) {
    //   if (kycEntities[0].attachments.length === 0) {
    //     attachment['entityId'] = kycEntities[0].id;
    //     const attachmentResult = Promise.resolve(this.props.createAttachment(attachment));
    //     attachmentResult.then(res => {
    //       const { attachmentEntity } = this.props;
    //       kycEntities[0].attachments.push(attachmentEntity);
    //       this.props.updateEntity(kycEntities[0]);
    //       this.props.history.push('/entity/kyc');
    //     });
    //   }
    // } else {
    //   const KYCResult = Promise.resolve(this.props.createEntity(entity));
    //   KYCResult.then(res => {
    //     attachment['entityId'] = kYCEntity.id;
    //     const attachmentResult = Promise.resolve(this.props.createAttachment(attachment));
    //     attachmentResult.then(res1 => {
    //       const { attachmentEntity } = this.props;
    //       kYCEntity.attachments.push(attachmentEntity);
    //       this.props.updateEntity(kYCEntity);
    //       this.props.history.push('/entity/kyc');
    //     });
    //   });
    // }
  };

  handleClose = () => {
    // console.log(this.props.history);
    // console.log(this.props.history.location);
    // console.log(this.props.history.length);
    this.props.history.go(this.props.history.length - 1);
  };

  handleNext = (event, errors, values) => {
    if (errors.length === 0) {
      this.props.updateStateEntity({ ...this.props.kYCEntity, ...values });
    }
  };

  onChangeKYCType = kycType => {
    this.setState({ kycType });
  };

  handleRiskCategoryChange = riskCategory => {
    this.props.getRiskClasses(0, 200, `,&riskCategoryId=${riskCategory}&companyId=${this.props.account.companyId}`);
  };

  render() {
    const { kYCEntity, clients, riskClasses, riskCategories, attachments, loading, updating } = this.props;
    const { isNew, kycType } = this.state;
    return (
      <div>
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <i className="mdi mdi-book mr-2" />
            {!kYCEntity.kycType && `Know Your Customer (KYC) Documentation`}
            {kYCEntity.kycType && (
              <span>
                KYC Document - <span className="text-capitalize">{kYCEntity.kycType}</span>
              </span>
            )}
          </CardTitle>
          <CardBody className="pl-5 pr-5">
            {loading && <p>Loading...</p>}
            {!kYCEntity.kycType && (
              <Fragment>
                <Row className="justify-content-md-center">
                  <Col md={5}>
                    <h4 className="mt-5 font-medium title text-center mb-5">
                      Select What Category of KYC Documents You Would Like to Upload
                    </h4>
                  </Col>
                </Row>
                <AvForm onSubmit={this.handleNext}>
                  <Row className="justify-content-md-center">
                    <Col lg={6}>
                      <AvGroup className="mb-5">
                        <Row>
                          <Label sm="3" id="kycTypeLabel" for="kyc-kycType">
                            KYC Category
                          </Label>
                          <Col>
                            <AvInput
                              id="kyc-kycType"
                              type="select"
                              className="form-control"
                              name="kycType"
                              onChange={selected => this.onChangeKYCType(selected.target.value)}
                              required
                            >
                              <option value="">Select</option>
                              <option value="GENERAL">GENERAL</option>
                              <option value="RISK_CLASS">RISK_CLASS</option>
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      {kycType && kycType === 'RISK_CLASS' && (
                        <>
                          <AvGroup className="mb-5 mt-4">
                            <Row>
                              <Label sm="3" className="text-right" id="kycTypeLabel" for="kyc-kycType">
                                Risk Category
                              </Label>
                              <Col>
                                <AvInput
                                  id="kyc-riskClass"
                                  type="select"
                                  onChange={selected => this.handleRiskCategoryChange(selected.target.value)}
                                  className="form-control"
                                  name="riskCategoryId"
                                >
                                  <option value="" key="">
                                    {' '}
                                    Select{' '}
                                  </option>
                                  {riskCategories
                                    ? sortBy(riskCategories, ['name']).map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {otherEntity.name}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                                <AvFeedback>This field is required.</AvFeedback>
                              </Col>
                            </Row>
                          </AvGroup>
                          <AvGroup className="mb-5 mt-4">
                            <Row>
                              <Label sm="3" className="text-right" id="kycTypeLabel" for="kyc-kycType">
                                Risk Class
                              </Label>
                              <Col>
                                <AvInput
                                  id="kyc-riskClass"
                                  type="select"
                                  // onChange={ selected => this.handleRiskCategoryChange(selected.target.value) }
                                  className="form-control"
                                  name="riskClassId"
                                >
                                  <option value="" key="">
                                    {' '}
                                    Select{' '}
                                  </option>
                                  {riskClasses
                                    ? sortBy(riskClasses, ['name']).map(otherEntity => (
                                        <option value={otherEntity.id} key={otherEntity.id}>
                                          {otherEntity.name}
                                        </option>
                                      ))
                                    : null}
                                </AvInput>
                                <AvFeedback>This field is required.</AvFeedback>
                              </Col>
                            </Row>
                          </AvGroup>
                        </>
                      )}
                    </Col>
                    <Col sm="12">
                      <FormGroup className="pt-5">
                        <Row>
                          <Col sm="6">
                            <Button id="cancel-save" onClick={this.handleClose} className="font-bold btn-rounded" color="info">
                              &nbsp; &nbsp;Back&nbsp; &nbsp;
                            </Button>
                          </Col>
                          <Col sm="6" className="text-right">
                            <Button type="submit" className="btn btn-success btn-rounded font-bold" disabled={updating}>
                              &nbsp; &nbsp; NEXT &nbsp; &nbsp;
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </AvForm>
              </Fragment>
            )}
            {kYCEntity.kycType === KYCType.GENERAL && (
              <GeneralForm kYCEntity={kYCEntity} updateStateEntity={updateStateEntity} onSubmit={this.saveEntity} />
            )}
            {kYCEntity.kycType === KYCType.RISK_CLASS && (
              <RiskClassForm kYCEntity={kYCEntity} updateStateEntity={updateStateEntity} onSubmit={this.saveEntity} />
            )}
          </CardBody>
        </Card>
        {/* <Row className="justify-content-center">
          <Col md="8">
            <h2 id="ibsApp.kYC.home.createOrEditLabel">Add KYC</h2>
          </Col>
        </Row> */}
        {/* <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={{ ...defaultValue, ...kYCEntity }} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="kyc-id">ID</Label>
                    <AvInput id="kyc-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="kycTypeLabel" for="kyc-kycType">
                    Kyc Type
                  </Label>
                  <AvInput
                    id="kyc-kycType"
                    type="select"
                    className="form-control"
                    name="kycType"
                    onChange={this.updateStateEntity}
                    value={kYCEntity.kycType || 'GENERAL'}
                  >
                    <option value="GENERAL">GENERAL</option>
                    <option value="RISK_CLASS">RISK_CLASS</option>
                  </AvInput>
                </AvGroup>
                {kYCEntity.kycType === KYCType.GENERAL && (
                  <div>
                    <AvGroup>
                      <Label id="generalKYCTypeLabel" for="kyc-generalKYCType">
                        General KYC Type
                      </Label>
                      <AvInput
                        id="kyc-generalKYCType"
                        type="select"
                        className="form-control"
                        name="generalKYCType"
                        value={kYCEntity.generalKYCType || 'ID'}
                      >
                        <option value="ID">ID</option>
                        <option value="PIN">PIN</option>
                        <option value="OTHER">OTHER</option>
                      </AvInput>
                    </AvGroup>
                    <AvGroup>
                      <Label id="customKYCTypeLabel" for="kyc-customKYCType">
                        Custom KYC Type
                      </Label>
                      <AvField id="kyc-customKYCType" type="text" name="customKYCType" />
                    </AvGroup>
                  </div>
                )}
                <AvGroup>
                  <Label id="motorKYCTypeLabel" for="kyc-motorKYCType">
                    Motor KYC Type
                  </Label>
                  <AvInput
                    id="kyc-motorKYCType"
                    type="select"
                    className="form-control"
                    name="motorKYCType"
                    value={kYCEntity.motorKYCType || 'DRIVERS_LICENSE'}
                  >
                    <option value="DRIVERS_LICENSE">DRIVERS_LICENSE</option>
                    <option value="LOGBOOK">LOGBOOK</option>
                    <option value="VALUATION_REPORT">VALUATION_REPORT</option>
                    <option value="OTHER">OTHER</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="expiryDateLabel" for="kyc-expiryDate">
                    Expiry Date
                  </Label>
                  <AvField id="kyc-expiryDate" type="date" className="form-control" name="expiryDate" />
                </AvGroup>
                <AvGroup>
                  <Label id="makeLabel" for="kyc-make">
                    Make
                  </Label>
                  <AvField id="kyc-make" type="text" name="make" />
                </AvGroup>
                <AvGroup>
                  <Label id="modelLabel" for="kyc-model">
                    Model
                  </Label>
                  <AvField id="kyc-model" type="text" name="model" />
                </AvGroup>
                <AvGroup>
                  <Label id="yearLabel" for="kyc-year">
                    Year
                  </Label>
                  <AvField id="kyc-year" type="string" className="form-control" name="year" />
                </AvGroup>
                <AvGroup>
                  <Label id="chasisNumberLabel" for="kyc-chasisNumber">
                    Chasis Number
                  </Label>
                  <AvField id="kyc-chasisNumber" type="text" name="chasisNumber" />
                </AvGroup>
                <AvGroup>
                  <Label id="engineNumberLabel" for="kyc-engineNumber">
                    Engine Number
                  </Label>
                  <AvField id="kyc-engineNumber" type="text" name="engineNumber" />
                </AvGroup>
                <AvGroup>
                  <Label id="colorLabel" for="kyc-color">
                    Color
                  </Label>
                  <AvField id="kyc-color" type="text" name="color" />
                </AvGroup>
                <AvGroup>
                  <Label id="registrationLabel" for="kyc-registration">
                    Registration
                  </Label>
                  <AvField id="kyc-registration" type="text" name="registration" />
                </AvGroup>
                <AvGroup>
                  <Label id="valueOfCarLabel" for="kyc-valueOfCar">
                    Value Of Car
                  </Label>
                  <AvField id="kyc-valueOfCar" type="string" className="form-control" name="valueOfCar" />
                </AvGroup>
                <AvGroup>
                  <Label id="dateOfValuationLabel" for="kyc-dateOfValuation">
                    Date Of Valuation
                  </Label>
                  <AvField id="kyc-dateOfValuation" type="date" className="form-control" name="dateOfValuation" />
                </AvGroup>
                <AvGroup>
                  <Label id="valuationCenterLabel" for="kyc-valuationCenter">
                    Valuation Center
                  </Label>
                  <AvField id="kyc-valuationCenter" type="text" name="valuationCenter" />
                </AvGroup>
                <AvGroup>
                  <Label id="carBodyTypeLabel" for="kyc-carBodyType">
                    Car Body Type
                  </Label>
                  <AvInput
                    id="kyc-carBodyType"
                    type="select"
                    className="form-control"
                    name="carBodyType"
                    value={kYCEntity.carBodyType || 'HATCHBACK'}
                  >
                    <option value="HATCHBACK">HATCHBACK</option>
                    <option value="SEDAN">SEDAN</option>
                    <option value="SUV">SUV</option>
                    <option value="COUPE">COUPE</option>
                    <option value="CONVERTIBLE">CONVERTIBLE</option>
                    <option value="STATION_WAGON">STATION_WAGON</option>
                    <option value="VAN">VAN</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="kyc-client">Client</Label>
                  <AvInput id="kyc-client" type="select" className="form-control" name="clientId">
                    <option value="" key="0" />
                    {clients
                      ? clients.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="kyc-riskClass">Risk Class</Label>
                  <AvInput id="kyc-riskClass" type="select" className="form-control" name="riskClassId">
                    <option value="" key="0" />
                    {riskClasses
                      ? riskClasses.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="kyc-attachment">Attachment</Label>
                  <AvInput id="kyc-attachment" type="select" className="form-control" name="attachmentId">
                    <option value="" key="0" />
                    {attachments
                      ? attachments.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/kyc" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row> */}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clients: storeState.client.entities,
  riskClasses: storeState.riskClass.entities,
  attachments: storeState.attachment.entities,
  attachmentEntity: storeState.attachment.entity,
  kYCEntity: storeState.kYC.entity,
  clientEntity: storeState.client.entity,
  kycEntities: storeState.kYC.entities,
  riskCategories: storeState.riskCategory.entities,
  loading: storeState.kYC.loading,
  updating: storeState.kYC.updating,
  updateSuccess: storeState.kYC.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getClients,
  updateStateEntity,
  getRiskClasses,
  getAttachments,
  getEntity,
  updateEntity,
  getRiskCategories,
  createEntity,
  createAttachment,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCUpdate);
