import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import { PasswordResetFinishSuccess } from 'app/modules/account/password-reset/finish/password-reset-finish-success';

const HomeTemplate = props => (
  <MainTemplate>
    <PasswordResetFinishSuccess {...props} />
  </MainTemplate>
);

export default HomeTemplate;
