import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeviesDefaultData from './levies-default-data';
import LeviesDefaultDataDetail from './levies-default-data-detail';
import LeviesDefaultDataUpdate from './levies-default-data-update';
import LeviesDefaultDataDeleteDialog from './levies-default-data-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeviesDefaultDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeviesDefaultDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeviesDefaultDataDetail} />
      <ErrorBoundaryRoute path={match.url} component={LeviesDefaultData} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={LeviesDefaultDataDeleteDialog} />
  </>
);

export default Routes;
