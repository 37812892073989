import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import DataTable from 'app/shared/common/dataTable';
import { TextFormat, getSortState, IPaginationBaseState } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './client-policy-payment.reducer';
import { IClientPolicyPayment } from 'app/shared/model/client-policy-payment.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IClientPolicyPaymentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IClientPolicyPaymentState = IPaginationBaseState;

export class ClientPolicyPayment extends React.Component<IClientPolicyPaymentProps, IClientPolicyPaymentState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { account } = this.props;
    const productTypeIds = account.productTypes.map(a => `productTypeId=${a.id}`);
    filtered.push(`companyId=${account.companyId}`);
    this.props.getEntities(state.page, state.pageSize, `${sort},${order}&${productTypeIds.join('&')}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { loading, clientPolicyPaymentList, match, totalItems } = this.props;
    const tableData = clientPolicyPaymentList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      isIPF: prop.isIPF ? 'true' : 'false',
      payDate: <TextFormat type="date" value={prop.payDate} format={APP_LOCAL_DATE_FORMAT} />,
      clientPolicyId: prop.clientPolicyId ? <NavLink to={`client/${prop.clientPolicyId}`}>{prop.clientPolicyId}</NavLink> : '',
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Client Policy Payments
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  Create new Client Policy Payment
                </NavLink>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Pay Date',
                  accessor: 'payDate'
                },
                {
                  Header: 'Amount',
                  accessor: 'amount'
                },
                {
                  Header: 'Payment Method',
                  accessor: 'paymentMethod',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="CARD">CARD</option>
                      <option value="MPESA">MPESA</option>
                      <option value="CASH">CASH</option>
                      <option value="CHEQUE">CHEQUE</option>
                      <option value="BANK_TRANSFER">BANK_TRANSFER</option>
                    </select>
                  )
                },
                {
                  Header: 'Is IPF',
                  accessor: 'isIPF',
                  filterable: false
                },
                {
                  Header: 'Status',
                  accessor: 'payment-status',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="IN_PROGRESS">IN PROGRESS</option>
                      <option value="SUCCESS">SUCCESS</option>
                      <option value="FAIL">FAIL</option>
                      <option value="PENDING">PENDING</option>
                      <option value="FAILED">FAILED</option>
                    </select>
                  )
                },
                {
                  Header: 'Client Policy',
                  accessor: 'clientPolicyName'
                },
                {
                  Header: 'Client',
                  accessor: 'clientName'
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ clientPolicyPayment, authentication }: IRootState) => ({
  loading: clientPolicyPayment.loading,
  clientPolicyPaymentList: clientPolicyPayment.entities,
  totalItems: clientPolicyPayment.totalItems,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPolicyPayment);
