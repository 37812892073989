import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './remittance.reducer';
import { IRemittance } from 'app/shared/model/remittance.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IRemittanceDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const RemittanceDetail = (props: IRemittanceDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { remittanceEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Remittance [<b>{remittanceEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="transCode">Trans Code</span>
          </dt>
          <dd>{remittanceEntity.transCode}</dd>
          <dt>
            <span id="transDate">Trans Date</span>
          </dt>
          <dd>
            {remittanceEntity.transDate ? (
              <TextFormat value={remittanceEntity.transDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="paymentMethod">Payment Method</span>
          </dt>
          <dd>{remittanceEntity.paymentMethod}</dd>
          <dt>Underwriter</dt>
          <dd>{remittanceEntity.underwriterId ? remittanceEntity.underwriterId : ''}</dd>
        </dl>
        <Button tag={Link} to="/remittance" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/remittance/${remittanceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ remittance }: IRootState) => ({
  remittanceEntity: remittance.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemittanceDetail);
