import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import findIndex from 'lodash/findIndex';
import sumBy from 'lodash/sumBy';
import { Link, RouteComponentProps } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { Card, Row, Col, CustomInput, Button, CardBody } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { APP_LOCAL_STRING_DATE_FORMAT } from 'app/config/constants';
import { getEntity, updateEntity } from '../quote.reducer';
import { getAdditionalBenefits } from '../../benefit/benefit.reducer';

export interface IQuoteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

class AddBenefit extends React.Component<any, any> {
  private componentRef = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {
      startAtStep: 0,
      calculatePremium: 0,
      selectedAdditionalBenefits: []
    };
  }
  componentWillMount = () => {
    const { location } = this.props;
    if (location.search.indexOf('wizard=2') !== -1) {
      const startAtStep = Number(location.search.split('=').pop());
      this.setState({ startAtStep: Number(startAtStep) });
    }
    this.props.getEntity(this.props.match.params.id);
    this.props.getAdditionalBenefits(`,&companyId=${this.props.account.companyId}`, this.props.match.params.id);
  };
  componentWillReceiveProps = newProps => {
    if (newProps.quote.id && this.state.calculatePremium === 0) {
      this.setState({ calculatePremium: newProps.quote.premiumPayable });
    }
  };
  handleSelect = (event, selectedValue) => {
    const { quote } = this.props;
    let { calculatePremium } = this.state;
    const { selectedAdditionalBenefits } = this.state;
    if (calculatePremium < 1) {
      calculatePremium = quote.premiumPayable;
    }
    // @ts-ignore
    const exitAdditionalBenefit = findIndex(selectedAdditionalBenefits, value => value.id === selectedValue.id);
    if (event.target.checked) {
      if (exitAdditionalBenefit === -1) {
        selectedAdditionalBenefits.push(selectedValue);
      }
      this.setState(prevState => ({
        ...prevState,
        selectedAdditionalBenefits,
        calculatePremium: calculatePremium + selectedValue.minimumValue
      }));
    } else {
      if (exitAdditionalBenefit !== -1) {
        selectedAdditionalBenefits.splice(exitAdditionalBenefit, 1);
      }

      this.setState(prevState => ({
        ...prevState,
        selectedAdditionalBenefits,
        calculatePremium: calculatePremium - selectedValue.minimumValue
      }));
    }
  };

  getCurrentLogo = quote => {
    const { logoFile } = quote;
    if (logoFile && logoFile.content) {
      return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
    }
    return null;
  };

  saveEntity = sendEmail => {
    const { quote } = this.props;
    const { calculatePremium, selectedAdditionalBenefits } = this.state;
    const entity = {
      ...quote,
      sendEmail,
      additionalBenefits: selectedAdditionalBenefits,
      premiumPayable: calculatePremium
    };

    this.props.updateEntity(entity);
  };

  render() {
    const { calculatePremium, selectedAdditionalBenefits, startAtStep } = this.state;
    const { loading, additionalBenefits, quote } = this.props;
    const standardBenefits = (quote && quote.basicBenefits && quote.basicBenefits.filter(b => b.benefitTypeName === 'Standard')) || [];
    const exclusionsBenefits = (quote && quote.basicBenefits && quote.basicBenefits.filter(b => b.benefitTypeName === 'Exclusions')) || [];
    const limitOfLiabilityBenefits =
      (quote && quote.basicBenefits && quote.basicBenefits.filter(b => b.benefitTypeName === 'Limit of Liability')) || [];
    const excessBenefits = (quote && quote.basicBenefits && quote.basicBenefits.filter(b => b.benefitTypeName === 'Excess')) || [];
    // @ts-ignore
    const totalAdditionalBenefits = sumBy(selectedAdditionalBenefits, value => value.minimumValue);
    return (
      <div className="add-benefit-container" ref={this.componentRef}>
        {loading && <p>Loading...</p>}
        {!loading && (
          <Fragment>
            <Row className="mb-4">
              <Col md="12">
                <div className="d-flex justify-content-end">
                  <Button className="icon-link-btn">
                    <i className="mdi mdi-file-pdf" />
                  </Button>
                  <Button className="icon-link-btn" onClick={() => this.saveEntity(true)}>
                    <i className="mdi mdi-email-outline" />
                  </Button>
                  <ReactToPrint
                    trigger={() => (
                      <Button className="icon-link-btn">
                        <i className="mdi mdi-printer" />
                      </Button>
                    )}
                    content={() => this.componentRef.current}
                  />
                </div>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row>
                  <Col md="5">
                    <div className="d-flex align-items-center">
                      <div className="media align-items-center">
                        {this.getCurrentLogo(quote) && (
                          <img src={this.getCurrentLogo(quote)} alt="Generic placeholder image" className="img-fluid mr-2 media-object" />
                        )}
                      </div>
                      <div className="d-flex align-items-center flex-fill">
                        <div>
                          <h4 className="font-24 font-light mb-0">Monthly Premium</h4>
                          <h1 className="text-primary font-bold mb-0">{`Ksh. ${String(Math.round(calculatePremium)).replace(
                            /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                            '$1,'
                          )}`}</h1>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="7">
                    <div className="d-flex align-items-center content-benefit">
                      <div className="d-flex align-items-center flex-fill justify-content-between">
                        <div className="flex-fill pr-5">
                          <div className="d-flex justify-content-between">
                            <h4 className="font-light mb-0">Risk Class:</h4>
                            <h4 className="font-bold mb-0">{quote.riskClassName}</h4>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4 className="font-light mb-0">Cover Type:</h4>
                            <h4 className="font-bold mb-0">{quote.coverTypeName}</h4>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4 className="font-light mb-0">Sum Insured:</h4>
                            <h4 className="font-bold mb-0">
                              {`Ksh. ${String(quote.sumInsured).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center flex-fill justify-content-between">
                        <div className="flex-fill pr-2">
                          <div className="d-flex justify-content-between">
                            <h4 className="font-light mb-0">Cover Start Date:</h4>
                            <h4 className="font-bold mb-0">
                              <TextFormat type="date" value={quote.createdAt} format={APP_LOCAL_STRING_DATE_FORMAT} />
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4 className="font-light mb-0">Cover EndDate:</h4>
                            <h4 className="font-bold mb-0">
                              <TextFormat type="date" value={quote.expiryDate} format={APP_LOCAL_STRING_DATE_FORMAT} />
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4 className="font-light mb-0">Quote Date:</h4>
                            <h4 className="font-bold mb-0">
                              <TextFormat type="date" value={quote.createdAt} format={APP_LOCAL_STRING_DATE_FORMAT} />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="p-5">
                <Row>
                  <Col md="6">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="t-header flex-2">
                        <h4>PREMIUM DETAILS</h4>
                      </div>
                    </div>
                    <div className="benefit-table">
                      <div className="d-flex justify-content-between t-row">
                        <div className="t-col flex-2">
                          <h4 className="font-normal mb-0">Basic Premium</h4>
                        </div>
                        <div className="t-col">
                          <h4 className="font-normal mb-0 text-right">
                            {`Ksh. ${String(Math.round(quote.basicPremium)).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between t-row">
                        <div className="t-col flex-2">
                          <h4 className="font-normal mb-0">Premium Rate</h4>
                        </div>
                        <div className="t-col">
                          <h4 className="font-normal mb-0 text-right">{quote.rate ? `${quote.rate}%` : '0.0%'}</h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between t-row">
                        <div className="t-col flex-2">
                          <h4 className="font-normal mb-0">Total Additional Benefits</h4>
                        </div>
                        <div className="t-col">
                          <h4 className="font-normal mb-0 text-right">
                            {`Ksh. ${String(Math.round(totalAdditionalBenefits)).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between t-row">
                        <div className="t-col flex-2">
                          <h4 className="font-normal mb-0">Levies</h4>
                        </div>
                        <div className="t-col">
                          <h4 className="font-normal mb-0 text-right">
                            {`Ksh. ${String(Math.round(quote.levies)).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between t-row">
                        <div className="t-col flex-2">
                          <h4 className="font-bold mb-0 text-primary">Total Premium</h4>
                        </div>
                        <div className="t-col">
                          <h4 className="font-bold mb-0 text-primary text-right">
                            {`Ksh. ${String(Math.round(calculatePremium)).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="t-header flex-2">
                        <h4>ADDITIONAL BENEFITS</h4>
                      </div>
                      <div className="t-header pr-4">
                        <h4>VALUES</h4>
                      </div>
                    </div>
                    <div className="benefit-table">
                      {additionalBenefits &&
                        additionalBenefits.map(prop => (
                          <div key={prop.id} className="d-flex justify-content-between align-items-center t-row">
                            <div className="t-col flex-2">
                              <div className="d-flex align-items-center">
                                <div className="mr-3">
                                  <div className="checkbox">
                                    <CustomInput
                                      type="checkbox"
                                      name="policy"
                                      id={`additinal_${prop.id}`}
                                      onChange={event => this.handleSelect(event, prop)}
                                    />
                                  </div>
                                </div>
                                <h4 className="font-normal mb-0">{prop.name}</h4>
                              </div>
                            </div>
                            <div className="t-col">
                              <h4 className="font-normal mb-0 text-right">{`Ksh. ${String(prop.minimumValue).replace(
                                /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                                '$1,'
                              )}`}</h4>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="p-5">
                <Row>
                  <Col md="12">
                    <h3 className="font-light mb-4">Cover Details</h3>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-5">
                    <h4 className="mb-3">Standard</h4>
                    <div className="benefit-striped">
                      <div className="t-head d-flex justify-content-between">
                        <div className="t-header flex-2">
                          <h4 className="mb-0">TYPE</h4>
                        </div>
                        <div className="t-header pr-5">
                          <h4 className="mb-0">LIMIT</h4>
                        </div>
                      </div>
                      {standardBenefits.map(prop => (
                        <div className="d-flex justify-content-between t-row">
                          <div className="t-col flex-3">
                            <h4 className="font-normal mb-0">{prop.name}</h4>
                          </div>
                          <div className="t-col">
                            <h4 className="font-normal mb-0 text-right">{`Ksh. ${String(prop.minimumValue).replace(
                              /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                              '$1,'
                            )}`}</h4>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col md="6 mb-5">
                    <h4 className="mb-3">Limit of Liability</h4>
                    <div className="benefit-striped">
                      <div className="t-head d-flex justify-content-between">
                        <div className="t-header flex-2">
                          <h4 className="mb-0">TYPE</h4>
                        </div>
                        <div className="t-header pr-5">
                          <h4 className="mb-0">LIMIT</h4>
                        </div>
                      </div>
                      {limitOfLiabilityBenefits.map(prop => (
                        <div className="d-flex justify-content-between t-row">
                          <div className="t-col flex-3">
                            <h4 className="font-normal mb-0">{prop.name}</h4>
                          </div>
                          <div className="t-col">
                            <h4 className="font-normal mb-0 text-right">{`Ksh. ${String(prop.minimumValue).replace(
                              /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                              '$1,'
                            )}`}</h4>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col md="6">
                    <h4 className="mb-3">Excesses</h4>
                    <div className="benefit-striped-table">
                      {excessBenefits.map(prop => (
                        <div className="d-flex justify-content-between t-row">
                          <div className="t-col flex-2 t-col-gray">
                            <h4 className="font-normal mb-0">{prop.name}</h4>
                          </div>
                          <div className="t-col">
                            <h4 className="font-normal mb-0 text-right">{prop.description}</h4>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col md="6">
                    <h4>Special Clauses</h4>
                    <div className="benefit-striped-table">
                      {exclusionsBenefits.map(prop => (
                        <div className="d-flex justify-content-between t-row">
                          <div className="t-col flex-2 t-col-gray">
                            <h4 className="font-normal mb-0">
                              {prop.name}&nbsp;-&nbsp;{prop.description}
                            </h4>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="12 text-right">
                    <h1 className="text-primary font-bold mb-0">{`Ksh. ${String(Math.round(calculatePremium)).replace(
                      /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                      '$1,'
                    )}`}</h1>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="6">
                    <Col sm="6" className="text-left">
                      <Button tag={Link} to={`/entity/quote/create-quote?wizard=${startAtStep}`} color="info">
                        <span className="d-none d-md-inline">Back</span>
                      </Button>
                    </Col>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button color="danger">
                      <span className="d-none d-md-inline">Proceed to payment</span>
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <ReactToPrint
                      trigger={() => (
                        <Button color="info">
                          <i className="mdi mdi-printer" />
                          &nbsp;
                          <span className="d-none d-md-inline">Print</span>
                        </Button>
                      )}
                      content={() => this.componentRef.current}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  quote: storeState.quote.entity,
  additionalBenefits: storeState.benefit.entities,
  account: storeState.authentication.account,
  loading: storeState.quote.loading,
  updating: storeState.quote.updating,
  updateSuccess: storeState.quote.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  getAdditionalBenefits,
  updateEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBenefit);
