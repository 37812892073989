import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, CustomInput, Label, Button, CardBody, Table } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { setFileData, openFile, byteSize, TextFormat, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import ImageUploader from 'react-images-upload';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntities as getUnderwriterProducts } from 'app/entities/underwriter-product/underwriter-product.reducer';
import { getEntity, updateEntity, createEntity, reset, setBlob, setPinImage, setBinderPdf, deleteBinderPdf } from './underwriter.reducer';
import { updateEntity as updateProductEntity } from '../underwriter-product/underwriter-product.reducer';
import { IUnderwriter } from 'app/shared/model/underwriter.model';
// tslint:disable-next-line:no-unused-variable
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import MultiSelect from 'app/shared/common/multi-select';
import Utils, { ImageType } from 'app/config/utils';

export interface IUnderwriterUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IUnderwriterUpdateState extends IPaginationBaseState {
  isNew: boolean;
  telephone: string;
  mobile: string;
  productTypes: any;
  country: string;
  productData: object;
  inEditMode: any;
  selectedOptions: any[];
}

export class UnderwriterUpdate extends React.Component<IUnderwriterUpdateProps, IUnderwriterUpdateState> {
  private componentRef = React.createRef<HTMLDivElement>();
  private readonly reactTable: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      itemsPerPage: ITEMS_PER_PAGE,
      isNew: !this.props.match.params || !this.props.match.params.id,
      telephone: '',
      mobile: '',
      productTypes: [],
      selectedOptions: [],
      country: '',
      productData: null,
      inEditMode: {
        status: false,
        name: null
      }
    };
    this.fetchData = this.fetchData.bind(this);
    this.reactTable = React.createRef();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  // getCurrentLogo = () => {
  //   const { logoFile } = this.props.underwriterEntity;
  //   if (logoFile && logoFile.content) {
  //     return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
  //   }
  //   return null;
  // };

  // getCurrentPinImage = () => {
  //   const { pinImage } = this.props.underwriterEntity;
  //   if (pinImage && pinImage.content) {
  //     return `data:${pinImage.contentContentType};base64, ${pinImage.content}`;
  //   }
  //   return null;
  // };

  componentDidMount() {
    this.props.getProductTypes(0, 20, `,&companyId=${this.props.account.companyId}`);
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.loadEntity(this.props.match.params.id);
    }
  }

  async loadEntity(name) {
    await this.props.getEntity(name);
    const { underwriterEntity } = this.props;
    this.setState({
      selectedOptions: underwriterEntity.productTypes.map(p => ({
        value: p.id,
        label: p.name
      }))
    });
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    if (this.props.match.params.id) {
      this.props.getUnderwriterProducts(
        state.page,
        state.pageSize,
        `${sort || 'id'},${order || 'desc'}&underwriterId=${this.props.match.params.id}&${filtered.join('&')}`
      );
    }

    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalUnderwriterProducts } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalUnderwriterProducts / itemsPerPage);
    const modulo = totalUnderwriterProducts % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  onCountry = country => {
    this.setState({ country });
  };

  onBlobChange = (isAnImage, name) => event => {
    // @ts-ignore
    setFileData(
      { target: { files: [event[event.length - 1]] } },
      (contentType, data) => this.props.setBlob(name, data, contentType),
      isAnImage
    );
  };

  onPinImageChange = (isAnImage, name) => event => {
    setFileData(
      { target: { files: [event[event.length - 1]] } },
      (contentType, data) => this.props.setPinImage(name, data, contentType),
      isAnImage
    );
  };

  onBinderPdfChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBinderPdf(name, data, contentType), isAnImage);
  };

  deleteBinderPdf = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    this.props.deleteBinderPdf(parseInt(event.currentTarget.dataset.arg, 10));
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { underwriterEntity, productTypes } = this.props;
      const entity = {
        ...underwriterEntity,
        ...values,
        companyId: this.props.account.companyId,
        mobile: this.state.mobile,
        telephone: this.state.telephone,
        productTypes: productTypes.filter(p => this.state.selectedOptions.map(e => e.value).includes(p.id)),
        // productTypes: productTypes.filter(p => values.productTypes.map(e => Number(e)).includes(p.id)),
        country: this.state.country,
        tpPrice: Number(values.tpPrice),
        comprehensiveAgeCap: Number(values.comprehensiveAgeCap),
        checkupAge: Number(values.checkupAge)
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/underwriter');
  };

  handlePhone = telephone => {
    this.setState({ telephone });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handleUpdateProduct = async (e, product) => {
    const { productData } = this.state;
    const { id, discountActive, discountRate, startDate, endDate, status } = product;
    const data = {
      ...product,
      discountActive: (productData[id] && productData[id][`discountActive-${id}`]) || discountActive,
      discountRate: (productData[id] && productData[id][`discountRate-${id}`]) || discountRate,
      startDate: (productData[id] && productData[id][`startDate-${id}`]) || startDate,
      endDate: (productData[id] && productData[id][`endDate-${id}`]) || endDate,
      status: (productData[id] && productData[id][`discountStatus-${id}`]) || status
    };
    try {
      await this.props.updateProductEntity(data);
      this.fetchData(this.reactTable.current.state);
      this.onCancel(id);
    } catch (error) {}
  };

  onEdit = (e, product) => {
    const disc = e.target.name === `discountActive-${product.id}`;
    this.setState({
      ...this.state,
      productData: {
        ...this.state.productData,
        [product.id]: this.state.productData
          ? {
              ...this.state.productData[product.id],
              [e.target.name]: disc ? e.target.checked : e.target.value,
              inEditMode: true
            }
          : {
              [e.target.name]: disc ? e.target.checked : e.target.value,
              inEditMode: true
            }
      }
    });
  };

  handleChangeProductType = selectedOptions => {
    this.setState({
      selectedOptions
    });
  };

  onCancel = key => {
    this.setState({
      ...this.state,
      productData: {
        ...this.state.productData,
        [key]: null
      }
    });
  };

  render() {
    const { underwriterEntity, loading, loadingUnderwriterProducts, updating, productTypes, underwriterProducts, match } = this.props;
    const { isNew, productData, selectedOptions } = this.state;
    const productTypesOptions = productTypes
      .filter(p => p.name !== 'Medical')
      .map(p => ({
        value: p.id,
        label: p.name
      }));

    const logo = Utils.getCurrentImage(underwriterEntity, ImageType.logo);
    const pinImage = Utils.getCurrentImage(underwriterEntity, ImageType.pin);

    const tableData = underwriterProducts.map(prop => ({
      ...prop,
      discount: (
        <div className="text-center">
          <CustomInput
            label="Discount Active"
            type="text"
            name={`discountRate-${prop.id}`}
            value={
              productData && productData[prop.id] && productData[prop.id].inEditMode && productData[prop.id][`discountRate-${prop.id}`]
                ? productData[prop.id][`discountRate-${prop.id}`]
                : prop.discountRate
            }
            id={`discountRate-${prop.id}`}
            onChange={e => this.onEdit(e, prop)}
          />
        </div>
      ),
      startDate: (
        <div className="text-center">
          <CustomInput
            label="Start Date"
            type="date"
            name={`startDate-${prop.id}`}
            onChange={e => this.onEdit(e, prop)}
            value={
              productData && productData[prop.id] && productData[prop.id].inEditMode && productData[prop.id][`startDate-${prop.id}`]
                ? productData[prop.id][`startDate-${prop.id}`]
                : prop.startDate
            }
            id={`startDate-${prop.id}`}
          />
        </div>
      ),
      endDate: (
        <div className="text-center">
          <CustomInput
            label="Discount Active"
            type="date"
            name={`endDate-${prop.id}`}
            onChange={e => this.onEdit(e, prop)}
            value={
              productData && productData[prop.id] && productData[prop.id].inEditMode && productData[prop.id][`endDate-${prop.id}`]
                ? productData[prop.id][`endDate-${prop.id}`]
                : prop.startDate
            }
            id={`endDate-${prop.id}`}
          />
        </div>
      ),
      discountActive: (
        <div className="text-center">
          <div className="checkbox">
            <CustomInput
              label="Discount Active"
              type="checkbox"
              name={`discountActive-${prop.id}`}
              onChange={e => this.onEdit(e, prop)}
              checked={
                productData && productData[prop.id] && productData[prop.id].inEditMode && productData[prop.id][`discountActive-${prop.id}`]
                  ? productData[prop.id][`discountActive-${prop.id}`]
                  : prop.discountActive
              }
              id={`discountActive-${prop.id}`}
            />
          </div>
        </div>
      ),
      discountStatus: (
        <div className="text-center">
          <CustomInput
            id={`discountStatus-${prop.id}`}
            type="select"
            className="form-control"
            name={`discountStatus-${prop.id}`}
            onChange={e => this.onEdit(e, prop)}
            value={
              productData && productData[prop.id] && productData[prop.id].inEditMode && productData[prop.id][`discountStatus-${prop.id}`]
                ? productData[prop.id][`discountStatus-${prop.id}`]
                : prop.status || 'INACTIVE'
            }
          >
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
          </CustomInput>
        </div>
      ),
      actions: (
        <div className="action-container">
          {productData && prop && productData[prop.id] && productData[prop.id]['inEditMode'] && (
            <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" onClick={() => this.onCancel(prop.id)}>
              <i className="fa fa-check-square-o" />
              CANCEL
            </Button>
          )}
          <Button className="ml-1" color="info" size="sm" round="true" icon="true" onClick={e => this.handleUpdateProduct(e, prop)}>
            <i className="fa fa-check-square-o" />
            APPLY
          </Button>
        </div>
      )
    }));

    const styles = {
      fontSize: '11px'
    };

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a Underwriter
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : underwriterEntity} onSubmit={this.saveEntity}>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="nameLabel" for="underwriter-name">
                                Name
                              </Label>
                              <Col sm="8">
                                <AvField
                                  id="underwriter-name"
                                  type="text"
                                  name="name"
                                  placeholder="Enter name"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="displayNameLabel" for="underwriter-displayName">
                                Display Name
                              </Label>
                              <Col sm="8">
                                <AvField id="underwriter-displayName" placeholder="Enter display name" type="text" name="displayName" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="statusLabel" for="underwriter-status">
                                Status
                              </Label>
                              <Col sm="8">
                                <AvInput
                                  id="underwriter-status"
                                  type="select"
                                  className="form-control"
                                  name="status"
                                  value={(!isNew && underwriterEntity.status) || 'ACTIVE'}
                                >
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="INACTIVE">INACTIVE</option>
                                </AvInput>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="taxpayerPinLabel" for="underwriter-taxpayer-pin">
                                Taxpayer PIN
                              </Label>
                              <Col sm="8">
                                <AvField id="underwriter-taxpayer-pin" placeholder="Enter Taxpayer PIN" type="text" name="taxpayerPin" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="telephoneLabel" for="underwriter-telephone">
                                Telephone
                              </Label>
                              <Col sm="8">
                                <PhoneInput value={(!isNew && underwriterEntity.telephone) || ''} onChange={this.handlePhone} />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="mobileLabel" for="underwriter-mobile">
                                Mobile
                              </Label>
                              <Col sm="8">
                                <PhoneInput value={(!isNew && underwriterEntity.mobile) || ''} onChange={this.handleMobile} />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="websiteLabel" for="underwriter-website">
                                Website
                              </Label>
                              <Col sm="8">
                                <AvField id="underwriter-website" type="text" name="website" placeholder="Enter website" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="contactPersionLabel" for="underwriter-contactPersion">
                                Contact Person
                              </Label>
                              <Col sm="8">
                                <AvField
                                  id="underwriter-contactPersion"
                                  placeholder="Enter contact person"
                                  type="text"
                                  name="contactPersion"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="emailLabel" for="underwriter-email">
                                Email
                              </Label>
                              <Col sm="8">
                                <AvField id="underwriter-email" placeholder="Enter email address" type="text" name="email" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="addressLabel" for="underwriter-address">
                                Address
                              </Label>
                              <Col sm="8">
                                <AvField id="underwriter-address" placeholder="Enter address" type="text" name="address" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="streetAddressLabel" for="underwriter-streetAddress">
                                Street Address
                              </Label>
                              <Col sm="8">
                                <AvField
                                  id="underwriter-streetAddress"
                                  type="text"
                                  name="streetAddress"
                                  placeholder="Enter street address"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="pinNumberLabel" for="underwriter-pinNumber">
                                Postal Code
                              </Label>
                              <Col sm="8">
                                <AvField
                                  id="underwriter-pinNumber"
                                  placeholder="Enter Postal Code"
                                  type="number"
                                  className="form-control"
                                  name="pinNumber"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="countyLabel" for="underwriter-county">
                                County
                              </Label>
                              <Col sm="8">
                                <AvField id="underwriter-county" placeholder="Enter county" type="text" name="county" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col>
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="countryLabel" for="underwriter-country">
                                Country
                              </Label>
                              <Col sm="8">
                                <ReactFlagsSelect
                                  id="underwriter-country"
                                  defaultCountry={(!isNew && underwriterEntity.country) || ''}
                                  onSelect={this.onCountry}
                                  name="country"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="memberCompanyId" for="memberCompanyId">
                                AKI Id
                              </Label>
                              <Col sm="8">
                                <AvField id="memberCompanyId" type="number" placeholder="Enter Member CO Id" name="akiCompanyId" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="comprehensiveAgeCap" for="comprehensiveAgeCap">
                                Comprehensive Cover Age Capping
                              </Label>
                              <Col sm="8">
                                <AvField
                                  id="comprehensiveAgeCap"
                                  type="number"
                                  placeholder="Enter Comprehensive Age Capping"
                                  name="comprehensiveAgeCap"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="thirdPartyCost" for="thirdPartyCost">
                                Third Party Cost
                              </Label>
                              <Col sm="8">
                                <AvField id="thirdPartyCost" type="number" placeholder="Enter Third Party Cost" name="tpPrice" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" id="thirdPartyCost" for="thirdPartyCost">
                                Medical Check Up Age
                              </Label>
                              <Col sm="8">
                                <AvField id="checkupAge" type="number" placeholder="Enter Adult Check Up Age" name="checkupAge" />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>

                      {/* <AvGroup>
                        <Row>
                          <Label sm="2" id="binderLabel" for="underwriter-binder">
                            Binder
                          </Label>
                          <Col sm="10">
                            <AvField id="underwriter-binder" type="text" placeholder="Enter binder" name="binder" />
                          </Col>
                        </Row>
                      </AvGroup> */}

                      {/* <AvGroup>
                        <Row>
                          <Label sm="2" id="statusLabel" for="underwriter-binder-pdf">
                            Binder PDF
                          </Label>
                          <Col sm="5">
                            <input
                              id="file_content"
                              type="file"
                              accept="application/pdf"
                              onChange={this.onBinderPdfChange(false, 'content')}
                            />
                            <AvInput type="hidden" name="content" />
                          </Col>
                          <Col sm="5">
                            <div className="table-responsive">
                              {underwriterEntity.binderPdfs && underwriterEntity.binderPdfs.length > 0 && (
                                <Table responsive>
                                  <div>
                                    <span>
                                      <strong>Uploaded Binder-PDFs</strong>
                                    </span>
                                  </div>
                                  <tbody>
                                    {underwriterEntity.binderPdfs.map((attachmentFile, i) => (
                                      <tr key={`entity-${i}`}>
                                        <td>
                                          {attachmentFile.content ? (
                                            <div>
                                              <span>{`Binder-PDF ${i + 1}`}</span>
                                            </div>
                                          ) : null}
                                        </td>
                                        <td className="text-right">
                                          <div className="btn-group flex-btn-group-container">
                                            <Button
                                              onClick={openFile(attachmentFile.contentContentType, attachmentFile.content)}
                                              color="success"
                                              size="sm"
                                            >
                                              <FontAwesomeIcon icon="eye" />
                                            </Button>
                                            <Button data-arg={i} onClick={this.deleteBinderPdf} color="danger" size="sm">
                                              <FontAwesomeIcon icon="trash" />
                                            </Button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </AvGroup> */}
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="3" id="statusLabel" for="underwriter-pin-image">
                                Pin Certificate
                              </Label>
                              <Col sm="5">
                                <ImageUploader
                                  withIcon
                                  singleImage
                                  defaultImage={pinImage}
                                  buttonText="Choose PIN Image"
                                  onChange={this.onPinImageChange(true, 'content')}
                                  imgExtension={['.jpg', '.jpeg', '.png']}
                                  maxFileSize={5242880}
                                />
                              </Col>
                              <Col sm="5">{pinImage && <img height="180" width="180" src={pinImage} />}</Col>
                            </Row>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Label sm="4" for="productTypes">
                                Product Types
                              </Label>
                              <Col sm="8">
                                <MultiSelect
                                  id="productTypes"
                                  name="productTypes"
                                  optionSelected={selectedOptions}
                                  isMulti
                                  handleChange={this.handleChangeProductType}
                                  options={productTypesOptions}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="statusLabel" for="underwriter-logo">
                            Logo
                          </Label>
                          <Col sm="5">
                            <ImageUploader
                              withIcon
                              singleImage
                              defaultImage={logo}
                              buttonText="Choose Logo Image"
                              onChange={this.onBlobChange(true, 'content')}
                              imgExtension={['.jpg', '.jpeg', '.png']}
                              maxFileSize={5242880}
                            />
                          </Col>
                          <Col sm="5">{logo && <img height="180" width="180" src={logo} />}</Col>
                        </Row>
                      </AvGroup>
                      <Row>
                        <Col md="12">
                          <AvField
                            id="underwriter-description"
                            type="textarea"
                            rows={3}
                            placeholder="Enter description"
                            name="description"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="pt-5 pb-5 text-center">
                          <h2 id="underwriter-product-heading">
                            Underwriter Products
                            {/*<Link to={`/entity/underwriter-product/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">*/}
                            {/*  <FontAwesomeIcon icon="plus" />*/}
                            {/*  &nbsp; Create new Product*/}
                            {/*</Link>*/}
                          </h2>
                        </Col>
                      </Row>
                      <Row className="pb-5">
                        <Col md="12">
                          <div className="table-container" ref={this.componentRef}>
                            <ReactTable
                              ref={this.reactTable}
                              columns={[
                                {
                                  Header: 'ID',
                                  accessor: 'id',
                                  width: 50,
                                  resizable: false,
                                  style: { display: 'flex', alignItems: 'center' }
                                },
                                {
                                  Header: 'Product Name',
                                  accessor: 'name',
                                  width: 200,
                                  resizable: true,
                                  headerStyle: { textAlign: 'left' },
                                  style: { display: 'flex', alignItems: 'center' },
                                  sortable: false,
                                  filterable: false
                                },
                                {
                                  Header: (
                                    <div className="header-container">
                                      <span>Product Status</span>
                                      <FontAwesomeIcon icon="sort" />
                                    </div>
                                  ),
                                  accessor: 'discountStatus',
                                  headerStyle: { textAlign: 'left' },
                                  style: { display: 'flex', alignItems: 'center' }
                                },
                                {
                                  Header: (
                                    <div className="header-container">
                                      <span>Discount</span>
                                      <FontAwesomeIcon icon="sort" />
                                    </div>
                                  ),
                                  accessor: 'discount',
                                  headerStyle: { textAlign: 'left' },
                                  style: { display: 'flex', alignItems: 'center' }
                                },
                                {
                                  Header: (
                                    <div className="header-container">
                                      <span>Start Date</span>
                                      <FontAwesomeIcon icon="sort" />
                                    </div>
                                  ),
                                  accessor: 'startDate',
                                  headerStyle: { textAlign: 'left' },
                                  style: { display: 'flex', alignItems: 'center' }
                                },
                                {
                                  Header: (
                                    <div className="header-container">
                                      <span>End Date</span>
                                      <FontAwesomeIcon icon="sort" />
                                    </div>
                                  ),
                                  accessor: 'endDate',
                                  headerStyle: { textAlign: 'left' },
                                  style: { display: 'flex', alignItems: 'center' }
                                },
                                {
                                  Header: (
                                    <div className="header-container">
                                      <span>Discount Status</span>
                                      <FontAwesomeIcon icon="sort" />
                                    </div>
                                  ),
                                  accessor: 'discountActive',
                                  headerStyle: { textAlign: 'left' },
                                  style: { display: 'flex', alignItems: 'center' }
                                },
                                {
                                  Header: 'Actions',
                                  accessor: 'actions',
                                  sortable: false,
                                  filterable: false
                                }
                              ]}
                              defaultPageSize={10}
                              pages={this.getMaxPage()}
                              onFetchData={this.fetchData}
                              loading={loadingUnderwriterProducts}
                              showPaginationBottom
                              data={tableData}
                              style={styles}
                              className="-striped -highlight"
                            />
                          </div>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/underwriter" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  underwriterEntity: storeState.underwriter.entity,
  loading: storeState.underwriter.loading,
  updating: storeState.underwriter.updating,
  updateSuccess: storeState.underwriter.updateSuccess,
  account: storeState.authentication.account,
  loadingUnderwriterProducts: storeState.underwriterProduct.loading,
  totalUnderwriterProducts: storeState.underwriterProduct.totalItems,
  underwriterProducts: storeState.underwriterProduct.entities,
  productTypes: storeState.productType.entities
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  updateProductEntity,
  createEntity,
  getProductTypes,
  reset,
  setBlob,
  setPinImage,
  setBinderPdf,
  deleteBinderPdf,
  getUnderwriterProducts
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderwriterUpdate);
