import { Moment } from 'moment';
import { ClaimGroup } from 'app/shared/model/enumerations/claim-group.model';

export interface IClaim {
  id?: number;
  brokerRefNo?: string;
  fileRefNo?: string;
  brokerClaimNo?: string;
  policyNo?: string;
  insurerClaimNo?: string;
  coverPeriod?: string;
  dateOfLoss?: string;
  claimGroup?: ClaimGroup;
  dateReported?: string;
  policeStationReportedTo?: string;
  garage?: string;
  claimAmount?: number;
  excessAmount?: number;
  settlementAmount?: number;
  adjusterAppointed?: string;
  quoteId?: number;
  clientId?: number;
  riskClassId?: number;
  underwriterId?: number;
  coverTypeId?: number;
  clientPolicyId?: number;
  kYCId?: number;
}

export const defaultValue: Readonly<IClaim> = {};
