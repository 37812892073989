import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import 'react-flags-select/css/react-flags-select.css';
import { IRootState } from 'app/shared/reducers';
import { CarBodyTypes } from 'app/config/constants';
import { createEntity } from '../../kyc/kyc.reducer';
import { createEntity as createAttachment, getEntities as getAttachments } from 'app/entities/attachment/attachment.reducer';
import {
  getEntities as getKYCs,
  getEntity as getKYC,
  setBlob,
  updateEntity as updateKYCEntity,
  setData
} from 'app/entities/kyc/kyc.reducer';
import { createEntity as createAttachmentFile } from 'app/entities/attachment-file/attachment-file.reducer';
import { getEntities as getCarMakes } from 'app/entities/car-make/car-make.reducer';
import { getEntities as getKYCTypes } from 'app/entities/kyc-type/kyc-type.reducer';
import { getEntityModels as getCarModels } from 'app/entities/car-model/car-model.reducer';
import { addVehicleAdditionalInfo } from 'app/shared/reducers/vehicle-adittional-info.reducer';
import { AttachmentType } from 'app/shared/model/attachment.model';
import DataTable from 'app/shared/common/dataTable';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty, isEqual, size } from 'lodash';
import { getEntities as getVehicleTypes, getEntity as getVehicleType } from 'app/entities/vehicle-type/vehicle-type.reducer';
import { getEntities as getTonnageRanges } from 'app/entities/tonnage-range/tonnage-range.reducer';
import sortBy from 'lodash/sortBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountriesList from 'app/assets/nationalities';
import { getSortState, IPaginationBaseState, setFileData } from 'react-jhipster';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import MultiSelect from 'app/shared/common/multi-select';
import InputItem from 'app/entities/direct-payment/components/InputItem';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { updateEntity as updatePayment } from 'app/entities/direct-payment/direct-payment.reducer';
import LightBox from '../components/LightBox';
import utils, { getContent, getAttachment } from 'app/config/utils';

export interface IClientKYCStepProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; id: string }> {
  jumpToStep;
}

export interface IClientKYCStepState extends IPaginationBaseState {
  carBodyTypes: any[];
  yearOfManufactures: any;
  currentYear: number;
  isNew: boolean;
  isFullScreen: boolean;
  countries: any[];
  selectedKYCTypes: any[];
  sumInsured: string;
}

class ClientKYCStep extends React.Component<IClientKYCStepProps, IClientKYCStepState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      carBodyTypes: CarBodyTypes,
      isNew: true,
      yearOfManufactures: [],
      currentYear: new Date().getFullYear(),
      isFullScreen: false,
      countries: CountriesList,
      selectedKYCTypes: [],
      sumInsured: ''
    };
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const { clientEntity, policyEntity } = this.props;
    const { companyId } = this.props.account;
    if (policyEntity && policyEntity.id) {
      this.props.getIdTypes(0, 2000, `,&companyId=${companyId}`);
      if (policyEntity.riskCategoryName === 'Motor') {
        this.props.getVehicleTypes(0, 200, `,&companyId=${companyId}`);
        this.props.getCarMakes(0, 2000);
        const year = new Date().getFullYear();
        this.setState({
          currentYear: year,
          yearOfManufactures: Array.from(new Array(42), (val, index) => ({
            name: String(year - index),
            id: year - index
          })).reverse()
        });
      }
    }
    if (clientEntity && clientEntity.id) {
      const riskCategoryId = policyEntity && policyEntity.riskCategoryId;
      this.fetchClientKYC(clientEntity.id, riskCategoryId).then(r => {
        if (this.props.kYCEntity) {
          if (size(this.props.kYCEntity.attachments) > 0) {
            const kycTypesVas = this.props.kYCTypes
              .filter(t => this.props.kYCEntity.attachments.map(a => a.type.replace('_IMAGE', '')).includes(t.name))
              .map(p => ({
                value: p.id,
                label: p.name
              }));
            this.setState({ selectedKYCTypes: kycTypesVas });
          }
          if (this.props.kYCEntity.carMakeId) {
            this.props.getCarModels(this.props.kYCEntity.carMakeId);
          }
        }
      });
    }
  }

  fetchClientKYC = async (id, riskCategoryId) => {
    const { productTypes } = this.props.account;
    const productTypeIds = productTypes.map(a => `productTypes_Id=${a.id}`);
    await this.props.getKYCs(0, 10, `id,desc&clientId=${id}&companyId=${1}&${productTypeIds.join('&')}`);
    if (size(this.props.kYCs) > 0) {
      this.setState({ isNew: false });
      await this.props.getKYC(this.props.kYCs[0].id);
    }
    riskCategoryId && (await this.props.getKYCTypes(0, 200, `,&riskCategoryId=${riskCategoryId}`));
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      const updatedUrl = this.props.location.search.replace(/(\?|&)wizard=2/, '$1wizard=3');
      this.props.history.push(`${updatedUrl}`);
      this.props.jumpToStep(3);
    }
  }

  async getCarModel(e) {
    if (!(event && event.target)) {
      return;
    }
    const make = await this.props.vehicleMakes.find(item => Number(item.id) === Number(e.target.value));
    this.props.getCarModels(make.id);
  }

  fetchData = state => {
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`companyId=${this.props.account.companyId}`);
    this.props.getAttachments(state.page, state.pageSize, `${state.sort || ''},${state.order || ''}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  };

  handleSubmit = async (event, errors, values) => {
    const { kYCEntity, clientEntity, directPayment } = this.props;
    values['companyId'] = this.props.account.companyId;
    if (errors.length === 0) {
      const kycData = {
        ...kYCEntity,
        ...values,
        clientId: clientEntity.id,
        ID: undefined,
        PIN: undefined,
        valuationReport: undefined,
        drivingLicence: undefined,
        logbookNumber: undefined
      };
      (await this.state.isNew) ? this.props.createEntity(kycData) : this.props.updateKYCEntity(kycData);
      const paymentEntity = {
        ...directPayment,
        kYCId: this.props.kYCEntity.id
      };
      !isEqual(JSON.stringify(directPayment), JSON.stringify(paymentEntity)) && (await this.props.updatePayment(paymentEntity));
    }
  };

  formatValue = value => {
    if (!value) return '0';
    if (Number.isNaN(value)) return '0';
    const re = `\\B(?=(\\d{${3}})+(?!\\d))`;
    this.setState({
      sumInsured: String(Number(value.replace(/\D/g, '')).toFixed(0)).replace(new RegExp(re, 'g'), ',')
    });
  };

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(1);
  }

  handleDataChange = (e, category) => {
    e.persist();
    this.props.setData(this.getType(category), e.target.name, e.target.value);
  };

  getType = name => {
    const typeMapping = {
      ID: AttachmentType.ID_IMAGE,
      PIN: AttachmentType.PIN_IMAGE,
      logbook: AttachmentType.LOG_BOOK_IMAGE,
      drivingLicence: AttachmentType.DRIVING_LICENCE_IMAGE,
      valuationReport: AttachmentType.VALUATION_REPORT_IMAGE
    };
    return typeMapping[name] || null;
  };

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(this.getType(name), data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(this.getType(name), undefined, undefined);
  };

  handleClick = () => {
    this.setState({ isFullScreen: true });
  };

  handleChangeKYCType = selectedKYCTypes => {
    this.setState({
      selectedKYCTypes
    });
  };

  getSelectedKYCTypeId = id => this.state.selectedKYCTypes.find(k => k.value === id);

  onCloseRequest = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  };

  render() {
    const {
      vehicleMakes,
      tonnageRanges,
      vehicleTypeEntity,
      vehicleTypes,
      kYCEntity,
      policyEntity,
      kYCTypes,
      vehicleModels,
      idTypes,
      clientEntity,
      clientCategories
    } = this.props;
    const { carBodyTypes, yearOfManufactures, selectedKYCTypes, isFullScreen, isNew, countries } = this.state;
    const { attachments } = kYCEntity;
    const kYCTypesOptions = kYCTypes.map(p => ({
      value: p.id,
      label: p.name
    }));
    const kycAttachmentsData =
      attachments &&
      attachments.map(prop => ({
        ...prop,
        id: (
          <Button tag={NavLink} to={`${prop.id}`} color="link" size="sm">
            {prop.id}
          </Button>
        ),
        actions: (
          <div className="text-center">
            <Input type="checkbox" onClick={e => {}} />
          </div>
        )
      }));
    return (
      <div className="step step2 mb-5">
        <AvForm model={isNew ? {} : kYCEntity} onSubmit={this.handleSubmit}>
          <div className="form-group mb-5 mt-5">
            <Row>
              <Col md={8}>
                <AvGroup>
                  <Row>
                    <Label sm="4" for="client-kycTypes">
                      KYC Types
                    </Label>
                    <Col sm="10">
                      <MultiSelect
                        id="client-kycTypes"
                        name="kycTypes"
                        optionSelected={selectedKYCTypes}
                        isMulti
                        handleChange={this.handleChangeKYCType}
                        options={kYCTypesOptions}
                      />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            {policyEntity['riskCategoryName'] === 'Motor' && (
              <>
                <Row>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="registrationLabel">
                          Registration
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="registrationLabel"
                            type="text"
                            className="form-control"
                            value={kYCEntity['registration']}
                            name="registration"
                            required
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="chassisNumberLabel">
                          Chassis Number
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="chassisNumberLabel"
                            type="text"
                            className="form-control"
                            name="chasisNumber"
                            value={kYCEntity['chasisNumber']}
                            required
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="engineNumberLabel">
                          Engine Number
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="engineNumberLabel"
                            type="text"
                            className="form-control"
                            name="engineNumber"
                            value={kYCEntity.engineNumber}
                            required
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="yearOfManufacture">
                          Year Of Manufacture
                        </Label>
                        <Col sm="8">
                          <AvInput
                            id="yearOfManufacture"
                            type="select"
                            className="form-control"
                            value={kYCEntity.year || ''}
                            name="year"
                            required
                          >
                            <option disabled value="" key="0">
                              {' '}
                              Select Year Of Manufacture{' '}
                            </option>
                            {yearOfManufactures
                              ? yearOfManufactures.map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="car-make">
                          Car Make
                        </Label>
                        <Col sm="8">
                          <AvInput
                            id="car-make"
                            type="select"
                            className="form-control"
                            value={kYCEntity.carMakeId || ''}
                            name="carMakeId"
                            onChange={e => this.getCarModel(e)}
                          >
                            <option disabled value="" key="0">
                              {' '}
                              Select Car Make{' '}
                            </option>
                            {vehicleMakes
                              ? vehicleMakes.map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="car-model">
                          Car Model
                        </Label>
                        <Col sm="8">
                          <AvInput
                            id="car-model"
                            type="select"
                            value={kYCEntity.carModelId || ''}
                            className="form-control"
                            name="carModelId"
                            required
                          >
                            <option disabled value="" key="0">
                              {' '}
                              Select Car Model{' '}
                            </option>
                            {vehicleModels
                              ? vehicleModels.map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="vehicle-type">
                          Vehicle Type
                        </Label>
                        <Col>
                          <AvInput
                            id="vehicle-type"
                            type="select"
                            className="form-control"
                            value={kYCEntity.vehicleTypeId || ''}
                            name="vehicleTypeId"
                            required
                          >
                            <option disabled value="" key="0">
                              {' '}
                              Select Vehicle Type{' '}
                            </option>
                            {vehicleTypes
                              ? vehicleTypes.map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="color">
                          Color
                        </Label>
                        <Col sm="6">
                          <AvField id="color" type="text" className="form-control" value={kYCEntity.color} name="color" required />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="valueOfCar">
                          Sum Insured
                        </Label>
                        <Col>
                          <AvField
                            id="valueOfCar"
                            type="text"
                            className="form-control"
                            value={kYCEntity.valueOfCar || ''}
                            name="valueOfCar"
                            required
                            onChange={value => this.formatValue(value.target.value)}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="valuationCentre">
                          Valuation Centre
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="valuationCentre"
                            type="text"
                            className="form-control"
                            value={kYCEntity['valuationCenter']}
                            name="valuationCenter"
                            required
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="effective-date">
                          Valuation Date
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="valuation-date"
                            type="date"
                            className="form-control"
                            value={kYCEntity.dateOfValuation || ''}
                            name="dateOfValuation"
                            required
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvGroup>
                      <Row>
                        <Label sm="4" for="car-body-type">
                          Car Body Type
                        </Label>
                        <Col sm="8">
                          <AvInput
                            id="car-body-type"
                            type="select"
                            value={kYCEntity.carBodyType || ''}
                            className="form-control"
                            name="carBodyType"
                            required
                          >
                            <option disabled value="" key="0">
                              {' '}
                              Select Car Body Type{' '}
                            </option>
                            {carBodyTypes
                              ? carBodyTypes.map(otherEntity => (
                                  <option value={otherEntity} key={otherEntity}>
                                    {otherEntity}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  {vehicleTypeEntity['hasRange'] && (
                    <Col md={3}>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="binder-rate-tonnageRange">
                            Tonnage Range
                          </Label>
                          <Col sm="8">
                            <AvInput id="binder-rate-tonnageRange" type="select" className="form-control" name="tonnageRangeId">
                              <option value="" key="0" />
                              {tonnageRanges
                                ? tonnageRanges.map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {`${otherEntity.minValue} - ${otherEntity.maxValue} ${otherEntity.unit}`}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </div>
          <hr className="mt-5 mb-4" />
          {attachments && (
            <Row>
              {selectedKYCTypes.find(k => k.value === 1) && (
                <Col md="6">
                  <h4>National ID</h4>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="client-idType">
                        Id Type
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="client-idType"
                          type="select"
                          value={clientEntity.idTypeId || ''}
                          className="form-control"
                          name="idTypeId"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {idTypes
                            ? sortBy(idTypes, ['name']).map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <InputItem
                    id="id-number"
                    name="name"
                    onChange={e => this.handleDataChange(e, 'ID')}
                    type={'text'}
                    placeholder={'Enter ID Number'}
                    label={'ID Number'}
                    value={getAttachment('ID', attachments)['name']}
                  />
                  <InputItem
                    id="DOB-date"
                    name="dateOfBirth"
                    onChange={e => this.handleDataChange(e, 'ID')}
                    type={'date'}
                    placeholder={'Enter DOB'}
                    label={'D.O.B'}
                    value={getAttachment('ID', attachments)['dateOfBirth']}
                  />
                  <InputItem
                    id="issue-date"
                    name="issueDate"
                    onChange={e => this.handleDataChange(e, 'ID')}
                    type={'date'}
                    placeholder={'Enter DOB'}
                    label={'Issue Date'}
                    value={getAttachment('ID', attachments)['issueDate']}
                  />
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="country-Issue">
                        Country of Issue
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="country-Issue"
                          type="select"
                          onChange={e => this.handleDataChange(e, 'ID')}
                          className="form-control"
                          // defaultValue="Kenya"
                          name="countryOfIssue"
                        >
                          <option>Select a Country</option>
                          {countries
                            ? countries.map(otherEntity => (
                                <option value={otherEntity.en_short_name} key={otherEntity.num_code}>
                                  {otherEntity.en_short_name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="country-Issue">
                        Upload Document
                      </Label>
                      <Col sm="8">
                        <div className="custom-file">
                          <input id="upload-id-document" type="file" className="form-control" onChange={this.onBlobChange(false, 'ID')} />
                          <AvInput type="hidden" name="ID" value={getContent('ID', attachments)} />
                          <Label className="custom-file-label custom-file-upload" for="upload-id-document">
                            <FontAwesomeIcon icon="cloud-upload-alt" />
                            Click to Upload...
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </AvGroup>
                  {getContent('ID', attachments) && (
                    <>
                      <Col md={4}>
                        <img height={150} width={300} src={utils.getImageUrl('ID', attachments)} alt={'ID-preview'} />
                      </Col>
                      <div className="image-button-container">
                        <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                          <FontAwesomeIcon icon="eye" />
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="button" color="danger" size="sm" onClick={this.clearBlob('ID')}>
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                      {isFullScreen && <LightBox attachments={attachments} name={'ID'} onCloseRequest={this.onCloseRequest} />}
                    </>
                  )}
                </Col>
              )}
              {selectedKYCTypes.find(k => k.value === 2) && (
                <Col md="6">
                  <h4>KRA PIN</h4>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="pin-type">
                        Pin Type
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="pin-type"
                          type="select"
                          onChange={e => this.handleDataChange(e, 'PIN')}
                          className="form-control"
                          name="pinType"
                          required
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {clientCategories
                            ? sortBy(clientCategories, ['name']).map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="pinNumber" for="pin-number">
                        Pin Number
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="pin-number"
                          onChange={e => this.handleDataChange(e, 'PIN')}
                          placeholder="Enter Pin Number"
                          value={getAttachment('PIN', attachments)['name']}
                          type="text"
                          name="name"
                        />
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="country-Issue">
                        Upload Document
                      </Label>
                      <Col sm="8">
                        <div className="custom-file">
                          <input id="upload-pin-document" type="file" className="form-control" onChange={this.onBlobChange(false, 'PIN')} />
                          <AvInput type="hidden" name="content" value={getContent('PIN', attachments)} />
                          <Label className="custom-file-label custom-file-upload" for="upload-pin-document">
                            <FontAwesomeIcon icon="cloud-upload-alt" />
                            Click to Upload...
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </AvGroup>
                  {getContent('PIN', attachments) && (
                    <div className="image-preview-container">
                      <div className="image-container">
                        <img height={150} width={300} src={utils.getImageUrl('PIN', attachments)} alt={'PIN-Preview'} />
                      </div>
                      <div className="image-button-container">
                        <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                          <FontAwesomeIcon icon="eye" />
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="button" color="danger" size="sm" onClick={this.clearBlob('PIN')}>
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                      {isFullScreen && <LightBox attachments={attachments} name={'PIN'} onCloseRequest={this.onCloseRequest} />}
                    </div>
                  )}
                </Col>
              )}
              {this.getSelectedKYCTypeId(3) && (
                <Col md="6">
                  <h4>Driving Licence</h4>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="drivingLicenceNumber" for="driving-licence-number">
                        DL Number
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="driving-licence-number"
                          onChange={e => this.handleDataChange(e, 'drivingLicence')}
                          value={getAttachment('drivingLicence', attachments)['name']}
                          placeholder="Enter DL Number"
                          type="text"
                          name="name"
                        />
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="dLDescription" for="dl-description">
                        Description
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="dl-description"
                          onChange={e => this.handleDataChange(e, 'drivingLicence')}
                          placeholder="Enter DL Description"
                          type="text"
                          name="description"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="country-Issue">
                        Upload Document
                      </Label>
                      <Col sm="8">
                        <div className="custom-file">
                          <input
                            id="upload-dl-document"
                            type="file"
                            className="form-control"
                            onChange={this.onBlobChange(false, 'drivingLicence')}
                          />
                          <AvInput type="hidden" name="drivingLicence" value={getContent('drivingLicence', attachments)} />
                          <Label className="custom-file-label custom-file-upload" for="upload-dl-document">
                            <FontAwesomeIcon icon="cloud-upload-alt" />
                            Click to Upload...
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </AvGroup>
                  {getContent('drivingLicence', attachments) && (
                    <div className="image-preview-container">
                      <div className="image-container">
                        <img
                          height={150}
                          width={300}
                          src={utils.getImageUrl('drivingLicence', attachments)}
                          alt={'drivingLicence-preview'}
                        />
                      </div>
                      <div className="image-button-container">
                        <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                          <FontAwesomeIcon icon="eye" />
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="button" color="danger" size="sm" onClick={this.clearBlob('drivingLicence')}>
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                      {isFullScreen && <LightBox attachments={attachments} name={'drivingLicence'} onCloseRequest={this.onCloseRequest} />}
                    </div>
                  )}
                </Col>
              )}
              {this.getSelectedKYCTypeId(4) && (
                <Col md="6">
                  <h4>Log Book</h4>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="logbookNumber" for="logbook-number">
                        Logbook Number
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="logbook-number"
                          onChange={e => this.handleDataChange(e, 'logbook')}
                          placeholder="Enter Logbook Number"
                          type="text"
                          value={getAttachment('logbook', attachments)['name']}
                          name="name"
                        />
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="description" for="description">
                        Description
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="logbookDescription"
                          onChange={e => this.handleDataChange(e, 'logbook')}
                          placeholder="Enter Logbook Description"
                          type="text"
                          name="description"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="country-Issue">
                        Upload Document
                      </Label>
                      <Col sm="8">
                        <div className="custom-file">
                          <input
                            id="upload-l-document"
                            type="file"
                            className="form-control"
                            onChange={this.onBlobChange(false, 'logbook')}
                          />
                          <AvInput type="hidden" name="logbookNumber" value={getContent('logbook', attachments)} />
                          <Label className="custom-file-label custom-file-upload" for="upload-l-document">
                            <FontAwesomeIcon icon="cloud-upload-alt" />
                            Click to Upload...
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </AvGroup>
                  {getContent('logbook', attachments) && (
                    <div className="image-preview-container mb-4">
                      <div className="image-contianer">
                        <img height={150} width={300} src={utils.getImageUrl('logbook', attachments)} alt={'logbook-preview'} />
                      </div>
                      <div className="image-button-container">
                        <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                          <FontAwesomeIcon icon="eye" />
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="button" color="danger" size="sm" onClick={this.clearBlob('logbook')}>
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                      {isFullScreen && <LightBox attachments={attachments} name={'logbook'} onCloseRequest={this.onCloseRequest} />}
                    </div>
                  )}
                </Col>
              )}
              {this.getSelectedKYCTypeId(5) && (
                <Col md="6">
                  <h4>Valuations Report</h4>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="valuationReportNumber" for="valuation-report-number">
                        Valuation Report Number
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="valuation-report-number"
                          onChange={e => this.handleDataChange(e, 'valuationReport')}
                          placeholder="Enter Valuation Number"
                          type="text"
                          value={getAttachment('valuationReport', attachments)['name']}
                          name="name"
                        />
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="description" for="description">
                        Description
                      </Label>
                      <Col sm="8">
                        <AvInput
                          id="description"
                          onChange={e => this.handleDataChange(e, 'valuationReport')}
                          placeholder="Enter Description"
                          type="text"
                          name="description"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="country-Issue">
                        Upload Document
                      </Label>
                      <Col sm="8">
                        <div className="custom-file">
                          <input
                            id="upload-vr-document"
                            type="file"
                            className="form-control"
                            onChange={this.onBlobChange(false, 'valuationReport')}
                          />
                          <AvInput type="hidden" name="valuationReport" value={getContent('valuationReport', attachments)} />
                          <Label className="custom-file-label custom-file-upload" for="upload-vr-document">
                            <FontAwesomeIcon icon="cloud-upload-alt" />
                            Click to Upload...
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </AvGroup>
                  {getContent('valuationReport', attachments) && (
                    <div className="image-preview-container">
                      <div className="image-container">
                        <img
                          height={150}
                          width={300}
                          src={utils.getImageUrl('valuationReport', attachments)}
                          alt={'valuationReport-preview'}
                        />
                      </div>
                      <div className="image-button-container">
                        <Button type="button" onClick={this.handleClick} color="primary" size="sm">
                          <FontAwesomeIcon icon="eye" />
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="button" color="danger" size="sm" onClick={this.clearBlob('valuationReport')}>
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                      {isFullScreen && <LightBox attachments={attachments} name={'logbook'} onCloseRequest={this.onCloseRequest} />}
                    </div>
                  )}
                </Col>
              )}
            </Row>
          )}
          <hr className="mt-5 mb-4" />
          <DataTable
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: 'Type',
                accessor: 'type'
              },
              {
                Header: 'Name',
                accessor: 'name'
              },
              {
                Header: 'ACTIONS',
                accessor: 'actions',
                sortable: false,
                filterable: false,
                style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
              }
            ]}
            manual
            defaultPageSize={5}
            pages={1}
            onFetchData={this.fetchData}
            showPaginationBottom
            tableData={kycAttachmentsData}
            filterable
          />
          <FormGroup className="mt-5">
            <Row>
              <Col sm="6">
                <Button type="button" color="info" onClick={this.handleBack}>
                  <span className="d-none d-md-inline">BACK</span>
                </Button>
              </Col>
              <Col sm="6" className="text-right">
                <Button type="submit" className="btn btn-success">
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </AvForm>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  vehicleMakes: storeState.carMake.entities,
  vehicleModels: storeState.carModel.entities,
  kYCEntity: storeState.kYC.entity,
  idTypes: storeState.idType.entities,
  attachment: storeState.attachment.entity,
  kYCs: storeState.kYC.entities,
  kYCTypes: storeState.kYCType.entities,
  attach: storeState.paymentCallbacks.attach,
  clientEntity: storeState.client.entity,
  vehicleTypes: storeState.vehicleType.entities,
  vehicleTypeEntity: storeState.vehicleType.entity,
  tonnageRanges: storeState.tonnageRange.entities,
  attachmentEntity: storeState.attachment.entity,
  clientPolicyEntity: storeState.clientPolicy.entity,
  policyEntity: storeState.policy.entity,
  directPayment: storeState.paymentCallbacks.entity,
  attachments: storeState.attachment.attachments,
  account: storeState.authentication.account,
  updating: storeState.kYC.updating,
  attachmentFile: storeState.attachmentFile.entity,
  updateSuccess: storeState.kYC.updateSuccess,
  attachmentUpdateSuccess: storeState.attachment.updateSuccess,
  clientCategories: storeState.clientCategory.entities
});

const mapDispatchToProps = {
  getCarMakes,
  getCarModels,
  updateKYCEntity,
  createEntity,
  createAttachment,
  createAttachmentFile,
  addVehicleAdditionalInfo,
  getVehicleTypes,
  getAttachments,
  getTonnageRanges,
  getKYC,
  getIdTypes,
  getClientCategories,
  getVehicleType,
  setBlob,
  setData,
  updatePayment,
  getKYCTypes,
  getKYCs
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClientKYCStep)
);
