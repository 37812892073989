import React, { useEffect, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';

const PDFPreview = ({ title, fileName, width, height, children, innerRef, className, ...props }) => {
  const [instance, updateInstance] = usePDF({ document: children });
  const [url, set] = useState();

  useEffect(() => {
    if (instance.blob) {
      set(
        URL.createObjectURL(
          new File([instance.blob], fileName, {
            type: instance.blob.type
          })
        )
      );
    }
  }, [instance]);

  useEffect(updateInstance, [children]);

  return (
    <>
      <iframe title={title} ref={innerRef} width={width} height={height} src={url} className={className} {...props} />
    </>
  );
};

export default PDFPreview;
