import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import Client from 'app/entities/client/index';

const EntityTemplate = props => (
  <MainTemplate>
    <Client {...props} />
  </MainTemplate>
);

export default EntityTemplate;
