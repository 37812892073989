import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, CustomInput, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IClientPolicy } from 'app/shared/model/client-policy.model';
import { getEntities as getClientPolicies } from 'app/entities/client-policy/client-policy.reducer';
import { getEntity, updateEntity, createEntity, reset } from './client-policy-payment.reducer';
import { IClientPolicyPayment } from 'app/shared/model/client-policy-payment.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import sortBy from 'lodash/sortBy';

export interface IClientPolicyPaymentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IClientPolicyPaymentUpdateState {
  isNew: boolean;
  clientPolicyId: string;
}

export class ClientPolicyPaymentUpdate extends React.Component<IClientPolicyPaymentUpdateProps, IClientPolicyPaymentUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      clientPolicyId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getClientPolicies(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { clientPolicyPaymentEntity } = this.props;
      const entity = {
        ...clientPolicyPaymentEntity,
        ...values,
        companyId: this.props.account.companyId
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/client-policy-payment');
  };

  render() {
    const { clientPolicyPaymentEntity, clientPolicies, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a ClientPolicyPayment
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew ? {} : clientPolicyPaymentEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="payDateLabel" for="client-policy-payment-payDate">
                            Pay Date
                          </Label>
                          <Col sm="10">
                            <AvField id="client-policy-payment-payDate" type="date" className="form-control" name="payDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="amountLabel" for="client-policy-payment-amount">
                            Amount
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="client-policy-payment-amount"
                              placeholder="Enter amount"
                              type="string"
                              className="form-control"
                              name="amount"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="paymentMethodLabel" for="client-policy-payment-paymentMethod">
                            Payment Method
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="client-policy-payment-paymentMethod"
                              type="select"
                              className="form-control"
                              name="paymentMethod"
                              value={(!isNew && clientPolicyPaymentEntity.paymentMethod) || 'CARD'}
                            >
                              <option value="CARD">CARD</option>
                              <option value="MPESA">MPESA</option>
                              <option value="CASH">CASH</option>
                              <option value="CHEQUE">CHEQUE</option>
                              <option value="BANK_TRANSFER">BANK_TRANSFER</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Col sm="2" />
                          <Col sm="10">
                            <div className="d-flex no-block align-items-center justify-content-between mb-4 mt-4">
                              <AvInput tag={CustomInput} type="checkbox" name="isIPF" label="Is IPF" />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="statusLabel" for="payment-status">
                            Status
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="payment-status"
                              type="select"
                              className="form-control"
                              name="status"
                              value={(!isNew && clientPolicyPaymentEntity.transactionStatus) || 'DEFAULT_STATUS'}
                            >
                              <option value="DEFAULT_STATUS">Pending</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="client-policy-payment-clientPolicy">
                            Client Policy
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="client-policy-payment-clientPolicy"
                              type="select"
                              className="form-control"
                              name="clientPolicyId"
                              required
                            >
                              {clientPolicies
                                ? sortBy(clientPolicies, ['clientName']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {`${otherEntity.clientName} | ${otherEntity.policyName}`}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/client-policy-payment" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clientPolicies: storeState.clientPolicy.entities,
  clientPolicyPaymentEntity: storeState.clientPolicyPayment.entity,
  loading: storeState.clientPolicyPayment.loading,
  updating: storeState.clientPolicyPayment.updating,
  updateSuccess: storeState.clientPolicyPayment.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getClientPolicies,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPolicyPaymentUpdate);
