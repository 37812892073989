import React from 'react';
import { connect } from 'react-redux';
import Header from 'app/shared/layout/header/header';
import Sidebar from 'app/shared/layout/sidebar/sidebar';
import Footer from 'app/shared/layout/footer/footer';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IMainTemplateProps extends StateProps, DispatchProps {}

export class MainTemplate extends React.Component<IMainTemplateProps> {
  componentDidMount() {
    const element = document.getElementById('main-wrapper');
    if (window.innerWidth < 1170) {
      element.setAttribute('data-sidebartype', 'mini-sidebar');
      element.classList.add('mini-sidebar');
    } else {
      element.setAttribute('data-sidebartype', 'mini-sidebar');
      element.classList.remove('mini-sidebar');
    }
  }

  render() {
    const { account, isAuthenticated, isSwaggerEnabled, isAdmin, isPartner, isAgent, ribbonEnv, isInProduction } = this.props;
    return (
      <div
        id="main-wrapper"
        dir="ltr"
        data-theme="light"
        data-layout="horizontal"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="boxed"
      >
        <ErrorBoundary>
          <Header
            account={account}
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            isPartner={isPartner}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isSwaggerEnabled={isSwaggerEnabled}
          />
          <Sidebar
            isAuthenticated={isAuthenticated}
            account={account}
            isAdmin={isAdmin}
            isAgent={isAgent}
            isPartner={isPartner}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isSwaggerEnabled={isSwaggerEnabled}
          />
        </ErrorBoundary>
        <div className="page-wrapper d-block" id="app-view-container">
          <div className="page-content container-fluid">{this.props.children}</div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication, applicationProfile }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isPartner: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PARTNER]),
  isAgent: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.AGENT]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled
});

const mapDispatchToProps = { getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainTemplate);
