import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import Home from 'app/modules/home/home';

const HomeTemplate = props => (
  <MainTemplate>
    <Home {...props} />
  </MainTemplate>
);

export default HomeTemplate;
