import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CarGeneration from './car-generation';
import CarGenerationDetail from './car-generation-detail';
import CarGenerationUpdate from './car-generation-update';
import CarGenerationDeleteDialog from './car-generation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CarGenerationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CarGenerationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CarGenerationDetail} />
      <ErrorBoundaryRoute path={match.url} component={CarGeneration} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CarGenerationDeleteDialog} />
  </>
);

export default Routes;
