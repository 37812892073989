export const enum PaymentMethod {
  CARD = 'CARD',

  MPESA = 'MPESA',

  PESAPAL = 'PESAPAL',

  CASH = 'CASH',

  CHEQUE = 'CHEQUE',

  BANK_TRANSFER = 'BANK_TRANSFER'
}
