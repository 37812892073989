import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import AgentHome from 'app/modules/home/agent-home';

const HomeTemplate = props => (
  <MainTemplate>
    <AgentHome {...props} />
  </MainTemplate>
);

export default HomeTemplate;
