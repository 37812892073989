import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { getEntity as getQuote } from 'app/entities/quote/quote.reducer';
import { createEntity, getEntityByEmail as getClient, updateEntity } from 'app/entities/client/client.reducer';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import PhoneInput from 'react-phone-number-input';
import ReactFlagsSelect from 'react-flags-select';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import _ from 'lodash';

export interface IClientDetailsProps extends StateProps, DispatchProps {
  id;
  jumpToStep;
}

export interface IClientDetailsState {
  isNew: boolean;
  idsadditionalBenefits: any[];
  underwriterId: string;
  clientId: string;
  riskClassId: bigint;
  coverTypeId: bigint;
  newClient: boolean;
  categoryId: any;
}

class ClientDetailsStep extends React.Component<IClientDetailsProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      idsadditionalBenefits: [],
      underwriterId: '0',
      clientId: '0',
      riskClassId: null,
      coverTypeId: null,
      newClient: false,
      categoryId: null
    };
  }

  onCountry = country => {
    this.setState({ country });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handlePhone = mobile => {
    this.setState({ telephone: mobile });
  };

  handleCategoryChange = event => {
    this.setState({ categoryId: Number(event.target.value) });
  };

  saveClient = (event, errors, values) => {
    if (errors.length === 0) {
      const { client, quote } = this.props;
      const entity = {
        ...client,
        ...values,
        companyId: this.props.account.companyId,
        mobile: this.state.mobile || this.props.quote.mobile,
        telephone: this.state.telephone,
        country: this.state.country,
        partner: quote.partner
      };
      if (!client) {
        this.props.createEntity(entity);
      } else {
        const individualClient = {
          pinNumber: client.pinNumber,
          status: client.status,
          firstName: client.firstName,
          middleName: client.middleName,
          lastName: client.lastName,
          idNumber: client.idNumber,
          email: client.email,
          idTypeId: client.idTypeId,
          categoryId: client.categoryId
        };
        const copClient = {
          address: client.address,
          categoryId: client.categoryId,
          clientName: client.clientName,
          contactPersion: client.contactPersion,
          county: client.county,
          ledgerName: client.ledgerName,
          notes: client.notes,
          streetAddress: client.streetAddress
        };
        if (_.isEqual(values, individualClient || copClient)) {
          this.props.jumpToStep(1);
        } else {
          this.props.updateEntity(entity);
        }
      }
    }
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(1);
    }
  }

  componentDidMount() {
    const quoteResult = Promise.resolve(this.props.getQuote(this.props.id));
    quoteResult.then(res => {
      const result = Promise.resolve(this.props.quote.email && this.props.getClient(this.props.quote.email));
      result.then(resp => {
        this.setState({
          categoryId: Number(this.props.client.categoryId),
          loadingClient: false
        });
        this.setState({ errorMessage: '' });
      });
      result.catch(err => {
        this.setState({
          loadingClient: false,
          newClient: true
        });
      });
    });
    this.props.getIdTypes(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getClientCategories(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  render() {
    const { categoryId, newClient } = this.state;
    const { account, clientCategories, quote, client, idTypes, updating } = this.props;

    return (
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          Client
        </CardTitle>
        <CardBody className="pr-0 pl-0">
          <AvForm onSubmit={this.saveClient}>
            <Row className="pl-3 pr-3">
              <Col md="12">
                <h4 className="font-medium mb-4">Client Details</h4>
              </Col>
            </Row>
            <Row className="pl-3 pr-3">
              <Col md="6">
                <AvGroup>
                  <Row>
                    <Label sm="3" for="client-category">
                      Client Category
                    </Label>
                    <Col sm="6">
                      <AvInput
                        id="client-category"
                        type="select"
                        className="form-control"
                        value={!newClient && client && client.categoryId}
                        name="categoryId"
                        onChange={category => this.handleCategoryChange(category)}
                        required
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {clientCategories
                          ? sortBy(clientCategories, ['name']).map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.name}
                              </option>
                            ))
                          : null}
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            {categoryId === 1 && (
              <div>
                <hr className="mt-0 mb-4" />
                <Row className="pl-3 pr-3 pt-3">
                  <Col md="12">
                    <h4 className="font-medium mb-4">Personal Info</h4>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="firstNameLabel" for="client-firstName">
                          First Name
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="client-firstName"
                            validate={{
                              required: { value: true, errorMessage: 'This field is required.' }
                            }}
                            value={client && client.firstName}
                            placeholder="Enter first name"
                            type="text"
                            name="firstName"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="idNumberLabel" for="client-idNumber">
                          Id Number
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="client-idNumber"
                            placeholder="Enter ID number"
                            value={client && client.idNumber}
                            type="text"
                            name="idNumber"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="middleNameLabel" for="client-middleName">
                          Middle Name
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="client-middleName"
                            placeholder="Enter middle name"
                            type="text"
                            value={client && client.middleName}
                            name="middleName"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="emailLabel" for="client-email">
                          Email
                        </Label>
                        <Col sm="8">
                          <AvField id="client-email" placeholder="Enter email address" type="text" name="email" value={quote.email} />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="lastNameLabel" for="client-lastName">
                          Last Name
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="client-lastName"
                            validate={{
                              required: { value: true, errorMessage: 'This field is required.' }
                            }}
                            placeholder="Enter last name"
                            type="text"
                            value={client && client.lastName}
                            name="lastName"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Row>
                        <Label sm="3" id="mobileLabel" for="client-mobile">
                          Mobile
                        </Label>
                        <Col sm="8">
                          <PhoneInput
                            placeholder="Enter mobile number"
                            value={quote.mobile}
                            id="client-mobile"
                            name="mobile"
                            onChange={this.handleMobile}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" for="client-idType">
                          Id Type
                        </Label>
                        <Col sm="8">
                          <AvInput
                            id="client-idType"
                            type="select"
                            value={!newClient && client && client.idTypeId}
                            className="form-control"
                            name="idTypeId"
                          >
                            <option value="" selected disabled>
                              Select
                            </option>
                            {idTypes
                              ? sortBy(idTypes, ['name']).map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="statusLabel" for="client-status">
                          Status
                        </Label>
                        <Col sm="8">
                          <AvInput id="client-status" type="select" className="form-control" name="status" value={'ACTIVE'}>
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                          </AvInput>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" for="pin-number">
                          Pin Number
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="pin-number"
                            validate={{
                              required: { value: false, errorMessage: 'This field is required.' }
                            }}
                            className="form-control"
                            value={client && client.pinNumber}
                            placeholder="Enter Pin"
                            type="text"
                            name="pinNumber"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
              </div>
            )}
            {categoryId === 2 && (
              <div>
                <hr className="mt-5 mb-4" />
                <Row className="pl-3 pr-3 pt-3">
                  <Col md="12">
                    <h4 className="font-medium mb-4">Company Info</h4>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="clientNameLabel" for="client-clientName">
                          Company Name
                        </Label>
                        <Col sm="8">
                          <AvField id="client-clientName" placeholder="Enter company name" type="text" name="clientName" />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="addressLabel" for="client-address">
                          Address
                        </Label>
                        <Col sm="8">
                          <AvField id="client-address" placeholder="Enter address" type="text" name="address" />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="ledgerNameLabel" for="client-ledgerName">
                          Ledger Name
                        </Label>
                        <Col sm="8">
                          <AvField id="client-ledgerName" placeholder="Enter ledger name" type="text" name="ledgerName" />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="streetAddressLabel" for="client-streetAddress">
                          Street Address
                        </Label>
                        <Col sm="8">
                          <AvField id="client-streetAddress" placeholder="Enter street address" type="text" name="streetAddress" />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="contactPersionLabel" for="client-contactPersion">
                          Contact Person
                        </Label>
                        <Col sm="8">
                          <AvField id="client-contactPersion" placeholder="Enter contact person" type="text" name="contactPersion" />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="countyLabel" for="client-county">
                          County
                        </Label>
                        <Col sm="8">
                          <AvField id="client-county" placeholder="Enter county" type="text" name="county" />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3 mb-2">
                  <Col md="6">
                    <FormGroup>
                      <Row>
                        <Label sm="3" id="telephoneLabel" for="client-telephone">
                          Telephone
                        </Label>
                        <Col sm="8">
                          <FormGroup>
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={quote.mobile}
                              id="client-telephone"
                              name="telephone"
                              onChange={this.handlePhone}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Row>
                        <Label sm="3" id="countryLabel" for="client-country">
                          Country
                        </Label>
                        <Col sm="8">
                          <FormGroup>
                            <ReactFlagsSelect id="client-country" defaultCountry={''} onSelect={this.onCountry} name="country" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="pl-3 pr-3">
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="notesLabel" for="client-notes">
                          Notes
                        </Label>
                        <Col sm="8">
                          <AvField id="client-notes" placeholder="Enter Notes" type="textarea" name="notes" />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Label sm="3" id="pinNumberLabel" for="client-pinNumber">
                          Postal Code
                        </Label>
                        <Col sm="8">
                          <AvField
                            id="client-pinNumber"
                            placeholder="Enter Postal Code"
                            type="number"
                            className="form-control"
                            name="pinNumber"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
              </div>
            )}
            <Row className="pl-3 pr-5 mt-4 mb-5">
              <Col md="12 text-right">
                <Button tag={Link} id="cancel-save" to={`/entity/quote`} replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp; &nbsp;
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> NEXT
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskCategories: storeState.riskCategory.entities,
  account: storeState.authentication.account,
  quote: storeState.quote.entity,
  updateSuccess: storeState.client.updateSuccess,
  clientCategories: storeState.clientCategory.entities,
  idTypes: storeState.idType.entities,
  updating: storeState.client.updating,
  client: storeState.client.entity
});

const mapDispatchToProps = {
  getQuote,
  getClient,
  getClientCategories,
  getIdTypes,
  updateEntity,
  createEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetailsStep);
