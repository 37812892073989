import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AgeRange from './age-range';
import AgeRangeDetail from './age-range-detail';
import AgeRangeUpdate from './age-range-update';
import AgeRangeDeleteDialog from './age-range-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AgeRangeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AgeRangeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AgeRangeDetail} />
      <ErrorBoundaryRoute path={match.url} component={AgeRange} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={AgeRangeDeleteDialog} />
  </>
);

export default Routes;
