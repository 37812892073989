import React, { DOMAttributes } from 'react';
import { Collapse } from 'reactstrap';
import MenuItem from 'app/shared/layout/menus/link-item';

export interface IPoliciesState {
  isOpen: boolean;
}

/* tslint:disable */
class PoliciesCollapseMenu extends React.Component<any, IPoliciesState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { isOpen } = this.state;
    const { showPayments } = this.props;
    return (
      <li className="sidebar-item">
        <span data-toggle="collapse" aria-expanded={isOpen} className="sidebar-link has-arrow" onClick={this.toggleCollapse}>
          <i className="mdi mdi-account-network" />
          <span className="hide-menu">Policies</span>
        </span>
        <Collapse className="first-level" isOpen={isOpen}>
          <ul>
            <MenuItem icon="mdi mdi-contacts" to="/client-policy">
              Client Policies
            </MenuItem>
            {/*<MenuItem icon="mdi mdi-contacts" to="/entity/policy">*/}
            {/*  Policy*/}
            {/*</MenuItem>*/}
            <MenuItem icon="mdi mdi-contacts" to="/entity/policy-benefit">
              Policy Benefit
            </MenuItem>
            {/*<MenuItem icon="mdi mdi-contacts" to="/entity/client-policy">*/}
            {/*  Client Policy*/}
            {/*</MenuItem>*/}
            <MenuItem icon="mdi mdi-contacts" to="/policy-renewal">
              Policy Renewal
            </MenuItem>
            {showPayments && (
              <MenuItem icon="mdi mdi-contacts" to="/entity/direct-payments">
                Direct Payments
              </MenuItem>
            )}
          </ul>
        </Collapse>
      </li>
    );
  }
}

export const PoliciesMenu = ({ showPayments }) => <PoliciesCollapseMenu showPayments={showPayments} />;

export default PoliciesMenu;
