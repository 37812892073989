import { ReminderType } from 'app/shared/model/enumerations/reminder-type.model';

export interface IReminder {
  id?: number;
  mobile?: string;
  email?: string;
  senderId?: string;
  message?: string;
  sendAt?: string;
  status?: boolean;
  messageType?: ReminderType;
}

export const defaultValue: Readonly<IReminder> = {
  status: false
};
