import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import DataTable from 'app/shared/common/dataTable';
import { getSortState, IPaginationBaseState } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './country.reducer';
import { ICountry } from 'app/shared/model/country.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface ICountryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ICountryState = IPaginationBaseState;

export class Country extends React.Component<ICountryProps, ICountryState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    this.props.getEntities(state.page, state.pageSize, `${sort},${order}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { loading, countryList, match } = this.props;
    const tableData = countryList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Countries
              </Col>
              <Col sm="6" className="text-right">
                <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                  Create new Country
                </NavLink>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Name',
                  accessor: 'name'
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ country }: IRootState) => ({
  loading: country.loading,
  countryList: country.entities,
  totalItems: country.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Country);
