import React, { Component } from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { Card, Row, Col, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { RouteComponentProps } from 'react-router-dom';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface IClientDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  isNew: boolean;
}

class ClientDetails extends React.Component<IClientDetailsProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  saveEntity = async (event, errors, values) => {};

  render() {
    const { quote, isNew, updating, isPartner } = this.props;
    return (
      <Card>
        <CardBody className="m-3 pb-5 pl-5 pr-5">
          <AvForm model={isNew ? {} : quote} onSubmit={this.saveEntity}>
            <Row className="d-flex justify-content-center p-5">
              <div className="t-header flex-2">
                <h4>Client Details</h4>
              </div>
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="firstNameLabel" for="client-firstName">
                      First Name
                    </Label>
                    <Col>
                      <AvField id="client-firstName" disabled={isPartner} placeholder="Enter first name" type="text" name="firstName" />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="lastNameLabel" for="client-lastName">
                      Last Name
                    </Label>
                    <Col>
                      <AvField id="client-lastName" disabled={isPartner} placeholder="Enter last name" type="text" name="lastName" />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="mobileLabel" for="client-mobile">
                      Mobile
                    </Label>
                    <Col className="font-bold">
                      <PhoneInput
                        placeholder="Mobile Number"
                        value={quote.mobile || ''}
                        id="client-mobile"
                        name="mobile"
                        disabled={isPartner}
                        onChange={() => {}}
                      />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
              <Col>
                <AvGroup>
                  <Row>
                    <Label sm="3" id="emailLabel" for="quote-email">
                      Email
                    </Label>
                    <Col>
                      <AvField id="quote-email" disabled={isPartner} type="text" name="email" />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            {/*<AvGroup>*/}
            {/*  <Row>*/}
            {/*    <Label sm="3" id="clientTypeLabel" for="quote-clientType">*/}
            {/*      Client Type*/}
            {/*    </Label>*/}
            {/*    <Col>*/}
            {/*      <AvInput*/}
            {/*        id="quote-clientType"*/}
            {/*        type="select"*/}
            {/*        className="form-control"*/}
            {/*        name="clientType"*/}
            {/*        value={ (!isNew && quote.clientType) || 'ACTIVE' }*/}
            {/*      >*/}
            {/*        <option value="ACTIVE">ACTIVE</option>*/}
            {/*        <option value="PROSPECTIVE">PROSPECTIVE</option>*/}
            {/*        <option value="INACTIVE">INACTIVE</option>*/}
            {/*      </AvInput>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</AvGroup>*/}
            <Row className={'pl-3 pr-5 mt-3 mb-5'}>
              <Col md="12 text-right">
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> UPDATE
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  quote: storeState.quote.entity,
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER]),
  updating: storeState.quote.updating
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetails);
