import React from 'react';
import { connect } from 'react-redux';
import { Card, CardTitle, Row, Col, Label, Button, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { Link } from 'react-router-dom';
import '../wizard/styles.css';
import PropTypes from 'prop-types';

export class AkiDetails extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
    }
  }

  componentDidMount() {}

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    const { loading, updating, akiResponse, akiErrors } = this.props;
    const akiResponseErrors = akiResponse ? akiResponse.Error : [];
    return (
      <div>
        <Card>
          <CardTitle className="bg-light border-bottom p-3 mb-0">
            <i className="mdi mdi-book mr-2" />
            Aki Certificate Generation
          </CardTitle>
          <CardBody>
            <Row className="justify-content-md-center">
              <Col lg={9}>
                <div className={'headerContainer'}>
                  <h3>{akiErrors || (akiResponseErrors.length && 'Error Occurred')}</h3>
                </div>
                <Row className={'mt-2'}>
                  {loading && <p>Loading...</p>}
                  {!loading &&
                    (akiErrors && (
                      <Col md={6} className={'primary'}>
                        {akiErrors}
                      </Col>
                    ))}
                </Row>
                {!loading && akiResponse && (
                  <Col className={'mt-2 '}>
                    <Row className={'justify-content-lg-end'}>
                      <Label sm="3">Request Number</Label>
                      <Col sm="5" className={'pt-1'}>
                        {akiResponse.APIRequestNumber}
                      </Col>
                    </Row>
                    <Row className={'justify-content-lg-end mb-2'}>
                      <Label sm="3">DMVICRefNo</Label>
                      <Col sm="5" className={'pt-1'}>
                        <div> {akiResponse.DMVICRefNo}</div>
                      </Col>
                    </Row>

                    {akiResponseErrors.map(err => (
                      <Row className={'primary'}>
                        <Label sm="4" for="risk-class-riskCategory">
                          {err.errorCode}
                        </Label>
                        <Col sm="8">
                          <div> {err.errorText}</div>
                        </Col>
                      </Row>
                    ))}
                    <Col />
                  </Col>
                )}

                {/*<Row className="pl-3 pr-5 mt-4 mb-5">*/}
                {/*  <Col md="12 text-right">*/}
                {/*    <Button tag={Link} id="cancel-save" replace color="info" onClick={this.handleClose}>*/}
                {/*      <span className="d-none d-md-inline">Close</span>*/}
                {/*    </Button>*/}
                {/*    &nbsp; &nbsp;*/}
                {/*    {!akiErrors && akiResponseErrors.length && (*/}
                {/*      <Button type="submit" className="btn btn-success" disabled={updating}>*/}
                {/*        <i className="fa fa-check" /> Save*/}
                {/*      </Button>*/}
                {/*    )}*/}
                {/*  </Col>*/}
                {/*</Row>*/}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.akiCertificateState.loading,
  updateSuccess: storeState.akiCertificateState.updateSuccess,
  akiResponse: storeState.akiCertificateState.response,
  clientCategories: storeState.clientCategory.entities,
  nameTitles: storeState.nameTitle.entities,
  idTypes: storeState.idType.entities,
  clientEntity: storeState.client.entity,
  updating: storeState.client.updating,
  handleClose: PropTypes.func,
  account: storeState.authentication.account
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AkiDetails);
