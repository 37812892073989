import { Moment } from 'moment';
import { IAttachment } from 'app/shared/model/attachment.model';
import { IKYCType } from 'app/shared/model/kyc-type.model';

export const enum KYCType {
  GENERAL = 'GENERAL',
  RISK_CLASS = 'RISK_CLASS'
}

export const enum GeneralKYCType {
  ID = 'ID',
  PIN = 'PIN',
  OTHER = 'OTHER'
}

export const enum MotorKYCType {
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  LOGBOOK = 'LOGBOOK',
  VALUATION_REPORT = 'VALUATION_REPORT',
  OTHER = 'OTHER'
}

export const enum CarBodyType {
  HATCHBACK = 'HATCHBACK',
  SEDAN = 'SEDAN',
  SUV = 'SUV',
  COUPE = 'COUPE',
  CONVERTIBLE = 'CONVERTIBLE',
  STATION_WAGON = 'STATION_WAGON',
  VAN = 'VAN'
}

export interface IKYC {
  id?: number;
  createdAt?: Moment;
  kycType?: KYCType;
  generalKYCType?: GeneralKYCType;
  customKYCType?: string;
  motorKYCType?: MotorKYCType;
  expiryDate?: Moment;
  carMakeId?: number;
  carMakeName?: string;
  carModelId?: number;
  carModelName?: string;
  year?: number;
  chasisNumber?: string;
  engineNumber?: string;
  color?: string;
  registration?: string;
  valueOfCar?: number;
  dateOfValuation?: Moment;
  valuationCenter?: string;
  carBodyType?: CarBodyType;
  clientId?: number;
  vehicleTypeId?: number;
  attachmentId?: number;
  contentContentType?: string;
  content?: any;
  attachments?: any[];
  kycTypes?: IKYCType[];
}

export const defaultValue: Readonly<IKYC> = {};
