import React from 'react';
import { Card, CardTitle, Row, Col, Label, Button, CardBody } from 'reactstrap';
import { AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// export interface IRiskClassFormulaParamsUpdateProps {}

export interface IRiskClassFormulaParamsState {
  formulaParams: any[];
}

export class RiskClassFormulaParams extends React.Component<{ formulaParams: any }, IRiskClassFormulaParamsState> {
  constructor(props) {
    super(props);
    this.state = {
      formulaParams: props.formulaParams
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleRemove(index) {
    const { formulaParams } = this.state;
    formulaParams.splice(index, 1);
    this.setState({ formulaParams });
  }
  handleClick() {
    this.setState(prevState => ({
      ...prevState,
      formulaParams: [...prevState.formulaParams, prevState.formulaParams[prevState.formulaParams.length - 1] + 1]
    }));
  }
  renderFields() {
    const { formulaParams } = this.state;
    const lastElementIndex = formulaParams.length - 1;
    return formulaParams.map((element, index) => (
      <Card key={element}>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <Row className="align-items-center">
            <Col sm="6">
              <i className="mdi mdi-book mr-2" />
              Risk Class Formula Params
            </Col>
            <Col sm="6" className="text-right">
              {lastElementIndex === index && (
                <Button type="button" className="btn btn-circle btn-success mr-2" onClick={this.handleClick}>
                  <FontAwesomeIcon icon="plus" />
                </Button>
              )}
              {index !== 0 && (
                <Button type="button" className="btn btn-circle btn-danger" onClick={() => this.handleRemove(index)}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              )}
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <AvGroup>
            <Row>
              <Label sm="2" id="nameLabel" for="formula-param-name">
                Name
              </Label>
              <Col sm="10">
                <AvField id="formula-param-name" type="text" name={`formula[${element}].name`} />
              </Col>
            </Row>
          </AvGroup>
          <AvGroup>
            <Row>
              <Label sm="2" id="calculationParamLabel" for="risk-class-formula-calculationParam">
                Calculation Param
              </Label>
              <Col sm="10">
                <AvInput
                  id="risk-class-formula-calculationParam"
                  type="select"
                  className="form-control"
                  name={`formulaParams[${element}].calculationParam`}
                  value={'SUM_INSURED'}
                >
                  <option value="SUM_INSURED">SUM_INSURED</option>
                  <option value="RATE">RATE</option>
                  <option value="NET_PREMIUM">NET_PREMIUM</option>
                  <option value="TL">TL</option>
                  <option value="PCF">PCF</option>
                  <option value="SD">SD</option>
                  <option value="GROSS_PREMIUM">GROSS_PREMIUM</option>
                  <option value="COMMISION_RATE">COMMISION_RATE</option>
                  <option value="USER_INPUT">USER_INPUT</option>
                  <option value="COMMISSION">COMMISSION</option>
                  <option value="FIXED">FIXED</option>
                </AvInput>
              </Col>
            </Row>
          </AvGroup>
        </CardBody>
      </Card>
    ));
  }
  render() {
    return this.renderFields();
  }
}
