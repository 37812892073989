import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getReports } from 'app/modules/reports/reducers/reports.reducer';
import SalesSummary from './sales-summary';
import EmailCampaign from './email-campaign';
import ActiveVisitors from './active-visitors';
import Stats from './stats';
import { getSortState, IPaginationBaseState } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { IQuoteState } from 'app/entities/quote/quote';

export interface IHomeProp extends StateProps, DispatchProps {}

export interface IHomeState {
  reportPeriod: string;
  uptakePeriod: string;
}

export class Home extends React.Component<IHomeProp, IHomeState> {
  constructor(props) {
    super(props);
    this.state = {
      reportPeriod: 'YEAR',
      uptakePeriod: 'YEAR'
    };
  }

  componentDidMount() {
    this.props.getSession();
    const { uptakePeriod, reportPeriod } = this.state;
    const { account, isAuthenticated, reports } = this.props;
    if (isAuthenticated) {
      this.props.getReports({ companyId: account.companyId, reportPeriod, uptakePeriod });
    }
  }

  onChangePeriod = value => {
    // console.log(value);
  };

  render() {
    const { account, reports } = this.props;
    return (
      <div>
        <Row>
          <Col xs={12}>
            <SalesSummary onChangePeriod={this.onChangePeriod} reports={reports} />
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <EmailCampaign reports={reports} />
          </Col>
          <Col md="4">
            <ActiveVisitors reports={reports} />
          </Col>
        </Row>
        <Stats />
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  reports: storeState.reports.entity
});

const mapDispatchToProps = { getSession, getReports };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
