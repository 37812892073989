import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IQuote, defaultValue, summaryValue } from 'app/shared/model/quote.model';

export const ACTION_TYPES = {
  FETCH_QUOTE_LIST: 'quote/FETCH_QUOTE_LIST',
  FETCH_SUB_QUOTE_LIST: 'quote/FETCH_SUB_QUOTE_LIST',
  FETCH_QUOTE: 'quote/FETCH_QUOTE',
  CREATE_QUOTE: 'quote/CREATE_QUOTE',
  SEND_ALL_QUOTE: 'quote/SEND_ALL_QUOTE',
  UPDATE_QUOTE: 'quote/UPDATE_QUOTE',
  REQUEST_QUOTE_PAYMENT: 'quote/REQUEST_QUOTE_PAYMENT',
  DELETE_QUOTE: 'quote/DELETE_QUOTE',
  SET_BLOB: 'quote/SET_BLOB',
  RESET: 'quote/RESET',
  SELECT_QUOTE_ITEM: 'quote/SELECT_QUOTE_ITEM',
  SET_INPUT_DATA: 'quote/SET_INPUT_DATA',
  UNSELECT_QUOTE_ITEM: 'quote/UNSELECT_QUOTE_ITEM',
  ADD_NOTIFICATION: 'quote/ADD_NOTIFICATION',
  GET_QUOTES_SUMMARY: 'quote/GET_QUOTES_SUMMARY'
};

const initialState = {
  loading: false,
  loadingSubQuotes: false,
  requestingPayment: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IQuote>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  paymentSuccessful: false,
  quotesSummary: summaryValue,
  subQuotes: [] as ReadonlyArray<IQuote>,
  summaryLoading: false,
  updateSuccess: false,
  inputData: {
    children: [],
    childDOBs: [],
    noOfChildren: 0
  },
  cart: [],
  cartTotal: 0
};

export type QuoteState = Readonly<typeof initialState>;

// Reducer

export default (state: QuoteState = initialState, action): QuoteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_QUOTE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_QUOTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_SUB_QUOTE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadingSubQuotes: true
      };
    case REQUEST(ACTION_TYPES.CREATE_QUOTE):
    case REQUEST(ACTION_TYPES.UPDATE_QUOTE):
    case REQUEST(ACTION_TYPES.DELETE_QUOTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.REQUEST_QUOTE_PAYMENT):
      return {
        ...state,
        requestingPayment: true,
        paymentSuccessful: false
      };
    case REQUEST(ACTION_TYPES.GET_QUOTES_SUMMARY):
      return {
        ...state,
        summaryLoading: true
      };
    case FAILURE(ACTION_TYPES.FETCH_QUOTE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUB_QUOTE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_QUOTE):
    case FAILURE(ACTION_TYPES.CREATE_QUOTE):
    case FAILURE(ACTION_TYPES.SEND_ALL_QUOTE):
    case FAILURE(ACTION_TYPES.UPDATE_QUOTE):
    case FAILURE(ACTION_TYPES.DELETE_QUOTE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        loadingSubQuotes: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.GET_QUOTES_SUMMARY):
      return {
        ...state,
        summaryLoading: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.REQUEST_QUOTE_PAYMENT):
      return {
        ...state,
        requestingPayment: false,
        paymentSuccessful: false
      };
    case SUCCESS(ACTION_TYPES.REQUEST_QUOTE_PAYMENT):
      return {
        ...state,
        requestingPayment: false,
        paymentSuccessful: true
      };
    case SUCCESS(ACTION_TYPES.FETCH_QUOTE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUB_QUOTE_LIST):
      return {
        ...state,
        loadingSubQuotes: false,
        subQuotes: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_QUOTE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.GET_QUOTES_SUMMARY):
      return {
        ...state,
        summaryLoading: false,
        quotesSummary: action.payload.data
      };
    case ACTION_TYPES.SET_INPUT_DATA:
      return {
        ...state,
        inputData: action.payload
      };
    case ACTION_TYPES.SELECT_QUOTE_ITEM:
      const item = action.payload;
      const product = state.cart.find(x => x.id === item.id);
      if (product) {
        return {
          ...state,
          cartTotal: state.cart.length,
          loading: false,
          totalItems: state.entities.length,
          updateSuccess: true,
          updating: false,
          cart: state.cart.map(x => (x.id === product.id ? product : x))
        };
      }
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        cart: [...state.cart, item],
        cartTotal: state.cart.length + 1
      };
    case ACTION_TYPES.UNSELECT_QUOTE_ITEM:
      return {
        ...state,
        cart: state.cart.filter(x => x.id !== action.payload),
        cartTotal: state.cart.length - 1
      };
    case SUCCESS(ACTION_TYPES.CREATE_QUOTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entities: action.payload.data,
        entity: action.payload.data.find(q => !q.underwriterId) || {}
      };
    case SUCCESS(ACTION_TYPES.UPDATE_QUOTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_QUOTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state as QuoteState;
  }
};

const apiUrl = 'api/quotes';

// Actions

export const getEntities: ICrudGetAllAction<IQuote> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/grouped${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE_LIST,
    payload: axios.get<IQuote>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getSubEntities: ICrudGetAllAction<IQuote> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/sub/all${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SUB_QUOTE_LIST,
    payload: axios.get<IQuote>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntities: ICrudGetAllAction<IQuote> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/all${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE_LIST,
    payload: axios.get<IQuote>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IQuote> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE,
    payload: axios.get<IQuote>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IQuote> = entity => async dispatch => {
  const quote = {
    date: entity.date,
    email: entity.email,
    mobile: entity.mobile,
    sumInsured: entity.sumInsured,
    coverTypeId: parseInt(String(entity.coverTypeId), 10),
    riskClassId: parseInt(String(entity.riskClassId), 10),
    vehicleTypeId: parseInt(String(entity.vehicleTypeId), 10),
    registration: entity.registration,
    firstName: entity.firstName,
    lastName: entity.lastName,
    coverTypeName: entity.coverTypeName,
    riskClassName: entity.riskClassName,
    clientId: entity.clientId,
    clientName: entity.clientName,
    clientType: entity.clientType,
    companyId: entity.companyId,
    tonnageRangeId: parseInt(String(entity.tonnageRangeId), 10),
    source: 'IBS Admin Portal',
    children: entity.children,
    budgetId: parseInt(String(entity.budgetId), 10),
    dateOfBirth: entity.dateOfBirth,
    noOfChildren: entity.noOfChildren,
    productTypeId: parseInt(String(entity.productTypeId), 10),
    spouseDOB: entity.spouseDOB,
    sendEmail: entity.sendEmail
  };
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_QUOTE,
    payload: axios.post(apiUrl, cleanEntity(quote))
  });

  // dispatch(getEntities());
  return result;
};

export const sendAllQuotes = quote => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SEND_ALL_QUOTE,
    payload: axios.post(`${apiUrl}/send-all-quotes`, cleanEntity(quote))
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IQuote> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_QUOTE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
export const requestPayment: ICrudPutAction<any> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.REQUEST_QUOTE_PAYMENT,
    payload: axios.post(`${apiUrl}/quotes-request-payment`, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IQuote> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_QUOTE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const selectQuoteItem = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SELECT_QUOTE_ITEM,
    payload: entity
  });
  return result;
};

export const setInputData = data => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_INPUT_DATA,
    payload: data
  });
  return result;
};

export const getQuotesSummary = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_QUOTES_SUMMARY,
    payload: axios.post('api/quotes-summary', entity)
  });
  return result;
};

export const unSelectQuoteItem = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UNSELECT_QUOTE_ITEM,
    payload: id
  });
  return result;
};
