import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getDebits, exportDebits } from 'app/entities/debit-note/debit-note.reducer';
import { getEntitiesSummary, getEntities as getRemits, exportRemits } from 'app/entities/remittance/remittance.reducer';
import { getEntities as getBillWises } from 'app/modules/reports/reducers/billwise-report.reducer';
import { getEntity as getClient } from 'app/entities/client/client.reducer';
import 'react-table/react-table.css';
import { FormGroup, Card, CardTitle, Label, Row, Col, Input, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import {
  getEntities as getClientPolicyPayments,
  remitPayments,
  exportClientPolicyPayments
} from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import {
  getEntities as getClientPolicies,
  getEntities as getRenewals,
  getEntity as getClientPolicy
} from 'app/entities/client-policy/client-policy.reducer';
import { connect } from 'react-redux';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import ReportTab from './report-tab';
import { RemitStatus } from 'app/shared/model/payment-callback.model';
import sortBy from 'lodash/sortBy';
import SelectTable from 'app/modules/policy-renewal/MyTable';
import { Remit } from './remittance/remit';

export interface IReportsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IReportsState extends IPaginationBaseState {
  selectedReportOption: string;
  allocateModal: boolean;
  clientName: string;
  startDate: string;
  endDate: string;
  selectClient: boolean;
  selectPolicy: boolean;
  policyName: string;
  underwriterId: string;
  selectAll: boolean;
  selection: any[];
  openRemit: boolean;
}

const allReportColumns = rowCheckboxRefs => [
  {
    Header: 'Select',
    accessor: 'selected',
    Cell: row => <input type="checkbox" ref={rowCheckboxRefs[row.index]} />
  },
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Created Date',
    accessor: 'createdAt'
  },
  {
    Header: 'Client Name',
    accessor: 'clientName'
  },
  {
    Header: 'Client Id',
    accessor: 'clientId'
  },
  {
    Header: 'Id Type',
    accessor: 'idTypeName'
  },
  {
    Header: 'Id Number',
    accessor: 'idNumber'
  },
  {
    Header: 'Debit Number',
    accessor: 'debitNoteId'
  },
  {
    Header: 'Total Amount',
    accessor: 'amount'
  },
  {
    Header: 'Trans Code',
    accessor: 'transCode'
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod'
  },
  {
    Header: 'Trans Date',
    accessor: 'transDate'
  },
  {
    Header: 'Total Clients',
    accessor: 'totalClients'
  },
  {
    Header: 'Client Policy Id',
    accessor: 'clientPolicyId'
  },
  {
    Header: 'Premium Payable',
    accessor: 'policyAmount'
  },
  {
    Header: 'Premium Paid',
    accessor: 'premiumPaid'
  },
  {
    Header: 'Client Balance',
    accessor: 'clientBalance'
  },
  {
    Header: 'Underwriter Name',
    accessor: 'underwriterName'
  },
  {
    Header: 'Underwriter Amount',
    accessor: 'underwriterAmount'
  },
  {
    Header: 'Amount Remitted',
    accessor: 'amountRemitted'
  },
  {
    Header: 'Underwriter Balance',
    accessor: 'underwriterBalance'
  },
  {
    Header: 'Pay Date',
    accessor: 'payDate'
  },
  {
    Header: 'Agent Code',
    accessor: 'agentCode'
  },
  {
    Header: 'Platform',
    accessor: 'platform'
  },
  {
    Header: 'Broker Commission',
    accessor: 'brokerCommission'
  },
  {
    Header: 'Agent Commission',
    accessor: 'agentCommission'
  },
  {
    Header: 'Is IPF',
    accessor: 'isIPF'
  },
  {
    Header: 'Transaction Code',
    accessor: 'paymentConfirmationNumber'
  },
  {
    Header: 'Payment ID',
    accessor: 'paymentId'
  },
  {
    Header: 'Id Type',
    accessor: 'idTypeName'
  },
  {
    Header: 'Mobile',
    accessor: 'mobile'
  },
  {
    Header: 'KYC Id',
    accessor: 'kycId'
  },
  {
    Header: 'Transaction Type',
    accessor: 'TransactionType'
  },
  {
    Header: 'Transaction ID',
    accessor: 'transID'
  },
  {
    Header: 'Transaction Amount',
    accessor: 'transAmount'
  },
  {
    Header: 'Pay Bill',
    accessor: 'businessShortCode'
  },
  {
    Header: 'Mobile',
    accessor: 'msisdn'
  },
  {
    Header: 'Bill Ref',
    accessor: 'billRefNumber'
  },
  {
    Header: 'Trans Time',
    accessor: 'TransTime'
  },
  {
    Header: 'Narration',
    accessor: 'remarks'
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: ({ filter, onChange }) => (
      <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : ''}>
        <option value="">Show All</option>
        <option value="ACTIVE">Active</option>
        <option value="PROSPECTIVE">Prospective</option>
        <option value="FORMER">Former</option>
      </select>
    )
  },
  {
    Header: 'Status',
    accessor: 'allocated',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Actions',
    accessor: 'remittanceActions',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Remitted',
    accessor: 'remitStatus',
    Filter: ({ filter, onChange }) => (
      <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : ''}>
        <option value="">Show All</option>
        <option value={RemitStatus.REMITTED}>Remitted</option>
        <option value={RemitStatus.NOT_REMITTED}>Not Recommitted</option>
      </select>
    )
  }
];

export class Reports extends React.Component<IReportsProps, IReportsState> {
  private readonly reactTable: React.RefObject<any>;
  private readonly rowCheckboxRefs: any;
  private form = React.createRef<any>();

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      selectedReportOption: '',
      allocateModal: false,
      startDate: '',
      endDate: '',
      selectClient: false,
      clientName: '',
      selectPolicy: false,
      policyName: '',
      underwriterId: '',
      selectAll: false,
      selection: [],
      openRemit: false
    };
    this.rowCheckboxRefs = [];
    this.reactTable = React.createRef();
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const param = 'activeTab';
    let currentParamValue = 'policyPayments';
    const hasQueryParam = searchParams.has(param);
    if (hasQueryParam) {
      currentParamValue = searchParams.get(param);
    }
    this.setState({ selectedReportOption: currentParamValue });
    this.props.history.push(`${this.props.location.pathname}?${param}=${currentParamValue}`);
  }

  componentDidMount() {
    const { account } = this.props;
    const productTypeIds = account.productTypes.map(a => `productTypes_Id=${a.id}`);
    this.props.getUnderwriters(0, 50, `,asc&companyId=${account.companyId}&${productTypeIds.join('&')}`);
    this.fetchData(this.reactTable.current.state);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.remitSuccess !== this.props.remitSuccess && nextProps.remitSuccess) {
      this.fetchData(this.reactTable.current.state);
    }
  }

  handleReportOptionChange = selectedOption => {
    const { log } = console;
    this.props.history.push(`${this.props.location.pathname}?activeTab=${selectedOption}`);
    // log(this.reactTable.current.state);
    this.setState({ selectedReportOption: selectedOption }, () => {
      if (this.reactTable.current) {
        this.fetchData(this.reactTable.current.state);
      }
    });
  };

  handleFilter = () => {
    this.fetchData(this.reactTable.current.state);
  };

  handleChange = (selectAll, selection) => {
    this.setState({ selectAll, selection });
  };

  getFilterParams = state => {
    const { startDate, endDate, underwriterId } = this.state;
    let size = 10;
    let queryParams = {
      page: 0,
      size,
      sort: `id,desc`,
      filters: []
    };
    if (state) {
      const { sorted, filtered, page, pageSize } = state;
      const sort = sorted && sorted.map(d => d.id).join();
      const order = sorted && sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
      size = pageSize || size;
      queryParams = {
        page: page || 0,
        size,
        sort: `${sort || 'createdAt'},${order || 'desc'}`,
        filters: []
      };
      if (filtered) {
        queryParams.filters.push(...filtered);
      }
    }
    queryParams.filters.push(`companyId=${this.props.account.companyId}`, `from=${startDate}`, `to=${endDate}`);
    underwriterId && queryParams.filters.push(`clientPolicy.policy.underwriterId=${underwriterId}`);
    return queryParams;
  };

  fetchData(state) {
    const { selectedReportOption } = this.state;
    const queryParams = this.getFilterParams(state);
    const filters = queryParams.filters.join('&');
    switch (selectedReportOption) {
      case 'policyPayments':
        this.props.getClientPolicyPayments(queryParams.page, queryParams.size, `${queryParams.sort}&${filters}`);
        break;
      case 'debitReports':
        this.props.getDebits(queryParams.page, queryParams.size, `${queryParams.sort}&${filters}`);
        break;
      case 'remittances':
        this.props.getEntitiesSummary(queryParams.page, queryParams.size, `${queryParams.sort}&${filters}`);
        break;
      default:
        break;
    }
    this.setState({ itemsPerPage: queryParams.size });
  }

  getMaxPage = () => {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  };

  handleUnderwriterChange = async e => {
    await this.setState({ underwriterId: e.target.value });
    this.fetchData(this.reactTable.current.state);
  };

  getColumnsForReportOption = (reportOption: string) => {
    switch (reportOption) {
      case 'commissionReport':
        return allReportColumns(this.rowCheckboxRefs).filter(c =>
          ['clientPolicyId', 'agentCommission', 'amount', 'brokerCommission'].includes(c.accessor)
        );
      case 'remittances':
        return allReportColumns(this.rowCheckboxRefs).filter(c =>
          ['underwriterName', 'transCode', 'transDate', 'paymentMethod', 'amount', 'totalClients', 'remittanceActions'].includes(c.accessor)
        );
      case 'renewalReports':
        return [];
      case 'riskNotes':
        return [];
      case 'debitReports':
        return allReportColumns(this.rowCheckboxRefs).filter(c =>
          ['debitType', 'clientName', 'clientPolicyId', 'underwriterName', 'productTypeId', 'remarks', 'createdAt', 'debitStatus'].includes(
            c.accessor
          )
        );
      case 'billWise':
        return allReportColumns(this.rowCheckboxRefs).filter(c =>
          [
            'name',
            'amount',
            'clientBalance',
            'debitType',
            'premiumPaid',
            'underwriterName',
            'underwriterAmount',
            'amountPaid',
            'amountRemitted',
            'underwriterBalance',
            'debitNoteId',
            'productTypeId'
          ].includes(c.accessor)
        );
      case 'policyPayments':
        return allReportColumns(this.rowCheckboxRefs).filter(c =>
          [
            'clientPolicyId',
            'clientName',
            'debitNoteId',
            'brokerCommission',
            'agentCommission',
            'underwriterAmount',
            'amount',
            'payDate',
            'isRemitted',
            'isDebited',
            'partial',
            'transactionStatus',
            'product',
            'isIPF',
            'remitStatus',
            'platform',
            'policy',
            'paymentMethod',
            'agentCode',
            'paymentConfirmationNumber'
          ].includes(c.accessor)
        );
      case 'reconciliationReports':
        return allReportColumns(this.rowCheckboxRefs).filter(c => ['id', 'firstName', 'lastName', 'mobile'].includes(c.accessor));
      default:
        return [];
    }
  };

  toggleRemitModal = () => {
    this.setState({ openRemit: !this.state.openRemit });
  };

  renderCreateButton() {
    const { selectedReportOption } = this.state;
    switch (selectedReportOption) {
      case 'SSAS':
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Policy
          </Link>
        );
      case '1':
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Claim
          </Link>
        );
      default:
        return <div className="btn btn-create-new visible-hidden">default button</div>;
    }
  }

  handleReset = () => {
    this.setState({ underwriterId: '', startDate: '', endDate: '' });
    // @ts-ignore
    this.form && this.form.reset();
  };

  handleValueChange = e => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleExport = () => {
    const { selectedReportOption } = this.state;
    const queryParams = this.getFilterParams(this.reactTable.current.state);
    switch (selectedReportOption) {
      case 'policyPayments':
        this.props.exportClientPolicyPayments(queryParams.page, queryParams.size, '', 'policyPayments');
        break;
      case 'debitReports':
        this.props.exportDebits(queryParams.page, queryParams.size, '', 'debits');
        break;
      case 'remittances':
        this.props.exportRemits(queryParams.page, queryParams.size, '', 'policyPayments');
        break;
      default:
        break;
    }
  };

  render() {
    const { loading, match, debits, billWises, remittances, underwriters, clientPolicyPayments, location, history } = this.props;
    const { selectedReportOption, selection, selectAll, underwriterId, startDate, endDate, openRemit } = this.state;
    const reportOptions = [
      { key: 'policyPayments', label: 'Client Policy Payments' },
      { key: 'debitReports', label: 'Debits' },
      { key: 'remittances', label: 'Remittance' },
      { key: 'billWise', label: 'Bill Wise' },
      { key: 'commissionReports', label: 'Commissions' },
      { key: 'renewalReports', label: 'Renewals' },
      { key: 'riskNotes', label: 'Risk Notes' },
      { key: 'reconciliationReports', label: 'Reconciliations' }
    ];
    let data;
    switch (selectedReportOption) {
      case 'policyPayments':
        data = clientPolicyPayments;
        break;
      case 'debitReports':
        data = debits;
        break;
      case 'remittances':
        data = remittances;
        break;
      case 'billWise':
        data = billWises;
        break;
      default:
        data = clientPolicyPayments;
    }
    data.forEach(row => {
      this.rowCheckboxRefs[row.index] = React.createRef();
    });
    const tableData = data.map(prop => ({
      ...prop,
      agentCommission: `${prop.agentCommission || 0}`,
      brokerCommission: `${prop.brokerCommission || 0}`,
      underwriterAmount: `${prop.underwriterAmount || 0}`,
      clientName: `${prop.firstName || ''} ${prop.middleName || ''} ${prop.lastName || ''}`,
      createdAt:
        prop.createdAt === null ? 'null' : <TextFormat type="date" value={`${prop.createdAt}`} format={APP_LOCAL_DATETIME_FORMAT} />,
      payDate: prop.payDate === null ? 'null' : <TextFormat type="date" value={`${prop.payDate}`} format={APP_LOCAL_DATETIME_FORMAT} />,
      allocated: (
        <Button color={prop.processed ? 'success' : 'primary'} tag={NavLink} to={`${match.url}/${prop.paymentId}/allocate`}>
          {prop.processed ? 'Allocated' : 'Not Allocated'}
        </Button>
      ),
      remitted: prop.isDebited && <Button color={prop.isRemitted ? 'success' : 'primary'}>{prop.isRemitted ? 'Remitted' : 'Remit'}</Button>,
      remittanceActions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/remittance`}>
            <i className="fa fa-eye" />
          </Button>
        </div>
      )
    }));

    const styles = {
      fontSize: '10px',
      textAlign: 'center'
    };

    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Reports
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <CardTitle className="mb-5 p-3 border-bottom bg-light">
              <Row>
                <Col sm="12">
                  <ReportTab activeTab={selectedReportOption} navTabItems={reportOptions} handleTab={this.handleReportOptionChange} />
                </Col>
                <Col sm="0" className="text-right">
                  {this.renderCreateButton()}
                </Col>
              </Row>
            </CardTitle>
            <AvForm onSubmit={this.handleFilter} ref={c => (this.form = c)}>
              <Row className="mb-5">
                <Col md={8}>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="start-date">
                            From
                          </Label>
                          <Col sm="8">
                            <AvField
                              id="start-date"
                              value={startDate}
                              type="date"
                              className="form-control"
                              onChange={this.handleValueChange}
                              name="startDate"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Row>
                          <Label sm="4" for="end-date">
                            To
                          </Label>
                          <Col sm="8">
                            <AvField
                              id="end-date"
                              value={endDate}
                              type="date"
                              className="form-control"
                              onChange={this.handleValueChange}
                              name="endDate"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {selectedReportOption === 'policyPayments' && (
                        <FormGroup className="mb-0">
                          <Row>
                            <Label sm="3" className="pr-0" for="risk-class-riskCategory">
                              Underwriter
                            </Label>
                            <Col sm="7">
                              <Input
                                id="report-underwriter"
                                type="select"
                                value={underwriterId}
                                onChange={event => this.handleUnderwriterChange(event)}
                              >
                                <option value="" key="">
                                  {' '}
                                  Select{' '}
                                </option>
                                {underwriters
                                  ? sortBy(underwriters, ['name']).map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.name}
                                      </option>
                                    ))
                                  : null}
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col sm={8}>
                      <Button color="primary" className="btn btn-block btn-info" onClick={this.handleFilter}>
                        <i className="mdi mdi-search-web" /> &nbsp; FILTER &nbsp;
                      </Button>
                    </Col>
                    <Col sm={4}>
                      <Button color="primary" type="button" onClick={this.handleReset}>
                        RESET &nbsp;
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-1 mb-5">
                <Col />
                {selection.length > 0 && (
                  <Col className="text-right">
                    <Button type="button" onClick={() => this.setState({ openRemit: !openRemit })} className="btn btn-block btn-success">
                      <i className="mdi mdi-file-pdf" />
                      &nbsp; REMIT
                    </Button>
                  </Col>
                )}
                <Col className="text-right">
                  <Button type="button" className="btn btn-success" onClick={this.handleExport}>
                    <i className="mdi mdi-file-pdf" />
                    &nbsp; Export
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <SelectTable
              selection={selection}
              selectAll={selectAll}
              styles={styles}
              onSelect={this.handleChange}
              tableRef={this.reactTable}
              columns={this.getColumnsForReportOption(selectedReportOption)}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              className="-striped -highlight"
              onFetchData={this.fetchData}
              showPaginationBottom
              data={tableData}
              keyField={'id'}
              style={styles}
              filterable
            />
            <Remit
              underwriters={this.props.underwriters}
              history={history}
              match={match}
              location={location}
              dataList={data.filter(p => this.state.selection.map(s => Number(s.substr(7))).includes(p.id))}
              open={openRemit}
              toggleModal={this.toggleRemitModal}
              account={this.props.account}
              remitPayments={this.props.remitPayments}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.underwriter.loading,
  underwriters: storeState.underwriter.entities,
  clientPolicies: storeState.clientPolicy.entities,
  clientPolicy: storeState.clientPolicy.entity,
  clientPolicyPayments: storeState.clientPolicyPayment.entities,
  totalItems: storeState.underwriter.totalItems,
  debits: storeState.debitNote.entities,
  remittances: storeState.remittance.entities,
  account: storeState.authentication.account,
  allocateModal: storeState.paymentCallbacks.allocateModal,
  remitSuccess: storeState.clientPolicyPayment.remitSuccess,
  updating: storeState.paymentCallbacks.updating,
  billWises: storeState.billWise.entities
});

const mapDispatchToProps = {
  getBillWises,
  getUnderwriters,
  getClientPolicyPayments,
  getRenewals,
  getClientPolicies,
  getClientPolicy,
  getDebits,
  getEntitiesSummary,
  getClient,
  remitPayments,
  exportClientPolicyPayments,
  exportDebits,
  getRemits,
  exportRemits
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
