import { IAttachmentFile } from './attachment-file.model';

export const enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface IUnderwriter {
  id?: number;
  name?: string;
  description?: string;
  logo?: string;
  binder?: string;
  website?: string;
  contactPersion?: string;
  telephone?: string;
  mobile?: string;
  email?: string;
  address?: string;
  streetAddress?: string;
  county?: string;
  country?: string;
  pinNumber?: number;
  taxpayerPin?: string;
  status?: Status;
  displayName?: string;
  healthDisplayName?: string;
  logoFile?: IAttachmentFile;
  pinImage?: IAttachmentFile;
  binderPdfs?: ReadonlyArray<IAttachmentFile>;
  productTypes?: any;
  companyId?: number;
  akiCompanyId?: number;
  comprehensiveAgeCap?: number;
  checkupAge?: number;
}

export const defaultValue: Readonly<IUnderwriter> = { logoFile: {}, binderPdfs: [], productTypes: [] };
