import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CarSpecification from './car-specification';
import CarSpecificationDetail from './car-specification-detail';
import CarSpecificationUpdate from './car-specification-update';
import CarSpecificationDeleteDialog from './car-specification-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CarSpecificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CarSpecificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CarSpecificationDetail} />
      <ErrorBoundaryRoute path={match.url} component={CarSpecification} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CarSpecificationDeleteDialog} />
  </>
);

export default Routes;
