import { Moment } from 'moment';
import { PayStatus } from 'app/shared/model/enumerations/pay-status.model';

export interface ITransactions {
  id?: number;
  transTime?: string;
  amount?: number;
  agentCommission?: number;
  status?: PayStatus;
  agentLogin?: string;
  agentId?: number;
  productTypeId?: number;
}

export const defaultValue: Readonly<ITransactions> = {};
