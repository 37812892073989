import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IProductType } from 'app/shared/model/product-type.model';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntities as getCompanies } from 'app/entities/company/company.reducer';
import { getEntity, updateEntity, createEntity, reset } from './short-codes.reducer';
import { IShortCodes } from 'app/shared/model/short-codes.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IShortCodesUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ShortCodesUpdate = (props: IShortCodesUpdateProps) => {
  const [idsproductType, setIdsproductType] = useState([]);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { shortCodesEntity, productTypes, loading, updating, companies } = props;

  const handleClose = () => {
    props.history.push('/entity/short-codes');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.getProductTypes(0, 20, `,&companyId=${props.account.companyId}`);
    props.getCompanies();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...shortCodesEntity,
        ...values,
        productTypes: mapIdList(values.productTypes)
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="ibsApp.shortCodes.home.createOrEditLabel">Create or edit a ShortCodes</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : shortCodesEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="short-codes-id">ID</Label>
                  <AvInput id="short-codes-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="shortcodeLabel" for="short-codes-shortcode">
                  Shortcode
                </Label>
                <AvField
                  id="short-codes-shortcode"
                  type="text"
                  name="shortcode"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="short-codes-description">
                  Description
                </Label>
                <AvField id="short-codes-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Row>
                  <Label sm="2" for="companyId">
                    Company
                  </Label>
                  <Col sm="10">
                    <AvInput
                      type="select"
                      className="form-control"
                      name="companyId"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Company is required.'
                        }
                      }}
                    >
                      <option value="" selected disabled>
                        Select Company
                      </option>
                      {companies.map(c => (
                        <option value={c.id} key={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </AvInput>
                  </Col>
                </Row>
              </AvGroup>
              <AvGroup>
                <Label for="short-codes-productType">Product Type</Label>
                <AvInput
                  id="short-codes-productType"
                  type="select"
                  multiple
                  className="form-control"
                  name="productTypes"
                  value={shortCodesEntity.productTypes && shortCodesEntity.productTypes.map(e => e.id)}
                >
                  <option value="" key="0" />
                  {productTypes
                    ? productTypes.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/entity/short-codes" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  productTypes: storeState.productType.entities,
  shortCodesEntity: storeState.shortCodes.entity,
  companies: storeState.company.entities,
  account: storeState.authentication.account,
  loading: storeState.shortCodes.loading,
  updating: storeState.shortCodes.updating,
  updateSuccess: storeState.shortCodes.updateSuccess
});

const mapDispatchToProps = {
  getProductTypes,
  getCompanies,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortCodesUpdate);
