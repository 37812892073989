import React from 'react';
import { components, default as ReactSelect } from 'react-select';

const Option = props => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
    </components.Option>
  </div>
);

const MultiSelect = ({ options, isMulti, handleChange, optionSelected, id, name }) => (
  <ReactSelect
    options={options}
    id={id}
    name={name}
    isMulti={isMulti}
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    components={{
      Option
    }}
    onChange={handleChange}
    allowSelectAll
    value={optionSelected}
  />
);

export default MultiSelect;
