import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './direct-payment.reducer';
import { getEntity as getClientPolicy } from 'app/entities/client-policy/client-policy.reducer';
import { getEntity as getPolicy } from 'app/entities/policy/policy.reducer';
import { getEntity as getInvoice } from 'app/entities/invoice/invoice.reducer';
import { getEntity as getKYC } from 'app/entities/kyc/kyc.reducer';
import { getEntity as getClient } from 'app/entities/client/client.reducer';
import { getEntity as getDebitNote } from 'app/entities/debit-note/debit-note.reducer';
// tslint:disable-next-line:no-unused-variable
import { PDFViewer } from '@react-pdf/renderer';
import DebitNote from 'app/entities/direct-payment/debit-wizard/debit-note';
import { DebitStatus } from 'app/shared/model/payment-callback.model';

export interface IDirectPaymentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class DirectPaymentDetail extends React.Component<IDirectPaymentDetailProps> {
  componentDidMount() {
    const paymentResult = Promise.resolve(this.props.getEntity(this.props.match.params.id));
    paymentResult.then(res => {
      const { directPaymentEntity } = this.props;
      const { log } = console;
      const start_actions = [];
      start_actions.push.apply(start_actions, [
        this.props.getClient(directPaymentEntity.clientId),
        this.props.getDebitNote(directPaymentEntity.debitNoteId),
        this.props.getClientPolicy(directPaymentEntity.clientPolicyId),
        this.props.getPolicy(directPaymentEntity.policyId),
        this.props.getKYC(directPaymentEntity.kYCId),
        this.props.getInvoice(directPaymentEntity.invoiceId)
      ]);
      Promise.all(start_actions);
    });
  }

  render() {
    const {
      directPaymentEntity,
      loading,
      account,
      kYCEntity,
      policyEntity,
      clientEntity,
      clientPolicyEntity,
      debitNoteEntity
    } = this.props;
    return (
      <Card>
        <CardBody>
          {loading ? (
            <div>Loading</div>
          ) : directPaymentEntity.debitStatus === DebitStatus.DEBITED ? (
            <Row className="justify-content-md-center">
              <Col md={12}>
                <div className="form-group mb-5 mt-5">
                  <PDFViewer width="100%" height="900px">
                    <DebitNote
                      account={account}
                      kYC={kYCEntity}
                      policy={policyEntity}
                      client={clientEntity}
                      clientPolicy={clientPolicyEntity}
                      debitNoteEntity={debitNoteEntity}
                    />
                  </PDFViewer>
                </div>
                {this.renderBackButton()}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md="12">
                <Card>
                  <CardTitle className="bg-light border-bottom p-3 mb-0">
                    <i className="mdi mdi-book mr-2" />
                    DirectPayment [<b>{directPaymentEntity.id}</b>]
                  </CardTitle>
                  <CardBody>
                    <dl className="jh-entity-details">
                      <dt>
                        <span id="name">Trans Is</span>
                      </dt>
                      // @ts-ignore
                      <dd>{directPaymentEntity.transID}</dd>
                      <dt>
                        <span id="brokerCommission">Amount</span>
                      </dt>
                      // @ts-ignore
                      <dd>{directPaymentEntity.transAmount}</dd>
                      <dt>
                        <span id="description">Description</span>
                      </dt>
                      <dd>{directPaymentEntity.MSISDN}</dd>
                      <dt>Risk Class</dt>
                      <dd>{directPaymentEntity.riskClassId ? directPaymentEntity.riskClassId : ''}</dd>
                    </dl>
                    {this.renderBackButton()}
                    &nbsp;
                    <Button tag={Link} to={`/entity/direct-payments/${directPaymentEntity.id}/edit`} replace color="primary">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    );
  }

  private renderBackButton() {
    return (
      <Button tag={Link} to="/entity/direct-payments" replace color="info">
        <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline"> Back </span>
      </Button>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  directPaymentEntity: storeState.paymentCallbacks.entity,
  debitNoteEntity: storeState.debitNote.entity,
  loading: storeState.paymentCallbacks.loading,
  updateSuccess: storeState.quote.updateSuccess,
  clientPolicyEntity: storeState.clientPolicy.entity,
  policyEntity: storeState.policy.entity,
  kYCEntity: storeState.kYC.entity,
  invoiceEntity: storeState.invoice.entity,
  clientEntity: storeState.client.entity,
  account: storeState.authentication.account
});

const mapDispatchToProps = { getEntity, getDebitNote, getClient, getPolicy, getKYC, getInvoice, getClientPolicy };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectPaymentDetail);
