import { Moment } from 'moment';

export const enum CommunicationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  CALL = 'CALL'
}

export const enum EntityName {
  QUOTE = 'QUOTE'
}

export interface ICommunicationInfo {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  dateTime?: Moment;
  type?: CommunicationType;
  entityName?: EntityName;
  entityId?: number;
  content?: string;
  userId?: number;
}

export const defaultValue: Readonly<ICommunicationInfo> = {};
