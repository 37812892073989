import React, { useState } from 'react';
import DataTable from 'app/shared/common/dataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import {
  FormGroup,
  Card,
  TabContent,
  TabPane,
  CardSubtitle,
  CardTitle,
  Label,
  Row,
  Col,
  Input,
  Button,
  CardBody,
  CustomInput
} from 'reactstrap';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from 'app/entities/underwriter/underwriter.reducer';
import {
  getEntity,
  toggleAllocate,
  getEntities as getDirectPayments,
  updateEntity
} from 'app/entities/direct-payment/direct-payment.reducer';
import {
  getEntities as getClientPolicies,
  getEntities as getRenewals,
  getEntity as getClientPolicy
} from 'app/entities/client-policy/client-policy.reducer';
import {
  createEntity as createClient,
  getClientByMobile,
  getEntities as getClients,
  getEntity as getClient,
  reset as resetClient
} from 'app/entities/client/client.reducer';
import { createEntity as createPolicyPayment } from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import { connect } from 'react-redux';
import { TransactionStatus, PaymentMethod } from 'app/shared/model/client-policy-payment.model';

export interface ISelectDataProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISelectDataState extends IPaginationBaseState {
  selectedReportOption: string;
  allocateModal: boolean;
  clientName: string;
  selectClient: boolean;
  selectPolicy: boolean;
  policyName: string;
  clientPolicyId: string;
  clientId: number;
}

export class SelectData extends React.Component<ISelectDataProps, ISelectDataState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      selectedReportOption: 'allClients',
      allocateModal: false,
      selectClient: false,
      clientName: '',
      selectPolicy: false,
      policyName: '',
      clientId: 0,
      clientPolicyId: '0'
    };
    this.fetchClientsData = this.fetchClientsData.bind(this);
  }

  componentDidMount() {
    this.props.resetClient();
    const result = Promise.resolve(this.props.getEntity(this.props.match.params.id));
    result.then(res => {
      const { log } = console;
      // @ts-ignore
      // log(this.props.directPayment.msisdn);
      Promise.resolve(this.props.getClientByMobile(this.props.directPayment.msisdn)).then(clientRes => {
        const { id } = this.props.client;
        if (id) {
          this.setState({ clientId: id });
          this.fetchClientPolicies(id);
        }
      });
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentWillUnmount() {
    this.props.resetClient();
  }

  fetchClientPolicies = id => {
    this.setState({ clientId: id });
    const { productTypes } = this.props.account;
    const productTypeIds = productTypes.map(a => `productTypes_Id=${a.id}`);
    this.props.getClientPolicies(0, 5, `id,desc&clientId=${id}&companyId=${this.props.client.companyId}&${productTypeIds.join('&')}`);
  };

  fetchClientsData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { productTypes, companyId } = this.props.account;
    filtered.push(`companyId=${companyId}`);
    const productTypeIds = productTypes.map(a => `productTypes_Id=${a.id}`);
    this.props.getClients(
      state.page,
      state.pageSize,
      `${sort || 'createdAt'},${order || 'desc'}&${productTypeIds.join('&')}&${filtered.join('&')}`
    );
    this.setState({ itemsPerPage: state.pageSize });
  }

  onPickClient = async (e, clientData) => {
    this.setState({ selectClient: !this.state.selectClient });
    const res = await this.props.getClient(clientData.id);
    if (this.props.client && this.props.client.id) {
      this.fetchClientPolicies(this.props.client.id);
    }
  };

  handleClose = () => {
    history.back();
  };

  saveEntity = (event, errors, values) => {
    const payment = {
      ...this.props.directPayment,
      processed: true
    };
    const { directPayment } = this.props;
    const { clientPolicyId, clientId } = this.state;
    const policyPayment = {
      agentCode: values.agentCode,
      payDate: moment(),
      // @ts-ignore
      amount: directPayment.transAmount,
      // @ts-ignore
      paymentConfirmationNumber: directPayment.transID,
      transactionStatus: TransactionStatus.SUCCESS,
      paymentMethod: PaymentMethod.MPESA,
      isIPF: false,
      clientPolicyId: Number(clientPolicyId),
      clientId,
      companyId: this.props.client.companyId
    };
    this.props.updateEntity(payment);
    this.props.createPolicyPayment(policyPayment);
  };

  onPickPolicy = (e, policyData) => {
    this.props.getClientPolicy(policyData.id);
    this.setState({ selectPolicy: !this.state.selectPolicy, clientPolicyId: policyData.id });
  };

  onSelectClient = () => {
    this.setState({ selectClient: !this.state.selectClient });
  };
  onSelectPolicy = () => {
    this.setState({ selectPolicy: !this.state.selectPolicy });
  };

  render() {
    const { selectClient, selectPolicy, clientId } = this.state;
    const { directPayment, clients, clientPolicies, client, clientPolicy, updating } = this.props;
    const clientsData = clients.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      idTypeId: prop.categoryId ? <NavLink to={`id-type/${prop.idTypeId}`}>{prop.idTypeId}</NavLink> : '',
      name: `${prop.firstName || ''} ${prop.middleName || ''} ${prop.lastName || ''}`,
      actions: (
        <div className="text-center">
          <Input type="checkbox" onClick={e => this.onPickClient(e, prop)} />
        </div>
      )
    }));

    const tablePoliciesData = clientPolicies.map(prop => ({
      ...prop,
      premiumPayable: `Ksh. ${String(prop.premiumPayable).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`,
      policyDate: (
        <TextFormat type="date" value={moment(prop.policyDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />
      ),
      startDate: <TextFormat type="date" value={moment(prop.startDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      endDate: <TextFormat type="date" value={moment(prop.endDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      policyId: prop.policyId ? <NavLink to={`policy/${prop.policyId}`}>{prop.policyId}</NavLink> : '',
      actions: (
        <div className="action-container">
          <Input type="checkbox" onClick={e => this.onPickPolicy(e, prop)} />
        </div>
      )
    }));

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <AvForm model={directPayment} onSubmit={this.saveEntity}>
              <Row className="mt-5">
                <Col md={clientId === 0 ? 8 : 12}>
                  <AvGroup>
                    <Label id="clientLabel" for="invoice-client">
                      Select Client
                    </Label>
                    <AvField
                      id="invoice-description"
                      type="text"
                      name="clientName"
                      onClick={this.onSelectClient}
                      value={client.firstName || client.lastName ? `${client.firstName} ${client.lastName}` : client.email}
                      placeholder="Client"
                      // validate={{
                      //   required: { value: true, errorMessage: 'This field is required.' }
                      // }}
                    />
                  </AvGroup>
                </Col>
                {clientId === 0 && (
                  <Col md={4}>
                    <Button tag={Link} id="cancel-save" to={`/entity/client/new?paymentId=${directPayment.paymentId}`} replace color="info">
                      <span className="d-none d-md-inline">New Client</span>
                    </Button>
                  </Col>
                )}
              </Row>
              {selectClient && clientId && (
                <DataTable
                  columns={[
                    {
                      Header: 'ID',
                      accessor: 'id'
                    },
                    {
                      Header: 'Client Name',
                      accessor: 'name'
                    },
                    {
                      Header: 'Mobile',
                      accessor: 'mobile'
                    },
                    {
                      Header: 'Id Number',
                      accessor: 'idNumber'
                    },
                    {
                      Header: 'Email',
                      accessor: 'email'
                    },
                    {
                      Header: 'Select',
                      accessor: 'select',
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: 'ACTIONS',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                      style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                    }
                  ]}
                  manual
                  defaultPageSize={5}
                  pages={1}
                  onFetchData={this.fetchClientsData}
                  showPaginationBottom
                  tableData={clientsData}
                  filterable
                />
              )}
              {clientId !== 0 && (
                <>
                  <Row className="mt-5">
                    <Col md={clientId === 0 ? 8 : 12}>
                      <AvGroup>
                        <Label id="policiesLabel" for="invoice-policies">
                          Policies
                        </Label>
                        <AvField
                          id="invoice-policies"
                          type="text"
                          name="policies"
                          placeholder="Client Policies"
                          onClick={this.onSelectPolicy}
                          value={clientPolicy.policyId}
                          // validate={{
                          //   required: { value: true, errorMessage: 'This field is required.' }
                          // }}
                        />
                      </AvGroup>
                    </Col>
                    {!clientPolicies.length && (
                      <Col md={4}>
                        <Button
                          tag={Link}
                          id="cancel-save"
                          to={`/entity/policy/new?clientId=${clientId}&paymentId=${directPayment.paymentId}`}
                          replace
                          color="info"
                        >
                          <span className="d-none d-md-inline">New Policy</span>
                        </Button>
                      </Col>
                    )}
                  </Row>

                  <div>
                    {selectPolicy && (
                      <>
                        <DataTable
                          columns={[
                            {
                              Header: 'ID',
                              accessor: 'id'
                            },
                            {
                              Header: 'Policy Name',
                              accessor: 'name'
                            },
                            {
                              Header: 'Policy Date',
                              accessor: 'policyDate'
                            },
                            {
                              Header: 'Start Date',
                              accessor: 'startDate'
                            },
                            {
                              Header: 'End Date',
                              accessor: 'endDate'
                            },
                            {
                              Header: 'Policy Id',
                              accessor: 'policyId',
                              sortable: false,
                              filterable: false
                            },
                            {
                              Header: 'ACTIONS',
                              accessor: 'actions',
                              sortable: false,
                              filterable: false,
                              style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                            }
                          ]}
                          manual
                          defaultPageSize={5}
                          pages={1}
                          noDataText={'No client policy found'}
                          onFetchData={() => {}}
                          showPaginationBottom
                          tableData={tablePoliciesData}
                          filterable
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              <AvGroup>
                <Label id="clientLabel" for="invoice-client">
                  Agent Code
                </Label>
                <AvField
                  id="payment-agentCode"
                  type="text"
                  name="agentCode"
                  placeholder="Agent Code"
                  // validate={{
                  //   required: { value: true, errorMessage: 'This field is required.' }
                  // }}
                />
              </AvGroup>
              <FormGroup>
                <Row>
                  <Col sm="12" className="text-center m-5">
                    <Button id="cancel-save" onClick={this.handleClose} color="info">
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">Cancel</span>
                    </Button>
                    &nbsp; &nbsp;
                    <Button type="submit" className="btn btn-success" disabled={updating}>
                      <i className="fa fa-check" /> Save
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </AvForm>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.underwriter.loading,
  loadingClients: storeState.client.loading,
  updateSuccess: storeState.paymentCallbacks.updateSuccess,
  clients: storeState.client.entities,
  client: storeState.client.entity,
  clientPolicies: storeState.clientPolicy.entities,
  clientPolicy: storeState.clientPolicy.entity,
  directPayments: storeState.paymentCallbacks.entities,
  account: storeState.authentication.account,
  allocateModal: storeState.paymentCallbacks.allocateModal,
  updating: storeState.paymentCallbacks.updating,
  directPayment: storeState.paymentCallbacks.entity
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  getDirectPayments,
  getRenewals,
  toggleAllocate,
  updateEntity,
  getClientPolicies,
  getClientPolicy,
  getClients,
  getClient,
  createClient,
  resetClient,
  getClientByMobile,
  createPolicyPayment
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectData);
