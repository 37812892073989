import { ClipLoader } from 'react-spinners';
import React from 'react';

const Loader = ({ loading, message, css, size }) => {
  if (size === undefined) {
    size = 40;
  }
  return loading ? (
    <div className="overlay-content">
      <div className="wrapper">
        <ClipLoader css={css} size={size} color={'#123abc'} loading={loading} />
        <span className="message">{message}</span>
      </div>
    </div>
  ) : null;
};

export default Loader;
