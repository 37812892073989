import { ProductType } from 'app/shared/model/enumerations/product-type.model';

export interface ICustomerAuthenticationToken {
  id?: number;
  token?: string;
  productType?: ProductType;
  customerAuthenticationId?: number;
}

export const defaultValue: Readonly<ICustomerAuthenticationToken> = {};
