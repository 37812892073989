export interface IBenefitDetails {
  id?: number;
  name?: string;
  productName?: string;
  value?: string;
  underwriterId?: number;
  underwriterName?: string;
  benefitTypeId?: number;
  waitingPeriod?: string;
  benefitTypeName?: string;
}

export const defaultValue: Readonly<IBenefitDetails> = {};
