import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IKYC, defaultValue } from 'app/shared/model/kyc.model';

export const ACTION_TYPES = {
  FETCH_KYC_LIST: 'kYC/FETCH_KYC_LIST',
  FETCH_KYC: 'kYC/FETCH_KYC',
  CREATE_KYC: 'kYC/CREATE_KYC',
  UPDATE_KYC: 'kYC/UPDATE_KYC',
  DELETE_KYC: 'kYC/DELETE_KYC',
  UPDATE_STATE_ENTITY_KYC: 'kYC/UPDATE_STATE_ENTITY_KYC',
  SET_BLOB: 'kYC/SET_BLOB',
  SET_DATA: 'kYC/SET_DATA',
  SET_ATTACHMENT_DATA: 'kYC/SET_ATTACHMENT_DATA',
  RESET: 'kYC/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IKYC>,
  entity: defaultValue,
  totalItems: 0,
  updating: false,
  updateSuccess: false
};

export type KYCState = Readonly<typeof initialState>;

// Reducer

export default (state: KYCState = initialState, action): KYCState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_KYC_LIST):
    case REQUEST(ACTION_TYPES.FETCH_KYC):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_KYC):
    case REQUEST(ACTION_TYPES.UPDATE_KYC):
    case REQUEST(ACTION_TYPES.DELETE_KYC):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_KYC_LIST):
    case FAILURE(ACTION_TYPES.FETCH_KYC):
    case FAILURE(ACTION_TYPES.CREATE_KYC):
    case FAILURE(ACTION_TYPES.UPDATE_KYC):
    case FAILURE(ACTION_TYPES.DELETE_KYC):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_KYC_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_KYC):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_KYC):
    case SUCCESS(ACTION_TYPES.UPDATE_KYC):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_KYC):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB:
      const { type, data, contentType } = action.payload;
      const attachmentEntity = state && state.entity && state.entity.attachments && state.entity.attachments.find(x => x.type === type);
      if (attachmentEntity) {
        return {
          ...state,
          entity: {
            ...state.entity,
            attachments: state.entity.attachments.map(a =>
              a.type === attachmentEntity.type
                ? {
                    ...attachmentEntity,
                    attachmentFile: { content: data, contentContentType: contentType }
                  }
                : a
            )
          }
        };
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          attachments: [
            ...(state.entity.attachments || []),
            {
              type,
              attachmentFile: { content: data, contentContentType: contentType }
            }
          ]
        }
      };
    case ACTION_TYPES.SET_ATTACHMENT_DATA:
      const attachmentData = state.entity.attachments && state.entity.attachments.find(x => x.type === action.payload.type);
      if (attachmentData) {
        return {
          ...state,
          entity: {
            ...state.entity,
            attachments: state.entity.attachments.map(a =>
              a.type === attachmentData.type
                ? {
                    ...attachmentData,
                    ...action.payload
                  }
                : a
            )
          }
        };
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          attachments: [...(state.entity.attachments || []), action.payload]
        }
      };
    case ACTION_TYPES.SET_DATA:
      const { log } = console;
      log(action.payload);
      const attachment = state.entity.attachments && state.entity.attachments.find(x => x.type === action.payload.type);
      if (attachment) {
        return {
          ...state,
          entity: {
            ...state.entity,
            attachments: state.entity.attachments.map(a =>
              a.type === attachment.type
                ? {
                    ...attachment,
                    [action.payload.name]: action.payload.value
                  }
                : a
            )
          }
        };
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          attachments: [
            ...(state.entity.attachments || []),
            {
              [action.payload.name]: action.payload.value,
              entityId: state.entity.id,
              type: action.payload.type
            }
          ]
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.UPDATE_STATE_ENTITY_KYC:
      return {
        ...state,
        entity: { ...state.entity, ...action.payload }
      };
    default:
      return state;
  }
};

const apiUrl = 'api/kycs';

// Actions

export const getEntities: ICrudGetAllAction<IKYC> = (page = 0, size = 10, sort = ' ,') => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_KYC_LIST,
    payload: axios.get<IKYC>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IKYC> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_KYC,
    payload: axios.get<IKYC>(requestUrl)
  };
};

export const getEntityByClientId: ICrudGetAction<IKYC> = id => {
  const requestUrl = `${apiUrl}/client/${id}`;
  return {
    type: ACTION_TYPES.FETCH_KYC,
    payload: axios.get<IKYC>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IKYC> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_KYC,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IKYC> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_KYC,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IKYC> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_KYC,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (type, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    type,
    data,
    contentType
  }
});

export const setData = (type, name, value?) => ({
  type: ACTION_TYPES.SET_DATA,
  payload: {
    type,
    name,
    value
  }
});

export const setAttachmentData = entity => ({
  type: ACTION_TYPES.SET_ATTACHMENT_DATA,
  payload: entity
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const updateStateEntity = entity => ({
  type: ACTION_TYPES.UPDATE_STATE_ENTITY_KYC,
  payload: entity
});
