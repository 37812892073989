export interface ICarSpecificationValue {
  id?: number;
  value?: string;
  unit?: string;
  carTrimId?: number;
  carTypeId?: number;
  carSpecificationId?: number;
}

export const defaultValue: Readonly<ICarSpecificationValue> = {};
