import React, { useEffect, useState } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
import { Tabs, Tab, makeStyles, AppBar } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { getEntities, updateRank } from '../underwriter.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { connect } from 'react-redux';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getSortState } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import TabPanel from 'app/entities/underwriter/ranking/tab-panel';

export interface IUnderwriterRankingProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Index = (props: IUnderwriterRankingProps) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    props.getProductTypes(0, 20, `id,asc&companyId=1`);
    props.getEntities(paginationState.activePage - 1, 100, `${paginationState.sort},${paginationState.order}&companyId=1`);
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.getEntities(paginationState.activePage - 1, 100, `${paginationState.sort},${paginationState.order}&companyId=1`);
  };

  const handleRankChange = async (underwriterId, productTypeId) => {
    await props.updateRank({ underwriterId, productTypeId });
    props.getEntities(paginationState.activePage - 1, 100, `${paginationState.sort},${paginationState.order}&companyId=1`);
  };

  const { underwriters, productTypes, loading, updating } = props;

  return (
    <div>
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <Row>
            <Col sm="6">
              <i className="mdi mdi-border-right mr-2" />
              Underwriter Ranking
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {productTypes &&
                productTypes.map((productType, index) => <Tab key={productType.name} label={productType.name} {...a11yProps(index)} />)}
            </Tabs>
          </AppBar>
          {productTypes &&
            productTypes.map((productType, index) => (
              <TabPanel
                key={productType.name}
                handleRankChange={handleRankChange}
                productTypeId={productType.id}
                underwriters={underwriters.filter(u => u.productTypes.map(p => p.id).includes(productType.id))}
                value={value}
                loading={loading}
                updating={updating}
                index={index}
              />
            ))}
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ underwriter, productType }: IRootState) => ({
  loading: underwriter.loading,
  updating: underwriter.updating,
  underwriters: underwriter.entities,
  productTypes: productType.entities.filter(p => p.name !== 'Medical')
});

const mapDispatchToProps = {
  getEntities,
  getProductTypes,
  updateRank
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));
