import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './levies-default-data.reducer';
import { ILeviesDefaultData } from 'app/shared/model/levies-default-data.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ILeviesDefaultDataUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ILeviesDefaultDataUpdateState {
  isNew: boolean;
}

export class LeviesDefaultDataUpdate extends React.Component<ILeviesDefaultDataUpdateProps, ILeviesDefaultDataUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { leviesDefaultDataEntity } = this.props;
      const entity = {
        ...leviesDefaultDataEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/levies-default-data');
  };

  render() {
    const { leviesDefaultDataEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="ibsApp.leviesDefaultData.home.createOrEditLabel">Create or edit a LeviesDefaultData</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : leviesDefaultDataEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="levies-default-data-id">ID</Label>
                    <AvInput id="levies-default-data-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="chargeNameLabel" for="levies-default-data-chargeName">
                    Charge Name
                  </Label>
                  <AvField id="levies-default-data-chargeName" type="text" name="chargeName" />
                </AvGroup>
                <AvGroup>
                  <Label id="abbreviationLabel" for="levies-default-data-abbreviation">
                    Abbreviation
                  </Label>
                  <AvField id="levies-default-data-abbreviation" type="text" name="abbreviation" />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="levies-default-data-description">
                    Description
                  </Label>
                  <AvField id="levies-default-data-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label id="countryLabel" for="levies-default-data-country">
                    Country
                  </Label>
                  <AvField id="levies-default-data-country" type="text" name="country" />
                </AvGroup>
                <AvGroup>
                  <Label id="startDateLabel" for="levies-default-data-startDate">
                    Start Date
                  </Label>
                  <AvField id="levies-default-data-startDate" type="date" className="form-control" name="startDate" />
                </AvGroup>
                <AvGroup>
                  <Label id="endDateTimeLabel" for="levies-default-data-endDateTime">
                    End Date Time
                  </Label>
                  <AvField id="levies-default-data-endDateTime" type="date" className="form-control" name="endDateTime" />
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel" for="levies-default-data-status">
                    Status
                  </Label>
                  <AvInput
                    id="levies-default-data-status"
                    type="select"
                    className="form-control"
                    name="status"
                    value={(!isNew && leviesDefaultDataEntity.status) || 'ACTIVE'}
                  >
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="rateTypeLabel" for="levies-default-data-rateType">
                    Rate Type
                  </Label>
                  <AvInput
                    id="levies-default-data-rateType"
                    type="select"
                    className="form-control"
                    name="rateType"
                    value={(!isNew && leviesDefaultDataEntity.rateType) || 'FIXED'}
                  >
                    <option value="FIXED">FIXED</option>
                    <option value="PERCENTAGE">PERCENTAGE</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="rateValueLabel" for="levies-default-data-rateValue">
                    Rate Value
                  </Label>
                  <AvField id="levies-default-data-rateValue" type="string" className="form-control" name="rateValue" />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/levies-default-data" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  leviesDefaultDataEntity: storeState.leviesDefaultData.entity,
  loading: storeState.leviesDefaultData.loading,
  updating: storeState.leviesDefaultData.updating,
  updateSuccess: storeState.leviesDefaultData.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeviesDefaultDataUpdate);
