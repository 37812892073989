import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUnderwriter } from 'app/shared/model/underwriter.model';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { IServiceProviderType } from 'app/shared/model/service-provider-type.model';
import { getEntities as getServiceProviderTypes } from 'app/entities/service-provider-type/service-provider-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './service-provider.reducer';
import { IServiceProvider } from 'app/shared/model/service-provider.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IServiceProviderUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IServiceProviderUpdateState {
  isNew: boolean;
  idsunderwriter: any[];
  idsserviceProviderType: any[];
}

export class ServiceProviderUpdate extends React.Component<IServiceProviderUpdateProps, IServiceProviderUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      idsunderwriter: [],
      idsserviceProviderType: [],
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUnderwriters(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getServiceProviderTypes();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { serviceProviderEntity } = this.props;
      const entity = {
        ...serviceProviderEntity,
        ...values,
        underwriters: mapIdList(values.underwriters),
        serviceProviderTypes: mapIdList(values.serviceProviderTypes)
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/service-provider');
  };

  render() {
    const { serviceProviderEntity, underwriters, serviceProviderTypes, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="ibsApp.serviceProvider.home.createOrEditLabel">Create or edit a ServiceProvider</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : serviceProviderEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="service-provider-id">ID</Label>
                    <AvInput id="service-provider-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="service-provider-name">
                    Name
                  </Label>
                  <AvField id="service-provider-name" type="text" name="name" />
                </AvGroup>
                <AvGroup>
                  <Label id="typeLabel" for="service-provider-type">
                    Type
                  </Label>
                  <AvField id="service-provider-type" type="text" name="type" />
                </AvGroup>
                <AvGroup>
                  <Label id="contactPersonLabel" for="service-provider-contactPerson">
                    Contact Person
                  </Label>
                  <AvField id="service-provider-contactPerson" type="text" name="contactPerson" />
                </AvGroup>
                <AvGroup>
                  <Label id="branchLabel" for="service-provider-branch">
                    Branch
                  </Label>
                  <AvField id="service-provider-branch" type="text" name="branch" />
                </AvGroup>
                <AvGroup>
                  <Label id="ratingLabel" for="service-provider-rating">
                    Rating
                  </Label>
                  <AvField id="service-provider-rating" type="string" className="form-control" name="rating" />
                </AvGroup>
                <AvGroup>
                  <Label id="countyLabel" for="service-provider-county">
                    County
                  </Label>
                  <AvField id="service-provider-county" type="text" name="county" />
                </AvGroup>
                <AvGroup>
                  <Label id="divisionLabel" for="service-provider-division">
                    Division
                  </Label>
                  <AvField id="service-provider-division" type="text" name="division" />
                </AvGroup>
                <AvGroup>
                  <Label id="longitudeLabel" for="service-provider-longitude">
                    Longitude
                  </Label>
                  <AvField id="service-provider-longitude" type="string" className="form-control" name="longitude" />
                </AvGroup>
                <AvGroup>
                  <Label id="latitudeLabel" for="service-provider-latitude">
                    Latitude
                  </Label>
                  <AvField id="service-provider-latitude" type="string" className="form-control" name="latitude" />
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="service-provider-email">
                    Email
                  </Label>
                  <AvField id="service-provider-email" type="text" name="email" />
                </AvGroup>
                <AvGroup>
                  <Label id="phoneLabel" for="service-provider-phone">
                    Phone
                  </Label>
                  <AvField id="service-provider-phone" type="text" name="phone" />
                </AvGroup>
                <AvGroup>
                  <Label id="userTypeLabel" for="service-provider-userType">
                    User Type
                  </Label>
                  <AvInput
                    id="service-provider-userType"
                    type="select"
                    className="form-control"
                    name="userType"
                    value={(!isNew && serviceProviderEntity.userType) || 'COMPANY'}
                  >
                    <option value="COMPANY">COMPANY</option>
                    <option value="INDIVIDUAL">INDIVIDUAL</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="branchTypeLabel" for="service-provider-branchType">
                    Branch Type
                  </Label>
                  <AvInput
                    id="service-provider-branchType"
                    type="select"
                    className="form-control"
                    name="branchType"
                    value={(!isNew && serviceProviderEntity.branchType) || 'HEAD_OFFICE'}
                  >
                    <option value="HEAD_OFFICE">HEAD_OFFICE</option>
                    <option value="BRANCH">BRANCH</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="addressLabel" for="service-provider-address">
                    Address
                  </Label>
                  <AvField id="service-provider-address" type="text" name="address" />
                </AvGroup>
                <AvGroup>
                  <Label id="streetAddressLabel" for="service-provider-streetAddress">
                    Street Address
                  </Label>
                  <AvField id="service-provider-streetAddress" type="text" name="streetAddress" />
                </AvGroup>
                <AvGroup>
                  <Label id="pinNumberLabel" for="service-provider-pinNumber">
                    Pin Number
                  </Label>
                  <AvField id="service-provider-pinNumber" type="text" name="pinNumber" />
                </AvGroup>
                <AvGroup>
                  <Label for="service-provider-underwriter">Underwriter</Label>
                  <AvInput
                    id="service-provider-underwriter"
                    type="select"
                    multiple
                    className="form-control"
                    name="underwriters"
                    value={serviceProviderEntity.underwriters && serviceProviderEntity.underwriters.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {underwriters
                      ? underwriters.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="service-provider-serviceProviderType">Service Provider Type</Label>
                  <AvInput
                    id="service-provider-serviceProviderType"
                    type="select"
                    multiple
                    className="form-control"
                    name="serviceProviderTypes"
                    value={serviceProviderEntity.serviceProviderTypes && serviceProviderEntity.serviceProviderTypes.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {serviceProviderTypes
                      ? serviceProviderTypes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/service-provider" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  underwriters: storeState.underwriter.entities,
  serviceProviderTypes: storeState.serviceProviderType.entities,
  serviceProviderEntity: storeState.serviceProvider.entity,
  loading: storeState.serviceProvider.loading,
  updating: storeState.serviceProvider.updating,
  updateSuccess: storeState.serviceProvider.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getUnderwriters,
  getServiceProviderTypes,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderUpdate);
