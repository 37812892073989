import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Reports from './reports';
// import { ReportsUpdate } from 'app/modules/reports/reports-update';
import SelectData from './selelct-data';
import Remittance from './remittance/remittance';
// import { ReportsDetail } from 'app/modules/reports/reports-detail';
// import { ReportsDeleteDialog } from 'app/modules/reports/reports-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      {/*<ErrorBoundaryRoute exact path={`${match.url}/new`} component={ReportsUpdate} />*/}
      {/*<ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ReportsUpdate} />*/}
      <ErrorBoundaryRoute exact path={`${match.url}/:id/allocate`} component={SelectData} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/remittance`} component={Remittance} />
      {/*<ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ReportsDetail} />*/}
      <ErrorBoundaryRoute path={match.url} component={Reports} />
    </Switch>
    {/*<ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ReportsDeleteDialog} />*/}
  </>
);

export default Routes;
