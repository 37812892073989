import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/scenes/login';
import Register from 'app/scenes/register';
import RegisterInitSuccess from 'app/scenes/register-init-success';
import RegisterSuccess from 'app/scenes/register-success';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetInitSuccess from 'app/scenes/reset-init-success';
import PasswordResetFinishSuccess from 'app/scenes/reset-finish-success';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import RegisterSetup from 'app/modules/account/register/register-setup';
import Logout from 'app/scenes/logout';
import Home from 'app/scenes/home';
import AgentHome from 'app/scenes/agent-home';
import Entities from 'app/scenes/entity';
import Claims from 'app/scenes/claims';
import Reports from 'app/scenes/reports';
import Invoice from 'app/scenes/invoice';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import Quote from 'app/scenes/quote';
import PolicyRenewal from 'app/scenes/policy-renewal';
import ClientPolicy from 'app/scenes/client-policy';
import Agent from 'app/scenes/agent';
import Aki from 'app/scenes/aki';
import Client from 'app/scenes/clients';

// tslint:disable:space-in-parens
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/scenes/account'),
  loading: () => <div>loading ...</div>
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/scenes/admin'),
  loading: () => <div>loading ...</div>
});
// tslint:enable

const Routes = () => (
  <div className="view-routes">
    <Switch>
      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/register/success" component={RegisterSuccess} />
      <ErrorBoundaryRoute path="/register/init-success" component={RegisterInitSuccess} />
      <ErrorBoundaryRoute path="/register" component={Register} />
      <ErrorBoundaryRoute path="/register-setup/finish/:key?" component={RegisterSetup} />
      <ErrorBoundaryRoute path="/activate/:key?" component={Activate} />
      <ErrorBoundaryRoute path="/reset/request" component={PasswordResetInit} />
      <ErrorBoundaryRoute path="/reset/init-success" component={PasswordResetInitSuccess} />
      <ErrorBoundaryRoute path="/reset/finish-success" component={PasswordResetFinishSuccess} />
      <ErrorBoundaryRoute path="/reset/finish/:key?" component={PasswordResetFinish} />
      <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPER_ADMIN, AUTHORITIES.PARTNER]} />
      <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
      <PrivateRoute path="/quotes" component={Quote} hasAnyAuthorities={[AUTHORITIES.USER, AUTHORITIES.PARTNER]} />
      <PrivateRoute path="/policy-renewal" component={PolicyRenewal} hasAnyAuthorities={[AUTHORITIES.USER]} />
      <PrivateRoute path="/client-policy" component={ClientPolicy} hasAnyAuthorities={[AUTHORITIES.USER]} />
      <PrivateRoute path="/agents" component={Agent} hasAnyAuthorities={[AUTHORITIES.PARTNER]} />
      <PrivateRoute path="/issue-sticker" component={Aki} hasAnyAuthorities={[AUTHORITIES.USER, AUTHORITIES.ADMIN, AUTHORITIES.PARTNER]} />
      <PrivateRoute path="/clients" component={Client} hasAnyAuthorities={[AUTHORITIES.USER, AUTHORITIES.PARTNER]} />
      <PrivateRoute path="/claims" component={Claims} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/reports" component={Reports} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/invoice" component={Invoice} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/entity" component={Entities} hasAnyAuthorities={[AUTHORITIES.USER, AUTHORITIES.PARTNER]} />
      <PrivateRoute path="/dashboard" component={Home} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.PARTNER]} />
      <PrivateRoute path="/agent" component={AgentHome} hasAnyAuthorities={[AUTHORITIES.AGENT]} />
      <ErrorBoundaryRoute path="/" exact component={Login} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  </div>
);

export default Routes;
