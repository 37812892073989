import React from 'react';
import { connect } from 'react-redux';
import { size } from 'lodash';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, TabContent, TabPane, CardSubtitle, CardTitle, Row, Col, Input, Button, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { TextFormat, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './client.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getInvoices } from 'app/entities/invoice/invoice.reducer';
import { getEntities as getClaims } from 'app/entities/claim/claim.reducer';
import { getEntities as getKYCs, getEntity as getKYC } from 'app/entities/kyc/kyc.reducer';
import { updateEntity as updateAttachment } from 'app/entities/attachment/attachment.reducer';
import DataTable from 'app/shared/common/dataTable';
import { IClient } from 'app/shared/model/client.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import ClientTab from './client-tab';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import { DebitStatus } from 'app/shared/model/payment-callback.model';

const attachmentTypes = [
  { id: '1', name: 'ID', verified: false },
  { id: '1', name: 'PIN', verified: true },
  { id: '1', name: 'LOGBOOK', verified: false }
];

export interface IClientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClientDetail extends React.Component<IClientDetailProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    };
  }

  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
    if (this.props.clientEntity.id) {
      const { companyId, id } = this.props.clientEntity;
      this.props.getRiskClasses(0, 2000, `,&companyId=${companyId}`);
      this.props.getInvoices(0, 2000, `,&companyId=${companyId}&clientId=${id}`);
      this.props.getClaims(0, 2000, `,&companyId=${companyId}&clientId=${id}`);
    }
  }

  handleTab = selectedTab => {
    this.setState({ activeTab: selectedTab });
    if (selectedTab === 5) {
      const { companyId, id } = this.props.clientEntity;
      this.props.getKYCs(0, 2000, `,&companyId=${companyId === 1 ? '' : companyId}&clientId=${id}`);
    }
  };

  renderCreateButton() {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 1:
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Policy
          </Link>
        );
      case 2:
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Claim
          </Link>
        );
      case 3:
        return (
          <Link to={`/entity/policy/new`} color="success" className="btn btn-create-new">
            Create New Invoice
          </Link>
        );
      case 5:
        return (
          <Link
            to={`${this.props.clientKYCs.length ? '/entity/kyc/' + this.props.clientKYCs[0].id + '/edit' : '/entity/kyc/new'}`}
            color="success"
            className="btn btn-create-new"
          >
            Create New KYC Document
          </Link>
        );
      case 6:
        return (
          <Link to={`/entity/kyc/new`} color="success" className="btn btn-create-new">
            Create New Client
          </Link>
        );
      default:
        return <div className="btn btn-create-new visible-hidden">default button</div>;
    }
  }

  getCurrentLogo(file) {
    if (file && file.content) {
      return `data:${file.contentContentType};base64, ${file.content}`;
    }
    return null;
  }

  toggleVerified = attachment => () => {
    this.props.updateAttachment({
      ...attachment,
      activated: !attachment.verified
    });
  };

  render() {
    const { activeTab } = this.state;
    const { clientEntity, riskClasses, clientClaims, clientInvoices, clientKYCs, account } = this.props;
    const { clientPolicies } = clientEntity;
    const sizeOfPolicies = size(clientPolicies);
    const sizeOfClaims = size(clientClaims);
    const sizeOfInvoices = size(clientInvoices);
    const { log } = console;
    log(account);
    const policyData =
      clientPolicies &&
      clientPolicies.map(prop => ({
        ...prop,
        startDate: <TextFormat type="date" value={prop.startDate} format={APP_LOCAL_DATE_FORMAT} />,
        endDate: <TextFormat type="date" value={prop.endDate} format={APP_LOCAL_DATE_FORMAT} />,
        actions: (
          <div className="text-center">
            <Button color="primary" size="sm" round="true" icon="true" tag={Link} to={`/entity/client-policy/${prop.id}`}>
              <i className="fa fa-eye" />
            </Button>
          </div>
        )
      }));

    const kycsData =
      clientKYCs &&
      clientKYCs.map(prop => ({
        ...prop,
        date: <TextFormat type="date" value={`${prop.createdAt}`} format={APP_LOCAL_DATE_FORMAT} />,
        actions: (
          <div className="text-center">
            <Button color="primary" size="sm" round="true" icon="true" tag={Link} to={`/entity/client-policy/${prop.id}`}>
              <i className="fa fa-eye" />
            </Button>
          </div>
        )
      }));

    const invoicesData =
      clientInvoices &&
      clientInvoices.map(prop => ({
        ...prop,
        startDate: <TextFormat type="date" value={`${prop.date}`} format={APP_LOCAL_DATE_FORMAT} />,
        actions: (
          <div className="text-center">
            <Button color="primary" size="sm" round="true" icon="true" tag={Link} to={`/entity/client-policy/${prop.id}`}>
              <i className="fa fa-eye" />
            </Button>
          </div>
        )
      }));
    const claimsData =
      clientInvoices &&
      clientInvoices.map(prop => ({
        ...prop,
        startDate: <TextFormat type="date" value={`${prop.date}`} format={APP_LOCAL_DATE_FORMAT} />,
        actions: (
          <div className="text-center">
            <Button color="primary" size="sm" round="true" icon="true" tag={Link} to={`/entity/client-policy/${prop.id}`}>
              <i className="fa fa-eye" />
            </Button>
          </div>
        )
      }));
    return (
      <React.Fragment>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mt-2">
                  <div>
                    <h2 className="font-bold mb-0">{`${clientEntity.firstName} ${clientEntity.lastName}`}</h2>
                    <h6 className="font-medium text-muted mb-0">
                      Client Since <TextFormat type="date" value={clientEntity.createdAt} format="MMM DD YYYY" />
                    </h6>
                  </div>
                </div>
              </CardBody>
              <CardBody className="border-top">
                <Row>
                  <Col md="6">
                    <div className="d-flex align-items-center">
                      <p className="font-normal text-muted m-0">Status</p>
                      <p className="m-0 ml-2 text-primary text-primary">{clientEntity.status}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="font-light text-muted m-0">Category</p>
                      <p className="m-0 ml-2 text-primary text-primary">{clientEntity.categoryName}</p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="d-flex align-items-center">
                      <p className="font-light text-muted m-0">Mobile</p>
                      <p className="m-0 ml-2 text-primary text-primary">{clientEntity.mobile}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="font-light text-muted m-0">Email</p>
                      <p className="m-0 ml-2 text-primary text-primary">{clientEntity.email}</p>
                    </div>
                    <div className="edit-button-container">
                      <Link to={`/entity/client/${clientEntity.id}/edit`} className="edit-link">
                        <i className="fa fa-edit" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="order-widget">
              <CardBody>
                <Row>
                  <Col sm="12" md="8">
                    <CardTitle>Client Activity Summary</CardTitle>
                    <CardSubtitle className="mb-0">Summary 2020</CardSubtitle>
                    <Row className="mt-3">
                      <Col xs="4" className="border-right">
                        <i className="fa fa-circle text-cyan" />
                        <h3 className="mb-0 font-medium">{sizeOfPolicies}</h3>
                        <span>Policies</span>
                      </Col>
                      <Col xs="4" className="border-right">
                        <i className="fa fa-circle text-orange" />
                        <h3 className="mb-0 font-medium">{sizeOfClaims}</h3>
                        <span>Claims</span>
                      </Col>
                      <Col xs="4" className="pr-0">
                        <i className="fa fa-circle text-info" />
                        <h3 className="mb-0 font-medium">{sizeOfInvoices}</h3>
                        <span>Pending Invoices</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12" md="4">
                    <div id="visitor" className="mt-3">
                      <Chart
                        style={{ height: '130px', width: '100%' }}
                        config={{
                          data: {
                            columns: [['Policies', sizeOfPolicies], ['Claims', sizeOfClaims], ['Pending Invoices', sizeOfInvoices]],
                            type: 'donut',
                            tooltip: {
                              show: true
                            }
                          },
                          donut: {
                            label: {
                              show: false
                            },
                            title: '75%',
                            width: 20
                          },
                          legend: {
                            hide: true
                          },
                          color: {
                            pattern: ['#40c4ff', '#2961ff', '#ff821c']
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="9">
                <ClientTab handleTab={this.handleTab} />
              </Col>
              <Col sm="3" className="text-right">
                {this.renderCreateButton()}
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <TabContent activeTab={String(activeTab)}>
              <TabPane tabId="1">
                <DataTable
                  columns={[
                    {
                      Header: 'Risk Class',
                      accessor: 'policyName',
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: '100%' }}
                          value={filter ? filter.value : '1'}
                        >
                          {riskClasses.map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </option>
                          ))}
                        </select>
                      )
                    },
                    {
                      Header: 'Policy Number',
                      accessor: 'invoiceNo'
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'startDate'
                    },
                    {
                      Header: 'Expiry Date',
                      accessor: 'endDate'
                    },
                    {
                      Header: 'Status',
                      accessor: 'paymentStatus',
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: '100%' }}
                          value={filter ? filter.value : 'all'}
                        >
                          <option value="all">Show All</option>
                          <option value="UNPAID">UNPAID</option>
                          <option value="PARTIAL">PARTIAL</option>
                          <option value="PAID">PAID</option>
                        </select>
                      )
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  tableData={policyData}
                  filterable
                />
              </TabPane>
              <TabPane tabId="2">
                <DataTable
                  columns={[
                    {
                      Header: 'Risk Class',
                      accessor: 'brokerRefNo'
                    },
                    {
                      Header: 'Policy Number',
                      accessor: 'brokerClaimNo'
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'policyNo'
                    },
                    {
                      Header: 'Expiry Date',
                      accessor: 'insurerClaimNo'
                    },
                    {
                      Header: 'Status',
                      accessor: 'coverPeriod'
                    },
                    {
                      Header: 'Status',
                      accessor: 'dateOfLoss'
                    },
                    {
                      Header: 'Claim Group',
                      accessor: 'claimGroup',
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: '100%' }}
                          value={filter ? filter.value : 'all'}
                        >
                          <option value="all">Show All</option>
                          <option value="WRITEOFF">WRITE OFF</option>
                          <option value="PARTIAL">PARTIAL</option>
                        </select>
                      )
                    },
                    {
                      Header: 'Date Reported',
                      accessor: 'dateReported'
                    },
                    {
                      Header: 'Claim Amount',
                      accessor: 'claimAmount'
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  tableData={claimsData}
                  filterable
                />
              </TabPane>
              <TabPane tabId="3">
                <DataTable
                  columns={[
                    {
                      Header: 'Invoice No',
                      accessor: 'policyName'
                    },
                    {
                      Header: 'Invoice Date',
                      accessor: 'invoiceNo'
                    },
                    {
                      Header: 'Invoice Amount',
                      accessor: 'invoiceAmount'
                    },
                    {
                      Header: 'Invoice Tax',
                      accessor: 'invoiceAmount'
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  tableData={invoicesData}
                  filterable
                />
              </TabPane>
              <TabPane tabId="5">
                {clientKYCs.length > 0 &&
                  clientKYCs[0].attachments.map(a => (
                    <Row key={a.id} className="my-5">
                      <Col>
                        <FontAwesomeIcon icon="cloud-upload-alt" />
                      </Col>
                      <Col>
                        {a.type} <h4>{a.name}</h4>
                      </Col>
                      <Col>
                        <img width={100} height={100} src={this.getCurrentLogo(a.attachmentFile)} alt={a.name} />
                      </Col>
                      <Col>
                        <Button color={a.verified ? 'success' : 'danger'} onClick={this.toggleVerified(a)}>
                          {a.verified ? 'Verified' : 'Not Verified'}
                        </Button>
                      </Col>
                    </Row>
                  ))}
                {/*<DataTable*/}
                {/*  columns={[*/}
                {/*    {*/}
                {/*      Header: 'KYC Type',*/}
                {/*      accessor: 'policyName',*/}
                {/*      Filter: ({ filter, onChange }) => (*/}
                {/*        <select*/}
                {/*          onChange={event => onChange(event.target.value)}*/}
                {/*          style={{ width: '100%' }}*/}
                {/*          value={filter ? filter.value : 'general'}*/}
                {/*        >*/}
                {/*          <option value="general">General</option>*/}
                {/*        </select>*/}
                {/*      )*/}
                {/*    },*/}
                {/*    {*/}
                {/*      Header: 'Document Type',*/}
                {/*      accessor: 'invoiceNo',*/}
                {/*      Filter: ({ filter, onChange }) => (*/}
                {/*        <select*/}
                {/*          onChange={event => onChange(event.target.value)}*/}
                {/*          style={{ width: '100%' }}*/}
                {/*          value={filter ? filter.value : 'national'}*/}
                {/*        >*/}
                {/*          <option value="national">National ID</option>*/}
                {/*          <option value="passport">Passport</option>*/}
                {/*        </select>*/}
                {/*      )*/}
                {/*    },*/}
                {/*    {*/}
                {/*      Header: 'Uploaded By',*/}
                {/*      accessor: 'startDate'*/}
                {/*    },*/}
                {/*    {*/}
                {/*      Header: 'Image Quick View',*/}
                {/*      accessor: 'endDate'*/}
                {/*    },*/}
                {/*    {*/}
                {/*      Header: 'Validity',*/}
                {/*      accessor: 'paymentStatus',*/}
                {/*      Filter: ({ filter, onChange }) => (*/}
                {/*        <select*/}
                {/*          onChange={event => onChange(event.target.value)}*/}
                {/*          style={{ width: '100%' }}*/}
                {/*          value={filter ? filter.value : 'valid'}*/}
                {/*        >*/}
                {/*          <option value="valid">Valid</option>*/}
                {/*          <option value="invalid">Invalid</option>*/}
                {/*        </select>*/}
                {/*      )*/}
                {/*    },*/}
                {/*    {*/}
                {/*      Header: 'Actions',*/}
                {/*      accessor: 'actions',*/}
                {/*      sortable: false,*/}
                {/*      filterable: false*/}
                {/*    }*/}
                {/*  ]}*/}
                {/*  defaultPageSize={10}*/}
                {/*  showPaginationBottom*/}
                {/*  tableData={kycsData}*/}
                {/*  filterable*/}
                {/*/>*/}
              </TabPane>
              <TabPane tabId="6">
                <Card>
                  <CardBody>
                    <CardTitle>
                      <h3>
                        <i className="fas fa-user-circle" style={{ color: 'success', fontWeight: 'lighter' }} /> Profile
                      </h3>
                    </CardTitle>
                    <Row>
                      <Col md="6">
                        <div
                          className="profile-info"
                          style={{ display: 'flex', fontSize: '15px', color: 'success', fontWeight: 'lighter', marginBottom: '10px' }}
                        >
                          <label htmlFor="name">
                            <i className="fas fa-user" /> Name:
                          </label>
                          <p id="name" className="profile-value" style={{ marginLeft: '10px', color: '#8e9ced', fontWeight: 'lighter' }}>
                            {account.firstName + ' ' + account.middleName + '' + account.lastName}
                          </p>
                        </div>
                        <div
                          className="profile-info"
                          style={{ display: 'flex', fontSize: '15px', color: 'success', fontWeight: 'lighter', marginBottom: '10px' }}
                        >
                          <label htmlFor="code">
                            <i className="fas fa-id-badge" /> Code:
                          </label>
                          <p id="code" className="profile-value" style={{ marginLeft: '10px', color: '#8e9ced', fontWeight: 'lighter' }}>
                            AG123
                          </p>
                        </div>
                        <div
                          className="profile-info"
                          style={{ display: 'flex', fontSize: '15px', color: 'success', fontWeight: 'lighter', marginBottom: '10px' }}
                        >
                          <label htmlFor="phone">
                            <i className="fas fa-phone" /> Phone Number:
                          </label>
                          <p id="phone" className="profile-value" style={{ marginLeft: '10px', color: '#8e9ced', fontWeight: 'lighter' }}>
                            {account.contactNo || ''}
                          </p>
                        </div>
                      </Col>
                      <Col md="6">
                        <div
                          className="profile-info"
                          style={{ display: 'flex', fontSize: '15px', color: 'success', fontWeight: 'lighter', marginBottom: '10px' }}
                        >
                          <label htmlFor="status">
                            <i className="fas fa-check-circle" /> Status:
                          </label>
                          <p id="status" className="profile-value" style={{ marginLeft: '10px', color: '#8e9ced', fontWeight: 'lighter' }}>
                            Active
                          </p>
                        </div>
                        <div
                          className="profile-info"
                          style={{ display: 'flex', fontSize: '15px', color: 'success', fontWeight: 'lighter', marginBottom: '10px' }}
                        >
                          <label htmlFor="rank">
                            <i className="fas fa-star" /> Rank:
                          </label>
                          <p id="rank" className="profile-value" style={{ marginLeft: '10px', color: '#8e9ced', fontWeight: 'lighter' }}>
                            {account.authorities}
                          </p>
                        </div>
                        <div
                          className="profile-info"
                          style={{ display: 'flex', fontSize: '15px', color: 'success', fontWeight: 'lighter', marginBottom: '10px' }}
                        >
                          <label htmlFor="lastActive">
                            <i className="far fa-clock" /> Last Active:
                          </label>
                          <p
                            id="lastActive"
                            className="profile-value"
                            style={{ marginLeft: '10px', color: '#8e9ced', fontWeight: 'lighter' }}
                          >
                            2023-07-31
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clientEntity: storeState.client.entity,
  riskClasses: storeState.riskClass.entities,
  clientClaims: storeState.claim.entities,
  clientInvoices: storeState.invoice.entities,
  clientKYCs: storeState.kYC.entities,
  account: storeState.authentication.account
});

const mapDispatchToProps = { getEntity, getRiskClasses, getInvoices, getClaims, getKYCs, getKYC, updateAttachment };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetail);
