import React from 'react';
import { connect } from 'react-redux';
import StepZilla from 'react-stepzilla';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import QuoteRiskStep from './step1';
import QuoteDetailStep from './step2';
import QuoteResultStep from './step3';
import QuoteKYCStep from 'app/entities/quote/wizard/step4';
import PaymentStep from 'app/entities/quote/wizard/step5';

export interface IQuoteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
export interface IWizardState {
  selectedRiskCategory: number;
  startAtStep: number;
}
class QuoteWizard extends React.Component<IQuoteProps, IWizardState> {
  constructor(props) {
    super(props);
    const { location } = props;
    let startAtStep = 0;
    if (location.search.indexOf('wizard=2') !== -1) {
      startAtStep = Number(location.search.split('=').pop());
    }
    this.state = {
      startAtStep,
      selectedRiskCategory: 0
    };
  }

  updateSelectedRiskCategory = selectedRiskCategory => {
    this.setState({ selectedRiskCategory });
  };

  render() {
    const { startAtStep } = this.state;
    const { match, location } = this.props;
    const steps = [
      {
        name: 'Risk',
        component: <QuoteRiskStep updateSelectedRiskCategory={this.updateSelectedRiskCategory} />
      },
      {
        name: 'Quotes',
        component: <QuoteResultStep match={match} />
      },
      {
        name: 'Details',
        component: <QuoteDetailStep selectedRiskCategory={this.state.selectedRiskCategory} />
      },
      {
        name: 'KYC',
        component: <QuoteKYCStep />
      },
      {
        name: 'Pay',
        component: <PaymentStep />
      }
    ];
    return (
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <i className="mdi mdi-border-right mr-2" />
          Get Quote
        </CardTitle>
        <CardBody>
          <StepZilla showSteps startAtStep={startAtStep} showNavigation={false} stepsNavigation={false} steps={steps} />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ quote }: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteWizard);
