import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AUTHORITIES } from 'app/config/constants';
import { createUser, getRoles, getUser, reset, updateUser } from './user-management.reducer';
import { getEntities as getCompanies } from 'app/entities/company/company.reducer';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { IRootState } from 'app/shared/reducers';
import MultiSelect from 'app/shared/common/multi-select';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IUserManagementUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> {}

export interface IUserManagementUpdateState {
  role: any;
  isNew: boolean;
  selectedOption: any[];
  selectedRoles: any[];
}

export class UserManagementUpdate extends React.Component<IUserManagementUpdateProps, IUserManagementUpdateState> {
  state: IUserManagementUpdateState = {
    role: null,
    isNew: !this.props.match.params || !this.props.match.params.login,
    selectedOption: [],
    selectedRoles: []
  };

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.setState({
        selectedOption: this.props.account.productTypes.map(p => ({
          value: p.id,
          label: p.name
        }))
      });
    } else {
      this.loadUser(this.props.match.params.login);
    }
    this.props.getCompanies();
    this.props.getRoles();
    this.props.getProductTypes(0, 10, `&companyId=${this.props.account.companyId}`);
  }

  async loadUser(name) {
    await this.props.getUser(name);
    const { user } = this.props;
    this.setState({
      selectedOption: user.productTypes.map(p => ({
        value: p.id,
        label: p.name
      })),
      selectedRoles: user.authorities.map(a => ({
        value: a,
        label: a
      }))
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  saveUser = (event, errors, values) => {
    if (errors.length === 0) {
      const valuesObj = {
        ...this.props.user,
        ...values,
        langKey: 'en',
        productTypes: this.props.productTypes.filter(p => this.state.selectedOption.map(o => o.value).includes(p.id)),
        authorities: this.props.roles.filter(r => this.state.selectedRoles.map(o => o.value).includes(r))
      };
      if (this.props.isPartner) {
        valuesObj['productTypes'] = this.props.account.productTypes.filter(p => this.state.selectedOption.map(o => o.value).includes(p.id));
      }
      if (this.state.isNew) {
        if (this.props.isPartner) {
          valuesObj['authorities'] = ['ROLE_AGENT'];
        }
        this.props.createUser(valuesObj);
      } else {
        valuesObj.id = this.props.user.id;
        this.props.updateUser(valuesObj);
      }
      if (this.props.updateSuccess) {
        this.handleClose();
      }
    }
  };
  handleChangeProductType = selectedOption => {
    this.setState({
      selectedOption
    });
  };

  handleChangeRoles = selectedRoles => {
    this.setState({
      selectedRoles
    });
  };

  handleClose = () => {
    this.props.history.push('/admin/user-management');
  };

  render() {
    const isInvalid = false;
    const { user, loading, updating, roles, productTypes, isSuperAdmin, isAdmin, isPartner, companies } = this.props;
    const { selectedOption, selectedRoles } = this.state;
    const productTypesOptions = productTypes
      .filter(p => p.name !== 'Medical')
      .map(p => ({
        value: p.id,
        label: p.name
      }));
    const rolesOptions = roles.map(a => ({ value: a, label: a }));
    return (
      <div>
        <Card>
          <CardBody className="d-flex justify-content-center">
            <Col md="8">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a {isPartner ? 'agent' : 'user'}
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm onSubmit={this.saveUser}>
                      <Row>
                        <Col>
                          <AvGroup>
                            <Label for="login">Login</Label>
                            <AvField
                              type="text"
                              className="form-control"
                              name="login"
                              placeholder="Enter username"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Username is required.'
                                },
                                pattern: {
                                  value: '^[_.@A-Za-z0-9-]*$',
                                  errorMessage: 'Username can only contain letters and digits.'
                                },
                                minLength: {
                                  value: 1,
                                  errorMessage: 'Username is required to be at least 1 character.'
                                },
                                maxLength: {
                                  value: 50,
                                  errorMessage: 'Username cannot be longer than 50 characters.'
                                }
                              }}
                              value={user.login}
                            />
                          </AvGroup>
                        </Col>
                        <Col>
                          <AvGroup>
                            <Label for="activated">Activated</Label>
                            <AvInput tag={CustomInput} type="checkbox" name="activated" value={user.activated} />
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AvGroup>
                            <Label for="firstName">First Name</Label>
                            <AvField
                              type="text"
                              className="form-control"
                              name="firstName"
                              placeholder="Enter firstname"
                              validate={{
                                maxLength: {
                                  value: 50,
                                  errorMessage: 'This field cannot be longer than 50 characters.'
                                }
                              }}
                              value={user.firstName}
                            />
                          </AvGroup>
                        </Col>
                        <Col>
                          <AvGroup>
                            <Label for="lastName">Last Name</Label>
                            <AvField
                              type="text"
                              className="form-control"
                              name="lastName"
                              placeholder="Enter lastname"
                              validate={{
                                maxLength: {
                                  value: 50,
                                  errorMessage: 'This field cannot be longer than 50 characters.'
                                }
                              }}
                              value={user.lastName}
                            />
                            <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                          </AvGroup>
                        </Col>
                      </Row>
                      <AvGroup>
                        <Label for="contactNo" className="font-medium">
                          Mobile No
                        </Label>
                        <AvField
                          name="contactNo"
                          placeholder={'Your Contact No'}
                          labelClass="font-medium"
                          size="md"
                          value={user.contactNo}
                          validate={{
                            required: { value: true, errorMessage: 'Your contactNo is required.' },
                            minLength: {
                              value: 10,
                              errorMessage: 'Your contactNo is required to be at least 10 character.'
                            }
                          }}
                        />
                      </AvGroup>
                      <AvGroup>
                        <Label for="lastName">Email</Label>
                        <AvField
                          name="email"
                          placeholder={'Enter email'}
                          type="email"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Email is required.'
                            },
                            email: {
                              errorMessage: 'Email is invalid.'
                            },
                            minLength: {
                              value: 5,
                              errorMessage: 'Email is required to be at least 5 characters.'
                            },
                            maxLength: {
                              value: 254,
                              errorMessage: 'Email cannot be longer than 50 characters.'
                            }
                          }}
                          value={user.email}
                        />
                      </AvGroup>
                      <AvGroup>
                        <Label for="password">New password</Label>
                        <AvField
                          name="password"
                          placeholder={'New password'}
                          type="text"
                          validate={{
                            minLength: {
                              value: 4,
                              errorMessage: 'Your password is required to be at least 4 characters.'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: 'Your password cannot be longer than 50 characters.'
                            }
                          }}
                          value={user.password}
                        />
                      </AvGroup>
                      {isSuperAdmin && (
                        <AvGroup>
                          <Label for="companyId">Company</Label>
                          <AvInput
                            type="select"
                            className="form-control"
                            name="companyId"
                            value={user.companyId}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Company is required.'
                              }
                            }}
                          >
                            <option value="" selected disabled>
                              Select Company
                            </option>
                            {companies.map(c => (
                              <option value={c.id} key={c.id}>
                                {c.name}
                              </option>
                            ))}
                          </AvInput>
                        </AvGroup>
                      )}
                      <AvGroup>
                        <Label for="user-productTypeId">Product Type</Label>
                        <MultiSelect
                          id="productTypes"
                          name="productTypes"
                          optionSelected={selectedOption}
                          isMulti
                          handleChange={this.handleChangeProductType}
                          options={productTypesOptions}
                        />
                      </AvGroup>
                      {isAdmin && (
                        <AvGroup>
                          <Label for="authorities">Roles</Label>
                          <MultiSelect
                            id="authorities"
                            name="authorities"
                            optionSelected={selectedRoles}
                            isMulti
                            handleChange={this.handleChangeRoles}
                            options={rolesOptions}
                          />
                        </AvGroup>
                      )}
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/admin/user-management" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={isInvalid || updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user,
  roles: storeState.userManagement.authorities,
  productTypes: storeState.productType.entities,
  companies: storeState.company.entities,
  loading: storeState.userManagement.loading,
  updateSuccess: storeState.userManagement.updateSuccess,
  account: storeState.authentication.account,
  updating: storeState.userManagement.updating,
  isSuperAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SUPER_ADMIN]),
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER])
});

const mapDispatchToProps = {
  getUser,
  getRoles,
  getProductTypes,
  updateUser,
  createUser,
  getCompanies,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementUpdate);
