import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const ClaimsMenu = () => (
  <MenuItem to="/claims" icon="mdi mdi-loop">
    Claims
  </MenuItem>
);

export default ClaimsMenu;
