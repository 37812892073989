import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { Card, CardTitle, Row, Col, Label, Button, CardBody } from 'reactstrap';
import { getEntity as getQuote } from 'app/entities/quote/quote.reducer';
import { getEntity as getClient, createEntity } from 'app/entities/client/client.reducer';
import { getEntity as getClientPolicyPayment } from 'app/entities/client-policy-payment/client-policy-payment.reducer';
import { getEntity as getPolicy } from 'app/entities/policy/policy.reducer';
import { getEntity as getUnderwriter } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { CertificateRequestType, TypeACertificate, TypeBCertificate, TypeOfCover } from 'app/modules/aki/aki-model';
import { previewAkiCertificate } from 'app/modules/aki/aki.reducer';
import Loader from 'app/shared/common/loader';

export interface IQuoteProps extends StateProps, DispatchProps {
  jumpToStep;
}

export interface IClientDetailsState {
  isNew: boolean;
  idsadditionalBenefits: any[];
  underwriterId: string;
  clientId: string;
  riskClassId: bigint;
  coverTypeId: bigint;
  newClient: boolean;
}

class ClientCertificateStep extends React.Component<IQuoteProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      underwriterId: '0',
      clientId: '0',
      riskClassId: null,
      coverTypeId: null,
      newClient: false,
      loading: true
    };
    this.handleBack = this.handleBack.bind(this);
  }

  onCountry = country => {
    this.setState({ country });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handleCategoryChange = categoryId => {
    this.setState({ categoryId: categoryId.target.value });
  };

  getTypeOfCertificate(riskClass) {
    if (riskClass.classificationCategory === 'COMMERCIAL_PRIMARY') {
      if (riskClass.name === 'Own Goods' || riskClass.name === 'Own Goods Enhanced' || riskClass.name === 'Own Goods - Pickups') {
        return TypeBCertificate.MOTOR_COMMERCIAL_OWN_GOODS;
      } else if (
        riskClass.name === 'Institutional Vehicles' ||
        riskClass.name === 'Driving Schools' ||
        riskClass.name === 'Driving Schools Vehicles - Saloon' ||
        riskClass.name === 'Driving Schools Vehicles - Others' ||
        riskClass.name === 'Driving Schools Vehicles - Heavy Vehicles'
      ) {
        return TypeBCertificate.MOTOR_INSTITUTIONAL_VEHICLE;
      } else if (riskClass.name === 'General Cartage - Tankers') {
        return TypeBCertificate.TANKERS_LIQUID_CARRYING;
      }
      if (
        riskClass.name === 'Agrictultural & Forestry Vehicles' ||
        riskClass.name === 'Ambulance, Hearse, Firefighters' ||
        riskClass.name === 'Contingency Liability' ||
        riskClass.name === 'Construction Vehicles'
      ) {
        return TypeBCertificate.MOTOR_SPECIAL_VEHICLES;
      } else {
        return TypeBCertificate.MOTOR_COMMERCIAL_GENERAL_CARTAGE;
      }
    } else if (riskClass.riskCategory === 'PUBLIC_SERVICE_VEHICLE') {
      if (riskClass.name === 'Chauffeur Driven' || riskClass.name === 'Chauffeur Driven - Taxis') {
        return TypeACertificate.TYPE_A_TAXI;
      } else {
        return TypeACertificate.CLASS_A_PSV_UNMARKED;
      }
    } else {
    }
  }

  getCoverType(coverTypeId) {
    return coverTypeId === 1
      ? TypeOfCover.COMPREHENSIVE
      : coverTypeId === 2
      ? TypeOfCover.THIRD_PARTY
      : TypeOfCover.THIRD_PARTY_THEFT_AMD_FIRE;
  }

  getCertificateRequestType(riskClass, action) {
    if (action === 'issue') {
      if (riskClass.classificationCategory === 'COMMERCIAL_PRIMARY') {
        return CertificateRequestType.ISSUE_TYPE_B_CERTIFICATE;
      } else if (riskClass.classificationCategory === 'PUBLIC_SERVICE_VEHICLE') {
        return CertificateRequestType.ISSUE_TYPE_A_CERTIFICATE;
      } else {
        return CertificateRequestType.ISSUE_TYPE_C_CERTIFICATE;
      }
    } else {
      if (riskClass.classificationCategory === 'COMMERCIAL_PRIMARY') {
        return CertificateRequestType.PREVIEW_TYPE_B_CERTIFICATE;
      } else if (riskClass.classificationCategory === 'PUBLIC_SERVICE_VEHICLE') {
        return CertificateRequestType.PREVIEW_TYPE_A_CERTIFICATE;
      } else {
        return CertificateRequestType.PREVIEW_TYPE_C_CERTIFICATE;
      }
    }
  }

  parsePhone(phone) {
    if (phone.length > 9) {
      return phone.substr(phone.length - 9);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
    }
  }

  componentDidMount() {
    const { quote } = this.props;
    this.setState({ type: 'Preview' });
    const underWriterResult = Promise.resolve(this.props.getUnderwriter(quote.underwriterId));
    underWriterResult.then(res => {
      this.previewAkiCert('preview');
    });
  }

  previewAkiCert = type => {
    const { vehicleInfo, vehicleMakes, quote, riskClass, vehicleModels, client, clientPolicy } = this.props;
    const carMake = vehicleMakes.find(v => Number(v.id) === Number(quote.carMakeId));
    const carModel = vehicleModels.find(v => Number(v.id) === Number(quote.carModelId));
    const memberCompany = this.props.underwriter;
    if (memberCompany && memberCompany.akiCompanyId > 0) {
      const akiPreviewEntity = {
        memberCompanyId: memberCompany.akiCompanyId, // undefined
        typeOfCertificate: riskClass.classificationCategory === 'PRIVATE' ? null : this.getTypeOfCertificate(riskClass),
        typeOfCover: this.getCoverType(quote.coverTypeId),
        certificateRequestType: this.getCertificateRequestType(riskClass, type),
        intermediaryIRANumber: null,
        policyHolder: `${quote.firstName} ${quote.lastName}`,
        policyNumber: clientPolicy.policyId,
        commencingDate: quote.date,
        expiringDate: quote.expiryDate,
        registrationNumber: quote.registration,
        chassisNumber: vehicleInfo.chassisNumber,
        phoneNumber: this.parsePhone(quote.mobile),
        bodyType: vehicleInfo.bodyType,
        vehicleMake: carMake.name,
        vehicleModel: carModel.name,
        engineNumber: vehicleInfo.engineNumber,
        email: quote.email,
        sumInsured: quote.sumInsured,
        insuredPIN: client.pinNumber,
        yearOfManufacture: quote.yearOfManufacture,
        hudumaNumber: null,
        lincensedToCarry: quote.capacity, // (When Type D PSV Motorcycle)
        tonnage: 4
      };
      const previewAkiCertificateResult = Promise.resolve(
        this.props.previewAkiCertificate(akiPreviewEntity, akiPreviewEntity.certificateRequestType)
      );
      previewAkiCertificateResult.then(res => {
        this.setState({ loading: false });
      });
    } else {
      this.setState({ akiErrors: `Underwriter ${memberCompany.name || ''} not recognized On AKI` });
    }
  };

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(3);
  }

  handleClose = () => {
    this.setState({ display: false });
  };

  render() {
    const { updating, akiResponse } = this.props;
    const akiResponseErrors = akiResponse ? akiResponse.Error : [];
    const { akiErrors, loading, type, display } = this.state;
    return (
      <Card className="border-bottom mt-5 mb-0">
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          Aki Certificate Generation
        </CardTitle>
        <CardBody className="pr-0 pl-0">
          <Row className="pl-3 pr-3">
            <Col md="12">
              <h4 className="font-medium mb-4">Policy Certificate {type === 'Preview' && type}</h4>
            </Col>
          </Row>
          <Row className="pl-3 pr-3">
            <Col>
              {loading ? (
                <Loader loading={loading} css="margin: 50%" message={''} size={50} />
              ) : (
                <Card>
                  <CardBody>
                    <Row className="justify-content-md-center">
                      {akiResponse.success ? (
                        <Col>
                          <div className={'headerContainer'}>
                            <h3>Request Successful</h3>
                          </div>
                          {type === 'Preview' && <span>Preview URL: ${akiResponse.callbackObj.previewCertificateURL}</span>}
                          {type === 'issue' && (
                            <Col md={6}>
                              <Row>
                                <Label sm="3">TransactionNo</Label>
                                <Col md={6} className={'primary'}>
                                  {akiResponse.callbackObj.TransactionNo}
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">ActualCNo</Label>
                                <Col md={6} className={'primary'}>
                                  {akiResponse.callbackObj.actualCNo}
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">Email</Label>
                                <Col md={6} className={'primary'}>
                                  {akiResponse.callbackObj.Email}
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Col>
                      ) : (
                        <Col>
                          <div className={'headerContainer'}>
                            <h3>{akiErrors || (akiResponseErrors.length && 'Error Occurred')}</h3>
                          </div>
                          <Row className={'mt-2'}>
                            {akiErrors && (
                              <Col md={6} className={'primary'}>
                                {akiErrors}
                              </Col>
                            )}
                          </Row>
                          {akiResponse && (
                            <Col className={'mt-2 '}>
                              <Row className={'justify-content-lg-end'}>
                                <Label sm="3">Request Number</Label>
                                <Col sm="5" className={'pt-1'}>
                                  {akiResponse.APIRequestNumber}
                                </Col>
                              </Row>
                              <Row className={'justify-content-lg-end mb-2'}>
                                <Label sm="3">DMVICRefNo</Label>
                                <Col sm="5" className={'pt-1'}>
                                  <div> {akiResponse.DMVICRefNo}</div>
                                </Col>
                              </Row>

                              {akiResponseErrors.map(err => (
                                <Row className={'primary'}>
                                  <Label sm="4" for="risk-class-riskCategory">
                                    {err.errorCode}
                                  </Label>
                                  <Col sm="8">
                                    <div> {err.errorText}</div>
                                  </Col>
                                </Row>
                              ))}
                              <Col />
                            </Col>
                          )}
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row className="pl-3 pr-5 mt-4 mb-5">
            <Col md="12 text-right">
              <Button id="cancel-save" onClick={this.handleBack} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp; &nbsp;
              <Button
                type="submit"
                className="btn btn-success"
                onClick={() => this.previewAkiCert('issue')}
                disabled={loading || akiResponseErrors.length || akiErrors}
              >
                <i className="fa fa-check" /> GENERATE CERTIFICATE
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  riskCategories: storeState.riskCategory.entities,
  riskClasses: storeState.riskClass.entities,
  riskClass: storeState.riskClass.entity,
  vehicleMakes: storeState.carMake.entities,
  vehicleModels: storeState.carModel.entities,
  coverTypes: storeState.coverType.entities,
  vehicleTypes: storeState.vehicleType.entities,
  account: storeState.authentication.account,
  quote: storeState.quote.entity,
  updateSuccess: storeState.client.updateSuccess,
  clientCategories: storeState.clientCategory.entities,
  idTypes: storeState.idType.entities,
  client: storeState.client.entity,
  clientPolicy: storeState.clientPolicy.entity,
  clientPolicyPayment: storeState.clientPolicyPayment.entity,
  updating: storeState.client.updating,
  underwriter: storeState.underwriter.entity,
  vehicleInfo: storeState.vehicleAdditionalInfo,
  akiResponse: storeState.akiCertificateState.response,
  akiUpdateSuccess: storeState.akiCertificateState.updateSuccess,
  loading: storeState.akiCertificateState.loading || storeState.clientPolicyPayment.loading
});

const mapDispatchToProps = {
  getQuote,
  getPolicy,
  getUnderwriter,
  getClientCategories,
  getIdTypes,
  getClient,
  getClientPolicyPayment,
  createEntity,
  previewAkiCertificate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCertificateStep);
