import React from 'react';
import { connect } from 'react-redux';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Button, Label } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { handleRegisterInit, reset } from './register.reducer';
import img2 from '../../../assets/images/big/auth-bg.jpg';
import Utils from 'app/config/utils';

export interface IRegisterProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover'
};

export interface IRegisterState {
  password: string;
}

export class RegisterPage extends React.Component<IRegisterProps, IRegisterState> {
  private formRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      password: ''
    };
  }

  componentDidMount() {}

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.registrationInitSuccess !== this.props.registrationInitSuccess && nextProps.registrationInitSuccess) {
      // @ts-ignore
      this.formRef && this.formRef.reset();
      this.props.history.push('/register/init-success');
    }
  }

  handleValidSubmit = (event, values) => {
    this.props.handleRegisterInit(
      values.username,
      values.email,
      // values.firstPassword,
      values.contactNo
      // values.companyName,
      // values.defaultData
    );
    // event.preventDefault();
  };

  render() {
    return (
      <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center">
              <Col md="6" lg="4" className="bg-dark text-white">
                <div className="p-4">
                  <h2 className="display-6 mt-4">
                    Insurance Saas
                    <br /> <span className="text-cyan font-bold">IBS</span>
                  </h2>
                  <p className="op-5 mt-4">IBS - Insurance Brokerage System is provides you with a one-stop automated insurance tool.</p>
                  <p className="op-5 mt-4">
                    We take care of all the hard work so you can focus on taking care of your customers. Our solution provides;
                  </p>
                  <p className="op-5 mt-4">
                    Quote compare, quote generation, claim management,agent coding, sales marketplaces and accounting.
                  </p>
                  <p className="op-5 mt-4">Our SaaS is best suited to brokers and agents. Sign Up Today</p>
                </div>
              </Col>
              <Col md="6" lg="4" className="bg-white">
                <div className="p-4">
                  <h3 className="font-medium mb-3">Sign Up</h3>
                  <AvForm id="register-form" onValidSubmit={this.handleValidSubmit} ref={this.formRef}>
                    <AvGroup>
                      <Label for="username" className="font-medium">
                        Username
                      </Label>
                      <AvField
                        name="username"
                        placeholder={'Your username'}
                        labelClass="font-medium"
                        size="lg"
                        validate={{
                          required: { value: true, errorMessage: 'Your username is required.' },
                          pattern: {
                            value: '^[A-Za-z0-9.@_]*$',
                            errorMessage: 'Your username can only contain letters and digits.'
                          },
                          minLength: {
                            value: 1,
                            errorMessage: 'Your username is required to be at least 1 character.'
                          },
                          maxLength: { value: 50, errorMessage: 'Your username cannot be longer than 50 characters.' }
                        }}
                      />
                    </AvGroup>
                    <AvGroup>
                      <Label for="email" className="font-medium">
                        Email
                      </Label>
                      <AvField
                        name="email"
                        placeholder={'Your email'}
                        type="email"
                        size="lg"
                        validate={{
                          required: { value: true, errorMessage: 'Your email is required.' },
                          minLength: { value: 5, errorMessage: 'Your email is required to be at least 5 characters.' },
                          maxLength: { value: 254, errorMessage: 'Your email cannot be longer than 50 characters.' }
                        }}
                      />
                    </AvGroup>
                    <AvGroup>
                      <Label for="contactNo" className="font-medium">
                        Contact No
                      </Label>
                      <AvField
                        name="contactNo"
                        placeholder={'Your Contact No'}
                        labelClass="font-medium"
                        size="lg"
                        validate={{
                          required: { value: true, errorMessage: 'Your contactNo is required.' },
                          minLength: {
                            value: 10,
                            errorMessage: 'Your contactNo is required to be at least 10 character.'
                          }
                        }}
                      />
                    </AvGroup>

                    {/*<AvGroup>*/}
                    {/*  <div className="d-flex no-block align-items-center justify-content-between mb-4 mt-4">*/}
                    {/*    <AvInput type="checkbox" name="defaultData" label="Default Data"/>*/}
                    {/*    <Label>Default Data</Label>*/}
                    {/*  </div>*/}
                    {/*</AvGroup>*/}
                    <Row className="mb-3 mt-3">
                      <Col xs="12">
                        <Button color="primary" size="lg" type="submit" block>
                          SUBMIT
                        </Button>
                      </Col>
                    </Row>
                    <div className="text-center">
                      Already have an account?{' '}
                      <Link to="/login" className="text-info ml-1">
                        <b>Sign In</b>
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  registrationInitSuccess: storeState.register.registrationInitSuccess
});

const mapDispatchToProps = {
  handleRegisterInit,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
