export const enum Unit {
  TONNE = 'TONNE'
}

export interface ITonnageRange {
  id?: number;
  minValue?: string;
  maxValue?: string;
  unit?: Unit;
}

export const defaultValue: Readonly<ITonnageRange> = {};
