export const enum ClassificationCategory {
  COMMERCIAL_SECONDARY = 'COMMERCIAL_SECONDARY',
  PRIVATE = 'PRIVATE',
  COMMERCIAL_PRIMARY = 'COMMERCIAL_PRIMARY',
  PUBLIC_SERVICE_VEHICLE = 'PUBLIC_SERVICE_VEHICLE'
}

export interface IVehicleType {
  id?: number;
  classificationCategory?: ClassificationCategory;
  name?: string;
  hasRange?: boolean;
  tonnageRangeId?: number;
}

export const defaultValue: Readonly<IVehicleType> = {
  hasRange: false
};
