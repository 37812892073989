import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const ReportsMenu = () => (
  <MenuItem to="/reports" icon="mdi mdi-chart-bar">
    Reports
  </MenuItem>
);

export default ReportsMenu;
