import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CustomerAuthentication from './customer-authentication';
import CustomerAuthenticationDetail from './customer-authentication-detail';
import CustomerAuthenticationUpdate from './customer-authentication-update';
import CustomerAuthenticationDeleteDialog from './customer-authentication-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CustomerAuthenticationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CustomerAuthenticationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CustomerAuthenticationDetail} />
      <ErrorBoundaryRoute path={match.url} component={CustomerAuthentication} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CustomerAuthenticationDeleteDialog} />
  </>
);

export default Routes;
