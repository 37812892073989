import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './policy-application.reducer';
import { IPolicyApplication } from 'app/shared/model/policy-application.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IPolicyApplicationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PolicyApplicationDetail = (props: IPolicyApplicationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { policyApplicationEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          PolicyApplication [<b>{policyApplicationEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="clientEmail">Client Email</span>
          </dt>
          <dd>{policyApplicationEntity.clientEmail}</dd>
          <dt>
            <span id="clientMobile">Client Mobile</span>
          </dt>
          <dd>{policyApplicationEntity.clientMobile}</dd>
          <dt>
            <span id="underwriterEmail">Underwriter Email</span>
          </dt>
          <dd>{policyApplicationEntity.underwriterEmail}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{policyApplicationEntity.status}</dd>
          <dt>
            <span id="notes">Notes</span>
          </dt>
          <dd>{policyApplicationEntity.notes}</dd>
          <dt>Client</dt>
          <dd>{policyApplicationEntity.clientId ? policyApplicationEntity.clientId : ''}</dd>
          <dt>Underwriter</dt>
          <dd>{policyApplicationEntity.underwriterId ? policyApplicationEntity.underwriterId : ''}</dd>
          <dt>Product Type</dt>
          <dd>{policyApplicationEntity.productTypeId ? policyApplicationEntity.productTypeId : ''}</dd>
          <dt>Company</dt>
          <dd>{policyApplicationEntity.companyId ? policyApplicationEntity.companyId : ''}</dd>
          <dt>Client Policy</dt>
          <dd>{policyApplicationEntity.clientPolicyId ? policyApplicationEntity.clientPolicyId : ''}</dd>
        </dl>
        <Button tag={Link} to="/policy-application" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/policy-application/${policyApplicationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ policyApplication }: IRootState) => ({
  policyApplicationEntity: policyApplication.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyApplicationDetail);
