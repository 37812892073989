import { Moment } from 'moment';

export const enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const enum BenefitRate {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE'
}

export interface ILeviesDefaultData {
  id?: number;
  chargeName?: string;
  abbreviation?: string;
  description?: string;
  country?: string;
  startDate?: Moment;
  endDateTime?: Moment;
  status?: Status;
  rateType?: BenefitRate;
  rateValue?: number;
}

export const defaultValue: Readonly<ILeviesDefaultData> = {};
