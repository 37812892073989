import React from 'react';
import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import Cart, { Home, Brand, Notification } from './header-components';
import { AccountMenu } from '../menus';

export interface IHeaderProps {
  account: any;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isPartner: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
}

export interface IHeaderState {
  menuOpen: boolean;
}

export default class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  renderDevRibbon = () =>
    this.props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  updateDimensions = () => {
    const element = document.getElementById('main-wrapper');
    if (window.innerWidth < 1170) {
      element.setAttribute('data-sidebartype', 'mini-sidebar');
      element.classList.add('mini-sidebar');
    } else {
      element.setAttribute('data-sidebartype', 'mini-sidebar');
      element.classList.remove('mini-sidebar');
    }
  };

  showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  };

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  render() {
    const { isAuthenticated, account } = this.props;
    return (
      <header className="topbar navbarbg" data-navbarbg="skin1">
        {/* {this.renderDevRibbon()} */}
        <LoadingBar className="loading-bar" />
        <Navbar className="top-navbar navbar-dark" expand="md">
          <div className="navbar-header" id="logobg">
            <span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
              <i className="ti-menu ti-close" />
            </span>
            {/* <NavbarToggler aria-label="Menu" onClick={this.toggleMenu} /> */}
            <Brand />
            <span className="topbartoggler d-block d-md-none" onClick={this.toggleMenu}>
              <i className="ti-more" />
            </span>
          </div>
          <Collapse className="navbarbg" isOpen={this.state.menuOpen} navbar data-navbarbg="skin1">
            <Home isPartner={this.props.isPartner} account={this.props.account} />
            <Nav className="ml-auto float-right" navbar>
              <Cart />

              <Notification />
              <AccountMenu account={account} isAuthenticated={isAuthenticated} />
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
