import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Label, FormGroup, Input, Button } from 'reactstrap';
import { getSortState, IPaginationBaseState } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import ReactFlagsSelect from 'react-flags-select';
import { getEntity, updateEntity } from 'app/entities/direct-payment/direct-payment.reducer';
import {
  createEntity as createClient,
  getEntities as getClients,
  getClientsByHash,
  getClientByMobile,
  getEntity as getClient,
  reset as resetClient,
  updateEntity as updateClient
} from 'app/entities/client/client.reducer';
import PhoneInput from 'react-phone-number-input';
import { Link, NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, size } from 'lodash';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import DataTable from 'app/shared/common/dataTable';
import ModalView from './modal-view';

export interface IClientProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; id: string }> {
  jumpToStep;
  updateSelectedRiskCategory;
  getClientByEmail;
  open;
  openModal;
  setModalError;
  modalError;
}

export interface IClientState extends IPaginationBaseState {
  email: string;
  isNew: boolean;
  selectedClient: boolean;
  withMobile: boolean;
  errorMessage: string;
  categoryId: any;
  mobile: string;
  country: string;
  telephone: string;
}

class ClientStep extends React.Component<IClientProps, IClientState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      isNew: true,
      email: '',
      categoryId: null,
      errorMessage: '',
      mobile: null,
      country: null,
      telephone: null,
      withMobile: false,
      selectedClient: false
    };
  }

  componentDidMount() {
    const { companyId } = this.props.account;
    this.fetchStartup().finally(() => {
      this.props.getIdTypes(0, 2000, `,&companyId=${companyId}`);
      this.props.getClientCategories(0, 2000, `,&companyId=${companyId}`);
    });
  }

  fetchStartup = async () => {
    await this.props.getEntity(this.props.match.params.id);
    const { itemsPerPage, activePage, sort, order } = this.state;
    // @ts-ignore
    const { msisdn } = this.props.directPayment;
    if (size(msisdn) > 12) {
      await this.props.getClientsByHash(
        activePage - 1,
        itemsPerPage,
        // @ts-ignore
        `${sort || ''},${order || ''}&mobile=${msisdn}&companyId=${this.props.account.companyId}`
      );
    } else {
      await this.props.getClients(
        activePage - 1,
        itemsPerPage,
        // @ts-ignore
        `${sort || ''},${order || ''}&mobile=${msisdn}&companyId=${this.props.account.companyId}`
      );
    }
    if (this.props.clients.length === 1) {
      await this.props.getClient(this.props.clients[0].id);
      const { categoryId, mobile } = this.props.clientEntity;
      this.setState({ categoryId, mobile });
    } else {
      this.props.resetClient();
    }
  };

  fetchData = async state => {
    if (this.props.directPayment.id) {
      const sort = state.sorted.map(d => d.id).join();
      const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
      const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
      filtered.push(`companyId=${this.props.account.companyId}`);
      // @ts-ignore
      const { msisdn } = this.props.directPayment;
      if (this.state.withMobile && size(msisdn) > 12) {
        this.props.getClientsByHash(
          state.page - 1,
          state.pageSize,
          // @ts-ignore
          `${sort || ''},${order || ''}${'&mobile=' + msisdn}&${filtered.join('&')}`
        );
      } else {
        this.props.getClients(
          state.page - 1,
          state.pageSize,
          // @ts-ignore
          `${sort || ''},${order || ''}&${filtered.join('&')}`
        );
      }
      this.setState({ itemsPerPage: state.pageSize });
    }
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(1);
    }
  }

  onPickClient = async (e, clientData) => {
    e.persist();
    const checked = e.target.checked;
    if (checked) {
      await this.props.getClient(clientData.id);
      this.setState({ categoryId: this.props.clientEntity.categoryId, selectedClient: checked });
    }
  };

  saveEntity = async (event, errors, values) => {
    if (errors.length === 0) {
      const { mobile, telephone, country } = this.state;
      const { clientEntity, account, directPayment } = this.props;
      const entity = {
        ...clientEntity,
        ...values,
        mobile,
        telephone,
        country,
        companyId: account.companyId
      };

      if (entity['idTypeId'] === '') {
        entity['idTypeId'] = null;
      }
      if (!this.props.clientEntity.id) {
        await this.props.createClient(entity);
      } else {
        if (!isEqual(JSON.stringify(clientEntity), JSON.stringify(entity))) {
          await this.props.updateClient(entity);
        }
      }
      // @ts-ignore
      this.props.getClients(0, 5, `&mobile=${this.props.directPayment.msisdn}&companyId=${this.props.account.companyId}`);
      const paymentEntity = {
        ...directPayment,
        clientId: this.props.clientEntity.id
      };
      if (!isEqual(JSON.stringify(directPayment), JSON.stringify(paymentEntity))) {
        await this.props.updateEntity(paymentEntity);
      }
    }
  };

  onCountry = country => {
    this.setState({ country });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handlePhone = mobile => {
    this.setState({ telephone: mobile });
  };

  handleCategoryChange = event => {
    this.setState({ categoryId: Number(event.target.value) });
  };

  onNext = () => {
    if (this.state.selectedClient) {
      const updatedUrl = this.props.location.search.replace(/(\?|&)wizard=0/, '$1wizard=1');
      this.props.history.push(`${updatedUrl}`);
      this.props.jumpToStep(1);
    } else {
      this.props.setModalError({
        title: 'No Client Selected',
        description: 'Please select client or create new client to continue'
      });
      this.props.openModal(true);
    }
  };

  render() {
    const { idTypes, updating, clientCategories, clients, clientEntity, clientsLoading, directPayment, open, modalError } = this.props;
    const { categoryId, isNew } = this.state;
    const clientsData = clients.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      idTypeId: prop.categoryId ? <NavLink to={`id-type/${prop.idTypeId}`}>{prop.idTypeId}</NavLink> : '',
      name: `${prop.firstName || ''} ${prop.middleName || ''} ${prop.lastName || ''}`,
      actions: (
        <div className="text-center">
          <Input type="checkbox" onClick={e => this.onPickClient(e, prop)} />
        </div>
      )
    }));
    return (
      <div className="step step1 mt-5 ">
        <AvForm model={isNew ? {} : clientEntity} onSubmit={this.saveEntity}>
          <Row className="pl-3 pr-3">
            <Col md="12">
              <h4 className="font-medium mb-4">Client Details</h4>
            </Col>
          </Row>
          <Row className="pl-3 pr-3">
            <Col md="6">
              <AvGroup>
                <Row>
                  <Label sm="3" for="client-category">
                    Client Category
                  </Label>
                  <Col sm="6">
                    <AvInput
                      id="client-category"
                      type="select"
                      className="form-control"
                      name="categoryId"
                      value={clientEntity.categoryId || ''}
                      onChange={category => this.handleCategoryChange(category)}
                      required
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {clientCategories
                        ? clientCategories.map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </option>
                          ))
                        : null}
                    </AvInput>
                    <AvFeedback>This field is required.</AvFeedback>
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <hr className="mt-5 mb-4" />
          {categoryId === 1 && (
            <div>
              <Row className="pl-3 pr-3 pt-3">
                <Col md="12">
                  <h4 className="font-medium mb-4">Personal Info</h4>
                </Col>
              </Row>
              <Row className="pl-3 pr-3">
                <Col md="4">
                  <AvGroup size="sm">
                    <Row>
                      <Label sm="4" id="firstNameLabel" for="client-firstName">
                        First Name
                      </Label>
                      <Col sm="7">
                        <AvField
                          id="client-firstName"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' }
                          }}
                          // @ts-ignore
                          value={clientEntity.firstName || directPayment.firstName}
                          placeholder="Enter first name"
                          type="text"
                          name="firstName"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col md="4">
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="middleNameLabel" for="client-middleName">
                        Middle Name
                      </Label>
                      <Col sm="7">
                        <AvField
                          id="client-middleName"
                          placeholder="Enter middle name"
                          type="text"
                          name="middleName"
                          // @ts-ignore
                          value={clientEntity.middleName || directPayment.middleName}
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col md="4">
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="lastNameLabel" for="client-lastName">
                        Last Name
                      </Label>
                      <Col sm="7">
                        <AvField
                          id="client-lastName"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' }
                          }}
                          // @ts-ignore
                          value={clientEntity.lastName || directPayment.lastName}
                          placeholder="Enter last name"
                          type="text"
                          name="lastName"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="pl-3 pr-3">
                <Col md="4">
                  <FormGroup>
                    <Row>
                      <Label sm="3" id="mobileLabel" for="client-mobile">
                        Mobile
                      </Label>
                      <Col sm="8">
                        <PhoneInput
                          // @ts-ignore
                          value={clientEntity.mobile || directPayment.msisdn}
                          onChange={this.handleMobile}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <AvGroup>
                    <Row>
                      <Label sm="4" for="client-idType">
                        Id Type
                      </Label>
                      <Col sm="7">
                        <AvInput
                          id="client-idType"
                          type="select"
                          className="form-control"
                          value={clientEntity.idTypeId || ''}
                          name="idTypeId"
                        >
                          <option value={''} disabled>
                            Select
                          </option>
                          {idTypes
                            ? sortBy(idTypes, ['name']).map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
              </Row>
            </div>
          )}
          <Row className="pl-3 pr-3">
            <Col md="4">
              <AvGroup>
                <Row>
                  <Label sm="4" id="emailLabel" for="client-email">
                    Email
                  </Label>
                  <Col sm="7">
                    <AvField
                      id="client-email"
                      value={(!isNew && clientEntity.email) || ''}
                      placeholder="Enter email address"
                      type="text"
                      name="email"
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col md="4">
              <AvGroup>
                <Row>
                  <Label sm="4" for="pin-number">
                    Pin Number
                  </Label>
                  <Col sm="7">
                    <AvField
                      id="pin-number"
                      validate={{
                        required: { value: false, errorMessage: 'This field is required.' }
                      }}
                      value={(!isNew && clientEntity.pinNumber) || ''}
                      className="form-control"
                      placeholder="Enter Pin"
                      type="text"
                      name="pinNumber"
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            {categoryId === 1 && (
              <Col md="4">
                <AvGroup>
                  <Row>
                    <Label sm="4" id="idNumberLabel" for="client-idNumber">
                      Id Number
                    </Label>
                    <Col sm="7">
                      <AvField
                        id="client-idNumber"
                        placeholder="Enter ID number"
                        type="text"
                        value={(!isNew && clientEntity.idNumber) || ''}
                        name="idNumber"
                      />
                    </Col>
                  </Row>
                </AvGroup>
              </Col>
            )}
          </Row>
          {categoryId === 2 && (
            <div>
              <Row className="pl-3 pr-3">
                <Col md="4">
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="clientNameLabel" for="client-clientName">
                        Company Name
                      </Label>
                      <Col sm="7">
                        <AvField id="client-clientName" placeholder="Enter company name" type="text" name="clientName" />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col md="4">
                  <AvGroup>
                    <Row>
                      <Label sm="4" id="contactPersionLabel" for="client-contactPersion">
                        Contact Person
                      </Label>
                      <Col sm="7">
                        <AvField
                          id="client-contactPersion"
                          value={(!isNew && clientEntity.contactPersion) || ''}
                          placeholder="Enter contact person"
                          type="text"
                          name="contactPersion"
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Row>
                      <Label sm="4" id="telephoneLabel" for="client-telephone">
                        Telephone
                      </Label>
                      <Col sm="7">
                        <FormGroup>
                          <PhoneInput value={(!isNew && clientEntity.telephone) || ''} onChange={this.handlePhone} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}
          <Row className="pl-3 pr-3">
            <Col md="4">
              <FormGroup>
                <Row>
                  <Label sm="4" id="countryLabel" for="client-country">
                    Country
                  </Label>
                  <Col sm="7">
                    <FormGroup>
                      <ReactFlagsSelect
                        id="client-country"
                        value={(!isNew && clientEntity.country) || 'KE'}
                        defaultCountry={'KE'}
                        onSelect={this.onCountry}
                        name="country"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col md="4">
              <AvGroup>
                <Row>
                  <Label sm="4" id="countyLabel" for="client-county">
                    County
                  </Label>
                  <Col sm="7">
                    <AvField
                      id="client-county"
                      value={(!isNew && clientEntity.county) || ''}
                      placeholder="Enter county"
                      type="text"
                      name="county"
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col md="4">
              <AvGroup>
                <Row>
                  <Label sm="4" id="ledgerNameLabel" for="client-ledgerName">
                    Ledger Name
                  </Label>
                  <Col sm="7">
                    <AvField
                      id="client-ledgerName"
                      value={(!isNew && clientEntity.ledgerName) || ''}
                      placeholder="Enter ledger name"
                      type="text"
                      name="ledgerName"
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <Row className="pl-3 pr-3">
            <Col md="4">
              <AvGroup>
                <Row>
                  <Label sm="4" id="addressLabel" for="client-address">
                    Address
                  </Label>
                  <Col sm="7">
                    <AvField
                      id="client-address"
                      value={(!isNew && clientEntity.address) || ''}
                      placeholder="Enter address"
                      type="text"
                      name="address"
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col md="4">
              <AvGroup>
                <Row>
                  <Label sm="4" id="streetAddressLabel" for="client-streetAddress">
                    Street Address
                  </Label>
                  <Col sm="7">
                    <AvField
                      id="client-streetAddress"
                      placeholder="Enter street address"
                      type="text"
                      value={(!isNew && clientEntity.streetAddress) || ''}
                      name="streetAddress"
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col md="4">
              <AvGroup>
                <Row>
                  <Label sm="4" id="pinNumberLabel" for="client-pinNumber">
                    Postal Code
                  </Label>
                  <Col sm="7">
                    <AvField
                      id="client-pinNumber"
                      placeholder="Enter Postal Code"
                      type="number"
                      className="form-control"
                      name="pinNumber"
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <AvGroup>
                <Row>
                  <Label sm="3" id="notesLabel" for="client-notes">
                    Notes
                  </Label>
                  <Col sm="8">
                    <AvField id="client-notes" rows={3} placeholder="Enter Notes" type="textarea" name="notes" />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col md="6">
              <AvGroup>
                <Row>
                  <Label sm="3" id="statusLabel" for="client-status">
                    Status
                  </Label>
                  <Col sm="8">
                    <AvInput
                      id="client-status"
                      type="select"
                      className="form-control"
                      name="status"
                      value={(!isNew && clientEntity.status) || 'PROSPECTIVE'}
                    >
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="PROSPECTIVE">PROSPECTIVE</option>
                      <option value="FORMER">FORMER</option>
                    </AvInput>
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <Row className="pl-3 pr-5 mb-5">
            <Col md="12 text-right">
              <Button type="submit" className="btn btn-success" disabled={updating}>
                <i className="fa fa-plus" /> NEW CLIENT
              </Button>
            </Col>
          </Row>
          <hr className="mt-5 mb-4" />
          <DataTable
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: 'Client Name',
                accessor: 'name'
              },
              {
                Header: 'Mobile',
                accessor: 'mobile'
              },
              {
                Header: 'Id Number',
                accessor: 'idNumber'
              },
              {
                Header: 'Email',
                accessor: 'email'
              },
              {
                Header: 'Select',
                accessor: 'select',
                sortable: false,
                filterable: false
              },
              {
                Header: 'ACTIONS',
                accessor: 'actions',
                sortable: false,
                filterable: false,
                style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
              }
            ]}
            manual
            defaultPageSize={5}
            pages={1}
            onFetchData={this.fetchData}
            showPaginationBottom
            tableData={clientsData}
            loading={clientsLoading}
            filterable
          />
          <Col sm="12" className="mt-5">
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Button tag={Link} id="cancel-save" to={`/entity/direct-payments`} replace color="info">
                    <span className="d-none d-md-inline">Back</span>
                  </Button>
                </Col>
                <Col sm="12" className="text-right">
                  <Button className="btn btn-success" onClick={this.onNext} disabled={updating}>
                    <i className="fa fa-check" /> NEXT
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </AvForm>
        <ModalView open={open} setOpen={this.props.openModal} modalError={modalError} />
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  directPayment: storeState.paymentCallbacks.entity,
  account: storeState.authentication.account,
  clientEntity: storeState.client.entity,
  clients: storeState.client.entities,
  idTypes: storeState.idType.entities,
  updating: storeState.paymentCallbacks.updating,
  clientsLoading: storeState.client.loading,
  clientCategories: storeState.clientCategory.entities,
  updateSuccess: storeState.paymentCallbacks.updateSuccess && storeState.client.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  resetClient,
  getClientByMobile,
  updateClient,
  getClientCategories,
  getIdTypes,
  getClientsByHash,
  getClients,
  getClient,
  updateEntity,
  createClient
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClientStep)
);
