import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IClient } from 'app/shared/model/client.model';
import { getEntities as getClients } from 'app/entities/client/client.reducer';
import { IUnderwriter } from 'app/shared/model/underwriter.model';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { IProductType } from 'app/shared/model/product-type.model';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { ICompany } from 'app/shared/model/company.model';
import { getEntities as getCompanies } from 'app/entities/company/company.reducer';
import { IClientPolicy } from 'app/shared/model/client-policy.model';
import { getEntities as getClientPolicies } from 'app/entities/client-policy/client-policy.reducer';
import { getEntity, updateEntity, createEntity, reset } from './policy-application.reducer';
import { IPolicyApplication } from 'app/shared/model/policy-application.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IPolicyApplicationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PolicyApplicationUpdate = (props: IPolicyApplicationUpdateProps) => {
  const [clientId, setClientId] = useState('0');
  const [underwriterId, setUnderwriterId] = useState('0');
  const [productTypeId, setProductTypeId] = useState('0');
  const [companyId, setCompanyId] = useState('0');
  const [clientPolicyId, setClientPolicyId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { policyApplicationEntity, clients, underwriters, productTypes, companies, clientPolicies, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/policy-application' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClients();
    props.getUnderwriters();
    props.getProductTypes();
    props.getCompanies();
    props.getClientPolicies();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...policyApplicationEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="ibsApp.policyApplication.home.createOrEditLabel">Create or edit a PolicyApplication</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : policyApplicationEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="policy-application-id">ID</Label>
                  <AvInput id="policy-application-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="clientEmailLabel" for="policy-application-clientEmail">
                  Client Email
                </Label>
                <AvField
                  id="policy-application-clientEmail"
                  type="text"
                  name="clientEmail"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="clientMobileLabel" for="policy-application-clientMobile">
                  Client Mobile
                </Label>
                <AvField
                  id="policy-application-clientMobile"
                  type="text"
                  name="clientMobile"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="underwriterEmailLabel" for="policy-application-underwriterEmail">
                  Underwriter Email
                </Label>
                <AvField
                  id="policy-application-underwriterEmail"
                  type="text"
                  name="underwriterEmail"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="policy-application-status">
                  Status
                </Label>
                <AvInput
                  id="policy-application-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && policyApplicationEntity.status) || 'REQUEST'}
                >
                  <option value="REQUEST">REQUEST</option>
                  <option value="RECIEVED">RECIEVED</option>
                  <option value="SUBMITED">SUBMITED</option>
                  <option value="PROCESSING">PROCESSING</option>
                  <option value="ACCEPTED">ACCEPTED</option>
                  <option value="DECLINED">DECLINED</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="notesLabel" for="policy-application-notes">
                  Notes
                </Label>
                <AvField id="policy-application-notes" type="text" name="notes" />
              </AvGroup>
              <AvGroup>
                <Label for="policy-application-client">Client</Label>
                <AvInput id="policy-application-client" type="select" className="form-control" name="clientId">
                  <option value="" key="0" />
                  {clients
                    ? clients.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="policy-application-underwriter">Underwriter</Label>
                <AvInput id="policy-application-underwriter" type="select" className="form-control" name="underwriterId">
                  <option value="" key="0" />
                  {underwriters
                    ? underwriters.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="policy-application-productType">Product Type</Label>
                <AvInput id="policy-application-productType" type="select" className="form-control" name="productTypeId">
                  <option value="" key="0" />
                  {productTypes
                    ? productTypes.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="policy-application-company">Company</Label>
                <AvInput id="policy-application-company" type="select" className="form-control" name="companyId">
                  <option value="" key="0" />
                  {companies
                    ? companies.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="policy-application-clientPolicy">Client Policy</Label>
                <AvInput id="policy-application-clientPolicy" type="select" className="form-control" name="clientPolicyId">
                  <option value="" key="0" />
                  {clientPolicies
                    ? clientPolicies.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/policy-application" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clients: storeState.client.entities,
  underwriters: storeState.underwriter.entities,
  productTypes: storeState.productType.entities,
  companies: storeState.company.entities,
  clientPolicies: storeState.clientPolicy.entities,
  policyApplicationEntity: storeState.policyApplication.entity,
  loading: storeState.policyApplication.loading,
  updating: storeState.policyApplication.updating,
  updateSuccess: storeState.policyApplication.updateSuccess
});

const mapDispatchToProps = {
  getClients,
  getUnderwriters,
  getProductTypes,
  getCompanies,
  getClientPolicies,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyApplicationUpdate);
