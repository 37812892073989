export interface ICustomerAuthentication {
  id?: number;
  mobile?: string;
  email?: string;
  idNumber?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  isVerified?: boolean;
}

export const defaultValue: Readonly<ICustomerAuthentication> = {
  isVerified: false
};
