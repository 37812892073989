import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import DataTable from 'app/shared/common/dataTable';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './direct-payment.reducer';
import { DebitStatus } from 'app/shared/model/payment-callback.model';
// tslint:disable-next-line:no-unused-variable
import { APP_LOCAL_DATETIME_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import utils from 'app/config/utils';

export interface IDirectPaymentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IDirectPaymentState = IPaginationBaseState;

export class DirectPayment extends React.Component<IDirectPaymentProps, IDirectPaymentState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`companyId=${this.props.account.companyId}`);
    this.props.getEntities(state.page, state.pageSize, `${sort || 'id'},${order || 'desc'}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { loading, directPayments, match, isAdmin, isDebitor, isFinance } = this.props;
    const showPayments = isAdmin || isDebitor || isFinance;
    // @ts-ignore
    const tableData = directPayments.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      // @ts-ignore
      name: `${prop.firstName || ''} ${prop.middleName || ''} ${prop.lastName || ''}`,
      allocated: (
        <Button
          color={prop.debitStatus === DebitStatus.DEBITED ? 'success' : 'primary'}
          tag={NavLink}
          to={prop.debitStatus === DebitStatus.DEBITED ? match.url : `${match.url}/${prop.id}/debit`}
        >
          {prop.debitStatus === DebitStatus.DEBITED ? 'DEBITED' : 'NOT DEBITED'}
        </Button>
      ),
      // @ts-ignore
      transactionTime: <TextFormat type="date" value={`${utils.getDateFromString(prop.transTime)}`} format={APP_LOCAL_DATETIME_FORMAT} />,
      actions: (
        <div className="text-center">
          <Button className="ml-1" color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          {isAdmin && (
            <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
              <i className="fa fa-edit" />
            </Button>
          )}
        </div>
      )
    }));
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Direct Payments
              </Col>
              <Col sm="6" className="text-right">
                {showPayments && (
                  <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                    Create new Direct Payment
                  </NavLink>
                )}
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Name',
                  accessor: 'name',
                  Cell: row => <div style={{ textAlign: 'center', fontSize: '12px' }}>{row.value}</div>
                },
                {
                  Header: 'Transaction ID',
                  accessor: 'transID'
                },
                {
                  Header: 'Amount',
                  accessor: 'transAmount'
                },
                {
                  Header: 'Pay Bill',
                  accessor: 'businessShortCode'
                },
                {
                  Header: 'Payment Category',
                  accessor: 'paymentType'
                },
                {
                  Header: 'Mobile',
                  accessor: 'msisdn'
                },
                {
                  Header: 'Bill Ref',
                  accessor: 'billRefNumber'
                },
                {
                  Header: 'Trans Time',
                  accessor: 'transactionTime'
                },
                {
                  Header: 'Status',
                  accessor: 'allocated',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ paymentCallbacks, authentication }: IRootState) => ({
  loading: paymentCallbacks.loading,
  directPayments: paymentCallbacks.entities,
  totalItems: paymentCallbacks.totalItems,
  account: authentication.account,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isDebitor: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.DEBITOR]),
  isFinance: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.FINANCE])
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectPayment);
