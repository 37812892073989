import React from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import PhoneInput from 'react-phone-number-input';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getAllClients, getClientByMobile } from 'app/entities/client/client.reducer';
import { getEntities } from 'app/entities/kyc/kyc.reducer';
import { connect } from 'react-redux';

export interface IDocumentTypeFormProps extends StateProps, DispatchProps {
  jumpToStep;
  onSubmit;
  updateState;
  updateStateEntity;
  formValues;
}

export interface IDocumentTypeFormState {
  clientId: string;
  mobile: string;
}

class DocumentTypeForm extends React.Component<IDocumentTypeFormProps, IDocumentTypeFormState> {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '0',
      mobile: undefined
    };
    this.onSelect = this.onSelect.bind(this);
  }

  handleNext = (event, errors, values) => {
    if (errors.length === 0) {
      this.props.updateState(values);
      this.props.jumpToStep(1);
    }
  };
  handlePrevious = () => {
    this.props.updateStateEntity({});
  };

  onSelect() {
    this.props.updateState({ ...this.props.kyc, clientId: this.props.client.id });
    this.props.jumpToStep(1);
  }

  handleKYCTypeChange = e => {
    const v = e.target.value;
    this.props.updateState({ ...this.props.kyc, name: v });
    this.props.updateStateEntity({ generalKYCType: v });
  };

  render() {
    const { formValues, client } = this.props;
    const { mobile } = this.state;
    return (
      <>
        <Row className="mb-1 mt-5 w-100">
          <Col sm="12">
            <AvForm model={formValues} onSubmit={this.handleNext}>
              <Row className="justify-content-md-center">
                <Col lg={8}>
                  <AvGroup className="mb-5 mt-4">
                    <Row>
                      <Label sm="3" className="text-right" id="kycTypeLabel" for="kyc-kycType">
                        Document Type
                      </Label>
                      <Col>
                        <AvInput
                          id="kyc-generalKYCType"
                          type="select"
                          onChange={this.handleKYCTypeChange}
                          className="form-control"
                          name="generalKYCType"
                        >
                          <option value="">Select</option>
                          <option value="ID">ID</option>
                          <option value="PIN">PIN</option>
                          <option value="OTHER">OTHER</option>
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </Col>
                    </Row>
                  </AvGroup>
                </Col>
                <Col sm="12">
                  <FormGroup className="pt-5">
                    <Row>
                      <Col sm="6" className="text-left">
                        <Button type="button" onClick={this.handlePrevious} className="font-bold btn-rounded" color="info">
                          &nbsp; &nbsp;Back&nbsp; &nbsp;
                        </Button>
                      </Col>
                      <Col sm="6" className="text-right">
                        <Button type="submit" className="btn btn-success btn-rounded font-bold">
                          &nbsp; &nbsp; NEXT &nbsp; &nbsp;
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.ageRange.loading,
  updating: storeState.ageRange.updating,
  account: storeState.authentication.account,
  client: storeState.client.entity,
  kyc: storeState.kYC.entity,
  updateSuccess: storeState.ageRange.updateSuccess
});

const mapDispatchToProps = {
  getAllClients,
  getClientByMobile,
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentTypeForm);
