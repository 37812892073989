import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import BenefitDetails from './benefit-details';
import BenefitDetailsDetail from './benefit-details-detail';
import BenefitDetailsUpdate from './benefit-details-update';
import BenefitDetailsDeleteDialog from './benefit-details-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={BenefitDetailsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={BenefitDetailsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={BenefitDetailsDetail} />
      <ErrorBoundaryRoute path={match.url} component={BenefitDetails} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={BenefitDetailsDeleteDialog} />
  </>
);

export default Routes;
