import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CustomInput, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { getEntities, getEntity as getQuote, selectQuoteItem, unSelectQuoteItem } from 'app/entities/quote/quote.reducer';
import { selectAkiQuoteItem, unSelectAkiQuoteItem } from 'app/modules/aki/aki.reducer';
import { createEntity, getEntityByEmail as getClient, updateEntity } from 'app/entities/client/client.reducer';
import { getEntities as getClientCategories } from 'app/entities/client-category/client-category.reducer';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getIdTypes } from 'app/entities/id-type/id-type.reducer';
import DataTable from 'app/shared/common/dataTable';
import { TextFormat } from 'react-jhipster';
import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

export interface ISelectQuoteProps extends StateProps, DispatchProps {
  id;
  jumpToStep;
  match;
  selectQuoteItem;
  unSelectQuoteItem;
}

export interface ISelectQuoteState {
  underwriterId: string;
  clientId: string;
  riskClassId: bigint;
  coverTypeId: bigint;
  newClient: boolean;
  categoryId: any;
  param: string;
  country: string;
  mobile: string;
  telephone: string;
  searchValue: string;
  itemsPerPage: number;
}

class SelectQuoteStep extends React.Component<ISelectQuoteProps, ISelectQuoteState> {
  private componentRef = React.createRef<HTMLDivElement>();
  private debounceLog = debounce(this.fetchData, 700);

  constructor(props) {
    super(props);
    this.state = {
      underwriterId: '0',
      clientId: '0',
      riskClassId: null,
      coverTypeId: null,
      newClient: false,
      categoryId: null,
      param: 'email',
      country: '',
      mobile: '',
      telephone: '',
      searchValue: null,
      itemsPerPage: null
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCountry = country => {
    this.setState({ country });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handlePhone = mobile => {
    this.setState({ telephone: mobile });
  };

  handleCategoryChange = event => {
    this.setState({ categoryId: Number(event.target.value) });
  };

  onChangeParam = event => {
    this.setState({ param: event.target.value });
  };

  onChangeSearchValue = event => {
    this.setState({ searchValue: event.target.value });
    this.debounceLog();
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(1);
    }
  }

  onSubmit() {
    const { jumpToStep } = this.props;
    jumpToStep(1);
  }

  fetchData() {
    // const sort = state.sorted.map(d => d.id).join();
    // const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    // const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    this.props.getEntities(0, 10, `createdAt,desc&eagerload=false&${this.state.param}=${this.state.searchValue}`);
    this.setState({ itemsPerPage: 10 });
  }

  componentDidMount() {}

  private addToCart(e, quote) {
    if (!e.target.checked) {
      this.props.unSelectAkiQuoteItem(quote.id);
    } else {
      this.props.selectAkiQuoteItem(quote);
    }
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { loading, account, quotes, updating } = this.props;
    const tableData = quotes
      .filter(q => q.underwriterId)
      .map(prop => ({
        ...prop,
        // logo: (
        //   <div className="media">
        //     {this.getCurrentLogo(prop) && (
        //       <img width="55" src={this.getCurrentLogo(prop)} alt="Generic placeholder image" className="img-fluid mr-2 media-object" />
        //     )}
        //   </div>
        // ),
        createdAt: prop.createdAt === null ? '' : <TextFormat type="date" value={prop.createdAt} format={APP_LOCAL_DATETIME_FORMAT} />,
        underwriter: <div className="media">{prop.underwriterName}</div>,
        rate: prop.rate ? `${prop.rate}%` : '0.0%',
        premiumPayable: `Ksh. ${String(prop.premiumPayable).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`,
        benefits: (
          <div className="link-container">
            <Link to={`/entity/quote/add-benefit/${prop.id}?wizard=2`} className="link-button">
              <FontAwesomeIcon icon="plus" />
            </Link>
            <Link to={`/entity/quote/add-benefit/${prop.id}?wizard=2`} className="link-button">
              <FontAwesomeIcon icon="eye" />
            </Link>
          </div>
        ),
        select: (
          <div className="text-center">
            <div className="checkbox">
              <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} />
            </div>
          </div>
        ),
        actions: (
          <div className="action-container">
            <div className="checkbox">
              <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} onChange={e => this.addToCart(e, prop)} />
            </div>
            <div onClick={e => this.addToCart(e, prop)} className="link-button">
              <i className=" fa fa-cart-plus " />
            </div>
          </div>
        )
      }));

    return (
      <Card className="border-bottom mt-5 mb-0">
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          Quote
        </CardTitle>
        <CardBody className="pr-2 pl-2">
          <AvForm onSubmit={this.onSubmit}>
            <Row className="pl-3 pr-3">
              <Col md="12">
                <h4 className="font-medium mb-4">Quote Details</h4>
              </Col>
            </Row>
            <Row className="pl-5 pr-5">
              <Col md="12">
                <AvGroup>
                  <Row>
                    <Label sm="2" for="client-category">
                      Search Quote By
                    </Label>
                    <Col sm="3">
                      <AvInput
                        id="search-param"
                        type="select"
                        className="form-control"
                        value="email"
                        name="searchParam"
                        onChange={value => this.onChangeParam(value)}
                        required
                      >
                        <option value="email" key="email">
                          Email
                        </option>
                        <option value="phone" key="phone">
                          Mobile Number
                        </option>
                        <option value="registration" key="registration">
                          Registration
                        </option>
                      </AvInput>
                      <AvFeedback>This field is required.</AvFeedback>
                    </Col>
                    <Col sm="4">
                      <AvField
                        id="searchValue"
                        placeholder=""
                        type="text"
                        name={'searchValue'}
                        value=""
                        onChange={this.onChangeSearchValue}
                      />
                    </Col>
                    {/*<Button type="button" className="btn btn-success" disabled={loading} onClick={this.fetchData}>*/}
                    {/*  <i className="fa fa-search" /> Search*/}
                    {/*</Button>*/}
                  </Row>
                </AvGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={12}>
                <div className="table-container" ref={this.componentRef}>
                  <DataTable
                    columns={[
                      {
                        Header: (
                          <div className="header-container">
                            <span>Quote Date</span>
                            <i className="mdi mdi-swap-vertical" />
                          </div>
                        ),
                        accessor: 'createdAt',
                        resizable: true
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Mobile</span>
                            <i className="mdi mdi-swap-vertical" />
                          </div>
                        ),
                        accessor: 'mobile'
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>Email</span>
                            <i className="mdi mdi-swap-vertical" />
                          </div>
                        ),
                        accessor: 'email'
                      },
                      {
                        Header: 'UNDERWRITER',
                        accessor: 'underwriter',
                        width: 250,
                        resizable: false,
                        headerStyle: { textAlign: 'left' },
                        style: {
                          display: 'flex',
                          alignItems: 'center'
                        },
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>REGISTRATION</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'registration',
                        headerStyle: { textAlign: 'left' },
                        style: { display: 'flex', alignItems: 'center' }
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>RISK CLASS</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'riskClassName',
                        headerStyle: { textAlign: 'left' },
                        style: { display: 'flex', alignItems: 'center' }
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>COVER TYPE</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'coverTypeName',
                        headerStyle: { textAlign: 'left' },
                        style: { display: 'flex', alignItems: 'center' }
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>PREMIUM</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'premiumPayable',
                        headerStyle: { textAlign: 'left' },
                        style: { display: 'flex', alignItems: 'center' }
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>RATE</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'rate',
                        width: 100,
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }
                      },
                      {
                        Header: 'BENEFITS',
                        accessor: 'benefits',
                        width: 130,
                        style: { display: 'flex', alignItems: 'center' },
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'ACTIONS',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }
                      }
                    ]}
                    manual
                    loading={loading}
                    defaultPageSize={10}
                    pages={this.getMaxPage()}
                    showPaginationBottom
                    tableData={tableData}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pl-3 pr-5 mt-4 mb-5">
              <Col md="12 text-right">
                <Button tag={Link} id="cancel-save" to={`/dashboard`} replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp; &nbsp;
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> NEXT
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.quote.loading,
  riskCategories: storeState.riskCategory.entities,
  account: storeState.authentication.account,
  quote: storeState.quote.entity,
  updateSuccess: storeState.client.updateSuccess,
  clientCategories: storeState.clientCategory.entities,
  idTypes: storeState.idType.entities,
  updating: storeState.client.updating,
  client: storeState.client.entity,
  quotes: storeState.quote.entities,
  cart: storeState.quote.cart,
  totalItems: storeState.quote.totalItems
});

const mapDispatchToProps = {
  getEntities,
  getQuote,
  getClient,
  getClientCategories,
  getIdTypes,
  updateEntity,
  createEntity,
  selectAkiQuoteItem,
  unSelectAkiQuoteItem
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectQuoteStep);
