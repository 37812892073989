import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getClients } from 'app/entities/client/client.reducer';
import { getEntities as getPolicies } from 'app/entities/policy/policy.reducer';
import { getEntity, updateEntity, createEntity, reset } from './client-policy.reducer';
import sortBy from 'lodash/sortBy';
import { getEntity as getPaymentEntity } from 'app/entities/direct-payment/direct-payment.reducer';

export interface IClientPolicyUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IClientPolicyUpdateState {
  isNew: boolean;
  clientId: string;
  policyId: string;
  paymentId: string;
}

export class ClientPolicyUpdate extends React.Component<IClientPolicyUpdateProps, IClientPolicyUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '0',
      policyId: '0',
      paymentId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isNew && nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const paymentId = searchParams.get('paymentId');
      this.props.reset();
      if (paymentId) {
        this.setState({ paymentId });
        Promise.resolve(this.props.getPaymentEntity(paymentId)).then(res => {
          const { directPayment, account } = this.props;
        });
      }
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getClients(0, 2000, `,&companyId=${this.props.account.companyId}`);
    this.props.getPolicies(0, 2000, `,&companyId=${this.props.account.companyId}`);
  }

  saveEntity = (event, errors, values) => {
    // console.log(values);
    // if (errors.length === 0) {
    //   const { clientPolicyEntity } = this.props;
    //   const entity = {
    //     ...clientPolicyEntity,
    //     ...values,
    //     companyId: this.props.account.companyId
    //   };
    //
    //   if (this.state.isNew) {
    //     this.props.createEntity(entity);
    //   } else {
    //     this.props.updateEntity(entity);
    //   }
    // }
  };

  handleClose = () => {
    history.back();
  };

  render() {
    const { clientPolicyEntity, clients, policies, directPayment, loading, updating } = this.props;
    const { isNew, paymentId } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <Col md="12">
              <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-book mr-2" />
                  Create or edit a ClientPolicy
                </CardTitle>
                <CardBody>
                  {loading && <p>Loading...</p>}
                  {!loading && (
                    <AvForm model={isNew && paymentId ? directPayment : isNew ? {} : clientPolicyEntity} onSubmit={this.saveEntity}>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="policyDateLabel" for="client-policy-policyDate">
                            Policy Date
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="client-policy-policyDate"
                              type="date"
                              className="form-control"
                              name="policyDate"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="invoiceNoLabel" for="client-policy-invoiceNo">
                            Invoice No
                          </Label>
                          <Col sm="10">
                            <AvField id="client-policy-invoiceNo" placeholder="Enter invoice no" type="text" name="invoiceNo" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="startDateLabel" for="client-policy-startDate">
                            Start Date
                          </Label>
                          <Col sm="10">
                            <AvField id="client-policy-startDate" type="date" className="form-control" name="startDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="endDateLabel" for="client-policy-endDate">
                            End Date
                          </Label>
                          <Col>
                            <AvField id="client-policy-endDate" type="date" className="form-control" name="endDate" />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="premiumPayableLabel" for="client-policy-premiumPayable">
                            Premium Payable
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="client-policy-premiumPayable"
                              type="string"
                              className="form-control"
                              name="premiumPayable"
                              placeholder="Enter Premium Payable"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' },
                                number: { value: true, errorMessage: 'This field should be a number.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="openPayableLabel" for="client-policy-openPayable">
                            Open Payable
                          </Label>
                          <Col sm="10">
                            <AvField
                              id="client-policy-openPayable"
                              type="string"
                              className="form-control"
                              name="openPayable"
                              placeholder="Enter open payable"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' },
                                number: { value: true, errorMessage: 'This field should be a number.' }
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" id="paymentStatusLabel" for="client-policy-paymentStatus">
                            Payment Status
                          </Label>
                          <Col sm="10">
                            <AvInput
                              id="client-policy-paymentStatus"
                              type="select"
                              className="form-control"
                              name="paymentStatus"
                              value={(!isNew && clientPolicyEntity.paymentStatus) || 'UNPAID'}
                            >
                              <option value="UNPAID">UNPAID</option>
                              <option value="PARTIAL">PARTIAL</option>
                              <option value="PAID">PAID</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="client-policy-client">
                            Client
                          </Label>
                          <Col sm="10">
                            <AvInput id="client-policy-client" type="select" className="form-control" name="clientId" required>
                              {clients
                                ? sortBy(clients, ['clientName']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.clientName}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                        <Row>
                          <Label sm="2" for="client-policy-policy">
                            Policy
                          </Label>
                          <Col sm="10">
                            <AvInput id="client-policy-policy" type="select" className="form-control" name="policyId" required>
                              {policies
                                ? sortBy(policies, ['name']).map(otherEntity => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.name}
                                    </option>
                                  ))
                                : null}
                            </AvInput>
                            <AvFeedback>This field is required.</AvFeedback>
                          </Col>
                        </Row>
                      </AvGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="12" className="text-center">
                            <Button tag={Link} id="cancel-save" to="/entity/client-policy" replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button type="submit" className="btn btn-success" disabled={updating}>
                              <i className="fa fa-check" /> Save
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clients: storeState.client.entities,
  policies: storeState.policy.entities,
  clientPolicyEntity: storeState.clientPolicy.entity,
  loading: storeState.clientPolicy.loading,
  updating: storeState.clientPolicy.updating,
  updateSuccess: storeState.clientPolicy.updateSuccess,
  directPayment: storeState.paymentCallbacks.entity,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getClients,
  getPolicies,
  getEntity,
  updateEntity,
  createEntity,
  getPaymentEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPolicyUpdate);
