export interface IRatioItem {
  value?: number;
  productName?: string;
}

export interface IReportClass {
  companyId?: number;
  currentClaims?: number;
  users?: number;
  quotes?: number;
  clients?: number;
  currentEarnings?: number;
  currentReferralEarnings?: number;
  currentSales?: number;
  currentWalletBalance?: number;
  earnings?: number;
  estimateSales?: number;
  productUptakeRatio?: IRatioItem[];
  claimsRatio?: IRatioItem[];
  from?: Date;
  to?: Date;
  yesToCall: number;
  noToCall: number;
}

export const defaultValue: Readonly<IReportClass> = {};
