import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

export interface IStatisticsProps {
  textColor: any;
  icon: string;
  title: ReactNode;
  subtitle: ReactNode;
}

class Statistics extends React.Component<IStatisticsProps> {
  render() {
    const icon = (
      <span className={'display-5 text-' + this.props.textColor}>
        <i className={'mdi mdi-' + this.props.icon} />
      </span>
    );
    const title = <span>{this.props.title}</span>;
    const subtitle = <h4 className="font-medium mb-0">{this.props.subtitle}</h4>;
    return (
      <div className="d-flex align-items-center">
        <div className="mr-2">{icon}</div>
        <div>
          {title}
          {subtitle}
        </div>
      </div>
    );
  }
}

export default Statistics;
