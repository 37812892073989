import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import Aki from 'app/modules/aki';

const EntityTemplate = props => (
  <MainTemplate>
    <Aki {...props} />
  </MainTemplate>
);

export default EntityTemplate;
