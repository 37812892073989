import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import Reports from 'app/modules/reports';

const EntityTemplate = props => (
  <MainTemplate>
    <Reports {...props} />
  </MainTemplate>
);

export default EntityTemplate;
