import React from 'react';
import { Collapse } from 'reactstrap';
import MenuItem from 'app/shared/layout/menus/link-item';

const adminMenuItems = (
  <>
    <MenuItem icon="fa fa-tachometer-alt fa-w-18 fa-fw" to="/admin/metrics">
      Metrics
    </MenuItem>
    <MenuItem icon="fa fa-heart fa-w-16 fa-fw" to="/admin/health">
      Health
    </MenuItem>
    <MenuItem icon="fa fa-list fa-w-16 fa-fw" to="/admin/configuration">
      Configuration
    </MenuItem>
    <MenuItem icon="fa fa-bell fa-w-14 fa-fw" to="/admin/audits">
      Audits
    </MenuItem>
    <MenuItem icon="fa fa-tasks fa-w-16 fa-fw" to="/admin/logs">
      Logs
    </MenuItem>
  </>
);

const swaggerItem = (
  <MenuItem icon="fa fa-book fa-w-14 fa-fw" to="/admin/docs">
    API
  </MenuItem>
);

class EntitiesCollapseMenu extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { isAdmin, showSwagger, account } = this.props;
    const { isOpen } = this.state;
    return (
      <li className="sidebar-item">
        <span data-toggle="collapse" className="sidebar-link has-arrow" onClick={this.toggleCollapse}>
          <i className="mdi mdi-settings" />
          <span className="hide-menu">Master</span>
        </span>
        <Collapse className="first-level" isOpen={isOpen}>
          <ul>
            <MenuItem icon="mdi mdi-umbrella-outline" to="/entity/underwriter">
              Underwriter
            </MenuItem>
            {account.productTypes.map(p => p.id).includes(3) && (
              <MenuItem icon="mdi mdi-umbrella-outline" to="/entity/underwriter-product">
                Underwriter Products
              </MenuItem>
            )}
            <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/kyc-type">
              KYC Type
            </MenuItem>
            <MenuItem icon="mdi mdi-shape-square-plus" to="/entity/risk-category">
              Risk Category
            </MenuItem>
            <MenuItem icon="mdi mdi-meteor" to="/entity/risk-class">
              Risk Class
            </MenuItem>
            {(isAdmin || account.productTypeId === null || account.productTypeId === 1) && (
              <MenuItem icon="mdi mdi-wrap" to="/entity/cover-type">
                Cover Type
              </MenuItem>
            )}
            <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/benefit-type">
              Benefit Type
            </MenuItem>
            <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/benefit">
              Benefit
            </MenuItem>
            <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/binder-rate">
              Binder Rate
            </MenuItem>
            <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/standard-rate">
              Standard Rate
            </MenuItem>
            <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/levies">
              Levies
            </MenuItem>
            {isAdmin && (
              <>
                <MenuItem icon="mdi mdi-folder-plus" to="/entity/product-type">
                  Product Type
                </MenuItem>
                <MenuItem icon="mdi mdi-odnoklassniki" to="/admin/user-management">
                  Users
                </MenuItem>
              </>
            )}
            {/* {isAdmin && adminMenuItems}
        {isAdmin && showSwagger && swaggerItem} */}
          </ul>
        </Collapse>
      </li>
    );
  }
}

export const EntitiesMenu = ({ isAdmin, showSwagger, account }) => (
  <EntitiesCollapseMenu isAdmin={isAdmin} showSwagger={showSwagger} account={account} />
);
