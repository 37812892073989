import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './customer-authentication-token.reducer';
import { ICustomerAuthenticationToken } from 'app/shared/model/customer-authentication-token.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICustomerAuthenticationTokenDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CustomerAuthenticationTokenDetail = (props: ICustomerAuthenticationTokenDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { customerAuthenticationTokenEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          CustomerAuthenticationToken [<b>{customerAuthenticationTokenEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="token">Token</span>
          </dt>
          <dd>{customerAuthenticationTokenEntity.token}</dd>
          <dt>
            <span id="productType">Product Type</span>
          </dt>
          <dd>{customerAuthenticationTokenEntity.productType}</dd>
          <dt>Customer Authentication</dt>
          <dd>
            {customerAuthenticationTokenEntity.customerAuthenticationId ? customerAuthenticationTokenEntity.customerAuthenticationId : ''}
          </dd>
        </dl>
        <Button tag={Link} to="/customer-authentication-token" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/customer-authentication-token/${customerAuthenticationTokenEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ customerAuthenticationToken }: IRootState) => ({
  customerAuthenticationTokenEntity: customerAuthenticationToken.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAuthenticationTokenDetail);
