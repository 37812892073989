import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import PolicyRenewal from 'app/modules/policy-renewal';

const EntityTemplate = props => (
  <MainTemplate>
    <PolicyRenewal {...props} />
  </MainTemplate>
);

export default EntityTemplate;
