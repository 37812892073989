import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CustomerAuthenticationToken from './customer-authentication-token';
import CustomerAuthenticationTokenDetail from './customer-authentication-token-detail';
import CustomerAuthenticationTokenUpdate from './customer-authentication-token-update';
import CustomerAuthenticationTokenDeleteDialog from './customer-authentication-token-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CustomerAuthenticationTokenUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CustomerAuthenticationTokenUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CustomerAuthenticationTokenDetail} />
      <ErrorBoundaryRoute path={match.url} component={CustomerAuthenticationToken} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CustomerAuthenticationTokenDeleteDialog} />
  </>
);

export default Routes;
