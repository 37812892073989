import { Moment } from 'moment';
import { Status } from 'app/shared/model/client.model';

export interface IUnderwriterProduct {
  id?: number;
  name?: string;
  description?: string;
  discountRate?: number;
  inpatient?: string;
  outpatient?: string;
  maternity?: string;
  dental?: string;
  optical?: string;
  underwriterId?: number;
  underwriterName?: string;
  discountActive?: boolean;
  productTypeId?: number;
  riskClassId?: number;
  riskClassName?: string;
  currencySymbol?: string;
  coverAreaId?: number;
  startDate?: Moment;
  endDate?: Moment;
  status?: Status;
  companyId?: number;
}

export const defaultValue: Readonly<IUnderwriterProduct> = {};
