import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import DataTable from 'app/shared/common/dataTable';
import { getSortState, IPaginationBaseState } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './product-type.reducer';
import { IProductType } from 'app/shared/model/product-type.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { updateUser, getUser } from 'app/modules/administration/user-management/user-management.reducer';
import { updateEntity as updateCompany, getEntity as getCompany } from 'app/entities/company/company.reducer';

export interface IProductTypeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IProductTypeState = IPaginationBaseState;

export class ProductType extends React.Component<IProductTypeProps, IProductTypeState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push('companyId=1');
    this.props.getEntities(state.page, state.pageSize, `${sort},${order}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
    this.props.getUser(this.props.account.login);
    this.props.getCompany(this.props.account.companyId);
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  toggleActivate = async (e, prop, active) => {
    const { productTypeList, user, company } = this.props;
    const accountProductTypes = productTypeList.filter(p => user.productTypes.map(item => item.id).includes(p.id));
    if (active) {
      const productTypes = accountProductTypes.filter(p => p.id !== prop.id);
      await this.props.updateUser({
        ...user,
        productTypes
      });
      await this.props.updateCompany({
        ...company,
        productTypes
      });
    } else {
      const productTypes = [...accountProductTypes, prop];
      await this.props.updateUser({
        ...user,
        productTypes
      });
      await this.props.updateCompany({
        ...company,
        productTypes
      });
    }
  };

  render() {
    const { loading, productTypeList, isPartner, match, user } = this.props;
    const tableData = productTypeList
      .filter(p => p.name !== 'Medical')
      .map(prop => ({
        ...prop,
        id: (
          <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
            {prop.id}
          </Button>
        ),
        activated: (
          <Button
            color={user.productTypes.map(p => p.id).includes(prop.id) ? 'success' : 'primary'}
            onClick={e => this.toggleActivate(e, prop, user.productTypes.map(p => p.id).includes(prop.id))}
          >
            {user.productTypes.map(p => p.id).includes(prop.id) ? 'Activated' : 'Not Activated'}
          </Button>
        ),
        actions: (
          <div className="text-center">
            <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
              <i className="fa fa-eye" />
            </Button>

            {!isPartner && (
              <>
                <Button
                  className="ml-1"
                  color="inverse"
                  size="sm"
                  round="true"
                  icon="true"
                  tag={NavLink}
                  to={`${match.url}/${prop.id}/edit`}
                >
                  <i className="fa fa-edit" />
                </Button>
                <Button
                  className="ml-1"
                  color="danger"
                  size="sm"
                  round="true"
                  icon="true"
                  tag={NavLink}
                  to={`${match.url}/${prop.id}/delete`}
                >
                  <i className="fa fa-times" />
                </Button>
              </>
            )}
          </div>
        )
      }));
    return (
      <div>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom bg-light">
            <Row>
              <Col sm="6">
                <i className="mdi mdi-border-right mr-2" />
                Product Types
              </Col>
              {!isPartner && (
                <Col sm="6" className="text-right">
                  <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                    Create new Product Type
                  </NavLink>
                </Col>
              )}
            </Row>
          </CardTitle>
          <CardBody>
            <DataTable
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id'
                },
                {
                  Header: 'Name',
                  accessor: 'name'
                },
                {
                  Header: 'Description',
                  accessor: 'description'
                },
                {
                  Header: 'Activated',
                  accessor: 'activated',
                  show: isPartner
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false
                }
              ]}
              manual
              defaultPageSize={10}
              pages={this.getMaxPage()}
              loading={loading}
              onFetchData={this.fetchData}
              showPaginationBottom
              tableData={tableData}
              filterable
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ productType, userManagement, authentication, company }: IRootState) => ({
  loading: productType.loading,
  productTypeList: productType.entities,
  totalItems: productType.totalItems,
  account: authentication.account,
  user: userManagement.user,
  company: company.entity,
  isPartner: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PARTNER])
});

const mapDispatchToProps = {
  getEntities,
  updateUser,
  getUser,
  getCompany,
  updateCompany
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductType);
