import React, { memo } from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import classname from 'classnames';
import find from 'lodash/find';
import moment from 'moment';
import { Typography, IconButton, Button, CircularProgress } from '@material-ui/core';
import useStyles from '../wizard/styles';
import { moneyConverter } from 'app/config/utils';

// import CouponCode from "./coupon-code";

const PolicySummary = ({ loading, onClick, quote, vehicleType }) => {
  const classes = useStyles();

  const handleClick = () => {};

  const additionalBenefitsTotal = Math.round(sumBy(quote.additionalBenefits, 'minimumValue'));

  const subTotal = Math.round(Math.round(quote.premiumPayable) + additionalBenefitsTotal);

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography component="h1" variant="h6" className={classes.title}>
          Policy Summary
        </Typography>
      </div>
      <div className={classes.headerContainer}>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.primary}>{`${quote.riskClassName} ${quote.coverTypeName}`}</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.primary}>Ksh.</Typography>
            <Typography className={classes.primary}>{moneyConverter(Math.round(quote.premiumPayable))}</Typography>
          </div>
        </div>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.primary}>
              {vehicleType && vehicleType.name} {quote && quote.registration}
            </Typography>
          </div>
        </div>
        {(quote.date || quote.additionalValues.coverStartDate) && (
          <div className={classes.headerRow}>
            <div className={classes.headerCol}>
              <Typography className={classes.primary}>
                {`Start Date: ${moment(quote.date || quote.additionalValues.coverStartDate).format('Do MMM YYYY')}`}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className={classes.premiumContainer}>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.secondary}>Premium Rate</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.secondary}>Rate</Typography>
            <Typography className={classes.secondary}>
              {(quote.rate &&
                `${Number(quote.rate)
                  .toFixed(3)
                  .slice(-2)
                  .split('')
                  .join('.')} % `) ||
                '0.0%'}
            </Typography>
          </div>
        </div>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.secondary}>Basic Premium</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.secondary}>Ksh.</Typography>
            <Typography className={classes.secondary}>{moneyConverter(Math.round(quote.basicPremium))}</Typography>
          </div>
        </div>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.secondary}>Additional Benefits</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.secondary}>Ksh.</Typography>
            <Typography className={classes.secondary}>{moneyConverter(additionalBenefitsTotal)}</Typography>
          </div>
        </div>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.secondary}>Levies</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.secondary}>Ksh.</Typography>
            <Typography className={classes.secondary}>{moneyConverter(Math.round(quote.levies))}</Typography>
          </div>
        </div>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.secondary}>Stamp Duty</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.secondary}>Ksh.</Typography>
            <Typography className={classes.secondary}>40</Typography>
          </div>
        </div>
      </div>
      <div className={classes.totalContainer}>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.totalText}>Sub-Total</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.totalSecondary}>Ksh.</Typography>
            <Typography className={classes.totalSecondary}>{moneyConverter(subTotal)}</Typography>
          </div>
        </div>
      </div>
      {/*<CouponCode /> */}
      <div className={classes.policyContainer}>
        <div className={classes.policyTextContainer}>
          <Typography className={classname(classes.secondary, classes.headerColHalf)}>Policy Underwriter</Typography>
          <Typography className={classname(classes.secondary, classes.flex)}>{quote.underwriterName}</Typography>
        </div>
        <div className={classes.policyTextContainer}>
          <Typography className={classname(classes.secondary, classes.headerColHalf)}>Policy Administrator</Typography>
          <Typography className={classname(classes.secondary, classes.flex)}>Kenbright Insurance Brokers</Typography>
        </div>
      </div>
      <div className={classes.amountContainer}>
        <div className={classes.headerRow}>
          <div className={classes.headerCol}>
            <Typography className={classes.amountText}>Amount Due</Typography>
          </div>
          <div className={classname(classes.headerCol, classes.headerColHalf)}>
            <Typography className={classes.amountText}>Ksh.</Typography>
            <Typography className={classes.amountText}>{moneyConverter(subTotal)}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

PolicySummary.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  quote: PropTypes.object,
  vehicleType: PropTypes.object
};

PolicySummary.defaultProps = {
  loading: false,
  onClick: () => {}
};

export default memo(PolicySummary);
