import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CoverArea from './cover-area';
import CoverAreaDetail from './cover-area-detail';
import CoverAreaUpdate from './cover-area-update';
import CoverAreaDeleteDialog from './cover-area-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CoverAreaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CoverAreaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CoverAreaDetail} />
      <ErrorBoundaryRoute path={match.url} component={CoverArea} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CoverAreaDeleteDialog} />
  </>
);

export default Routes;
