import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getRiskClasses } from 'app/entities/risk-class/risk-class.reducer';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntity, updateEntity, createEntity, reset } from './benefit-span-type.reducer';

export interface IBenefitSpanTypeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const BenefitSpanTypeUpdate = (props: IBenefitSpanTypeUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { benefitSpanTypeEntity, underwriters, riskClasses, coverTypes, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/entity/benefit-span-type' + props.location.search);
  };

  useEffect(() => {
    const { companyId } = props.account;
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getUnderwriters(0, 100, `,&companyId=${companyId}`);
    props.getRiskClasses(0, 100, `,&companyId=${companyId}&riskCategoryName=HEALTH`);
    props.getCoverTypes(0, 20, `,&companyId=${companyId}&productTypeId=3`);
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...benefitSpanTypeEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="ibsApp.benefitSpanType.home.createOrEditLabel">Create or edit a BenefitSpanType</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : benefitSpanTypeEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="benefit-span-type-id">ID</Label>
                  <AvInput id="benefit-span-type-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="inpatientLabel" for="benefit-span-type-inpatient">
                  Inpatient
                </Label>
                <AvField
                  id="benefit-span-type-inpatient"
                  type="text"
                  name="inpatient"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="outpatientLabel" for="benefit-span-type-outpatient">
                  Outpatient
                </Label>
                <AvField
                  id="benefit-span-type-outpatient"
                  type="text"
                  name="outpatient"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="maternityLabel" for="benefit-span-type-maternity">
                  Maternity
                </Label>
                <AvField
                  id="benefit-span-type-maternity"
                  type="text"
                  name="maternity"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="dentalLabel" for="benefit-span-type-dental">
                  Dental
                </Label>
                <AvField
                  id="benefit-span-type-dental"
                  type="text"
                  name="dental"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="opticalLabel" for="benefit-span-type-optical">
                  Optical
                </Label>
                <AvField
                  id="benefit-span-type-optical"
                  type="text"
                  name="optical"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label for="benefit-span-type-underwriter">Underwriter</Label>
                <AvInput id="benefit-span-type-underwriter" type="select" className="form-control" name="underwriterId" required>
                  <option value="0" key="0">
                    Select Underwriter
                  </option>
                  {underwriters
                    ? underwriters.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
                <AvFeedback>This field is required.</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="benefit-span-type-riskClass">Risk Class</Label>
                <AvInput id="benefit-span-type-riskClass" type="select" className="form-control" name="riskClassId" required>
                  <option value="0" key="0">
                    Select Risk Class
                  </option>
                  {riskClasses
                    ? riskClasses.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
                <AvFeedback>This field is required.</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="benefit-span-type-coverType">Cover Type</Label>
                <AvInput id="benefit-span-type-coverType" type="select" className="form-control" name="coverTypeId">
                  <option value="0" key="0">
                    Select Cover Type
                  </option>
                  {coverTypes
                    ? coverTypes.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
                <AvFeedback>This field is required.</AvFeedback>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/entity/benefit-span-type" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ underwriter, riskClass, benefitSpanType, authentication, coverType }: IRootState) => ({
  underwriters: underwriter.entities,
  riskClasses: riskClass.entities,
  benefitSpanTypeEntity: benefitSpanType.entity,
  loading: benefitSpanType.loading,
  updating: benefitSpanType.updating,
  account: authentication.account,
  coverTypes: coverType.entities,
  updateSuccess: benefitSpanType.updateSuccess
});

const mapDispatchToProps = {
  getUnderwriters,
  getRiskClasses,
  getCoverTypes,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitSpanTypeUpdate);
