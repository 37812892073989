import React from 'react';

import { Nav } from 'reactstrap';
import {
  DashboardMenu,
  AgentDashboardMenu,
  ClientsMenu,
  QuotesMenu,
  PoliciesMenu,
  ClaimsMenu,
  AgentsMenu,
  ProductsMenu,
  InvoicingMenu,
  ReportsMenu,
  EcoSystemMenu,
  EntitiesMenu
} from '../menus';

export interface ISidebarProps {
  account: any;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isDebitor: boolean;
  isFinance: boolean;
  isAgent: boolean;
  isPartner: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
}

export interface ISidebarState {
  menuOpen: boolean;
}

export default class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  state: ISidebarState = {
    menuOpen: false
  };

  render() {
    const { isAuthenticated, isAdmin, isPartner, isAgent, isFinance, isDebitor, isSwaggerEnabled, account } = this.props;
    const showMenus = account.companyId !== 1;
    const showPayments = isAdmin || isDebitor || isFinance;
    return (
      <aside className="left-sidebar" id="sidebarbg" data-sidebarbg="skin6">
        <div className="scroll-sidebar">
          <div className="sidebar-nav">
            <Nav id="sidebarnav">
              <li className="nav-small-cap">
                <i className="mdi mdi-dots-horizontal" />
                <span className="hide-menu">Personal</span>
              </li>
              {isAuthenticated && isAgent && <AgentDashboardMenu />}
              {isAuthenticated && !isAgent && <DashboardMenu />}
              {isAuthenticated && !isAgent && <ClientsMenu isAdmin={isAdmin} account={account} />}
              {isAuthenticated && !showMenus && <PoliciesMenu showPayments={showPayments} />}
              {isAuthenticated && !isAgent && <QuotesMenu />}
              {isAuthenticated && !showMenus && isAdmin && <InvoicingMenu />}
              {isAuthenticated && !showMenus && isAdmin && <ClaimsMenu />}
              {isAuthenticated && isPartner && <AgentsMenu />}
              {isAuthenticated && isPartner && <ProductsMenu />}
              {isAuthenticated && !showMenus && isAdmin && <ReportsMenu />}
              {isAuthenticated && !showMenus && <EcoSystemMenu isAdmin={isAdmin} account={account} />}
              {isAuthenticated && !showMenus && <EntitiesMenu isAdmin={isAdmin} showSwagger={isSwaggerEnabled} account={account} />}
            </Nav>
          </div>
        </div>
      </aside>
    );
  }
}
