import React, { DOMAttributes } from 'react';
import { Collapse } from 'reactstrap';
import MenuItem from 'app/shared/layout/menus/link-item';

export interface IEcoSystemState {
  isOpen: boolean;
}

/* tslint:disable */
class EcoSystemCollapseMenu extends React.Component<any, IEcoSystemState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { isOpen } = this.state;
    const { isAdmin, account } = this.props;

    return (
      <li className="sidebar-item">
        <span data-toggle="collapse" aria-expanded={isOpen} className="sidebar-link has-arrow" onClick={this.toggleCollapse}>
          <i className="mdi mdi-account-network" />
          <span className="hide-menu">Ecosystem</span>
        </span>
        <Collapse className="first-level" isOpen={isOpen}>
          <ul>
            <MenuItem icon="mdi mdi-contacts" to="/entity/service-provider">
              Service Provider
            </MenuItem>
            {(isAdmin || account.productTypeId === 1) && (
              <>
                <MenuItem icon="mdi mdi-contacts" to="/entity/service-provider-type">
                  Service Provider Type
                </MenuItem>
                <MenuItem icon="mdi mdi-contacts" to="/entity/countries">
                  Countries
                </MenuItem>
                <MenuItem icon="mdi mdi-contacts" to="/entity/car-model">
                  Car Model
                </MenuItem>
                <MenuItem icon="mdi mdi-contacts" to="/entity/car-make">
                  Car Make
                </MenuItem>
              </>
            )}
            {/*<MenuItem icon="mdi mdi-contacts" to="/entity/calls">*/}
            {/*  Calls*/}
            {/*</MenuItem>*/}
            {/*<MenuItem icon="mdi mdi-contacts" to="/entity/call-status">*/}
            {/*  Call Status*/}
            {/*</MenuItem>*/}
            <MenuItem icon="mdi mdi-contacts" to="/entity/underwriter/ranking">
              Underwriter Ranking
            </MenuItem>
            {account.productTypes.map(p => p.id).includes(3) && (
              <>
                <MenuItem icon="mdi mdi-contacts" to="/entity/cover-area">
                  Cover Area
                </MenuItem>
                <MenuItem icon="mdi mdi-contacts" to="/entity/benefit-details">
                  Benefit Details
                </MenuItem>
                <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/benefit-span-type">
                  Span Type
                </MenuItem>
                <MenuItem icon="mdi mdi-thumb-up-outline" to="/entity/offer">
                  Offers
                </MenuItem>
              </>
            )}
          </ul>
        </Collapse>
      </li>
    );
  }
}

export const EcoSystemMenu = ({ isAdmin, account }) => <EcoSystemCollapseMenu isAdmin={isAdmin} account={account} />;

export default EcoSystemMenu;
