import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { setFileData } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset, setOfferImage } from './offer.reducer';
import ImageUploader from 'react-images-upload';
import Utils, { ImageType } from 'app/config/utils';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';

export interface IOfferUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const OfferUpdate = (props: IOfferUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const [items, setItems] = useState([{ name: '', value: '' }]);

  const handleAddRow = () => {
    setItems([...items, { name: '', value: '' }]);
  };

  const handleItemChange = (index, key, value) => {
    const updatedItems = [...items];
    updatedItems[index][key] = value;
    setItems(updatedItems);
  };

  const { offerEntity, productTypes, account, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/entity/offer' + props.location.search);
  };
  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      Promise.all([props.getEntity(props.match.params.id)]).then(res => {
        const offer = res[0]['value']['data'];
        if (offer['offerItems'].length) {
          setItems([...offer['offerItems'], { name: '', value: '' }]);
        }
      });
    }
  }, []);

  useEffect(() => {
    const { companyId } = account;
    props.getProductTypes(0, 20, `,&${account.productTypes.map(a => `id=${a.id}`).join('&')}&companyId=${companyId}`);
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...offerEntity,
        ...values,
        offerItems: items
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const offerImage = Utils.getCurrentImage(props.offerEntity, ImageType.offer);

  const onOfferImageChange = (isAnImage, name) => event => {
    setFileData(
      // @ts-ignore
      { target: { files: [event[event.length - 1]] } },
      (contentType, data) => props.setOfferImage(name, data, contentType),
      isAnImage
    );
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="ibsApp.offer.home.createOrEditLabel">Create or edit a Offer</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : offerEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="offer-id">ID</Label>
                  <AvInput id="offer-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="offer-name">
                  Name
                </Label>
                <AvField
                  id="offer-name"
                  type="text"
                  name="name"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label for="binder-rate-ageRange">Product Type</Label>
                <AvInput id="binder-rate-productType" type="select" className="form-control" name="productTypeId" required>
                  <option value="" key="0">
                    SELECT
                  </option>
                  {productTypes
                    ? productTypes.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="offer-description">
                  Description
                </Label>
                <AvField id="offer-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="offer-status">
                  Status
                </Label>
                <AvInput
                  id="offer-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && offerEntity.status) || 'ACTIVE'}
                >
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="amountLabel" for="offer-amount">
                  Amount
                </Label>
                <AvField
                  id="offer-amount"
                  type="number"
                  className="form-control"
                  name="amount"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    number: { value: true, errorMessage: 'This field should be a number.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="startDateLabel" for="offer-startDate">
                  Start Date
                </Label>
                <AvField id="offer-startDate" type="date" className="form-control" name="startDate" />
              </AvGroup>
              <AvGroup>
                <Label id="endDateLabel" for="offer-endDate">
                  End Date
                </Label>
                <AvField id="offer-endDate" type="date" className="form-control" name="endDate" />
              </AvGroup>
              <AvGroup check>
                <Label id="topOfferLabel">
                  <AvInput id="offer-topOffer" type="checkbox" className="form-check-input" name="topOffer" />
                  Top Offer
                </Label>
              </AvGroup>
              <AvGroup>
                <Row>
                  <Label sm="2" id="offerImageLabel" for="offer-image">
                    Offer Banner
                  </Label>
                  <Col sm="5">
                    <ImageUploader
                      withIcon
                      singleImage
                      defaultImage={offerImage}
                      buttonText="Choose OFFER Image"
                      onChange={onOfferImageChange(true, 'content')}
                      imgExtension={['.jpg', '.jpeg', '.png']}
                      maxFileSize={5242880}
                    />
                  </Col>
                  <Col sm="5">{offerImage && <img height="200" width="220" src={offerImage} alt={offerImage} />}</Col>
                </Row>
              </AvGroup>
              <h2 className="mb-5">Offer Items</h2>
              {items.map((item, index) => (
                <Row key={index}>
                  <Col>
                    <AvGroup>
                      <Label id={`amountLabel-${index}`} for={`offer-item-name-${index}`}>
                        Item Name
                      </Label>
                      <AvField
                        id={`offer-item-name-${index}`}
                        type="text"
                        className="form-control"
                        name={`itemName-${index}`}
                        value={item.name}
                        onChange={e => handleItemChange(index, 'name', e.target.value)}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col>
                    <AvGroup>
                      <Label id={`amountLabel-${index}`} for={`offer-item-value-${index}`}>
                        Item Value
                      </Label>
                      <AvField
                        id={`offer-item-value-${index}`}
                        type="text"
                        className="form-control"
                        name={`itemValue-${index}`}
                        value={item.value}
                        onChange={e => handleItemChange(index, 'value', e.target.value)}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm="2" className="flex">
                    <Button color="primary" className="flex align-self-center" onClick={handleAddRow} id="add-offer-item">
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button tag={Link} id="cancel-save" to="/entity/offer" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ productType, offer, authentication }: IRootState) => ({
  productTypes: productType.entities,
  offerEntity: offer.entity,
  loading: offer.loading,
  account: authentication.account,
  updating: offer.updating,
  updateSuccess: offer.updateSuccess
});

const mapDispatchToProps = {
  getProductTypes,
  getEntity,
  updateEntity,
  createEntity,
  setOfferImage,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferUpdate);
