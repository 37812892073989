export interface ICarTrim {
  id?: number;
  name?: string;
  startProductionYear?: number;
  endProductionYear?: number;
  carSerieId?: number;
  carTypeId?: number;
  carModelId?: number;
}

export const defaultValue: Readonly<ICarTrim> = {};
