import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CustomInput, CardBody } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { createEntity, getEntityByEmail as getClient, updateEntity } from 'app/entities/client/client.reducer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities } from 'app/entities/kyc/kyc.reducer';
import DataTable from 'app/shared/common/dataTable';

export interface ISelectQuoteProps extends StateProps, DispatchProps {
  id;
  jumpToStep;
  match;
  selectQuoteItem;
  unSelectQuoteItem;
}

export interface ISelectClientState {
  underwriterId: string;
  clientId: string;
  riskClassId: bigint;
  coverTypeId: bigint;
  newClient: boolean;
  categoryId: any;
  param: string;
  searchValue: string;
  country: string;
  mobile: string;
  telephone: string;
  itemsPerPage: number;
}

class ClientKYCStep extends React.Component<ISelectQuoteProps, ISelectClientState> {
  private componentRef = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.state = {
      underwriterId: '0',
      clientId: '0',
      riskClassId: null,
      coverTypeId: null,
      newClient: false,
      categoryId: null,
      param: 'email',
      country: '',
      mobile: '',
      telephone: '',
      searchValue: null,
      itemsPerPage: null
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  onCountry = country => {
    this.setState({ country });
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  handlePhone = mobile => {
    this.setState({ telephone: mobile });
  };

  handleCategoryChange = event => {
    this.setState({ categoryId: Number(event.target.value) });
  };

  onChangeParam = event => {
    this.setState({ param: event.target.value });
  };

  onChangeSearchValue = event => {
    this.setState({ searchValue: event.target.value });
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.props.jumpToStep(2);
    }
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`companyId=${this.props.account.companyId}`);
    this.props.getEntities(
      state.page,
      state.pageSize,
      `${sort},${order}&${this.props.account.partner ? 'partner=' + this.props.account.partner.id : ''}&${filtered.join('&')}`
    );
    this.setState({ itemsPerPage: state.pageSize });
  }

  async componentDidMount() {
    const { client } = this.props;
    const kycResult = await this.props.getEntities(0, 20, `createdAt,desc&companyId=${this.props.account.companyId}&clientId=${client.id}`);
    if (kycResult && kycResult.value && kycResult.value.data) {
      this.setState({
        clients: kycResult.value.data
      });
    } else {
      const newClient = {
        email: client.email,
        mobile: client.mobile
      };
      this.setState({
        clients: [newClient]
      });
    }
  }

  private addToCart(e, quote) {
    if (!e.target.checked) {
      this.props.unSelectQuoteItem(quote.id);
    } else {
      this.props.selectQuoteItem(quote);
    }
  }

  getCurrentLogo = quote => {
    const { logoFile } = quote;
    if (logoFile && logoFile.content) {
      return `data:${logoFile.contentContentType};base64, ${logoFile.content}`;
    }
    return null;
  };

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  handleBack() {
    const { jumpToStep } = this.props;
    jumpToStep(1);
  }

  handleNext() {
    this.props.jumpToStep(3);
  }

  render() {
    const { loading, account, kycs, updating } = this.props;
    const tableData = kycs.map(prop => ({
      ...prop,
      logo: (
        <div className="media">
          {this.getCurrentLogo(prop) && (
            <img width="55" src={this.getCurrentLogo(prop)} alt="Generic placeholder image" className="img-fluid mr-2 media-object" />
          )}
        </div>
      ),
      select: (
        <div className="text-center">
          <div className="checkbox">
            <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} />
          </div>
        </div>
      ),
      actions: (
        <div className="action-container">
          <div className="checkbox">
            <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} onChange={e => this.addToCart(e, prop)} />
          </div>
          <div onClick={e => this.addToCart(e, prop)} className="link-button">
            <i className=" fa fa-cart-plus " />
          </div>
        </div>
      )
    }));

    return (
      <Card className="border-bottom mt-5 mb-0">
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          KYC
        </CardTitle>
        <CardBody className="pr-0 pl-0">
          <AvForm onSubmit={this.handleNext}>
            <Row className="p-3 m-5 d-flex justify-content-between">
              <Col md="6">
                <h4 className="font-medium mb-4">Client KYC</h4>
              </Col>
              <Button tag={Link} color={'primary'} to={'/issue-sticker/add-kyc'}>
                <i className="mdi mdi-check-circle-outline" />
                ADD KYC ATTACHMENT
              </Button>
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={12}>
                <div className="table-container" ref={this.componentRef}>
                  <DataTable
                    columns={[
                      {
                        Header: 'UNDERWRITER',
                        accessor: 'logo',
                        width: 250,
                        resizable: false,
                        headerStyle: { textAlign: 'left' },
                        style: { display: 'flex', alignItems: 'center' },
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>PRODUCT</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'underwriterName',
                        headerStyle: { textAlign: 'left' },
                        style: { display: 'flex', alignItems: 'center' }
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>PREMIUM</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'premiumPayable',
                        headerStyle: { textAlign: 'left' },
                        style: { display: 'flex', alignItems: 'center' }
                      },
                      {
                        Header: (
                          <div className="header-container">
                            <span>RATE</span>
                            <FontAwesomeIcon icon="sort" />
                          </div>
                        ),
                        accessor: 'rate',
                        width: 100,
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }
                      },
                      {
                        Header: 'BENEFITS',
                        accessor: 'benefits',
                        width: 130,
                        style: { display: 'flex', alignItems: 'center' },
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'ACTIONS',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }
                      }
                    ]}
                    manual
                    defaultPageSize={5}
                    pages={1}
                    showPaginationBottom
                    tableData={tableData}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pl-3 pr-5 mt-4 mb-5">
              <Col md="12 text-right">
                <Button type="button" color="info" onClick={this.handleBack}>
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp; &nbsp;
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> NEXT
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.quote.loading,
  riskCategories: storeState.riskCategory.entities,
  account: storeState.authentication.account,
  quote: storeState.quote.entity,
  partners: storeState.partner.entities,
  updateSuccess: storeState.client.updateSuccess,
  clientCategories: storeState.clientCategory.entities,
  idTypes: storeState.idType.entities,
  updating: storeState.client.updating,
  client: storeState.client.entity,
  kycs: storeState.kYC.entities,
  cart: storeState.quote.cart,
  totalItems: storeState.quote.totalItems
});

const mapDispatchToProps = {
  getEntities,
  getClient,
  updateEntity,
  createEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientKYCStep);
