import React from 'react';
import MainTemplate from 'app/shared/templates/main-template';
import Logout from 'app/modules/login/logout';

const HomeTemplate = props => (
  <MainTemplate>
    <Logout {...props} />
  </MainTemplate>
);

export default HomeTemplate;
