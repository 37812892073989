import React from 'react';
import useSelector, { connect } from 'react-redux';
import { Nav, NavbarBrand, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import logolighticon from '../../../assets/images/logo-light.svg';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from 'app/entities/quote/quote.reducer';
import { getEntities as getRiskCategories } from 'app/entities/risk-category/risk-category.reducer';
import { AkiMenu } from '../menus/aki';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <b className="logo-icon">
      <img src={logolighticon} alt="homepage" className="dark-logo" />
      <img src={logolighticon} alt="homepage" className="light-logo" />
    </b>
    {/* <div className="logo-text">
      <h2 className="mt-2 mb-0 font-bold">IBS</h2>
    </div> */}
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/">
    <BrandIcon />
  </NavbarBrand>
);

export const Home = ({ isPartner, account }) => (
  <Nav className="float-left" navbar>
    {account.companyId === 1 && (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          Create New <i className="fa fa-angle-down" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag={Link} to={'/entity/client/new'}>
            Client
          </DropdownItem>
          <DropdownItem tag={Link} to={'/entity/quote/create-quote'}>
            Quote
          </DropdownItem>
          <DropdownItem tag={Link} to={'/entity/kyc/new'}>
            KYC
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )}
  </Nav>
);

export const Notification = () => (
  <Nav className="ml-auto float-right" navbar>
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <i className="mdi mdi-bell font-24" />
      </DropdownToggle>
      <DropdownMenu right className="mailbox">
        <span className="with-arrow">
          <span className="bg-primary" />
        </span>
        <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
          <div className="">
            <h4 className="mb-0">4 New</h4>
            <p className="mb-0">Notifications</p>
          </div>
        </div>
        <div className="message-center notifications" />
        <a className="nav-link text-center mb-1 text-dark" href=";">
          <strong>Check all notifications</strong> <i className="fa fa-angle-right" />
        </a>
      </DropdownMenu>
    </UncontrolledDropdown>
  </Nav>
);

export interface IQuoteProps {
  jumpToStep: any;
  quotes;
  cart;
  cartTotal;
  match;
  sendAllQuotes;
  selectQuoteItem;
  unSelectQuoteItem;
}

class Cart extends React.Component<IQuoteProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { match, cartTotal } = this.props;
    return (
      <Nav className="ml-auto float-right align-items-center">
        {cartTotal > 0 && (
          <div className="font-20  text-white">
            <Badge color="error" badgeContent={cartTotal}>
              <i className="fa fa-shopping-cart  text-center" />
            </Badge>
          </div>
        )}
      </Nav>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.quote.loading,
  quoteList: storeState.quote.entities,
  totalItems: storeState.quote.totalItems,
  quote: storeState.quote,
  cartTotal: storeState.quote.cartTotal,
  cart: storeState.quote.cart,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getEntities,
  getRiskCategories
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
