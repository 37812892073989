import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RiskClassKycType from './risk-class-kyc-type';
import RiskClassKycTypeDetail from './risk-class-kyc-type-detail';
import RiskClassKycTypeUpdate from './risk-class-kyc-type-update';
import RiskClassKycTypeDeleteDialog from './risk-class-kyc-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RiskClassKycTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RiskClassKycTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RiskClassKycTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={RiskClassKycType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={RiskClassKycTypeDeleteDialog} />
  </>
);

export default Routes;
