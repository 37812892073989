import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Button, Row, Col, CardBody } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './standard-rate.reducer';
import { IStandardRate } from 'app/shared/model/standard-rate.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import DataTable from 'app/shared/common/dataTable';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IStandardRateProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IStandardRateState = IPaginationBaseState;

export class StandardRate extends React.Component<IStandardRateProps, IStandardRateState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    this.props.getEntities(state.page, state.pageSize, `${sort},${order}&${filtered.join('&')}`);
    this.setState({ itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  render() {
    const { loading, standardRateList, match } = this.props;
    const tableData = standardRateList.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      startActiveDate: <TextFormat type="date" value={prop.startActiveDate} format={APP_LOCAL_DATE_FORMAT} />,
      endActiveDate: <TextFormat type="date" value={prop.endActiveDate} format={APP_LOCAL_DATE_FORMAT} />,
      productTypeId: prop.productTypeId ? <NavLink to={`product-type/${prop.productTypeId}`}>{prop.productTypeId}</NavLink> : '',
      riskCategoryId: prop.riskCategoryId ? <NavLink to={`risk-category/${prop.riskCategoryId}`}>{prop.riskCategoryId}</NavLink> : '',
      riskClassId: prop.riskClassId ? <NavLink to={`risk-class/${prop.riskClassId}`}>{prop.riskClassId}</NavLink> : '',
      coverTypeId: prop.coverTypeId ? <NavLink to={`cover-type/${prop.coverTypeId}`}>{prop.coverTypeId}</NavLink> : '',
      actions: (
        <div className="text-center">
          <Button color="primary" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}`}>
            <i className="fa fa-eye" />
          </Button>
          <Button className="ml-1" color="inverse" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
          <Button className="ml-1" color="danger" size="sm" round="true" icon="true" tag={NavLink} to={`${match.url}/${prop.id}/delete`}>
            <i className="fa fa-times" />
          </Button>
        </div>
      )
    }));
    return (
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <Row>
            <Col sm="6">
              <i className="mdi mdi-border-right mr-2" />
              Standard Rates
            </Col>
            <Col sm="6" className="text-right">
              <NavLink to={`${match.url}/new`} color="success" activeClassName="active" className="btn btn-create-new">
                Create new Standard Rate
              </NavLink>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <DataTable
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: 'Risk Class',
                accessor: 'riskClassName',
                sortable: false,
                filterable: false
              },
              {
                Header: 'Rate',
                accessor: 'rate'
              },
              {
                Header: 'Cover Type',
                accessor: 'coverTypeName',
                sortable: false,
                filterable: false
              },
              {
                Header: 'Actions',
                accessor: 'actions',
                sortable: false,
                filterable: false
              }
            ]}
            manual
            defaultPageSize={10}
            pages={this.getMaxPage()}
            loading={loading}
            onFetchData={this.fetchData}
            showPaginationBottom
            tableData={tableData}
            filterable
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ standardRate }: IRootState) => ({
  loading: standardRate.loading,
  standardRateList: standardRate.entities,
  totalItems: standardRate.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardRate);
