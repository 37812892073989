import React, { Component } from 'react';
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { Button, CustomInput } from 'reactstrap';

import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT, AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getEntities } from 'app/entities/client-policy/client-policy.reducer';
import { connect } from 'react-redux';
import DataTable from 'app/shared/common/dataTable';
import 'react-table/react-table.css';

export interface ISearchFilterProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ISearchFilterState = IPaginationBaseState;

class ClientPolicyFilter extends Component<ISearchFilterProps, ISearchFilterState> {
  private readonly reactTable: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(window.location, ITEMS_PER_PAGE)
    };
    this.fetchData = this.fetchData.bind(this);
    this.reactTable = React.createRef();
  }

  fetchData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    filtered.push(`companyId=${this.props.account.companyId}`);
    this.props.getEntities(state.page, state.pageSize, `${sort || 'createdAt'},${order || 'desc'}&${filtered.join('&')}`);
    this.setState({ ...state, itemsPerPage: state.pageSize });
  }

  getMaxPage() {
    const { totalItems } = this.props;
    const { itemsPerPage } = this.state;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  }

  private selectClientPolicy(e, clientPolicy) {
    if (!e.target.checked) {
      this.props.onPickItem(null);
    } else {
      this.props.onPickItem(clientPolicy.id);
    }
  }

  render() {
    const { loading, clientPolicies, match, account } = this.props;
    const columns = [
      {
        Header: (
          <div className="header-container">
            <span>Id</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'id'
      },
      {
        Header: (
          <div className="header-container">
            <span>Payment Status</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'paymentStatus'
      },
      {
        Header: (
          <div className="header-container">
            <span>Invoice No</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'invoiceNo'
      },
      {
        Header: (
          <div className="header-container">
            <span>Client Id</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'clientId'
      },
      {
        Header: (
          <div className="header-container">
            <span>Policy Id</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'policyId'
      },
      {
        Header: (
          <div className="header-container">
            <span>Policy Date</span>
            <i className="mdi mdi-swap-vertical" />
          </div>
        ),
        accessor: 'policyDate',
        resizable: true
      },
      {
        Header: () => (
          <div className="header-container">
            <span>Premium Payable</span>
          </div>
        ),
        accessor: 'premiumPayable',
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        filterable: false
      }
    ];
    const tableData = clientPolicies.map(prop => ({
      ...prop,
      select: (
        <div className="text-center">
          <div className="checkbox">
            <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} />
          </div>
        </div>
      ),
      actions: (
        <div className="text-center">
          <CustomInput type="checkbox" name="policy" id={`select_${prop.id}`} onChange={e => this.selectClientPolicy(e, prop)} />
        </div>
      )
    }));
    return (
      <DataTable
        ref={this.reactTable}
        manual
        className="-striped -highlight"
        defaultPageSize={10}
        columns={columns}
        pages={this.getMaxPage()}
        loading={loading}
        onFetchData={this.fetchData}
        tableData={tableData}
        filterable
      />
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.clientPolicy.loading,
  clientPolicies: storeState.clientPolicy.entities,
  totalItems: storeState.clientPolicy.totalItems,
  account: storeState.authentication.account,
  isPartner: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PARTNER])
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPolicyFilter);
