import React from 'react';
import classname from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

class AgentTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    };
  }
  handleClick = selectedTab => {
    const { handleTab } = this.props;
    this.setState({ activeTab: selectedTab });
    handleTab(selectedTab);
  };
  render() {
    const { activeTab } = this.state;
    return (
      <div className="client-tab">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classname({
                active: activeTab === 1
              })}
              onClick={() => this.handleClick(1)}
            >
              EARNINGS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classname({
                active: activeTab === 2
              })}
              onClick={() => this.handleClick(2)}
            >
              STATEMENTS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classname({
                active: activeTab === 6
              })}
              onClick={() => this.handleClick(6)}
            >
              Profile
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AgentTab;
