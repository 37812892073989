import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './car-specification-value.reducer';
import { ICarSpecificationValue } from 'app/shared/model/car-specification-value.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICarSpecificationValueDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CarSpecificationValueDetail extends React.Component<ICarSpecificationValueDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { carSpecificationValueEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            CarSpecificationValue [<b>{carSpecificationValueEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="value">Value</span>
            </dt>
            <dd>{carSpecificationValueEntity.value}</dd>
            <dt>
              <span id="unit">Unit</span>
            </dt>
            <dd>{carSpecificationValueEntity.unit}</dd>
            <dt>Car Trim</dt>
            <dd>{carSpecificationValueEntity.carTrimId ? carSpecificationValueEntity.carTrimId : ''}</dd>
            <dt>Car Type</dt>
            <dd>{carSpecificationValueEntity.carTypeId ? carSpecificationValueEntity.carTypeId : ''}</dd>
            <dt>Car Specification</dt>
            <dd>{carSpecificationValueEntity.carSpecificationId ? carSpecificationValueEntity.carSpecificationId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/car-specification-value" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/car-specification-value/${carSpecificationValueEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ carSpecificationValue }: IRootState) => ({
  carSpecificationValueEntity: carSpecificationValue.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarSpecificationValueDetail);
