import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardTitle, Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { APP_LOCAL_STRING_DATE_FORMAT } from 'app/config/constants';
import { getEntity as getQuote, requestPayment } from 'app/entities/quote/quote.reducer';
import { connect } from 'react-redux';
import { TextFormat } from 'react-jhipster';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import PhoneInput from 'react-phone-number-input';

export interface IPaymentProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

class RequestPayment extends React.Component<IPaymentProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      mobile: ''
    };
  }

  componentDidMount() {
    this.props.getQuote(this.props.match.params.id);
  }

  handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      const { quote } = this.props;
      const data = {
        mobile: this.state.mobile || quote.mobile,
        quoteId: quote.id,
        amount: values.amount || quote.id
      };
      this.props.requestPayment(data);
    }
  };

  handleMobile = mobile => {
    this.setState({ mobile });
  };

  render() {
    const { quote, loading } = this.props;
    return (
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          <i className="mdi mdi-book mr-2" />
          Quote Payment
        </CardTitle>
        <CardBody className="pr-0 pl-0">
          <Row>
            <Col>
              <h4 className="font-medium mb-4">Quote Summary</h4>
              <div className="d-flex align-items-center content-benefit">
                <div className="d-flex align-items-center flex-fill justify-content-between">
                  <div className="flex-fill pr-5">
                    <div className="d-flex justify-content-around mb-4">
                      <h6 className="font-light mb-0">Quote Date:</h6>
                      <h6 className="font-bold mb-0">
                        <TextFormat type="date" value={`${quote.createdAt}`} format={APP_LOCAL_STRING_DATE_FORMAT} />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                      <h6 className="font-light mb-0">Risk Class:</h6>
                      <h6 className="font-bold mb-0">{quote.riskClassName}</h6>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                      <h6 className="font-light mb-0">Cover Type:</h6>
                      <h6 className="font-bold mb-0">{quote.coverTypeName}</h6>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                      <h6 className="font-light mb-0">Underwriter:</h6>
                      <h6 className="font-bold mb-0">{quote.underwriterName}</h6>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                      <h6 className="font-light mb-0">Sum Insured:</h6>
                      <h6 className="font-bold mb-0">
                        {`Ksh. ${String(quote.sumInsured).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                      <h6 className="font-light mb-0">Cover Start Date:</h6>
                      <h6 className="font-bold mb-0">
                        <TextFormat type="date" value={`${quote.createdAt || null}`} format={APP_LOCAL_STRING_DATE_FORMAT} />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                      <h6 className="font-light mb-0">Cover EndDate:</h6>
                      <h6 className="font-bold mb-0">
                        {quote.expiryDate && (
                          <TextFormat type="date" value={`${quote.expiryDate}`} format={APP_LOCAL_STRING_DATE_FORMAT || ''} />
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <h4 className="font-medium mb-4">Request Payment</h4>
              <AvForm onSubmit={this.handleSubmit}>
                <Row>
                  <Col md={10}>
                    <FormGroup>
                      <Row>
                        <Label sm="3" id="mobileLabel" for="client-mobile">
                          Mobile
                        </Label>
                        <Col sm="8">
                          <PhoneInput value={quote.mobile} onChange={this.handleMobile} />
                        </Col>
                      </Row>
                    </FormGroup>
                    <AvGroup>
                      <Row>
                        <Label sm="2" id="quoteAmountLabel" for="quote-amount">
                          Amount
                        </Label>
                        <Col sm="10">
                          <AvField id="quote-amount" placeholder="Enter Amount " type="number" name="amount" value={quote.premiumPayable} />
                        </Col>
                      </Row>
                    </AvGroup>
                    <FormGroup>
                      <Button type="submit" loading={loading} className="btn btn-block btn-success">
                        <i className="fa fa-check" /> Request Payment
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  quote: storeState.quote.entity,
  loading: storeState.quote.requestingPayment,
  account: storeState.authentication.account,
  companyId: storeState.authentication.account.companyId
});

const mapDispatchToProps = {
  getQuote,
  requestPayment
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPayment);
