import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IRemittance, defaultValue } from 'app/shared/model/remittance.model';
import utils from 'app/config/utils';

export const ACTION_TYPES = {
  FETCH_REMITTANCE_LIST: 'remittance/FETCH_REMITTANCE_LIST',
  FETCH_REMITTANCE: 'remittance/FETCH_REMITTANCE',
  FETCH_SUMMARY_REMITTANCE: 'remittance/FETCH_SUMMARY_REMITTANCE',
  CREATE_REMITTANCE: 'remittance/CREATE_REMITTANCE',
  UPDATE_REMITTANCE: 'remittance/UPDATE_REMITTANCE',
  DELETE_REMITTANCE: 'remittance/DELETE_REMITTANCE',
  EXPORT_REMITS: 'remittance/EXPORT_REMITS',
  RESET: 'remittance/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRemittance>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type RemittanceState = Readonly<typeof initialState>;

// Reducer

export default (state: RemittanceState = initialState, action): RemittanceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REMITTANCE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SUMMARY_REMITTANCE):
    case REQUEST(ACTION_TYPES.FETCH_REMITTANCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_REMITTANCE):
    case REQUEST(ACTION_TYPES.UPDATE_REMITTANCE):
    case REQUEST(ACTION_TYPES.DELETE_REMITTANCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_REMITTANCE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUMMARY_REMITTANCE):
    case FAILURE(ACTION_TYPES.FETCH_REMITTANCE):
    case FAILURE(ACTION_TYPES.CREATE_REMITTANCE):
    case FAILURE(ACTION_TYPES.UPDATE_REMITTANCE):
    case FAILURE(ACTION_TYPES.DELETE_REMITTANCE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_REMITTANCE_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_SUMMARY_REMITTANCE):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_REMITTANCE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_REMITTANCE):
    case SUCCESS(ACTION_TYPES.UPDATE_REMITTANCE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_REMITTANCE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/remittances';

// Actions

export const getEntities: ICrudGetAllAction<IRemittance> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_REMITTANCE_LIST,
    payload: axios.get<IRemittance>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesSummary: ICrudGetAllAction<IRemittance> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/summary${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_REMITTANCE_LIST,
    payload: axios.get<IRemittance>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IRemittance> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_REMITTANCE,
    payload: axios.get<IRemittance>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IRemittance> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_REMITTANCE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IRemittance> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REMITTANCE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IRemittance> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_REMITTANCE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const exportRemits = (page = 0, size = 10, params, entityName) => async dispatch => {
  await utils.exportEntity(apiUrl, page, size, params, ACTION_TYPES.EXPORT_REMITS, entityName, dispatch);
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
