import { IFormulaParam } from 'app/shared/model/formula-param.model';

export const enum CalculationParam {
  SUM_INSURED = 'SUM_INSURED',
  RATE = 'RATE',
  NET_PREMIUM = 'NET_PREMIUM',
  TL = 'TL',
  PCF = 'PCF',
  SD = 'SD',
  GROSS_PREMIUM = 'GROSS_PREMIUM',
  COMMISION_RATE = 'COMMISION_RATE',
  USER_INPUT = 'USER_INPUT',
  COMMISSION = 'COMMISSION',
  FIXED = 'FIXED'
}

export const enum CalculationType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
  ADDITION = 'ADDITION',
  SUBTRACTION = 'SUBTRACTION',
  MULTIPLICATION = 'MULTIPLICATION',
  DIVISION = 'DIVISION'
}

export interface IRiskClassFormula {
  id?: number;
  name?: string;
  calculationParam?: CalculationParam;
  calculationType?: CalculationType;
  riskClassId?: number;
  coverTypeId?: number;
  formulaParams?: IFormulaParam[];
}

export const defaultValue: Readonly<IRiskClassFormula> = {};
