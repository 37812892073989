import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import {
  FormGroup,
  Card,
  TabContent,
  TabPane,
  CardSubtitle,
  CardTitle,
  Label,
  Row,
  Col,
  Input,
  Button,
  CardBody,
  CustomInput
} from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { getSortState, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, IPaginationBaseState, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getProductTypes } from 'app/entities/product-type/product-type.reducer';
import { getEntities as getCoverTypes } from 'app/entities/cover-type/cover-type.reducer';
import { getEntities as getUnderwriters } from 'app/entities/underwriter/underwriter.reducer';
import { getEntities as getClientPolicies } from 'app/entities/client-policy/client-policy.reducer';
import { selectPolicyItem, unSelectPolicyItem, selectClientItem, unSelectClientItem } from 'app/entities/invoice/invoice.reducer';
import { getEntity, updateEntity, createEntity, reset, openModal, closeModal } from './invoice.reducer';
import { getEntities as getClients, getEntity as getClient } from 'app/entities/client/client.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import DataTable from 'app/shared/common/dataTable';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import DataModal from 'app/shared/common/dataModal';
import sortBy from 'lodash/sortBy';

export interface IInvoiceUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IInvoiceState extends IPaginationBaseState {
  productTypeId: string;
  isNew: boolean;
  clientName: string;
  selectClient: boolean;
  selectPolicy: boolean;
  policyName: string;
}

export class InvoiceUpdate extends React.Component<IInvoiceUpdateProps, IInvoiceState> {
  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE, 'createdAt', 'desc'),
      isNew: !this.props.match.params || !this.props.match.params.id,
      selectClient: false,
      productTypeId: '0',
      clientName: '',
      selectPolicy: false,
      policyName: ''
    };
    this.fetchClientsData = this.fetchClientsData.bind(this);
    this.fetchPoliciesData = this.fetchPoliciesData.bind(this);
  }

  handleClose() {
    history.back();
  }

  handleBack() {
    history.back();
  }

  async toggle() {
    if (!this.props.modalOpen) {
      this.props.openModal(true);
      await this.props.getProductTypes(0, 30, `id,asc&companyId=1`);
      this.props.getCoverTypes(0, 30, `id,asc&companyId=1`);
      const productTypeIds = this.props.productTypes.map(a => `productTypes_Id=${a.id}`);
      this.props.getUnderwriters(0, 100, `name,asc&companyId=${this.props.account.companyId}&${productTypeIds.join('&')} `);
    } else {
      this.props.closeModal(false);
    }
  }

  // const fetchClient = async id => {
  //   const res = await props.getClient(id);
  //   log(res);
  //   // @ts-ignore
  //   fetchClientPolicies(res.value.data.id);
  // };
  // const fetchClientPolicies = id => {
  //   const productTypeIds = account.productTypes.map(a => `productTypeId=${ a.id }`);
  //   props.getClientPolicies(
  //     paginationState.activePage - 1,
  //     paginationState.itemsPerPage,
  //     `${ paginationState.sort },${ paginationState.order }&companyId=${ account.companyId }&${ productTypeIds.join('&') }&clientId=${ id }`
  //   );
  // };

  fetchClientsData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { productTypes, companyId } = this.props.account;
    filtered.push(`companyId=${companyId}`);
    const productTypeIds = productTypes.map(a => `productTypes_Id=${a.id}`);
    this.props.getClients(
      state.page,
      state.pageSize,
      `${sort || 'createdAt'},${order || 'desc'}&${productTypeIds.join('&')}&${filtered.join('&')}`
    );
    this.setState({ itemsPerPage: state.pageSize });
  }

  fetchPoliciesData(state) {
    const sort = state.sorted.map(d => d.id).join();
    const order = state.sorted.map(d => (d.desc ? 'desc' : 'asc')).join();
    const filtered = state.filtered.map(a => `${a.id}=${a.value}`);
    const { productTypes, companyId } = this.props.account;
    filtered.push(`companyId=${companyId}`);
    const productTypeIds = productTypes.map(a => `productTypes_Id=${a.id}`);
    this.props.getClientPolicies(
      state.page,
      state.pageSize,
      `${sort || 'createdAt'},${order || 'desc'}&${productTypeIds.join('&')}&${filtered.join('&')}`
    );
    this.setState({ itemsPerPage: state.pageSize });
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...values,
        clientId: this.props.cart.client.id,
        clientPoliciesIds: this.props.cart.clientPolicies.map(p => p.id)
      };
      delete entity['policy'];
      delete entity['totalItems'];
      delete entity['cartTotal'];
      delete entity['clientName'];
      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  onChangeProduct(value) {
    this.setState({ productTypeId: value });
  }

  onClickClient = () => {
    this.setState({ selectClient: !this.state.selectClient });
  };
  onClickPolicy = () => {
    this.setState({ selectPolicy: !this.state.selectPolicy });
  };

  onSelectClient(e, client) {
    let clientName;
    if (!e.target.checked) {
      this.props.unSelectClientItem(client.id);
      clientName = '';
    } else {
      this.props.selectClientItem(client);
      clientName = client.firstName || client.lastName ? `${client.firstName} ${client.lastName}` : client.email;
    }
    this.setState({ clientName });
  }

  onSelectPolicy(e, policy) {
    this.setState({ policyName: policy.name });
    if (!e.target.checked) {
      this.props.unSelectPolicyItem(policy.id);
    } else {
      this.props.selectPolicyItem(policy);
    }
  }

  render() {
    const {
      clientPolicies,
      updating,
      clients,
      productTypes,
      match,
      clientPolicyEntity,
      underwriters,
      account,
      cart,
      loading,
      invoiceEntity,
      coverTypes
    } = this.props;
    const { selectClient, selectPolicy, isNew, clientName, policyName } = this.state;
    const tablePoliciesData = clientPolicies.map(prop => ({
      ...prop,
      premiumPayable: `Ksh. ${String(prop.premiumPayable).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')}`,
      policyDate: (
        <TextFormat type="date" value={moment(prop.policyDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />
      ),
      startDate: <TextFormat type="date" value={moment(prop.startDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      endDate: <TextFormat type="date" value={moment(prop.endDate).format(APP_LOCAL_DATETIME_FORMAT)} format={APP_LOCAL_DATE_FORMAT} />,
      policyId: prop.policyId ? <NavLink to={`policy/${prop.policyId}`}>{prop.policyId}</NavLink> : '',
      actions: (
        <div className="action-container">
          <Input type="checkbox" onClick={e => this.onSelectPolicy(e, prop)} />
        </div>
      )
    }));

    const clientsData = clients.map(prop => ({
      ...prop,
      id: (
        <Button tag={NavLink} to={`${match.url}/${prop.id}`} color="link" size="sm">
          {prop.id}
        </Button>
      ),
      idTypeId: prop.categoryId ? <NavLink to={`id-type/${prop.idTypeId}`}>{prop.idTypeId}</NavLink> : '',
      name: `${prop.firstName || ''} ${prop.middleName || ''} ${prop.lastName || ''}`,
      actions: (
        <div className="text-center">
          <Input type="checkbox" onClick={e => this.onSelectClient(e, prop)} />
        </div>
      )
    }));

    const renderChildren = () => (
      <div>
        <AvForm model={isNew ? {} : clientPolicyEntity} onSubmit={this.saveEntity}>
          <Row>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" for="underwriter-product-productType">
                    Product Type
                  </Label>
                  <Col sm="8">
                    <AvInput
                      id="underwriter-product-productType"
                      type="select"
                      className="form-control"
                      onChange={e => this.onChangeProduct(e.target.value)}
                      name="productTypeId"
                    >
                      <option value="0" key="0">
                        Select Product Type
                      </option>
                      {productTypes
                        ? productTypes.map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </option>
                          ))
                        : null}
                    </AvInput>
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" for="policy-underwriter">
                    Underwriter
                  </Label>
                  <Col sm="8">
                    <AvInput id="policy-underwriter" type="select" className="form-control" name="underwriterId" required>
                      {underwriters
                        ? sortBy(underwriters, ['name']).map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </option>
                          ))
                        : null}
                    </AvInput>
                    <AvFeedback>This field is required.</AvFeedback>
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" for="policy-coverType">
                    Cover Type
                  </Label>
                  <Col sm="8">
                    <AvInput id="policy-coverType" type="select" className="form-control" name="coverTypeId" required>
                      {coverTypes
                        ? sortBy(coverTypes, ['name']).map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </option>
                          ))
                        : null}
                    </AvInput>
                    <AvFeedback>This field is required.</AvFeedback>
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" id="policyDateLabel" for="client-policy-policyDate">
                    Policy Date
                  </Label>
                  <Col sm="8">
                    <AvField
                      id="client-policy-policyDate"
                      type="date"
                      className="form-control"
                      name="policyDate"
                      validate={{
                        required: { value: true, errorMessage: 'This field is required.' }
                      }}
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" id="startDateLabel" for="client-policy-startDate">
                    Start Date
                  </Label>
                  <Col sm="8">
                    <AvField id="client-policy-startDate" type="date" className="form-control" name="startDate" />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" id="endDateLabel" for="client-policy-endDate">
                    End Date
                  </Label>
                  <Col sm="8">
                    <AvField id="client-policy-endDate" type="date" className="form-control" name="endDate" />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          {/*<AvGroup>*/}
          {/*  <Row>*/}
          {/*    <Label sm="2" id="invoiceNoLabel" for="client-policy-invoiceNo">*/}
          {/*      Invoice No*/}
          {/*    </Label>*/}
          {/*    <Col sm="10">*/}
          {/*      <AvField id="client-policy-invoiceNo" placeholder="Enter invoice no" type="text" name="invoiceNo"/>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</AvGroup>*/}
          <Row>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" id="premiumPayableLabel" for="client-policy-premiumPayable">
                    Premium Payable
                  </Label>
                  <Col sm="8">
                    <AvField
                      id="client-policy-premiumPayable"
                      type="string"
                      className="form-control"
                      name="premiumPayable"
                      placeholder="Enter Premium Payable"
                      validate={{
                        required: { value: true, errorMessage: 'This field is required.' },
                        number: { value: true, errorMessage: 'This field should be a number.' }
                      }}
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
            <Col>
              <AvGroup>
                <Row>
                  <Label sm="4" id="openPayableLabel" for="client-policy-openPayable">
                    Open Payable
                  </Label>
                  <Col sm="8">
                    <AvField
                      id="client-policy-openPayable"
                      type="string"
                      className="form-control"
                      name="openPayable"
                      placeholder="Enter open payable"
                      validate={{
                        required: { value: true, errorMessage: 'This field is required.' },
                        number: { value: true, errorMessage: 'This field should be a number.' }
                      }}
                    />
                  </Col>
                </Row>
              </AvGroup>
            </Col>
          </Row>
          <AvGroup>
            <Row>
              <Label sm="4" id="paymentStatusLabel" for="client-policy-paymentStatus">
                Payment Status
              </Label>
              <Col sm="8">
                <AvInput id="client-policy-paymentStatus" type="select" className="form-control" name="paymentStatus" value={'UNPAID'}>
                  <option value="UNPAID">UNPAID</option>
                  <option value="PARTIAL">PARTIAL</option>
                  <option value="PAID">PAID</option>
                </AvInput>
              </Col>
            </Row>
          </AvGroup>
          <FormGroup>
            <Row>
              <Col sm="12" className="text-center">
                <Button tag={Link} id="cancel-save" to="/entity/client-policy" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp; &nbsp;
                <Button type="submit" className="btn btn-success" disabled={updating}>
                  <i className="fa fa-check" /> Save
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </AvForm>
      </div>
    );

    return (
      <div>
        <Card>
          <CardBody className="pr-0 pl-0">
            <Row className="justify-content-center">
              <Col md="10">
                <h2 id="ibsApp.invoice.home.createOrEditLabel">Create or edit a Invoice</h2>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="10">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    <AvForm model={isNew ? {} : invoiceEntity} onSubmit={this.saveEntity}>
                      {!isNew ? (
                        <>
                          <AvGroup>
                            <Label for="invoice-id">ID</Label>
                            <AvInput id="invoice-id" type="text" className="form-control" name="id" required readOnly />
                          </AvGroup>
                          <AvGroup>
                            <Label for="invoice-no">Invoice No</Label>
                            <AvInput id="invoice-no" type="text" className="form-control" name="invoiceNo" required readOnly />
                          </AvGroup>
                        </>
                      ) : null}
                      <AvGroup>
                        <Label id="dateLabel" for="invoice-date">
                          Invoice Date
                        </Label>
                        <AvField
                          id="invoice-date"
                          type="date"
                          name="date"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' }
                          }}
                        />
                      </AvGroup>
                      <AvGroup>
                        <Label id="clientLabel" for="invoice-client">
                          Client
                        </Label>
                        <AvField
                          id="invoice-description"
                          type="text"
                          name="clientName"
                          onClick={this.onClickClient}
                          value={clientName}
                          placeholder="Client"
                          // validate={{
                          //   required: { value: true, errorMessage: 'This field is required.' }
                          // }}
                        />
                      </AvGroup>
                      {selectClient && (
                        <DataTable
                          columns={[
                            {
                              Header: 'ID',
                              accessor: 'id'
                            },
                            {
                              Header: 'Client Name',
                              accessor: 'name'
                            },
                            {
                              Header: 'Mobile',
                              accessor: 'mobile'
                            },
                            {
                              Header: 'Id Number',
                              accessor: 'idNumber'
                            },
                            {
                              Header: 'Email',
                              accessor: 'email'
                            },
                            {
                              Header: 'Select',
                              accessor: 'select',
                              sortable: false,
                              filterable: false
                            },
                            {
                              Header: 'ACTIONS',
                              accessor: 'actions',
                              sortable: false,
                              filterable: false,
                              style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                            }
                          ]}
                          manual
                          defaultPageSize={5}
                          pages={1}
                          onFetchData={this.fetchClientsData}
                          showPaginationBottom
                          tableData={clientsData}
                          filterable
                        />
                      )}
                      <AvGroup>
                        <Label id="policiesLabel" for="invoice-policies">
                          Policies
                        </Label>
                        <AvField
                          id="invoice-policies"
                          type="text"
                          name="policies"
                          placeholder="Client Policies"
                          onClick={this.onClickPolicy}
                          value={policyName}
                          // validate={{
                          //   required: { value: true, errorMessage: 'This field is required.' }
                          // }}
                        />
                      </AvGroup>
                      <div>
                        {selectPolicy && (
                          <>
                            <DataTable
                              columns={[
                                {
                                  Header: 'ID',
                                  accessor: 'id'
                                },
                                {
                                  Header: 'Policy Name',
                                  accessor: 'name'
                                },
                                {
                                  Header: 'Policy Date',
                                  accessor: 'policyDate'
                                },
                                {
                                  Header: 'Start Date',
                                  accessor: 'startDate'
                                },
                                {
                                  Header: 'End Date',
                                  accessor: 'endDate'
                                },
                                {
                                  Header: 'Policy Id',
                                  accessor: 'policyId',
                                  sortable: false,
                                  filterable: false
                                },
                                {
                                  Header: 'ACTIONS',
                                  accessor: 'actions',
                                  sortable: false,
                                  filterable: false,
                                  style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                                }
                              ]}
                              manual
                              defaultPageSize={5}
                              pages={1}
                              onFetchData={this.fetchPoliciesData}
                              showPaginationBottom
                              tableData={tablePoliciesData}
                              filterable
                            />
                          </>
                        )}
                      </div>

                      <AvGroup>
                        <Label id="descriptionLabel" for="invoice-description">
                          Description
                        </Label>
                        <AvField
                          id="invoice-description"
                          type="textarea"
                          name="description"
                          rows={3}
                          placeholder="Description"
                          // validate={ {
                          //   required: { value: true, errorMessage: 'This field is required.' }
                          // } }
                        />
                      </AvGroup>
                      <Row className={'d-flex align-items-md-end text-right'}>
                        <Col md={12}>
                          <div className="d-flex align-items-md-end text-right mt-5">
                            <Button onClick={this.handleBack} color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button color="success" id="save-entity" type="submit" disabled={updating}>
                              <FontAwesomeIcon icon="save" />
                              &nbsp; CREATE
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/*<DataModal size="lg" open={modalOpen} actions={false} children={renderChildren()} toggle={this.toggle} backdrop fullscreen />*/}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  clients: storeState.client.entities,
  clientPolicies: storeState.clientPolicy.entities,
  client: storeState.client.entity,
  invoiceEntity: storeState.invoice.entity,
  loading: storeState.invoice.loading,
  updating: storeState.invoice.updating,
  modalOpen: storeState.invoice.modalOpen,
  updateSuccess: storeState.invoice.updateSuccess,
  account: storeState.authentication.account,
  clientPolicyEntity: storeState.clientPolicy.entity,
  coverTypes: storeState.coverType.entities,
  productTypes: storeState.productType.entities,
  underwriters: storeState.underwriter.entities,
  cart: storeState.invoice.cart
});

const mapDispatchToProps = {
  getClientPolicies,
  getClients,
  getEntity,
  getProductTypes,
  getUnderwriters,
  getCoverTypes,
  updateEntity,
  createEntity,
  unSelectClientItem,
  selectClientItem,
  selectPolicyItem,
  unSelectPolicyItem,
  openModal,
  closeModal,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceUpdate);
