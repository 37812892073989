import React from 'react';
import MenuItem from 'app/shared/layout/menus/link-item';

export const ProductsMenu = () => (
  <MenuItem to="/entity/product-type" icon="mdi mdi-basket">
    Products
  </MenuItem>
);

export default ProductsMenu;
