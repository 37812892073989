import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICarGeneration, defaultValue } from 'app/shared/model/car-generation.model';

export const ACTION_TYPES = {
  FETCH_CARGENERATION_LIST: 'carGeneration/FETCH_CARGENERATION_LIST',
  FETCH_CARGENERATION: 'carGeneration/FETCH_CARGENERATION',
  CREATE_CARGENERATION: 'carGeneration/CREATE_CARGENERATION',
  UPDATE_CARGENERATION: 'carGeneration/UPDATE_CARGENERATION',
  DELETE_CARGENERATION: 'carGeneration/DELETE_CARGENERATION',
  RESET: 'carGeneration/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICarGeneration>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type CarGenerationState = Readonly<typeof initialState>;

// Reducer

export default (state: CarGenerationState = initialState, action): CarGenerationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CARGENERATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CARGENERATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CARGENERATION):
    case REQUEST(ACTION_TYPES.UPDATE_CARGENERATION):
    case REQUEST(ACTION_TYPES.DELETE_CARGENERATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CARGENERATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CARGENERATION):
    case FAILURE(ACTION_TYPES.CREATE_CARGENERATION):
    case FAILURE(ACTION_TYPES.UPDATE_CARGENERATION):
    case FAILURE(ACTION_TYPES.DELETE_CARGENERATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CARGENERATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CARGENERATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CARGENERATION):
    case SUCCESS(ACTION_TYPES.UPDATE_CARGENERATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CARGENERATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/car-generations';

// Actions

export const getEntities: ICrudGetAllAction<ICarGeneration> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_CARGENERATION_LIST,
  payload: axios.get<ICarGeneration>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<ICarGeneration> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CARGENERATION,
    payload: axios.get<ICarGeneration>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ICarGeneration> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CARGENERATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICarGeneration> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CARGENERATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICarGeneration> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CARGENERATION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
